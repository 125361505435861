// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: schema/schema.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../google/protobuf/timestamp";
import {
  AssertCategoryType,
  assertCategoryTypeFromJSON,
  assertCategoryTypeToJSON,
  AssertStateType,
  assertStateTypeFromJSON,
  assertStateTypeToJSON,
} from "../models/assert";
import {
  KnownProvider,
  knownProviderFromJSON,
  knownProviderToJSON,
  RestKeyType,
  restKeyTypeFromJSON,
  restKeyTypeToJSON,
  TokenScope,
  tokenScopeFromJSON,
  tokenScopeToJSON,
  UserRole,
  userRoleFromJSON,
  userRoleToJSON,
} from "../models/auth";
import {
  CableType,
  cableTypeFromJSON,
  cableTypeToJSON,
  FabricTopology,
  fabricTopologyFromJSON,
  fabricTopologyToJSON,
  NodeRole,
  nodeRoleFromJSON,
  nodeRoleToJSON,
  NodeType,
  nodeTypeFromJSON,
  nodeTypeToJSON,
  OsType,
  osTypeFromJSON,
  osTypeToJSON,
  PortRole,
  portRoleFromJSON,
  portRoleToJSON,
} from "../models/fabric";
import {
  AdminState,
  adminStateFromJSON,
  adminStateToJSON,
  FabricType,
  fabricTypeFromJSON,
  fabricTypeToJSON,
  OperState,
  operStateFromJSON,
  operStateToJSON,
  OrgKind,
  orgKindFromJSON,
  orgKindToJSON,
} from "../models/global";
import {
  BmcProductType,
  bmcProductTypeFromJSON,
  bmcProductTypeToJSON,
  ConnectivityStatus,
  connectivityStatusFromJSON,
  connectivityStatusToJSON,
  ManagedEndpointType,
  managedEndpointTypeFromJSON,
  managedEndpointTypeToJSON,
  SmartNicDpuProductType,
  smartNicDpuProductTypeFromJSON,
  smartNicDpuProductTypeToJSON,
} from "../models/server";
import { PathId } from "../schema_path/path_id";

export const protobufPackage = "schema";

export enum TestEnum {
  TEST_ENUM_INVALID = 0,
  TEST_ENUM_ONE = 1,
  TEST_ENUM_TWO = 2,
  UNRECOGNIZED = -1,
}

export function testEnumFromJSON(object: any): TestEnum {
  switch (object) {
    case 0:
    case "TEST_ENUM_INVALID":
      return TestEnum.TEST_ENUM_INVALID;
    case 1:
    case "TEST_ENUM_ONE":
      return TestEnum.TEST_ENUM_ONE;
    case 2:
    case "TEST_ENUM_TWO":
      return TestEnum.TEST_ENUM_TWO;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TestEnum.UNRECOGNIZED;
  }
}

export function testEnumToJSON(object: TestEnum): string {
  switch (object) {
    case TestEnum.TEST_ENUM_INVALID:
      return "TEST_ENUM_INVALID";
    case TestEnum.TEST_ENUM_ONE:
      return "TEST_ENUM_ONE";
    case TestEnum.TEST_ENUM_TWO:
      return "TEST_ENUM_TWO";
    case TestEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** TemperatureSensors gives the well known names for temperature sensors. */
export enum TemperatureSensors {
  INVALID_SENSOR = 0,
  ACPI = 1,
  CPU_U17_P1P05V_TEMP = 2,
  CPU_U17_PVCCIN_TEMP = 3,
  CPU_U117_P1P2V_TEMP = 4,
  CPU_U117_P1P05V_TEMP = 5,
  DIMM_TEMP1 = 6,
  DIMM_TEMP2 = 7,
  FAN_Sensor = 8,
  MB_JM01_L1_TEMP = 9,
  MB_JM01_L2_TEMP = 10,
  MB_JM11_L1_TEMP = 11,
  MB_JM11_L2_TEMP = 12,
  MB_JM21_L1_TEMP = 13,
  MB_JM21_L2_TEMP = 14,
  MB_PORT_Sensor = 15,
  MB_TMP421_Local = 16,
  PSU0HSNK_Temp = 17,
  PSU0Inlet_Temp = 18,
  PSU0Outlet_Temp = 19,
  PSU1HSNK_Temp = 20,
  PSU1Inlet_Temp = 21,
  PSU1Outlet_Temp = 22,
  SSD_Temp = 23,
  X86_CORE_0_T = 24,
  X86_CORE_1_T = 25,
  X86_CORE_2_T = 26,
  X86_CORE_3_T = 27,
  X86_PKG_TEMP = 28,
  MB_3_3V_R_VDDCK_TEMP_L1 = 29,
  MB_3_3V_R_VDDCK_TEMP_L2 = 30,
  MB_GB_CORE_TEMP_L1 = 31,
  MB_VDDS_VDDA_TEMP_L1 = 32,
  MB_VDDS_VDDA_TEMP_L2 = 33,
  PSU0HSNK_Temp1 = 34,
  PSU0HSNK_Temp2 = 35,
  PSU1HSNK_Temp1 = 36,
  PSU1HSNK_Temp2 = 37,
  NPU0_TEMP_0 = 38,
  NPU0_TEMP_1 = 39,
  NPU0_TEMP_2 = 40,
  NPU0_TEMP_3 = 41,
  NPU0_TEMP_4 = 42,
  NPU0_TEMP_5 = 43,
  NPU0_TEMP_6 = 44,
  NPU0_TEMP_7 = 45,
  NPU0_TEMP_8 = 46,
  NPU0_TEMP_9 = 47,
  NPU1_TEMP_0 = 48,
  NPU1_TEMP_1 = 49,
  NPU1_TEMP_2 = 50,
  NPU1_TEMP_3 = 51,
  NPU1_TEMP_4 = 52,
  NPU1_TEMP_5 = 53,
  NPU1_TEMP_6 = 54,
  NPU1_TEMP_7 = 55,
  NPU1_TEMP_8 = 56,
  NPU1_TEMP_9 = 57,
  NPU1_TEMP_10 = 58,
  PSU2HSNK_Temp = 59,
  PSU2Inlet_Temp = 60,
  PSU2Outlet_Temp = 61,
  X86_CORE_4_T = 62,
  CPU_BRD_T = 63,
  CPU_CORE_TEMP_1_T = 64,
  CPU_CORE_TEMP_2_T = 65,
  CPU_CORE_TEMP_3_T = 66,
  CPU_CORE_TEMP_4_T = 67,
  CPU_CORE_TEMP_5_T = 68,
  CPU_CORE_TEMP_6_T = 69,
  CPU_CORE_TEMP_7_T = 70,
  CPU_CORE_TEMP_8_T = 71,
  CPU_PKG_TEMP_T = 72,
  FANIO_BRD_T = 73,
  MB_1_T = 74,
  MB_2_T = 75,
  MB_3_T = 76,
  NPU1_HBM_TEMP_1 = 77,
  NPU1_HBM_TEMP_2 = 78,
  SODIMM_1_TEMP_T = 79,
  SODIMM_2_TEMP_T = 80,
  SSD_TEMP_T = 81,
  XINDI_L_1_T = 82,
  XINDI_L_2_T = 83,
  XINDI_R_1_T = 84,
  XINDI_R_2_T = 85,
  UNRECOGNIZED = -1,
}

export function temperatureSensorsFromJSON(object: any): TemperatureSensors {
  switch (object) {
    case 0:
    case "INVALID_SENSOR":
      return TemperatureSensors.INVALID_SENSOR;
    case 1:
    case "ACPI":
      return TemperatureSensors.ACPI;
    case 2:
    case "CPU_U17_P1P05V_TEMP":
      return TemperatureSensors.CPU_U17_P1P05V_TEMP;
    case 3:
    case "CPU_U17_PVCCIN_TEMP":
      return TemperatureSensors.CPU_U17_PVCCIN_TEMP;
    case 4:
    case "CPU_U117_P1P2V_TEMP":
      return TemperatureSensors.CPU_U117_P1P2V_TEMP;
    case 5:
    case "CPU_U117_P1P05V_TEMP":
      return TemperatureSensors.CPU_U117_P1P05V_TEMP;
    case 6:
    case "DIMM_TEMP1":
      return TemperatureSensors.DIMM_TEMP1;
    case 7:
    case "DIMM_TEMP2":
      return TemperatureSensors.DIMM_TEMP2;
    case 8:
    case "FAN_Sensor":
      return TemperatureSensors.FAN_Sensor;
    case 9:
    case "MB_JM01_L1_TEMP":
      return TemperatureSensors.MB_JM01_L1_TEMP;
    case 10:
    case "MB_JM01_L2_TEMP":
      return TemperatureSensors.MB_JM01_L2_TEMP;
    case 11:
    case "MB_JM11_L1_TEMP":
      return TemperatureSensors.MB_JM11_L1_TEMP;
    case 12:
    case "MB_JM11_L2_TEMP":
      return TemperatureSensors.MB_JM11_L2_TEMP;
    case 13:
    case "MB_JM21_L1_TEMP":
      return TemperatureSensors.MB_JM21_L1_TEMP;
    case 14:
    case "MB_JM21_L2_TEMP":
      return TemperatureSensors.MB_JM21_L2_TEMP;
    case 15:
    case "MB_PORT_Sensor":
      return TemperatureSensors.MB_PORT_Sensor;
    case 16:
    case "MB_TMP421_Local":
      return TemperatureSensors.MB_TMP421_Local;
    case 17:
    case "PSU0HSNK_Temp":
      return TemperatureSensors.PSU0HSNK_Temp;
    case 18:
    case "PSU0Inlet_Temp":
      return TemperatureSensors.PSU0Inlet_Temp;
    case 19:
    case "PSU0Outlet_Temp":
      return TemperatureSensors.PSU0Outlet_Temp;
    case 20:
    case "PSU1HSNK_Temp":
      return TemperatureSensors.PSU1HSNK_Temp;
    case 21:
    case "PSU1Inlet_Temp":
      return TemperatureSensors.PSU1Inlet_Temp;
    case 22:
    case "PSU1Outlet_Temp":
      return TemperatureSensors.PSU1Outlet_Temp;
    case 23:
    case "SSD_Temp":
      return TemperatureSensors.SSD_Temp;
    case 24:
    case "X86_CORE_0_T":
      return TemperatureSensors.X86_CORE_0_T;
    case 25:
    case "X86_CORE_1_T":
      return TemperatureSensors.X86_CORE_1_T;
    case 26:
    case "X86_CORE_2_T":
      return TemperatureSensors.X86_CORE_2_T;
    case 27:
    case "X86_CORE_3_T":
      return TemperatureSensors.X86_CORE_3_T;
    case 28:
    case "X86_PKG_TEMP":
      return TemperatureSensors.X86_PKG_TEMP;
    case 29:
    case "MB_3_3V_R_VDDCK_TEMP_L1":
      return TemperatureSensors.MB_3_3V_R_VDDCK_TEMP_L1;
    case 30:
    case "MB_3_3V_R_VDDCK_TEMP_L2":
      return TemperatureSensors.MB_3_3V_R_VDDCK_TEMP_L2;
    case 31:
    case "MB_GB_CORE_TEMP_L1":
      return TemperatureSensors.MB_GB_CORE_TEMP_L1;
    case 32:
    case "MB_VDDS_VDDA_TEMP_L1":
      return TemperatureSensors.MB_VDDS_VDDA_TEMP_L1;
    case 33:
    case "MB_VDDS_VDDA_TEMP_L2":
      return TemperatureSensors.MB_VDDS_VDDA_TEMP_L2;
    case 34:
    case "PSU0HSNK_Temp1":
      return TemperatureSensors.PSU0HSNK_Temp1;
    case 35:
    case "PSU0HSNK_Temp2":
      return TemperatureSensors.PSU0HSNK_Temp2;
    case 36:
    case "PSU1HSNK_Temp1":
      return TemperatureSensors.PSU1HSNK_Temp1;
    case 37:
    case "PSU1HSNK_Temp2":
      return TemperatureSensors.PSU1HSNK_Temp2;
    case 38:
    case "NPU0_TEMP_0":
      return TemperatureSensors.NPU0_TEMP_0;
    case 39:
    case "NPU0_TEMP_1":
      return TemperatureSensors.NPU0_TEMP_1;
    case 40:
    case "NPU0_TEMP_2":
      return TemperatureSensors.NPU0_TEMP_2;
    case 41:
    case "NPU0_TEMP_3":
      return TemperatureSensors.NPU0_TEMP_3;
    case 42:
    case "NPU0_TEMP_4":
      return TemperatureSensors.NPU0_TEMP_4;
    case 43:
    case "NPU0_TEMP_5":
      return TemperatureSensors.NPU0_TEMP_5;
    case 44:
    case "NPU0_TEMP_6":
      return TemperatureSensors.NPU0_TEMP_6;
    case 45:
    case "NPU0_TEMP_7":
      return TemperatureSensors.NPU0_TEMP_7;
    case 46:
    case "NPU0_TEMP_8":
      return TemperatureSensors.NPU0_TEMP_8;
    case 47:
    case "NPU0_TEMP_9":
      return TemperatureSensors.NPU0_TEMP_9;
    case 48:
    case "NPU1_TEMP_0":
      return TemperatureSensors.NPU1_TEMP_0;
    case 49:
    case "NPU1_TEMP_1":
      return TemperatureSensors.NPU1_TEMP_1;
    case 50:
    case "NPU1_TEMP_2":
      return TemperatureSensors.NPU1_TEMP_2;
    case 51:
    case "NPU1_TEMP_3":
      return TemperatureSensors.NPU1_TEMP_3;
    case 52:
    case "NPU1_TEMP_4":
      return TemperatureSensors.NPU1_TEMP_4;
    case 53:
    case "NPU1_TEMP_5":
      return TemperatureSensors.NPU1_TEMP_5;
    case 54:
    case "NPU1_TEMP_6":
      return TemperatureSensors.NPU1_TEMP_6;
    case 55:
    case "NPU1_TEMP_7":
      return TemperatureSensors.NPU1_TEMP_7;
    case 56:
    case "NPU1_TEMP_8":
      return TemperatureSensors.NPU1_TEMP_8;
    case 57:
    case "NPU1_TEMP_9":
      return TemperatureSensors.NPU1_TEMP_9;
    case 58:
    case "NPU1_TEMP_10":
      return TemperatureSensors.NPU1_TEMP_10;
    case 59:
    case "PSU2HSNK_Temp":
      return TemperatureSensors.PSU2HSNK_Temp;
    case 60:
    case "PSU2Inlet_Temp":
      return TemperatureSensors.PSU2Inlet_Temp;
    case 61:
    case "PSU2Outlet_Temp":
      return TemperatureSensors.PSU2Outlet_Temp;
    case 62:
    case "X86_CORE_4_T":
      return TemperatureSensors.X86_CORE_4_T;
    case 63:
    case "CPU_BRD_T":
      return TemperatureSensors.CPU_BRD_T;
    case 64:
    case "CPU_CORE_TEMP_1_T":
      return TemperatureSensors.CPU_CORE_TEMP_1_T;
    case 65:
    case "CPU_CORE_TEMP_2_T":
      return TemperatureSensors.CPU_CORE_TEMP_2_T;
    case 66:
    case "CPU_CORE_TEMP_3_T":
      return TemperatureSensors.CPU_CORE_TEMP_3_T;
    case 67:
    case "CPU_CORE_TEMP_4_T":
      return TemperatureSensors.CPU_CORE_TEMP_4_T;
    case 68:
    case "CPU_CORE_TEMP_5_T":
      return TemperatureSensors.CPU_CORE_TEMP_5_T;
    case 69:
    case "CPU_CORE_TEMP_6_T":
      return TemperatureSensors.CPU_CORE_TEMP_6_T;
    case 70:
    case "CPU_CORE_TEMP_7_T":
      return TemperatureSensors.CPU_CORE_TEMP_7_T;
    case 71:
    case "CPU_CORE_TEMP_8_T":
      return TemperatureSensors.CPU_CORE_TEMP_8_T;
    case 72:
    case "CPU_PKG_TEMP_T":
      return TemperatureSensors.CPU_PKG_TEMP_T;
    case 73:
    case "FANIO_BRD_T":
      return TemperatureSensors.FANIO_BRD_T;
    case 74:
    case "MB_1_T":
      return TemperatureSensors.MB_1_T;
    case 75:
    case "MB_2_T":
      return TemperatureSensors.MB_2_T;
    case 76:
    case "MB_3_T":
      return TemperatureSensors.MB_3_T;
    case 77:
    case "NPU1_HBM_TEMP_1":
      return TemperatureSensors.NPU1_HBM_TEMP_1;
    case 78:
    case "NPU1_HBM_TEMP_2":
      return TemperatureSensors.NPU1_HBM_TEMP_2;
    case 79:
    case "SODIMM_1_TEMP_T":
      return TemperatureSensors.SODIMM_1_TEMP_T;
    case 80:
    case "SODIMM_2_TEMP_T":
      return TemperatureSensors.SODIMM_2_TEMP_T;
    case 81:
    case "SSD_TEMP_T":
      return TemperatureSensors.SSD_TEMP_T;
    case 82:
    case "XINDI_L_1_T":
      return TemperatureSensors.XINDI_L_1_T;
    case 83:
    case "XINDI_L_2_T":
      return TemperatureSensors.XINDI_L_2_T;
    case 84:
    case "XINDI_R_1_T":
      return TemperatureSensors.XINDI_R_1_T;
    case 85:
    case "XINDI_R_2_T":
      return TemperatureSensors.XINDI_R_2_T;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TemperatureSensors.UNRECOGNIZED;
  }
}

export function temperatureSensorsToJSON(object: TemperatureSensors): string {
  switch (object) {
    case TemperatureSensors.INVALID_SENSOR:
      return "INVALID_SENSOR";
    case TemperatureSensors.ACPI:
      return "ACPI";
    case TemperatureSensors.CPU_U17_P1P05V_TEMP:
      return "CPU_U17_P1P05V_TEMP";
    case TemperatureSensors.CPU_U17_PVCCIN_TEMP:
      return "CPU_U17_PVCCIN_TEMP";
    case TemperatureSensors.CPU_U117_P1P2V_TEMP:
      return "CPU_U117_P1P2V_TEMP";
    case TemperatureSensors.CPU_U117_P1P05V_TEMP:
      return "CPU_U117_P1P05V_TEMP";
    case TemperatureSensors.DIMM_TEMP1:
      return "DIMM_TEMP1";
    case TemperatureSensors.DIMM_TEMP2:
      return "DIMM_TEMP2";
    case TemperatureSensors.FAN_Sensor:
      return "FAN_Sensor";
    case TemperatureSensors.MB_JM01_L1_TEMP:
      return "MB_JM01_L1_TEMP";
    case TemperatureSensors.MB_JM01_L2_TEMP:
      return "MB_JM01_L2_TEMP";
    case TemperatureSensors.MB_JM11_L1_TEMP:
      return "MB_JM11_L1_TEMP";
    case TemperatureSensors.MB_JM11_L2_TEMP:
      return "MB_JM11_L2_TEMP";
    case TemperatureSensors.MB_JM21_L1_TEMP:
      return "MB_JM21_L1_TEMP";
    case TemperatureSensors.MB_JM21_L2_TEMP:
      return "MB_JM21_L2_TEMP";
    case TemperatureSensors.MB_PORT_Sensor:
      return "MB_PORT_Sensor";
    case TemperatureSensors.MB_TMP421_Local:
      return "MB_TMP421_Local";
    case TemperatureSensors.PSU0HSNK_Temp:
      return "PSU0HSNK_Temp";
    case TemperatureSensors.PSU0Inlet_Temp:
      return "PSU0Inlet_Temp";
    case TemperatureSensors.PSU0Outlet_Temp:
      return "PSU0Outlet_Temp";
    case TemperatureSensors.PSU1HSNK_Temp:
      return "PSU1HSNK_Temp";
    case TemperatureSensors.PSU1Inlet_Temp:
      return "PSU1Inlet_Temp";
    case TemperatureSensors.PSU1Outlet_Temp:
      return "PSU1Outlet_Temp";
    case TemperatureSensors.SSD_Temp:
      return "SSD_Temp";
    case TemperatureSensors.X86_CORE_0_T:
      return "X86_CORE_0_T";
    case TemperatureSensors.X86_CORE_1_T:
      return "X86_CORE_1_T";
    case TemperatureSensors.X86_CORE_2_T:
      return "X86_CORE_2_T";
    case TemperatureSensors.X86_CORE_3_T:
      return "X86_CORE_3_T";
    case TemperatureSensors.X86_PKG_TEMP:
      return "X86_PKG_TEMP";
    case TemperatureSensors.MB_3_3V_R_VDDCK_TEMP_L1:
      return "MB_3_3V_R_VDDCK_TEMP_L1";
    case TemperatureSensors.MB_3_3V_R_VDDCK_TEMP_L2:
      return "MB_3_3V_R_VDDCK_TEMP_L2";
    case TemperatureSensors.MB_GB_CORE_TEMP_L1:
      return "MB_GB_CORE_TEMP_L1";
    case TemperatureSensors.MB_VDDS_VDDA_TEMP_L1:
      return "MB_VDDS_VDDA_TEMP_L1";
    case TemperatureSensors.MB_VDDS_VDDA_TEMP_L2:
      return "MB_VDDS_VDDA_TEMP_L2";
    case TemperatureSensors.PSU0HSNK_Temp1:
      return "PSU0HSNK_Temp1";
    case TemperatureSensors.PSU0HSNK_Temp2:
      return "PSU0HSNK_Temp2";
    case TemperatureSensors.PSU1HSNK_Temp1:
      return "PSU1HSNK_Temp1";
    case TemperatureSensors.PSU1HSNK_Temp2:
      return "PSU1HSNK_Temp2";
    case TemperatureSensors.NPU0_TEMP_0:
      return "NPU0_TEMP_0";
    case TemperatureSensors.NPU0_TEMP_1:
      return "NPU0_TEMP_1";
    case TemperatureSensors.NPU0_TEMP_2:
      return "NPU0_TEMP_2";
    case TemperatureSensors.NPU0_TEMP_3:
      return "NPU0_TEMP_3";
    case TemperatureSensors.NPU0_TEMP_4:
      return "NPU0_TEMP_4";
    case TemperatureSensors.NPU0_TEMP_5:
      return "NPU0_TEMP_5";
    case TemperatureSensors.NPU0_TEMP_6:
      return "NPU0_TEMP_6";
    case TemperatureSensors.NPU0_TEMP_7:
      return "NPU0_TEMP_7";
    case TemperatureSensors.NPU0_TEMP_8:
      return "NPU0_TEMP_8";
    case TemperatureSensors.NPU0_TEMP_9:
      return "NPU0_TEMP_9";
    case TemperatureSensors.NPU1_TEMP_0:
      return "NPU1_TEMP_0";
    case TemperatureSensors.NPU1_TEMP_1:
      return "NPU1_TEMP_1";
    case TemperatureSensors.NPU1_TEMP_2:
      return "NPU1_TEMP_2";
    case TemperatureSensors.NPU1_TEMP_3:
      return "NPU1_TEMP_3";
    case TemperatureSensors.NPU1_TEMP_4:
      return "NPU1_TEMP_4";
    case TemperatureSensors.NPU1_TEMP_5:
      return "NPU1_TEMP_5";
    case TemperatureSensors.NPU1_TEMP_6:
      return "NPU1_TEMP_6";
    case TemperatureSensors.NPU1_TEMP_7:
      return "NPU1_TEMP_7";
    case TemperatureSensors.NPU1_TEMP_8:
      return "NPU1_TEMP_8";
    case TemperatureSensors.NPU1_TEMP_9:
      return "NPU1_TEMP_9";
    case TemperatureSensors.NPU1_TEMP_10:
      return "NPU1_TEMP_10";
    case TemperatureSensors.PSU2HSNK_Temp:
      return "PSU2HSNK_Temp";
    case TemperatureSensors.PSU2Inlet_Temp:
      return "PSU2Inlet_Temp";
    case TemperatureSensors.PSU2Outlet_Temp:
      return "PSU2Outlet_Temp";
    case TemperatureSensors.X86_CORE_4_T:
      return "X86_CORE_4_T";
    case TemperatureSensors.CPU_BRD_T:
      return "CPU_BRD_T";
    case TemperatureSensors.CPU_CORE_TEMP_1_T:
      return "CPU_CORE_TEMP_1_T";
    case TemperatureSensors.CPU_CORE_TEMP_2_T:
      return "CPU_CORE_TEMP_2_T";
    case TemperatureSensors.CPU_CORE_TEMP_3_T:
      return "CPU_CORE_TEMP_3_T";
    case TemperatureSensors.CPU_CORE_TEMP_4_T:
      return "CPU_CORE_TEMP_4_T";
    case TemperatureSensors.CPU_CORE_TEMP_5_T:
      return "CPU_CORE_TEMP_5_T";
    case TemperatureSensors.CPU_CORE_TEMP_6_T:
      return "CPU_CORE_TEMP_6_T";
    case TemperatureSensors.CPU_CORE_TEMP_7_T:
      return "CPU_CORE_TEMP_7_T";
    case TemperatureSensors.CPU_CORE_TEMP_8_T:
      return "CPU_CORE_TEMP_8_T";
    case TemperatureSensors.CPU_PKG_TEMP_T:
      return "CPU_PKG_TEMP_T";
    case TemperatureSensors.FANIO_BRD_T:
      return "FANIO_BRD_T";
    case TemperatureSensors.MB_1_T:
      return "MB_1_T";
    case TemperatureSensors.MB_2_T:
      return "MB_2_T";
    case TemperatureSensors.MB_3_T:
      return "MB_3_T";
    case TemperatureSensors.NPU1_HBM_TEMP_1:
      return "NPU1_HBM_TEMP_1";
    case TemperatureSensors.NPU1_HBM_TEMP_2:
      return "NPU1_HBM_TEMP_2";
    case TemperatureSensors.SODIMM_1_TEMP_T:
      return "SODIMM_1_TEMP_T";
    case TemperatureSensors.SODIMM_2_TEMP_T:
      return "SODIMM_2_TEMP_T";
    case TemperatureSensors.SSD_TEMP_T:
      return "SSD_TEMP_T";
    case TemperatureSensors.XINDI_L_1_T:
      return "XINDI_L_1_T";
    case TemperatureSensors.XINDI_L_2_T:
      return "XINDI_L_2_T";
    case TemperatureSensors.XINDI_R_1_T:
      return "XINDI_R_1_T";
    case TemperatureSensors.XINDI_R_2_T:
      return "XINDI_R_2_T";
    case TemperatureSensors.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HostName {
  HOST_NAME_UNSPECIFIED = 0,
  UNRECOGNIZED = -1,
}

export function hostNameFromJSON(object: any): HostName {
  switch (object) {
    case 0:
    case "HOST_NAME_UNSPECIFIED":
      return HostName.HOST_NAME_UNSPECIFIED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HostName.UNRECOGNIZED;
  }
}

export function hostNameToJSON(object: HostName): string {
  switch (object) {
    case HostName.HOST_NAME_UNSPECIFIED:
      return "HOST_NAME_UNSPECIFIED";
    case HostName.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FabricAssignmentState {
  AssignmentStateUnknown = 0,
  AssignmentStateStandby = 1,
  AssignmentStateActive = 2,
  AssignmentStateActivePending = 3,
  AssignmentStateStandbyPending = 4,
  AssignmentStateDemoted = 5,
  AssignmentStatePromoted = 6,
  UNRECOGNIZED = -1,
}

export function fabricAssignmentStateFromJSON(object: any): FabricAssignmentState {
  switch (object) {
    case 0:
    case "AssignmentStateUnknown":
      return FabricAssignmentState.AssignmentStateUnknown;
    case 1:
    case "AssignmentStateStandby":
      return FabricAssignmentState.AssignmentStateStandby;
    case 2:
    case "AssignmentStateActive":
      return FabricAssignmentState.AssignmentStateActive;
    case 3:
    case "AssignmentStateActivePending":
      return FabricAssignmentState.AssignmentStateActivePending;
    case 4:
    case "AssignmentStateStandbyPending":
      return FabricAssignmentState.AssignmentStateStandbyPending;
    case 5:
    case "AssignmentStateDemoted":
      return FabricAssignmentState.AssignmentStateDemoted;
    case 6:
    case "AssignmentStatePromoted":
      return FabricAssignmentState.AssignmentStatePromoted;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricAssignmentState.UNRECOGNIZED;
  }
}

export function fabricAssignmentStateToJSON(object: FabricAssignmentState): string {
  switch (object) {
    case FabricAssignmentState.AssignmentStateUnknown:
      return "AssignmentStateUnknown";
    case FabricAssignmentState.AssignmentStateStandby:
      return "AssignmentStateStandby";
    case FabricAssignmentState.AssignmentStateActive:
      return "AssignmentStateActive";
    case FabricAssignmentState.AssignmentStateActivePending:
      return "AssignmentStateActivePending";
    case FabricAssignmentState.AssignmentStateStandbyPending:
      return "AssignmentStateStandbyPending";
    case FabricAssignmentState.AssignmentStateDemoted:
      return "AssignmentStateDemoted";
    case FabricAssignmentState.AssignmentStatePromoted:
      return "AssignmentStatePromoted";
    case FabricAssignmentState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FabricEvent {
  FABRIC_EVENT_UNSPECIFIED = 0,
  FABRIC_EVENT_ADD_DEVICE = 1,
  FABRIC_EVENT_REMOVE_DEVICE = 2,
  FABRIC_EVENT_BIND_DEVICE = 3,
  FABRIC_EVENT_UNBIND_DEVICE = 4,
  UNRECOGNIZED = -1,
}

export function fabricEventFromJSON(object: any): FabricEvent {
  switch (object) {
    case 0:
    case "FABRIC_EVENT_UNSPECIFIED":
      return FabricEvent.FABRIC_EVENT_UNSPECIFIED;
    case 1:
    case "FABRIC_EVENT_ADD_DEVICE":
      return FabricEvent.FABRIC_EVENT_ADD_DEVICE;
    case 2:
    case "FABRIC_EVENT_REMOVE_DEVICE":
      return FabricEvent.FABRIC_EVENT_REMOVE_DEVICE;
    case 3:
    case "FABRIC_EVENT_BIND_DEVICE":
      return FabricEvent.FABRIC_EVENT_BIND_DEVICE;
    case 4:
    case "FABRIC_EVENT_UNBIND_DEVICE":
      return FabricEvent.FABRIC_EVENT_UNBIND_DEVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricEvent.UNRECOGNIZED;
  }
}

export function fabricEventToJSON(object: FabricEvent): string {
  switch (object) {
    case FabricEvent.FABRIC_EVENT_UNSPECIFIED:
      return "FABRIC_EVENT_UNSPECIFIED";
    case FabricEvent.FABRIC_EVENT_ADD_DEVICE:
      return "FABRIC_EVENT_ADD_DEVICE";
    case FabricEvent.FABRIC_EVENT_REMOVE_DEVICE:
      return "FABRIC_EVENT_REMOVE_DEVICE";
    case FabricEvent.FABRIC_EVENT_BIND_DEVICE:
      return "FABRIC_EVENT_BIND_DEVICE";
    case FabricEvent.FABRIC_EVENT_UNBIND_DEVICE:
      return "FABRIC_EVENT_UNBIND_DEVICE";
    case FabricEvent.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EnvStatus {
  ENV_STATUS_UNSPECIFIED = 0,
  OK = 1,
  NOT_OK = 2,
  WARNING = 3,
  NOT_PRESENT = 4,
  UNRECOGNIZED = -1,
}

export function envStatusFromJSON(object: any): EnvStatus {
  switch (object) {
    case 0:
    case "ENV_STATUS_UNSPECIFIED":
      return EnvStatus.ENV_STATUS_UNSPECIFIED;
    case 1:
    case "OK":
      return EnvStatus.OK;
    case 2:
    case "NOT_OK":
      return EnvStatus.NOT_OK;
    case 3:
    case "WARNING":
      return EnvStatus.WARNING;
    case 4:
    case "NOT_PRESENT":
      return EnvStatus.NOT_PRESENT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnvStatus.UNRECOGNIZED;
  }
}

export function envStatusToJSON(object: EnvStatus): string {
  switch (object) {
    case EnvStatus.ENV_STATUS_UNSPECIFIED:
      return "ENV_STATUS_UNSPECIFIED";
    case EnvStatus.OK:
      return "OK";
    case EnvStatus.NOT_OK:
      return "NOT_OK";
    case EnvStatus.WARNING:
      return "WARNING";
    case EnvStatus.NOT_PRESENT:
      return "NOT_PRESENT";
    case EnvStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum EnvLedStatus {
  ENV_LED_STATUS_UNSPECIFIED = 0,
  GREEN = 1,
  RED = 2,
  OFF = 3,
  UNRECOGNIZED = -1,
}

export function envLedStatusFromJSON(object: any): EnvLedStatus {
  switch (object) {
    case 0:
    case "ENV_LED_STATUS_UNSPECIFIED":
      return EnvLedStatus.ENV_LED_STATUS_UNSPECIFIED;
    case 1:
    case "GREEN":
      return EnvLedStatus.GREEN;
    case 2:
    case "RED":
      return EnvLedStatus.RED;
    case 3:
    case "OFF":
      return EnvLedStatus.OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return EnvLedStatus.UNRECOGNIZED;
  }
}

export function envLedStatusToJSON(object: EnvLedStatus): string {
  switch (object) {
    case EnvLedStatus.ENV_LED_STATUS_UNSPECIFIED:
      return "ENV_LED_STATUS_UNSPECIFIED";
    case EnvLedStatus.GREEN:
      return "GREEN";
    case EnvLedStatus.RED:
      return "RED";
    case EnvLedStatus.OFF:
      return "OFF";
    case EnvLedStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum FanDirection {
  FAN_DIRECTION_UNSPECIFIED = 0,
  INTAKE = 1,
  EXHAUST = 2,
  UNRECOGNIZED = -1,
}

export function fanDirectionFromJSON(object: any): FanDirection {
  switch (object) {
    case 0:
    case "FAN_DIRECTION_UNSPECIFIED":
      return FanDirection.FAN_DIRECTION_UNSPECIFIED;
    case 1:
    case "INTAKE":
      return FanDirection.INTAKE;
    case 2:
    case "EXHAUST":
      return FanDirection.EXHAUST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FanDirection.UNRECOGNIZED;
  }
}

export function fanDirectionToJSON(object: FanDirection): string {
  switch (object) {
    case FanDirection.FAN_DIRECTION_UNSPECIFIED:
      return "FAN_DIRECTION_UNSPECIFIED";
    case FanDirection.INTAKE:
      return "INTAKE";
    case FanDirection.EXHAUST:
      return "EXHAUST";
    case FanDirection.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** DEPRECATED */
export enum LLDPSysCapabilityType {
  LLDP_SYS_CAPABILITY_TYPE_UNSPECIFIED = 0,
  REPEATER = 1,
  BRIDGE = 2,
  WLAN = 3,
  ROUTER = 4,
  TELEPHONE = 5,
  DOCSIS_CABLE_DEVICE = 6,
  STATION = 7,
  UNRECOGNIZED = -1,
}

export function lLDPSysCapabilityTypeFromJSON(object: any): LLDPSysCapabilityType {
  switch (object) {
    case 0:
    case "LLDP_SYS_CAPABILITY_TYPE_UNSPECIFIED":
      return LLDPSysCapabilityType.LLDP_SYS_CAPABILITY_TYPE_UNSPECIFIED;
    case 1:
    case "REPEATER":
      return LLDPSysCapabilityType.REPEATER;
    case 2:
    case "BRIDGE":
      return LLDPSysCapabilityType.BRIDGE;
    case 3:
    case "WLAN":
      return LLDPSysCapabilityType.WLAN;
    case 4:
    case "ROUTER":
      return LLDPSysCapabilityType.ROUTER;
    case 5:
    case "TELEPHONE":
      return LLDPSysCapabilityType.TELEPHONE;
    case 6:
    case "DOCSIS_CABLE_DEVICE":
      return LLDPSysCapabilityType.DOCSIS_CABLE_DEVICE;
    case 7:
    case "STATION":
      return LLDPSysCapabilityType.STATION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LLDPSysCapabilityType.UNRECOGNIZED;
  }
}

export function lLDPSysCapabilityTypeToJSON(object: LLDPSysCapabilityType): string {
  switch (object) {
    case LLDPSysCapabilityType.LLDP_SYS_CAPABILITY_TYPE_UNSPECIFIED:
      return "LLDP_SYS_CAPABILITY_TYPE_UNSPECIFIED";
    case LLDPSysCapabilityType.REPEATER:
      return "REPEATER";
    case LLDPSysCapabilityType.BRIDGE:
      return "BRIDGE";
    case LLDPSysCapabilityType.WLAN:
      return "WLAN";
    case LLDPSysCapabilityType.ROUTER:
      return "ROUTER";
    case LLDPSysCapabilityType.TELEPHONE:
      return "TELEPHONE";
    case LLDPSysCapabilityType.DOCSIS_CABLE_DEVICE:
      return "DOCSIS_CABLE_DEVICE";
    case LLDPSysCapabilityType.STATION:
      return "STATION";
    case LLDPSysCapabilityType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LLDPRemoteDeviceHostSubType {
  LLDP_REMOTE_DEVICE_HOST_SUB_TYPE_UNSPECIFIED = 0,
  IF_ALIAS = 1,
  LOCAL = 2,
  MAC = 3,
  IP = 4,
  IFNAME = 5,
  UNRECOGNIZED = -1,
}

export function lLDPRemoteDeviceHostSubTypeFromJSON(object: any): LLDPRemoteDeviceHostSubType {
  switch (object) {
    case 0:
    case "LLDP_REMOTE_DEVICE_HOST_SUB_TYPE_UNSPECIFIED":
      return LLDPRemoteDeviceHostSubType.LLDP_REMOTE_DEVICE_HOST_SUB_TYPE_UNSPECIFIED;
    case 1:
    case "IF_ALIAS":
      return LLDPRemoteDeviceHostSubType.IF_ALIAS;
    case 2:
    case "LOCAL":
      return LLDPRemoteDeviceHostSubType.LOCAL;
    case 3:
    case "MAC":
      return LLDPRemoteDeviceHostSubType.MAC;
    case 4:
    case "IP":
      return LLDPRemoteDeviceHostSubType.IP;
    case 5:
    case "IFNAME":
      return LLDPRemoteDeviceHostSubType.IFNAME;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LLDPRemoteDeviceHostSubType.UNRECOGNIZED;
  }
}

export function lLDPRemoteDeviceHostSubTypeToJSON(object: LLDPRemoteDeviceHostSubType): string {
  switch (object) {
    case LLDPRemoteDeviceHostSubType.LLDP_REMOTE_DEVICE_HOST_SUB_TYPE_UNSPECIFIED:
      return "LLDP_REMOTE_DEVICE_HOST_SUB_TYPE_UNSPECIFIED";
    case LLDPRemoteDeviceHostSubType.IF_ALIAS:
      return "IF_ALIAS";
    case LLDPRemoteDeviceHostSubType.LOCAL:
      return "LOCAL";
    case LLDPRemoteDeviceHostSubType.MAC:
      return "MAC";
    case LLDPRemoteDeviceHostSubType.IP:
      return "IP";
    case LLDPRemoteDeviceHostSubType.IFNAME:
      return "IFNAME";
    case LLDPRemoteDeviceHostSubType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum L3TableEntrySource {
  L3_TABLE_IP_SOURCE_UNSPECIFIED = 0,
  ARP = 1,
  ND = 2,
  UNRECOGNIZED = -1,
}

export function l3TableEntrySourceFromJSON(object: any): L3TableEntrySource {
  switch (object) {
    case 0:
    case "L3_TABLE_IP_SOURCE_UNSPECIFIED":
      return L3TableEntrySource.L3_TABLE_IP_SOURCE_UNSPECIFIED;
    case 1:
    case "ARP":
      return L3TableEntrySource.ARP;
    case 2:
    case "ND":
      return L3TableEntrySource.ND;
    case -1:
    case "UNRECOGNIZED":
    default:
      return L3TableEntrySource.UNRECOGNIZED;
  }
}

export function l3TableEntrySourceToJSON(object: L3TableEntrySource): string {
  switch (object) {
    case L3TableEntrySource.L3_TABLE_IP_SOURCE_UNSPECIFIED:
      return "L3_TABLE_IP_SOURCE_UNSPECIFIED";
    case L3TableEntrySource.ARP:
      return "ARP";
    case L3TableEntrySource.ND:
      return "ND";
    case L3TableEntrySource.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BgpIdType {
  BGP_ID_TYPE_UNSPECIFIED = 0,
  IPV6 = 1,
  IPV4 = 2,
  INTERFACE = 3,
  UNRECOGNIZED = -1,
}

export function bgpIdTypeFromJSON(object: any): BgpIdType {
  switch (object) {
    case 0:
    case "BGP_ID_TYPE_UNSPECIFIED":
      return BgpIdType.BGP_ID_TYPE_UNSPECIFIED;
    case 1:
    case "IPV6":
      return BgpIdType.IPV6;
    case 2:
    case "IPV4":
      return BgpIdType.IPV4;
    case 3:
    case "INTERFACE":
      return BgpIdType.INTERFACE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BgpIdType.UNRECOGNIZED;
  }
}

export function bgpIdTypeToJSON(object: BgpIdType): string {
  switch (object) {
    case BgpIdType.BGP_ID_TYPE_UNSPECIFIED:
      return "BGP_ID_TYPE_UNSPECIFIED";
    case BgpIdType.IPV6:
      return "IPV6";
    case BgpIdType.IPV4:
      return "IPV4";
    case BgpIdType.INTERFACE:
      return "INTERFACE";
    case BgpIdType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BgpPeerConnectionState {
  BGP_PEER_CONNECTION_STATE_UNSPECIFIED = 0,
  ESTABLISHED = 1,
  NOT_ESTABLISHED = 2,
  UNRECOGNIZED = -1,
}

export function bgpPeerConnectionStateFromJSON(object: any): BgpPeerConnectionState {
  switch (object) {
    case 0:
    case "BGP_PEER_CONNECTION_STATE_UNSPECIFIED":
      return BgpPeerConnectionState.BGP_PEER_CONNECTION_STATE_UNSPECIFIED;
    case 1:
    case "ESTABLISHED":
      return BgpPeerConnectionState.ESTABLISHED;
    case 2:
    case "NOT_ESTABLISHED":
      return BgpPeerConnectionState.NOT_ESTABLISHED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BgpPeerConnectionState.UNRECOGNIZED;
  }
}

export function bgpPeerConnectionStateToJSON(object: BgpPeerConnectionState): string {
  switch (object) {
    case BgpPeerConnectionState.BGP_PEER_CONNECTION_STATE_UNSPECIFIED:
      return "BGP_PEER_CONNECTION_STATE_UNSPECIFIED";
    case BgpPeerConnectionState.ESTABLISHED:
      return "ESTABLISHED";
    case BgpPeerConnectionState.NOT_ESTABLISHED:
      return "NOT_ESTABLISHED";
    case BgpPeerConnectionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BgpPeerState {
  BGP_PEER_STATE_UNSPECIFIED = 0,
  PEER_STATE_OK = 1,
  PEER_STATE_NOT_OK = 2,
  UNRECOGNIZED = -1,
}

export function bgpPeerStateFromJSON(object: any): BgpPeerState {
  switch (object) {
    case 0:
    case "BGP_PEER_STATE_UNSPECIFIED":
      return BgpPeerState.BGP_PEER_STATE_UNSPECIFIED;
    case 1:
    case "PEER_STATE_OK":
      return BgpPeerState.PEER_STATE_OK;
    case 2:
    case "PEER_STATE_NOT_OK":
      return BgpPeerState.PEER_STATE_NOT_OK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BgpPeerState.UNRECOGNIZED;
  }
}

export function bgpPeerStateToJSON(object: BgpPeerState): string {
  switch (object) {
    case BgpPeerState.BGP_PEER_STATE_UNSPECIFIED:
      return "BGP_PEER_STATE_UNSPECIFIED";
    case BgpPeerState.PEER_STATE_OK:
      return "PEER_STATE_OK";
    case BgpPeerState.PEER_STATE_NOT_OK:
      return "PEER_STATE_NOT_OK";
    case BgpPeerState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum BgpAddressFamily {
  BGP_ADDRESS_FAMILY_UNSPECIFIED = 0,
  IPV6_UNICAST = 1,
  L2_VPN_EVPN = 2,
  IPV4_UNICAST = 3,
  UNRECOGNIZED = -1,
}

export function bgpAddressFamilyFromJSON(object: any): BgpAddressFamily {
  switch (object) {
    case 0:
    case "BGP_ADDRESS_FAMILY_UNSPECIFIED":
      return BgpAddressFamily.BGP_ADDRESS_FAMILY_UNSPECIFIED;
    case 1:
    case "IPV6_UNICAST":
      return BgpAddressFamily.IPV6_UNICAST;
    case 2:
    case "L2_VPN_EVPN":
      return BgpAddressFamily.L2_VPN_EVPN;
    case 3:
    case "IPV4_UNICAST":
      return BgpAddressFamily.IPV4_UNICAST;
    case -1:
    case "UNRECOGNIZED":
    default:
      return BgpAddressFamily.UNRECOGNIZED;
  }
}

export function bgpAddressFamilyToJSON(object: BgpAddressFamily): string {
  switch (object) {
    case BgpAddressFamily.BGP_ADDRESS_FAMILY_UNSPECIFIED:
      return "BGP_ADDRESS_FAMILY_UNSPECIFIED";
    case BgpAddressFamily.IPV6_UNICAST:
      return "IPV6_UNICAST";
    case BgpAddressFamily.L2_VPN_EVPN:
      return "L2_VPN_EVPN";
    case BgpAddressFamily.IPV4_UNICAST:
      return "IPV4_UNICAST";
    case BgpAddressFamily.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PlannedConfigPhase {
  CONFIG_PHASE_UNSPECIFIED = 0,
  CONFIG_PHASE_ACK = 1,
  CONFIG_PHASE_RECEIVING = 2,
  CONFIG_PHASE_APPLYING = 3,
  CONFIG_PHASE_COMPLETED = 4,
  UNRECOGNIZED = -1,
}

export function plannedConfigPhaseFromJSON(object: any): PlannedConfigPhase {
  switch (object) {
    case 0:
    case "CONFIG_PHASE_UNSPECIFIED":
      return PlannedConfigPhase.CONFIG_PHASE_UNSPECIFIED;
    case 1:
    case "CONFIG_PHASE_ACK":
      return PlannedConfigPhase.CONFIG_PHASE_ACK;
    case 2:
    case "CONFIG_PHASE_RECEIVING":
      return PlannedConfigPhase.CONFIG_PHASE_RECEIVING;
    case 3:
    case "CONFIG_PHASE_APPLYING":
      return PlannedConfigPhase.CONFIG_PHASE_APPLYING;
    case 4:
    case "CONFIG_PHASE_COMPLETED":
      return PlannedConfigPhase.CONFIG_PHASE_COMPLETED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PlannedConfigPhase.UNRECOGNIZED;
  }
}

export function plannedConfigPhaseToJSON(object: PlannedConfigPhase): string {
  switch (object) {
    case PlannedConfigPhase.CONFIG_PHASE_UNSPECIFIED:
      return "CONFIG_PHASE_UNSPECIFIED";
    case PlannedConfigPhase.CONFIG_PHASE_ACK:
      return "CONFIG_PHASE_ACK";
    case PlannedConfigPhase.CONFIG_PHASE_RECEIVING:
      return "CONFIG_PHASE_RECEIVING";
    case PlannedConfigPhase.CONFIG_PHASE_APPLYING:
      return "CONFIG_PHASE_APPLYING";
    case PlannedConfigPhase.CONFIG_PHASE_COMPLETED:
      return "CONFIG_PHASE_COMPLETED";
    case PlannedConfigPhase.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TLSVerifyError {
  /** ERROR_OK - No error. Verification is successful */
  ERROR_OK = 0,
  /** UNKNOWN_ERROR - Unknown Error. Should not happen */
  UNKNOWN_ERROR = 1,
  /** NOT_AUTHORIZED_TO_SIGN - results when a certificate is signed by another which isn't marked as a CA certificate. */
  NOT_AUTHORIZED_TO_SIGN = 2,
  /** CERTIFICATE_EXPIRED - results when a certificate has expired, based on the time in the VerifyOptions. */
  CERTIFICATE_EXPIRED = 3,
  /** CA_NOT_AUTHORIZED_FOR_THIS_NAME - results when an intermediate or root certificate has a name constraint which doesn't permit a DNS or other name (including IP address) in the leaf certificate. */
  CA_NOT_AUTHORIZED_FOR_THIS_NAME = 4,
  /** TOO_MANY_INTERMEDIATES - results when a path length constraint is violated. */
  TOO_MANY_INTERMEDIATES = 5,
  /** INCOMPATIBLE_USAGE - results when the certificate's key usage indicates that it may only be used for a different purpose. */
  INCOMPATIBLE_USAGE = 6,
  /** NAME_MISMATCH - results when the subject name of a parent certificate does not match the issuer name in the child. */
  NAME_MISMATCH = 7,
  /** NAME_CONSTRAINTS_WITHOUT_SANS - is a legacy error and is no longer returned. */
  NAME_CONSTRAINTS_WITHOUT_SANS = 8,
  /** UNCONSTRAINED_NAME - results when a CA certificate contains permitted name constraints, but leaf certificate contains a name of an unsupported or unconstrained type. */
  UNCONSTRAINED_NAME = 9,
  /** TOO_MANY_CONSTRAINTS - results when the number of comparison operations needed to check a certificate exceeds the limit set by VerifyOptions.MaxConstraintComparisions. This limit exists to prevent pathological certificates can consuming excessive amounts of CPU time to verify. */
  TOO_MANY_CONSTRAINTS = 10,
  /** CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE - results when an intermediate or root certificate does not permit a requested extended key usage. */
  CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE = 11,
  /** HOST_NAME_ERROR - results when the set of authorized names doesn't match the requested name. */
  HOST_NAME_ERROR = 12,
  /** UNKNOWN_AUTHORITY_ERROR - results when the certificate issuer is unknown */
  UNKNOWN_AUTHORITY_ERROR = 13,
  /** SYSTEM_ROOTS_ERROR - results when we fail to load the system root certificates. */
  SYSTEM_ROOTS_ERROR = 14,
  UNRECOGNIZED = -1,
}

export function tLSVerifyErrorFromJSON(object: any): TLSVerifyError {
  switch (object) {
    case 0:
    case "ERROR_OK":
      return TLSVerifyError.ERROR_OK;
    case 1:
    case "UNKNOWN_ERROR":
      return TLSVerifyError.UNKNOWN_ERROR;
    case 2:
    case "NOT_AUTHORIZED_TO_SIGN":
      return TLSVerifyError.NOT_AUTHORIZED_TO_SIGN;
    case 3:
    case "CERTIFICATE_EXPIRED":
      return TLSVerifyError.CERTIFICATE_EXPIRED;
    case 4:
    case "CA_NOT_AUTHORIZED_FOR_THIS_NAME":
      return TLSVerifyError.CA_NOT_AUTHORIZED_FOR_THIS_NAME;
    case 5:
    case "TOO_MANY_INTERMEDIATES":
      return TLSVerifyError.TOO_MANY_INTERMEDIATES;
    case 6:
    case "INCOMPATIBLE_USAGE":
      return TLSVerifyError.INCOMPATIBLE_USAGE;
    case 7:
    case "NAME_MISMATCH":
      return TLSVerifyError.NAME_MISMATCH;
    case 8:
    case "NAME_CONSTRAINTS_WITHOUT_SANS":
      return TLSVerifyError.NAME_CONSTRAINTS_WITHOUT_SANS;
    case 9:
    case "UNCONSTRAINED_NAME":
      return TLSVerifyError.UNCONSTRAINED_NAME;
    case 10:
    case "TOO_MANY_CONSTRAINTS":
      return TLSVerifyError.TOO_MANY_CONSTRAINTS;
    case 11:
    case "CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE":
      return TLSVerifyError.CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE;
    case 12:
    case "HOST_NAME_ERROR":
      return TLSVerifyError.HOST_NAME_ERROR;
    case 13:
    case "UNKNOWN_AUTHORITY_ERROR":
      return TLSVerifyError.UNKNOWN_AUTHORITY_ERROR;
    case 14:
    case "SYSTEM_ROOTS_ERROR":
      return TLSVerifyError.SYSTEM_ROOTS_ERROR;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TLSVerifyError.UNRECOGNIZED;
  }
}

export function tLSVerifyErrorToJSON(object: TLSVerifyError): string {
  switch (object) {
    case TLSVerifyError.ERROR_OK:
      return "ERROR_OK";
    case TLSVerifyError.UNKNOWN_ERROR:
      return "UNKNOWN_ERROR";
    case TLSVerifyError.NOT_AUTHORIZED_TO_SIGN:
      return "NOT_AUTHORIZED_TO_SIGN";
    case TLSVerifyError.CERTIFICATE_EXPIRED:
      return "CERTIFICATE_EXPIRED";
    case TLSVerifyError.CA_NOT_AUTHORIZED_FOR_THIS_NAME:
      return "CA_NOT_AUTHORIZED_FOR_THIS_NAME";
    case TLSVerifyError.TOO_MANY_INTERMEDIATES:
      return "TOO_MANY_INTERMEDIATES";
    case TLSVerifyError.INCOMPATIBLE_USAGE:
      return "INCOMPATIBLE_USAGE";
    case TLSVerifyError.NAME_MISMATCH:
      return "NAME_MISMATCH";
    case TLSVerifyError.NAME_CONSTRAINTS_WITHOUT_SANS:
      return "NAME_CONSTRAINTS_WITHOUT_SANS";
    case TLSVerifyError.UNCONSTRAINED_NAME:
      return "UNCONSTRAINED_NAME";
    case TLSVerifyError.TOO_MANY_CONSTRAINTS:
      return "TOO_MANY_CONSTRAINTS";
    case TLSVerifyError.CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE:
      return "CA_NOT_AUTHORIZED_FOR_EXTKEYUSAGE";
    case TLSVerifyError.HOST_NAME_ERROR:
      return "HOST_NAME_ERROR";
    case TLSVerifyError.UNKNOWN_AUTHORITY_ERROR:
      return "UNKNOWN_AUTHORITY_ERROR";
    case TLSVerifyError.SYSTEM_ROOTS_ERROR:
      return "SYSTEM_ROOTS_ERROR";
    case TLSVerifyError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TlsConnectionState {
  TLS_CONNECTION_STATE_UNSPECIFIED = 0,
  CONNECTING = 1,
  CONNECTED = 2,
  DISCONNECTED = 3,
  CONNECTION_FAILED = 4,
  UNRECOGNIZED = -1,
}

export function tlsConnectionStateFromJSON(object: any): TlsConnectionState {
  switch (object) {
    case 0:
    case "TLS_CONNECTION_STATE_UNSPECIFIED":
      return TlsConnectionState.TLS_CONNECTION_STATE_UNSPECIFIED;
    case 1:
    case "CONNECTING":
      return TlsConnectionState.CONNECTING;
    case 2:
    case "CONNECTED":
      return TlsConnectionState.CONNECTED;
    case 3:
    case "DISCONNECTED":
      return TlsConnectionState.DISCONNECTED;
    case 4:
    case "CONNECTION_FAILED":
      return TlsConnectionState.CONNECTION_FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TlsConnectionState.UNRECOGNIZED;
  }
}

export function tlsConnectionStateToJSON(object: TlsConnectionState): string {
  switch (object) {
    case TlsConnectionState.TLS_CONNECTION_STATE_UNSPECIFIED:
      return "TLS_CONNECTION_STATE_UNSPECIFIED";
    case TlsConnectionState.CONNECTING:
      return "CONNECTING";
    case TlsConnectionState.CONNECTED:
      return "CONNECTED";
    case TlsConnectionState.DISCONNECTED:
      return "DISCONNECTED";
    case TlsConnectionState.CONNECTION_FAILED:
      return "CONNECTION_FAILED";
    case TlsConnectionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum TlsSessionState {
  TLS_SESSION_STATE_UNSPECIFIED = 0,
  VERIFY_IN_PROGRESS = 1,
  VERIFY_INVALID = 2,
  VERIFY_VALID = 3,
  UNRECOGNIZED = -1,
}

export function tlsSessionStateFromJSON(object: any): TlsSessionState {
  switch (object) {
    case 0:
    case "TLS_SESSION_STATE_UNSPECIFIED":
      return TlsSessionState.TLS_SESSION_STATE_UNSPECIFIED;
    case 1:
    case "VERIFY_IN_PROGRESS":
      return TlsSessionState.VERIFY_IN_PROGRESS;
    case 2:
    case "VERIFY_INVALID":
      return TlsSessionState.VERIFY_INVALID;
    case 3:
    case "VERIFY_VALID":
      return TlsSessionState.VERIFY_VALID;
    case -1:
    case "UNRECOGNIZED":
    default:
      return TlsSessionState.UNRECOGNIZED;
  }
}

export function tlsSessionStateToJSON(object: TlsSessionState): string {
  switch (object) {
    case TlsSessionState.TLS_SESSION_STATE_UNSPECIFIED:
      return "TLS_SESSION_STATE_UNSPECIFIED";
    case TlsSessionState.VERIFY_IN_PROGRESS:
      return "VERIFY_IN_PROGRESS";
    case TlsSessionState.VERIFY_INVALID:
      return "VERIFY_INVALID";
    case TlsSessionState.VERIFY_VALID:
      return "VERIFY_VALID";
    case TlsSessionState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProxyAuthState {
  CLOUD_AUTH_UNSPECIFIED = 0,
  CLOUD_AUTH_IN_PROGRESS = 1,
  CLOUD_AUTH_DONE = 2,
  CLOUD_AUTH_FAILED = 3,
  /** CLOUD_AUTH_NONE - When would we have NONE as a valid state? */
  CLOUD_AUTH_NONE = 4,
  UNRECOGNIZED = -1,
}

export function proxyAuthStateFromJSON(object: any): ProxyAuthState {
  switch (object) {
    case 0:
    case "CLOUD_AUTH_UNSPECIFIED":
      return ProxyAuthState.CLOUD_AUTH_UNSPECIFIED;
    case 1:
    case "CLOUD_AUTH_IN_PROGRESS":
      return ProxyAuthState.CLOUD_AUTH_IN_PROGRESS;
    case 2:
    case "CLOUD_AUTH_DONE":
      return ProxyAuthState.CLOUD_AUTH_DONE;
    case 3:
    case "CLOUD_AUTH_FAILED":
      return ProxyAuthState.CLOUD_AUTH_FAILED;
    case 4:
    case "CLOUD_AUTH_NONE":
      return ProxyAuthState.CLOUD_AUTH_NONE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProxyAuthState.UNRECOGNIZED;
  }
}

export function proxyAuthStateToJSON(object: ProxyAuthState): string {
  switch (object) {
    case ProxyAuthState.CLOUD_AUTH_UNSPECIFIED:
      return "CLOUD_AUTH_UNSPECIFIED";
    case ProxyAuthState.CLOUD_AUTH_IN_PROGRESS:
      return "CLOUD_AUTH_IN_PROGRESS";
    case ProxyAuthState.CLOUD_AUTH_DONE:
      return "CLOUD_AUTH_DONE";
    case ProxyAuthState.CLOUD_AUTH_FAILED:
      return "CLOUD_AUTH_FAILED";
    case ProxyAuthState.CLOUD_AUTH_NONE:
      return "CLOUD_AUTH_NONE";
    case ProxyAuthState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum LivenessStatus {
  LIVENESS_ADMIN_DOWN = 0,
  LIVENESS_UP = 1,
  LIVENESS_DOWN = 2,
  UNRECOGNIZED = -1,
}

export function livenessStatusFromJSON(object: any): LivenessStatus {
  switch (object) {
    case 0:
    case "LIVENESS_ADMIN_DOWN":
      return LivenessStatus.LIVENESS_ADMIN_DOWN;
    case 1:
    case "LIVENESS_UP":
      return LivenessStatus.LIVENESS_UP;
    case 2:
    case "LIVENESS_DOWN":
      return LivenessStatus.LIVENESS_DOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LivenessStatus.UNRECOGNIZED;
  }
}

export function livenessStatusToJSON(object: LivenessStatus): string {
  switch (object) {
    case LivenessStatus.LIVENESS_ADMIN_DOWN:
      return "LIVENESS_ADMIN_DOWN";
    case LivenessStatus.LIVENESS_UP:
      return "LIVENESS_UP";
    case LivenessStatus.LIVENESS_DOWN:
      return "LIVENESS_DOWN";
    case LivenessStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum SpyglassSessionStatus {
  SPYGLASS_SESSION_STATUS_UNSPECIFIED = 0,
  SPYGLASS_SESSION_INVALID = 1,
  SPYGLASS_SESSION_INIT = 2,
  SPYGLASS_SESSION_READY_LOCAL = 3,
  SPYGLASS_SESSION_CONNECTED = 4,
  SPYGLASS_SESSION_COMPLETE = 5,
  UNRECOGNIZED = -1,
}

export function spyglassSessionStatusFromJSON(object: any): SpyglassSessionStatus {
  switch (object) {
    case 0:
    case "SPYGLASS_SESSION_STATUS_UNSPECIFIED":
      return SpyglassSessionStatus.SPYGLASS_SESSION_STATUS_UNSPECIFIED;
    case 1:
    case "SPYGLASS_SESSION_INVALID":
      return SpyglassSessionStatus.SPYGLASS_SESSION_INVALID;
    case 2:
    case "SPYGLASS_SESSION_INIT":
      return SpyglassSessionStatus.SPYGLASS_SESSION_INIT;
    case 3:
    case "SPYGLASS_SESSION_READY_LOCAL":
      return SpyglassSessionStatus.SPYGLASS_SESSION_READY_LOCAL;
    case 4:
    case "SPYGLASS_SESSION_CONNECTED":
      return SpyglassSessionStatus.SPYGLASS_SESSION_CONNECTED;
    case 5:
    case "SPYGLASS_SESSION_COMPLETE":
      return SpyglassSessionStatus.SPYGLASS_SESSION_COMPLETE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return SpyglassSessionStatus.UNRECOGNIZED;
  }
}

export function spyglassSessionStatusToJSON(object: SpyglassSessionStatus): string {
  switch (object) {
    case SpyglassSessionStatus.SPYGLASS_SESSION_STATUS_UNSPECIFIED:
      return "SPYGLASS_SESSION_STATUS_UNSPECIFIED";
    case SpyglassSessionStatus.SPYGLASS_SESSION_INVALID:
      return "SPYGLASS_SESSION_INVALID";
    case SpyglassSessionStatus.SPYGLASS_SESSION_INIT:
      return "SPYGLASS_SESSION_INIT";
    case SpyglassSessionStatus.SPYGLASS_SESSION_READY_LOCAL:
      return "SPYGLASS_SESSION_READY_LOCAL";
    case SpyglassSessionStatus.SPYGLASS_SESSION_CONNECTED:
      return "SPYGLASS_SESSION_CONNECTED";
    case SpyglassSessionStatus.SPYGLASS_SESSION_COMPLETE:
      return "SPYGLASS_SESSION_COMPLETE";
    case SpyglassSessionStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum RemedyActionCode {
  REMEDY_ACTION_UNSPECIFIED = 0,
  FIX_CONNECTION = 1,
  ADD_MISSING_CONNECTION = 2,
  REMOVE_UNEXPECTED_CONNECTION = 3,
  UNRECOGNIZED = -1,
}

export function remedyActionCodeFromJSON(object: any): RemedyActionCode {
  switch (object) {
    case 0:
    case "REMEDY_ACTION_UNSPECIFIED":
      return RemedyActionCode.REMEDY_ACTION_UNSPECIFIED;
    case 1:
    case "FIX_CONNECTION":
      return RemedyActionCode.FIX_CONNECTION;
    case 2:
    case "ADD_MISSING_CONNECTION":
      return RemedyActionCode.ADD_MISSING_CONNECTION;
    case 3:
    case "REMOVE_UNEXPECTED_CONNECTION":
      return RemedyActionCode.REMOVE_UNEXPECTED_CONNECTION;
    case -1:
    case "UNRECOGNIZED":
    default:
      return RemedyActionCode.UNRECOGNIZED;
  }
}

export function remedyActionCodeToJSON(object: RemedyActionCode): string {
  switch (object) {
    case RemedyActionCode.REMEDY_ACTION_UNSPECIFIED:
      return "REMEDY_ACTION_UNSPECIFIED";
    case RemedyActionCode.FIX_CONNECTION:
      return "FIX_CONNECTION";
    case RemedyActionCode.ADD_MISSING_CONNECTION:
      return "ADD_MISSING_CONNECTION";
    case RemedyActionCode.REMOVE_UNEXPECTED_CONNECTION:
      return "REMOVE_UNEXPECTED_CONNECTION";
    case RemedyActionCode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum NeighborType {
  UNKNOWN_NEIGHBOR_TYPE = 0,
  /** DRAKE_FABRIC - Our neighbor is a Tortuga node and part of the same fabric */
  DRAKE_FABRIC = 1,
  /** DRAKE_NOT_FABRIC - Our neighbor is a Tortuga node but not part of our fabric */
  DRAKE_NOT_FABRIC = 2,
  UNRECOGNIZED = -1,
}

export function neighborTypeFromJSON(object: any): NeighborType {
  switch (object) {
    case 0:
    case "UNKNOWN_NEIGHBOR_TYPE":
      return NeighborType.UNKNOWN_NEIGHBOR_TYPE;
    case 1:
    case "DRAKE_FABRIC":
      return NeighborType.DRAKE_FABRIC;
    case 2:
    case "DRAKE_NOT_FABRIC":
      return NeighborType.DRAKE_NOT_FABRIC;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NeighborType.UNRECOGNIZED;
  }
}

export function neighborTypeToJSON(object: NeighborType): string {
  switch (object) {
    case NeighborType.UNKNOWN_NEIGHBOR_TYPE:
      return "UNKNOWN_NEIGHBOR_TYPE";
    case NeighborType.DRAKE_FABRIC:
      return "DRAKE_FABRIC";
    case NeighborType.DRAKE_NOT_FABRIC:
      return "DRAKE_NOT_FABRIC";
    case NeighborType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum VlanTaggingMode {
  VLAN_TAGGING_MODE_UNSPECIFIED = 0,
  VLAN_TAGGING_MODE_TAGGED = 1,
  VLAN_TAGGING_MODE_UNTAGGED = 2,
  VLAN_TAGGING_MODE_PRIORITY_TAGGED = 3,
  UNRECOGNIZED = -1,
}

export function vlanTaggingModeFromJSON(object: any): VlanTaggingMode {
  switch (object) {
    case 0:
    case "VLAN_TAGGING_MODE_UNSPECIFIED":
      return VlanTaggingMode.VLAN_TAGGING_MODE_UNSPECIFIED;
    case 1:
    case "VLAN_TAGGING_MODE_TAGGED":
      return VlanTaggingMode.VLAN_TAGGING_MODE_TAGGED;
    case 2:
    case "VLAN_TAGGING_MODE_UNTAGGED":
      return VlanTaggingMode.VLAN_TAGGING_MODE_UNTAGGED;
    case 3:
    case "VLAN_TAGGING_MODE_PRIORITY_TAGGED":
      return VlanTaggingMode.VLAN_TAGGING_MODE_PRIORITY_TAGGED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return VlanTaggingMode.UNRECOGNIZED;
  }
}

export function vlanTaggingModeToJSON(object: VlanTaggingMode): string {
  switch (object) {
    case VlanTaggingMode.VLAN_TAGGING_MODE_UNSPECIFIED:
      return "VLAN_TAGGING_MODE_UNSPECIFIED";
    case VlanTaggingMode.VLAN_TAGGING_MODE_TAGGED:
      return "VLAN_TAGGING_MODE_TAGGED";
    case VlanTaggingMode.VLAN_TAGGING_MODE_UNTAGGED:
      return "VLAN_TAGGING_MODE_UNTAGGED";
    case VlanTaggingMode.VLAN_TAGGING_MODE_PRIORITY_TAGGED:
      return "VLAN_TAGGING_MODE_PRIORITY_TAGGED";
    case VlanTaggingMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum ProtocolType {
  PROTOCOL_TYPE_UNSPECIFIED = 0,
  SYSTEM = 1,
  KERNEL = 2,
  DIRECTLY_CONNECTED = 3,
  STATIC = 4,
  RIP = 5,
  RIPNG = 6,
  OSFP = 7,
  OSFP6 = 8,
  ISIS = 9,
  BGP = 10,
  PIM = 11,
  HSLS = 12,
  OLSR = 13,
  BABEL = 14,
  UNRECOGNIZED = -1,
}

export function protocolTypeFromJSON(object: any): ProtocolType {
  switch (object) {
    case 0:
    case "PROTOCOL_TYPE_UNSPECIFIED":
      return ProtocolType.PROTOCOL_TYPE_UNSPECIFIED;
    case 1:
    case "SYSTEM":
      return ProtocolType.SYSTEM;
    case 2:
    case "KERNEL":
      return ProtocolType.KERNEL;
    case 3:
    case "DIRECTLY_CONNECTED":
      return ProtocolType.DIRECTLY_CONNECTED;
    case 4:
    case "STATIC":
      return ProtocolType.STATIC;
    case 5:
    case "RIP":
      return ProtocolType.RIP;
    case 6:
    case "RIPNG":
      return ProtocolType.RIPNG;
    case 7:
    case "OSFP":
      return ProtocolType.OSFP;
    case 8:
    case "OSFP6":
      return ProtocolType.OSFP6;
    case 9:
    case "ISIS":
      return ProtocolType.ISIS;
    case 10:
    case "BGP":
      return ProtocolType.BGP;
    case 11:
    case "PIM":
      return ProtocolType.PIM;
    case 12:
    case "HSLS":
      return ProtocolType.HSLS;
    case 13:
    case "OLSR":
      return ProtocolType.OLSR;
    case 14:
    case "BABEL":
      return ProtocolType.BABEL;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ProtocolType.UNRECOGNIZED;
  }
}

export function protocolTypeToJSON(object: ProtocolType): string {
  switch (object) {
    case ProtocolType.PROTOCOL_TYPE_UNSPECIFIED:
      return "PROTOCOL_TYPE_UNSPECIFIED";
    case ProtocolType.SYSTEM:
      return "SYSTEM";
    case ProtocolType.KERNEL:
      return "KERNEL";
    case ProtocolType.DIRECTLY_CONNECTED:
      return "DIRECTLY_CONNECTED";
    case ProtocolType.STATIC:
      return "STATIC";
    case ProtocolType.RIP:
      return "RIP";
    case ProtocolType.RIPNG:
      return "RIPNG";
    case ProtocolType.OSFP:
      return "OSFP";
    case ProtocolType.OSFP6:
      return "OSFP6";
    case ProtocolType.ISIS:
      return "ISIS";
    case ProtocolType.BGP:
      return "BGP";
    case ProtocolType.PIM:
      return "PIM";
    case ProtocolType.HSLS:
      return "HSLS";
    case ProtocolType.OLSR:
      return "OLSR";
    case ProtocolType.BABEL:
      return "BABEL";
    case ProtocolType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum IpFamilyType {
  IP_FAMILY_TYPE_UNSPECIFIED = 0,
  IPv4 = 1,
  IPv6 = 2,
  UNRECOGNIZED = -1,
}

export function ipFamilyTypeFromJSON(object: any): IpFamilyType {
  switch (object) {
    case 0:
    case "IP_FAMILY_TYPE_UNSPECIFIED":
      return IpFamilyType.IP_FAMILY_TYPE_UNSPECIFIED;
    case 1:
    case "IPv4":
      return IpFamilyType.IPv4;
    case 2:
    case "IPv6":
      return IpFamilyType.IPv6;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IpFamilyType.UNRECOGNIZED;
  }
}

export function ipFamilyTypeToJSON(object: IpFamilyType): string {
  switch (object) {
    case IpFamilyType.IP_FAMILY_TYPE_UNSPECIFIED:
      return "IP_FAMILY_TYPE_UNSPECIFIED";
    case IpFamilyType.IPv4:
      return "IPv4";
    case IpFamilyType.IPv6:
      return "IPv6";
    case IpFamilyType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum QsfpTransceiverModule {
  QSFP_TRANSCEIVER_MODULE_UNSPECIFIED = 0,
  COPPER = 1,
  FIBER = 2,
  FIBER_400GBASE_DR4 = 3,
  FIBER_400GBASE_FR4 = 4,
  FIBER_400GBASE_SR8 = 5,
  FIBER_400GBASE_LR8 = 6,
  UNRECOGNIZED = -1,
}

export function qsfpTransceiverModuleFromJSON(object: any): QsfpTransceiverModule {
  switch (object) {
    case 0:
    case "QSFP_TRANSCEIVER_MODULE_UNSPECIFIED":
      return QsfpTransceiverModule.QSFP_TRANSCEIVER_MODULE_UNSPECIFIED;
    case 1:
    case "COPPER":
      return QsfpTransceiverModule.COPPER;
    case 2:
    case "FIBER":
      return QsfpTransceiverModule.FIBER;
    case 3:
    case "FIBER_400GBASE_DR4":
      return QsfpTransceiverModule.FIBER_400GBASE_DR4;
    case 4:
    case "FIBER_400GBASE_FR4":
      return QsfpTransceiverModule.FIBER_400GBASE_FR4;
    case 5:
    case "FIBER_400GBASE_SR8":
      return QsfpTransceiverModule.FIBER_400GBASE_SR8;
    case 6:
    case "FIBER_400GBASE_LR8":
      return QsfpTransceiverModule.FIBER_400GBASE_LR8;
    case -1:
    case "UNRECOGNIZED":
    default:
      return QsfpTransceiverModule.UNRECOGNIZED;
  }
}

export function qsfpTransceiverModuleToJSON(object: QsfpTransceiverModule): string {
  switch (object) {
    case QsfpTransceiverModule.QSFP_TRANSCEIVER_MODULE_UNSPECIFIED:
      return "QSFP_TRANSCEIVER_MODULE_UNSPECIFIED";
    case QsfpTransceiverModule.COPPER:
      return "COPPER";
    case QsfpTransceiverModule.FIBER:
      return "FIBER";
    case QsfpTransceiverModule.FIBER_400GBASE_DR4:
      return "FIBER_400GBASE_DR4";
    case QsfpTransceiverModule.FIBER_400GBASE_FR4:
      return "FIBER_400GBASE_FR4";
    case QsfpTransceiverModule.FIBER_400GBASE_SR8:
      return "FIBER_400GBASE_SR8";
    case QsfpTransceiverModule.FIBER_400GBASE_LR8:
      return "FIBER_400GBASE_LR8";
    case QsfpTransceiverModule.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum OperationStatus {
  OPERATION_STATUS_UNSPECIFIED = 0,
  UP = 1,
  DOWN = 2,
  UNRECOGNIZED = -1,
}

export function operationStatusFromJSON(object: any): OperationStatus {
  switch (object) {
    case 0:
    case "OPERATION_STATUS_UNSPECIFIED":
      return OperationStatus.OPERATION_STATUS_UNSPECIFIED;
    case 1:
    case "UP":
      return OperationStatus.UP;
    case 2:
    case "DOWN":
      return OperationStatus.DOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OperationStatus.UNRECOGNIZED;
  }
}

export function operationStatusToJSON(object: OperationStatus): string {
  switch (object) {
    case OperationStatus.OPERATION_STATUS_UNSPECIFIED:
      return "OPERATION_STATUS_UNSPECIFIED";
    case OperationStatus.UP:
      return "UP";
    case OperationStatus.DOWN:
      return "DOWN";
    case OperationStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum L3Status {
  L3_STATUS_UNSPECIFIED = 0,
  UNKNOWN = 1,
  NO_ARP = 2,
  STALE = 3,
  REACHABLE = 4,
  PERMANENT = 5,
  NONE = 6,
  INCOMPLETE = 7,
  DELAY = 8,
  PROBE = 9,
  FAILED = 10,
  UNRECOGNIZED = -1,
}

export function l3StatusFromJSON(object: any): L3Status {
  switch (object) {
    case 0:
    case "L3_STATUS_UNSPECIFIED":
      return L3Status.L3_STATUS_UNSPECIFIED;
    case 1:
    case "UNKNOWN":
      return L3Status.UNKNOWN;
    case 2:
    case "NO_ARP":
      return L3Status.NO_ARP;
    case 3:
    case "STALE":
      return L3Status.STALE;
    case 4:
    case "REACHABLE":
      return L3Status.REACHABLE;
    case 5:
    case "PERMANENT":
      return L3Status.PERMANENT;
    case 6:
    case "NONE":
      return L3Status.NONE;
    case 7:
    case "INCOMPLETE":
      return L3Status.INCOMPLETE;
    case 8:
    case "DELAY":
      return L3Status.DELAY;
    case 9:
    case "PROBE":
      return L3Status.PROBE;
    case 10:
    case "FAILED":
      return L3Status.FAILED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return L3Status.UNRECOGNIZED;
  }
}

export function l3StatusToJSON(object: L3Status): string {
  switch (object) {
    case L3Status.L3_STATUS_UNSPECIFIED:
      return "L3_STATUS_UNSPECIFIED";
    case L3Status.UNKNOWN:
      return "UNKNOWN";
    case L3Status.NO_ARP:
      return "NO_ARP";
    case L3Status.STALE:
      return "STALE";
    case L3Status.REACHABLE:
      return "REACHABLE";
    case L3Status.PERMANENT:
      return "PERMANENT";
    case L3Status.NONE:
      return "NONE";
    case L3Status.INCOMPLETE:
      return "INCOMPLETE";
    case L3Status.DELAY:
      return "DELAY";
    case L3Status.PROBE:
      return "PROBE";
    case L3Status.FAILED:
      return "FAILED";
    case L3Status.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PortChannelMemberInfoState {
  PORT_CHANNEL_MEMBER_INFO_STATE_UNSPECIFIED = 0,
  DISABLED = 1,
  CURRENT = 2,
  EXPIRED = 3,
  DEFAULTED = 4,
  UNRECOGNIZED = -1,
}

export function portChannelMemberInfoStateFromJSON(object: any): PortChannelMemberInfoState {
  switch (object) {
    case 0:
    case "PORT_CHANNEL_MEMBER_INFO_STATE_UNSPECIFIED":
      return PortChannelMemberInfoState.PORT_CHANNEL_MEMBER_INFO_STATE_UNSPECIFIED;
    case 1:
    case "DISABLED":
      return PortChannelMemberInfoState.DISABLED;
    case 2:
    case "CURRENT":
      return PortChannelMemberInfoState.CURRENT;
    case 3:
    case "EXPIRED":
      return PortChannelMemberInfoState.EXPIRED;
    case 4:
    case "DEFAULTED":
      return PortChannelMemberInfoState.DEFAULTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortChannelMemberInfoState.UNRECOGNIZED;
  }
}

export function portChannelMemberInfoStateToJSON(object: PortChannelMemberInfoState): string {
  switch (object) {
    case PortChannelMemberInfoState.PORT_CHANNEL_MEMBER_INFO_STATE_UNSPECIFIED:
      return "PORT_CHANNEL_MEMBER_INFO_STATE_UNSPECIFIED";
    case PortChannelMemberInfoState.DISABLED:
      return "DISABLED";
    case PortChannelMemberInfoState.CURRENT:
      return "CURRENT";
    case PortChannelMemberInfoState.EXPIRED:
      return "EXPIRED";
    case PortChannelMemberInfoState.DEFAULTED:
      return "DEFAULTED";
    case PortChannelMemberInfoState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PortChannelStatus {
  PORT_CHANNEL_STATUS_UNSPECIFIED = 0,
  PORT_CHANNEL_STATUS_OK = 1,
  PORT_CHANNEL_STATUS_NOT_OK = 2,
  UNRECOGNIZED = -1,
}

export function portChannelStatusFromJSON(object: any): PortChannelStatus {
  switch (object) {
    case 0:
    case "PORT_CHANNEL_STATUS_UNSPECIFIED":
      return PortChannelStatus.PORT_CHANNEL_STATUS_UNSPECIFIED;
    case 1:
    case "PORT_CHANNEL_STATUS_OK":
      return PortChannelStatus.PORT_CHANNEL_STATUS_OK;
    case 2:
    case "PORT_CHANNEL_STATUS_NOT_OK":
      return PortChannelStatus.PORT_CHANNEL_STATUS_NOT_OK;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortChannelStatus.UNRECOGNIZED;
  }
}

export function portChannelStatusToJSON(object: PortChannelStatus): string {
  switch (object) {
    case PortChannelStatus.PORT_CHANNEL_STATUS_UNSPECIFIED:
      return "PORT_CHANNEL_STATUS_UNSPECIFIED";
    case PortChannelStatus.PORT_CHANNEL_STATUS_OK:
      return "PORT_CHANNEL_STATUS_OK";
    case PortChannelStatus.PORT_CHANNEL_STATUS_NOT_OK:
      return "PORT_CHANNEL_STATUS_NOT_OK";
    case PortChannelStatus.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PortChannelKernelTeamMode {
  PORT_CHANNEL_KERNEL_TEAM_MODE = 0,
  LOAD_BALANCE = 1,
  ROUND_ROBIN = 2,
  UNRECOGNIZED = -1,
}

export function portChannelKernelTeamModeFromJSON(object: any): PortChannelKernelTeamMode {
  switch (object) {
    case 0:
    case "PORT_CHANNEL_KERNEL_TEAM_MODE":
      return PortChannelKernelTeamMode.PORT_CHANNEL_KERNEL_TEAM_MODE;
    case 1:
    case "LOAD_BALANCE":
      return PortChannelKernelTeamMode.LOAD_BALANCE;
    case 2:
    case "ROUND_ROBIN":
      return PortChannelKernelTeamMode.ROUND_ROBIN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortChannelKernelTeamMode.UNRECOGNIZED;
  }
}

export function portChannelKernelTeamModeToJSON(object: PortChannelKernelTeamMode): string {
  switch (object) {
    case PortChannelKernelTeamMode.PORT_CHANNEL_KERNEL_TEAM_MODE:
      return "PORT_CHANNEL_KERNEL_TEAM_MODE";
    case PortChannelKernelTeamMode.LOAD_BALANCE:
      return "LOAD_BALANCE";
    case PortChannelKernelTeamMode.ROUND_ROBIN:
      return "ROUND_ROBIN";
    case PortChannelKernelTeamMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PortChannelProtocol {
  PORT_CHANNEL_PROTOCOL_UNSPECIFIED = 0,
  LACP = 1,
  UNRECOGNIZED = -1,
}

export function portChannelProtocolFromJSON(object: any): PortChannelProtocol {
  switch (object) {
    case 0:
    case "PORT_CHANNEL_PROTOCOL_UNSPECIFIED":
      return PortChannelProtocol.PORT_CHANNEL_PROTOCOL_UNSPECIFIED;
    case 1:
    case "LACP":
      return PortChannelProtocol.LACP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortChannelProtocol.UNRECOGNIZED;
  }
}

export function portChannelProtocolToJSON(object: PortChannelProtocol): string {
  switch (object) {
    case PortChannelProtocol.PORT_CHANNEL_PROTOCOL_UNSPECIFIED:
      return "PORT_CHANNEL_PROTOCOL_UNSPECIFIED";
    case PortChannelProtocol.LACP:
      return "LACP";
    case PortChannelProtocol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum PowerStateEnum {
  POWER_STATE_UNKNOWN = 0,
  POWER_STATE_ON = 1,
  POWER_STATE_OFF = 2,
  POWER_STATE_POWERING_ON = 3,
  POWER_STATE_POWERING_OFF = 4,
  UNRECOGNIZED = -1,
}

export function powerStateEnumFromJSON(object: any): PowerStateEnum {
  switch (object) {
    case 0:
    case "POWER_STATE_UNKNOWN":
      return PowerStateEnum.POWER_STATE_UNKNOWN;
    case 1:
    case "POWER_STATE_ON":
      return PowerStateEnum.POWER_STATE_ON;
    case 2:
    case "POWER_STATE_OFF":
      return PowerStateEnum.POWER_STATE_OFF;
    case 3:
    case "POWER_STATE_POWERING_ON":
      return PowerStateEnum.POWER_STATE_POWERING_ON;
    case 4:
    case "POWER_STATE_POWERING_OFF":
      return PowerStateEnum.POWER_STATE_POWERING_OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PowerStateEnum.UNRECOGNIZED;
  }
}

export function powerStateEnumToJSON(object: PowerStateEnum): string {
  switch (object) {
    case PowerStateEnum.POWER_STATE_UNKNOWN:
      return "POWER_STATE_UNKNOWN";
    case PowerStateEnum.POWER_STATE_ON:
      return "POWER_STATE_ON";
    case PowerStateEnum.POWER_STATE_OFF:
      return "POWER_STATE_OFF";
    case PowerStateEnum.POWER_STATE_POWERING_ON:
      return "POWER_STATE_POWERING_ON";
    case PowerStateEnum.POWER_STATE_POWERING_OFF:
      return "POWER_STATE_POWERING_OFF";
    case PowerStateEnum.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface TestSubMessage {
  subInt32: number;
  subRepeatedString: string[];
}

export interface TestRootOne {
  id: PathId | undefined;
  rootBool: boolean;
  rootEnum: TestEnum;
  rootInt32: number;
  rootSint32: number;
  rootSfixed32: number;
  rootUint32: number;
  rootInt64: number;
  rootSint64: number;
  rootSfixed64: number;
  rootFloat: number;
  rootDouble: number;
  rootString: string;
  rootBytes: Uint8Array;
  rootTimestamp: Date | undefined;
  rootMacaddress: string;
  rstr: string[];
  rint32: number[];
  subMessage: TestSubMessage[];
  rootIpaddress: string;
  rootIpprefix: string;
}

export interface TestRootTwo {
  id: PathId | undefined;
}

export interface TestMidOne {
  id: PathId | undefined;
}

export interface TestLeafOne {
  id: PathId | undefined;
}

export interface TestIpPrefix {
  id: PathId | undefined;
}

export interface TestIpAddress {
  id: PathId | undefined;
}

export interface TestMacAddress {
  id: PathId | undefined;
}

/** Universal counters for L2/L3 entities */
export interface BasicCounters {
  bytesIn: number;
  bytesOut: number;
  pktsIn: number;
  pktsOut: number;
  errPktsIn: number;
  errPktsOut: number;
}

export interface Fabric {
  id: PathId | undefined;
  orgId: string;
  numDevices: number;
}

/** FabricDevice determines the assignment of a given device id to a fabric id. */
export interface FabricDevice {
  id: PathId | undefined;
  deviceId: string;
  fabricId: string;
  orgId: string;
  nodeId: string;
  majorLatchedFalseAsserts: number;
  majorUnlatchedFalseAsserts: number;
  /** FIXME: unused for now as we do not have assert severity yet, but once this has been defined we will */
  minorLatchedFalseAsserts: number;
  /** FIXME: unused for now as we do not have assert severity yet, but once this has been defined we will */
  minorUnlatchedFalseAsserts: number;
  majorLatchedTrueAsserts: number;
  majorUnlatchedTrueAsserts: number;
  /** FIXME: unused for now as we do not have assert severity yet, but once this has been defined we will */
  minorLatchedTrueAsserts: number;
  /** FIXME: unused for now as we do not have assert severity yet, but once this has been defined we will */
  minorUnlatchedTrueAsserts: number;
  plannedConfig: PlannedConfigData | undefined;
  mgmtPortState: ManagementState | undefined;
}

export interface FabricConfigNotification {
  id: PathId | undefined;
  configFp: string;
  fabricId: string;
  sequence: number;
}

export interface FabricTransaction {
  id: PathId | undefined;
  changedAt: Date | undefined;
  committed: boolean;
}

export interface StandbyAssignment {
  standbyInstance: string;
  syncFromInstance: string;
  standbyInstanceId: string;
  promoteStandby: boolean;
}

/** SextantFabricAssignment is mislabeled - this object models fabric assignments for any fabric-sharded service. */
export interface SextantFabricAssignment {
  id: PathId | undefined;
  orgId: string;
  /** The currently-assigned active instance for this fabric */
  activeInstance: string;
  /** All standby assignments for this fabric, sorted in standby-instance order */
  standbyInstances: StandbyAssignment[];
  activeInstanceId: string;
  activeInstanceUrl: string;
  revision: number;
  fabricType: FabricType;
  demoteActive: boolean;
}

export interface FabricAssignmentStatus {
  id: PathId | undefined;
  assignmentState: FabricAssignmentState;
}

export interface FabricMemoryUsage {
  id: PathId | undefined;
}

export interface TypeMemoryUsage {
  id: PathId | undefined;
  bytes: number;
}

export interface FabricConfig {
  id: PathId | undefined;
  name: string;
  description: string;
  fabricId: string;
  adminState: AdminState;
  topology: FabricTopology;
  type: FabricType;
  modifiedAt: Date | undefined;
  orgId: string;
  tags: string[];
}

export interface FabricConfigEvent {
  id: PathId | undefined;
  event: FabricEvent;
  nodeId: string;
  deviceId: string;
}

/** A detected Device */
export interface Device {
  id: PathId | undefined;
  info:
    | DeviceInfo
    | undefined;
  /** @deprecated */
  fabricId: string;
  /** @deprecated */
  firstSeen:
    | Date
    | undefined;
  /** @deprecated */
  lastSeen: Date | undefined;
  commonPortSpeed: number;
}

export interface DeviceConnection {
  id:
    | PathId
    | undefined;
  /** Number of websocket connections that are currently up */
  wsConnected: number;
  /** Time at which any websocket connection was last made */
  wsLastConnectedAt:
    | Date
    | undefined;
  /** Time at which any websocket connection was last lost */
  wsLastDisconnectedAt:
    | Date
    | undefined;
  /** Grace period for initial connection after device is marked active */
  wsMustConnectBy: Date | undefined;
}

export interface PlannedConfigData {
  currentConfigFp: string;
  plannedConfigFp: string;
  plannedConfigUpdatedAt: Date | undefined;
  plannedConfigPhase: PlannedConfigPhase;
}

export interface PlannedConfig {
  id: PathId | undefined;
  plannedConfig: PlannedConfigData | undefined;
}

export interface DeviceInfo {
  model: string;
  psuNum: string;
  revision: string;
  serial: string;
  name: string;
  lldpLocId: string;
  sonicVersion: string;
  agentsVersion: string;
}

export interface NodeConfig {
  id: PathId | undefined;
  nodeId: string;
  fabricId: string;
  deviceId: string;
  name: string;
  type: NodeType;
  adminState: AdminState;
  description: string;
  serialNumber: string;
  modelName: string;
  role: NodeRole;
  osType: OsType;
  modifiedAt: Date | undefined;
  tags: string[];
  orgId: string;
  configFp: string;
}

export interface Fan {
  id:
    | PathId
    | undefined;
  /** Fan speed as a percentage of the maximum rated speed */
  speed: number;
  name: string;
  /** @deprecated */
  deprecatedDirection: string;
  status: EnvStatus;
  ledStatus: EnvLedStatus;
  drawer: string;
  collectedAt: Date | undefined;
  direction: FanDirection;
  serial: string;
}

export interface ProcTable {
  id: PathId | undefined;
  processes: Process[];
  collectedAt: Date | undefined;
}

export interface Process {
  /**
   * FIXME: we should probably report CPU in CPU core units rather than
   * percent
   */
  cpuPercent: number;
  /** FIXME: we should report memory in MB */
  memPercent: number;
  cmd: string;
  /** CPU time in seconds */
  cpuTimeS: number;
}

export interface Psu {
  id: PathId | undefined;
  watts: number;
  /** Fan speed as a percentage of the maximum rated speed */
  fanSpeed: number;
  name: string;
  status: EnvStatus;
  ledStatus: EnvLedStatus;
  model: string;
  serial: string;
  voltage: number;
  current: number;
}

export interface TempSensor {
  id: PathId | undefined;
  degC: number;
}

/**
 * ProxyStats contains useful debug information we can use
 * to examine network issues between Drakes and the cloud.
 * For now, this should not be user-facing.
 */
export interface ProxyStats {
  id:
    | PathId
    | undefined;
  /** TLS stats only for now */
  reqCount: number;
  reqErrors: number;
  respCount: number;
  respErrors: number;
  minRtt: number;
  maxRtt: number;
  meanRtt: number;
  medianRtt: number;
}

export interface UnderlayInfo {
  id:
    | PathId
    | undefined;
  /** TODO: not populated yet */
  proxySessionInfo: ProxySessionInfo | undefined;
  gatewayInfo: GatewayInfo | undefined;
}

export interface ProxySessionInfo {
  proxyAuthState: ProxyAuthState;
  /** TODO: document the cert format */
  certChain: string[];
  initiatedAt: Date | undefined;
  validUntil: Date | undefined;
}

export interface ProxyConnectionState {
  id: PathId | undefined;
  sourcePort: number;
  destPort: number;
  sourceIp: string;
  destIp: string;
  outerTlsConnectionState: TlsConnectionState;
  outerTlsSessionState: TlsSessionState;
  /** An error string used for internal debugging */
  outerTlsErr: string;
  innerTlsConnectionState: TlsConnectionState;
  innerTlsErr: string;
  /** All certificates are base64 encoded DER certificates */
  outerTlsLeafCert: string;
  outerTlsIntermediateCerts: string[];
  outerTlsRootCerts: string[];
  outerTlsVerifyErr: TLSVerifyError;
  outerTlsVerifyErrString: string;
  proxyUrl: string;
  source: string;
  fabricPath: string;
  /** Session initiation time */
  initiatedAt: Date | undefined;
  collectedAt: Date | undefined;
}

export interface ProxyConnectionStats {
  id: PathId | undefined;
  bytesIn: number;
  bytesOut: number;
  inBitsps: number;
  outBitsps: number;
  collectedAt: Date | undefined;
}

export interface GatewayInfo {
  isGateway: boolean;
}

export interface LineCard {
  id: PathId | undefined;
}

export interface Breakout {
  id: PathId | undefined;
  state: PortState | undefined;
}

export interface DacQsfp {
  id: PathId | undefined;
  counters: DacCounters | undefined;
}

export interface DacCounters {
  inOctets: number;
}

export interface FiberQsfp {
  id: PathId | undefined;
  counters: FiberCounters | undefined;
}

export interface FiberCounters {
  inOctets: number;
}

export interface PortConfig {
  id: PathId | undefined;
  config: PortConfigDetails | undefined;
}

export interface PortConfigDetails {
  portName: string;
  portIndex: number;
  portRole: PortRole;
  adminState: AdminState;
  mtu: number;
  deviceId: string;
  targetDeviceId: string;
  targetPortName: string;
  modifiedAt: Date | undefined;
  breakOut: boolean;
}

export interface Port {
  id: PathId | undefined;
}

export interface SubInterface {
  id: PathId | undefined;
  details: SubInterfaceDetails | undefined;
}

export interface SubInterfaceDetails {
  subInterfaceName: string;
  portName: string;
  adminState: AdminState;
  operState: OperState;
  mtu: number;
  macAddr: string;
  vlan: number;
  collectedAt: Date | undefined;
}

export interface PortCounters {
  id: PathId | undefined;
  inOctets: number;
  inPkts: number;
  inUnicastPkts: number;
  inBroadcastPkts: number;
  inMulticastPkts: number;
  inDiscards: number;
  inErrors: number;
  inUnknownProtos: number;
  inFcsErrors: number;
  outOctets: number;
  outPkts: number;
  outUnicastPkts: number;
  outBroadcastPkts: number;
  outMulticastPkts: number;
  outDiscards: number;
  outErrors: number;
  carrierTransitions: number;
  inPfc0Pkts: number;
  outPfc0Pkts: number;
  inPfc1Pkts: number;
  outPfc1Pkts: number;
  inPfc2Pkts: number;
  outPfc2Pkts: number;
  inPfc3Pkts: number;
  outPfc3Pkts: number;
  inPfc4Pkts: number;
  outPfc4Pkts: number;
  inPfc5Pkts: number;
  outPfc5Pkts: number;
  inPfc6Pkts: number;
  outPfc6Pkts: number;
  inPfc7Pkts: number;
  outPfc7Pkts: number;
  /** bytes per second */
  inBitsps: number;
  outBitsps: number;
  /** packets per second */
  inPps: number;
  outPps: number;
  /** unicast packets per second */
  inUnicastPps: number;
  outUnicastPps: number;
  /** Aggregate packets per second over all PFC counters */
  inPfcPps: number;
  outPfcPps: number;
  lastClear: Date | undefined;
  collectedAt: Date | undefined;
}

export interface IpmSessionId {
  id: PathId | undefined;
}

export interface IpmSessionParameters {
  description: string;
  srcAddr: string;
  dstAddr: string;
  vrf: string;
  flowLabel: number;
  dscp: number;
  confSessionId: string;
}

export interface IpmRawLatencyCounters {
  id: PathId | undefined;
  rxBin0: number;
  rxBin1: number;
  rxBin2: number;
  rxBin3: number;
  rxBin4: number;
  rxBin5: number;
  rxBin6: number;
  rxBin7: number;
  rxBin8: number;
  rxBin9: number;
  rxBin10: number;
  rxBin11: number;
  rxBin12: number;
  rxBin13: number;
  rxBin14: number;
  rxBin15: number;
  rxBin16: number;
  rxBin17: number;
  rxBin18: number;
  rxBin19: number;
  rxBin20: number;
  rxBin21: number;
  rxBin22: number;
  rxBin23: number;
  rxBin24: number;
  rxBin25: number;
  rxBin26: number;
  rxBin27: number;
  maxBin0: number;
  maxBin1: number;
  maxBin2: number;
  maxBin3: number;
  maxBin4: number;
  maxBin5: number;
  maxBin6: number;
  maxBin7: number;
  maxBin8: number;
  maxBin9: number;
  maxBin10: number;
  maxBin11: number;
  maxBin12: number;
  maxBin13: number;
  maxBin14: number;
  maxBin15: number;
  maxBin16: number;
  maxBin17: number;
  maxBin18: number;
  maxBin19: number;
  maxBin20: number;
  maxBin21: number;
  maxBin22: number;
  maxBin23: number;
  maxBin24: number;
  maxBin25: number;
  maxBin26: number;
  maxBin27: number;
  ipmParams: IpmSessionParameters | undefined;
  collectedAt: Date | undefined;
}

export interface IpmProcLatencyCounters {
  id: PathId | undefined;
  exceedingSuspicious: number;
  exceedingCritical: number;
  ipmParams: IpmSessionParameters | undefined;
  collectedAt: Date | undefined;
}

export interface IpmProcLossCounters {
  id: PathId | undefined;
  transmittedPackets: number;
  receivedPackets: number;
  loss: number;
  pLoss: number;
  ipmParams: IpmSessionParameters | undefined;
  collectedAt: Date | undefined;
}

export interface IpmLivenessCounters {
  id: PathId | undefined;
  state: LivenessStatus;
  livenessTimer: number;
  ipmParams: IpmSessionParameters | undefined;
  collectedAt: Date | undefined;
}

export interface VlanCounters {
  id: PathId | undefined;
  bytesIn: number;
  bytesOut: number;
  pktsIn: number;
  pktsOut: number;
  errIn: number;
  errOut: number;
  errPktsIn: number;
  errPktsOut: number;
  collectedAt: Date | undefined;
}

export interface RoutedInterfaceCounters {
  id: PathId | undefined;
  bytesIn: number;
  bytesOut: number;
  pktsIn: number;
  pktsOut: number;
  errIn: number;
  errOut: number;
  errPktsIn: number;
  errPktsOut: number;
  collectedAt: Date | undefined;
}

export interface L2Fdb {
  id:
    | PathId
    | undefined;
  /**
   * TBD collection time
   * TBD min/max key values for block
   */
  l2entries: L2FdbEntry[];
}

export interface L2FdbEntry {
  macAddress: string;
  vlanId: number;
  /** Give this a field type for port ref */
  portRef: string;
  /** Was entry added statically? */
  staticEntry: boolean;
  /**
   * TBD last hit time?
   * TBD valid or removed bit?
   */
  vni: number;
}

/** For the table of current Spyglass sessions; maps TCP port to device */
export interface SpyglassSshSessionEntry {
  id: PathId | undefined;
  tcpPort: number;
  deviceId: string;
}

/** Uplifted from Configd for Spyglass Broker status */
export interface SpyglassBrokerEntry {
  id: PathId | undefined;
  deviceId: string;
  connectedInternal: boolean;
  connectedExternal: boolean;
  /** Packets sent to external intf */
  pktsIntToExt: number;
  /** Packets sent to internal intf */
  pktsExtToInt: number;
  internalConnectionCreated: Date | undefined;
  externalConnectionCreated: Date | undefined;
}

/** Uplifted from Lira, contains the internal keys */
export interface TestPlaceHolder1Secret {
  id: PathId | undefined;
}

/** A Spyglass device is indexed by a device-id (switch MAC) */
export interface SpyglassDevice {
  id: PathId | undefined;
  connectedAt: Date | undefined;
  listenerCount: number;
}

/** A Spyglass listener is a child of a spyglass device and is indexed by its TCP port */
export interface SpyglassTcpListener {
  id: PathId | undefined;
  createdAt: Date | undefined;
  currentSessionCount: number;
  totalSessionCount: number;
  maxConcurrentSessions: number;
}

/**
 * A Spyglass session is spawned from a listener (for a device)
 * It is indexed by its session port
 */
export interface SpyglassTcpSession {
  id: PathId | undefined;
  created: Date | undefined;
}

/** DEPRECATED */
export interface SpyglassGingerEntry {
  id: PathId | undefined;
  deviceId: string;
  tcpPort: number;
  status: SpyglassSessionStatus;
  connectionCreated: Date | undefined;
}

export interface RemedyActionFixConnection {
  moveFrom: string;
  moveTo: string;
  connectFrom: string;
  connectTo: string;
}

export interface RemedyActionAddMissingConnection {
  connectFrom: string;
  connectTo: string;
}

export interface RemedyActionRemoveUnexpectedConnection {
  disconnectFrom: string;
  disconnectTo: string;
}

export interface RemedyAction {
  id: PathId | undefined;
  relatedAsserts: PathId[];
  /** Indicates which of the following fields is in-use */
  actionType: RemedyActionCode;
  fixConnection: RemedyActionFixConnection | undefined;
  addConnection: RemedyActionAddMissingConnection | undefined;
  removeConnection: RemedyActionRemoveUnexpectedConnection | undefined;
}

/** DEPRECATED */
export interface RemoteSysCapability {
  sysCapability: LLDPSysCapabilityType;
  enabled: boolean;
}

export interface AdvertisedAutoNegotiation {
  type: string;
  hd: boolean;
  fd: boolean;
}

export interface RemotePortAutoNegotiation {
  supported: boolean;
  enabled: boolean;
  current: string;
  advertised: AdvertisedAutoNegotiation[];
}

/** PortNeighbor encapsulates the state of the remote side of a port's link. */
export interface PortNeighbor {
  id: PathId | undefined;
  remotePortDesc: string;
  remotePortId: string;
  remoteSysDescription: string;
  remoteSysName: string;
  remoteManagementAddrIpv4: string;
  remoteManagementAddrIpv6: string;
  remoteDeviceId: string;
  remoteIndex: number;
  remotePortIdSubtype: LLDPRemoteDeviceHostSubType;
  remoteDeviceIdSubtype: LLDPRemoteDeviceHostSubType;
  remoteTimeMark: number;
  /** @deprecated */
  deprecatedRemoteSysCap: RemoteSysCapability[];
  collectedAt: Date | undefined;
  localPort: string;
  neighborType: NeighborType;
  remotePortTtl: number;
  remotePortAutoNegotiation: RemotePortAutoNegotiation | undefined;
  RemoteSysCapabilityRepeater: boolean;
  RemoteSysCapabilityBridge: boolean;
  RemoteSysCapabilityWlan: boolean;
  RemoteSysCapabilityRouter: boolean;
  RemoteSysCapabilityTelephone: boolean;
  RemoteSysCapabilityDocsisCableDevice: boolean;
  RemoteSysCapabilityStation: boolean;
}

export interface PortState {
  adminState: AdminState;
  operState: OperState;
  ipv4: string;
  /** Incremented whenever admin state goes ENABLED -> DISABLED */
  adminDownCounter: number;
  /** Incremented whenever operational state goes ENABLED -> DISABLED */
  operDownCounter: number;
  speed: number;
  collectedAt: Date | undefined;
}

export interface Vtep {
  id: PathId | undefined;
  bytesIn: number;
  bytesOut: number;
  pktsIn: number;
  pktsOut: number;
  collectedAt: Date | undefined;
}

export interface RemoteVtep {
  id: PathId | undefined;
  bytesIn: number;
  bytesOut: number;
  pktsIn: number;
  pktsOut: number;
  collectedAt: Date | undefined;
}

/** Vni encapsulates the per-port VNI counters. */
export interface VniPort {
  id: PathId | undefined;
  counters: BasicCounters | undefined;
}

/** MACs observed on a VNI port */
export interface Mac {
  id: PathId | undefined;
  macAddress: string;
}

export interface PortVlan {
  id: PathId | undefined;
  vlan: number;
  /** @deprecated */
  deprecatedUntagged: string;
  taggingMode: VlanTaggingMode;
  collectedAt: Date | undefined;
}

export interface VlanState {
  id: PathId | undefined;
  adminState: AdminState;
  hostIfname: string;
  macAddr: string;
  mtu: number;
  vlanId: number;
  collectedAt: Date | undefined;
}

export interface UnknownVlanPort {
  id: PathId | undefined;
}

export interface UnknownVlan {
  id: PathId | undefined;
  vlanId: number;
  port: string;
  lastRxPkt: Date | undefined;
  collectedAt: Date | undefined;
}

export interface QsfpDomSensor {
  id: PathId | undefined;
  laserTemperature: number;
  voltage: number;
  rx1power: number;
  rx2power: number;
  rx3power: number;
  rx4power: number;
  rx5power: number;
  rx6power: number;
  rx7power: number;
  rx8power: number;
  temperature: number;
  tx1bias: number;
  tx2bias: number;
  tx3bias: number;
  tx4bias: number;
  tx5bias: number;
  tx6bias: number;
  tx7bias: number;
  tx8bias: number;
  tx1power: number;
  tx2power: number;
  tx3power: number;
  tx4power: number;
  tx5power: number;
  tx6power: number;
  tx7power: number;
  tx8power: number;
  mediaLaneCount: number;
  collectedAt: Date | undefined;
}

export interface QsfpDomThreshold {
  id: PathId | undefined;
  lasertemphighalarm: number;
  lasertemphighwarning: number;
  lasertemplowalarm: number;
  lasertemplowwarning: number;
  rxpowerhighalarm: number;
  rxpowerhighwarning: number;
  rxpowerlowalarm: number;
  rxpowerlowwarning: number;
  temphighalarm: number;
  temphighwarning: number;
  templowalarm: number;
  templowwarning: number;
  txbiashighalarm: number;
  txbiashighwarning: number;
  txbiaslowalarm: number;
  txbiaslowwarning: number;
  txpowerhighalarm: number;
  txpowerhighwarning: number;
  txpowerlowalarm: number;
  txpowerlowwarning: number;
  vcchighalarm: number;
  vcchighwarning: number;
  vcclowalarm: number;
  vcclowwarning: number;
  collectedAt: Date | undefined;
}

export interface L2Mac {
  id: PathId | undefined;
}

export interface L3Table {
  id: PathId | undefined;
  macAddress: string;
  ifName: string;
  ipAddress: string;
  familyType: IpFamilyType;
  vlan: number;
  vni: number;
  /** @deprecated */
  stale: boolean;
  collectedAt: Date | undefined;
  learnedFrom: L3TableEntrySource;
  status: L3Status;
}

export interface IpPrefix {
  id: PathId | undefined;
}

export interface Vrf {
  id: PathId | undefined;
}

export interface IpNextHop {
  id: PathId | undefined;
  flags: number;
  fib: boolean;
  directlyConnected: boolean;
  ip: string;
  /** @deprecated */
  deprecatedAfi: string;
  interfaceIndex: number;
  interfaceName: string;
  active: boolean;
  onLink: boolean;
  weight: number;
  collectedAt: Date | undefined;
  afi: IpFamilyType;
}

export interface IpRouteTable {
  id: PathId | undefined;
  prefix: string;
  prefixLen: number;
  /** @deprecated */
  deprecatedProtocol: string;
  vrfId: number;
  vrfName: string;
  selected: boolean;
  destSelected: boolean;
  distance: number;
  metric: number;
  installed: boolean;
  offloaded: boolean;
  table: number;
  internalStatus: number;
  internalFlags: number;
  internalNextHopNum: number;
  internalNextHopActiveNum: number;
  nextHopGroupId: number;
  installedNextHopGroupId: number;
  uptime: string;
  ipFamily: IpFamilyType;
  collectedAt: Date | undefined;
  protocol: ProtocolType;
  multipleDiffNextHops: boolean;
}

export interface VlanVniMap {
  id: PathId | undefined;
  vni: number;
  vlan: number;
  collectedAt: Date | undefined;
}

export interface ManagementConfig {
  id: PathId | undefined;
  sonic: SonicConfig | undefined;
  proxy: ProxyConfig | undefined;
  dns: DnsConfig | undefined;
  drake: DrakeConfig | undefined;
  ntpServers: string[];
  configSource: string;
  ipv4Method: string;
  ipv6Method: string;
}

export interface ManagementState {
  id: PathId | undefined;
  sonic: SonicConfig | undefined;
  proxy: ProxyConfig | undefined;
  dns: DnsConfig | undefined;
  drake: DrakeConfig | undefined;
  ntpServers: string[];
  configSource: string;
  ipv4Method: string;
  ipv6Method: string;
  operationStatus: OperationStatus;
  collectedAt: Date | undefined;
}

export interface BgpNeighborFamily {
  id: PathId | undefined;
}

export interface BgpNeighborSummary {
  id: PathId | undefined;
  routerId: string;
  as: number;
  vrfId: number;
  vrfName: string;
  tableVersion: number;
  ribCount: number;
  ribMemory: number;
  peerCount: number;
  peerMemory: number;
  peerGroupCount: number;
  peerGroupMemory: number;
  peerName: string;
  peerHostname: string;
  peerRemoteAs: number;
  peerLocalAs: number;
  peerTableVersion: number;
  peerMsgRcvd: number;
  peerMsgSent: number;
  peerOutQ: number;
  peerInQ: number;
  peerUptime: string;
  peerUptimeMsec: number;
  peerUptimeEstablishedEpoch: number;
  peerPfxRcd: number;
  peerPfxSnt: number;
  peerConnectionState: BgpPeerConnectionState;
  peerState: BgpPeerState;
  peerConnectionsEstablished: number;
  peerConnectionsDropped: number;
  peerIdType: BgpIdType;
  failedPeers: number;
  displayedPeers: number;
  totalPeers: number;
  dynamicPeers: number;
  addressFamily: BgpAddressFamily;
  multiPathRelax: boolean;
  peerVersion: number;
  collectedAt: Date | undefined;
}

export interface SystemStats {
  id: PathId | undefined;
  CpuUsed: number;
  CpuIdle: number;
  MemTotal: number;
  MemUsed: number;
  MemFree: number;
  DiskTotal: number;
  DiskUsed: number;
  DiskFree: number;
  collectedAt: Date | undefined;
}

export interface PortChannelMemberState {
  id: PathId | undefined;
  name: string;
  memberInterfaceName: string;
  devAddr: string;
  ifIndex: number;
  linkUp: boolean;
  linkWatchUp: boolean;
  actorLacpduInfoPort: number;
  actorLacpduInfoState: number;
  actorLacpduInfoSystem: string;
  partnerLacpduInfoPort: number;
  partnerLacpduInfoState: number;
  partnerLacpduInfoSystem: string;
  aggregatorId: number;
  aggregatorSelected: boolean;
  selected: boolean;
  state: PortChannelMemberInfoState;
  protocol: PortChannelProtocol;
  collectedAt: Date | undefined;
}

export interface PortChannelState {
  id: PathId | undefined;
  name: string;
  adminStatus: OperState;
  mtu: number;
  operStatus: OperState;
  state: PortChannelStatus;
  runnerActive: boolean;
  runnerFallback: boolean;
  runnerFastRate: boolean;
  setupKernelTeamModeName: PortChannelKernelTeamMode;
  setupPid: number;
  teamDeviceIfInfoDevAddr: string;
  teamDeviceIfInfoIfIndex: number;
  protocol: PortChannelProtocol;
  collectedAt: Date | undefined;
}

/** FIXME: Should not need DeviceType */
export interface OrgUuid {
  id: PathId | undefined;
}

export interface FabricUuid {
  id: PathId | undefined;
}

export interface OrgServiceSvc {
  id: PathId | undefined;
}

export interface OrgServiceProto {
  id: PathId | undefined;
}

export interface OrgServiceRoute {
  id: PathId | undefined;
  orgUuid: string;
  fabricUuid: string;
  svc: string;
  proto: string;
  host: string;
  port: number;
  isLocal: boolean;
  revision: number;
  orgKind: OrgKind;
  fabricType: FabricType;
  updatedTs: Date | undefined;
}

export interface OrgToCellPairMapping {
  id: PathId | undefined;
  orgUuid: string;
  cellPairUuid: string;
  /** fred for cell A */
  cellASvcHost: string;
  /** fred for cell B */
  cellBSvcHost: string;
  /** monotonically increasing number */
  generation: number;
  orgKind: OrgKind;
  updatedTs: Date | undefined;
}

export interface QsfpInfo {
  id: PathId | undefined;
  activeApselHostlane1: boolean;
  activeApselHostlane2: boolean;
  activeApselHostlane3: boolean;
  activeApselHostlane4: boolean;
  activeApselHostlane5: boolean;
  activeApselHostlane6: boolean;
  activeApselHostlane7: boolean;
  activeApselHostlane8: boolean;
  activeFirmware: string;
  applicationAdvertisement: string;
  cableLength: number;
  cableType: string;
  cmisRev: number;
  connector: string;
  domCapability: string;
  encoding: string;
  extIdentifier: string;
  extRateselectCompliance: string;
  hardwareRev: number;
  hostElectricalInterface: string;
  hostLaneAssignmentOption: number;
  hostLaneCount: number;
  inactiveFirmware: string;
  isReplaceable: boolean;
  manufacturer: string;
  mediaInterfaceCode: string;
  mediaInterfaceTechnology: string;
  mediaLaneAssignmentOption: number;
  mediaLaneCount: number;
  model: string;
  nominalBitRate: number;
  serial: string;
  specificationCompliance: string;
  supportedMaxLaserFreq: number;
  supportedMaxTxPower: number;
  supportedMinLaserFreq: number;
  supportedMinTxPower: number;
  type: string;
  vendorDate: string;
  vendorOui: string;
  vendorRev: string;
  transceiverModule: QsfpTransceiverModule;
  collectedAt: Date | undefined;
}

export interface ApiPath {
  id: PathId | undefined;
}

export interface ApiPathMethod {
  id: PathId | undefined;
}

export interface ApiServiceMapping {
  id: PathId | undefined;
  api: string;
  method: string;
  svc: string;
}

export interface TenantUuid {
  id: PathId | undefined;
}

export interface UserEmail {
  id: PathId | undefined;
  orgUuid: string;
  tenantUuid: string;
  userEmail: string;
}

export interface ServiceInstanceHealth {
  id: PathId | undefined;
  collectedAt:
    | Date
    | undefined;
  /** UUID representing the "incarnation" of this instance (e.g. container uuid) */
  instanceUid: string;
  memoryTotal: number;
  memoryFree: number;
  /** Number of seconds of CPU user runtime since process start */
  cpuUserTime: number;
  /** Number of seconds of CPU system runtime since process start */
  cpuSystemTime: number;
}

export interface AssertConfigLinkUpData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortLinkUp {
  id: PathId | undefined;
  config: AssertConfigLinkUpData | undefined;
}

export interface AssertPortLinkUp {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigLinkUpData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigLinkDownData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortLinkDown {
  id: PathId | undefined;
  config: AssertConfigLinkDownData | undefined;
}

export interface AssertPortLinkDown {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigLinkDownData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  remoteDeviceId: string;
  remotePortName: string;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigConnectedToCloudData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigDeviceConnectedToCloud {
  id: PathId | undefined;
  config: AssertConfigConnectedToCloudData | undefined;
}

export interface AssertDeviceConnectedToCloud {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigConnectedToCloudData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigExpectedNeighborData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortExpectedNeighbor {
  id: PathId | undefined;
  config: AssertConfigExpectedNeighborData | undefined;
}

export interface AssertPortExpectedNeighbor {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigExpectedNeighborData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  remoteDeviceId: string;
  remotePortName: string;
  numNeighbors: number;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigNotConnectedToFabricData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortNotConnectedToFabric {
  id: PathId | undefined;
  config: AssertConfigNotConnectedToFabricData | undefined;
}

export interface AssertPortNotConnectedToFabric {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigNotConnectedToFabricData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  remoteDeviceId: string;
  remotePortName: string;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigConnectedToFabricData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigDeviceConnectedToFabric {
  id: PathId | undefined;
  config: AssertConfigConnectedToFabricData | undefined;
}

export interface AssertDeviceConnectedToFabric {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigConnectedToFabricData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigConnectedPortSpeedsMatchData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortConnectionSpeedMatch {
  id: PathId | undefined;
  config: AssertConfigConnectedPortSpeedsMatchData | undefined;
}

export interface AssertPortConnectionSpeedMatch {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigConnectedPortSpeedsMatchData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  localPortSpeed: number;
  remotePortName: string;
  remoteDeviceId: string;
  remotePortSpeed: number;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigPortSpeedConsistentData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  port: PortConfigDetails | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigPortSpeedConsistent {
  id: PathId | undefined;
  config: AssertConfigPortSpeedConsistentData | undefined;
}

export interface AssertPortSpeedConsistent {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigPortSpeedConsistentData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  localPortSpeed: number;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigVlanHasTrafficData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigVlanHasTraffic {
  id: PathId | undefined;
  config: AssertConfigVlanHasTrafficData | undefined;
}

export interface AssertVlanHasTraffic {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigVlanHasTrafficData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  lastTrafficBytes: number;
  lastTrafficTime: Date | undefined;
  recentTrafficBytes: number;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigStaticDefaultRouteExistsData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigStaticDefaultRouteExists {
  id: PathId | undefined;
  config: AssertConfigStaticDefaultRouteExistsData | undefined;
}

export interface AssertStaticDefaultRouteExists {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigStaticDefaultRouteExistsData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigManagementPortConfigData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  mgmtPortState: ManagementState | undefined;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigManagementPortConfigSame {
  id: PathId | undefined;
  config: AssertConfigManagementPortConfigData | undefined;
}

export interface AssertManagementPortConfigSame {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigManagementPortConfigData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  operationalMgmtPortState: ManagementState | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigExpectedPortBreakoutExistsData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  expectedPortBreakoutCfg: PortBreakoutConfig | undefined;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigExpectedPortBreakoutExists {
  id: PathId | undefined;
  config: AssertConfigExpectedPortBreakoutExistsData | undefined;
}

export interface AssertExpectedPortBreakoutExists {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigExpectedPortBreakoutExistsData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface PortBreakoutConfig {
  speed: number;
  media: CableType;
  name: string;
}

export interface AssertConfigDeviceResourceUsageBelowThresholdData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  cpuThreshold: number;
  memoryThreshold: number;
  diskThreshold: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigDeviceResourceUsageBelowThreshold {
  id: PathId | undefined;
  config: AssertConfigDeviceResourceUsageBelowThresholdData | undefined;
}

export interface AssertDeviceResourceUsageBelowThreshold {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigDeviceResourceUsageBelowThresholdData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  currentSystemStats: SystemStats | undefined;
  highCpuUtil: boolean;
  highMemUtil: boolean;
  highDiskUtil: boolean;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigBgpPeerEstablishedData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  implicit: boolean;
  orgId: string;
  peerRemoteAs: number;
  vrfName: string;
  fabricId: string;
}

export interface AssertConfigBgpPeerEstablished {
  id: PathId | undefined;
  config: AssertConfigBgpPeerEstablishedData | undefined;
}

export interface AssertBgpPeerEstablished {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigBgpPeerEstablishedData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigIpmLatencyBelowThresholdData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  suspiciousThreshold: number;
  criticalThreshold: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigIpmLatencyBelowThreshold {
  id: PathId | undefined;
  config: AssertConfigIpmLatencyBelowThresholdData | undefined;
}

export interface AssertIpmLatencyBelowThreshold {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigIpmLatencyBelowThresholdData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  currentLatencyStats: IpmProcLatencyCounters | undefined;
  suspiciousLatency: boolean;
  criticalLatency: boolean;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface AssertConfigIpmLossBelowThresholdData {
  generationId: number;
  latchedAt: Date | undefined;
  latchTime: number;
  suspiciousThreshold: number;
  criticalThreshold: number;
  implicit: boolean;
  orgId: string;
  fabricId: string;
}

export interface AssertConfigIpmLossBelowThreshold {
  id: PathId | undefined;
  config: AssertConfigIpmLossBelowThresholdData | undefined;
}

export interface AssertIpmLossBelowThreshold {
  id: PathId | undefined;
  category: AssertCategoryType;
  assertState: AssertStateType;
  modifiedAt: Date | undefined;
  config: AssertConfigIpmLossBelowThresholdData | undefined;
  masked: boolean;
  readyToLatchAt: Date | undefined;
  currentLossStats: IpmProcLossCounters | undefined;
  suspiciousLoss: boolean;
  criticalLoss: boolean;
  maskedByPortLinkUp: boolean;
  maskedByConnectionToCloud: boolean;
}

export interface SonicConfig {
  ipv4Addr: string;
  ipv6Addr: string;
  ipv4Gateway: string;
  ipv6Gateway: string;
  hostname: string;
}

export interface ProxyConfig {
  httpProxy: string;
  httpsProxy: string;
  noProxy: string;
  user: string;
  pass: string;
}

export interface DnsConfig {
  dnsServers: string[];
}

export interface DrakeConfig {
  cloudBaseUrlList: string[];
}

/**
 * ServerBmcMinimalHardwareInfo
 * On BMC's with Redfish this information is available pre-authorization from the resource Service Root /redfish/v1
 */
export interface ServerBmcPreAuthInfo {
  id: string;
  vendor: string;
  product: string;
}

export interface ServerFirmwareInventoryItem {
  name: string;
  description: string;
  manufacturer: string;
  version: string;
}

export interface ServerProcessorId {
  family: string;
  model: string;
  step: string;
  vendorId: string;
}

export interface ServerProcessor {
  id: string;
  name: string;
  description: string;
  instructionSet: string;
  manufacturer: string;
  operatingSpeedMhz: number;
  processorId: ServerProcessorId | undefined;
  socket: string;
  totalCores: number;
  totalThreads: number;
}

export interface ServerMemorySummary {
  TotalSystemMemoryGiB: number;
}

export interface ServerMemory {
  id: string;
  name: string;
  capacityMiB: number;
  deviceLocator: string;
  manufacturer: string;
  memoryType: string;
  operatingSpeedMhz: number;
  partNumber: string;
  serialNumber: string;
}

export interface ServerTrustedModule {
  firmwareVersion: string;
  interfaceType: string;
}

export interface ServerEthernetInterface {
  id: string;
  name: string;
  description: string;
  macAddress: string;
}

export interface ServerPcieInterface {
  maxLanes: number;
  lanesInUse: number;
  pcieType: string;
}

/** ServerPcieDevice is populated from this resource of the Redfish data model from Chassis/<chassis>/PCIeDevices/<device>/index.json */
export interface ServerPcieDevice {
  id: string;
  /** -> Slot/Location/PartLocation/LocationOrdinalValue */
  slotNumber: number;
  /** Exceeds 32 bytes :( */
  name: string;
  partNumber: string;
  firmwareVersion: string;
  manufacturer: string;
  sku: string;
  serialNumber: string;
  model: string;
  pcieInterface: ServerPcieInterface | undefined;
}

export interface ServerPCIeFunction {
  id: string;
  name: string;
  vendorId: string;
  deviceId: string;
  deviceName: string;
  ethernetInterfaceIds: string[];
}

export interface ServerStorageController {
  id: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  firmwareVersion: string;
}

export interface ServerStorage {
  id: string;
  name: string;
  description: string;
  controllers: ServerStorageController[];
}

export interface ServerSystem {
  id: string;
  name: string;
  description: string;
  powerState: string;
  health: string;
  biosVersion: string;
  hostName: string;
  memorySummary: ServerMemorySummary | undefined;
  trustedModules: ServerTrustedModule[];
  ethernetInterfaces: ServerEthernetInterface[];
  pcieDevices: ServerPcieDevice[];
  pcieFunctions: ServerPCIeFunction[];
  processors: ServerProcessor[];
  memorys: ServerMemory[];
  storages: ServerStorage[];
}

export interface ServerDrive {
  id: string;
  name: string;
  description: string;
  manufacturer: string;
  model: string;
  mediaType: string;
  blockSizeBytes: number;
  capacityBytes: number;
  revision: string;
  serialNumber: string;
}

export interface ServerTemperature {
  name: string;
  sensorNumber: number;
  memberId: string;
  physicalContext: string;
  readingCelsius: number;
  lowerThresholdCritical: number;
  lowerThresholdNonCritical: number;
  upperThresholdCritical: number;
  upperThresholdNonCritical: number;
  minReadingRangeTemp: number;
  maxReadingRangeTemp: number;
}

export interface ServerThermal {
  temperatures: ServerTemperature[];
}

export interface ServerVoltage {
  name: string;
  sensorNumber: number;
  memberId: string;
  physicalContext: string;
  readingVolts: number;
  lowerThresholdCritical: number;
  lowerThresholdNonCritical: number;
  upperThresholdCritical: number;
  upperThresholdNonCritical: number;
  minReadingRangeVolts: number;
  maxReadingRangeVolts: number;
}

export interface ServerInputRange {
  minimumVoltage: number;
  maximumVoltage: number;
  minimumFrequencyHz: number;
  maximumFrequencyHz: number;
  outputWattage: number;
}

export interface ServerPowerSupply {
  name: string;
  memberId: string;
  manufacturer: string;
  model: string;
  partNumber: string;
  serialNumber: string;
  sparePartNumber: string;
  firmwareVersion: string;
  powerSupplyType: string;
  lineInputVoltage: number;
  powerInputWatts: number;
  inputRanges: ServerInputRange[];
}

export interface ServerPower {
  powerSupplies: ServerPowerSupply[];
  voltages: ServerVoltage[];
}

export interface ServerAssembly {
  memberId: string;
  producer: string;
  model: string;
  serialNumber: string;
  version: string;
  physicalContext: string;
}

export interface ServerNetworkAdaptor {
  id: string;
  manufacturer: string;
  model: string;
  partNumber: string;
  serialNumber: string;
}

/**
 * A 'chassis' in the redfish model is not the same as what we are calling a 'device'.
 * A BMC with a redfish server on it will appear to us as a device, but the redfish model has an abstraction called chassis that allows a vendor to model multiple hardware complexes.
 * So we'll call this Redfish 'chassis' a 'ServerChassis'.
 * So in future if we need to model multiple ServerChassis the relationships are:
 *  Device -> ServerChassis 1
 *         -> ServerChassis 2
 *         -> .....
 */
export interface ServerChassis {
  id: string;
  name: string;
  description: string;
  manufacturer: string;
  model: string;
  serialNumber: string;
  partNumber: string;
  chassisType: string;
  uuid: string;
  heightMm: number;
  widthMm: number;
  depthMm: number;
  weightKg: number;
  drives: ServerDrive[];
  thermal: ServerThermal | undefined;
  power: ServerPower | undefined;
  powerState: string;
  assemblies: ServerAssembly[];
  networkAdaptors: ServerNetworkAdaptor[];
  pcieDevices: ServerPcieDevice[];
}

export interface ServerManager {
  id: string;
  name: string;
  description: string;
  managerType: string;
  model: string;
  uuid: string;
  firmwareVersion: string;
}

export interface ServerCertificate {
  id: string;
  name: string;
  description: string;
  certString: string;
  type: string;
}

export interface SPDMresponderAuth {
  certificate: ServerCertificate | undefined;
}

export interface SPDMidentity {
  respAuth: SPDMresponderAuth | undefined;
}

export interface SPDMInfo {
  identity: SPDMidentity | undefined;
}

export interface ServerComponentIntegrity {
  enabled: boolean;
  type: string;
  version: string;
  description: string;
  lastUpdated: string;
  spdm: SPDMInfo | undefined;
}

export interface ServerSmartNicCard {
  serialNum: string;
  /** Only valid when BMCType is SERVER, as the SMART_NIC BMC does not have slot information */
  slotId: string;
}

/**
 * For now we include minimal information to support immediate functionality requirements.
 * More Redfish derived data will be included as needed
 */
export interface ServerBmcInfo {
  /** Note this can be updated after initial discovery (cards added or removed) */
  smartNicCards: ServerSmartNicCard[];
}

/** Similarly for the SmartNic BMC we just include the minimum that is necessary for what we need to do now. Will be added to as required. */
export interface SmartNicBmcInfo {
}

export interface ManagedEndpointServerInfo {
  manufacturer: string;
  product: string;
  version: string;
  serialNum: string;
  uuid: string;
  sku: string;
  /** Populated for DPU BMC and DPU, but not from a Server BMC */
  pcieSlot: number;
  /** Populated for DPU BMC and DPU, but not from a Server BMC */
  pcieBus: number;
  /** Populated for DPU BMC and DPU, but not from a Server BMC */
  pcieFunction: number;
}

/** Cannot include time, as we hash on this to determine change */
export interface ManagedEndpointLldpInfo {
  /** LldpRemChassisId -> MAC Address */
  macAddress: string;
  /** lldpSysName */
  sysName: string;
  /** lldpSysDesc */
  sysDesc: string;
  /** LocalPort */
  localInterface: string;
  /** lldpRemManAddr -> We expect a IPv6 LLA here, and possibly on IPv4 address with optionally more IPv6 addresses */
  mgmtAddrs: string[];
  serverInfo: ManagedEndpointServerInfo | undefined;
}

export interface ManagedEndpointDeviceInfo {
  manufacturer: string;
  model: string;
  productName: string;
  partNumber: string;
  serialNumber: string;
  uuid: string;
  powerState: PowerStateEnum;
  pcieDevices: ServerPcieDevice[];
}

export interface ManagedEndpoint {
  /** Information available in LLDP */
  lldpInfo: ManagedEndpointLldpInfo | undefined;
  lastLldpReceivedAt:
    | Date
    | undefined;
  /** Last time we successfully connected to Managed Endpoint */
  lastConnectedAt: Date | undefined;
  connectivityStatus: ConnectivityStatus;
  connectionReason: string;
  meType: ManagedEndpointType;
  supportsIdentityAuth: boolean;
  identityAuthOk: boolean;
  deviceInfo: ManagedEndpointDeviceInfo | undefined;
}

/** Discovered BMCs */
export interface Bmc {
  id: PathId | undefined;
  managedEndpoint:
    | ManagedEndpoint
    | undefined;
  /** Information below is available pre-authentication via a non-authenticated query to /redfish/v1/ */
  preAuthInfo: ServerBmcPreAuthInfo | undefined;
  bmcProduct: BmcProductType;
  /**
   * Information below is available Post authentication - the actual parts of the extensive BMC model we utilize will be adjusted based on requirements.
   * Query is done to get all firmware inventory items.
   */
  firmwareInventory: ServerFirmwareInventoryItem[];
  /** Only valid if BmcFamilyType = BMC_SERVER */
  serverBmcInfo:
    | ServerBmcInfo
    | undefined;
  /** Only valid if BmcFamilyType = BMC_SMART_NIC */
  smartNicBmcInfo: SmartNicBmcInfo | undefined;
}

export interface ServerVoltageSensor {
  id: PathId | undefined;
  voltageInfo: ServerVoltage | undefined;
  collectedAt: Date | undefined;
}

export interface ServerTemperatureSensor {
  id: PathId | undefined;
  temperatureInfo: ServerTemperature | undefined;
  collectedAt: Date | undefined;
}

/** Discovered SmartNic DPU complex */
export interface SmartNicDpu {
  id: PathId | undefined;
  managedEndpoint: ManagedEndpoint | undefined;
  dpuProduct: SmartNicDpuProductType;
}

/**
 * This is created when the BMC is bound, its used by Lira to understand
 * 1) A detected BMC is now bound and may be accessed
 * 2) If Cert based auth is not supported it will convey the BMC Creds.
 */
export interface BmcConfig {
  /** Future stuff... */
  id: PathId | undefined;
}

/**
 * BearerToken describes the metadata for a bearer token used during a REST API call.
 * Bearer tokens are scoped to a user within a tenant.
 */
export interface BearerToken {
  id:
    | PathId
    | undefined;
  /** The orgId that owns the tenant that owns the token. */
  orgId: string;
  /** The tenant that owns the token. */
  tenantId: string;
  /** The token owner. */
  email: string;
  /** The token owner provider. */
  provider: KnownProvider;
  /** The token owner role. */
  userRole: UserRole;
  /** The unique id for the token. This is embedded in the token JWT as the JTI claim. */
  tokenId: string;
  /** The fingerprint of the token. */
  fingerprint: string;
  /** The start of the validity period for a token. */
  notBefore:
    | Date
    | undefined;
  /** The end of the validity period for a token. */
  expires:
    | Date
    | undefined;
  /** The hardcoded scope of the token, read-write, admin etc. */
  scope: TokenScope;
  /** An ed25519 public key used to verify the token. The private key is owned by Brig. */
  signingPublicKey: string;
  /** The fingerprint of the signing public key. */
  signingPublicKeyFp: string;
}

/**
 * ApiKey describes the metadata for an API key used during a REST API call.
 * API keys are scoped to a user within a tenant.
 */
export interface ApiKey {
  id:
    | PathId
    | undefined;
  /** The orgId that owns the tenant that owns the key. */
  orgId: string;
  /** The tenant that owns the key. */
  tenantId: string;
  /** The key owner. */
  email: string;
  /** The key owner provider. */
  provider: KnownProvider;
  /** The key owner role. */
  userRole: UserRole;
  /** The unique id for the key. This is provided in the Authorization header in the request. */
  keyId: string;
  /** The fingerprint of the key. */
  fingerprint: string;
  /** The start of the validity period for a key. */
  notBefore:
    | Date
    | undefined;
  /** The end of the validity period for a key. */
  notAfter:
    | Date
    | undefined;
  /** The hardcoded scope of the key, read-write, admin etc. */
  scope: TokenScope;
  /** The key type. */
  keyType: RestKeyType;
  /**
   * The ed25519/RSA public key, provided by the user. The private key is not known to us.
   *
   * FIXME
   * Right now this is fixed at 64 which is enough for a hex encoded ed25519 public key.
   * RSA public keys are much larger, but whether we support RSA at all is still TBD.
   */
  publicKey: string;
}

function createBaseTestSubMessage(): TestSubMessage {
  return { subInt32: 0, subRepeatedString: [] };
}

export const TestSubMessage = {
  encode(message: TestSubMessage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subInt32 !== 0) {
      writer.uint32(8).int32(message.subInt32);
    }
    for (const v of message.subRepeatedString) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestSubMessage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestSubMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.subInt32 = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.subRepeatedString.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestSubMessage {
    return {
      subInt32: isSet(object.subInt32) ? globalThis.Number(object.subInt32) : 0,
      subRepeatedString: globalThis.Array.isArray(object?.subRepeatedString)
        ? object.subRepeatedString.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: TestSubMessage): unknown {
    const obj: any = {};
    if (message.subInt32 !== 0) {
      obj.subInt32 = Math.round(message.subInt32);
    }
    if (message.subRepeatedString?.length) {
      obj.subRepeatedString = message.subRepeatedString;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestSubMessage>, I>>(base?: I): TestSubMessage {
    return TestSubMessage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestSubMessage>, I>>(object: I): TestSubMessage {
    const message = createBaseTestSubMessage();
    message.subInt32 = object.subInt32 ?? 0;
    message.subRepeatedString = object.subRepeatedString?.map((e) => e) || [];
    return message;
  },
};

function createBaseTestRootOne(): TestRootOne {
  return {
    id: undefined,
    rootBool: false,
    rootEnum: 0,
    rootInt32: 0,
    rootSint32: 0,
    rootSfixed32: 0,
    rootUint32: 0,
    rootInt64: 0,
    rootSint64: 0,
    rootSfixed64: 0,
    rootFloat: 0,
    rootDouble: 0,
    rootString: "",
    rootBytes: new Uint8Array(0),
    rootTimestamp: undefined,
    rootMacaddress: "",
    rstr: [],
    rint32: [],
    subMessage: [],
    rootIpaddress: "",
    rootIpprefix: "",
  };
}

export const TestRootOne = {
  encode(message: TestRootOne, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.rootBool !== false) {
      writer.uint32(16).bool(message.rootBool);
    }
    if (message.rootEnum !== 0) {
      writer.uint32(24).int32(message.rootEnum);
    }
    if (message.rootInt32 !== 0) {
      writer.uint32(32).int32(message.rootInt32);
    }
    if (message.rootSint32 !== 0) {
      writer.uint32(40).sint32(message.rootSint32);
    }
    if (message.rootSfixed32 !== 0) {
      writer.uint32(53).sfixed32(message.rootSfixed32);
    }
    if (message.rootUint32 !== 0) {
      writer.uint32(56).uint32(message.rootUint32);
    }
    if (message.rootInt64 !== 0) {
      writer.uint32(64).int64(message.rootInt64);
    }
    if (message.rootSint64 !== 0) {
      writer.uint32(72).sint64(message.rootSint64);
    }
    if (message.rootSfixed64 !== 0) {
      writer.uint32(81).sfixed64(message.rootSfixed64);
    }
    if (message.rootFloat !== 0) {
      writer.uint32(93).float(message.rootFloat);
    }
    if (message.rootDouble !== 0) {
      writer.uint32(97).double(message.rootDouble);
    }
    if (message.rootString !== "") {
      writer.uint32(106).string(message.rootString);
    }
    if (message.rootBytes.length !== 0) {
      writer.uint32(114).bytes(message.rootBytes);
    }
    if (message.rootTimestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.rootTimestamp), writer.uint32(122).fork()).ldelim();
    }
    if (message.rootMacaddress !== "") {
      writer.uint32(130).string(message.rootMacaddress);
    }
    for (const v of message.rstr) {
      writer.uint32(138).string(v!);
    }
    writer.uint32(146).fork();
    for (const v of message.rint32) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.subMessage) {
      TestSubMessage.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    if (message.rootIpaddress !== "") {
      writer.uint32(162).string(message.rootIpaddress);
    }
    if (message.rootIpprefix !== "") {
      writer.uint32(170).string(message.rootIpprefix);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestRootOne {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestRootOne();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rootBool = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rootEnum = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.rootInt32 = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.rootSint32 = reader.sint32();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.rootSfixed32 = reader.sfixed32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.rootUint32 = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.rootInt64 = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.rootSint64 = longToNumber(reader.sint64() as Long);
          continue;
        case 10:
          if (tag !== 81) {
            break;
          }

          message.rootSfixed64 = longToNumber(reader.sfixed64() as Long);
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.rootFloat = reader.float();
          continue;
        case 12:
          if (tag !== 97) {
            break;
          }

          message.rootDouble = reader.double();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.rootString = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.rootBytes = reader.bytes();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.rootTimestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.rootMacaddress = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.rstr.push(reader.string());
          continue;
        case 18:
          if (tag === 144) {
            message.rint32.push(reader.int32());

            continue;
          }

          if (tag === 146) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rint32.push(reader.int32());
            }

            continue;
          }

          break;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.subMessage.push(TestSubMessage.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.rootIpaddress = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.rootIpprefix = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestRootOne {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      rootBool: isSet(object.rootBool) ? globalThis.Boolean(object.rootBool) : false,
      rootEnum: isSet(object.rootEnum) ? testEnumFromJSON(object.rootEnum) : 0,
      rootInt32: isSet(object.rootInt32) ? globalThis.Number(object.rootInt32) : 0,
      rootSint32: isSet(object.rootSint32) ? globalThis.Number(object.rootSint32) : 0,
      rootSfixed32: isSet(object.rootSfixed32) ? globalThis.Number(object.rootSfixed32) : 0,
      rootUint32: isSet(object.rootUint32) ? globalThis.Number(object.rootUint32) : 0,
      rootInt64: isSet(object.rootInt64) ? globalThis.Number(object.rootInt64) : 0,
      rootSint64: isSet(object.rootSint64) ? globalThis.Number(object.rootSint64) : 0,
      rootSfixed64: isSet(object.rootSfixed64) ? globalThis.Number(object.rootSfixed64) : 0,
      rootFloat: isSet(object.rootFloat) ? globalThis.Number(object.rootFloat) : 0,
      rootDouble: isSet(object.rootDouble) ? globalThis.Number(object.rootDouble) : 0,
      rootString: isSet(object.rootString) ? globalThis.String(object.rootString) : "",
      rootBytes: isSet(object.rootBytes) ? bytesFromBase64(object.rootBytes) : new Uint8Array(0),
      rootTimestamp: isSet(object.rootTimestamp) ? fromJsonTimestamp(object.rootTimestamp) : undefined,
      rootMacaddress: isSet(object.rootMacaddress) ? globalThis.String(object.rootMacaddress) : "",
      rstr: globalThis.Array.isArray(object?.rstr) ? object.rstr.map((e: any) => globalThis.String(e)) : [],
      rint32: globalThis.Array.isArray(object?.rint32) ? object.rint32.map((e: any) => globalThis.Number(e)) : [],
      subMessage: globalThis.Array.isArray(object?.subMessage)
        ? object.subMessage.map((e: any) => TestSubMessage.fromJSON(e))
        : [],
      rootIpaddress: isSet(object.rootIpaddress) ? globalThis.String(object.rootIpaddress) : "",
      rootIpprefix: isSet(object.rootIpprefix) ? globalThis.String(object.rootIpprefix) : "",
    };
  },

  toJSON(message: TestRootOne): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.rootBool !== false) {
      obj.rootBool = message.rootBool;
    }
    if (message.rootEnum !== 0) {
      obj.rootEnum = testEnumToJSON(message.rootEnum);
    }
    if (message.rootInt32 !== 0) {
      obj.rootInt32 = Math.round(message.rootInt32);
    }
    if (message.rootSint32 !== 0) {
      obj.rootSint32 = Math.round(message.rootSint32);
    }
    if (message.rootSfixed32 !== 0) {
      obj.rootSfixed32 = Math.round(message.rootSfixed32);
    }
    if (message.rootUint32 !== 0) {
      obj.rootUint32 = Math.round(message.rootUint32);
    }
    if (message.rootInt64 !== 0) {
      obj.rootInt64 = Math.round(message.rootInt64);
    }
    if (message.rootSint64 !== 0) {
      obj.rootSint64 = Math.round(message.rootSint64);
    }
    if (message.rootSfixed64 !== 0) {
      obj.rootSfixed64 = Math.round(message.rootSfixed64);
    }
    if (message.rootFloat !== 0) {
      obj.rootFloat = message.rootFloat;
    }
    if (message.rootDouble !== 0) {
      obj.rootDouble = message.rootDouble;
    }
    if (message.rootString !== "") {
      obj.rootString = message.rootString;
    }
    if (message.rootBytes.length !== 0) {
      obj.rootBytes = base64FromBytes(message.rootBytes);
    }
    if (message.rootTimestamp !== undefined) {
      obj.rootTimestamp = message.rootTimestamp.toISOString();
    }
    if (message.rootMacaddress !== "") {
      obj.rootMacaddress = message.rootMacaddress;
    }
    if (message.rstr?.length) {
      obj.rstr = message.rstr;
    }
    if (message.rint32?.length) {
      obj.rint32 = message.rint32.map((e) => Math.round(e));
    }
    if (message.subMessage?.length) {
      obj.subMessage = message.subMessage.map((e) => TestSubMessage.toJSON(e));
    }
    if (message.rootIpaddress !== "") {
      obj.rootIpaddress = message.rootIpaddress;
    }
    if (message.rootIpprefix !== "") {
      obj.rootIpprefix = message.rootIpprefix;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestRootOne>, I>>(base?: I): TestRootOne {
    return TestRootOne.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestRootOne>, I>>(object: I): TestRootOne {
    const message = createBaseTestRootOne();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.rootBool = object.rootBool ?? false;
    message.rootEnum = object.rootEnum ?? 0;
    message.rootInt32 = object.rootInt32 ?? 0;
    message.rootSint32 = object.rootSint32 ?? 0;
    message.rootSfixed32 = object.rootSfixed32 ?? 0;
    message.rootUint32 = object.rootUint32 ?? 0;
    message.rootInt64 = object.rootInt64 ?? 0;
    message.rootSint64 = object.rootSint64 ?? 0;
    message.rootSfixed64 = object.rootSfixed64 ?? 0;
    message.rootFloat = object.rootFloat ?? 0;
    message.rootDouble = object.rootDouble ?? 0;
    message.rootString = object.rootString ?? "";
    message.rootBytes = object.rootBytes ?? new Uint8Array(0);
    message.rootTimestamp = object.rootTimestamp ?? undefined;
    message.rootMacaddress = object.rootMacaddress ?? "";
    message.rstr = object.rstr?.map((e) => e) || [];
    message.rint32 = object.rint32?.map((e) => e) || [];
    message.subMessage = object.subMessage?.map((e) => TestSubMessage.fromPartial(e)) || [];
    message.rootIpaddress = object.rootIpaddress ?? "";
    message.rootIpprefix = object.rootIpprefix ?? "";
    return message;
  },
};

function createBaseTestRootTwo(): TestRootTwo {
  return { id: undefined };
}

export const TestRootTwo = {
  encode(message: TestRootTwo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestRootTwo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestRootTwo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestRootTwo {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestRootTwo): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestRootTwo>, I>>(base?: I): TestRootTwo {
    return TestRootTwo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestRootTwo>, I>>(object: I): TestRootTwo {
    const message = createBaseTestRootTwo();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTestMidOne(): TestMidOne {
  return { id: undefined };
}

export const TestMidOne = {
  encode(message: TestMidOne, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestMidOne {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestMidOne();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestMidOne {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestMidOne): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestMidOne>, I>>(base?: I): TestMidOne {
    return TestMidOne.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestMidOne>, I>>(object: I): TestMidOne {
    const message = createBaseTestMidOne();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTestLeafOne(): TestLeafOne {
  return { id: undefined };
}

export const TestLeafOne = {
  encode(message: TestLeafOne, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestLeafOne {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestLeafOne();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestLeafOne {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestLeafOne): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestLeafOne>, I>>(base?: I): TestLeafOne {
    return TestLeafOne.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestLeafOne>, I>>(object: I): TestLeafOne {
    const message = createBaseTestLeafOne();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTestIpPrefix(): TestIpPrefix {
  return { id: undefined };
}

export const TestIpPrefix = {
  encode(message: TestIpPrefix, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestIpPrefix {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestIpPrefix();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestIpPrefix {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestIpPrefix): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestIpPrefix>, I>>(base?: I): TestIpPrefix {
    return TestIpPrefix.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestIpPrefix>, I>>(object: I): TestIpPrefix {
    const message = createBaseTestIpPrefix();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTestIpAddress(): TestIpAddress {
  return { id: undefined };
}

export const TestIpAddress = {
  encode(message: TestIpAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestIpAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestIpAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestIpAddress {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestIpAddress): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestIpAddress>, I>>(base?: I): TestIpAddress {
    return TestIpAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestIpAddress>, I>>(object: I): TestIpAddress {
    const message = createBaseTestIpAddress();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTestMacAddress(): TestMacAddress {
  return { id: undefined };
}

export const TestMacAddress = {
  encode(message: TestMacAddress, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestMacAddress {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestMacAddress();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestMacAddress {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestMacAddress): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestMacAddress>, I>>(base?: I): TestMacAddress {
    return TestMacAddress.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestMacAddress>, I>>(object: I): TestMacAddress {
    const message = createBaseTestMacAddress();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseBasicCounters(): BasicCounters {
  return { bytesIn: 0, bytesOut: 0, pktsIn: 0, pktsOut: 0, errPktsIn: 0, errPktsOut: 0 };
}

export const BasicCounters = {
  encode(message: BasicCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.bytesIn !== 0) {
      writer.uint32(16).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(24).uint64(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      writer.uint32(32).uint64(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      writer.uint32(40).uint64(message.pktsOut);
    }
    if (message.errPktsIn !== 0) {
      writer.uint32(48).uint64(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      writer.uint32(56).uint64(message.errPktsOut);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BasicCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBasicCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.errPktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.errPktsOut = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BasicCounters {
    return {
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      pktsIn: isSet(object.pktsIn) ? globalThis.Number(object.pktsIn) : 0,
      pktsOut: isSet(object.pktsOut) ? globalThis.Number(object.pktsOut) : 0,
      errPktsIn: isSet(object.errPktsIn) ? globalThis.Number(object.errPktsIn) : 0,
      errPktsOut: isSet(object.errPktsOut) ? globalThis.Number(object.errPktsOut) : 0,
    };
  },

  toJSON(message: BasicCounters): unknown {
    const obj: any = {};
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      obj.pktsIn = Math.round(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      obj.pktsOut = Math.round(message.pktsOut);
    }
    if (message.errPktsIn !== 0) {
      obj.errPktsIn = Math.round(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      obj.errPktsOut = Math.round(message.errPktsOut);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BasicCounters>, I>>(base?: I): BasicCounters {
    return BasicCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BasicCounters>, I>>(object: I): BasicCounters {
    const message = createBaseBasicCounters();
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.pktsIn = object.pktsIn ?? 0;
    message.pktsOut = object.pktsOut ?? 0;
    message.errPktsIn = object.errPktsIn ?? 0;
    message.errPktsOut = object.errPktsOut ?? 0;
    return message;
  },
};

function createBaseFabric(): Fabric {
  return { id: undefined, orgId: "", numDevices: 0 };
}

export const Fabric = {
  encode(message: Fabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(26).string(message.orgId);
    }
    if (message.numDevices !== 0) {
      writer.uint32(32).uint32(message.numDevices);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Fabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.numDevices = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fabric {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      numDevices: isSet(object.numDevices) ? globalThis.Number(object.numDevices) : 0,
    };
  },

  toJSON(message: Fabric): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.numDevices !== 0) {
      obj.numDevices = Math.round(message.numDevices);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fabric>, I>>(base?: I): Fabric {
    return Fabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fabric>, I>>(object: I): Fabric {
    const message = createBaseFabric();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgId = object.orgId ?? "";
    message.numDevices = object.numDevices ?? 0;
    return message;
  },
};

function createBaseFabricDevice(): FabricDevice {
  return {
    id: undefined,
    deviceId: "",
    fabricId: "",
    orgId: "",
    nodeId: "",
    majorLatchedFalseAsserts: 0,
    majorUnlatchedFalseAsserts: 0,
    minorLatchedFalseAsserts: 0,
    minorUnlatchedFalseAsserts: 0,
    majorLatchedTrueAsserts: 0,
    majorUnlatchedTrueAsserts: 0,
    minorLatchedTrueAsserts: 0,
    minorUnlatchedTrueAsserts: 0,
    plannedConfig: undefined,
    mgmtPortState: undefined,
  };
}

export const FabricDevice = {
  encode(message: FabricDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.orgId !== "") {
      writer.uint32(34).string(message.orgId);
    }
    if (message.nodeId !== "") {
      writer.uint32(42).string(message.nodeId);
    }
    if (message.majorLatchedFalseAsserts !== 0) {
      writer.uint32(48).uint32(message.majorLatchedFalseAsserts);
    }
    if (message.majorUnlatchedFalseAsserts !== 0) {
      writer.uint32(56).uint32(message.majorUnlatchedFalseAsserts);
    }
    if (message.minorLatchedFalseAsserts !== 0) {
      writer.uint32(64).uint32(message.minorLatchedFalseAsserts);
    }
    if (message.minorUnlatchedFalseAsserts !== 0) {
      writer.uint32(72).uint32(message.minorUnlatchedFalseAsserts);
    }
    if (message.majorLatchedTrueAsserts !== 0) {
      writer.uint32(80).uint32(message.majorLatchedTrueAsserts);
    }
    if (message.majorUnlatchedTrueAsserts !== 0) {
      writer.uint32(88).uint32(message.majorUnlatchedTrueAsserts);
    }
    if (message.minorLatchedTrueAsserts !== 0) {
      writer.uint32(96).uint32(message.minorLatchedTrueAsserts);
    }
    if (message.minorUnlatchedTrueAsserts !== 0) {
      writer.uint32(104).uint32(message.minorUnlatchedTrueAsserts);
    }
    if (message.plannedConfig !== undefined) {
      PlannedConfigData.encode(message.plannedConfig, writer.uint32(114).fork()).ldelim();
    }
    if (message.mgmtPortState !== undefined) {
      ManagementState.encode(message.mgmtPortState, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.majorLatchedFalseAsserts = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.majorUnlatchedFalseAsserts = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.minorLatchedFalseAsserts = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.minorUnlatchedFalseAsserts = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.majorLatchedTrueAsserts = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.majorUnlatchedTrueAsserts = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.minorLatchedTrueAsserts = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.minorUnlatchedTrueAsserts = reader.uint32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.plannedConfig = PlannedConfigData.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.mgmtPortState = ManagementState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricDevice {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      majorLatchedFalseAsserts: isSet(object.majorLatchedFalseAsserts)
        ? globalThis.Number(object.majorLatchedFalseAsserts)
        : 0,
      majorUnlatchedFalseAsserts: isSet(object.majorUnlatchedFalseAsserts)
        ? globalThis.Number(object.majorUnlatchedFalseAsserts)
        : 0,
      minorLatchedFalseAsserts: isSet(object.minorLatchedFalseAsserts)
        ? globalThis.Number(object.minorLatchedFalseAsserts)
        : 0,
      minorUnlatchedFalseAsserts: isSet(object.minorUnlatchedFalseAsserts)
        ? globalThis.Number(object.minorUnlatchedFalseAsserts)
        : 0,
      majorLatchedTrueAsserts: isSet(object.majorLatchedTrueAsserts)
        ? globalThis.Number(object.majorLatchedTrueAsserts)
        : 0,
      majorUnlatchedTrueAsserts: isSet(object.majorUnlatchedTrueAsserts)
        ? globalThis.Number(object.majorUnlatchedTrueAsserts)
        : 0,
      minorLatchedTrueAsserts: isSet(object.minorLatchedTrueAsserts)
        ? globalThis.Number(object.minorLatchedTrueAsserts)
        : 0,
      minorUnlatchedTrueAsserts: isSet(object.minorUnlatchedTrueAsserts)
        ? globalThis.Number(object.minorUnlatchedTrueAsserts)
        : 0,
      plannedConfig: isSet(object.plannedConfig) ? PlannedConfigData.fromJSON(object.plannedConfig) : undefined,
      mgmtPortState: isSet(object.mgmtPortState) ? ManagementState.fromJSON(object.mgmtPortState) : undefined,
    };
  },

  toJSON(message: FabricDevice): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.majorLatchedFalseAsserts !== 0) {
      obj.majorLatchedFalseAsserts = Math.round(message.majorLatchedFalseAsserts);
    }
    if (message.majorUnlatchedFalseAsserts !== 0) {
      obj.majorUnlatchedFalseAsserts = Math.round(message.majorUnlatchedFalseAsserts);
    }
    if (message.minorLatchedFalseAsserts !== 0) {
      obj.minorLatchedFalseAsserts = Math.round(message.minorLatchedFalseAsserts);
    }
    if (message.minorUnlatchedFalseAsserts !== 0) {
      obj.minorUnlatchedFalseAsserts = Math.round(message.minorUnlatchedFalseAsserts);
    }
    if (message.majorLatchedTrueAsserts !== 0) {
      obj.majorLatchedTrueAsserts = Math.round(message.majorLatchedTrueAsserts);
    }
    if (message.majorUnlatchedTrueAsserts !== 0) {
      obj.majorUnlatchedTrueAsserts = Math.round(message.majorUnlatchedTrueAsserts);
    }
    if (message.minorLatchedTrueAsserts !== 0) {
      obj.minorLatchedTrueAsserts = Math.round(message.minorLatchedTrueAsserts);
    }
    if (message.minorUnlatchedTrueAsserts !== 0) {
      obj.minorUnlatchedTrueAsserts = Math.round(message.minorUnlatchedTrueAsserts);
    }
    if (message.plannedConfig !== undefined) {
      obj.plannedConfig = PlannedConfigData.toJSON(message.plannedConfig);
    }
    if (message.mgmtPortState !== undefined) {
      obj.mgmtPortState = ManagementState.toJSON(message.mgmtPortState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricDevice>, I>>(base?: I): FabricDevice {
    return FabricDevice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricDevice>, I>>(object: I): FabricDevice {
    const message = createBaseFabricDevice();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.deviceId = object.deviceId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.orgId = object.orgId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.majorLatchedFalseAsserts = object.majorLatchedFalseAsserts ?? 0;
    message.majorUnlatchedFalseAsserts = object.majorUnlatchedFalseAsserts ?? 0;
    message.minorLatchedFalseAsserts = object.minorLatchedFalseAsserts ?? 0;
    message.minorUnlatchedFalseAsserts = object.minorUnlatchedFalseAsserts ?? 0;
    message.majorLatchedTrueAsserts = object.majorLatchedTrueAsserts ?? 0;
    message.majorUnlatchedTrueAsserts = object.majorUnlatchedTrueAsserts ?? 0;
    message.minorLatchedTrueAsserts = object.minorLatchedTrueAsserts ?? 0;
    message.minorUnlatchedTrueAsserts = object.minorUnlatchedTrueAsserts ?? 0;
    message.plannedConfig = (object.plannedConfig !== undefined && object.plannedConfig !== null)
      ? PlannedConfigData.fromPartial(object.plannedConfig)
      : undefined;
    message.mgmtPortState = (object.mgmtPortState !== undefined && object.mgmtPortState !== null)
      ? ManagementState.fromPartial(object.mgmtPortState)
      : undefined;
    return message;
  },
};

function createBaseFabricConfigNotification(): FabricConfigNotification {
  return { id: undefined, configFp: "", fabricId: "", sequence: 0 };
}

export const FabricConfigNotification = {
  encode(message: FabricConfigNotification, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.configFp !== "") {
      writer.uint32(18).string(message.configFp);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.sequence !== 0) {
      writer.uint32(32).int64(message.sequence);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConfigNotification {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConfigNotification();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.sequence = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConfigNotification {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      sequence: isSet(object.sequence) ? globalThis.Number(object.sequence) : 0,
    };
  },

  toJSON(message: FabricConfigNotification): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.sequence !== 0) {
      obj.sequence = Math.round(message.sequence);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConfigNotification>, I>>(base?: I): FabricConfigNotification {
    return FabricConfigNotification.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConfigNotification>, I>>(object: I): FabricConfigNotification {
    const message = createBaseFabricConfigNotification();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.configFp = object.configFp ?? "";
    message.fabricId = object.fabricId ?? "";
    message.sequence = object.sequence ?? 0;
    return message;
  },
};

function createBaseFabricTransaction(): FabricTransaction {
  return { id: undefined, changedAt: undefined, committed: false };
}

export const FabricTransaction = {
  encode(message: FabricTransaction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.changedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.changedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.committed !== false) {
      writer.uint32(24).bool(message.committed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricTransaction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricTransaction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.changedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.committed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricTransaction {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      changedAt: isSet(object.changedAt) ? fromJsonTimestamp(object.changedAt) : undefined,
      committed: isSet(object.committed) ? globalThis.Boolean(object.committed) : false,
    };
  },

  toJSON(message: FabricTransaction): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.changedAt !== undefined) {
      obj.changedAt = message.changedAt.toISOString();
    }
    if (message.committed !== false) {
      obj.committed = message.committed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricTransaction>, I>>(base?: I): FabricTransaction {
    return FabricTransaction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricTransaction>, I>>(object: I): FabricTransaction {
    const message = createBaseFabricTransaction();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.changedAt = object.changedAt ?? undefined;
    message.committed = object.committed ?? false;
    return message;
  },
};

function createBaseStandbyAssignment(): StandbyAssignment {
  return { standbyInstance: "", syncFromInstance: "", standbyInstanceId: "", promoteStandby: false };
}

export const StandbyAssignment = {
  encode(message: StandbyAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.standbyInstance !== "") {
      writer.uint32(10).string(message.standbyInstance);
    }
    if (message.syncFromInstance !== "") {
      writer.uint32(18).string(message.syncFromInstance);
    }
    if (message.standbyInstanceId !== "") {
      writer.uint32(26).string(message.standbyInstanceId);
    }
    if (message.promoteStandby !== false) {
      writer.uint32(32).bool(message.promoteStandby);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): StandbyAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStandbyAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.standbyInstance = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.syncFromInstance = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.standbyInstanceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.promoteStandby = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): StandbyAssignment {
    return {
      standbyInstance: isSet(object.standbyInstance) ? globalThis.String(object.standbyInstance) : "",
      syncFromInstance: isSet(object.syncFromInstance) ? globalThis.String(object.syncFromInstance) : "",
      standbyInstanceId: isSet(object.standbyInstanceId) ? globalThis.String(object.standbyInstanceId) : "",
      promoteStandby: isSet(object.promoteStandby) ? globalThis.Boolean(object.promoteStandby) : false,
    };
  },

  toJSON(message: StandbyAssignment): unknown {
    const obj: any = {};
    if (message.standbyInstance !== "") {
      obj.standbyInstance = message.standbyInstance;
    }
    if (message.syncFromInstance !== "") {
      obj.syncFromInstance = message.syncFromInstance;
    }
    if (message.standbyInstanceId !== "") {
      obj.standbyInstanceId = message.standbyInstanceId;
    }
    if (message.promoteStandby !== false) {
      obj.promoteStandby = message.promoteStandby;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<StandbyAssignment>, I>>(base?: I): StandbyAssignment {
    return StandbyAssignment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<StandbyAssignment>, I>>(object: I): StandbyAssignment {
    const message = createBaseStandbyAssignment();
    message.standbyInstance = object.standbyInstance ?? "";
    message.syncFromInstance = object.syncFromInstance ?? "";
    message.standbyInstanceId = object.standbyInstanceId ?? "";
    message.promoteStandby = object.promoteStandby ?? false;
    return message;
  },
};

function createBaseSextantFabricAssignment(): SextantFabricAssignment {
  return {
    id: undefined,
    orgId: "",
    activeInstance: "",
    standbyInstances: [],
    activeInstanceId: "",
    activeInstanceUrl: "",
    revision: 0,
    fabricType: 0,
    demoteActive: false,
  };
}

export const SextantFabricAssignment = {
  encode(message: SextantFabricAssignment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.activeInstance !== "") {
      writer.uint32(34).string(message.activeInstance);
    }
    for (const v of message.standbyInstances) {
      StandbyAssignment.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.activeInstanceId !== "") {
      writer.uint32(50).string(message.activeInstanceId);
    }
    if (message.activeInstanceUrl !== "") {
      writer.uint32(58).string(message.activeInstanceUrl);
    }
    if (message.revision !== 0) {
      writer.uint32(64).uint32(message.revision);
    }
    if (message.fabricType !== 0) {
      writer.uint32(72).int32(message.fabricType);
    }
    if (message.demoteActive !== false) {
      writer.uint32(80).bool(message.demoteActive);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SextantFabricAssignment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSextantFabricAssignment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.activeInstance = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.standbyInstances.push(StandbyAssignment.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.activeInstanceId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.activeInstanceUrl = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.revision = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.fabricType = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.demoteActive = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SextantFabricAssignment {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      activeInstance: isSet(object.activeInstance) ? globalThis.String(object.activeInstance) : "",
      standbyInstances: globalThis.Array.isArray(object?.standbyInstances)
        ? object.standbyInstances.map((e: any) => StandbyAssignment.fromJSON(e))
        : [],
      activeInstanceId: isSet(object.activeInstanceId) ? globalThis.String(object.activeInstanceId) : "",
      activeInstanceUrl: isSet(object.activeInstanceUrl) ? globalThis.String(object.activeInstanceUrl) : "",
      revision: isSet(object.revision) ? globalThis.Number(object.revision) : 0,
      fabricType: isSet(object.fabricType) ? fabricTypeFromJSON(object.fabricType) : 0,
      demoteActive: isSet(object.demoteActive) ? globalThis.Boolean(object.demoteActive) : false,
    };
  },

  toJSON(message: SextantFabricAssignment): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.activeInstance !== "") {
      obj.activeInstance = message.activeInstance;
    }
    if (message.standbyInstances?.length) {
      obj.standbyInstances = message.standbyInstances.map((e) => StandbyAssignment.toJSON(e));
    }
    if (message.activeInstanceId !== "") {
      obj.activeInstanceId = message.activeInstanceId;
    }
    if (message.activeInstanceUrl !== "") {
      obj.activeInstanceUrl = message.activeInstanceUrl;
    }
    if (message.revision !== 0) {
      obj.revision = Math.round(message.revision);
    }
    if (message.fabricType !== 0) {
      obj.fabricType = fabricTypeToJSON(message.fabricType);
    }
    if (message.demoteActive !== false) {
      obj.demoteActive = message.demoteActive;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SextantFabricAssignment>, I>>(base?: I): SextantFabricAssignment {
    return SextantFabricAssignment.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SextantFabricAssignment>, I>>(object: I): SextantFabricAssignment {
    const message = createBaseSextantFabricAssignment();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgId = object.orgId ?? "";
    message.activeInstance = object.activeInstance ?? "";
    message.standbyInstances = object.standbyInstances?.map((e) => StandbyAssignment.fromPartial(e)) || [];
    message.activeInstanceId = object.activeInstanceId ?? "";
    message.activeInstanceUrl = object.activeInstanceUrl ?? "";
    message.revision = object.revision ?? 0;
    message.fabricType = object.fabricType ?? 0;
    message.demoteActive = object.demoteActive ?? false;
    return message;
  },
};

function createBaseFabricAssignmentStatus(): FabricAssignmentStatus {
  return { id: undefined, assignmentState: 0 };
}

export const FabricAssignmentStatus = {
  encode(message: FabricAssignmentStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.assignmentState !== 0) {
      writer.uint32(16).int32(message.assignmentState);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricAssignmentStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricAssignmentStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.assignmentState = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricAssignmentStatus {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      assignmentState: isSet(object.assignmentState) ? fabricAssignmentStateFromJSON(object.assignmentState) : 0,
    };
  },

  toJSON(message: FabricAssignmentStatus): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.assignmentState !== 0) {
      obj.assignmentState = fabricAssignmentStateToJSON(message.assignmentState);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricAssignmentStatus>, I>>(base?: I): FabricAssignmentStatus {
    return FabricAssignmentStatus.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricAssignmentStatus>, I>>(object: I): FabricAssignmentStatus {
    const message = createBaseFabricAssignmentStatus();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.assignmentState = object.assignmentState ?? 0;
    return message;
  },
};

function createBaseFabricMemoryUsage(): FabricMemoryUsage {
  return { id: undefined };
}

export const FabricMemoryUsage = {
  encode(message: FabricMemoryUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricMemoryUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricMemoryUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricMemoryUsage {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: FabricMemoryUsage): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricMemoryUsage>, I>>(base?: I): FabricMemoryUsage {
    return FabricMemoryUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricMemoryUsage>, I>>(object: I): FabricMemoryUsage {
    const message = createBaseFabricMemoryUsage();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseTypeMemoryUsage(): TypeMemoryUsage {
  return { id: undefined, bytes: 0 };
}

export const TypeMemoryUsage = {
  encode(message: TypeMemoryUsage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.bytes !== 0) {
      writer.uint32(16).uint64(message.bytes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TypeMemoryUsage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTypeMemoryUsage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.bytes = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TypeMemoryUsage {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytes: isSet(object.bytes) ? globalThis.Number(object.bytes) : 0,
    };
  },

  toJSON(message: TypeMemoryUsage): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytes !== 0) {
      obj.bytes = Math.round(message.bytes);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TypeMemoryUsage>, I>>(base?: I): TypeMemoryUsage {
    return TypeMemoryUsage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TypeMemoryUsage>, I>>(object: I): TypeMemoryUsage {
    const message = createBaseTypeMemoryUsage();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytes = object.bytes ?? 0;
    return message;
  },
};

function createBaseFabricConfig(): FabricConfig {
  return {
    id: undefined,
    name: "",
    description: "",
    fabricId: "",
    adminState: 0,
    topology: 0,
    type: 0,
    modifiedAt: undefined,
    orgId: "",
    tags: [],
  };
}

export const FabricConfig = {
  encode(message: FabricConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    if (message.adminState !== 0) {
      writer.uint32(40).int32(message.adminState);
    }
    if (message.topology !== 0) {
      writer.uint32(48).int32(message.topology);
    }
    if (message.type !== 0) {
      writer.uint32(56).int32(message.type);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(98).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(106).string(message.orgId);
    }
    for (const v of message.tags) {
      writer.uint32(114).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.topology = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.tags.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConfig {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      topology: isSet(object.topology) ? fabricTopologyFromJSON(object.topology) : 0,
      type: isSet(object.type) ? fabricTypeFromJSON(object.type) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: FabricConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.topology !== 0) {
      obj.topology = fabricTopologyToJSON(message.topology);
    }
    if (message.type !== 0) {
      obj.type = fabricTypeToJSON(message.type);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConfig>, I>>(base?: I): FabricConfig {
    return FabricConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConfig>, I>>(object: I): FabricConfig {
    const message = createBaseFabricConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.fabricId = object.fabricId ?? "";
    message.adminState = object.adminState ?? 0;
    message.topology = object.topology ?? 0;
    message.type = object.type ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.orgId = object.orgId ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    return message;
  },
};

function createBaseFabricConfigEvent(): FabricConfigEvent {
  return { id: undefined, event: 0, nodeId: "", deviceId: "" };
}

export const FabricConfigEvent = {
  encode(message: FabricConfigEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.event !== 0) {
      writer.uint32(16).int32(message.event);
    }
    if (message.nodeId !== "") {
      writer.uint32(26).string(message.nodeId);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConfigEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConfigEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.event = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConfigEvent {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      event: isSet(object.event) ? fabricEventFromJSON(object.event) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: FabricConfigEvent): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.event !== 0) {
      obj.event = fabricEventToJSON(message.event);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConfigEvent>, I>>(base?: I): FabricConfigEvent {
    return FabricConfigEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConfigEvent>, I>>(object: I): FabricConfigEvent {
    const message = createBaseFabricConfigEvent();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.event = object.event ?? 0;
    message.nodeId = object.nodeId ?? "";
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseDevice(): Device {
  return {
    id: undefined,
    info: undefined,
    fabricId: "",
    firstSeen: undefined,
    lastSeen: undefined,
    commonPortSpeed: 0,
  };
}

export const Device = {
  encode(message: Device, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.info !== undefined) {
      DeviceInfo.encode(message.info, writer.uint32(26).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    if (message.firstSeen !== undefined) {
      Timestamp.encode(toTimestamp(message.firstSeen), writer.uint32(42).fork()).ldelim();
    }
    if (message.lastSeen !== undefined) {
      Timestamp.encode(toTimestamp(message.lastSeen), writer.uint32(50).fork()).ldelim();
    }
    if (message.commonPortSpeed !== 0) {
      writer.uint32(56).uint64(message.commonPortSpeed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Device {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.info = DeviceInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firstSeen = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastSeen = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.commonPortSpeed = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      info: isSet(object.info) ? DeviceInfo.fromJSON(object.info) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      firstSeen: isSet(object.firstSeen) ? fromJsonTimestamp(object.firstSeen) : undefined,
      lastSeen: isSet(object.lastSeen) ? fromJsonTimestamp(object.lastSeen) : undefined,
      commonPortSpeed: isSet(object.commonPortSpeed) ? globalThis.Number(object.commonPortSpeed) : 0,
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.info !== undefined) {
      obj.info = DeviceInfo.toJSON(message.info);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.firstSeen !== undefined) {
      obj.firstSeen = message.firstSeen.toISOString();
    }
    if (message.lastSeen !== undefined) {
      obj.lastSeen = message.lastSeen.toISOString();
    }
    if (message.commonPortSpeed !== 0) {
      obj.commonPortSpeed = Math.round(message.commonPortSpeed);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Device>, I>>(base?: I): Device {
    return Device.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Device>, I>>(object: I): Device {
    const message = createBaseDevice();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.info = (object.info !== undefined && object.info !== null)
      ? DeviceInfo.fromPartial(object.info)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.firstSeen = object.firstSeen ?? undefined;
    message.lastSeen = object.lastSeen ?? undefined;
    message.commonPortSpeed = object.commonPortSpeed ?? 0;
    return message;
  },
};

function createBaseDeviceConnection(): DeviceConnection {
  return {
    id: undefined,
    wsConnected: 0,
    wsLastConnectedAt: undefined,
    wsLastDisconnectedAt: undefined,
    wsMustConnectBy: undefined,
  };
}

export const DeviceConnection = {
  encode(message: DeviceConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.wsConnected !== 0) {
      writer.uint32(32).uint32(message.wsConnected);
    }
    if (message.wsLastConnectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.wsLastConnectedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.wsLastDisconnectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.wsLastDisconnectedAt), writer.uint32(50).fork()).ldelim();
    }
    if (message.wsMustConnectBy !== undefined) {
      Timestamp.encode(toTimestamp(message.wsMustConnectBy), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.wsConnected = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.wsLastConnectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.wsLastDisconnectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.wsMustConnectBy = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceConnection {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      wsConnected: isSet(object.wsConnected) ? globalThis.Number(object.wsConnected) : 0,
      wsLastConnectedAt: isSet(object.wsLastConnectedAt) ? fromJsonTimestamp(object.wsLastConnectedAt) : undefined,
      wsLastDisconnectedAt: isSet(object.wsLastDisconnectedAt)
        ? fromJsonTimestamp(object.wsLastDisconnectedAt)
        : undefined,
      wsMustConnectBy: isSet(object.wsMustConnectBy) ? fromJsonTimestamp(object.wsMustConnectBy) : undefined,
    };
  },

  toJSON(message: DeviceConnection): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.wsConnected !== 0) {
      obj.wsConnected = Math.round(message.wsConnected);
    }
    if (message.wsLastConnectedAt !== undefined) {
      obj.wsLastConnectedAt = message.wsLastConnectedAt.toISOString();
    }
    if (message.wsLastDisconnectedAt !== undefined) {
      obj.wsLastDisconnectedAt = message.wsLastDisconnectedAt.toISOString();
    }
    if (message.wsMustConnectBy !== undefined) {
      obj.wsMustConnectBy = message.wsMustConnectBy.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceConnection>, I>>(base?: I): DeviceConnection {
    return DeviceConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceConnection>, I>>(object: I): DeviceConnection {
    const message = createBaseDeviceConnection();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.wsConnected = object.wsConnected ?? 0;
    message.wsLastConnectedAt = object.wsLastConnectedAt ?? undefined;
    message.wsLastDisconnectedAt = object.wsLastDisconnectedAt ?? undefined;
    message.wsMustConnectBy = object.wsMustConnectBy ?? undefined;
    return message;
  },
};

function createBasePlannedConfigData(): PlannedConfigData {
  return { currentConfigFp: "", plannedConfigFp: "", plannedConfigUpdatedAt: undefined, plannedConfigPhase: 0 };
}

export const PlannedConfigData = {
  encode(message: PlannedConfigData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currentConfigFp !== "") {
      writer.uint32(18).string(message.currentConfigFp);
    }
    if (message.plannedConfigFp !== "") {
      writer.uint32(26).string(message.plannedConfigFp);
    }
    if (message.plannedConfigUpdatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.plannedConfigUpdatedAt), writer.uint32(34).fork()).ldelim();
    }
    if (message.plannedConfigPhase !== 0) {
      writer.uint32(40).int32(message.plannedConfigPhase);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlannedConfigData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlannedConfigData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.currentConfigFp = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.plannedConfigFp = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.plannedConfigUpdatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.plannedConfigPhase = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlannedConfigData {
    return {
      currentConfigFp: isSet(object.currentConfigFp) ? globalThis.String(object.currentConfigFp) : "",
      plannedConfigFp: isSet(object.plannedConfigFp) ? globalThis.String(object.plannedConfigFp) : "",
      plannedConfigUpdatedAt: isSet(object.plannedConfigUpdatedAt)
        ? fromJsonTimestamp(object.plannedConfigUpdatedAt)
        : undefined,
      plannedConfigPhase: isSet(object.plannedConfigPhase) ? plannedConfigPhaseFromJSON(object.plannedConfigPhase) : 0,
    };
  },

  toJSON(message: PlannedConfigData): unknown {
    const obj: any = {};
    if (message.currentConfigFp !== "") {
      obj.currentConfigFp = message.currentConfigFp;
    }
    if (message.plannedConfigFp !== "") {
      obj.plannedConfigFp = message.plannedConfigFp;
    }
    if (message.plannedConfigUpdatedAt !== undefined) {
      obj.plannedConfigUpdatedAt = message.plannedConfigUpdatedAt.toISOString();
    }
    if (message.plannedConfigPhase !== 0) {
      obj.plannedConfigPhase = plannedConfigPhaseToJSON(message.plannedConfigPhase);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlannedConfigData>, I>>(base?: I): PlannedConfigData {
    return PlannedConfigData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlannedConfigData>, I>>(object: I): PlannedConfigData {
    const message = createBasePlannedConfigData();
    message.currentConfigFp = object.currentConfigFp ?? "";
    message.plannedConfigFp = object.plannedConfigFp ?? "";
    message.plannedConfigUpdatedAt = object.plannedConfigUpdatedAt ?? undefined;
    message.plannedConfigPhase = object.plannedConfigPhase ?? 0;
    return message;
  },
};

function createBasePlannedConfig(): PlannedConfig {
  return { id: undefined, plannedConfig: undefined };
}

export const PlannedConfig = {
  encode(message: PlannedConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.plannedConfig !== undefined) {
      PlannedConfigData.encode(message.plannedConfig, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PlannedConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePlannedConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.plannedConfig = PlannedConfigData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PlannedConfig {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      plannedConfig: isSet(object.plannedConfig) ? PlannedConfigData.fromJSON(object.plannedConfig) : undefined,
    };
  },

  toJSON(message: PlannedConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.plannedConfig !== undefined) {
      obj.plannedConfig = PlannedConfigData.toJSON(message.plannedConfig);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PlannedConfig>, I>>(base?: I): PlannedConfig {
    return PlannedConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PlannedConfig>, I>>(object: I): PlannedConfig {
    const message = createBasePlannedConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.plannedConfig = (object.plannedConfig !== undefined && object.plannedConfig !== null)
      ? PlannedConfigData.fromPartial(object.plannedConfig)
      : undefined;
    return message;
  },
};

function createBaseDeviceInfo(): DeviceInfo {
  return {
    model: "",
    psuNum: "",
    revision: "",
    serial: "",
    name: "",
    lldpLocId: "",
    sonicVersion: "",
    agentsVersion: "",
  };
}

export const DeviceInfo = {
  encode(message: DeviceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.psuNum !== "") {
      writer.uint32(34).string(message.psuNum);
    }
    if (message.revision !== "") {
      writer.uint32(42).string(message.revision);
    }
    if (message.serial !== "") {
      writer.uint32(50).string(message.serial);
    }
    if (message.name !== "") {
      writer.uint32(58).string(message.name);
    }
    if (message.lldpLocId !== "") {
      writer.uint32(66).string(message.lldpLocId);
    }
    if (message.sonicVersion !== "") {
      writer.uint32(74).string(message.sonicVersion);
    }
    if (message.agentsVersion !== "") {
      writer.uint32(82).string(message.agentsVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.psuNum = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.revision = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.name = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.lldpLocId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.sonicVersion = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.agentsVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceInfo {
    return {
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      psuNum: isSet(object.psuNum) ? globalThis.String(object.psuNum) : "",
      revision: isSet(object.revision) ? globalThis.String(object.revision) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      lldpLocId: isSet(object.lldpLocId) ? globalThis.String(object.lldpLocId) : "",
      sonicVersion: isSet(object.sonicVersion) ? globalThis.String(object.sonicVersion) : "",
      agentsVersion: isSet(object.agentsVersion) ? globalThis.String(object.agentsVersion) : "",
    };
  },

  toJSON(message: DeviceInfo): unknown {
    const obj: any = {};
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.psuNum !== "") {
      obj.psuNum = message.psuNum;
    }
    if (message.revision !== "") {
      obj.revision = message.revision;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.lldpLocId !== "") {
      obj.lldpLocId = message.lldpLocId;
    }
    if (message.sonicVersion !== "") {
      obj.sonicVersion = message.sonicVersion;
    }
    if (message.agentsVersion !== "") {
      obj.agentsVersion = message.agentsVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceInfo>, I>>(base?: I): DeviceInfo {
    return DeviceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceInfo>, I>>(object: I): DeviceInfo {
    const message = createBaseDeviceInfo();
    message.model = object.model ?? "";
    message.psuNum = object.psuNum ?? "";
    message.revision = object.revision ?? "";
    message.serial = object.serial ?? "";
    message.name = object.name ?? "";
    message.lldpLocId = object.lldpLocId ?? "";
    message.sonicVersion = object.sonicVersion ?? "";
    message.agentsVersion = object.agentsVersion ?? "";
    return message;
  },
};

function createBaseNodeConfig(): NodeConfig {
  return {
    id: undefined,
    nodeId: "",
    fabricId: "",
    deviceId: "",
    name: "",
    type: 0,
    adminState: 0,
    description: "",
    serialNumber: "",
    modelName: "",
    role: 0,
    osType: 0,
    modifiedAt: undefined,
    tags: [],
    orgId: "",
    configFp: "",
  };
}

export const NodeConfig = {
  encode(message: NodeConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.fabricId !== "") {
      writer.uint32(26).string(message.fabricId);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.type !== 0) {
      writer.uint32(48).int32(message.type);
    }
    if (message.adminState !== 0) {
      writer.uint32(56).int32(message.adminState);
    }
    if (message.description !== "") {
      writer.uint32(66).string(message.description);
    }
    if (message.serialNumber !== "") {
      writer.uint32(74).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(82).string(message.modelName);
    }
    if (message.role !== 0) {
      writer.uint32(96).int32(message.role);
    }
    if (message.osType !== 0) {
      writer.uint32(104).int32(message.osType);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.tags) {
      writer.uint32(122).string(v!);
    }
    if (message.orgId !== "") {
      writer.uint32(130).string(message.orgId);
    }
    if (message.configFp !== "") {
      writer.uint32(138).string(message.configFp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.description = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.role = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.configFp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeConfig {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      type: isSet(object.type) ? nodeTypeFromJSON(object.type) : 0,
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      role: isSet(object.role) ? nodeRoleFromJSON(object.role) : 0,
      osType: isSet(object.osType) ? osTypeFromJSON(object.osType) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
    };
  },

  toJSON(message: NodeConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.type !== 0) {
      obj.type = nodeTypeToJSON(message.type);
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.role !== 0) {
      obj.role = nodeRoleToJSON(message.role);
    }
    if (message.osType !== 0) {
      obj.osType = osTypeToJSON(message.osType);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeConfig>, I>>(base?: I): NodeConfig {
    return NodeConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeConfig>, I>>(object: I): NodeConfig {
    const message = createBaseNodeConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.nodeId = object.nodeId ?? "";
    message.fabricId = object.fabricId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.name = object.name ?? "";
    message.type = object.type ?? 0;
    message.adminState = object.adminState ?? 0;
    message.description = object.description ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.role = object.role ?? 0;
    message.osType = object.osType ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.orgId = object.orgId ?? "";
    message.configFp = object.configFp ?? "";
    return message;
  },
};

function createBaseFan(): Fan {
  return {
    id: undefined,
    speed: 0,
    name: "",
    deprecatedDirection: "",
    status: 0,
    ledStatus: 0,
    drawer: "",
    collectedAt: undefined,
    direction: 0,
    serial: "",
  };
}

export const Fan = {
  encode(message: Fan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.speed !== 0) {
      writer.uint32(24).uint32(message.speed);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.deprecatedDirection !== "") {
      writer.uint32(42).string(message.deprecatedDirection);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    if (message.ledStatus !== 0) {
      writer.uint32(56).int32(message.ledStatus);
    }
    if (message.drawer !== "") {
      writer.uint32(66).string(message.drawer);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(74).fork()).ldelim();
    }
    if (message.direction !== 0) {
      writer.uint32(80).int32(message.direction);
    }
    if (message.serial !== "") {
      writer.uint32(90).string(message.serial);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Fan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.speed = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deprecatedDirection = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ledStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.drawer = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.direction = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.serial = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Fan {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      deprecatedDirection: isSet(object.deprecatedDirection) ? globalThis.String(object.deprecatedDirection) : "",
      status: isSet(object.status) ? envStatusFromJSON(object.status) : 0,
      ledStatus: isSet(object.ledStatus) ? envLedStatusFromJSON(object.ledStatus) : 0,
      drawer: isSet(object.drawer) ? globalThis.String(object.drawer) : "",
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
      direction: isSet(object.direction) ? fanDirectionFromJSON(object.direction) : 0,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
    };
  },

  toJSON(message: Fan): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.speed !== 0) {
      obj.speed = Math.round(message.speed);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.deprecatedDirection !== "") {
      obj.deprecatedDirection = message.deprecatedDirection;
    }
    if (message.status !== 0) {
      obj.status = envStatusToJSON(message.status);
    }
    if (message.ledStatus !== 0) {
      obj.ledStatus = envLedStatusToJSON(message.ledStatus);
    }
    if (message.drawer !== "") {
      obj.drawer = message.drawer;
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    if (message.direction !== 0) {
      obj.direction = fanDirectionToJSON(message.direction);
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Fan>, I>>(base?: I): Fan {
    return Fan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Fan>, I>>(object: I): Fan {
    const message = createBaseFan();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.speed = object.speed ?? 0;
    message.name = object.name ?? "";
    message.deprecatedDirection = object.deprecatedDirection ?? "";
    message.status = object.status ?? 0;
    message.ledStatus = object.ledStatus ?? 0;
    message.drawer = object.drawer ?? "";
    message.collectedAt = object.collectedAt ?? undefined;
    message.direction = object.direction ?? 0;
    message.serial = object.serial ?? "";
    return message;
  },
};

function createBaseProcTable(): ProcTable {
  return { id: undefined, processes: [], collectedAt: undefined };
}

export const ProcTable = {
  encode(message: ProcTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.processes) {
      Process.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProcTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.processes.push(Process.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProcTable {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      processes: globalThis.Array.isArray(object?.processes)
        ? object.processes.map((e: any) => Process.fromJSON(e))
        : [],
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ProcTable): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.processes?.length) {
      obj.processes = message.processes.map((e) => Process.toJSON(e));
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProcTable>, I>>(base?: I): ProcTable {
    return ProcTable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProcTable>, I>>(object: I): ProcTable {
    const message = createBaseProcTable();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.processes = object.processes?.map((e) => Process.fromPartial(e)) || [];
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseProcess(): Process {
  return { cpuPercent: 0, memPercent: 0, cmd: "", cpuTimeS: 0 };
}

export const Process = {
  encode(message: Process, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cpuPercent !== 0) {
      writer.uint32(21).float(message.cpuPercent);
    }
    if (message.memPercent !== 0) {
      writer.uint32(29).float(message.memPercent);
    }
    if (message.cmd !== "") {
      writer.uint32(34).string(message.cmd);
    }
    if (message.cpuTimeS !== 0) {
      writer.uint32(40).uint64(message.cpuTimeS);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Process {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProcess();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 21) {
            break;
          }

          message.cpuPercent = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.memPercent = reader.float();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cmd = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.cpuTimeS = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Process {
    return {
      cpuPercent: isSet(object.cpuPercent) ? globalThis.Number(object.cpuPercent) : 0,
      memPercent: isSet(object.memPercent) ? globalThis.Number(object.memPercent) : 0,
      cmd: isSet(object.cmd) ? globalThis.String(object.cmd) : "",
      cpuTimeS: isSet(object.cpuTimeS) ? globalThis.Number(object.cpuTimeS) : 0,
    };
  },

  toJSON(message: Process): unknown {
    const obj: any = {};
    if (message.cpuPercent !== 0) {
      obj.cpuPercent = message.cpuPercent;
    }
    if (message.memPercent !== 0) {
      obj.memPercent = message.memPercent;
    }
    if (message.cmd !== "") {
      obj.cmd = message.cmd;
    }
    if (message.cpuTimeS !== 0) {
      obj.cpuTimeS = Math.round(message.cpuTimeS);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Process>, I>>(base?: I): Process {
    return Process.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Process>, I>>(object: I): Process {
    const message = createBaseProcess();
    message.cpuPercent = object.cpuPercent ?? 0;
    message.memPercent = object.memPercent ?? 0;
    message.cmd = object.cmd ?? "";
    message.cpuTimeS = object.cpuTimeS ?? 0;
    return message;
  },
};

function createBasePsu(): Psu {
  return {
    id: undefined,
    watts: 0,
    fanSpeed: 0,
    name: "",
    status: 0,
    ledStatus: 0,
    model: "",
    serial: "",
    voltage: 0,
    current: 0,
  };
}

export const Psu = {
  encode(message: Psu, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.watts !== 0) {
      writer.uint32(24).uint32(message.watts);
    }
    if (message.fanSpeed !== 0) {
      writer.uint32(32).uint32(message.fanSpeed);
    }
    if (message.name !== "") {
      writer.uint32(42).string(message.name);
    }
    if (message.status !== 0) {
      writer.uint32(48).int32(message.status);
    }
    if (message.ledStatus !== 0) {
      writer.uint32(56).int32(message.ledStatus);
    }
    if (message.model !== "") {
      writer.uint32(66).string(message.model);
    }
    if (message.serial !== "") {
      writer.uint32(74).string(message.serial);
    }
    if (message.voltage !== 0) {
      writer.uint32(85).float(message.voltage);
    }
    if (message.current !== 0) {
      writer.uint32(93).float(message.current);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Psu {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePsu();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.watts = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.fanSpeed = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.name = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.ledStatus = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.model = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.voltage = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.current = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Psu {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      watts: isSet(object.watts) ? globalThis.Number(object.watts) : 0,
      fanSpeed: isSet(object.fanSpeed) ? globalThis.Number(object.fanSpeed) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      status: isSet(object.status) ? envStatusFromJSON(object.status) : 0,
      ledStatus: isSet(object.ledStatus) ? envLedStatusFromJSON(object.ledStatus) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      voltage: isSet(object.voltage) ? globalThis.Number(object.voltage) : 0,
      current: isSet(object.current) ? globalThis.Number(object.current) : 0,
    };
  },

  toJSON(message: Psu): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.watts !== 0) {
      obj.watts = Math.round(message.watts);
    }
    if (message.fanSpeed !== 0) {
      obj.fanSpeed = Math.round(message.fanSpeed);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.status !== 0) {
      obj.status = envStatusToJSON(message.status);
    }
    if (message.ledStatus !== 0) {
      obj.ledStatus = envLedStatusToJSON(message.ledStatus);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.voltage !== 0) {
      obj.voltage = message.voltage;
    }
    if (message.current !== 0) {
      obj.current = message.current;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Psu>, I>>(base?: I): Psu {
    return Psu.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Psu>, I>>(object: I): Psu {
    const message = createBasePsu();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.watts = object.watts ?? 0;
    message.fanSpeed = object.fanSpeed ?? 0;
    message.name = object.name ?? "";
    message.status = object.status ?? 0;
    message.ledStatus = object.ledStatus ?? 0;
    message.model = object.model ?? "";
    message.serial = object.serial ?? "";
    message.voltage = object.voltage ?? 0;
    message.current = object.current ?? 0;
    return message;
  },
};

function createBaseTempSensor(): TempSensor {
  return { id: undefined, degC: 0 };
}

export const TempSensor = {
  encode(message: TempSensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.degC !== 0) {
      writer.uint32(24).uint32(message.degC);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TempSensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTempSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.degC = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TempSensor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      degC: isSet(object.degC) ? globalThis.Number(object.degC) : 0,
    };
  },

  toJSON(message: TempSensor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.degC !== 0) {
      obj.degC = Math.round(message.degC);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TempSensor>, I>>(base?: I): TempSensor {
    return TempSensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TempSensor>, I>>(object: I): TempSensor {
    const message = createBaseTempSensor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.degC = object.degC ?? 0;
    return message;
  },
};

function createBaseProxyStats(): ProxyStats {
  return {
    id: undefined,
    reqCount: 0,
    reqErrors: 0,
    respCount: 0,
    respErrors: 0,
    minRtt: 0,
    maxRtt: 0,
    meanRtt: 0,
    medianRtt: 0,
  };
}

export const ProxyStats = {
  encode(message: ProxyStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.reqCount !== 0) {
      writer.uint32(24).uint64(message.reqCount);
    }
    if (message.reqErrors !== 0) {
      writer.uint32(32).uint64(message.reqErrors);
    }
    if (message.respCount !== 0) {
      writer.uint32(40).uint64(message.respCount);
    }
    if (message.respErrors !== 0) {
      writer.uint32(48).uint64(message.respErrors);
    }
    if (message.minRtt !== 0) {
      writer.uint32(56).uint32(message.minRtt);
    }
    if (message.maxRtt !== 0) {
      writer.uint32(64).uint32(message.maxRtt);
    }
    if (message.meanRtt !== 0) {
      writer.uint32(72).uint32(message.meanRtt);
    }
    if (message.medianRtt !== 0) {
      writer.uint32(80).uint32(message.medianRtt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxyStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxyStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.reqCount = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.reqErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.respCount = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.respErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.minRtt = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxRtt = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.meanRtt = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.medianRtt = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxyStats {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      reqCount: isSet(object.reqCount) ? globalThis.Number(object.reqCount) : 0,
      reqErrors: isSet(object.reqErrors) ? globalThis.Number(object.reqErrors) : 0,
      respCount: isSet(object.respCount) ? globalThis.Number(object.respCount) : 0,
      respErrors: isSet(object.respErrors) ? globalThis.Number(object.respErrors) : 0,
      minRtt: isSet(object.minRtt) ? globalThis.Number(object.minRtt) : 0,
      maxRtt: isSet(object.maxRtt) ? globalThis.Number(object.maxRtt) : 0,
      meanRtt: isSet(object.meanRtt) ? globalThis.Number(object.meanRtt) : 0,
      medianRtt: isSet(object.medianRtt) ? globalThis.Number(object.medianRtt) : 0,
    };
  },

  toJSON(message: ProxyStats): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.reqCount !== 0) {
      obj.reqCount = Math.round(message.reqCount);
    }
    if (message.reqErrors !== 0) {
      obj.reqErrors = Math.round(message.reqErrors);
    }
    if (message.respCount !== 0) {
      obj.respCount = Math.round(message.respCount);
    }
    if (message.respErrors !== 0) {
      obj.respErrors = Math.round(message.respErrors);
    }
    if (message.minRtt !== 0) {
      obj.minRtt = Math.round(message.minRtt);
    }
    if (message.maxRtt !== 0) {
      obj.maxRtt = Math.round(message.maxRtt);
    }
    if (message.meanRtt !== 0) {
      obj.meanRtt = Math.round(message.meanRtt);
    }
    if (message.medianRtt !== 0) {
      obj.medianRtt = Math.round(message.medianRtt);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxyStats>, I>>(base?: I): ProxyStats {
    return ProxyStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxyStats>, I>>(object: I): ProxyStats {
    const message = createBaseProxyStats();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.reqCount = object.reqCount ?? 0;
    message.reqErrors = object.reqErrors ?? 0;
    message.respCount = object.respCount ?? 0;
    message.respErrors = object.respErrors ?? 0;
    message.minRtt = object.minRtt ?? 0;
    message.maxRtt = object.maxRtt ?? 0;
    message.meanRtt = object.meanRtt ?? 0;
    message.medianRtt = object.medianRtt ?? 0;
    return message;
  },
};

function createBaseUnderlayInfo(): UnderlayInfo {
  return { id: undefined, proxySessionInfo: undefined, gatewayInfo: undefined };
}

export const UnderlayInfo = {
  encode(message: UnderlayInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.proxySessionInfo !== undefined) {
      ProxySessionInfo.encode(message.proxySessionInfo, writer.uint32(26).fork()).ldelim();
    }
    if (message.gatewayInfo !== undefined) {
      GatewayInfo.encode(message.gatewayInfo, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnderlayInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnderlayInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.proxySessionInfo = ProxySessionInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.gatewayInfo = GatewayInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnderlayInfo {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      proxySessionInfo: isSet(object.proxySessionInfo) ? ProxySessionInfo.fromJSON(object.proxySessionInfo) : undefined,
      gatewayInfo: isSet(object.gatewayInfo) ? GatewayInfo.fromJSON(object.gatewayInfo) : undefined,
    };
  },

  toJSON(message: UnderlayInfo): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.proxySessionInfo !== undefined) {
      obj.proxySessionInfo = ProxySessionInfo.toJSON(message.proxySessionInfo);
    }
    if (message.gatewayInfo !== undefined) {
      obj.gatewayInfo = GatewayInfo.toJSON(message.gatewayInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnderlayInfo>, I>>(base?: I): UnderlayInfo {
    return UnderlayInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnderlayInfo>, I>>(object: I): UnderlayInfo {
    const message = createBaseUnderlayInfo();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.proxySessionInfo = (object.proxySessionInfo !== undefined && object.proxySessionInfo !== null)
      ? ProxySessionInfo.fromPartial(object.proxySessionInfo)
      : undefined;
    message.gatewayInfo = (object.gatewayInfo !== undefined && object.gatewayInfo !== null)
      ? GatewayInfo.fromPartial(object.gatewayInfo)
      : undefined;
    return message;
  },
};

function createBaseProxySessionInfo(): ProxySessionInfo {
  return { proxyAuthState: 0, certChain: [], initiatedAt: undefined, validUntil: undefined };
}

export const ProxySessionInfo = {
  encode(message: ProxySessionInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.proxyAuthState !== 0) {
      writer.uint32(8).int32(message.proxyAuthState);
    }
    for (const v of message.certChain) {
      writer.uint32(18).string(v!);
    }
    if (message.initiatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.initiatedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.validUntil !== undefined) {
      Timestamp.encode(toTimestamp(message.validUntil), writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxySessionInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxySessionInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.proxyAuthState = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.certChain.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.initiatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.validUntil = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxySessionInfo {
    return {
      proxyAuthState: isSet(object.proxyAuthState) ? proxyAuthStateFromJSON(object.proxyAuthState) : 0,
      certChain: globalThis.Array.isArray(object?.certChain)
        ? object.certChain.map((e: any) => globalThis.String(e))
        : [],
      initiatedAt: isSet(object.initiatedAt) ? fromJsonTimestamp(object.initiatedAt) : undefined,
      validUntil: isSet(object.validUntil) ? fromJsonTimestamp(object.validUntil) : undefined,
    };
  },

  toJSON(message: ProxySessionInfo): unknown {
    const obj: any = {};
    if (message.proxyAuthState !== 0) {
      obj.proxyAuthState = proxyAuthStateToJSON(message.proxyAuthState);
    }
    if (message.certChain?.length) {
      obj.certChain = message.certChain;
    }
    if (message.initiatedAt !== undefined) {
      obj.initiatedAt = message.initiatedAt.toISOString();
    }
    if (message.validUntil !== undefined) {
      obj.validUntil = message.validUntil.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxySessionInfo>, I>>(base?: I): ProxySessionInfo {
    return ProxySessionInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxySessionInfo>, I>>(object: I): ProxySessionInfo {
    const message = createBaseProxySessionInfo();
    message.proxyAuthState = object.proxyAuthState ?? 0;
    message.certChain = object.certChain?.map((e) => e) || [];
    message.initiatedAt = object.initiatedAt ?? undefined;
    message.validUntil = object.validUntil ?? undefined;
    return message;
  },
};

function createBaseProxyConnectionState(): ProxyConnectionState {
  return {
    id: undefined,
    sourcePort: 0,
    destPort: 0,
    sourceIp: "",
    destIp: "",
    outerTlsConnectionState: 0,
    outerTlsSessionState: 0,
    outerTlsErr: "",
    innerTlsConnectionState: 0,
    innerTlsErr: "",
    outerTlsLeafCert: "",
    outerTlsIntermediateCerts: [],
    outerTlsRootCerts: [],
    outerTlsVerifyErr: 0,
    outerTlsVerifyErrString: "",
    proxyUrl: "",
    source: "",
    fabricPath: "",
    initiatedAt: undefined,
    collectedAt: undefined,
  };
}

export const ProxyConnectionState = {
  encode(message: ProxyConnectionState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.sourcePort !== 0) {
      writer.uint32(24).uint32(message.sourcePort);
    }
    if (message.destPort !== 0) {
      writer.uint32(32).uint32(message.destPort);
    }
    if (message.sourceIp !== "") {
      writer.uint32(42).string(message.sourceIp);
    }
    if (message.destIp !== "") {
      writer.uint32(50).string(message.destIp);
    }
    if (message.outerTlsConnectionState !== 0) {
      writer.uint32(56).int32(message.outerTlsConnectionState);
    }
    if (message.outerTlsSessionState !== 0) {
      writer.uint32(64).int32(message.outerTlsSessionState);
    }
    if (message.outerTlsErr !== "") {
      writer.uint32(74).string(message.outerTlsErr);
    }
    if (message.innerTlsConnectionState !== 0) {
      writer.uint32(80).int32(message.innerTlsConnectionState);
    }
    if (message.innerTlsErr !== "") {
      writer.uint32(90).string(message.innerTlsErr);
    }
    if (message.outerTlsLeafCert !== "") {
      writer.uint32(98).string(message.outerTlsLeafCert);
    }
    for (const v of message.outerTlsIntermediateCerts) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.outerTlsRootCerts) {
      writer.uint32(114).string(v!);
    }
    if (message.outerTlsVerifyErr !== 0) {
      writer.uint32(144).int32(message.outerTlsVerifyErr);
    }
    if (message.outerTlsVerifyErrString !== "") {
      writer.uint32(154).string(message.outerTlsVerifyErrString);
    }
    if (message.proxyUrl !== "") {
      writer.uint32(138).string(message.proxyUrl);
    }
    if (message.source !== "") {
      writer.uint32(162).string(message.source);
    }
    if (message.fabricPath !== "") {
      writer.uint32(170).string(message.fabricPath);
    }
    if (message.initiatedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.initiatedAt), writer.uint32(122).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxyConnectionState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxyConnectionState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.sourcePort = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.destPort = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.sourceIp = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.destIp = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.outerTlsConnectionState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.outerTlsSessionState = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.outerTlsErr = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.innerTlsConnectionState = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.innerTlsErr = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.outerTlsLeafCert = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.outerTlsIntermediateCerts.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.outerTlsRootCerts.push(reader.string());
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.outerTlsVerifyErr = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.outerTlsVerifyErrString = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.proxyUrl = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.source = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.fabricPath = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.initiatedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxyConnectionState {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      sourcePort: isSet(object.sourcePort) ? globalThis.Number(object.sourcePort) : 0,
      destPort: isSet(object.destPort) ? globalThis.Number(object.destPort) : 0,
      sourceIp: isSet(object.sourceIp) ? globalThis.String(object.sourceIp) : "",
      destIp: isSet(object.destIp) ? globalThis.String(object.destIp) : "",
      outerTlsConnectionState: isSet(object.outerTlsConnectionState)
        ? tlsConnectionStateFromJSON(object.outerTlsConnectionState)
        : 0,
      outerTlsSessionState: isSet(object.outerTlsSessionState)
        ? tlsSessionStateFromJSON(object.outerTlsSessionState)
        : 0,
      outerTlsErr: isSet(object.outerTlsErr) ? globalThis.String(object.outerTlsErr) : "",
      innerTlsConnectionState: isSet(object.innerTlsConnectionState)
        ? tlsConnectionStateFromJSON(object.innerTlsConnectionState)
        : 0,
      innerTlsErr: isSet(object.innerTlsErr) ? globalThis.String(object.innerTlsErr) : "",
      outerTlsLeafCert: isSet(object.outerTlsLeafCert) ? globalThis.String(object.outerTlsLeafCert) : "",
      outerTlsIntermediateCerts: globalThis.Array.isArray(object?.outerTlsIntermediateCerts)
        ? object.outerTlsIntermediateCerts.map((e: any) => globalThis.String(e))
        : [],
      outerTlsRootCerts: globalThis.Array.isArray(object?.outerTlsRootCerts)
        ? object.outerTlsRootCerts.map((e: any) => globalThis.String(e))
        : [],
      outerTlsVerifyErr: isSet(object.outerTlsVerifyErr) ? tLSVerifyErrorFromJSON(object.outerTlsVerifyErr) : 0,
      outerTlsVerifyErrString: isSet(object.outerTlsVerifyErrString)
        ? globalThis.String(object.outerTlsVerifyErrString)
        : "",
      proxyUrl: isSet(object.proxyUrl) ? globalThis.String(object.proxyUrl) : "",
      source: isSet(object.source) ? globalThis.String(object.source) : "",
      fabricPath: isSet(object.fabricPath) ? globalThis.String(object.fabricPath) : "",
      initiatedAt: isSet(object.initiatedAt) ? fromJsonTimestamp(object.initiatedAt) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ProxyConnectionState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.sourcePort !== 0) {
      obj.sourcePort = Math.round(message.sourcePort);
    }
    if (message.destPort !== 0) {
      obj.destPort = Math.round(message.destPort);
    }
    if (message.sourceIp !== "") {
      obj.sourceIp = message.sourceIp;
    }
    if (message.destIp !== "") {
      obj.destIp = message.destIp;
    }
    if (message.outerTlsConnectionState !== 0) {
      obj.outerTlsConnectionState = tlsConnectionStateToJSON(message.outerTlsConnectionState);
    }
    if (message.outerTlsSessionState !== 0) {
      obj.outerTlsSessionState = tlsSessionStateToJSON(message.outerTlsSessionState);
    }
    if (message.outerTlsErr !== "") {
      obj.outerTlsErr = message.outerTlsErr;
    }
    if (message.innerTlsConnectionState !== 0) {
      obj.innerTlsConnectionState = tlsConnectionStateToJSON(message.innerTlsConnectionState);
    }
    if (message.innerTlsErr !== "") {
      obj.innerTlsErr = message.innerTlsErr;
    }
    if (message.outerTlsLeafCert !== "") {
      obj.outerTlsLeafCert = message.outerTlsLeafCert;
    }
    if (message.outerTlsIntermediateCerts?.length) {
      obj.outerTlsIntermediateCerts = message.outerTlsIntermediateCerts;
    }
    if (message.outerTlsRootCerts?.length) {
      obj.outerTlsRootCerts = message.outerTlsRootCerts;
    }
    if (message.outerTlsVerifyErr !== 0) {
      obj.outerTlsVerifyErr = tLSVerifyErrorToJSON(message.outerTlsVerifyErr);
    }
    if (message.outerTlsVerifyErrString !== "") {
      obj.outerTlsVerifyErrString = message.outerTlsVerifyErrString;
    }
    if (message.proxyUrl !== "") {
      obj.proxyUrl = message.proxyUrl;
    }
    if (message.source !== "") {
      obj.source = message.source;
    }
    if (message.fabricPath !== "") {
      obj.fabricPath = message.fabricPath;
    }
    if (message.initiatedAt !== undefined) {
      obj.initiatedAt = message.initiatedAt.toISOString();
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxyConnectionState>, I>>(base?: I): ProxyConnectionState {
    return ProxyConnectionState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxyConnectionState>, I>>(object: I): ProxyConnectionState {
    const message = createBaseProxyConnectionState();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.sourcePort = object.sourcePort ?? 0;
    message.destPort = object.destPort ?? 0;
    message.sourceIp = object.sourceIp ?? "";
    message.destIp = object.destIp ?? "";
    message.outerTlsConnectionState = object.outerTlsConnectionState ?? 0;
    message.outerTlsSessionState = object.outerTlsSessionState ?? 0;
    message.outerTlsErr = object.outerTlsErr ?? "";
    message.innerTlsConnectionState = object.innerTlsConnectionState ?? 0;
    message.innerTlsErr = object.innerTlsErr ?? "";
    message.outerTlsLeafCert = object.outerTlsLeafCert ?? "";
    message.outerTlsIntermediateCerts = object.outerTlsIntermediateCerts?.map((e) => e) || [];
    message.outerTlsRootCerts = object.outerTlsRootCerts?.map((e) => e) || [];
    message.outerTlsVerifyErr = object.outerTlsVerifyErr ?? 0;
    message.outerTlsVerifyErrString = object.outerTlsVerifyErrString ?? "";
    message.proxyUrl = object.proxyUrl ?? "";
    message.source = object.source ?? "";
    message.fabricPath = object.fabricPath ?? "";
    message.initiatedAt = object.initiatedAt ?? undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseProxyConnectionStats(): ProxyConnectionStats {
  return { id: undefined, bytesIn: 0, bytesOut: 0, inBitsps: 0, outBitsps: 0, collectedAt: undefined };
}

export const ProxyConnectionStats = {
  encode(message: ProxyConnectionStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.bytesIn !== 0) {
      writer.uint32(24).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(32).uint64(message.bytesOut);
    }
    if (message.inBitsps !== 0) {
      writer.uint32(45).float(message.inBitsps);
    }
    if (message.outBitsps !== 0) {
      writer.uint32(53).float(message.outBitsps);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxyConnectionStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxyConnectionStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.inBitsps = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.outBitsps = reader.float();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxyConnectionStats {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      inBitsps: isSet(object.inBitsps) ? globalThis.Number(object.inBitsps) : 0,
      outBitsps: isSet(object.outBitsps) ? globalThis.Number(object.outBitsps) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ProxyConnectionStats): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.inBitsps !== 0) {
      obj.inBitsps = message.inBitsps;
    }
    if (message.outBitsps !== 0) {
      obj.outBitsps = message.outBitsps;
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxyConnectionStats>, I>>(base?: I): ProxyConnectionStats {
    return ProxyConnectionStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxyConnectionStats>, I>>(object: I): ProxyConnectionStats {
    const message = createBaseProxyConnectionStats();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.inBitsps = object.inBitsps ?? 0;
    message.outBitsps = object.outBitsps ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseGatewayInfo(): GatewayInfo {
  return { isGateway: false };
}

export const GatewayInfo = {
  encode(message: GatewayInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.isGateway !== false) {
      writer.uint32(8).bool(message.isGateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GatewayInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGatewayInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.isGateway = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GatewayInfo {
    return { isGateway: isSet(object.isGateway) ? globalThis.Boolean(object.isGateway) : false };
  },

  toJSON(message: GatewayInfo): unknown {
    const obj: any = {};
    if (message.isGateway !== false) {
      obj.isGateway = message.isGateway;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GatewayInfo>, I>>(base?: I): GatewayInfo {
    return GatewayInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GatewayInfo>, I>>(object: I): GatewayInfo {
    const message = createBaseGatewayInfo();
    message.isGateway = object.isGateway ?? false;
    return message;
  },
};

function createBaseLineCard(): LineCard {
  return { id: undefined };
}

export const LineCard = {
  encode(message: LineCard, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineCard {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineCard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LineCard {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: LineCard): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LineCard>, I>>(base?: I): LineCard {
    return LineCard.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LineCard>, I>>(object: I): LineCard {
    const message = createBaseLineCard();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseBreakout(): Breakout {
  return { id: undefined, state: undefined };
}

export const Breakout = {
  encode(message: Breakout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.state !== undefined) {
      PortState.encode(message.state, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Breakout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBreakout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.state = PortState.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Breakout {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      state: isSet(object.state) ? PortState.fromJSON(object.state) : undefined,
    };
  },

  toJSON(message: Breakout): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.state !== undefined) {
      obj.state = PortState.toJSON(message.state);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Breakout>, I>>(base?: I): Breakout {
    return Breakout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Breakout>, I>>(object: I): Breakout {
    const message = createBaseBreakout();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.state = (object.state !== undefined && object.state !== null)
      ? PortState.fromPartial(object.state)
      : undefined;
    return message;
  },
};

function createBaseDacQsfp(): DacQsfp {
  return { id: undefined, counters: undefined };
}

export const DacQsfp = {
  encode(message: DacQsfp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.counters !== undefined) {
      DacCounters.encode(message.counters, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DacQsfp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDacQsfp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.counters = DacCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DacQsfp {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      counters: isSet(object.counters) ? DacCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: DacQsfp): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.counters !== undefined) {
      obj.counters = DacCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DacQsfp>, I>>(base?: I): DacQsfp {
    return DacQsfp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DacQsfp>, I>>(object: I): DacQsfp {
    const message = createBaseDacQsfp();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? DacCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseDacCounters(): DacCounters {
  return { inOctets: 0 };
}

export const DacCounters = {
  encode(message: DacCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inOctets !== 0) {
      writer.uint32(16).uint64(message.inOctets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DacCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDacCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inOctets = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DacCounters {
    return { inOctets: isSet(object["in-octets"]) ? globalThis.Number(object["in-octets"]) : 0 };
  },

  toJSON(message: DacCounters): unknown {
    const obj: any = {};
    if (message.inOctets !== 0) {
      obj["in-octets"] = Math.round(message.inOctets);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DacCounters>, I>>(base?: I): DacCounters {
    return DacCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DacCounters>, I>>(object: I): DacCounters {
    const message = createBaseDacCounters();
    message.inOctets = object.inOctets ?? 0;
    return message;
  },
};

function createBaseFiberQsfp(): FiberQsfp {
  return { id: undefined, counters: undefined };
}

export const FiberQsfp = {
  encode(message: FiberQsfp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.counters !== undefined) {
      FiberCounters.encode(message.counters, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FiberQsfp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFiberQsfp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.counters = FiberCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FiberQsfp {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      counters: isSet(object.counters) ? FiberCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: FiberQsfp): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.counters !== undefined) {
      obj.counters = FiberCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FiberQsfp>, I>>(base?: I): FiberQsfp {
    return FiberQsfp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FiberQsfp>, I>>(object: I): FiberQsfp {
    const message = createBaseFiberQsfp();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? FiberCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseFiberCounters(): FiberCounters {
  return { inOctets: 0 };
}

export const FiberCounters = {
  encode(message: FiberCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.inOctets !== 0) {
      writer.uint32(16).uint64(message.inOctets);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FiberCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFiberCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inOctets = longToNumber(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FiberCounters {
    return { inOctets: isSet(object["in-octets"]) ? globalThis.Number(object["in-octets"]) : 0 };
  },

  toJSON(message: FiberCounters): unknown {
    const obj: any = {};
    if (message.inOctets !== 0) {
      obj["in-octets"] = Math.round(message.inOctets);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FiberCounters>, I>>(base?: I): FiberCounters {
    return FiberCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FiberCounters>, I>>(object: I): FiberCounters {
    const message = createBaseFiberCounters();
    message.inOctets = object.inOctets ?? 0;
    return message;
  },
};

function createBasePortConfig(): PortConfig {
  return { id: undefined, config: undefined };
}

export const PortConfig = {
  encode(message: PortConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      PortConfigDetails.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = PortConfigDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortConfig {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? PortConfigDetails.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: PortConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = PortConfigDetails.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortConfig>, I>>(base?: I): PortConfig {
    return PortConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortConfig>, I>>(object: I): PortConfig {
    const message = createBasePortConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? PortConfigDetails.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBasePortConfigDetails(): PortConfigDetails {
  return {
    portName: "",
    portIndex: 0,
    portRole: 0,
    adminState: 0,
    mtu: 0,
    deviceId: "",
    targetDeviceId: "",
    targetPortName: "",
    modifiedAt: undefined,
    breakOut: false,
  };
}

export const PortConfigDetails = {
  encode(message: PortConfigDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.portName !== "") {
      writer.uint32(10).string(message.portName);
    }
    if (message.portIndex !== 0) {
      writer.uint32(16).int32(message.portIndex);
    }
    if (message.portRole !== 0) {
      writer.uint32(24).int32(message.portRole);
    }
    if (message.adminState !== 0) {
      writer.uint32(32).int32(message.adminState);
    }
    if (message.mtu !== 0) {
      writer.uint32(40).uint32(message.mtu);
    }
    if (message.deviceId !== "") {
      writer.uint32(50).string(message.deviceId);
    }
    if (message.targetDeviceId !== "") {
      writer.uint32(58).string(message.targetDeviceId);
    }
    if (message.targetPortName !== "") {
      writer.uint32(66).string(message.targetPortName);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(74).fork()).ldelim();
    }
    if (message.breakOut !== false) {
      writer.uint32(80).bool(message.breakOut);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortConfigDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortConfigDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.portIndex = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.portRole = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.targetDeviceId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.targetPortName = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.breakOut = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortConfigDetails {
    return {
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      portIndex: isSet(object.portIndex) ? globalThis.Number(object.portIndex) : 0,
      portRole: isSet(object.portRole) ? portRoleFromJSON(object.portRole) : 0,
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      targetDeviceId: isSet(object.targetDeviceId) ? globalThis.String(object.targetDeviceId) : "",
      targetPortName: isSet(object.targetPortName) ? globalThis.String(object.targetPortName) : "",
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      breakOut: isSet(object.breakOut) ? globalThis.Boolean(object.breakOut) : false,
    };
  },

  toJSON(message: PortConfigDetails): unknown {
    const obj: any = {};
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.portIndex !== 0) {
      obj.portIndex = Math.round(message.portIndex);
    }
    if (message.portRole !== 0) {
      obj.portRole = portRoleToJSON(message.portRole);
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.targetDeviceId !== "") {
      obj.targetDeviceId = message.targetDeviceId;
    }
    if (message.targetPortName !== "") {
      obj.targetPortName = message.targetPortName;
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.breakOut !== false) {
      obj.breakOut = message.breakOut;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortConfigDetails>, I>>(base?: I): PortConfigDetails {
    return PortConfigDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortConfigDetails>, I>>(object: I): PortConfigDetails {
    const message = createBasePortConfigDetails();
    message.portName = object.portName ?? "";
    message.portIndex = object.portIndex ?? 0;
    message.portRole = object.portRole ?? 0;
    message.adminState = object.adminState ?? 0;
    message.mtu = object.mtu ?? 0;
    message.deviceId = object.deviceId ?? "";
    message.targetDeviceId = object.targetDeviceId ?? "";
    message.targetPortName = object.targetPortName ?? "";
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.breakOut = object.breakOut ?? false;
    return message;
  },
};

function createBasePort(): Port {
  return { id: undefined };
}

export const Port = {
  encode(message: Port, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Port {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Port {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: Port): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Port>, I>>(base?: I): Port {
    return Port.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Port>, I>>(object: I): Port {
    const message = createBasePort();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseSubInterface(): SubInterface {
  return { id: undefined, details: undefined };
}

export const SubInterface = {
  encode(message: SubInterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.details !== undefined) {
      SubInterfaceDetails.encode(message.details, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubInterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.details = SubInterfaceDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubInterface {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      details: isSet(object.details) ? SubInterfaceDetails.fromJSON(object.details) : undefined,
    };
  },

  toJSON(message: SubInterface): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.details !== undefined) {
      obj.details = SubInterfaceDetails.toJSON(message.details);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubInterface>, I>>(base?: I): SubInterface {
    return SubInterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubInterface>, I>>(object: I): SubInterface {
    const message = createBaseSubInterface();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.details = (object.details !== undefined && object.details !== null)
      ? SubInterfaceDetails.fromPartial(object.details)
      : undefined;
    return message;
  },
};

function createBaseSubInterfaceDetails(): SubInterfaceDetails {
  return {
    subInterfaceName: "",
    portName: "",
    adminState: 0,
    operState: 0,
    mtu: 0,
    macAddr: "",
    vlan: 0,
    collectedAt: undefined,
  };
}

export const SubInterfaceDetails = {
  encode(message: SubInterfaceDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.subInterfaceName !== "") {
      writer.uint32(10).string(message.subInterfaceName);
    }
    if (message.portName !== "") {
      writer.uint32(18).string(message.portName);
    }
    if (message.adminState !== 0) {
      writer.uint32(24).int32(message.adminState);
    }
    if (message.operState !== 0) {
      writer.uint32(32).int32(message.operState);
    }
    if (message.mtu !== 0) {
      writer.uint32(40).int32(message.mtu);
    }
    if (message.macAddr !== "") {
      writer.uint32(50).string(message.macAddr);
    }
    if (message.vlan !== 0) {
      writer.uint32(56).int32(message.vlan);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SubInterfaceDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSubInterfaceDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.subInterfaceName = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.portName = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.operState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mtu = reader.int32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.macAddr = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.vlan = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SubInterfaceDetails {
    return {
      subInterfaceName: isSet(object.subInterfaceName) ? globalThis.String(object.subInterfaceName) : "",
      portName: isSet(object.portName) ? globalThis.String(object.portName) : "",
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      operState: isSet(object.operState) ? operStateFromJSON(object.operState) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      macAddr: isSet(object.macAddr) ? globalThis.String(object.macAddr) : "",
      vlan: isSet(object.vlan) ? globalThis.Number(object.vlan) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: SubInterfaceDetails): unknown {
    const obj: any = {};
    if (message.subInterfaceName !== "") {
      obj.subInterfaceName = message.subInterfaceName;
    }
    if (message.portName !== "") {
      obj.portName = message.portName;
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.operState !== 0) {
      obj.operState = operStateToJSON(message.operState);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.macAddr !== "") {
      obj.macAddr = message.macAddr;
    }
    if (message.vlan !== 0) {
      obj.vlan = Math.round(message.vlan);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SubInterfaceDetails>, I>>(base?: I): SubInterfaceDetails {
    return SubInterfaceDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SubInterfaceDetails>, I>>(object: I): SubInterfaceDetails {
    const message = createBaseSubInterfaceDetails();
    message.subInterfaceName = object.subInterfaceName ?? "";
    message.portName = object.portName ?? "";
    message.adminState = object.adminState ?? 0;
    message.operState = object.operState ?? 0;
    message.mtu = object.mtu ?? 0;
    message.macAddr = object.macAddr ?? "";
    message.vlan = object.vlan ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBasePortCounters(): PortCounters {
  return {
    id: undefined,
    inOctets: 0,
    inPkts: 0,
    inUnicastPkts: 0,
    inBroadcastPkts: 0,
    inMulticastPkts: 0,
    inDiscards: 0,
    inErrors: 0,
    inUnknownProtos: 0,
    inFcsErrors: 0,
    outOctets: 0,
    outPkts: 0,
    outUnicastPkts: 0,
    outBroadcastPkts: 0,
    outMulticastPkts: 0,
    outDiscards: 0,
    outErrors: 0,
    carrierTransitions: 0,
    inPfc0Pkts: 0,
    outPfc0Pkts: 0,
    inPfc1Pkts: 0,
    outPfc1Pkts: 0,
    inPfc2Pkts: 0,
    outPfc2Pkts: 0,
    inPfc3Pkts: 0,
    outPfc3Pkts: 0,
    inPfc4Pkts: 0,
    outPfc4Pkts: 0,
    inPfc5Pkts: 0,
    outPfc5Pkts: 0,
    inPfc6Pkts: 0,
    outPfc6Pkts: 0,
    inPfc7Pkts: 0,
    outPfc7Pkts: 0,
    inBitsps: 0,
    outBitsps: 0,
    inPps: 0,
    outPps: 0,
    inUnicastPps: 0,
    outUnicastPps: 0,
    inPfcPps: 0,
    outPfcPps: 0,
    lastClear: undefined,
    collectedAt: undefined,
  };
}

export const PortCounters = {
  encode(message: PortCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.inOctets !== 0) {
      writer.uint32(16).uint64(message.inOctets);
    }
    if (message.inPkts !== 0) {
      writer.uint32(24).uint64(message.inPkts);
    }
    if (message.inUnicastPkts !== 0) {
      writer.uint32(32).uint64(message.inUnicastPkts);
    }
    if (message.inBroadcastPkts !== 0) {
      writer.uint32(160).uint64(message.inBroadcastPkts);
    }
    if (message.inMulticastPkts !== 0) {
      writer.uint32(40).uint64(message.inMulticastPkts);
    }
    if (message.inDiscards !== 0) {
      writer.uint32(48).uint64(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      writer.uint32(56).uint64(message.inErrors);
    }
    if (message.inUnknownProtos !== 0) {
      writer.uint32(64).uint64(message.inUnknownProtos);
    }
    if (message.inFcsErrors !== 0) {
      writer.uint32(72).uint64(message.inFcsErrors);
    }
    if (message.outOctets !== 0) {
      writer.uint32(80).uint64(message.outOctets);
    }
    if (message.outPkts !== 0) {
      writer.uint32(88).uint64(message.outPkts);
    }
    if (message.outUnicastPkts !== 0) {
      writer.uint32(96).uint64(message.outUnicastPkts);
    }
    if (message.outBroadcastPkts !== 0) {
      writer.uint32(112).uint64(message.outBroadcastPkts);
    }
    if (message.outMulticastPkts !== 0) {
      writer.uint32(120).uint64(message.outMulticastPkts);
    }
    if (message.outDiscards !== 0) {
      writer.uint32(128).uint64(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      writer.uint32(136).uint64(message.outErrors);
    }
    if (message.carrierTransitions !== 0) {
      writer.uint32(144).uint64(message.carrierTransitions);
    }
    if (message.inPfc0Pkts !== 0) {
      writer.uint32(176).uint64(message.inPfc0Pkts);
    }
    if (message.outPfc0Pkts !== 0) {
      writer.uint32(184).uint64(message.outPfc0Pkts);
    }
    if (message.inPfc1Pkts !== 0) {
      writer.uint32(192).uint64(message.inPfc1Pkts);
    }
    if (message.outPfc1Pkts !== 0) {
      writer.uint32(200).uint64(message.outPfc1Pkts);
    }
    if (message.inPfc2Pkts !== 0) {
      writer.uint32(208).uint64(message.inPfc2Pkts);
    }
    if (message.outPfc2Pkts !== 0) {
      writer.uint32(216).uint64(message.outPfc2Pkts);
    }
    if (message.inPfc3Pkts !== 0) {
      writer.uint32(224).uint64(message.inPfc3Pkts);
    }
    if (message.outPfc3Pkts !== 0) {
      writer.uint32(232).uint64(message.outPfc3Pkts);
    }
    if (message.inPfc4Pkts !== 0) {
      writer.uint32(240).uint64(message.inPfc4Pkts);
    }
    if (message.outPfc4Pkts !== 0) {
      writer.uint32(248).uint64(message.outPfc4Pkts);
    }
    if (message.inPfc5Pkts !== 0) {
      writer.uint32(256).uint64(message.inPfc5Pkts);
    }
    if (message.outPfc5Pkts !== 0) {
      writer.uint32(264).uint64(message.outPfc5Pkts);
    }
    if (message.inPfc6Pkts !== 0) {
      writer.uint32(272).uint64(message.inPfc6Pkts);
    }
    if (message.outPfc6Pkts !== 0) {
      writer.uint32(280).uint64(message.outPfc6Pkts);
    }
    if (message.inPfc7Pkts !== 0) {
      writer.uint32(288).uint64(message.inPfc7Pkts);
    }
    if (message.outPfc7Pkts !== 0) {
      writer.uint32(296).uint64(message.outPfc7Pkts);
    }
    if (message.inBitsps !== 0) {
      writer.uint32(309).float(message.inBitsps);
    }
    if (message.outBitsps !== 0) {
      writer.uint32(317).float(message.outBitsps);
    }
    if (message.inPps !== 0) {
      writer.uint32(325).float(message.inPps);
    }
    if (message.outPps !== 0) {
      writer.uint32(333).float(message.outPps);
    }
    if (message.inUnicastPps !== 0) {
      writer.uint32(341).float(message.inUnicastPps);
    }
    if (message.outUnicastPps !== 0) {
      writer.uint32(349).float(message.outUnicastPps);
    }
    if (message.inPfcPps !== 0) {
      writer.uint32(357).float(message.inPfcPps);
    }
    if (message.outPfcPps !== 0) {
      writer.uint32(365).float(message.outPfcPps);
    }
    if (message.lastClear !== undefined) {
      Timestamp.encode(toTimestamp(message.lastClear), writer.uint32(154).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(170).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.inOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.inPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.inUnicastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.inBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.inMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.inDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.inErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.inUnknownProtos = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.inFcsErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.outOctets = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.outPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.outUnicastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.outBroadcastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.outMulticastPkts = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.outDiscards = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.outErrors = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.carrierTransitions = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.inPfc0Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.outPfc0Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.inPfc1Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.outPfc1Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.inPfc2Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.outPfc2Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.inPfc3Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.outPfc3Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.inPfc4Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.outPfc4Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.inPfc5Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.outPfc5Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.inPfc6Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.outPfc6Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.inPfc7Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.outPfc7Pkts = longToNumber(reader.uint64() as Long);
          continue;
        case 38:
          if (tag !== 309) {
            break;
          }

          message.inBitsps = reader.float();
          continue;
        case 39:
          if (tag !== 317) {
            break;
          }

          message.outBitsps = reader.float();
          continue;
        case 40:
          if (tag !== 325) {
            break;
          }

          message.inPps = reader.float();
          continue;
        case 41:
          if (tag !== 333) {
            break;
          }

          message.outPps = reader.float();
          continue;
        case 42:
          if (tag !== 341) {
            break;
          }

          message.inUnicastPps = reader.float();
          continue;
        case 43:
          if (tag !== 349) {
            break;
          }

          message.outUnicastPps = reader.float();
          continue;
        case 44:
          if (tag !== 357) {
            break;
          }

          message.inPfcPps = reader.float();
          continue;
        case 45:
          if (tag !== 365) {
            break;
          }

          message.outPfcPps = reader.float();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.lastClear = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      inOctets: isSet(object["in-octets"]) ? globalThis.Number(object["in-octets"]) : 0,
      inPkts: isSet(object["in-pkts"]) ? globalThis.Number(object["in-pkts"]) : 0,
      inUnicastPkts: isSet(object["in-unicast-pkts"]) ? globalThis.Number(object["in-unicast-pkts"]) : 0,
      inBroadcastPkts: isSet(object["in-broadcast-pkts"]) ? globalThis.Number(object["in-broadcast-pkts"]) : 0,
      inMulticastPkts: isSet(object["in-multicast-pkts"]) ? globalThis.Number(object["in-multicast-pkts"]) : 0,
      inDiscards: isSet(object["in-discards"]) ? globalThis.Number(object["in-discards"]) : 0,
      inErrors: isSet(object["in-errors"]) ? globalThis.Number(object["in-errors"]) : 0,
      inUnknownProtos: isSet(object["in-unknown-protos"]) ? globalThis.Number(object["in-unknown-protos"]) : 0,
      inFcsErrors: isSet(object["in-fcs-errors"]) ? globalThis.Number(object["in-fcs-errors"]) : 0,
      outOctets: isSet(object["out-octets"]) ? globalThis.Number(object["out-octets"]) : 0,
      outPkts: isSet(object["out-pkts"]) ? globalThis.Number(object["out-pkts"]) : 0,
      outUnicastPkts: isSet(object["out-unicast-pkts"]) ? globalThis.Number(object["out-unicast-pkts"]) : 0,
      outBroadcastPkts: isSet(object["out-broadcast-pkts"]) ? globalThis.Number(object["out-broadcast-pkts"]) : 0,
      outMulticastPkts: isSet(object["out-multicast-pkts"]) ? globalThis.Number(object["out-multicast-pkts"]) : 0,
      outDiscards: isSet(object["out-discards"]) ? globalThis.Number(object["out-discards"]) : 0,
      outErrors: isSet(object["out-errors"]) ? globalThis.Number(object["out-errors"]) : 0,
      carrierTransitions: isSet(object["carrier-transitions"]) ? globalThis.Number(object["carrier-transitions"]) : 0,
      inPfc0Pkts: isSet(object["in-pfc0-pkts"]) ? globalThis.Number(object["in-pfc0-pkts"]) : 0,
      outPfc0Pkts: isSet(object["out-pfc0-pkts"]) ? globalThis.Number(object["out-pfc0-pkts"]) : 0,
      inPfc1Pkts: isSet(object["in-pfc1-pkts"]) ? globalThis.Number(object["in-pfc1-pkts"]) : 0,
      outPfc1Pkts: isSet(object["out-pfc1-pkts"]) ? globalThis.Number(object["out-pfc1-pkts"]) : 0,
      inPfc2Pkts: isSet(object["in-pfc2-pkts"]) ? globalThis.Number(object["in-pfc2-pkts"]) : 0,
      outPfc2Pkts: isSet(object["out-pfc2-pkts"]) ? globalThis.Number(object["out-pfc2-pkts"]) : 0,
      inPfc3Pkts: isSet(object["in-pfc3-pkts"]) ? globalThis.Number(object["in-pfc3-pkts"]) : 0,
      outPfc3Pkts: isSet(object["out-pfc3-pkts"]) ? globalThis.Number(object["out-pfc3-pkts"]) : 0,
      inPfc4Pkts: isSet(object["in-pfc4-pkts"]) ? globalThis.Number(object["in-pfc4-pkts"]) : 0,
      outPfc4Pkts: isSet(object["out-pfc4-pkts"]) ? globalThis.Number(object["out-pfc4-pkts"]) : 0,
      inPfc5Pkts: isSet(object["in-pfc5-pkts"]) ? globalThis.Number(object["in-pfc5-pkts"]) : 0,
      outPfc5Pkts: isSet(object["out-pfc5-pkts"]) ? globalThis.Number(object["out-pfc5-pkts"]) : 0,
      inPfc6Pkts: isSet(object["in-pfc6-pkts"]) ? globalThis.Number(object["in-pfc6-pkts"]) : 0,
      outPfc6Pkts: isSet(object["out-pfc6-pkts"]) ? globalThis.Number(object["out-pfc6-pkts"]) : 0,
      inPfc7Pkts: isSet(object["in-pfc7-pkts"]) ? globalThis.Number(object["in-pfc7-pkts"]) : 0,
      outPfc7Pkts: isSet(object["out-pfc7-pkts"]) ? globalThis.Number(object["out-pfc7-pkts"]) : 0,
      inBitsps: isSet(object["in-bps"]) ? globalThis.Number(object["in-bps"]) : 0,
      outBitsps: isSet(object["out-bps"]) ? globalThis.Number(object["out-bps"]) : 0,
      inPps: isSet(object["in-pps"]) ? globalThis.Number(object["in-pps"]) : 0,
      outPps: isSet(object["out-pps"]) ? globalThis.Number(object["out-pps"]) : 0,
      inUnicastPps: isSet(object["in-unicast-pps"]) ? globalThis.Number(object["in-unicast-pps"]) : 0,
      outUnicastPps: isSet(object["out-unicast-pps"]) ? globalThis.Number(object["out-unicast-pps"]) : 0,
      inPfcPps: isSet(object["in-pfc-pps"]) ? globalThis.Number(object["in-pfc-pps"]) : 0,
      outPfcPps: isSet(object["out-pfc-pps"]) ? globalThis.Number(object["out-pfc-pps"]) : 0,
      lastClear: isSet(object["last-clear"]) ? fromJsonTimestamp(object["last-clear"]) : undefined,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: PortCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.inOctets !== 0) {
      obj["in-octets"] = Math.round(message.inOctets);
    }
    if (message.inPkts !== 0) {
      obj["in-pkts"] = Math.round(message.inPkts);
    }
    if (message.inUnicastPkts !== 0) {
      obj["in-unicast-pkts"] = Math.round(message.inUnicastPkts);
    }
    if (message.inBroadcastPkts !== 0) {
      obj["in-broadcast-pkts"] = Math.round(message.inBroadcastPkts);
    }
    if (message.inMulticastPkts !== 0) {
      obj["in-multicast-pkts"] = Math.round(message.inMulticastPkts);
    }
    if (message.inDiscards !== 0) {
      obj["in-discards"] = Math.round(message.inDiscards);
    }
    if (message.inErrors !== 0) {
      obj["in-errors"] = Math.round(message.inErrors);
    }
    if (message.inUnknownProtos !== 0) {
      obj["in-unknown-protos"] = Math.round(message.inUnknownProtos);
    }
    if (message.inFcsErrors !== 0) {
      obj["in-fcs-errors"] = Math.round(message.inFcsErrors);
    }
    if (message.outOctets !== 0) {
      obj["out-octets"] = Math.round(message.outOctets);
    }
    if (message.outPkts !== 0) {
      obj["out-pkts"] = Math.round(message.outPkts);
    }
    if (message.outUnicastPkts !== 0) {
      obj["out-unicast-pkts"] = Math.round(message.outUnicastPkts);
    }
    if (message.outBroadcastPkts !== 0) {
      obj["out-broadcast-pkts"] = Math.round(message.outBroadcastPkts);
    }
    if (message.outMulticastPkts !== 0) {
      obj["out-multicast-pkts"] = Math.round(message.outMulticastPkts);
    }
    if (message.outDiscards !== 0) {
      obj["out-discards"] = Math.round(message.outDiscards);
    }
    if (message.outErrors !== 0) {
      obj["out-errors"] = Math.round(message.outErrors);
    }
    if (message.carrierTransitions !== 0) {
      obj["carrier-transitions"] = Math.round(message.carrierTransitions);
    }
    if (message.inPfc0Pkts !== 0) {
      obj["in-pfc0-pkts"] = Math.round(message.inPfc0Pkts);
    }
    if (message.outPfc0Pkts !== 0) {
      obj["out-pfc0-pkts"] = Math.round(message.outPfc0Pkts);
    }
    if (message.inPfc1Pkts !== 0) {
      obj["in-pfc1-pkts"] = Math.round(message.inPfc1Pkts);
    }
    if (message.outPfc1Pkts !== 0) {
      obj["out-pfc1-pkts"] = Math.round(message.outPfc1Pkts);
    }
    if (message.inPfc2Pkts !== 0) {
      obj["in-pfc2-pkts"] = Math.round(message.inPfc2Pkts);
    }
    if (message.outPfc2Pkts !== 0) {
      obj["out-pfc2-pkts"] = Math.round(message.outPfc2Pkts);
    }
    if (message.inPfc3Pkts !== 0) {
      obj["in-pfc3-pkts"] = Math.round(message.inPfc3Pkts);
    }
    if (message.outPfc3Pkts !== 0) {
      obj["out-pfc3-pkts"] = Math.round(message.outPfc3Pkts);
    }
    if (message.inPfc4Pkts !== 0) {
      obj["in-pfc4-pkts"] = Math.round(message.inPfc4Pkts);
    }
    if (message.outPfc4Pkts !== 0) {
      obj["out-pfc4-pkts"] = Math.round(message.outPfc4Pkts);
    }
    if (message.inPfc5Pkts !== 0) {
      obj["in-pfc5-pkts"] = Math.round(message.inPfc5Pkts);
    }
    if (message.outPfc5Pkts !== 0) {
      obj["out-pfc5-pkts"] = Math.round(message.outPfc5Pkts);
    }
    if (message.inPfc6Pkts !== 0) {
      obj["in-pfc6-pkts"] = Math.round(message.inPfc6Pkts);
    }
    if (message.outPfc6Pkts !== 0) {
      obj["out-pfc6-pkts"] = Math.round(message.outPfc6Pkts);
    }
    if (message.inPfc7Pkts !== 0) {
      obj["in-pfc7-pkts"] = Math.round(message.inPfc7Pkts);
    }
    if (message.outPfc7Pkts !== 0) {
      obj["out-pfc7-pkts"] = Math.round(message.outPfc7Pkts);
    }
    if (message.inBitsps !== 0) {
      obj["in-bps"] = message.inBitsps;
    }
    if (message.outBitsps !== 0) {
      obj["out-bps"] = message.outBitsps;
    }
    if (message.inPps !== 0) {
      obj["in-pps"] = message.inPps;
    }
    if (message.outPps !== 0) {
      obj["out-pps"] = message.outPps;
    }
    if (message.inUnicastPps !== 0) {
      obj["in-unicast-pps"] = message.inUnicastPps;
    }
    if (message.outUnicastPps !== 0) {
      obj["out-unicast-pps"] = message.outUnicastPps;
    }
    if (message.inPfcPps !== 0) {
      obj["in-pfc-pps"] = message.inPfcPps;
    }
    if (message.outPfcPps !== 0) {
      obj["out-pfc-pps"] = message.outPfcPps;
    }
    if (message.lastClear !== undefined) {
      obj["last-clear"] = message.lastClear.toISOString();
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortCounters>, I>>(base?: I): PortCounters {
    return PortCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortCounters>, I>>(object: I): PortCounters {
    const message = createBasePortCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.inOctets = object.inOctets ?? 0;
    message.inPkts = object.inPkts ?? 0;
    message.inUnicastPkts = object.inUnicastPkts ?? 0;
    message.inBroadcastPkts = object.inBroadcastPkts ?? 0;
    message.inMulticastPkts = object.inMulticastPkts ?? 0;
    message.inDiscards = object.inDiscards ?? 0;
    message.inErrors = object.inErrors ?? 0;
    message.inUnknownProtos = object.inUnknownProtos ?? 0;
    message.inFcsErrors = object.inFcsErrors ?? 0;
    message.outOctets = object.outOctets ?? 0;
    message.outPkts = object.outPkts ?? 0;
    message.outUnicastPkts = object.outUnicastPkts ?? 0;
    message.outBroadcastPkts = object.outBroadcastPkts ?? 0;
    message.outMulticastPkts = object.outMulticastPkts ?? 0;
    message.outDiscards = object.outDiscards ?? 0;
    message.outErrors = object.outErrors ?? 0;
    message.carrierTransitions = object.carrierTransitions ?? 0;
    message.inPfc0Pkts = object.inPfc0Pkts ?? 0;
    message.outPfc0Pkts = object.outPfc0Pkts ?? 0;
    message.inPfc1Pkts = object.inPfc1Pkts ?? 0;
    message.outPfc1Pkts = object.outPfc1Pkts ?? 0;
    message.inPfc2Pkts = object.inPfc2Pkts ?? 0;
    message.outPfc2Pkts = object.outPfc2Pkts ?? 0;
    message.inPfc3Pkts = object.inPfc3Pkts ?? 0;
    message.outPfc3Pkts = object.outPfc3Pkts ?? 0;
    message.inPfc4Pkts = object.inPfc4Pkts ?? 0;
    message.outPfc4Pkts = object.outPfc4Pkts ?? 0;
    message.inPfc5Pkts = object.inPfc5Pkts ?? 0;
    message.outPfc5Pkts = object.outPfc5Pkts ?? 0;
    message.inPfc6Pkts = object.inPfc6Pkts ?? 0;
    message.outPfc6Pkts = object.outPfc6Pkts ?? 0;
    message.inPfc7Pkts = object.inPfc7Pkts ?? 0;
    message.outPfc7Pkts = object.outPfc7Pkts ?? 0;
    message.inBitsps = object.inBitsps ?? 0;
    message.outBitsps = object.outBitsps ?? 0;
    message.inPps = object.inPps ?? 0;
    message.outPps = object.outPps ?? 0;
    message.inUnicastPps = object.inUnicastPps ?? 0;
    message.outUnicastPps = object.outUnicastPps ?? 0;
    message.inPfcPps = object.inPfcPps ?? 0;
    message.outPfcPps = object.outPfcPps ?? 0;
    message.lastClear = object.lastClear ?? undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseIpmSessionId(): IpmSessionId {
  return { id: undefined };
}

export const IpmSessionId = {
  encode(message: IpmSessionId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmSessionId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmSessionId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmSessionId {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: IpmSessionId): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmSessionId>, I>>(base?: I): IpmSessionId {
    return IpmSessionId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmSessionId>, I>>(object: I): IpmSessionId {
    const message = createBaseIpmSessionId();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseIpmSessionParameters(): IpmSessionParameters {
  return { description: "", srcAddr: "", dstAddr: "", vrf: "", flowLabel: 0, dscp: 0, confSessionId: "" };
}

export const IpmSessionParameters = {
  encode(message: IpmSessionParameters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.srcAddr !== "") {
      writer.uint32(26).string(message.srcAddr);
    }
    if (message.dstAddr !== "") {
      writer.uint32(34).string(message.dstAddr);
    }
    if (message.vrf !== "") {
      writer.uint32(42).string(message.vrf);
    }
    if (message.flowLabel !== 0) {
      writer.uint32(48).uint32(message.flowLabel);
    }
    if (message.dscp !== 0) {
      writer.uint32(56).uint32(message.dscp);
    }
    if (message.confSessionId !== "") {
      writer.uint32(66).string(message.confSessionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmSessionParameters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmSessionParameters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.srcAddr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.dstAddr = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vrf = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.flowLabel = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.dscp = reader.uint32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.confSessionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmSessionParameters {
    return {
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      srcAddr: isSet(object.srcAddr) ? globalThis.String(object.srcAddr) : "",
      dstAddr: isSet(object.dstAddr) ? globalThis.String(object.dstAddr) : "",
      vrf: isSet(object.vrf) ? globalThis.String(object.vrf) : "",
      flowLabel: isSet(object.flowLabel) ? globalThis.Number(object.flowLabel) : 0,
      dscp: isSet(object.dscp) ? globalThis.Number(object.dscp) : 0,
      confSessionId: isSet(object.confSessionId) ? globalThis.String(object.confSessionId) : "",
    };
  },

  toJSON(message: IpmSessionParameters): unknown {
    const obj: any = {};
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.srcAddr !== "") {
      obj.srcAddr = message.srcAddr;
    }
    if (message.dstAddr !== "") {
      obj.dstAddr = message.dstAddr;
    }
    if (message.vrf !== "") {
      obj.vrf = message.vrf;
    }
    if (message.flowLabel !== 0) {
      obj.flowLabel = Math.round(message.flowLabel);
    }
    if (message.dscp !== 0) {
      obj.dscp = Math.round(message.dscp);
    }
    if (message.confSessionId !== "") {
      obj.confSessionId = message.confSessionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmSessionParameters>, I>>(base?: I): IpmSessionParameters {
    return IpmSessionParameters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmSessionParameters>, I>>(object: I): IpmSessionParameters {
    const message = createBaseIpmSessionParameters();
    message.description = object.description ?? "";
    message.srcAddr = object.srcAddr ?? "";
    message.dstAddr = object.dstAddr ?? "";
    message.vrf = object.vrf ?? "";
    message.flowLabel = object.flowLabel ?? 0;
    message.dscp = object.dscp ?? 0;
    message.confSessionId = object.confSessionId ?? "";
    return message;
  },
};

function createBaseIpmRawLatencyCounters(): IpmRawLatencyCounters {
  return {
    id: undefined,
    rxBin0: 0,
    rxBin1: 0,
    rxBin2: 0,
    rxBin3: 0,
    rxBin4: 0,
    rxBin5: 0,
    rxBin6: 0,
    rxBin7: 0,
    rxBin8: 0,
    rxBin9: 0,
    rxBin10: 0,
    rxBin11: 0,
    rxBin12: 0,
    rxBin13: 0,
    rxBin14: 0,
    rxBin15: 0,
    rxBin16: 0,
    rxBin17: 0,
    rxBin18: 0,
    rxBin19: 0,
    rxBin20: 0,
    rxBin21: 0,
    rxBin22: 0,
    rxBin23: 0,
    rxBin24: 0,
    rxBin25: 0,
    rxBin26: 0,
    rxBin27: 0,
    maxBin0: 0,
    maxBin1: 0,
    maxBin2: 0,
    maxBin3: 0,
    maxBin4: 0,
    maxBin5: 0,
    maxBin6: 0,
    maxBin7: 0,
    maxBin8: 0,
    maxBin9: 0,
    maxBin10: 0,
    maxBin11: 0,
    maxBin12: 0,
    maxBin13: 0,
    maxBin14: 0,
    maxBin15: 0,
    maxBin16: 0,
    maxBin17: 0,
    maxBin18: 0,
    maxBin19: 0,
    maxBin20: 0,
    maxBin21: 0,
    maxBin22: 0,
    maxBin23: 0,
    maxBin24: 0,
    maxBin25: 0,
    maxBin26: 0,
    maxBin27: 0,
    ipmParams: undefined,
    collectedAt: undefined,
  };
}

export const IpmRawLatencyCounters = {
  encode(message: IpmRawLatencyCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.rxBin0 !== 0) {
      writer.uint32(16).uint64(message.rxBin0);
    }
    if (message.rxBin1 !== 0) {
      writer.uint32(24).uint64(message.rxBin1);
    }
    if (message.rxBin2 !== 0) {
      writer.uint32(32).uint64(message.rxBin2);
    }
    if (message.rxBin3 !== 0) {
      writer.uint32(40).uint64(message.rxBin3);
    }
    if (message.rxBin4 !== 0) {
      writer.uint32(48).uint64(message.rxBin4);
    }
    if (message.rxBin5 !== 0) {
      writer.uint32(56).uint64(message.rxBin5);
    }
    if (message.rxBin6 !== 0) {
      writer.uint32(64).uint64(message.rxBin6);
    }
    if (message.rxBin7 !== 0) {
      writer.uint32(72).uint64(message.rxBin7);
    }
    if (message.rxBin8 !== 0) {
      writer.uint32(80).uint64(message.rxBin8);
    }
    if (message.rxBin9 !== 0) {
      writer.uint32(88).uint64(message.rxBin9);
    }
    if (message.rxBin10 !== 0) {
      writer.uint32(96).uint64(message.rxBin10);
    }
    if (message.rxBin11 !== 0) {
      writer.uint32(104).uint64(message.rxBin11);
    }
    if (message.rxBin12 !== 0) {
      writer.uint32(112).uint64(message.rxBin12);
    }
    if (message.rxBin13 !== 0) {
      writer.uint32(120).uint64(message.rxBin13);
    }
    if (message.rxBin14 !== 0) {
      writer.uint32(128).uint64(message.rxBin14);
    }
    if (message.rxBin15 !== 0) {
      writer.uint32(136).uint64(message.rxBin15);
    }
    if (message.rxBin16 !== 0) {
      writer.uint32(144).uint64(message.rxBin16);
    }
    if (message.rxBin17 !== 0) {
      writer.uint32(152).uint64(message.rxBin17);
    }
    if (message.rxBin18 !== 0) {
      writer.uint32(160).uint64(message.rxBin18);
    }
    if (message.rxBin19 !== 0) {
      writer.uint32(168).uint64(message.rxBin19);
    }
    if (message.rxBin20 !== 0) {
      writer.uint32(176).uint64(message.rxBin20);
    }
    if (message.rxBin21 !== 0) {
      writer.uint32(184).uint64(message.rxBin21);
    }
    if (message.rxBin22 !== 0) {
      writer.uint32(192).uint64(message.rxBin22);
    }
    if (message.rxBin23 !== 0) {
      writer.uint32(200).uint64(message.rxBin23);
    }
    if (message.rxBin24 !== 0) {
      writer.uint32(208).uint64(message.rxBin24);
    }
    if (message.rxBin25 !== 0) {
      writer.uint32(216).uint64(message.rxBin25);
    }
    if (message.rxBin26 !== 0) {
      writer.uint32(224).uint64(message.rxBin26);
    }
    if (message.rxBin27 !== 0) {
      writer.uint32(232).uint64(message.rxBin27);
    }
    if (message.maxBin0 !== 0) {
      writer.uint32(240).uint64(message.maxBin0);
    }
    if (message.maxBin1 !== 0) {
      writer.uint32(248).uint64(message.maxBin1);
    }
    if (message.maxBin2 !== 0) {
      writer.uint32(256).uint64(message.maxBin2);
    }
    if (message.maxBin3 !== 0) {
      writer.uint32(264).uint64(message.maxBin3);
    }
    if (message.maxBin4 !== 0) {
      writer.uint32(272).uint64(message.maxBin4);
    }
    if (message.maxBin5 !== 0) {
      writer.uint32(280).uint64(message.maxBin5);
    }
    if (message.maxBin6 !== 0) {
      writer.uint32(288).uint64(message.maxBin6);
    }
    if (message.maxBin7 !== 0) {
      writer.uint32(296).uint64(message.maxBin7);
    }
    if (message.maxBin8 !== 0) {
      writer.uint32(304).uint64(message.maxBin8);
    }
    if (message.maxBin9 !== 0) {
      writer.uint32(312).uint64(message.maxBin9);
    }
    if (message.maxBin10 !== 0) {
      writer.uint32(320).uint64(message.maxBin10);
    }
    if (message.maxBin11 !== 0) {
      writer.uint32(328).uint64(message.maxBin11);
    }
    if (message.maxBin12 !== 0) {
      writer.uint32(336).uint64(message.maxBin12);
    }
    if (message.maxBin13 !== 0) {
      writer.uint32(344).uint64(message.maxBin13);
    }
    if (message.maxBin14 !== 0) {
      writer.uint32(352).uint64(message.maxBin14);
    }
    if (message.maxBin15 !== 0) {
      writer.uint32(360).uint64(message.maxBin15);
    }
    if (message.maxBin16 !== 0) {
      writer.uint32(368).uint64(message.maxBin16);
    }
    if (message.maxBin17 !== 0) {
      writer.uint32(376).uint64(message.maxBin17);
    }
    if (message.maxBin18 !== 0) {
      writer.uint32(384).uint64(message.maxBin18);
    }
    if (message.maxBin19 !== 0) {
      writer.uint32(392).uint64(message.maxBin19);
    }
    if (message.maxBin20 !== 0) {
      writer.uint32(400).uint64(message.maxBin20);
    }
    if (message.maxBin21 !== 0) {
      writer.uint32(408).uint64(message.maxBin21);
    }
    if (message.maxBin22 !== 0) {
      writer.uint32(416).uint64(message.maxBin22);
    }
    if (message.maxBin23 !== 0) {
      writer.uint32(424).uint64(message.maxBin23);
    }
    if (message.maxBin24 !== 0) {
      writer.uint32(432).uint64(message.maxBin24);
    }
    if (message.maxBin25 !== 0) {
      writer.uint32(440).uint64(message.maxBin25);
    }
    if (message.maxBin26 !== 0) {
      writer.uint32(448).uint64(message.maxBin26);
    }
    if (message.maxBin27 !== 0) {
      writer.uint32(456).uint64(message.maxBin27);
    }
    if (message.ipmParams !== undefined) {
      IpmSessionParameters.encode(message.ipmParams, writer.uint32(466).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(474).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmRawLatencyCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmRawLatencyCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.rxBin0 = longToNumber(reader.uint64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.rxBin1 = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.rxBin2 = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.rxBin3 = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.rxBin4 = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.rxBin5 = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.rxBin6 = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.rxBin7 = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.rxBin8 = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.rxBin9 = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.rxBin10 = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.rxBin11 = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.rxBin12 = longToNumber(reader.uint64() as Long);
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.rxBin13 = longToNumber(reader.uint64() as Long);
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.rxBin14 = longToNumber(reader.uint64() as Long);
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.rxBin15 = longToNumber(reader.uint64() as Long);
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.rxBin16 = longToNumber(reader.uint64() as Long);
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.rxBin17 = longToNumber(reader.uint64() as Long);
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.rxBin18 = longToNumber(reader.uint64() as Long);
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.rxBin19 = longToNumber(reader.uint64() as Long);
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.rxBin20 = longToNumber(reader.uint64() as Long);
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.rxBin21 = longToNumber(reader.uint64() as Long);
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.rxBin22 = longToNumber(reader.uint64() as Long);
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.rxBin23 = longToNumber(reader.uint64() as Long);
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.rxBin24 = longToNumber(reader.uint64() as Long);
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.rxBin25 = longToNumber(reader.uint64() as Long);
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.rxBin26 = longToNumber(reader.uint64() as Long);
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.rxBin27 = longToNumber(reader.uint64() as Long);
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.maxBin0 = longToNumber(reader.uint64() as Long);
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.maxBin1 = longToNumber(reader.uint64() as Long);
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.maxBin2 = longToNumber(reader.uint64() as Long);
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.maxBin3 = longToNumber(reader.uint64() as Long);
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.maxBin4 = longToNumber(reader.uint64() as Long);
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.maxBin5 = longToNumber(reader.uint64() as Long);
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.maxBin6 = longToNumber(reader.uint64() as Long);
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.maxBin7 = longToNumber(reader.uint64() as Long);
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.maxBin8 = longToNumber(reader.uint64() as Long);
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.maxBin9 = longToNumber(reader.uint64() as Long);
          continue;
        case 40:
          if (tag !== 320) {
            break;
          }

          message.maxBin10 = longToNumber(reader.uint64() as Long);
          continue;
        case 41:
          if (tag !== 328) {
            break;
          }

          message.maxBin11 = longToNumber(reader.uint64() as Long);
          continue;
        case 42:
          if (tag !== 336) {
            break;
          }

          message.maxBin12 = longToNumber(reader.uint64() as Long);
          continue;
        case 43:
          if (tag !== 344) {
            break;
          }

          message.maxBin13 = longToNumber(reader.uint64() as Long);
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.maxBin14 = longToNumber(reader.uint64() as Long);
          continue;
        case 45:
          if (tag !== 360) {
            break;
          }

          message.maxBin15 = longToNumber(reader.uint64() as Long);
          continue;
        case 46:
          if (tag !== 368) {
            break;
          }

          message.maxBin16 = longToNumber(reader.uint64() as Long);
          continue;
        case 47:
          if (tag !== 376) {
            break;
          }

          message.maxBin17 = longToNumber(reader.uint64() as Long);
          continue;
        case 48:
          if (tag !== 384) {
            break;
          }

          message.maxBin18 = longToNumber(reader.uint64() as Long);
          continue;
        case 49:
          if (tag !== 392) {
            break;
          }

          message.maxBin19 = longToNumber(reader.uint64() as Long);
          continue;
        case 50:
          if (tag !== 400) {
            break;
          }

          message.maxBin20 = longToNumber(reader.uint64() as Long);
          continue;
        case 51:
          if (tag !== 408) {
            break;
          }

          message.maxBin21 = longToNumber(reader.uint64() as Long);
          continue;
        case 52:
          if (tag !== 416) {
            break;
          }

          message.maxBin22 = longToNumber(reader.uint64() as Long);
          continue;
        case 53:
          if (tag !== 424) {
            break;
          }

          message.maxBin23 = longToNumber(reader.uint64() as Long);
          continue;
        case 54:
          if (tag !== 432) {
            break;
          }

          message.maxBin24 = longToNumber(reader.uint64() as Long);
          continue;
        case 55:
          if (tag !== 440) {
            break;
          }

          message.maxBin25 = longToNumber(reader.uint64() as Long);
          continue;
        case 56:
          if (tag !== 448) {
            break;
          }

          message.maxBin26 = longToNumber(reader.uint64() as Long);
          continue;
        case 57:
          if (tag !== 456) {
            break;
          }

          message.maxBin27 = longToNumber(reader.uint64() as Long);
          continue;
        case 58:
          if (tag !== 466) {
            break;
          }

          message.ipmParams = IpmSessionParameters.decode(reader, reader.uint32());
          continue;
        case 59:
          if (tag !== 474) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmRawLatencyCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      rxBin0: isSet(object.rx_bin0) ? globalThis.Number(object.rx_bin0) : 0,
      rxBin1: isSet(object.rx_bin1) ? globalThis.Number(object.rx_bin1) : 0,
      rxBin2: isSet(object.rx_bin2) ? globalThis.Number(object.rx_bin2) : 0,
      rxBin3: isSet(object.rx_bin3) ? globalThis.Number(object.rx_bin3) : 0,
      rxBin4: isSet(object.rx_bin4) ? globalThis.Number(object.rx_bin4) : 0,
      rxBin5: isSet(object.rx_bin5) ? globalThis.Number(object.rx_bin5) : 0,
      rxBin6: isSet(object.rx_bin6) ? globalThis.Number(object.rx_bin6) : 0,
      rxBin7: isSet(object.rx_bin7) ? globalThis.Number(object.rx_bin7) : 0,
      rxBin8: isSet(object.rx_bin8) ? globalThis.Number(object.rx_bin8) : 0,
      rxBin9: isSet(object.rx_bin9) ? globalThis.Number(object.rx_bin9) : 0,
      rxBin10: isSet(object.rx_bin10) ? globalThis.Number(object.rx_bin10) : 0,
      rxBin11: isSet(object.rx_bin11) ? globalThis.Number(object.rx_bin11) : 0,
      rxBin12: isSet(object.rx_bin12) ? globalThis.Number(object.rx_bin12) : 0,
      rxBin13: isSet(object.rx_bin13) ? globalThis.Number(object.rx_bin13) : 0,
      rxBin14: isSet(object.rx_bin14) ? globalThis.Number(object.rx_bin14) : 0,
      rxBin15: isSet(object.rx_bin15) ? globalThis.Number(object.rx_bin15) : 0,
      rxBin16: isSet(object.rx_bin16) ? globalThis.Number(object.rx_bin16) : 0,
      rxBin17: isSet(object.rx_bin17) ? globalThis.Number(object.rx_bin17) : 0,
      rxBin18: isSet(object.rx_bin18) ? globalThis.Number(object.rx_bin18) : 0,
      rxBin19: isSet(object.rx_bin19) ? globalThis.Number(object.rx_bin19) : 0,
      rxBin20: isSet(object.rx_bin20) ? globalThis.Number(object.rx_bin20) : 0,
      rxBin21: isSet(object.rx_bin21) ? globalThis.Number(object.rx_bin21) : 0,
      rxBin22: isSet(object.rx_bin22) ? globalThis.Number(object.rx_bin22) : 0,
      rxBin23: isSet(object.rx_bin23) ? globalThis.Number(object.rx_bin23) : 0,
      rxBin24: isSet(object.rx_bin24) ? globalThis.Number(object.rx_bin24) : 0,
      rxBin25: isSet(object.rx_bin25) ? globalThis.Number(object.rx_bin25) : 0,
      rxBin26: isSet(object.rx_bin26) ? globalThis.Number(object.rx_bin26) : 0,
      rxBin27: isSet(object.rx_bin27) ? globalThis.Number(object.rx_bin27) : 0,
      maxBin0: isSet(object.max_bin0) ? globalThis.Number(object.max_bin0) : 0,
      maxBin1: isSet(object.max_bin1) ? globalThis.Number(object.max_bin1) : 0,
      maxBin2: isSet(object.max_bin2) ? globalThis.Number(object.max_bin2) : 0,
      maxBin3: isSet(object.max_bin3) ? globalThis.Number(object.max_bin3) : 0,
      maxBin4: isSet(object.max_bin4) ? globalThis.Number(object.max_bin4) : 0,
      maxBin5: isSet(object.max_bin5) ? globalThis.Number(object.max_bin5) : 0,
      maxBin6: isSet(object.max_bin6) ? globalThis.Number(object.max_bin6) : 0,
      maxBin7: isSet(object.max_bin7) ? globalThis.Number(object.max_bin7) : 0,
      maxBin8: isSet(object.max_bin8) ? globalThis.Number(object.max_bin8) : 0,
      maxBin9: isSet(object.max_bin9) ? globalThis.Number(object.max_bin9) : 0,
      maxBin10: isSet(object.max_bin10) ? globalThis.Number(object.max_bin10) : 0,
      maxBin11: isSet(object.max_bin11) ? globalThis.Number(object.max_bin11) : 0,
      maxBin12: isSet(object.max_bin12) ? globalThis.Number(object.max_bin12) : 0,
      maxBin13: isSet(object.max_bin13) ? globalThis.Number(object.max_bin13) : 0,
      maxBin14: isSet(object.max_bin14) ? globalThis.Number(object.max_bin14) : 0,
      maxBin15: isSet(object.max_bin15) ? globalThis.Number(object.max_bin15) : 0,
      maxBin16: isSet(object.max_bin16) ? globalThis.Number(object.max_bin16) : 0,
      maxBin17: isSet(object.max_bin17) ? globalThis.Number(object.max_bin17) : 0,
      maxBin18: isSet(object.max_bin18) ? globalThis.Number(object.max_bin18) : 0,
      maxBin19: isSet(object.max_bin19) ? globalThis.Number(object.max_bin19) : 0,
      maxBin20: isSet(object.max_bin20) ? globalThis.Number(object.max_bin20) : 0,
      maxBin21: isSet(object.max_bin21) ? globalThis.Number(object.max_bin21) : 0,
      maxBin22: isSet(object.max_bin22) ? globalThis.Number(object.max_bin22) : 0,
      maxBin23: isSet(object.max_bin23) ? globalThis.Number(object.max_bin23) : 0,
      maxBin24: isSet(object.max_bin24) ? globalThis.Number(object.max_bin24) : 0,
      maxBin25: isSet(object.max_bin25) ? globalThis.Number(object.max_bin25) : 0,
      maxBin26: isSet(object.max_bin26) ? globalThis.Number(object.max_bin26) : 0,
      maxBin27: isSet(object.max_bin27) ? globalThis.Number(object.max_bin27) : 0,
      ipmParams: isSet(object.ipmParams) ? IpmSessionParameters.fromJSON(object.ipmParams) : undefined,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: IpmRawLatencyCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.rxBin0 !== 0) {
      obj.rx_bin0 = Math.round(message.rxBin0);
    }
    if (message.rxBin1 !== 0) {
      obj.rx_bin1 = Math.round(message.rxBin1);
    }
    if (message.rxBin2 !== 0) {
      obj.rx_bin2 = Math.round(message.rxBin2);
    }
    if (message.rxBin3 !== 0) {
      obj.rx_bin3 = Math.round(message.rxBin3);
    }
    if (message.rxBin4 !== 0) {
      obj.rx_bin4 = Math.round(message.rxBin4);
    }
    if (message.rxBin5 !== 0) {
      obj.rx_bin5 = Math.round(message.rxBin5);
    }
    if (message.rxBin6 !== 0) {
      obj.rx_bin6 = Math.round(message.rxBin6);
    }
    if (message.rxBin7 !== 0) {
      obj.rx_bin7 = Math.round(message.rxBin7);
    }
    if (message.rxBin8 !== 0) {
      obj.rx_bin8 = Math.round(message.rxBin8);
    }
    if (message.rxBin9 !== 0) {
      obj.rx_bin9 = Math.round(message.rxBin9);
    }
    if (message.rxBin10 !== 0) {
      obj.rx_bin10 = Math.round(message.rxBin10);
    }
    if (message.rxBin11 !== 0) {
      obj.rx_bin11 = Math.round(message.rxBin11);
    }
    if (message.rxBin12 !== 0) {
      obj.rx_bin12 = Math.round(message.rxBin12);
    }
    if (message.rxBin13 !== 0) {
      obj.rx_bin13 = Math.round(message.rxBin13);
    }
    if (message.rxBin14 !== 0) {
      obj.rx_bin14 = Math.round(message.rxBin14);
    }
    if (message.rxBin15 !== 0) {
      obj.rx_bin15 = Math.round(message.rxBin15);
    }
    if (message.rxBin16 !== 0) {
      obj.rx_bin16 = Math.round(message.rxBin16);
    }
    if (message.rxBin17 !== 0) {
      obj.rx_bin17 = Math.round(message.rxBin17);
    }
    if (message.rxBin18 !== 0) {
      obj.rx_bin18 = Math.round(message.rxBin18);
    }
    if (message.rxBin19 !== 0) {
      obj.rx_bin19 = Math.round(message.rxBin19);
    }
    if (message.rxBin20 !== 0) {
      obj.rx_bin20 = Math.round(message.rxBin20);
    }
    if (message.rxBin21 !== 0) {
      obj.rx_bin21 = Math.round(message.rxBin21);
    }
    if (message.rxBin22 !== 0) {
      obj.rx_bin22 = Math.round(message.rxBin22);
    }
    if (message.rxBin23 !== 0) {
      obj.rx_bin23 = Math.round(message.rxBin23);
    }
    if (message.rxBin24 !== 0) {
      obj.rx_bin24 = Math.round(message.rxBin24);
    }
    if (message.rxBin25 !== 0) {
      obj.rx_bin25 = Math.round(message.rxBin25);
    }
    if (message.rxBin26 !== 0) {
      obj.rx_bin26 = Math.round(message.rxBin26);
    }
    if (message.rxBin27 !== 0) {
      obj.rx_bin27 = Math.round(message.rxBin27);
    }
    if (message.maxBin0 !== 0) {
      obj.max_bin0 = Math.round(message.maxBin0);
    }
    if (message.maxBin1 !== 0) {
      obj.max_bin1 = Math.round(message.maxBin1);
    }
    if (message.maxBin2 !== 0) {
      obj.max_bin2 = Math.round(message.maxBin2);
    }
    if (message.maxBin3 !== 0) {
      obj.max_bin3 = Math.round(message.maxBin3);
    }
    if (message.maxBin4 !== 0) {
      obj.max_bin4 = Math.round(message.maxBin4);
    }
    if (message.maxBin5 !== 0) {
      obj.max_bin5 = Math.round(message.maxBin5);
    }
    if (message.maxBin6 !== 0) {
      obj.max_bin6 = Math.round(message.maxBin6);
    }
    if (message.maxBin7 !== 0) {
      obj.max_bin7 = Math.round(message.maxBin7);
    }
    if (message.maxBin8 !== 0) {
      obj.max_bin8 = Math.round(message.maxBin8);
    }
    if (message.maxBin9 !== 0) {
      obj.max_bin9 = Math.round(message.maxBin9);
    }
    if (message.maxBin10 !== 0) {
      obj.max_bin10 = Math.round(message.maxBin10);
    }
    if (message.maxBin11 !== 0) {
      obj.max_bin11 = Math.round(message.maxBin11);
    }
    if (message.maxBin12 !== 0) {
      obj.max_bin12 = Math.round(message.maxBin12);
    }
    if (message.maxBin13 !== 0) {
      obj.max_bin13 = Math.round(message.maxBin13);
    }
    if (message.maxBin14 !== 0) {
      obj.max_bin14 = Math.round(message.maxBin14);
    }
    if (message.maxBin15 !== 0) {
      obj.max_bin15 = Math.round(message.maxBin15);
    }
    if (message.maxBin16 !== 0) {
      obj.max_bin16 = Math.round(message.maxBin16);
    }
    if (message.maxBin17 !== 0) {
      obj.max_bin17 = Math.round(message.maxBin17);
    }
    if (message.maxBin18 !== 0) {
      obj.max_bin18 = Math.round(message.maxBin18);
    }
    if (message.maxBin19 !== 0) {
      obj.max_bin19 = Math.round(message.maxBin19);
    }
    if (message.maxBin20 !== 0) {
      obj.max_bin20 = Math.round(message.maxBin20);
    }
    if (message.maxBin21 !== 0) {
      obj.max_bin21 = Math.round(message.maxBin21);
    }
    if (message.maxBin22 !== 0) {
      obj.max_bin22 = Math.round(message.maxBin22);
    }
    if (message.maxBin23 !== 0) {
      obj.max_bin23 = Math.round(message.maxBin23);
    }
    if (message.maxBin24 !== 0) {
      obj.max_bin24 = Math.round(message.maxBin24);
    }
    if (message.maxBin25 !== 0) {
      obj.max_bin25 = Math.round(message.maxBin25);
    }
    if (message.maxBin26 !== 0) {
      obj.max_bin26 = Math.round(message.maxBin26);
    }
    if (message.maxBin27 !== 0) {
      obj.max_bin27 = Math.round(message.maxBin27);
    }
    if (message.ipmParams !== undefined) {
      obj.ipmParams = IpmSessionParameters.toJSON(message.ipmParams);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmRawLatencyCounters>, I>>(base?: I): IpmRawLatencyCounters {
    return IpmRawLatencyCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmRawLatencyCounters>, I>>(object: I): IpmRawLatencyCounters {
    const message = createBaseIpmRawLatencyCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.rxBin0 = object.rxBin0 ?? 0;
    message.rxBin1 = object.rxBin1 ?? 0;
    message.rxBin2 = object.rxBin2 ?? 0;
    message.rxBin3 = object.rxBin3 ?? 0;
    message.rxBin4 = object.rxBin4 ?? 0;
    message.rxBin5 = object.rxBin5 ?? 0;
    message.rxBin6 = object.rxBin6 ?? 0;
    message.rxBin7 = object.rxBin7 ?? 0;
    message.rxBin8 = object.rxBin8 ?? 0;
    message.rxBin9 = object.rxBin9 ?? 0;
    message.rxBin10 = object.rxBin10 ?? 0;
    message.rxBin11 = object.rxBin11 ?? 0;
    message.rxBin12 = object.rxBin12 ?? 0;
    message.rxBin13 = object.rxBin13 ?? 0;
    message.rxBin14 = object.rxBin14 ?? 0;
    message.rxBin15 = object.rxBin15 ?? 0;
    message.rxBin16 = object.rxBin16 ?? 0;
    message.rxBin17 = object.rxBin17 ?? 0;
    message.rxBin18 = object.rxBin18 ?? 0;
    message.rxBin19 = object.rxBin19 ?? 0;
    message.rxBin20 = object.rxBin20 ?? 0;
    message.rxBin21 = object.rxBin21 ?? 0;
    message.rxBin22 = object.rxBin22 ?? 0;
    message.rxBin23 = object.rxBin23 ?? 0;
    message.rxBin24 = object.rxBin24 ?? 0;
    message.rxBin25 = object.rxBin25 ?? 0;
    message.rxBin26 = object.rxBin26 ?? 0;
    message.rxBin27 = object.rxBin27 ?? 0;
    message.maxBin0 = object.maxBin0 ?? 0;
    message.maxBin1 = object.maxBin1 ?? 0;
    message.maxBin2 = object.maxBin2 ?? 0;
    message.maxBin3 = object.maxBin3 ?? 0;
    message.maxBin4 = object.maxBin4 ?? 0;
    message.maxBin5 = object.maxBin5 ?? 0;
    message.maxBin6 = object.maxBin6 ?? 0;
    message.maxBin7 = object.maxBin7 ?? 0;
    message.maxBin8 = object.maxBin8 ?? 0;
    message.maxBin9 = object.maxBin9 ?? 0;
    message.maxBin10 = object.maxBin10 ?? 0;
    message.maxBin11 = object.maxBin11 ?? 0;
    message.maxBin12 = object.maxBin12 ?? 0;
    message.maxBin13 = object.maxBin13 ?? 0;
    message.maxBin14 = object.maxBin14 ?? 0;
    message.maxBin15 = object.maxBin15 ?? 0;
    message.maxBin16 = object.maxBin16 ?? 0;
    message.maxBin17 = object.maxBin17 ?? 0;
    message.maxBin18 = object.maxBin18 ?? 0;
    message.maxBin19 = object.maxBin19 ?? 0;
    message.maxBin20 = object.maxBin20 ?? 0;
    message.maxBin21 = object.maxBin21 ?? 0;
    message.maxBin22 = object.maxBin22 ?? 0;
    message.maxBin23 = object.maxBin23 ?? 0;
    message.maxBin24 = object.maxBin24 ?? 0;
    message.maxBin25 = object.maxBin25 ?? 0;
    message.maxBin26 = object.maxBin26 ?? 0;
    message.maxBin27 = object.maxBin27 ?? 0;
    message.ipmParams = (object.ipmParams !== undefined && object.ipmParams !== null)
      ? IpmSessionParameters.fromPartial(object.ipmParams)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseIpmProcLatencyCounters(): IpmProcLatencyCounters {
  return { id: undefined, exceedingSuspicious: 0, exceedingCritical: 0, ipmParams: undefined, collectedAt: undefined };
}

export const IpmProcLatencyCounters = {
  encode(message: IpmProcLatencyCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.exceedingSuspicious !== 0) {
      writer.uint32(21).float(message.exceedingSuspicious);
    }
    if (message.exceedingCritical !== 0) {
      writer.uint32(29).float(message.exceedingCritical);
    }
    if (message.ipmParams !== undefined) {
      IpmSessionParameters.encode(message.ipmParams, writer.uint32(34).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmProcLatencyCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmProcLatencyCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.exceedingSuspicious = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.exceedingCritical = reader.float();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipmParams = IpmSessionParameters.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmProcLatencyCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      exceedingSuspicious: isSet(object.exceeding_suspicious) ? globalThis.Number(object.exceeding_suspicious) : 0,
      exceedingCritical: isSet(object.exceeding_critical) ? globalThis.Number(object.exceeding_critical) : 0,
      ipmParams: isSet(object.ipmParams) ? IpmSessionParameters.fromJSON(object.ipmParams) : undefined,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: IpmProcLatencyCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.exceedingSuspicious !== 0) {
      obj.exceeding_suspicious = message.exceedingSuspicious;
    }
    if (message.exceedingCritical !== 0) {
      obj.exceeding_critical = message.exceedingCritical;
    }
    if (message.ipmParams !== undefined) {
      obj.ipmParams = IpmSessionParameters.toJSON(message.ipmParams);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmProcLatencyCounters>, I>>(base?: I): IpmProcLatencyCounters {
    return IpmProcLatencyCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmProcLatencyCounters>, I>>(object: I): IpmProcLatencyCounters {
    const message = createBaseIpmProcLatencyCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.exceedingSuspicious = object.exceedingSuspicious ?? 0;
    message.exceedingCritical = object.exceedingCritical ?? 0;
    message.ipmParams = (object.ipmParams !== undefined && object.ipmParams !== null)
      ? IpmSessionParameters.fromPartial(object.ipmParams)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseIpmProcLossCounters(): IpmProcLossCounters {
  return {
    id: undefined,
    transmittedPackets: 0,
    receivedPackets: 0,
    loss: 0,
    pLoss: 0,
    ipmParams: undefined,
    collectedAt: undefined,
  };
}

export const IpmProcLossCounters = {
  encode(message: IpmProcLossCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.transmittedPackets !== 0) {
      writer.uint32(24).uint64(message.transmittedPackets);
    }
    if (message.receivedPackets !== 0) {
      writer.uint32(32).uint64(message.receivedPackets);
    }
    if (message.loss !== 0) {
      writer.uint32(40).uint64(message.loss);
    }
    if (message.pLoss !== 0) {
      writer.uint32(53).float(message.pLoss);
    }
    if (message.ipmParams !== undefined) {
      IpmSessionParameters.encode(message.ipmParams, writer.uint32(58).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmProcLossCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmProcLossCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.transmittedPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.receivedPackets = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.loss = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.pLoss = reader.float();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipmParams = IpmSessionParameters.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmProcLossCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      transmittedPackets: isSet(object.transmitted_packets) ? globalThis.Number(object.transmitted_packets) : 0,
      receivedPackets: isSet(object.received_packets) ? globalThis.Number(object.received_packets) : 0,
      loss: isSet(object.loss) ? globalThis.Number(object.loss) : 0,
      pLoss: isSet(object.p_loss) ? globalThis.Number(object.p_loss) : 0,
      ipmParams: isSet(object.ipmParams) ? IpmSessionParameters.fromJSON(object.ipmParams) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: IpmProcLossCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.transmittedPackets !== 0) {
      obj.transmitted_packets = Math.round(message.transmittedPackets);
    }
    if (message.receivedPackets !== 0) {
      obj.received_packets = Math.round(message.receivedPackets);
    }
    if (message.loss !== 0) {
      obj.loss = Math.round(message.loss);
    }
    if (message.pLoss !== 0) {
      obj.p_loss = message.pLoss;
    }
    if (message.ipmParams !== undefined) {
      obj.ipmParams = IpmSessionParameters.toJSON(message.ipmParams);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmProcLossCounters>, I>>(base?: I): IpmProcLossCounters {
    return IpmProcLossCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmProcLossCounters>, I>>(object: I): IpmProcLossCounters {
    const message = createBaseIpmProcLossCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.transmittedPackets = object.transmittedPackets ?? 0;
    message.receivedPackets = object.receivedPackets ?? 0;
    message.loss = object.loss ?? 0;
    message.pLoss = object.pLoss ?? 0;
    message.ipmParams = (object.ipmParams !== undefined && object.ipmParams !== null)
      ? IpmSessionParameters.fromPartial(object.ipmParams)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseIpmLivenessCounters(): IpmLivenessCounters {
  return { id: undefined, state: 0, livenessTimer: 0, ipmParams: undefined, collectedAt: undefined };
}

export const IpmLivenessCounters = {
  encode(message: IpmLivenessCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.livenessTimer !== 0) {
      writer.uint32(24).uint32(message.livenessTimer);
    }
    if (message.ipmParams !== undefined) {
      IpmSessionParameters.encode(message.ipmParams, writer.uint32(34).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpmLivenessCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpmLivenessCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.livenessTimer = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipmParams = IpmSessionParameters.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpmLivenessCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      state: isSet(object.state) ? livenessStatusFromJSON(object.state) : 0,
      livenessTimer: isSet(object.liveness_timer) ? globalThis.Number(object.liveness_timer) : 0,
      ipmParams: isSet(object.ipmParams) ? IpmSessionParameters.fromJSON(object.ipmParams) : undefined,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: IpmLivenessCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.state !== 0) {
      obj.state = livenessStatusToJSON(message.state);
    }
    if (message.livenessTimer !== 0) {
      obj.liveness_timer = Math.round(message.livenessTimer);
    }
    if (message.ipmParams !== undefined) {
      obj.ipmParams = IpmSessionParameters.toJSON(message.ipmParams);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpmLivenessCounters>, I>>(base?: I): IpmLivenessCounters {
    return IpmLivenessCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpmLivenessCounters>, I>>(object: I): IpmLivenessCounters {
    const message = createBaseIpmLivenessCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.state = object.state ?? 0;
    message.livenessTimer = object.livenessTimer ?? 0;
    message.ipmParams = (object.ipmParams !== undefined && object.ipmParams !== null)
      ? IpmSessionParameters.fromPartial(object.ipmParams)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseVlanCounters(): VlanCounters {
  return {
    id: undefined,
    bytesIn: 0,
    bytesOut: 0,
    pktsIn: 0,
    pktsOut: 0,
    errIn: 0,
    errOut: 0,
    errPktsIn: 0,
    errPktsOut: 0,
    collectedAt: undefined,
  };
}

export const VlanCounters = {
  encode(message: VlanCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.bytesIn !== 0) {
      writer.uint32(24).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(32).uint64(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      writer.uint32(40).uint64(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      writer.uint32(48).uint64(message.pktsOut);
    }
    if (message.errIn !== 0) {
      writer.uint32(56).uint64(message.errIn);
    }
    if (message.errOut !== 0) {
      writer.uint32(64).uint64(message.errOut);
    }
    if (message.errPktsIn !== 0) {
      writer.uint32(72).uint64(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      writer.uint32(80).uint64(message.errPktsOut);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.errIn = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.errOut = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.errPktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.errPktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      pktsIn: isSet(object.pktsIn) ? globalThis.Number(object.pktsIn) : 0,
      pktsOut: isSet(object.pktsOut) ? globalThis.Number(object.pktsOut) : 0,
      errIn: isSet(object.errIn) ? globalThis.Number(object.errIn) : 0,
      errOut: isSet(object.errOut) ? globalThis.Number(object.errOut) : 0,
      errPktsIn: isSet(object.errPktsIn) ? globalThis.Number(object.errPktsIn) : 0,
      errPktsOut: isSet(object.errPktsOut) ? globalThis.Number(object.errPktsOut) : 0,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: VlanCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      obj.pktsIn = Math.round(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      obj.pktsOut = Math.round(message.pktsOut);
    }
    if (message.errIn !== 0) {
      obj.errIn = Math.round(message.errIn);
    }
    if (message.errOut !== 0) {
      obj.errOut = Math.round(message.errOut);
    }
    if (message.errPktsIn !== 0) {
      obj.errPktsIn = Math.round(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      obj.errPktsOut = Math.round(message.errPktsOut);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanCounters>, I>>(base?: I): VlanCounters {
    return VlanCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanCounters>, I>>(object: I): VlanCounters {
    const message = createBaseVlanCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.pktsIn = object.pktsIn ?? 0;
    message.pktsOut = object.pktsOut ?? 0;
    message.errIn = object.errIn ?? 0;
    message.errOut = object.errOut ?? 0;
    message.errPktsIn = object.errPktsIn ?? 0;
    message.errPktsOut = object.errPktsOut ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseRoutedInterfaceCounters(): RoutedInterfaceCounters {
  return {
    id: undefined,
    bytesIn: 0,
    bytesOut: 0,
    pktsIn: 0,
    pktsOut: 0,
    errIn: 0,
    errOut: 0,
    errPktsIn: 0,
    errPktsOut: 0,
    collectedAt: undefined,
  };
}

export const RoutedInterfaceCounters = {
  encode(message: RoutedInterfaceCounters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.bytesIn !== 0) {
      writer.uint32(24).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(32).uint64(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      writer.uint32(40).uint64(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      writer.uint32(48).uint64(message.pktsOut);
    }
    if (message.errIn !== 0) {
      writer.uint32(56).uint64(message.errIn);
    }
    if (message.errOut !== 0) {
      writer.uint32(64).uint64(message.errOut);
    }
    if (message.errPktsIn !== 0) {
      writer.uint32(72).uint64(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      writer.uint32(80).uint64(message.errPktsOut);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RoutedInterfaceCounters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRoutedInterfaceCounters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.errIn = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.errOut = longToNumber(reader.uint64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.errPktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.errPktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RoutedInterfaceCounters {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      pktsIn: isSet(object.pktsIn) ? globalThis.Number(object.pktsIn) : 0,
      pktsOut: isSet(object.pktsOut) ? globalThis.Number(object.pktsOut) : 0,
      errIn: isSet(object.errIn) ? globalThis.Number(object.errIn) : 0,
      errOut: isSet(object.errOut) ? globalThis.Number(object.errOut) : 0,
      errPktsIn: isSet(object.errPktsIn) ? globalThis.Number(object.errPktsIn) : 0,
      errPktsOut: isSet(object.errPktsOut) ? globalThis.Number(object.errPktsOut) : 0,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: RoutedInterfaceCounters): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      obj.pktsIn = Math.round(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      obj.pktsOut = Math.round(message.pktsOut);
    }
    if (message.errIn !== 0) {
      obj.errIn = Math.round(message.errIn);
    }
    if (message.errOut !== 0) {
      obj.errOut = Math.round(message.errOut);
    }
    if (message.errPktsIn !== 0) {
      obj.errPktsIn = Math.round(message.errPktsIn);
    }
    if (message.errPktsOut !== 0) {
      obj.errPktsOut = Math.round(message.errPktsOut);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RoutedInterfaceCounters>, I>>(base?: I): RoutedInterfaceCounters {
    return RoutedInterfaceCounters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RoutedInterfaceCounters>, I>>(object: I): RoutedInterfaceCounters {
    const message = createBaseRoutedInterfaceCounters();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.pktsIn = object.pktsIn ?? 0;
    message.pktsOut = object.pktsOut ?? 0;
    message.errIn = object.errIn ?? 0;
    message.errOut = object.errOut ?? 0;
    message.errPktsIn = object.errPktsIn ?? 0;
    message.errPktsOut = object.errPktsOut ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseL2Fdb(): L2Fdb {
  return { id: undefined, l2entries: [] };
}

export const L2Fdb = {
  encode(message: L2Fdb, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.l2entries) {
      L2FdbEntry.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): L2Fdb {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseL2Fdb();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.l2entries.push(L2FdbEntry.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): L2Fdb {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      l2entries: globalThis.Array.isArray(object?.l2entries)
        ? object.l2entries.map((e: any) => L2FdbEntry.fromJSON(e))
        : [],
    };
  },

  toJSON(message: L2Fdb): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.l2entries?.length) {
      obj.l2entries = message.l2entries.map((e) => L2FdbEntry.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<L2Fdb>, I>>(base?: I): L2Fdb {
    return L2Fdb.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<L2Fdb>, I>>(object: I): L2Fdb {
    const message = createBaseL2Fdb();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.l2entries = object.l2entries?.map((e) => L2FdbEntry.fromPartial(e)) || [];
    return message;
  },
};

function createBaseL2FdbEntry(): L2FdbEntry {
  return { macAddress: "", vlanId: 0, portRef: "", staticEntry: false, vni: 0 };
}

export const L2FdbEntry = {
  encode(message: L2FdbEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.macAddress !== "") {
      writer.uint32(18).string(message.macAddress);
    }
    if (message.vlanId !== 0) {
      writer.uint32(24).uint32(message.vlanId);
    }
    if (message.portRef !== "") {
      writer.uint32(34).string(message.portRef);
    }
    if (message.staticEntry !== false) {
      writer.uint32(40).bool(message.staticEntry);
    }
    if (message.vni !== 0) {
      writer.uint32(48).uint32(message.vni);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): L2FdbEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseL2FdbEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.portRef = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.staticEntry = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.vni = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): L2FdbEntry {
    return {
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      portRef: isSet(object.portRef) ? globalThis.String(object.portRef) : "",
      staticEntry: isSet(object.staticEntry) ? globalThis.Boolean(object.staticEntry) : false,
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
    };
  },

  toJSON(message: L2FdbEntry): unknown {
    const obj: any = {};
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.portRef !== "") {
      obj.portRef = message.portRef;
    }
    if (message.staticEntry !== false) {
      obj.staticEntry = message.staticEntry;
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<L2FdbEntry>, I>>(base?: I): L2FdbEntry {
    return L2FdbEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<L2FdbEntry>, I>>(object: I): L2FdbEntry {
    const message = createBaseL2FdbEntry();
    message.macAddress = object.macAddress ?? "";
    message.vlanId = object.vlanId ?? 0;
    message.portRef = object.portRef ?? "";
    message.staticEntry = object.staticEntry ?? false;
    message.vni = object.vni ?? 0;
    return message;
  },
};

function createBaseSpyglassSshSessionEntry(): SpyglassSshSessionEntry {
  return { id: undefined, tcpPort: 0, deviceId: "" };
}

export const SpyglassSshSessionEntry = {
  encode(message: SpyglassSshSessionEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.tcpPort !== 0) {
      writer.uint32(24).uint32(message.tcpPort);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassSshSessionEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassSshSessionEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.tcpPort = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassSshSessionEntry {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      tcpPort: isSet(object.tcpPort) ? globalThis.Number(object.tcpPort) : 0,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: SpyglassSshSessionEntry): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.tcpPort !== 0) {
      obj.tcpPort = Math.round(message.tcpPort);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassSshSessionEntry>, I>>(base?: I): SpyglassSshSessionEntry {
    return SpyglassSshSessionEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassSshSessionEntry>, I>>(object: I): SpyglassSshSessionEntry {
    const message = createBaseSpyglassSshSessionEntry();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.tcpPort = object.tcpPort ?? 0;
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseSpyglassBrokerEntry(): SpyglassBrokerEntry {
  return {
    id: undefined,
    deviceId: "",
    connectedInternal: false,
    connectedExternal: false,
    pktsIntToExt: 0,
    pktsExtToInt: 0,
    internalConnectionCreated: undefined,
    externalConnectionCreated: undefined,
  };
}

export const SpyglassBrokerEntry = {
  encode(message: SpyglassBrokerEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    if (message.connectedInternal !== false) {
      writer.uint32(32).bool(message.connectedInternal);
    }
    if (message.connectedExternal !== false) {
      writer.uint32(40).bool(message.connectedExternal);
    }
    if (message.pktsIntToExt !== 0) {
      writer.uint32(48).uint64(message.pktsIntToExt);
    }
    if (message.pktsExtToInt !== 0) {
      writer.uint32(56).uint64(message.pktsExtToInt);
    }
    if (message.internalConnectionCreated !== undefined) {
      Timestamp.encode(toTimestamp(message.internalConnectionCreated), writer.uint32(66).fork()).ldelim();
    }
    if (message.externalConnectionCreated !== undefined) {
      Timestamp.encode(toTimestamp(message.externalConnectionCreated), writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassBrokerEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassBrokerEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.connectedInternal = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.connectedExternal = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pktsIntToExt = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.pktsExtToInt = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.internalConnectionCreated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.externalConnectionCreated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassBrokerEntry {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      connectedInternal: isSet(object.connectedInternal) ? globalThis.Boolean(object.connectedInternal) : false,
      connectedExternal: isSet(object.connectedExternal) ? globalThis.Boolean(object.connectedExternal) : false,
      pktsIntToExt: isSet(object.pktsIntToExt) ? globalThis.Number(object.pktsIntToExt) : 0,
      pktsExtToInt: isSet(object.pktsExtToInt) ? globalThis.Number(object.pktsExtToInt) : 0,
      internalConnectionCreated: isSet(object.internalConnectionCreated)
        ? fromJsonTimestamp(object.internalConnectionCreated)
        : undefined,
      externalConnectionCreated: isSet(object.externalConnectionCreated)
        ? fromJsonTimestamp(object.externalConnectionCreated)
        : undefined,
    };
  },

  toJSON(message: SpyglassBrokerEntry): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.connectedInternal !== false) {
      obj.connectedInternal = message.connectedInternal;
    }
    if (message.connectedExternal !== false) {
      obj.connectedExternal = message.connectedExternal;
    }
    if (message.pktsIntToExt !== 0) {
      obj.pktsIntToExt = Math.round(message.pktsIntToExt);
    }
    if (message.pktsExtToInt !== 0) {
      obj.pktsExtToInt = Math.round(message.pktsExtToInt);
    }
    if (message.internalConnectionCreated !== undefined) {
      obj.internalConnectionCreated = message.internalConnectionCreated.toISOString();
    }
    if (message.externalConnectionCreated !== undefined) {
      obj.externalConnectionCreated = message.externalConnectionCreated.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassBrokerEntry>, I>>(base?: I): SpyglassBrokerEntry {
    return SpyglassBrokerEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassBrokerEntry>, I>>(object: I): SpyglassBrokerEntry {
    const message = createBaseSpyglassBrokerEntry();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.deviceId = object.deviceId ?? "";
    message.connectedInternal = object.connectedInternal ?? false;
    message.connectedExternal = object.connectedExternal ?? false;
    message.pktsIntToExt = object.pktsIntToExt ?? 0;
    message.pktsExtToInt = object.pktsExtToInt ?? 0;
    message.internalConnectionCreated = object.internalConnectionCreated ?? undefined;
    message.externalConnectionCreated = object.externalConnectionCreated ?? undefined;
    return message;
  },
};

function createBaseTestPlaceHolder1Secret(): TestPlaceHolder1Secret {
  return { id: undefined };
}

export const TestPlaceHolder1Secret = {
  encode(message: TestPlaceHolder1Secret, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TestPlaceHolder1Secret {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestPlaceHolder1Secret();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestPlaceHolder1Secret {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TestPlaceHolder1Secret): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TestPlaceHolder1Secret>, I>>(base?: I): TestPlaceHolder1Secret {
    return TestPlaceHolder1Secret.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TestPlaceHolder1Secret>, I>>(object: I): TestPlaceHolder1Secret {
    const message = createBaseTestPlaceHolder1Secret();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseSpyglassDevice(): SpyglassDevice {
  return { id: undefined, connectedAt: undefined, listenerCount: 0 };
}

export const SpyglassDevice = {
  encode(message: SpyglassDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.connectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.connectedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.listenerCount !== 0) {
      writer.uint32(32).uint32(message.listenerCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.connectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.listenerCount = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassDevice {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      connectedAt: isSet(object.connectedAt) ? fromJsonTimestamp(object.connectedAt) : undefined,
      listenerCount: isSet(object.listenerCount) ? globalThis.Number(object.listenerCount) : 0,
    };
  },

  toJSON(message: SpyglassDevice): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.connectedAt !== undefined) {
      obj.connectedAt = message.connectedAt.toISOString();
    }
    if (message.listenerCount !== 0) {
      obj.listenerCount = Math.round(message.listenerCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassDevice>, I>>(base?: I): SpyglassDevice {
    return SpyglassDevice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassDevice>, I>>(object: I): SpyglassDevice {
    const message = createBaseSpyglassDevice();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.connectedAt = object.connectedAt ?? undefined;
    message.listenerCount = object.listenerCount ?? 0;
    return message;
  },
};

function createBaseSpyglassTcpListener(): SpyglassTcpListener {
  return {
    id: undefined,
    createdAt: undefined,
    currentSessionCount: 0,
    totalSessionCount: 0,
    maxConcurrentSessions: 0,
  };
}

export const SpyglassTcpListener = {
  encode(message: SpyglassTcpListener, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.currentSessionCount !== 0) {
      writer.uint32(32).uint32(message.currentSessionCount);
    }
    if (message.totalSessionCount !== 0) {
      writer.uint32(40).uint32(message.totalSessionCount);
    }
    if (message.maxConcurrentSessions !== 0) {
      writer.uint32(48).uint32(message.maxConcurrentSessions);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassTcpListener {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassTcpListener();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.currentSessionCount = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.totalSessionCount = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxConcurrentSessions = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassTcpListener {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      currentSessionCount: isSet(object.currentSessionCount) ? globalThis.Number(object.currentSessionCount) : 0,
      totalSessionCount: isSet(object.totalSessionCount) ? globalThis.Number(object.totalSessionCount) : 0,
      maxConcurrentSessions: isSet(object.maxConcurrentSessions) ? globalThis.Number(object.maxConcurrentSessions) : 0,
    };
  },

  toJSON(message: SpyglassTcpListener): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.currentSessionCount !== 0) {
      obj.currentSessionCount = Math.round(message.currentSessionCount);
    }
    if (message.totalSessionCount !== 0) {
      obj.totalSessionCount = Math.round(message.totalSessionCount);
    }
    if (message.maxConcurrentSessions !== 0) {
      obj.maxConcurrentSessions = Math.round(message.maxConcurrentSessions);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassTcpListener>, I>>(base?: I): SpyglassTcpListener {
    return SpyglassTcpListener.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassTcpListener>, I>>(object: I): SpyglassTcpListener {
    const message = createBaseSpyglassTcpListener();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.createdAt = object.createdAt ?? undefined;
    message.currentSessionCount = object.currentSessionCount ?? 0;
    message.totalSessionCount = object.totalSessionCount ?? 0;
    message.maxConcurrentSessions = object.maxConcurrentSessions ?? 0;
    return message;
  },
};

function createBaseSpyglassTcpSession(): SpyglassTcpSession {
  return { id: undefined, created: undefined };
}

export const SpyglassTcpSession = {
  encode(message: SpyglassTcpSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassTcpSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassTcpSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassTcpSession {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      created: isSet(object.created) ? fromJsonTimestamp(object.created) : undefined,
    };
  },

  toJSON(message: SpyglassTcpSession): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.created !== undefined) {
      obj.created = message.created.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassTcpSession>, I>>(base?: I): SpyglassTcpSession {
    return SpyglassTcpSession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassTcpSession>, I>>(object: I): SpyglassTcpSession {
    const message = createBaseSpyglassTcpSession();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.created = object.created ?? undefined;
    return message;
  },
};

function createBaseSpyglassGingerEntry(): SpyglassGingerEntry {
  return { id: undefined, deviceId: "", tcpPort: 0, status: 0, connectionCreated: undefined };
}

export const SpyglassGingerEntry = {
  encode(message: SpyglassGingerEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    if (message.tcpPort !== 0) {
      writer.uint32(32).uint32(message.tcpPort);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.connectionCreated !== undefined) {
      Timestamp.encode(toTimestamp(message.connectionCreated), writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpyglassGingerEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpyglassGingerEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.tcpPort = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.connectionCreated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpyglassGingerEntry {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      tcpPort: isSet(object.tcpPort) ? globalThis.Number(object.tcpPort) : 0,
      status: isSet(object.status) ? spyglassSessionStatusFromJSON(object.status) : 0,
      connectionCreated: isSet(object.connectionCreated) ? fromJsonTimestamp(object.connectionCreated) : undefined,
    };
  },

  toJSON(message: SpyglassGingerEntry): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.tcpPort !== 0) {
      obj.tcpPort = Math.round(message.tcpPort);
    }
    if (message.status !== 0) {
      obj.status = spyglassSessionStatusToJSON(message.status);
    }
    if (message.connectionCreated !== undefined) {
      obj.connectionCreated = message.connectionCreated.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpyglassGingerEntry>, I>>(base?: I): SpyglassGingerEntry {
    return SpyglassGingerEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpyglassGingerEntry>, I>>(object: I): SpyglassGingerEntry {
    const message = createBaseSpyglassGingerEntry();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.deviceId = object.deviceId ?? "";
    message.tcpPort = object.tcpPort ?? 0;
    message.status = object.status ?? 0;
    message.connectionCreated = object.connectionCreated ?? undefined;
    return message;
  },
};

function createBaseRemedyActionFixConnection(): RemedyActionFixConnection {
  return { moveFrom: "", moveTo: "", connectFrom: "", connectTo: "" };
}

export const RemedyActionFixConnection = {
  encode(message: RemedyActionFixConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.moveFrom !== "") {
      writer.uint32(18).string(message.moveFrom);
    }
    if (message.moveTo !== "") {
      writer.uint32(26).string(message.moveTo);
    }
    if (message.connectFrom !== "") {
      writer.uint32(34).string(message.connectFrom);
    }
    if (message.connectTo !== "") {
      writer.uint32(42).string(message.connectTo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemedyActionFixConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemedyActionFixConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.moveFrom = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.moveTo = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.connectFrom = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.connectTo = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemedyActionFixConnection {
    return {
      moveFrom: isSet(object.moveFrom) ? globalThis.String(object.moveFrom) : "",
      moveTo: isSet(object.moveTo) ? globalThis.String(object.moveTo) : "",
      connectFrom: isSet(object.connectFrom) ? globalThis.String(object.connectFrom) : "",
      connectTo: isSet(object.connectTo) ? globalThis.String(object.connectTo) : "",
    };
  },

  toJSON(message: RemedyActionFixConnection): unknown {
    const obj: any = {};
    if (message.moveFrom !== "") {
      obj.moveFrom = message.moveFrom;
    }
    if (message.moveTo !== "") {
      obj.moveTo = message.moveTo;
    }
    if (message.connectFrom !== "") {
      obj.connectFrom = message.connectFrom;
    }
    if (message.connectTo !== "") {
      obj.connectTo = message.connectTo;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemedyActionFixConnection>, I>>(base?: I): RemedyActionFixConnection {
    return RemedyActionFixConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemedyActionFixConnection>, I>>(object: I): RemedyActionFixConnection {
    const message = createBaseRemedyActionFixConnection();
    message.moveFrom = object.moveFrom ?? "";
    message.moveTo = object.moveTo ?? "";
    message.connectFrom = object.connectFrom ?? "";
    message.connectTo = object.connectTo ?? "";
    return message;
  },
};

function createBaseRemedyActionAddMissingConnection(): RemedyActionAddMissingConnection {
  return { connectFrom: "", connectTo: "" };
}

export const RemedyActionAddMissingConnection = {
  encode(message: RemedyActionAddMissingConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.connectFrom !== "") {
      writer.uint32(18).string(message.connectFrom);
    }
    if (message.connectTo !== "") {
      writer.uint32(26).string(message.connectTo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemedyActionAddMissingConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemedyActionAddMissingConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connectFrom = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.connectTo = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemedyActionAddMissingConnection {
    return {
      connectFrom: isSet(object.connectFrom) ? globalThis.String(object.connectFrom) : "",
      connectTo: isSet(object.connectTo) ? globalThis.String(object.connectTo) : "",
    };
  },

  toJSON(message: RemedyActionAddMissingConnection): unknown {
    const obj: any = {};
    if (message.connectFrom !== "") {
      obj.connectFrom = message.connectFrom;
    }
    if (message.connectTo !== "") {
      obj.connectTo = message.connectTo;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemedyActionAddMissingConnection>, I>>(
    base?: I,
  ): RemedyActionAddMissingConnection {
    return RemedyActionAddMissingConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemedyActionAddMissingConnection>, I>>(
    object: I,
  ): RemedyActionAddMissingConnection {
    const message = createBaseRemedyActionAddMissingConnection();
    message.connectFrom = object.connectFrom ?? "";
    message.connectTo = object.connectTo ?? "";
    return message;
  },
};

function createBaseRemedyActionRemoveUnexpectedConnection(): RemedyActionRemoveUnexpectedConnection {
  return { disconnectFrom: "", disconnectTo: "" };
}

export const RemedyActionRemoveUnexpectedConnection = {
  encode(message: RemedyActionRemoveUnexpectedConnection, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.disconnectFrom !== "") {
      writer.uint32(18).string(message.disconnectFrom);
    }
    if (message.disconnectTo !== "") {
      writer.uint32(26).string(message.disconnectTo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemedyActionRemoveUnexpectedConnection {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemedyActionRemoveUnexpectedConnection();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.disconnectFrom = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.disconnectTo = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemedyActionRemoveUnexpectedConnection {
    return {
      disconnectFrom: isSet(object.disconnectFrom) ? globalThis.String(object.disconnectFrom) : "",
      disconnectTo: isSet(object.disconnectTo) ? globalThis.String(object.disconnectTo) : "",
    };
  },

  toJSON(message: RemedyActionRemoveUnexpectedConnection): unknown {
    const obj: any = {};
    if (message.disconnectFrom !== "") {
      obj.disconnectFrom = message.disconnectFrom;
    }
    if (message.disconnectTo !== "") {
      obj.disconnectTo = message.disconnectTo;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemedyActionRemoveUnexpectedConnection>, I>>(
    base?: I,
  ): RemedyActionRemoveUnexpectedConnection {
    return RemedyActionRemoveUnexpectedConnection.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemedyActionRemoveUnexpectedConnection>, I>>(
    object: I,
  ): RemedyActionRemoveUnexpectedConnection {
    const message = createBaseRemedyActionRemoveUnexpectedConnection();
    message.disconnectFrom = object.disconnectFrom ?? "";
    message.disconnectTo = object.disconnectTo ?? "";
    return message;
  },
};

function createBaseRemedyAction(): RemedyAction {
  return {
    id: undefined,
    relatedAsserts: [],
    actionType: 0,
    fixConnection: undefined,
    addConnection: undefined,
    removeConnection: undefined,
  };
}

export const RemedyAction = {
  encode(message: RemedyAction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.relatedAsserts) {
      PathId.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.actionType !== 0) {
      writer.uint32(32).int32(message.actionType);
    }
    if (message.fixConnection !== undefined) {
      RemedyActionFixConnection.encode(message.fixConnection, writer.uint32(42).fork()).ldelim();
    }
    if (message.addConnection !== undefined) {
      RemedyActionAddMissingConnection.encode(message.addConnection, writer.uint32(50).fork()).ldelim();
    }
    if (message.removeConnection !== undefined) {
      RemedyActionRemoveUnexpectedConnection.encode(message.removeConnection, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemedyAction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemedyAction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.relatedAsserts.push(PathId.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.actionType = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fixConnection = RemedyActionFixConnection.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.addConnection = RemedyActionAddMissingConnection.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.removeConnection = RemedyActionRemoveUnexpectedConnection.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemedyAction {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      relatedAsserts: globalThis.Array.isArray(object?.relatedAsserts)
        ? object.relatedAsserts.map((e: any) => PathId.fromJSON(e))
        : [],
      actionType: isSet(object.actionType) ? remedyActionCodeFromJSON(object.actionType) : 0,
      fixConnection: isSet(object.fixConnection) ? RemedyActionFixConnection.fromJSON(object.fixConnection) : undefined,
      addConnection: isSet(object.addConnection)
        ? RemedyActionAddMissingConnection.fromJSON(object.addConnection)
        : undefined,
      removeConnection: isSet(object.removeConnection)
        ? RemedyActionRemoveUnexpectedConnection.fromJSON(object.removeConnection)
        : undefined,
    };
  },

  toJSON(message: RemedyAction): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.relatedAsserts?.length) {
      obj.relatedAsserts = message.relatedAsserts.map((e) => PathId.toJSON(e));
    }
    if (message.actionType !== 0) {
      obj.actionType = remedyActionCodeToJSON(message.actionType);
    }
    if (message.fixConnection !== undefined) {
      obj.fixConnection = RemedyActionFixConnection.toJSON(message.fixConnection);
    }
    if (message.addConnection !== undefined) {
      obj.addConnection = RemedyActionAddMissingConnection.toJSON(message.addConnection);
    }
    if (message.removeConnection !== undefined) {
      obj.removeConnection = RemedyActionRemoveUnexpectedConnection.toJSON(message.removeConnection);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemedyAction>, I>>(base?: I): RemedyAction {
    return RemedyAction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemedyAction>, I>>(object: I): RemedyAction {
    const message = createBaseRemedyAction();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.relatedAsserts = object.relatedAsserts?.map((e) => PathId.fromPartial(e)) || [];
    message.actionType = object.actionType ?? 0;
    message.fixConnection = (object.fixConnection !== undefined && object.fixConnection !== null)
      ? RemedyActionFixConnection.fromPartial(object.fixConnection)
      : undefined;
    message.addConnection = (object.addConnection !== undefined && object.addConnection !== null)
      ? RemedyActionAddMissingConnection.fromPartial(object.addConnection)
      : undefined;
    message.removeConnection = (object.removeConnection !== undefined && object.removeConnection !== null)
      ? RemedyActionRemoveUnexpectedConnection.fromPartial(object.removeConnection)
      : undefined;
    return message;
  },
};

function createBaseRemoteSysCapability(): RemoteSysCapability {
  return { sysCapability: 0, enabled: false };
}

export const RemoteSysCapability = {
  encode(message: RemoteSysCapability, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sysCapability !== 0) {
      writer.uint32(8).int32(message.sysCapability);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoteSysCapability {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoteSysCapability();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sysCapability = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoteSysCapability {
    return {
      sysCapability: isSet(object.sysCapability) ? lLDPSysCapabilityTypeFromJSON(object.sysCapability) : 0,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
    };
  },

  toJSON(message: RemoteSysCapability): unknown {
    const obj: any = {};
    if (message.sysCapability !== 0) {
      obj.sysCapability = lLDPSysCapabilityTypeToJSON(message.sysCapability);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoteSysCapability>, I>>(base?: I): RemoteSysCapability {
    return RemoteSysCapability.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoteSysCapability>, I>>(object: I): RemoteSysCapability {
    const message = createBaseRemoteSysCapability();
    message.sysCapability = object.sysCapability ?? 0;
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseAdvertisedAutoNegotiation(): AdvertisedAutoNegotiation {
  return { type: "", hd: false, fd: false };
}

export const AdvertisedAutoNegotiation = {
  encode(message: AdvertisedAutoNegotiation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.hd !== false) {
      writer.uint32(16).bool(message.hd);
    }
    if (message.fd !== false) {
      writer.uint32(24).bool(message.fd);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AdvertisedAutoNegotiation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdvertisedAutoNegotiation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.hd = reader.bool();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fd = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AdvertisedAutoNegotiation {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      hd: isSet(object.hd) ? globalThis.Boolean(object.hd) : false,
      fd: isSet(object.fd) ? globalThis.Boolean(object.fd) : false,
    };
  },

  toJSON(message: AdvertisedAutoNegotiation): unknown {
    const obj: any = {};
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.hd !== false) {
      obj.hd = message.hd;
    }
    if (message.fd !== false) {
      obj.fd = message.fd;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AdvertisedAutoNegotiation>, I>>(base?: I): AdvertisedAutoNegotiation {
    return AdvertisedAutoNegotiation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AdvertisedAutoNegotiation>, I>>(object: I): AdvertisedAutoNegotiation {
    const message = createBaseAdvertisedAutoNegotiation();
    message.type = object.type ?? "";
    message.hd = object.hd ?? false;
    message.fd = object.fd ?? false;
    return message;
  },
};

function createBaseRemotePortAutoNegotiation(): RemotePortAutoNegotiation {
  return { supported: false, enabled: false, current: "", advertised: [] };
}

export const RemotePortAutoNegotiation = {
  encode(message: RemotePortAutoNegotiation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.supported !== false) {
      writer.uint32(8).bool(message.supported);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    if (message.current !== "") {
      writer.uint32(26).string(message.current);
    }
    for (const v of message.advertised) {
      AdvertisedAutoNegotiation.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemotePortAutoNegotiation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemotePortAutoNegotiation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.supported = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.current = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.advertised.push(AdvertisedAutoNegotiation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemotePortAutoNegotiation {
    return {
      supported: isSet(object.supported) ? globalThis.Boolean(object.supported) : false,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      current: isSet(object.current) ? globalThis.String(object.current) : "",
      advertised: globalThis.Array.isArray(object?.advertised)
        ? object.advertised.map((e: any) => AdvertisedAutoNegotiation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: RemotePortAutoNegotiation): unknown {
    const obj: any = {};
    if (message.supported !== false) {
      obj.supported = message.supported;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.current !== "") {
      obj.current = message.current;
    }
    if (message.advertised?.length) {
      obj.advertised = message.advertised.map((e) => AdvertisedAutoNegotiation.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemotePortAutoNegotiation>, I>>(base?: I): RemotePortAutoNegotiation {
    return RemotePortAutoNegotiation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemotePortAutoNegotiation>, I>>(object: I): RemotePortAutoNegotiation {
    const message = createBaseRemotePortAutoNegotiation();
    message.supported = object.supported ?? false;
    message.enabled = object.enabled ?? false;
    message.current = object.current ?? "";
    message.advertised = object.advertised?.map((e) => AdvertisedAutoNegotiation.fromPartial(e)) || [];
    return message;
  },
};

function createBasePortNeighbor(): PortNeighbor {
  return {
    id: undefined,
    remotePortDesc: "",
    remotePortId: "",
    remoteSysDescription: "",
    remoteSysName: "",
    remoteManagementAddrIpv4: "",
    remoteManagementAddrIpv6: "",
    remoteDeviceId: "",
    remoteIndex: 0,
    remotePortIdSubtype: 0,
    remoteDeviceIdSubtype: 0,
    remoteTimeMark: 0,
    deprecatedRemoteSysCap: [],
    collectedAt: undefined,
    localPort: "",
    neighborType: 0,
    remotePortTtl: 0,
    remotePortAutoNegotiation: undefined,
    RemoteSysCapabilityRepeater: false,
    RemoteSysCapabilityBridge: false,
    RemoteSysCapabilityWlan: false,
    RemoteSysCapabilityRouter: false,
    RemoteSysCapabilityTelephone: false,
    RemoteSysCapabilityDocsisCableDevice: false,
    RemoteSysCapabilityStation: false,
  };
}

export const PortNeighbor = {
  encode(message: PortNeighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.remotePortDesc !== "") {
      writer.uint32(18).string(message.remotePortDesc);
    }
    if (message.remotePortId !== "") {
      writer.uint32(26).string(message.remotePortId);
    }
    if (message.remoteSysDescription !== "") {
      writer.uint32(34).string(message.remoteSysDescription);
    }
    if (message.remoteSysName !== "") {
      writer.uint32(42).string(message.remoteSysName);
    }
    if (message.remoteManagementAddrIpv4 !== "") {
      writer.uint32(50).string(message.remoteManagementAddrIpv4);
    }
    if (message.remoteManagementAddrIpv6 !== "") {
      writer.uint32(58).string(message.remoteManagementAddrIpv6);
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(74).string(message.remoteDeviceId);
    }
    if (message.remoteIndex !== 0) {
      writer.uint32(80).uint32(message.remoteIndex);
    }
    if (message.remotePortIdSubtype !== 0) {
      writer.uint32(88).int32(message.remotePortIdSubtype);
    }
    if (message.remoteDeviceIdSubtype !== 0) {
      writer.uint32(96).int32(message.remoteDeviceIdSubtype);
    }
    if (message.remoteTimeMark !== 0) {
      writer.uint32(104).uint64(message.remoteTimeMark);
    }
    for (const v of message.deprecatedRemoteSysCap) {
      RemoteSysCapability.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(122).fork()).ldelim();
    }
    if (message.localPort !== "") {
      writer.uint32(130).string(message.localPort);
    }
    if (message.neighborType !== 0) {
      writer.uint32(136).int32(message.neighborType);
    }
    if (message.remotePortTtl !== 0) {
      writer.uint32(144).int32(message.remotePortTtl);
    }
    if (message.remotePortAutoNegotiation !== undefined) {
      RemotePortAutoNegotiation.encode(message.remotePortAutoNegotiation, writer.uint32(154).fork()).ldelim();
    }
    if (message.RemoteSysCapabilityRepeater !== false) {
      writer.uint32(160).bool(message.RemoteSysCapabilityRepeater);
    }
    if (message.RemoteSysCapabilityBridge !== false) {
      writer.uint32(168).bool(message.RemoteSysCapabilityBridge);
    }
    if (message.RemoteSysCapabilityWlan !== false) {
      writer.uint32(176).bool(message.RemoteSysCapabilityWlan);
    }
    if (message.RemoteSysCapabilityRouter !== false) {
      writer.uint32(184).bool(message.RemoteSysCapabilityRouter);
    }
    if (message.RemoteSysCapabilityTelephone !== false) {
      writer.uint32(192).bool(message.RemoteSysCapabilityTelephone);
    }
    if (message.RemoteSysCapabilityDocsisCableDevice !== false) {
      writer.uint32(200).bool(message.RemoteSysCapabilityDocsisCableDevice);
    }
    if (message.RemoteSysCapabilityStation !== false) {
      writer.uint32(208).bool(message.RemoteSysCapabilityStation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortNeighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortNeighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.remotePortDesc = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.remotePortId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.remoteSysDescription = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.remoteSysName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.remoteManagementAddrIpv4 = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.remoteManagementAddrIpv6 = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.remoteIndex = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.remotePortIdSubtype = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.remoteDeviceIdSubtype = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.remoteTimeMark = longToNumber(reader.uint64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.deprecatedRemoteSysCap.push(RemoteSysCapability.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.localPort = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.neighborType = reader.int32() as any;
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.remotePortTtl = reader.int32();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.remotePortAutoNegotiation = RemotePortAutoNegotiation.decode(reader, reader.uint32());
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.RemoteSysCapabilityRepeater = reader.bool();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.RemoteSysCapabilityBridge = reader.bool();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.RemoteSysCapabilityWlan = reader.bool();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.RemoteSysCapabilityRouter = reader.bool();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.RemoteSysCapabilityTelephone = reader.bool();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.RemoteSysCapabilityDocsisCableDevice = reader.bool();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.RemoteSysCapabilityStation = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortNeighbor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      remotePortDesc: isSet(object.remotePortDesc) ? globalThis.String(object.remotePortDesc) : "",
      remotePortId: isSet(object.remotePortId) ? globalThis.String(object.remotePortId) : "",
      remoteSysDescription: isSet(object.remoteSysDescription) ? globalThis.String(object.remoteSysDescription) : "",
      remoteSysName: isSet(object.remoteSysName) ? globalThis.String(object.remoteSysName) : "",
      remoteManagementAddrIpv4: isSet(object.remoteManagementAddrIpv4)
        ? globalThis.String(object.remoteManagementAddrIpv4)
        : "",
      remoteManagementAddrIpv6: isSet(object.remoteManagementAddrIpv6)
        ? globalThis.String(object.remoteManagementAddrIpv6)
        : "",
      remoteDeviceId: isSet(object.remoteDeviceId) ? globalThis.String(object.remoteDeviceId) : "",
      remoteIndex: isSet(object.remoteIndex) ? globalThis.Number(object.remoteIndex) : 0,
      remotePortIdSubtype: isSet(object.remotePortIdSubtype)
        ? lLDPRemoteDeviceHostSubTypeFromJSON(object.remotePortIdSubtype)
        : 0,
      remoteDeviceIdSubtype: isSet(object.remoteDeviceIdSubtype)
        ? lLDPRemoteDeviceHostSubTypeFromJSON(object.remoteDeviceIdSubtype)
        : 0,
      remoteTimeMark: isSet(object.remoteTimeMark) ? globalThis.Number(object.remoteTimeMark) : 0,
      deprecatedRemoteSysCap: globalThis.Array.isArray(object?.deprecatedRemoteSysCap)
        ? object.deprecatedRemoteSysCap.map((e: any) => RemoteSysCapability.fromJSON(e))
        : [],
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
      localPort: isSet(object.localPort) ? globalThis.String(object.localPort) : "",
      neighborType: isSet(object.neighborType) ? neighborTypeFromJSON(object.neighborType) : 0,
      remotePortTtl: isSet(object.remotePortTtl) ? globalThis.Number(object.remotePortTtl) : 0,
      remotePortAutoNegotiation: isSet(object.remotePortAutoNegotiation)
        ? RemotePortAutoNegotiation.fromJSON(object.remotePortAutoNegotiation)
        : undefined,
      RemoteSysCapabilityRepeater: isSet(object.RemoteSysCapabilityRepeater)
        ? globalThis.Boolean(object.RemoteSysCapabilityRepeater)
        : false,
      RemoteSysCapabilityBridge: isSet(object.RemoteSysCapabilityBridge)
        ? globalThis.Boolean(object.RemoteSysCapabilityBridge)
        : false,
      RemoteSysCapabilityWlan: isSet(object.RemoteSysCapabilityWlan)
        ? globalThis.Boolean(object.RemoteSysCapabilityWlan)
        : false,
      RemoteSysCapabilityRouter: isSet(object.RemoteSysCapabilityRouter)
        ? globalThis.Boolean(object.RemoteSysCapabilityRouter)
        : false,
      RemoteSysCapabilityTelephone: isSet(object.RemoteSysCapabilityTelephone)
        ? globalThis.Boolean(object.RemoteSysCapabilityTelephone)
        : false,
      RemoteSysCapabilityDocsisCableDevice: isSet(object.RemoteSysCapabilityDocsisCableDevice)
        ? globalThis.Boolean(object.RemoteSysCapabilityDocsisCableDevice)
        : false,
      RemoteSysCapabilityStation: isSet(object.RemoteSysCapabilityStation)
        ? globalThis.Boolean(object.RemoteSysCapabilityStation)
        : false,
    };
  },

  toJSON(message: PortNeighbor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.remotePortDesc !== "") {
      obj.remotePortDesc = message.remotePortDesc;
    }
    if (message.remotePortId !== "") {
      obj.remotePortId = message.remotePortId;
    }
    if (message.remoteSysDescription !== "") {
      obj.remoteSysDescription = message.remoteSysDescription;
    }
    if (message.remoteSysName !== "") {
      obj.remoteSysName = message.remoteSysName;
    }
    if (message.remoteManagementAddrIpv4 !== "") {
      obj.remoteManagementAddrIpv4 = message.remoteManagementAddrIpv4;
    }
    if (message.remoteManagementAddrIpv6 !== "") {
      obj.remoteManagementAddrIpv6 = message.remoteManagementAddrIpv6;
    }
    if (message.remoteDeviceId !== "") {
      obj.remoteDeviceId = message.remoteDeviceId;
    }
    if (message.remoteIndex !== 0) {
      obj.remoteIndex = Math.round(message.remoteIndex);
    }
    if (message.remotePortIdSubtype !== 0) {
      obj.remotePortIdSubtype = lLDPRemoteDeviceHostSubTypeToJSON(message.remotePortIdSubtype);
    }
    if (message.remoteDeviceIdSubtype !== 0) {
      obj.remoteDeviceIdSubtype = lLDPRemoteDeviceHostSubTypeToJSON(message.remoteDeviceIdSubtype);
    }
    if (message.remoteTimeMark !== 0) {
      obj.remoteTimeMark = Math.round(message.remoteTimeMark);
    }
    if (message.deprecatedRemoteSysCap?.length) {
      obj.deprecatedRemoteSysCap = message.deprecatedRemoteSysCap.map((e) => RemoteSysCapability.toJSON(e));
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    if (message.localPort !== "") {
      obj.localPort = message.localPort;
    }
    if (message.neighborType !== 0) {
      obj.neighborType = neighborTypeToJSON(message.neighborType);
    }
    if (message.remotePortTtl !== 0) {
      obj.remotePortTtl = Math.round(message.remotePortTtl);
    }
    if (message.remotePortAutoNegotiation !== undefined) {
      obj.remotePortAutoNegotiation = RemotePortAutoNegotiation.toJSON(message.remotePortAutoNegotiation);
    }
    if (message.RemoteSysCapabilityRepeater !== false) {
      obj.RemoteSysCapabilityRepeater = message.RemoteSysCapabilityRepeater;
    }
    if (message.RemoteSysCapabilityBridge !== false) {
      obj.RemoteSysCapabilityBridge = message.RemoteSysCapabilityBridge;
    }
    if (message.RemoteSysCapabilityWlan !== false) {
      obj.RemoteSysCapabilityWlan = message.RemoteSysCapabilityWlan;
    }
    if (message.RemoteSysCapabilityRouter !== false) {
      obj.RemoteSysCapabilityRouter = message.RemoteSysCapabilityRouter;
    }
    if (message.RemoteSysCapabilityTelephone !== false) {
      obj.RemoteSysCapabilityTelephone = message.RemoteSysCapabilityTelephone;
    }
    if (message.RemoteSysCapabilityDocsisCableDevice !== false) {
      obj.RemoteSysCapabilityDocsisCableDevice = message.RemoteSysCapabilityDocsisCableDevice;
    }
    if (message.RemoteSysCapabilityStation !== false) {
      obj.RemoteSysCapabilityStation = message.RemoteSysCapabilityStation;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortNeighbor>, I>>(base?: I): PortNeighbor {
    return PortNeighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortNeighbor>, I>>(object: I): PortNeighbor {
    const message = createBasePortNeighbor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.remotePortDesc = object.remotePortDesc ?? "";
    message.remotePortId = object.remotePortId ?? "";
    message.remoteSysDescription = object.remoteSysDescription ?? "";
    message.remoteSysName = object.remoteSysName ?? "";
    message.remoteManagementAddrIpv4 = object.remoteManagementAddrIpv4 ?? "";
    message.remoteManagementAddrIpv6 = object.remoteManagementAddrIpv6 ?? "";
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.remoteIndex = object.remoteIndex ?? 0;
    message.remotePortIdSubtype = object.remotePortIdSubtype ?? 0;
    message.remoteDeviceIdSubtype = object.remoteDeviceIdSubtype ?? 0;
    message.remoteTimeMark = object.remoteTimeMark ?? 0;
    message.deprecatedRemoteSysCap = object.deprecatedRemoteSysCap?.map((e) => RemoteSysCapability.fromPartial(e)) ||
      [];
    message.collectedAt = object.collectedAt ?? undefined;
    message.localPort = object.localPort ?? "";
    message.neighborType = object.neighborType ?? 0;
    message.remotePortTtl = object.remotePortTtl ?? 0;
    message.remotePortAutoNegotiation =
      (object.remotePortAutoNegotiation !== undefined && object.remotePortAutoNegotiation !== null)
        ? RemotePortAutoNegotiation.fromPartial(object.remotePortAutoNegotiation)
        : undefined;
    message.RemoteSysCapabilityRepeater = object.RemoteSysCapabilityRepeater ?? false;
    message.RemoteSysCapabilityBridge = object.RemoteSysCapabilityBridge ?? false;
    message.RemoteSysCapabilityWlan = object.RemoteSysCapabilityWlan ?? false;
    message.RemoteSysCapabilityRouter = object.RemoteSysCapabilityRouter ?? false;
    message.RemoteSysCapabilityTelephone = object.RemoteSysCapabilityTelephone ?? false;
    message.RemoteSysCapabilityDocsisCableDevice = object.RemoteSysCapabilityDocsisCableDevice ?? false;
    message.RemoteSysCapabilityStation = object.RemoteSysCapabilityStation ?? false;
    return message;
  },
};

function createBasePortState(): PortState {
  return {
    adminState: 0,
    operState: 0,
    ipv4: "",
    adminDownCounter: 0,
    operDownCounter: 0,
    speed: 0,
    collectedAt: undefined,
  };
}

export const PortState = {
  encode(message: PortState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.adminState !== 0) {
      writer.uint32(16).int32(message.adminState);
    }
    if (message.operState !== 0) {
      writer.uint32(24).int32(message.operState);
    }
    if (message.ipv4 !== "") {
      writer.uint32(34).string(message.ipv4);
    }
    if (message.adminDownCounter !== 0) {
      writer.uint32(48).uint64(message.adminDownCounter);
    }
    if (message.operDownCounter !== 0) {
      writer.uint32(56).uint64(message.operDownCounter);
    }
    if (message.speed !== 0) {
      writer.uint32(64).uint64(message.speed);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 16) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.operState = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv4 = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.adminDownCounter = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.operDownCounter = longToNumber(reader.uint64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.speed = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortState {
    return {
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      operState: isSet(object.operState) ? operStateFromJSON(object.operState) : 0,
      ipv4: isSet(object.ipv4) ? globalThis.String(object.ipv4) : "",
      adminDownCounter: isSet(object.adminDownCounter) ? globalThis.Number(object.adminDownCounter) : 0,
      operDownCounter: isSet(object.operDownCounter) ? globalThis.Number(object.operDownCounter) : 0,
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      collectedAt: isSet(object["collected-at"]) ? fromJsonTimestamp(object["collected-at"]) : undefined,
    };
  },

  toJSON(message: PortState): unknown {
    const obj: any = {};
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.operState !== 0) {
      obj.operState = operStateToJSON(message.operState);
    }
    if (message.ipv4 !== "") {
      obj.ipv4 = message.ipv4;
    }
    if (message.adminDownCounter !== 0) {
      obj.adminDownCounter = Math.round(message.adminDownCounter);
    }
    if (message.operDownCounter !== 0) {
      obj.operDownCounter = Math.round(message.operDownCounter);
    }
    if (message.speed !== 0) {
      obj.speed = Math.round(message.speed);
    }
    if (message.collectedAt !== undefined) {
      obj["collected-at"] = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortState>, I>>(base?: I): PortState {
    return PortState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortState>, I>>(object: I): PortState {
    const message = createBasePortState();
    message.adminState = object.adminState ?? 0;
    message.operState = object.operState ?? 0;
    message.ipv4 = object.ipv4 ?? "";
    message.adminDownCounter = object.adminDownCounter ?? 0;
    message.operDownCounter = object.operDownCounter ?? 0;
    message.speed = object.speed ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseVtep(): Vtep {
  return { id: undefined, bytesIn: 0, bytesOut: 0, pktsIn: 0, pktsOut: 0, collectedAt: undefined };
}

export const Vtep = {
  encode(message: Vtep, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.bytesIn !== 0) {
      writer.uint32(24).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(32).uint64(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      writer.uint32(40).uint64(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      writer.uint32(48).uint64(message.pktsOut);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vtep {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVtep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vtep {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      pktsIn: isSet(object.pktsIn) ? globalThis.Number(object.pktsIn) : 0,
      pktsOut: isSet(object.pktsOut) ? globalThis.Number(object.pktsOut) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: Vtep): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      obj.pktsIn = Math.round(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      obj.pktsOut = Math.round(message.pktsOut);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vtep>, I>>(base?: I): Vtep {
    return Vtep.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vtep>, I>>(object: I): Vtep {
    const message = createBaseVtep();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.pktsIn = object.pktsIn ?? 0;
    message.pktsOut = object.pktsOut ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseRemoteVtep(): RemoteVtep {
  return { id: undefined, bytesIn: 0, bytesOut: 0, pktsIn: 0, pktsOut: 0, collectedAt: undefined };
}

export const RemoteVtep = {
  encode(message: RemoteVtep, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.bytesIn !== 0) {
      writer.uint32(24).uint64(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      writer.uint32(32).uint64(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      writer.uint32(40).uint64(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      writer.uint32(48).uint64(message.pktsOut);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoteVtep {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoteVtep();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.bytesIn = longToNumber(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bytesOut = longToNumber(reader.uint64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pktsIn = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.pktsOut = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemoteVtep {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      bytesIn: isSet(object.bytesIn) ? globalThis.Number(object.bytesIn) : 0,
      bytesOut: isSet(object.bytesOut) ? globalThis.Number(object.bytesOut) : 0,
      pktsIn: isSet(object.pktsIn) ? globalThis.Number(object.pktsIn) : 0,
      pktsOut: isSet(object.pktsOut) ? globalThis.Number(object.pktsOut) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: RemoteVtep): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.bytesIn !== 0) {
      obj.bytesIn = Math.round(message.bytesIn);
    }
    if (message.bytesOut !== 0) {
      obj.bytesOut = Math.round(message.bytesOut);
    }
    if (message.pktsIn !== 0) {
      obj.pktsIn = Math.round(message.pktsIn);
    }
    if (message.pktsOut !== 0) {
      obj.pktsOut = Math.round(message.pktsOut);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RemoteVtep>, I>>(base?: I): RemoteVtep {
    return RemoteVtep.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RemoteVtep>, I>>(object: I): RemoteVtep {
    const message = createBaseRemoteVtep();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.bytesIn = object.bytesIn ?? 0;
    message.bytesOut = object.bytesOut ?? 0;
    message.pktsIn = object.pktsIn ?? 0;
    message.pktsOut = object.pktsOut ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseVniPort(): VniPort {
  return { id: undefined, counters: undefined };
}

export const VniPort = {
  encode(message: VniPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.counters !== undefined) {
      BasicCounters.encode(message.counters, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VniPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVniPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.counters = BasicCounters.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VniPort {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      counters: isSet(object.counters) ? BasicCounters.fromJSON(object.counters) : undefined,
    };
  },

  toJSON(message: VniPort): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.counters !== undefined) {
      obj.counters = BasicCounters.toJSON(message.counters);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VniPort>, I>>(base?: I): VniPort {
    return VniPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VniPort>, I>>(object: I): VniPort {
    const message = createBaseVniPort();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.counters = (object.counters !== undefined && object.counters !== null)
      ? BasicCounters.fromPartial(object.counters)
      : undefined;
    return message;
  },
};

function createBaseMac(): Mac {
  return { id: undefined, macAddress: "" };
}

export const Mac = {
  encode(message: Mac, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.macAddress !== "") {
      writer.uint32(26).string(message.macAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Mac {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMac();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.macAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Mac {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
    };
  },

  toJSON(message: Mac): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Mac>, I>>(base?: I): Mac {
    return Mac.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Mac>, I>>(object: I): Mac {
    const message = createBaseMac();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.macAddress = object.macAddress ?? "";
    return message;
  },
};

function createBasePortVlan(): PortVlan {
  return { id: undefined, vlan: 0, deprecatedUntagged: "", taggingMode: 0, collectedAt: undefined };
}

export const PortVlan = {
  encode(message: PortVlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.vlan !== 0) {
      writer.uint32(24).uint32(message.vlan);
    }
    if (message.deprecatedUntagged !== "") {
      writer.uint32(34).string(message.deprecatedUntagged);
    }
    if (message.taggingMode !== 0) {
      writer.uint32(40).int32(message.taggingMode);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortVlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortVlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vlan = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deprecatedUntagged = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.taggingMode = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortVlan {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      vlan: isSet(object.vlan) ? globalThis.Number(object.vlan) : 0,
      deprecatedUntagged: isSet(object.deprecatedUntagged) ? globalThis.String(object.deprecatedUntagged) : "",
      taggingMode: isSet(object.taggingMode) ? vlanTaggingModeFromJSON(object.taggingMode) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: PortVlan): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.vlan !== 0) {
      obj.vlan = Math.round(message.vlan);
    }
    if (message.deprecatedUntagged !== "") {
      obj.deprecatedUntagged = message.deprecatedUntagged;
    }
    if (message.taggingMode !== 0) {
      obj.taggingMode = vlanTaggingModeToJSON(message.taggingMode);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortVlan>, I>>(base?: I): PortVlan {
    return PortVlan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortVlan>, I>>(object: I): PortVlan {
    const message = createBasePortVlan();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.vlan = object.vlan ?? 0;
    message.deprecatedUntagged = object.deprecatedUntagged ?? "";
    message.taggingMode = object.taggingMode ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseVlanState(): VlanState {
  return { id: undefined, adminState: 0, hostIfname: "", macAddr: "", mtu: 0, vlanId: 0, collectedAt: undefined };
}

export const VlanState = {
  encode(message: VlanState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.adminState !== 0) {
      writer.uint32(24).int32(message.adminState);
    }
    if (message.hostIfname !== "") {
      writer.uint32(34).string(message.hostIfname);
    }
    if (message.macAddr !== "") {
      writer.uint32(42).string(message.macAddr);
    }
    if (message.mtu !== 0) {
      writer.uint32(48).uint32(message.mtu);
    }
    if (message.vlanId !== 0) {
      writer.uint32(56).uint32(message.vlanId);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.adminState = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.hostIfname = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.macAddr = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.vlanId = reader.uint32();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanState {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      adminState: isSet(object.adminState) ? adminStateFromJSON(object.adminState) : 0,
      hostIfname: isSet(object.hostIfname) ? globalThis.String(object.hostIfname) : "",
      macAddr: isSet(object.macAddr) ? globalThis.String(object.macAddr) : "",
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: VlanState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.adminState !== 0) {
      obj.adminState = adminStateToJSON(message.adminState);
    }
    if (message.hostIfname !== "") {
      obj.hostIfname = message.hostIfname;
    }
    if (message.macAddr !== "") {
      obj.macAddr = message.macAddr;
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanState>, I>>(base?: I): VlanState {
    return VlanState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanState>, I>>(object: I): VlanState {
    const message = createBaseVlanState();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.adminState = object.adminState ?? 0;
    message.hostIfname = object.hostIfname ?? "";
    message.macAddr = object.macAddr ?? "";
    message.mtu = object.mtu ?? 0;
    message.vlanId = object.vlanId ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseUnknownVlanPort(): UnknownVlanPort {
  return { id: undefined };
}

export const UnknownVlanPort = {
  encode(message: UnknownVlanPort, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnknownVlanPort {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnknownVlanPort();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnknownVlanPort {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: UnknownVlanPort): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnknownVlanPort>, I>>(base?: I): UnknownVlanPort {
    return UnknownVlanPort.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnknownVlanPort>, I>>(object: I): UnknownVlanPort {
    const message = createBaseUnknownVlanPort();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseUnknownVlan(): UnknownVlan {
  return { id: undefined, vlanId: 0, port: "", lastRxPkt: undefined, collectedAt: undefined };
}

export const UnknownVlan = {
  encode(message: UnknownVlan, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.vlanId !== 0) {
      writer.uint32(32).int32(message.vlanId);
    }
    if (message.port !== "") {
      writer.uint32(42).string(message.port);
    }
    if (message.lastRxPkt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastRxPkt), writer.uint32(50).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnknownVlan {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnknownVlan();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.vlanId = reader.int32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.port = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.lastRxPkt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnknownVlan {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      vlanId: isSet(object.vlanId) ? globalThis.Number(object.vlanId) : 0,
      port: isSet(object.port) ? globalThis.String(object.port) : "",
      lastRxPkt: isSet(object.lastRxPkt) ? fromJsonTimestamp(object.lastRxPkt) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: UnknownVlan): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.vlanId !== 0) {
      obj.vlanId = Math.round(message.vlanId);
    }
    if (message.port !== "") {
      obj.port = message.port;
    }
    if (message.lastRxPkt !== undefined) {
      obj.lastRxPkt = message.lastRxPkt.toISOString();
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnknownVlan>, I>>(base?: I): UnknownVlan {
    return UnknownVlan.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnknownVlan>, I>>(object: I): UnknownVlan {
    const message = createBaseUnknownVlan();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.vlanId = object.vlanId ?? 0;
    message.port = object.port ?? "";
    message.lastRxPkt = object.lastRxPkt ?? undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseQsfpDomSensor(): QsfpDomSensor {
  return {
    id: undefined,
    laserTemperature: 0,
    voltage: 0,
    rx1power: 0,
    rx2power: 0,
    rx3power: 0,
    rx4power: 0,
    rx5power: 0,
    rx6power: 0,
    rx7power: 0,
    rx8power: 0,
    temperature: 0,
    tx1bias: 0,
    tx2bias: 0,
    tx3bias: 0,
    tx4bias: 0,
    tx5bias: 0,
    tx6bias: 0,
    tx7bias: 0,
    tx8bias: 0,
    tx1power: 0,
    tx2power: 0,
    tx3power: 0,
    tx4power: 0,
    tx5power: 0,
    tx6power: 0,
    tx7power: 0,
    tx8power: 0,
    mediaLaneCount: 0,
    collectedAt: undefined,
  };
}

export const QsfpDomSensor = {
  encode(message: QsfpDomSensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.laserTemperature !== 0) {
      writer.uint32(29).float(message.laserTemperature);
    }
    if (message.voltage !== 0) {
      writer.uint32(37).float(message.voltage);
    }
    if (message.rx1power !== 0) {
      writer.uint32(45).float(message.rx1power);
    }
    if (message.rx2power !== 0) {
      writer.uint32(53).float(message.rx2power);
    }
    if (message.rx3power !== 0) {
      writer.uint32(61).float(message.rx3power);
    }
    if (message.rx4power !== 0) {
      writer.uint32(69).float(message.rx4power);
    }
    if (message.rx5power !== 0) {
      writer.uint32(77).float(message.rx5power);
    }
    if (message.rx6power !== 0) {
      writer.uint32(85).float(message.rx6power);
    }
    if (message.rx7power !== 0) {
      writer.uint32(93).float(message.rx7power);
    }
    if (message.rx8power !== 0) {
      writer.uint32(101).float(message.rx8power);
    }
    if (message.temperature !== 0) {
      writer.uint32(109).float(message.temperature);
    }
    if (message.tx1bias !== 0) {
      writer.uint32(117).float(message.tx1bias);
    }
    if (message.tx2bias !== 0) {
      writer.uint32(125).float(message.tx2bias);
    }
    if (message.tx3bias !== 0) {
      writer.uint32(133).float(message.tx3bias);
    }
    if (message.tx4bias !== 0) {
      writer.uint32(141).float(message.tx4bias);
    }
    if (message.tx5bias !== 0) {
      writer.uint32(149).float(message.tx5bias);
    }
    if (message.tx6bias !== 0) {
      writer.uint32(157).float(message.tx6bias);
    }
    if (message.tx7bias !== 0) {
      writer.uint32(165).float(message.tx7bias);
    }
    if (message.tx8bias !== 0) {
      writer.uint32(173).float(message.tx8bias);
    }
    if (message.tx1power !== 0) {
      writer.uint32(181).float(message.tx1power);
    }
    if (message.tx2power !== 0) {
      writer.uint32(189).float(message.tx2power);
    }
    if (message.tx3power !== 0) {
      writer.uint32(197).float(message.tx3power);
    }
    if (message.tx4power !== 0) {
      writer.uint32(205).float(message.tx4power);
    }
    if (message.tx5power !== 0) {
      writer.uint32(213).float(message.tx5power);
    }
    if (message.tx6power !== 0) {
      writer.uint32(221).float(message.tx6power);
    }
    if (message.tx7power !== 0) {
      writer.uint32(229).float(message.tx7power);
    }
    if (message.tx8power !== 0) {
      writer.uint32(237).float(message.tx8power);
    }
    if (message.mediaLaneCount !== 0) {
      writer.uint32(240).int32(message.mediaLaneCount);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(250).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QsfpDomSensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQsfpDomSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.laserTemperature = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.voltage = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.rx1power = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.rx2power = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.rx3power = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.rx4power = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.rx5power = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.rx6power = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.rx7power = reader.float();
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.rx8power = reader.float();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.temperature = reader.float();
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.tx1bias = reader.float();
          continue;
        case 15:
          if (tag !== 125) {
            break;
          }

          message.tx2bias = reader.float();
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.tx3bias = reader.float();
          continue;
        case 17:
          if (tag !== 141) {
            break;
          }

          message.tx4bias = reader.float();
          continue;
        case 18:
          if (tag !== 149) {
            break;
          }

          message.tx5bias = reader.float();
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.tx6bias = reader.float();
          continue;
        case 20:
          if (tag !== 165) {
            break;
          }

          message.tx7bias = reader.float();
          continue;
        case 21:
          if (tag !== 173) {
            break;
          }

          message.tx8bias = reader.float();
          continue;
        case 22:
          if (tag !== 181) {
            break;
          }

          message.tx1power = reader.float();
          continue;
        case 23:
          if (tag !== 189) {
            break;
          }

          message.tx2power = reader.float();
          continue;
        case 24:
          if (tag !== 197) {
            break;
          }

          message.tx3power = reader.float();
          continue;
        case 25:
          if (tag !== 205) {
            break;
          }

          message.tx4power = reader.float();
          continue;
        case 26:
          if (tag !== 213) {
            break;
          }

          message.tx5power = reader.float();
          continue;
        case 27:
          if (tag !== 221) {
            break;
          }

          message.tx6power = reader.float();
          continue;
        case 28:
          if (tag !== 229) {
            break;
          }

          message.tx7power = reader.float();
          continue;
        case 29:
          if (tag !== 237) {
            break;
          }

          message.tx8power = reader.float();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.mediaLaneCount = reader.int32();
          continue;
        case 31:
          if (tag !== 250) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QsfpDomSensor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      laserTemperature: isSet(object.laserTemperature) ? globalThis.Number(object.laserTemperature) : 0,
      voltage: isSet(object.voltage) ? globalThis.Number(object.voltage) : 0,
      rx1power: isSet(object.rx1power) ? globalThis.Number(object.rx1power) : 0,
      rx2power: isSet(object.rx2power) ? globalThis.Number(object.rx2power) : 0,
      rx3power: isSet(object.rx3power) ? globalThis.Number(object.rx3power) : 0,
      rx4power: isSet(object.rx4power) ? globalThis.Number(object.rx4power) : 0,
      rx5power: isSet(object.rx5power) ? globalThis.Number(object.rx5power) : 0,
      rx6power: isSet(object.rx6power) ? globalThis.Number(object.rx6power) : 0,
      rx7power: isSet(object.rx7power) ? globalThis.Number(object.rx7power) : 0,
      rx8power: isSet(object.rx8power) ? globalThis.Number(object.rx8power) : 0,
      temperature: isSet(object.temperature) ? globalThis.Number(object.temperature) : 0,
      tx1bias: isSet(object.tx1bias) ? globalThis.Number(object.tx1bias) : 0,
      tx2bias: isSet(object.tx2bias) ? globalThis.Number(object.tx2bias) : 0,
      tx3bias: isSet(object.tx3bias) ? globalThis.Number(object.tx3bias) : 0,
      tx4bias: isSet(object.tx4bias) ? globalThis.Number(object.tx4bias) : 0,
      tx5bias: isSet(object.tx5bias) ? globalThis.Number(object.tx5bias) : 0,
      tx6bias: isSet(object.tx6bias) ? globalThis.Number(object.tx6bias) : 0,
      tx7bias: isSet(object.tx7bias) ? globalThis.Number(object.tx7bias) : 0,
      tx8bias: isSet(object.tx8bias) ? globalThis.Number(object.tx8bias) : 0,
      tx1power: isSet(object.tx1power) ? globalThis.Number(object.tx1power) : 0,
      tx2power: isSet(object.tx2power) ? globalThis.Number(object.tx2power) : 0,
      tx3power: isSet(object.tx3power) ? globalThis.Number(object.tx3power) : 0,
      tx4power: isSet(object.tx4power) ? globalThis.Number(object.tx4power) : 0,
      tx5power: isSet(object.tx5power) ? globalThis.Number(object.tx5power) : 0,
      tx6power: isSet(object.tx6power) ? globalThis.Number(object.tx6power) : 0,
      tx7power: isSet(object.tx7power) ? globalThis.Number(object.tx7power) : 0,
      tx8power: isSet(object.tx8power) ? globalThis.Number(object.tx8power) : 0,
      mediaLaneCount: isSet(object.mediaLaneCount) ? globalThis.Number(object.mediaLaneCount) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: QsfpDomSensor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.laserTemperature !== 0) {
      obj.laserTemperature = message.laserTemperature;
    }
    if (message.voltage !== 0) {
      obj.voltage = message.voltage;
    }
    if (message.rx1power !== 0) {
      obj.rx1power = message.rx1power;
    }
    if (message.rx2power !== 0) {
      obj.rx2power = message.rx2power;
    }
    if (message.rx3power !== 0) {
      obj.rx3power = message.rx3power;
    }
    if (message.rx4power !== 0) {
      obj.rx4power = message.rx4power;
    }
    if (message.rx5power !== 0) {
      obj.rx5power = message.rx5power;
    }
    if (message.rx6power !== 0) {
      obj.rx6power = message.rx6power;
    }
    if (message.rx7power !== 0) {
      obj.rx7power = message.rx7power;
    }
    if (message.rx8power !== 0) {
      obj.rx8power = message.rx8power;
    }
    if (message.temperature !== 0) {
      obj.temperature = message.temperature;
    }
    if (message.tx1bias !== 0) {
      obj.tx1bias = message.tx1bias;
    }
    if (message.tx2bias !== 0) {
      obj.tx2bias = message.tx2bias;
    }
    if (message.tx3bias !== 0) {
      obj.tx3bias = message.tx3bias;
    }
    if (message.tx4bias !== 0) {
      obj.tx4bias = message.tx4bias;
    }
    if (message.tx5bias !== 0) {
      obj.tx5bias = message.tx5bias;
    }
    if (message.tx6bias !== 0) {
      obj.tx6bias = message.tx6bias;
    }
    if (message.tx7bias !== 0) {
      obj.tx7bias = message.tx7bias;
    }
    if (message.tx8bias !== 0) {
      obj.tx8bias = message.tx8bias;
    }
    if (message.tx1power !== 0) {
      obj.tx1power = message.tx1power;
    }
    if (message.tx2power !== 0) {
      obj.tx2power = message.tx2power;
    }
    if (message.tx3power !== 0) {
      obj.tx3power = message.tx3power;
    }
    if (message.tx4power !== 0) {
      obj.tx4power = message.tx4power;
    }
    if (message.tx5power !== 0) {
      obj.tx5power = message.tx5power;
    }
    if (message.tx6power !== 0) {
      obj.tx6power = message.tx6power;
    }
    if (message.tx7power !== 0) {
      obj.tx7power = message.tx7power;
    }
    if (message.tx8power !== 0) {
      obj.tx8power = message.tx8power;
    }
    if (message.mediaLaneCount !== 0) {
      obj.mediaLaneCount = Math.round(message.mediaLaneCount);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QsfpDomSensor>, I>>(base?: I): QsfpDomSensor {
    return QsfpDomSensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QsfpDomSensor>, I>>(object: I): QsfpDomSensor {
    const message = createBaseQsfpDomSensor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.laserTemperature = object.laserTemperature ?? 0;
    message.voltage = object.voltage ?? 0;
    message.rx1power = object.rx1power ?? 0;
    message.rx2power = object.rx2power ?? 0;
    message.rx3power = object.rx3power ?? 0;
    message.rx4power = object.rx4power ?? 0;
    message.rx5power = object.rx5power ?? 0;
    message.rx6power = object.rx6power ?? 0;
    message.rx7power = object.rx7power ?? 0;
    message.rx8power = object.rx8power ?? 0;
    message.temperature = object.temperature ?? 0;
    message.tx1bias = object.tx1bias ?? 0;
    message.tx2bias = object.tx2bias ?? 0;
    message.tx3bias = object.tx3bias ?? 0;
    message.tx4bias = object.tx4bias ?? 0;
    message.tx5bias = object.tx5bias ?? 0;
    message.tx6bias = object.tx6bias ?? 0;
    message.tx7bias = object.tx7bias ?? 0;
    message.tx8bias = object.tx8bias ?? 0;
    message.tx1power = object.tx1power ?? 0;
    message.tx2power = object.tx2power ?? 0;
    message.tx3power = object.tx3power ?? 0;
    message.tx4power = object.tx4power ?? 0;
    message.tx5power = object.tx5power ?? 0;
    message.tx6power = object.tx6power ?? 0;
    message.tx7power = object.tx7power ?? 0;
    message.tx8power = object.tx8power ?? 0;
    message.mediaLaneCount = object.mediaLaneCount ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseQsfpDomThreshold(): QsfpDomThreshold {
  return {
    id: undefined,
    lasertemphighalarm: 0,
    lasertemphighwarning: 0,
    lasertemplowalarm: 0,
    lasertemplowwarning: 0,
    rxpowerhighalarm: 0,
    rxpowerhighwarning: 0,
    rxpowerlowalarm: 0,
    rxpowerlowwarning: 0,
    temphighalarm: 0,
    temphighwarning: 0,
    templowalarm: 0,
    templowwarning: 0,
    txbiashighalarm: 0,
    txbiashighwarning: 0,
    txbiaslowalarm: 0,
    txbiaslowwarning: 0,
    txpowerhighalarm: 0,
    txpowerhighwarning: 0,
    txpowerlowalarm: 0,
    txpowerlowwarning: 0,
    vcchighalarm: 0,
    vcchighwarning: 0,
    vcclowalarm: 0,
    vcclowwarning: 0,
    collectedAt: undefined,
  };
}

export const QsfpDomThreshold = {
  encode(message: QsfpDomThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.lasertemphighalarm !== 0) {
      writer.uint32(29).float(message.lasertemphighalarm);
    }
    if (message.lasertemphighwarning !== 0) {
      writer.uint32(37).float(message.lasertemphighwarning);
    }
    if (message.lasertemplowalarm !== 0) {
      writer.uint32(45).float(message.lasertemplowalarm);
    }
    if (message.lasertemplowwarning !== 0) {
      writer.uint32(53).float(message.lasertemplowwarning);
    }
    if (message.rxpowerhighalarm !== 0) {
      writer.uint32(61).float(message.rxpowerhighalarm);
    }
    if (message.rxpowerhighwarning !== 0) {
      writer.uint32(69).float(message.rxpowerhighwarning);
    }
    if (message.rxpowerlowalarm !== 0) {
      writer.uint32(77).float(message.rxpowerlowalarm);
    }
    if (message.rxpowerlowwarning !== 0) {
      writer.uint32(85).float(message.rxpowerlowwarning);
    }
    if (message.temphighalarm !== 0) {
      writer.uint32(93).float(message.temphighalarm);
    }
    if (message.temphighwarning !== 0) {
      writer.uint32(101).float(message.temphighwarning);
    }
    if (message.templowalarm !== 0) {
      writer.uint32(109).float(message.templowalarm);
    }
    if (message.templowwarning !== 0) {
      writer.uint32(117).float(message.templowwarning);
    }
    if (message.txbiashighalarm !== 0) {
      writer.uint32(125).float(message.txbiashighalarm);
    }
    if (message.txbiashighwarning !== 0) {
      writer.uint32(133).float(message.txbiashighwarning);
    }
    if (message.txbiaslowalarm !== 0) {
      writer.uint32(141).float(message.txbiaslowalarm);
    }
    if (message.txbiaslowwarning !== 0) {
      writer.uint32(149).float(message.txbiaslowwarning);
    }
    if (message.txpowerhighalarm !== 0) {
      writer.uint32(157).float(message.txpowerhighalarm);
    }
    if (message.txpowerhighwarning !== 0) {
      writer.uint32(165).float(message.txpowerhighwarning);
    }
    if (message.txpowerlowalarm !== 0) {
      writer.uint32(173).float(message.txpowerlowalarm);
    }
    if (message.txpowerlowwarning !== 0) {
      writer.uint32(181).float(message.txpowerlowwarning);
    }
    if (message.vcchighalarm !== 0) {
      writer.uint32(189).float(message.vcchighalarm);
    }
    if (message.vcchighwarning !== 0) {
      writer.uint32(197).float(message.vcchighwarning);
    }
    if (message.vcclowalarm !== 0) {
      writer.uint32(205).float(message.vcclowalarm);
    }
    if (message.vcclowwarning !== 0) {
      writer.uint32(213).float(message.vcclowwarning);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(218).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QsfpDomThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQsfpDomThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.lasertemphighalarm = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.lasertemphighwarning = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.lasertemplowalarm = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.lasertemplowwarning = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.rxpowerhighalarm = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.rxpowerhighwarning = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.rxpowerlowalarm = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.rxpowerlowwarning = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.temphighalarm = reader.float();
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.temphighwarning = reader.float();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.templowalarm = reader.float();
          continue;
        case 14:
          if (tag !== 117) {
            break;
          }

          message.templowwarning = reader.float();
          continue;
        case 15:
          if (tag !== 125) {
            break;
          }

          message.txbiashighalarm = reader.float();
          continue;
        case 16:
          if (tag !== 133) {
            break;
          }

          message.txbiashighwarning = reader.float();
          continue;
        case 17:
          if (tag !== 141) {
            break;
          }

          message.txbiaslowalarm = reader.float();
          continue;
        case 18:
          if (tag !== 149) {
            break;
          }

          message.txbiaslowwarning = reader.float();
          continue;
        case 19:
          if (tag !== 157) {
            break;
          }

          message.txpowerhighalarm = reader.float();
          continue;
        case 20:
          if (tag !== 165) {
            break;
          }

          message.txpowerhighwarning = reader.float();
          continue;
        case 21:
          if (tag !== 173) {
            break;
          }

          message.txpowerlowalarm = reader.float();
          continue;
        case 22:
          if (tag !== 181) {
            break;
          }

          message.txpowerlowwarning = reader.float();
          continue;
        case 23:
          if (tag !== 189) {
            break;
          }

          message.vcchighalarm = reader.float();
          continue;
        case 24:
          if (tag !== 197) {
            break;
          }

          message.vcchighwarning = reader.float();
          continue;
        case 25:
          if (tag !== 205) {
            break;
          }

          message.vcclowalarm = reader.float();
          continue;
        case 26:
          if (tag !== 213) {
            break;
          }

          message.vcclowwarning = reader.float();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QsfpDomThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      lasertemphighalarm: isSet(object.lasertemphighalarm) ? globalThis.Number(object.lasertemphighalarm) : 0,
      lasertemphighwarning: isSet(object.lasertemphighwarning) ? globalThis.Number(object.lasertemphighwarning) : 0,
      lasertemplowalarm: isSet(object.lasertemplowalarm) ? globalThis.Number(object.lasertemplowalarm) : 0,
      lasertemplowwarning: isSet(object.lasertemplowwarning) ? globalThis.Number(object.lasertemplowwarning) : 0,
      rxpowerhighalarm: isSet(object.rxpowerhighalarm) ? globalThis.Number(object.rxpowerhighalarm) : 0,
      rxpowerhighwarning: isSet(object.rxpowerhighwarning) ? globalThis.Number(object.rxpowerhighwarning) : 0,
      rxpowerlowalarm: isSet(object.rxpowerlowalarm) ? globalThis.Number(object.rxpowerlowalarm) : 0,
      rxpowerlowwarning: isSet(object.rxpowerlowwarning) ? globalThis.Number(object.rxpowerlowwarning) : 0,
      temphighalarm: isSet(object.temphighalarm) ? globalThis.Number(object.temphighalarm) : 0,
      temphighwarning: isSet(object.temphighwarning) ? globalThis.Number(object.temphighwarning) : 0,
      templowalarm: isSet(object.templowalarm) ? globalThis.Number(object.templowalarm) : 0,
      templowwarning: isSet(object.templowwarning) ? globalThis.Number(object.templowwarning) : 0,
      txbiashighalarm: isSet(object.txbiashighalarm) ? globalThis.Number(object.txbiashighalarm) : 0,
      txbiashighwarning: isSet(object.txbiashighwarning) ? globalThis.Number(object.txbiashighwarning) : 0,
      txbiaslowalarm: isSet(object.txbiaslowalarm) ? globalThis.Number(object.txbiaslowalarm) : 0,
      txbiaslowwarning: isSet(object.txbiaslowwarning) ? globalThis.Number(object.txbiaslowwarning) : 0,
      txpowerhighalarm: isSet(object.txpowerhighalarm) ? globalThis.Number(object.txpowerhighalarm) : 0,
      txpowerhighwarning: isSet(object.txpowerhighwarning) ? globalThis.Number(object.txpowerhighwarning) : 0,
      txpowerlowalarm: isSet(object.txpowerlowalarm) ? globalThis.Number(object.txpowerlowalarm) : 0,
      txpowerlowwarning: isSet(object.txpowerlowwarning) ? globalThis.Number(object.txpowerlowwarning) : 0,
      vcchighalarm: isSet(object.vcchighalarm) ? globalThis.Number(object.vcchighalarm) : 0,
      vcchighwarning: isSet(object.vcchighwarning) ? globalThis.Number(object.vcchighwarning) : 0,
      vcclowalarm: isSet(object.vcclowalarm) ? globalThis.Number(object.vcclowalarm) : 0,
      vcclowwarning: isSet(object.vcclowwarning) ? globalThis.Number(object.vcclowwarning) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: QsfpDomThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.lasertemphighalarm !== 0) {
      obj.lasertemphighalarm = message.lasertemphighalarm;
    }
    if (message.lasertemphighwarning !== 0) {
      obj.lasertemphighwarning = message.lasertemphighwarning;
    }
    if (message.lasertemplowalarm !== 0) {
      obj.lasertemplowalarm = message.lasertemplowalarm;
    }
    if (message.lasertemplowwarning !== 0) {
      obj.lasertemplowwarning = message.lasertemplowwarning;
    }
    if (message.rxpowerhighalarm !== 0) {
      obj.rxpowerhighalarm = message.rxpowerhighalarm;
    }
    if (message.rxpowerhighwarning !== 0) {
      obj.rxpowerhighwarning = message.rxpowerhighwarning;
    }
    if (message.rxpowerlowalarm !== 0) {
      obj.rxpowerlowalarm = message.rxpowerlowalarm;
    }
    if (message.rxpowerlowwarning !== 0) {
      obj.rxpowerlowwarning = message.rxpowerlowwarning;
    }
    if (message.temphighalarm !== 0) {
      obj.temphighalarm = message.temphighalarm;
    }
    if (message.temphighwarning !== 0) {
      obj.temphighwarning = message.temphighwarning;
    }
    if (message.templowalarm !== 0) {
      obj.templowalarm = message.templowalarm;
    }
    if (message.templowwarning !== 0) {
      obj.templowwarning = message.templowwarning;
    }
    if (message.txbiashighalarm !== 0) {
      obj.txbiashighalarm = message.txbiashighalarm;
    }
    if (message.txbiashighwarning !== 0) {
      obj.txbiashighwarning = message.txbiashighwarning;
    }
    if (message.txbiaslowalarm !== 0) {
      obj.txbiaslowalarm = message.txbiaslowalarm;
    }
    if (message.txbiaslowwarning !== 0) {
      obj.txbiaslowwarning = message.txbiaslowwarning;
    }
    if (message.txpowerhighalarm !== 0) {
      obj.txpowerhighalarm = message.txpowerhighalarm;
    }
    if (message.txpowerhighwarning !== 0) {
      obj.txpowerhighwarning = message.txpowerhighwarning;
    }
    if (message.txpowerlowalarm !== 0) {
      obj.txpowerlowalarm = message.txpowerlowalarm;
    }
    if (message.txpowerlowwarning !== 0) {
      obj.txpowerlowwarning = message.txpowerlowwarning;
    }
    if (message.vcchighalarm !== 0) {
      obj.vcchighalarm = message.vcchighalarm;
    }
    if (message.vcchighwarning !== 0) {
      obj.vcchighwarning = message.vcchighwarning;
    }
    if (message.vcclowalarm !== 0) {
      obj.vcclowalarm = message.vcclowalarm;
    }
    if (message.vcclowwarning !== 0) {
      obj.vcclowwarning = message.vcclowwarning;
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QsfpDomThreshold>, I>>(base?: I): QsfpDomThreshold {
    return QsfpDomThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QsfpDomThreshold>, I>>(object: I): QsfpDomThreshold {
    const message = createBaseQsfpDomThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.lasertemphighalarm = object.lasertemphighalarm ?? 0;
    message.lasertemphighwarning = object.lasertemphighwarning ?? 0;
    message.lasertemplowalarm = object.lasertemplowalarm ?? 0;
    message.lasertemplowwarning = object.lasertemplowwarning ?? 0;
    message.rxpowerhighalarm = object.rxpowerhighalarm ?? 0;
    message.rxpowerhighwarning = object.rxpowerhighwarning ?? 0;
    message.rxpowerlowalarm = object.rxpowerlowalarm ?? 0;
    message.rxpowerlowwarning = object.rxpowerlowwarning ?? 0;
    message.temphighalarm = object.temphighalarm ?? 0;
    message.temphighwarning = object.temphighwarning ?? 0;
    message.templowalarm = object.templowalarm ?? 0;
    message.templowwarning = object.templowwarning ?? 0;
    message.txbiashighalarm = object.txbiashighalarm ?? 0;
    message.txbiashighwarning = object.txbiashighwarning ?? 0;
    message.txbiaslowalarm = object.txbiaslowalarm ?? 0;
    message.txbiaslowwarning = object.txbiaslowwarning ?? 0;
    message.txpowerhighalarm = object.txpowerhighalarm ?? 0;
    message.txpowerhighwarning = object.txpowerhighwarning ?? 0;
    message.txpowerlowalarm = object.txpowerlowalarm ?? 0;
    message.txpowerlowwarning = object.txpowerlowwarning ?? 0;
    message.vcchighalarm = object.vcchighalarm ?? 0;
    message.vcchighwarning = object.vcchighwarning ?? 0;
    message.vcclowalarm = object.vcclowalarm ?? 0;
    message.vcclowwarning = object.vcclowwarning ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseL2Mac(): L2Mac {
  return { id: undefined };
}

export const L2Mac = {
  encode(message: L2Mac, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): L2Mac {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseL2Mac();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): L2Mac {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: L2Mac): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<L2Mac>, I>>(base?: I): L2Mac {
    return L2Mac.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<L2Mac>, I>>(object: I): L2Mac {
    const message = createBaseL2Mac();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseL3Table(): L3Table {
  return {
    id: undefined,
    macAddress: "",
    ifName: "",
    ipAddress: "",
    familyType: 0,
    vlan: 0,
    vni: 0,
    stale: false,
    collectedAt: undefined,
    learnedFrom: 0,
    status: 0,
  };
}

export const L3Table = {
  encode(message: L3Table, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(34).fork()).ldelim();
    }
    if (message.macAddress !== "") {
      writer.uint32(42).string(message.macAddress);
    }
    if (message.ifName !== "") {
      writer.uint32(50).string(message.ifName);
    }
    if (message.ipAddress !== "") {
      writer.uint32(58).string(message.ipAddress);
    }
    if (message.familyType !== 0) {
      writer.uint32(64).int32(message.familyType);
    }
    if (message.vlan !== 0) {
      writer.uint32(72).uint32(message.vlan);
    }
    if (message.vni !== 0) {
      writer.uint32(80).uint32(message.vni);
    }
    if (message.stale !== false) {
      writer.uint32(88).bool(message.stale);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(98).fork()).ldelim();
    }
    if (message.learnedFrom !== 0) {
      writer.uint32(104).int32(message.learnedFrom);
    }
    if (message.status !== 0) {
      writer.uint32(112).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): L3Table {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseL3Table();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ifName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipAddress = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.familyType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.vlan = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.stale = reader.bool();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.learnedFrom = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): L3Table {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      ifName: isSet(object.ifName) ? globalThis.String(object.ifName) : "",
      ipAddress: isSet(object.ipAddress) ? globalThis.String(object.ipAddress) : "",
      familyType: isSet(object.familyType) ? ipFamilyTypeFromJSON(object.familyType) : 0,
      vlan: isSet(object.vlan) ? globalThis.Number(object.vlan) : 0,
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      stale: isSet(object.stale) ? globalThis.Boolean(object.stale) : false,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
      learnedFrom: isSet(object.learnedFrom) ? l3TableEntrySourceFromJSON(object.learnedFrom) : 0,
      status: isSet(object.status) ? l3StatusFromJSON(object.status) : 0,
    };
  },

  toJSON(message: L3Table): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.ifName !== "") {
      obj.ifName = message.ifName;
    }
    if (message.ipAddress !== "") {
      obj.ipAddress = message.ipAddress;
    }
    if (message.familyType !== 0) {
      obj.familyType = ipFamilyTypeToJSON(message.familyType);
    }
    if (message.vlan !== 0) {
      obj.vlan = Math.round(message.vlan);
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.stale !== false) {
      obj.stale = message.stale;
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    if (message.learnedFrom !== 0) {
      obj.learnedFrom = l3TableEntrySourceToJSON(message.learnedFrom);
    }
    if (message.status !== 0) {
      obj.status = l3StatusToJSON(message.status);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<L3Table>, I>>(base?: I): L3Table {
    return L3Table.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<L3Table>, I>>(object: I): L3Table {
    const message = createBaseL3Table();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.macAddress = object.macAddress ?? "";
    message.ifName = object.ifName ?? "";
    message.ipAddress = object.ipAddress ?? "";
    message.familyType = object.familyType ?? 0;
    message.vlan = object.vlan ?? 0;
    message.vni = object.vni ?? 0;
    message.stale = object.stale ?? false;
    message.collectedAt = object.collectedAt ?? undefined;
    message.learnedFrom = object.learnedFrom ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseIpPrefix(): IpPrefix {
  return { id: undefined };
}

export const IpPrefix = {
  encode(message: IpPrefix, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpPrefix {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpPrefix();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpPrefix {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: IpPrefix): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpPrefix>, I>>(base?: I): IpPrefix {
    return IpPrefix.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpPrefix>, I>>(object: I): IpPrefix {
    const message = createBaseIpPrefix();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseVrf(): Vrf {
  return { id: undefined };
}

export const Vrf = {
  encode(message: Vrf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vrf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVrf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3:
          if (tag !== 26) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vrf {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: Vrf): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vrf>, I>>(base?: I): Vrf {
    return Vrf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vrf>, I>>(object: I): Vrf {
    const message = createBaseVrf();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseIpNextHop(): IpNextHop {
  return {
    id: undefined,
    flags: 0,
    fib: false,
    directlyConnected: false,
    ip: "",
    deprecatedAfi: "",
    interfaceIndex: 0,
    interfaceName: "",
    active: false,
    onLink: false,
    weight: 0,
    collectedAt: undefined,
    afi: 0,
  };
}

export const IpNextHop = {
  encode(message: IpNextHop, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(42).fork()).ldelim();
    }
    if (message.flags !== 0) {
      writer.uint32(48).int32(message.flags);
    }
    if (message.fib !== false) {
      writer.uint32(56).bool(message.fib);
    }
    if (message.directlyConnected !== false) {
      writer.uint32(64).bool(message.directlyConnected);
    }
    if (message.ip !== "") {
      writer.uint32(74).string(message.ip);
    }
    if (message.deprecatedAfi !== "") {
      writer.uint32(82).string(message.deprecatedAfi);
    }
    if (message.interfaceIndex !== 0) {
      writer.uint32(88).int32(message.interfaceIndex);
    }
    if (message.interfaceName !== "") {
      writer.uint32(98).string(message.interfaceName);
    }
    if (message.active !== false) {
      writer.uint32(104).bool(message.active);
    }
    if (message.onLink !== false) {
      writer.uint32(112).bool(message.onLink);
    }
    if (message.weight !== 0) {
      writer.uint32(120).int32(message.weight);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(130).fork()).ldelim();
    }
    if (message.afi !== 0) {
      writer.uint32(136).int32(message.afi);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpNextHop {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpNextHop();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 5:
          if (tag !== 42) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.flags = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.fib = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.directlyConnected = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ip = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.deprecatedAfi = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.interfaceIndex = reader.int32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.interfaceName = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.onLink = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.weight = reader.int32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.afi = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpNextHop {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      flags: isSet(object.flags) ? globalThis.Number(object.flags) : 0,
      fib: isSet(object.fib) ? globalThis.Boolean(object.fib) : false,
      directlyConnected: isSet(object.directlyConnected) ? globalThis.Boolean(object.directlyConnected) : false,
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      deprecatedAfi: isSet(object.deprecatedAfi) ? globalThis.String(object.deprecatedAfi) : "",
      interfaceIndex: isSet(object.interfaceIndex) ? globalThis.Number(object.interfaceIndex) : 0,
      interfaceName: isSet(object.interfaceName) ? globalThis.String(object.interfaceName) : "",
      active: isSet(object.active) ? globalThis.Boolean(object.active) : false,
      onLink: isSet(object.onLink) ? globalThis.Boolean(object.onLink) : false,
      weight: isSet(object.weight) ? globalThis.Number(object.weight) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
      afi: isSet(object.afi) ? ipFamilyTypeFromJSON(object.afi) : 0,
    };
  },

  toJSON(message: IpNextHop): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.flags !== 0) {
      obj.flags = Math.round(message.flags);
    }
    if (message.fib !== false) {
      obj.fib = message.fib;
    }
    if (message.directlyConnected !== false) {
      obj.directlyConnected = message.directlyConnected;
    }
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.deprecatedAfi !== "") {
      obj.deprecatedAfi = message.deprecatedAfi;
    }
    if (message.interfaceIndex !== 0) {
      obj.interfaceIndex = Math.round(message.interfaceIndex);
    }
    if (message.interfaceName !== "") {
      obj.interfaceName = message.interfaceName;
    }
    if (message.active !== false) {
      obj.active = message.active;
    }
    if (message.onLink !== false) {
      obj.onLink = message.onLink;
    }
    if (message.weight !== 0) {
      obj.weight = Math.round(message.weight);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    if (message.afi !== 0) {
      obj.afi = ipFamilyTypeToJSON(message.afi);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpNextHop>, I>>(base?: I): IpNextHop {
    return IpNextHop.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpNextHop>, I>>(object: I): IpNextHop {
    const message = createBaseIpNextHop();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.flags = object.flags ?? 0;
    message.fib = object.fib ?? false;
    message.directlyConnected = object.directlyConnected ?? false;
    message.ip = object.ip ?? "";
    message.deprecatedAfi = object.deprecatedAfi ?? "";
    message.interfaceIndex = object.interfaceIndex ?? 0;
    message.interfaceName = object.interfaceName ?? "";
    message.active = object.active ?? false;
    message.onLink = object.onLink ?? false;
    message.weight = object.weight ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    message.afi = object.afi ?? 0;
    return message;
  },
};

function createBaseIpRouteTable(): IpRouteTable {
  return {
    id: undefined,
    prefix: "",
    prefixLen: 0,
    deprecatedProtocol: "",
    vrfId: 0,
    vrfName: "",
    selected: false,
    destSelected: false,
    distance: 0,
    metric: 0,
    installed: false,
    offloaded: false,
    table: 0,
    internalStatus: 0,
    internalFlags: 0,
    internalNextHopNum: 0,
    internalNextHopActiveNum: 0,
    nextHopGroupId: 0,
    installedNextHopGroupId: 0,
    uptime: "",
    ipFamily: 0,
    collectedAt: undefined,
    protocol: 0,
    multipleDiffNextHops: false,
  };
}

export const IpRouteTable = {
  encode(message: IpRouteTable, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(34).fork()).ldelim();
    }
    if (message.prefix !== "") {
      writer.uint32(42).string(message.prefix);
    }
    if (message.prefixLen !== 0) {
      writer.uint32(48).uint32(message.prefixLen);
    }
    if (message.deprecatedProtocol !== "") {
      writer.uint32(58).string(message.deprecatedProtocol);
    }
    if (message.vrfId !== 0) {
      writer.uint32(64).uint32(message.vrfId);
    }
    if (message.vrfName !== "") {
      writer.uint32(74).string(message.vrfName);
    }
    if (message.selected !== false) {
      writer.uint32(80).bool(message.selected);
    }
    if (message.destSelected !== false) {
      writer.uint32(88).bool(message.destSelected);
    }
    if (message.distance !== 0) {
      writer.uint32(96).uint32(message.distance);
    }
    if (message.metric !== 0) {
      writer.uint32(104).uint32(message.metric);
    }
    if (message.installed !== false) {
      writer.uint32(112).bool(message.installed);
    }
    if (message.offloaded !== false) {
      writer.uint32(120).bool(message.offloaded);
    }
    if (message.table !== 0) {
      writer.uint32(128).uint32(message.table);
    }
    if (message.internalStatus !== 0) {
      writer.uint32(136).uint32(message.internalStatus);
    }
    if (message.internalFlags !== 0) {
      writer.uint32(144).uint32(message.internalFlags);
    }
    if (message.internalNextHopNum !== 0) {
      writer.uint32(152).uint32(message.internalNextHopNum);
    }
    if (message.internalNextHopActiveNum !== 0) {
      writer.uint32(160).uint32(message.internalNextHopActiveNum);
    }
    if (message.nextHopGroupId !== 0) {
      writer.uint32(168).uint32(message.nextHopGroupId);
    }
    if (message.installedNextHopGroupId !== 0) {
      writer.uint32(176).uint32(message.installedNextHopGroupId);
    }
    if (message.uptime !== "") {
      writer.uint32(186).string(message.uptime);
    }
    if (message.ipFamily !== 0) {
      writer.uint32(192).int32(message.ipFamily);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(202).fork()).ldelim();
    }
    if (message.protocol !== 0) {
      writer.uint32(208).int32(message.protocol);
    }
    if (message.multipleDiffNextHops !== false) {
      writer.uint32(216).bool(message.multipleDiffNextHops);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IpRouteTable {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIpRouteTable();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.prefix = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.prefixLen = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.deprecatedProtocol = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.vrfId = reader.uint32();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.vrfName = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.selected = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.destSelected = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.distance = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.metric = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.installed = reader.bool();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.offloaded = reader.bool();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.table = reader.uint32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.internalStatus = reader.uint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.internalFlags = reader.uint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.internalNextHopNum = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.internalNextHopActiveNum = reader.uint32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.nextHopGroupId = reader.uint32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.installedNextHopGroupId = reader.uint32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.uptime = reader.string();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.ipFamily = reader.int32() as any;
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.protocol = reader.int32() as any;
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.multipleDiffNextHops = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IpRouteTable {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      prefix: isSet(object.prefix) ? globalThis.String(object.prefix) : "",
      prefixLen: isSet(object.prefixLen) ? globalThis.Number(object.prefixLen) : 0,
      deprecatedProtocol: isSet(object.deprecatedProtocol) ? globalThis.String(object.deprecatedProtocol) : "",
      vrfId: isSet(object.vrfId) ? globalThis.Number(object.vrfId) : 0,
      vrfName: isSet(object.vrfName) ? globalThis.String(object.vrfName) : "",
      selected: isSet(object.selected) ? globalThis.Boolean(object.selected) : false,
      destSelected: isSet(object.destSelected) ? globalThis.Boolean(object.destSelected) : false,
      distance: isSet(object.distance) ? globalThis.Number(object.distance) : 0,
      metric: isSet(object.metric) ? globalThis.Number(object.metric) : 0,
      installed: isSet(object.installed) ? globalThis.Boolean(object.installed) : false,
      offloaded: isSet(object.offloaded) ? globalThis.Boolean(object.offloaded) : false,
      table: isSet(object.table) ? globalThis.Number(object.table) : 0,
      internalStatus: isSet(object.internalStatus) ? globalThis.Number(object.internalStatus) : 0,
      internalFlags: isSet(object.internalFlags) ? globalThis.Number(object.internalFlags) : 0,
      internalNextHopNum: isSet(object.internalNextHopNum) ? globalThis.Number(object.internalNextHopNum) : 0,
      internalNextHopActiveNum: isSet(object.internalNextHopActiveNum)
        ? globalThis.Number(object.internalNextHopActiveNum)
        : 0,
      nextHopGroupId: isSet(object.nextHopGroupId) ? globalThis.Number(object.nextHopGroupId) : 0,
      installedNextHopGroupId: isSet(object.installedNextHopGroupId)
        ? globalThis.Number(object.installedNextHopGroupId)
        : 0,
      uptime: isSet(object.uptime) ? globalThis.String(object.uptime) : "",
      ipFamily: isSet(object.ipFamily) ? ipFamilyTypeFromJSON(object.ipFamily) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
      protocol: isSet(object.protocol) ? protocolTypeFromJSON(object.protocol) : 0,
      multipleDiffNextHops: isSet(object.multipleDiffNextHops)
        ? globalThis.Boolean(object.multipleDiffNextHops)
        : false,
    };
  },

  toJSON(message: IpRouteTable): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.prefix !== "") {
      obj.prefix = message.prefix;
    }
    if (message.prefixLen !== 0) {
      obj.prefixLen = Math.round(message.prefixLen);
    }
    if (message.deprecatedProtocol !== "") {
      obj.deprecatedProtocol = message.deprecatedProtocol;
    }
    if (message.vrfId !== 0) {
      obj.vrfId = Math.round(message.vrfId);
    }
    if (message.vrfName !== "") {
      obj.vrfName = message.vrfName;
    }
    if (message.selected !== false) {
      obj.selected = message.selected;
    }
    if (message.destSelected !== false) {
      obj.destSelected = message.destSelected;
    }
    if (message.distance !== 0) {
      obj.distance = Math.round(message.distance);
    }
    if (message.metric !== 0) {
      obj.metric = Math.round(message.metric);
    }
    if (message.installed !== false) {
      obj.installed = message.installed;
    }
    if (message.offloaded !== false) {
      obj.offloaded = message.offloaded;
    }
    if (message.table !== 0) {
      obj.table = Math.round(message.table);
    }
    if (message.internalStatus !== 0) {
      obj.internalStatus = Math.round(message.internalStatus);
    }
    if (message.internalFlags !== 0) {
      obj.internalFlags = Math.round(message.internalFlags);
    }
    if (message.internalNextHopNum !== 0) {
      obj.internalNextHopNum = Math.round(message.internalNextHopNum);
    }
    if (message.internalNextHopActiveNum !== 0) {
      obj.internalNextHopActiveNum = Math.round(message.internalNextHopActiveNum);
    }
    if (message.nextHopGroupId !== 0) {
      obj.nextHopGroupId = Math.round(message.nextHopGroupId);
    }
    if (message.installedNextHopGroupId !== 0) {
      obj.installedNextHopGroupId = Math.round(message.installedNextHopGroupId);
    }
    if (message.uptime !== "") {
      obj.uptime = message.uptime;
    }
    if (message.ipFamily !== 0) {
      obj.ipFamily = ipFamilyTypeToJSON(message.ipFamily);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    if (message.protocol !== 0) {
      obj.protocol = protocolTypeToJSON(message.protocol);
    }
    if (message.multipleDiffNextHops !== false) {
      obj.multipleDiffNextHops = message.multipleDiffNextHops;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<IpRouteTable>, I>>(base?: I): IpRouteTable {
    return IpRouteTable.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<IpRouteTable>, I>>(object: I): IpRouteTable {
    const message = createBaseIpRouteTable();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.prefix = object.prefix ?? "";
    message.prefixLen = object.prefixLen ?? 0;
    message.deprecatedProtocol = object.deprecatedProtocol ?? "";
    message.vrfId = object.vrfId ?? 0;
    message.vrfName = object.vrfName ?? "";
    message.selected = object.selected ?? false;
    message.destSelected = object.destSelected ?? false;
    message.distance = object.distance ?? 0;
    message.metric = object.metric ?? 0;
    message.installed = object.installed ?? false;
    message.offloaded = object.offloaded ?? false;
    message.table = object.table ?? 0;
    message.internalStatus = object.internalStatus ?? 0;
    message.internalFlags = object.internalFlags ?? 0;
    message.internalNextHopNum = object.internalNextHopNum ?? 0;
    message.internalNextHopActiveNum = object.internalNextHopActiveNum ?? 0;
    message.nextHopGroupId = object.nextHopGroupId ?? 0;
    message.installedNextHopGroupId = object.installedNextHopGroupId ?? 0;
    message.uptime = object.uptime ?? "";
    message.ipFamily = object.ipFamily ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    message.protocol = object.protocol ?? 0;
    message.multipleDiffNextHops = object.multipleDiffNextHops ?? false;
    return message;
  },
};

function createBaseVlanVniMap(): VlanVniMap {
  return { id: undefined, vni: 0, vlan: 0, collectedAt: undefined };
}

export const VlanVniMap = {
  encode(message: VlanVniMap, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.vni !== 0) {
      writer.uint32(24).uint32(message.vni);
    }
    if (message.vlan !== 0) {
      writer.uint32(32).uint32(message.vlan);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): VlanVniMap {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlanVniMap();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.vlan = reader.uint32();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VlanVniMap {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      vlan: isSet(object.vlan) ? globalThis.Number(object.vlan) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: VlanVniMap): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.vlan !== 0) {
      obj.vlan = Math.round(message.vlan);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<VlanVniMap>, I>>(base?: I): VlanVniMap {
    return VlanVniMap.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<VlanVniMap>, I>>(object: I): VlanVniMap {
    const message = createBaseVlanVniMap();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.vni = object.vni ?? 0;
    message.vlan = object.vlan ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseManagementConfig(): ManagementConfig {
  return {
    id: undefined,
    sonic: undefined,
    proxy: undefined,
    dns: undefined,
    drake: undefined,
    ntpServers: [],
    configSource: "",
    ipv4Method: "",
    ipv6Method: "",
  };
}

export const ManagementConfig = {
  encode(message: ManagementConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.sonic !== undefined) {
      SonicConfig.encode(message.sonic, writer.uint32(26).fork()).ldelim();
    }
    if (message.proxy !== undefined) {
      ProxyConfig.encode(message.proxy, writer.uint32(34).fork()).ldelim();
    }
    if (message.dns !== undefined) {
      DnsConfig.encode(message.dns, writer.uint32(42).fork()).ldelim();
    }
    if (message.drake !== undefined) {
      DrakeConfig.encode(message.drake, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.ntpServers) {
      writer.uint32(58).string(v!);
    }
    if (message.configSource !== "") {
      writer.uint32(66).string(message.configSource);
    }
    if (message.ipv4Method !== "") {
      writer.uint32(74).string(message.ipv4Method);
    }
    if (message.ipv6Method !== "") {
      writer.uint32(82).string(message.ipv6Method);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagementConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagementConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sonic = SonicConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proxy = ProxyConfig.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dns = DnsConfig.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.drake = DrakeConfig.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ntpServers.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.configSource = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ipv4Method = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ipv6Method = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagementConfig {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      sonic: isSet(object.sonic) ? SonicConfig.fromJSON(object.sonic) : undefined,
      proxy: isSet(object.proxy) ? ProxyConfig.fromJSON(object.proxy) : undefined,
      dns: isSet(object.dns) ? DnsConfig.fromJSON(object.dns) : undefined,
      drake: isSet(object.drake) ? DrakeConfig.fromJSON(object.drake) : undefined,
      ntpServers: globalThis.Array.isArray(object?.ntpServers)
        ? object.ntpServers.map((e: any) => globalThis.String(e))
        : [],
      configSource: isSet(object.configSource) ? globalThis.String(object.configSource) : "",
      ipv4Method: isSet(object.ipv4Method) ? globalThis.String(object.ipv4Method) : "",
      ipv6Method: isSet(object.ipv6Method) ? globalThis.String(object.ipv6Method) : "",
    };
  },

  toJSON(message: ManagementConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.sonic !== undefined) {
      obj.sonic = SonicConfig.toJSON(message.sonic);
    }
    if (message.proxy !== undefined) {
      obj.proxy = ProxyConfig.toJSON(message.proxy);
    }
    if (message.dns !== undefined) {
      obj.dns = DnsConfig.toJSON(message.dns);
    }
    if (message.drake !== undefined) {
      obj.drake = DrakeConfig.toJSON(message.drake);
    }
    if (message.ntpServers?.length) {
      obj.ntpServers = message.ntpServers;
    }
    if (message.configSource !== "") {
      obj.configSource = message.configSource;
    }
    if (message.ipv4Method !== "") {
      obj.ipv4Method = message.ipv4Method;
    }
    if (message.ipv6Method !== "") {
      obj.ipv6Method = message.ipv6Method;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagementConfig>, I>>(base?: I): ManagementConfig {
    return ManagementConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagementConfig>, I>>(object: I): ManagementConfig {
    const message = createBaseManagementConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.sonic = (object.sonic !== undefined && object.sonic !== null)
      ? SonicConfig.fromPartial(object.sonic)
      : undefined;
    message.proxy = (object.proxy !== undefined && object.proxy !== null)
      ? ProxyConfig.fromPartial(object.proxy)
      : undefined;
    message.dns = (object.dns !== undefined && object.dns !== null) ? DnsConfig.fromPartial(object.dns) : undefined;
    message.drake = (object.drake !== undefined && object.drake !== null)
      ? DrakeConfig.fromPartial(object.drake)
      : undefined;
    message.ntpServers = object.ntpServers?.map((e) => e) || [];
    message.configSource = object.configSource ?? "";
    message.ipv4Method = object.ipv4Method ?? "";
    message.ipv6Method = object.ipv6Method ?? "";
    return message;
  },
};

function createBaseManagementState(): ManagementState {
  return {
    id: undefined,
    sonic: undefined,
    proxy: undefined,
    dns: undefined,
    drake: undefined,
    ntpServers: [],
    configSource: "",
    ipv4Method: "",
    ipv6Method: "",
    operationStatus: 0,
    collectedAt: undefined,
  };
}

export const ManagementState = {
  encode(message: ManagementState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.sonic !== undefined) {
      SonicConfig.encode(message.sonic, writer.uint32(26).fork()).ldelim();
    }
    if (message.proxy !== undefined) {
      ProxyConfig.encode(message.proxy, writer.uint32(34).fork()).ldelim();
    }
    if (message.dns !== undefined) {
      DnsConfig.encode(message.dns, writer.uint32(42).fork()).ldelim();
    }
    if (message.drake !== undefined) {
      DrakeConfig.encode(message.drake, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.ntpServers) {
      writer.uint32(58).string(v!);
    }
    if (message.configSource !== "") {
      writer.uint32(66).string(message.configSource);
    }
    if (message.ipv4Method !== "") {
      writer.uint32(74).string(message.ipv4Method);
    }
    if (message.ipv6Method !== "") {
      writer.uint32(82).string(message.ipv6Method);
    }
    if (message.operationStatus !== 0) {
      writer.uint32(88).int32(message.operationStatus);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagementState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagementState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sonic = SonicConfig.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.proxy = ProxyConfig.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.dns = DnsConfig.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.drake = DrakeConfig.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ntpServers.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.configSource = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.ipv4Method = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ipv6Method = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.operationStatus = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagementState {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      sonic: isSet(object.sonic) ? SonicConfig.fromJSON(object.sonic) : undefined,
      proxy: isSet(object.proxy) ? ProxyConfig.fromJSON(object.proxy) : undefined,
      dns: isSet(object.dns) ? DnsConfig.fromJSON(object.dns) : undefined,
      drake: isSet(object.drake) ? DrakeConfig.fromJSON(object.drake) : undefined,
      ntpServers: globalThis.Array.isArray(object?.ntpServers)
        ? object.ntpServers.map((e: any) => globalThis.String(e))
        : [],
      configSource: isSet(object.configSource) ? globalThis.String(object.configSource) : "",
      ipv4Method: isSet(object.ipv4Method) ? globalThis.String(object.ipv4Method) : "",
      ipv6Method: isSet(object.ipv6Method) ? globalThis.String(object.ipv6Method) : "",
      operationStatus: isSet(object.operationStatus) ? operationStatusFromJSON(object.operationStatus) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ManagementState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.sonic !== undefined) {
      obj.sonic = SonicConfig.toJSON(message.sonic);
    }
    if (message.proxy !== undefined) {
      obj.proxy = ProxyConfig.toJSON(message.proxy);
    }
    if (message.dns !== undefined) {
      obj.dns = DnsConfig.toJSON(message.dns);
    }
    if (message.drake !== undefined) {
      obj.drake = DrakeConfig.toJSON(message.drake);
    }
    if (message.ntpServers?.length) {
      obj.ntpServers = message.ntpServers;
    }
    if (message.configSource !== "") {
      obj.configSource = message.configSource;
    }
    if (message.ipv4Method !== "") {
      obj.ipv4Method = message.ipv4Method;
    }
    if (message.ipv6Method !== "") {
      obj.ipv6Method = message.ipv6Method;
    }
    if (message.operationStatus !== 0) {
      obj.operationStatus = operationStatusToJSON(message.operationStatus);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagementState>, I>>(base?: I): ManagementState {
    return ManagementState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagementState>, I>>(object: I): ManagementState {
    const message = createBaseManagementState();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.sonic = (object.sonic !== undefined && object.sonic !== null)
      ? SonicConfig.fromPartial(object.sonic)
      : undefined;
    message.proxy = (object.proxy !== undefined && object.proxy !== null)
      ? ProxyConfig.fromPartial(object.proxy)
      : undefined;
    message.dns = (object.dns !== undefined && object.dns !== null) ? DnsConfig.fromPartial(object.dns) : undefined;
    message.drake = (object.drake !== undefined && object.drake !== null)
      ? DrakeConfig.fromPartial(object.drake)
      : undefined;
    message.ntpServers = object.ntpServers?.map((e) => e) || [];
    message.configSource = object.configSource ?? "";
    message.ipv4Method = object.ipv4Method ?? "";
    message.ipv6Method = object.ipv6Method ?? "";
    message.operationStatus = object.operationStatus ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseBgpNeighborFamily(): BgpNeighborFamily {
  return { id: undefined };
}

export const BgpNeighborFamily = {
  encode(message: BgpNeighborFamily, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpNeighborFamily {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpNeighborFamily();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpNeighborFamily {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: BgpNeighborFamily): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpNeighborFamily>, I>>(base?: I): BgpNeighborFamily {
    return BgpNeighborFamily.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpNeighborFamily>, I>>(object: I): BgpNeighborFamily {
    const message = createBaseBgpNeighborFamily();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseBgpNeighborSummary(): BgpNeighborSummary {
  return {
    id: undefined,
    routerId: "",
    as: 0,
    vrfId: 0,
    vrfName: "",
    tableVersion: 0,
    ribCount: 0,
    ribMemory: 0,
    peerCount: 0,
    peerMemory: 0,
    peerGroupCount: 0,
    peerGroupMemory: 0,
    peerName: "",
    peerHostname: "",
    peerRemoteAs: 0,
    peerLocalAs: 0,
    peerTableVersion: 0,
    peerMsgRcvd: 0,
    peerMsgSent: 0,
    peerOutQ: 0,
    peerInQ: 0,
    peerUptime: "",
    peerUptimeMsec: 0,
    peerUptimeEstablishedEpoch: 0,
    peerPfxRcd: 0,
    peerPfxSnt: 0,
    peerConnectionState: 0,
    peerState: 0,
    peerConnectionsEstablished: 0,
    peerConnectionsDropped: 0,
    peerIdType: 0,
    failedPeers: 0,
    displayedPeers: 0,
    totalPeers: 0,
    dynamicPeers: 0,
    addressFamily: 0,
    multiPathRelax: false,
    peerVersion: 0,
    collectedAt: undefined,
  };
}

export const BgpNeighborSummary = {
  encode(message: BgpNeighborSummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.routerId !== "") {
      writer.uint32(26).string(message.routerId);
    }
    if (message.as !== 0) {
      writer.uint32(32).uint32(message.as);
    }
    if (message.vrfId !== 0) {
      writer.uint32(40).uint32(message.vrfId);
    }
    if (message.vrfName !== "") {
      writer.uint32(50).string(message.vrfName);
    }
    if (message.tableVersion !== 0) {
      writer.uint32(56).uint32(message.tableVersion);
    }
    if (message.ribCount !== 0) {
      writer.uint32(64).uint32(message.ribCount);
    }
    if (message.ribMemory !== 0) {
      writer.uint32(72).uint32(message.ribMemory);
    }
    if (message.peerCount !== 0) {
      writer.uint32(80).uint32(message.peerCount);
    }
    if (message.peerMemory !== 0) {
      writer.uint32(88).uint32(message.peerMemory);
    }
    if (message.peerGroupCount !== 0) {
      writer.uint32(96).uint32(message.peerGroupCount);
    }
    if (message.peerGroupMemory !== 0) {
      writer.uint32(104).uint32(message.peerGroupMemory);
    }
    if (message.peerName !== "") {
      writer.uint32(114).string(message.peerName);
    }
    if (message.peerHostname !== "") {
      writer.uint32(122).string(message.peerHostname);
    }
    if (message.peerRemoteAs !== 0) {
      writer.uint32(128).uint32(message.peerRemoteAs);
    }
    if (message.peerLocalAs !== 0) {
      writer.uint32(136).uint32(message.peerLocalAs);
    }
    if (message.peerTableVersion !== 0) {
      writer.uint32(144).uint32(message.peerTableVersion);
    }
    if (message.peerMsgRcvd !== 0) {
      writer.uint32(152).uint32(message.peerMsgRcvd);
    }
    if (message.peerMsgSent !== 0) {
      writer.uint32(160).uint32(message.peerMsgSent);
    }
    if (message.peerOutQ !== 0) {
      writer.uint32(168).uint32(message.peerOutQ);
    }
    if (message.peerInQ !== 0) {
      writer.uint32(176).uint32(message.peerInQ);
    }
    if (message.peerUptime !== "") {
      writer.uint32(186).string(message.peerUptime);
    }
    if (message.peerUptimeMsec !== 0) {
      writer.uint32(192).uint32(message.peerUptimeMsec);
    }
    if (message.peerUptimeEstablishedEpoch !== 0) {
      writer.uint32(200).uint32(message.peerUptimeEstablishedEpoch);
    }
    if (message.peerPfxRcd !== 0) {
      writer.uint32(208).uint32(message.peerPfxRcd);
    }
    if (message.peerPfxSnt !== 0) {
      writer.uint32(216).uint32(message.peerPfxSnt);
    }
    if (message.peerConnectionState !== 0) {
      writer.uint32(224).int32(message.peerConnectionState);
    }
    if (message.peerState !== 0) {
      writer.uint32(232).int32(message.peerState);
    }
    if (message.peerConnectionsEstablished !== 0) {
      writer.uint32(240).uint32(message.peerConnectionsEstablished);
    }
    if (message.peerConnectionsDropped !== 0) {
      writer.uint32(248).uint32(message.peerConnectionsDropped);
    }
    if (message.peerIdType !== 0) {
      writer.uint32(256).int32(message.peerIdType);
    }
    if (message.failedPeers !== 0) {
      writer.uint32(264).uint32(message.failedPeers);
    }
    if (message.displayedPeers !== 0) {
      writer.uint32(272).uint32(message.displayedPeers);
    }
    if (message.totalPeers !== 0) {
      writer.uint32(280).uint32(message.totalPeers);
    }
    if (message.dynamicPeers !== 0) {
      writer.uint32(288).uint32(message.dynamicPeers);
    }
    if (message.addressFamily !== 0) {
      writer.uint32(296).int32(message.addressFamily);
    }
    if (message.multiPathRelax !== false) {
      writer.uint32(304).bool(message.multiPathRelax);
    }
    if (message.peerVersion !== 0) {
      writer.uint32(312).uint32(message.peerVersion);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(322).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpNeighborSummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpNeighborSummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.routerId = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.as = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.vrfId = reader.uint32();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.vrfName = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.tableVersion = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.ribCount = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.ribMemory = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.peerCount = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.peerMemory = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.peerGroupCount = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.peerGroupMemory = reader.uint32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.peerName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.peerHostname = reader.string();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.peerRemoteAs = reader.uint32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.peerLocalAs = reader.uint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.peerTableVersion = reader.uint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.peerMsgRcvd = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.peerMsgSent = reader.uint32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.peerOutQ = reader.uint32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.peerInQ = reader.uint32();
          continue;
        case 23:
          if (tag !== 186) {
            break;
          }

          message.peerUptime = reader.string();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.peerUptimeMsec = reader.uint32();
          continue;
        case 25:
          if (tag !== 200) {
            break;
          }

          message.peerUptimeEstablishedEpoch = reader.uint32();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.peerPfxRcd = reader.uint32();
          continue;
        case 27:
          if (tag !== 216) {
            break;
          }

          message.peerPfxSnt = reader.uint32();
          continue;
        case 28:
          if (tag !== 224) {
            break;
          }

          message.peerConnectionState = reader.int32() as any;
          continue;
        case 29:
          if (tag !== 232) {
            break;
          }

          message.peerState = reader.int32() as any;
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.peerConnectionsEstablished = reader.uint32();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.peerConnectionsDropped = reader.uint32();
          continue;
        case 32:
          if (tag !== 256) {
            break;
          }

          message.peerIdType = reader.int32() as any;
          continue;
        case 33:
          if (tag !== 264) {
            break;
          }

          message.failedPeers = reader.uint32();
          continue;
        case 34:
          if (tag !== 272) {
            break;
          }

          message.displayedPeers = reader.uint32();
          continue;
        case 35:
          if (tag !== 280) {
            break;
          }

          message.totalPeers = reader.uint32();
          continue;
        case 36:
          if (tag !== 288) {
            break;
          }

          message.dynamicPeers = reader.uint32();
          continue;
        case 37:
          if (tag !== 296) {
            break;
          }

          message.addressFamily = reader.int32() as any;
          continue;
        case 38:
          if (tag !== 304) {
            break;
          }

          message.multiPathRelax = reader.bool();
          continue;
        case 39:
          if (tag !== 312) {
            break;
          }

          message.peerVersion = reader.uint32();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpNeighborSummary {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      routerId: isSet(object.routerId) ? globalThis.String(object.routerId) : "",
      as: isSet(object.as) ? globalThis.Number(object.as) : 0,
      vrfId: isSet(object.vrfId) ? globalThis.Number(object.vrfId) : 0,
      vrfName: isSet(object.vrfName) ? globalThis.String(object.vrfName) : "",
      tableVersion: isSet(object.tableVersion) ? globalThis.Number(object.tableVersion) : 0,
      ribCount: isSet(object.ribCount) ? globalThis.Number(object.ribCount) : 0,
      ribMemory: isSet(object.ribMemory) ? globalThis.Number(object.ribMemory) : 0,
      peerCount: isSet(object.peerCount) ? globalThis.Number(object.peerCount) : 0,
      peerMemory: isSet(object.peerMemory) ? globalThis.Number(object.peerMemory) : 0,
      peerGroupCount: isSet(object.peerGroupCount) ? globalThis.Number(object.peerGroupCount) : 0,
      peerGroupMemory: isSet(object.peerGroupMemory) ? globalThis.Number(object.peerGroupMemory) : 0,
      peerName: isSet(object.peerName) ? globalThis.String(object.peerName) : "",
      peerHostname: isSet(object.peerHostname) ? globalThis.String(object.peerHostname) : "",
      peerRemoteAs: isSet(object.peerRemoteAs) ? globalThis.Number(object.peerRemoteAs) : 0,
      peerLocalAs: isSet(object.peerLocalAs) ? globalThis.Number(object.peerLocalAs) : 0,
      peerTableVersion: isSet(object.peerTableVersion) ? globalThis.Number(object.peerTableVersion) : 0,
      peerMsgRcvd: isSet(object.peerMsgRcvd) ? globalThis.Number(object.peerMsgRcvd) : 0,
      peerMsgSent: isSet(object.peerMsgSent) ? globalThis.Number(object.peerMsgSent) : 0,
      peerOutQ: isSet(object.peerOutQ) ? globalThis.Number(object.peerOutQ) : 0,
      peerInQ: isSet(object.peerInQ) ? globalThis.Number(object.peerInQ) : 0,
      peerUptime: isSet(object.peerUptime) ? globalThis.String(object.peerUptime) : "",
      peerUptimeMsec: isSet(object.peerUptimeMsec) ? globalThis.Number(object.peerUptimeMsec) : 0,
      peerUptimeEstablishedEpoch: isSet(object.peerUptimeEstablishedEpoch)
        ? globalThis.Number(object.peerUptimeEstablishedEpoch)
        : 0,
      peerPfxRcd: isSet(object.peerPfxRcd) ? globalThis.Number(object.peerPfxRcd) : 0,
      peerPfxSnt: isSet(object.peerPfxSnt) ? globalThis.Number(object.peerPfxSnt) : 0,
      peerConnectionState: isSet(object.peerConnectionState)
        ? bgpPeerConnectionStateFromJSON(object.peerConnectionState)
        : 0,
      peerState: isSet(object.peerState) ? bgpPeerStateFromJSON(object.peerState) : 0,
      peerConnectionsEstablished: isSet(object.peerConnectionsEstablished)
        ? globalThis.Number(object.peerConnectionsEstablished)
        : 0,
      peerConnectionsDropped: isSet(object.peerConnectionsDropped)
        ? globalThis.Number(object.peerConnectionsDropped)
        : 0,
      peerIdType: isSet(object.peerIdType) ? bgpIdTypeFromJSON(object.peerIdType) : 0,
      failedPeers: isSet(object.failedPeers) ? globalThis.Number(object.failedPeers) : 0,
      displayedPeers: isSet(object.displayedPeers) ? globalThis.Number(object.displayedPeers) : 0,
      totalPeers: isSet(object.totalPeers) ? globalThis.Number(object.totalPeers) : 0,
      dynamicPeers: isSet(object.dynamicPeers) ? globalThis.Number(object.dynamicPeers) : 0,
      addressFamily: isSet(object.addressFamily) ? bgpAddressFamilyFromJSON(object.addressFamily) : 0,
      multiPathRelax: isSet(object.multiPathRelax) ? globalThis.Boolean(object.multiPathRelax) : false,
      peerVersion: isSet(object.peerVersion) ? globalThis.Number(object.peerVersion) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: BgpNeighborSummary): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.routerId !== "") {
      obj.routerId = message.routerId;
    }
    if (message.as !== 0) {
      obj.as = Math.round(message.as);
    }
    if (message.vrfId !== 0) {
      obj.vrfId = Math.round(message.vrfId);
    }
    if (message.vrfName !== "") {
      obj.vrfName = message.vrfName;
    }
    if (message.tableVersion !== 0) {
      obj.tableVersion = Math.round(message.tableVersion);
    }
    if (message.ribCount !== 0) {
      obj.ribCount = Math.round(message.ribCount);
    }
    if (message.ribMemory !== 0) {
      obj.ribMemory = Math.round(message.ribMemory);
    }
    if (message.peerCount !== 0) {
      obj.peerCount = Math.round(message.peerCount);
    }
    if (message.peerMemory !== 0) {
      obj.peerMemory = Math.round(message.peerMemory);
    }
    if (message.peerGroupCount !== 0) {
      obj.peerGroupCount = Math.round(message.peerGroupCount);
    }
    if (message.peerGroupMemory !== 0) {
      obj.peerGroupMemory = Math.round(message.peerGroupMemory);
    }
    if (message.peerName !== "") {
      obj.peerName = message.peerName;
    }
    if (message.peerHostname !== "") {
      obj.peerHostname = message.peerHostname;
    }
    if (message.peerRemoteAs !== 0) {
      obj.peerRemoteAs = Math.round(message.peerRemoteAs);
    }
    if (message.peerLocalAs !== 0) {
      obj.peerLocalAs = Math.round(message.peerLocalAs);
    }
    if (message.peerTableVersion !== 0) {
      obj.peerTableVersion = Math.round(message.peerTableVersion);
    }
    if (message.peerMsgRcvd !== 0) {
      obj.peerMsgRcvd = Math.round(message.peerMsgRcvd);
    }
    if (message.peerMsgSent !== 0) {
      obj.peerMsgSent = Math.round(message.peerMsgSent);
    }
    if (message.peerOutQ !== 0) {
      obj.peerOutQ = Math.round(message.peerOutQ);
    }
    if (message.peerInQ !== 0) {
      obj.peerInQ = Math.round(message.peerInQ);
    }
    if (message.peerUptime !== "") {
      obj.peerUptime = message.peerUptime;
    }
    if (message.peerUptimeMsec !== 0) {
      obj.peerUptimeMsec = Math.round(message.peerUptimeMsec);
    }
    if (message.peerUptimeEstablishedEpoch !== 0) {
      obj.peerUptimeEstablishedEpoch = Math.round(message.peerUptimeEstablishedEpoch);
    }
    if (message.peerPfxRcd !== 0) {
      obj.peerPfxRcd = Math.round(message.peerPfxRcd);
    }
    if (message.peerPfxSnt !== 0) {
      obj.peerPfxSnt = Math.round(message.peerPfxSnt);
    }
    if (message.peerConnectionState !== 0) {
      obj.peerConnectionState = bgpPeerConnectionStateToJSON(message.peerConnectionState);
    }
    if (message.peerState !== 0) {
      obj.peerState = bgpPeerStateToJSON(message.peerState);
    }
    if (message.peerConnectionsEstablished !== 0) {
      obj.peerConnectionsEstablished = Math.round(message.peerConnectionsEstablished);
    }
    if (message.peerConnectionsDropped !== 0) {
      obj.peerConnectionsDropped = Math.round(message.peerConnectionsDropped);
    }
    if (message.peerIdType !== 0) {
      obj.peerIdType = bgpIdTypeToJSON(message.peerIdType);
    }
    if (message.failedPeers !== 0) {
      obj.failedPeers = Math.round(message.failedPeers);
    }
    if (message.displayedPeers !== 0) {
      obj.displayedPeers = Math.round(message.displayedPeers);
    }
    if (message.totalPeers !== 0) {
      obj.totalPeers = Math.round(message.totalPeers);
    }
    if (message.dynamicPeers !== 0) {
      obj.dynamicPeers = Math.round(message.dynamicPeers);
    }
    if (message.addressFamily !== 0) {
      obj.addressFamily = bgpAddressFamilyToJSON(message.addressFamily);
    }
    if (message.multiPathRelax !== false) {
      obj.multiPathRelax = message.multiPathRelax;
    }
    if (message.peerVersion !== 0) {
      obj.peerVersion = Math.round(message.peerVersion);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpNeighborSummary>, I>>(base?: I): BgpNeighborSummary {
    return BgpNeighborSummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpNeighborSummary>, I>>(object: I): BgpNeighborSummary {
    const message = createBaseBgpNeighborSummary();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.routerId = object.routerId ?? "";
    message.as = object.as ?? 0;
    message.vrfId = object.vrfId ?? 0;
    message.vrfName = object.vrfName ?? "";
    message.tableVersion = object.tableVersion ?? 0;
    message.ribCount = object.ribCount ?? 0;
    message.ribMemory = object.ribMemory ?? 0;
    message.peerCount = object.peerCount ?? 0;
    message.peerMemory = object.peerMemory ?? 0;
    message.peerGroupCount = object.peerGroupCount ?? 0;
    message.peerGroupMemory = object.peerGroupMemory ?? 0;
    message.peerName = object.peerName ?? "";
    message.peerHostname = object.peerHostname ?? "";
    message.peerRemoteAs = object.peerRemoteAs ?? 0;
    message.peerLocalAs = object.peerLocalAs ?? 0;
    message.peerTableVersion = object.peerTableVersion ?? 0;
    message.peerMsgRcvd = object.peerMsgRcvd ?? 0;
    message.peerMsgSent = object.peerMsgSent ?? 0;
    message.peerOutQ = object.peerOutQ ?? 0;
    message.peerInQ = object.peerInQ ?? 0;
    message.peerUptime = object.peerUptime ?? "";
    message.peerUptimeMsec = object.peerUptimeMsec ?? 0;
    message.peerUptimeEstablishedEpoch = object.peerUptimeEstablishedEpoch ?? 0;
    message.peerPfxRcd = object.peerPfxRcd ?? 0;
    message.peerPfxSnt = object.peerPfxSnt ?? 0;
    message.peerConnectionState = object.peerConnectionState ?? 0;
    message.peerState = object.peerState ?? 0;
    message.peerConnectionsEstablished = object.peerConnectionsEstablished ?? 0;
    message.peerConnectionsDropped = object.peerConnectionsDropped ?? 0;
    message.peerIdType = object.peerIdType ?? 0;
    message.failedPeers = object.failedPeers ?? 0;
    message.displayedPeers = object.displayedPeers ?? 0;
    message.totalPeers = object.totalPeers ?? 0;
    message.dynamicPeers = object.dynamicPeers ?? 0;
    message.addressFamily = object.addressFamily ?? 0;
    message.multiPathRelax = object.multiPathRelax ?? false;
    message.peerVersion = object.peerVersion ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseSystemStats(): SystemStats {
  return {
    id: undefined,
    CpuUsed: 0,
    CpuIdle: 0,
    MemTotal: 0,
    MemUsed: 0,
    MemFree: 0,
    DiskTotal: 0,
    DiskUsed: 0,
    DiskFree: 0,
    collectedAt: undefined,
  };
}

export const SystemStats = {
  encode(message: SystemStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.CpuUsed !== 0) {
      writer.uint32(29).float(message.CpuUsed);
    }
    if (message.CpuIdle !== 0) {
      writer.uint32(37).float(message.CpuIdle);
    }
    if (message.MemTotal !== 0) {
      writer.uint32(40).int64(message.MemTotal);
    }
    if (message.MemUsed !== 0) {
      writer.uint32(48).int64(message.MemUsed);
    }
    if (message.MemFree !== 0) {
      writer.uint32(56).int64(message.MemFree);
    }
    if (message.DiskTotal !== 0) {
      writer.uint32(64).int64(message.DiskTotal);
    }
    if (message.DiskUsed !== 0) {
      writer.uint32(72).int64(message.DiskUsed);
    }
    if (message.DiskFree !== 0) {
      writer.uint32(80).int64(message.DiskFree);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SystemStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSystemStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.CpuUsed = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.CpuIdle = reader.float();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.MemTotal = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.MemUsed = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.MemFree = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.DiskTotal = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.DiskUsed = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.DiskFree = longToNumber(reader.int64() as Long);
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SystemStats {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      CpuUsed: isSet(object.CpuUsed) ? globalThis.Number(object.CpuUsed) : 0,
      CpuIdle: isSet(object.CpuIdle) ? globalThis.Number(object.CpuIdle) : 0,
      MemTotal: isSet(object.MemTotal) ? globalThis.Number(object.MemTotal) : 0,
      MemUsed: isSet(object.MemUsed) ? globalThis.Number(object.MemUsed) : 0,
      MemFree: isSet(object.MemFree) ? globalThis.Number(object.MemFree) : 0,
      DiskTotal: isSet(object.DiskTotal) ? globalThis.Number(object.DiskTotal) : 0,
      DiskUsed: isSet(object.DiskUsed) ? globalThis.Number(object.DiskUsed) : 0,
      DiskFree: isSet(object.DiskFree) ? globalThis.Number(object.DiskFree) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: SystemStats): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.CpuUsed !== 0) {
      obj.CpuUsed = message.CpuUsed;
    }
    if (message.CpuIdle !== 0) {
      obj.CpuIdle = message.CpuIdle;
    }
    if (message.MemTotal !== 0) {
      obj.MemTotal = Math.round(message.MemTotal);
    }
    if (message.MemUsed !== 0) {
      obj.MemUsed = Math.round(message.MemUsed);
    }
    if (message.MemFree !== 0) {
      obj.MemFree = Math.round(message.MemFree);
    }
    if (message.DiskTotal !== 0) {
      obj.DiskTotal = Math.round(message.DiskTotal);
    }
    if (message.DiskUsed !== 0) {
      obj.DiskUsed = Math.round(message.DiskUsed);
    }
    if (message.DiskFree !== 0) {
      obj.DiskFree = Math.round(message.DiskFree);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SystemStats>, I>>(base?: I): SystemStats {
    return SystemStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SystemStats>, I>>(object: I): SystemStats {
    const message = createBaseSystemStats();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.CpuUsed = object.CpuUsed ?? 0;
    message.CpuIdle = object.CpuIdle ?? 0;
    message.MemTotal = object.MemTotal ?? 0;
    message.MemUsed = object.MemUsed ?? 0;
    message.MemFree = object.MemFree ?? 0;
    message.DiskTotal = object.DiskTotal ?? 0;
    message.DiskUsed = object.DiskUsed ?? 0;
    message.DiskFree = object.DiskFree ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBasePortChannelMemberState(): PortChannelMemberState {
  return {
    id: undefined,
    name: "",
    memberInterfaceName: "",
    devAddr: "",
    ifIndex: 0,
    linkUp: false,
    linkWatchUp: false,
    actorLacpduInfoPort: 0,
    actorLacpduInfoState: 0,
    actorLacpduInfoSystem: "",
    partnerLacpduInfoPort: 0,
    partnerLacpduInfoState: 0,
    partnerLacpduInfoSystem: "",
    aggregatorId: 0,
    aggregatorSelected: false,
    selected: false,
    state: 0,
    protocol: 0,
    collectedAt: undefined,
  };
}

export const PortChannelMemberState = {
  encode(message: PortChannelMemberState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.memberInterfaceName !== "") {
      writer.uint32(34).string(message.memberInterfaceName);
    }
    if (message.devAddr !== "") {
      writer.uint32(42).string(message.devAddr);
    }
    if (message.ifIndex !== 0) {
      writer.uint32(48).int32(message.ifIndex);
    }
    if (message.linkUp !== false) {
      writer.uint32(56).bool(message.linkUp);
    }
    if (message.linkWatchUp !== false) {
      writer.uint32(64).bool(message.linkWatchUp);
    }
    if (message.actorLacpduInfoPort !== 0) {
      writer.uint32(72).int32(message.actorLacpduInfoPort);
    }
    if (message.actorLacpduInfoState !== 0) {
      writer.uint32(80).int32(message.actorLacpduInfoState);
    }
    if (message.actorLacpduInfoSystem !== "") {
      writer.uint32(90).string(message.actorLacpduInfoSystem);
    }
    if (message.partnerLacpduInfoPort !== 0) {
      writer.uint32(96).int32(message.partnerLacpduInfoPort);
    }
    if (message.partnerLacpduInfoState !== 0) {
      writer.uint32(104).int32(message.partnerLacpduInfoState);
    }
    if (message.partnerLacpduInfoSystem !== "") {
      writer.uint32(114).string(message.partnerLacpduInfoSystem);
    }
    if (message.aggregatorId !== 0) {
      writer.uint32(120).int32(message.aggregatorId);
    }
    if (message.aggregatorSelected !== false) {
      writer.uint32(128).bool(message.aggregatorSelected);
    }
    if (message.selected !== false) {
      writer.uint32(136).bool(message.selected);
    }
    if (message.state !== 0) {
      writer.uint32(144).int32(message.state);
    }
    if (message.protocol !== 0) {
      writer.uint32(152).int32(message.protocol);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortChannelMemberState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortChannelMemberState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.memberInterfaceName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.devAddr = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.ifIndex = reader.int32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.linkUp = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.linkWatchUp = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.actorLacpduInfoPort = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.actorLacpduInfoState = reader.int32();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.actorLacpduInfoSystem = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.partnerLacpduInfoPort = reader.int32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.partnerLacpduInfoState = reader.int32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.partnerLacpduInfoSystem = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.aggregatorId = reader.int32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.aggregatorSelected = reader.bool();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.selected = reader.bool();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.protocol = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortChannelMemberState {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      memberInterfaceName: isSet(object.memberInterfaceName) ? globalThis.String(object.memberInterfaceName) : "",
      devAddr: isSet(object.devAddr) ? globalThis.String(object.devAddr) : "",
      ifIndex: isSet(object.ifIndex) ? globalThis.Number(object.ifIndex) : 0,
      linkUp: isSet(object.linkUp) ? globalThis.Boolean(object.linkUp) : false,
      linkWatchUp: isSet(object.linkWatchUp) ? globalThis.Boolean(object.linkWatchUp) : false,
      actorLacpduInfoPort: isSet(object.actorLacpduInfoPort) ? globalThis.Number(object.actorLacpduInfoPort) : 0,
      actorLacpduInfoState: isSet(object.actorLacpduInfoState) ? globalThis.Number(object.actorLacpduInfoState) : 0,
      actorLacpduInfoSystem: isSet(object.actorLacpduInfoSystem) ? globalThis.String(object.actorLacpduInfoSystem) : "",
      partnerLacpduInfoPort: isSet(object.partnerLacpduInfoPort) ? globalThis.Number(object.partnerLacpduInfoPort) : 0,
      partnerLacpduInfoState: isSet(object.partnerLacpduInfoState)
        ? globalThis.Number(object.partnerLacpduInfoState)
        : 0,
      partnerLacpduInfoSystem: isSet(object.partnerLacpduInfoSystem)
        ? globalThis.String(object.partnerLacpduInfoSystem)
        : "",
      aggregatorId: isSet(object.aggregatorId) ? globalThis.Number(object.aggregatorId) : 0,
      aggregatorSelected: isSet(object.aggregatorSelected) ? globalThis.Boolean(object.aggregatorSelected) : false,
      selected: isSet(object.selected) ? globalThis.Boolean(object.selected) : false,
      state: isSet(object.state) ? portChannelMemberInfoStateFromJSON(object.state) : 0,
      protocol: isSet(object.protocol) ? portChannelProtocolFromJSON(object.protocol) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: PortChannelMemberState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.memberInterfaceName !== "") {
      obj.memberInterfaceName = message.memberInterfaceName;
    }
    if (message.devAddr !== "") {
      obj.devAddr = message.devAddr;
    }
    if (message.ifIndex !== 0) {
      obj.ifIndex = Math.round(message.ifIndex);
    }
    if (message.linkUp !== false) {
      obj.linkUp = message.linkUp;
    }
    if (message.linkWatchUp !== false) {
      obj.linkWatchUp = message.linkWatchUp;
    }
    if (message.actorLacpduInfoPort !== 0) {
      obj.actorLacpduInfoPort = Math.round(message.actorLacpduInfoPort);
    }
    if (message.actorLacpduInfoState !== 0) {
      obj.actorLacpduInfoState = Math.round(message.actorLacpduInfoState);
    }
    if (message.actorLacpduInfoSystem !== "") {
      obj.actorLacpduInfoSystem = message.actorLacpduInfoSystem;
    }
    if (message.partnerLacpduInfoPort !== 0) {
      obj.partnerLacpduInfoPort = Math.round(message.partnerLacpduInfoPort);
    }
    if (message.partnerLacpduInfoState !== 0) {
      obj.partnerLacpduInfoState = Math.round(message.partnerLacpduInfoState);
    }
    if (message.partnerLacpduInfoSystem !== "") {
      obj.partnerLacpduInfoSystem = message.partnerLacpduInfoSystem;
    }
    if (message.aggregatorId !== 0) {
      obj.aggregatorId = Math.round(message.aggregatorId);
    }
    if (message.aggregatorSelected !== false) {
      obj.aggregatorSelected = message.aggregatorSelected;
    }
    if (message.selected !== false) {
      obj.selected = message.selected;
    }
    if (message.state !== 0) {
      obj.state = portChannelMemberInfoStateToJSON(message.state);
    }
    if (message.protocol !== 0) {
      obj.protocol = portChannelProtocolToJSON(message.protocol);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortChannelMemberState>, I>>(base?: I): PortChannelMemberState {
    return PortChannelMemberState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortChannelMemberState>, I>>(object: I): PortChannelMemberState {
    const message = createBasePortChannelMemberState();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.name = object.name ?? "";
    message.memberInterfaceName = object.memberInterfaceName ?? "";
    message.devAddr = object.devAddr ?? "";
    message.ifIndex = object.ifIndex ?? 0;
    message.linkUp = object.linkUp ?? false;
    message.linkWatchUp = object.linkWatchUp ?? false;
    message.actorLacpduInfoPort = object.actorLacpduInfoPort ?? 0;
    message.actorLacpduInfoState = object.actorLacpduInfoState ?? 0;
    message.actorLacpduInfoSystem = object.actorLacpduInfoSystem ?? "";
    message.partnerLacpduInfoPort = object.partnerLacpduInfoPort ?? 0;
    message.partnerLacpduInfoState = object.partnerLacpduInfoState ?? 0;
    message.partnerLacpduInfoSystem = object.partnerLacpduInfoSystem ?? "";
    message.aggregatorId = object.aggregatorId ?? 0;
    message.aggregatorSelected = object.aggregatorSelected ?? false;
    message.selected = object.selected ?? false;
    message.state = object.state ?? 0;
    message.protocol = object.protocol ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBasePortChannelState(): PortChannelState {
  return {
    id: undefined,
    name: "",
    adminStatus: 0,
    mtu: 0,
    operStatus: 0,
    state: 0,
    runnerActive: false,
    runnerFallback: false,
    runnerFastRate: false,
    setupKernelTeamModeName: 0,
    setupPid: 0,
    teamDeviceIfInfoDevAddr: "",
    teamDeviceIfInfoIfIndex: 0,
    protocol: 0,
    collectedAt: undefined,
  };
}

export const PortChannelState = {
  encode(message: PortChannelState, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.adminStatus !== 0) {
      writer.uint32(32).int32(message.adminStatus);
    }
    if (message.mtu !== 0) {
      writer.uint32(40).uint32(message.mtu);
    }
    if (message.operStatus !== 0) {
      writer.uint32(48).int32(message.operStatus);
    }
    if (message.state !== 0) {
      writer.uint32(56).int32(message.state);
    }
    if (message.runnerActive !== false) {
      writer.uint32(64).bool(message.runnerActive);
    }
    if (message.runnerFallback !== false) {
      writer.uint32(72).bool(message.runnerFallback);
    }
    if (message.runnerFastRate !== false) {
      writer.uint32(80).bool(message.runnerFastRate);
    }
    if (message.setupKernelTeamModeName !== 0) {
      writer.uint32(88).int32(message.setupKernelTeamModeName);
    }
    if (message.setupPid !== 0) {
      writer.uint32(96).int32(message.setupPid);
    }
    if (message.teamDeviceIfInfoDevAddr !== "") {
      writer.uint32(106).string(message.teamDeviceIfInfoDevAddr);
    }
    if (message.teamDeviceIfInfoIfIndex !== 0) {
      writer.uint32(112).int32(message.teamDeviceIfInfoIfIndex);
    }
    if (message.protocol !== 0) {
      writer.uint32(120).int32(message.protocol);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(130).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortChannelState {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortChannelState();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.adminStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.mtu = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.operStatus = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.runnerActive = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.runnerFallback = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.runnerFastRate = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.setupKernelTeamModeName = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.setupPid = reader.int32();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.teamDeviceIfInfoDevAddr = reader.string();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.teamDeviceIfInfoIfIndex = reader.int32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.protocol = reader.int32() as any;
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortChannelState {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      adminStatus: isSet(object.adminStatus) ? operStateFromJSON(object.adminStatus) : 0,
      mtu: isSet(object.mtu) ? globalThis.Number(object.mtu) : 0,
      operStatus: isSet(object.operStatus) ? operStateFromJSON(object.operStatus) : 0,
      state: isSet(object.state) ? portChannelStatusFromJSON(object.state) : 0,
      runnerActive: isSet(object.runnerActive) ? globalThis.Boolean(object.runnerActive) : false,
      runnerFallback: isSet(object.runnerFallback) ? globalThis.Boolean(object.runnerFallback) : false,
      runnerFastRate: isSet(object.runnerFastRate) ? globalThis.Boolean(object.runnerFastRate) : false,
      setupKernelTeamModeName: isSet(object.setupKernelTeamModeName)
        ? portChannelKernelTeamModeFromJSON(object.setupKernelTeamModeName)
        : 0,
      setupPid: isSet(object.setupPid) ? globalThis.Number(object.setupPid) : 0,
      teamDeviceIfInfoDevAddr: isSet(object.teamDeviceIfInfoDevAddr)
        ? globalThis.String(object.teamDeviceIfInfoDevAddr)
        : "",
      teamDeviceIfInfoIfIndex: isSet(object.teamDeviceIfInfoIfIndex)
        ? globalThis.Number(object.teamDeviceIfInfoIfIndex)
        : 0,
      protocol: isSet(object.protocol) ? portChannelProtocolFromJSON(object.protocol) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: PortChannelState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.adminStatus !== 0) {
      obj.adminStatus = operStateToJSON(message.adminStatus);
    }
    if (message.mtu !== 0) {
      obj.mtu = Math.round(message.mtu);
    }
    if (message.operStatus !== 0) {
      obj.operStatus = operStateToJSON(message.operStatus);
    }
    if (message.state !== 0) {
      obj.state = portChannelStatusToJSON(message.state);
    }
    if (message.runnerActive !== false) {
      obj.runnerActive = message.runnerActive;
    }
    if (message.runnerFallback !== false) {
      obj.runnerFallback = message.runnerFallback;
    }
    if (message.runnerFastRate !== false) {
      obj.runnerFastRate = message.runnerFastRate;
    }
    if (message.setupKernelTeamModeName !== 0) {
      obj.setupKernelTeamModeName = portChannelKernelTeamModeToJSON(message.setupKernelTeamModeName);
    }
    if (message.setupPid !== 0) {
      obj.setupPid = Math.round(message.setupPid);
    }
    if (message.teamDeviceIfInfoDevAddr !== "") {
      obj.teamDeviceIfInfoDevAddr = message.teamDeviceIfInfoDevAddr;
    }
    if (message.teamDeviceIfInfoIfIndex !== 0) {
      obj.teamDeviceIfInfoIfIndex = Math.round(message.teamDeviceIfInfoIfIndex);
    }
    if (message.protocol !== 0) {
      obj.protocol = portChannelProtocolToJSON(message.protocol);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortChannelState>, I>>(base?: I): PortChannelState {
    return PortChannelState.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortChannelState>, I>>(object: I): PortChannelState {
    const message = createBasePortChannelState();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.name = object.name ?? "";
    message.adminStatus = object.adminStatus ?? 0;
    message.mtu = object.mtu ?? 0;
    message.operStatus = object.operStatus ?? 0;
    message.state = object.state ?? 0;
    message.runnerActive = object.runnerActive ?? false;
    message.runnerFallback = object.runnerFallback ?? false;
    message.runnerFastRate = object.runnerFastRate ?? false;
    message.setupKernelTeamModeName = object.setupKernelTeamModeName ?? 0;
    message.setupPid = object.setupPid ?? 0;
    message.teamDeviceIfInfoDevAddr = object.teamDeviceIfInfoDevAddr ?? "";
    message.teamDeviceIfInfoIfIndex = object.teamDeviceIfInfoIfIndex ?? 0;
    message.protocol = object.protocol ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseOrgUuid(): OrgUuid {
  return { id: undefined };
}

export const OrgUuid = {
  encode(message: OrgUuid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgUuid {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgUuid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgUuid {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: OrgUuid): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgUuid>, I>>(base?: I): OrgUuid {
    return OrgUuid.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgUuid>, I>>(object: I): OrgUuid {
    const message = createBaseOrgUuid();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseFabricUuid(): FabricUuid {
  return { id: undefined };
}

export const FabricUuid = {
  encode(message: FabricUuid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricUuid {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricUuid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricUuid {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: FabricUuid): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricUuid>, I>>(base?: I): FabricUuid {
    return FabricUuid.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricUuid>, I>>(object: I): FabricUuid {
    const message = createBaseFabricUuid();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseOrgServiceSvc(): OrgServiceSvc {
  return { id: undefined };
}

export const OrgServiceSvc = {
  encode(message: OrgServiceSvc, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgServiceSvc {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgServiceSvc();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgServiceSvc {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: OrgServiceSvc): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgServiceSvc>, I>>(base?: I): OrgServiceSvc {
    return OrgServiceSvc.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgServiceSvc>, I>>(object: I): OrgServiceSvc {
    const message = createBaseOrgServiceSvc();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseOrgServiceProto(): OrgServiceProto {
  return { id: undefined };
}

export const OrgServiceProto = {
  encode(message: OrgServiceProto, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgServiceProto {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgServiceProto();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgServiceProto {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: OrgServiceProto): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgServiceProto>, I>>(base?: I): OrgServiceProto {
    return OrgServiceProto.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgServiceProto>, I>>(object: I): OrgServiceProto {
    const message = createBaseOrgServiceProto();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseOrgServiceRoute(): OrgServiceRoute {
  return {
    id: undefined,
    orgUuid: "",
    fabricUuid: "",
    svc: "",
    proto: "",
    host: "",
    port: 0,
    isLocal: false,
    revision: 0,
    orgKind: 0,
    fabricType: 0,
    updatedTs: undefined,
  };
}

export const OrgServiceRoute = {
  encode(message: OrgServiceRoute, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.orgUuid !== "") {
      writer.uint32(26).string(message.orgUuid);
    }
    if (message.fabricUuid !== "") {
      writer.uint32(34).string(message.fabricUuid);
    }
    if (message.svc !== "") {
      writer.uint32(42).string(message.svc);
    }
    if (message.proto !== "") {
      writer.uint32(50).string(message.proto);
    }
    if (message.host !== "") {
      writer.uint32(58).string(message.host);
    }
    if (message.port !== 0) {
      writer.uint32(64).int32(message.port);
    }
    if (message.isLocal !== false) {
      writer.uint32(72).bool(message.isLocal);
    }
    if (message.revision !== 0) {
      writer.uint32(80).uint32(message.revision);
    }
    if (message.orgKind !== 0) {
      writer.uint32(88).int32(message.orgKind);
    }
    if (message.fabricType !== 0) {
      writer.uint32(96).int32(message.fabricType);
    }
    if (message.updatedTs !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedTs), writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgServiceRoute {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgServiceRoute();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgUuid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricUuid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.svc = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.proto = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.host = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.port = reader.int32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.isLocal = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.revision = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.orgKind = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.fabricType = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.updatedTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgServiceRoute {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgUuid: isSet(object.orgUuid) ? globalThis.String(object.orgUuid) : "",
      fabricUuid: isSet(object.fabricUuid) ? globalThis.String(object.fabricUuid) : "",
      svc: isSet(object.svc) ? globalThis.String(object.svc) : "",
      proto: isSet(object.proto) ? globalThis.String(object.proto) : "",
      host: isSet(object.host) ? globalThis.String(object.host) : "",
      port: isSet(object.port) ? globalThis.Number(object.port) : 0,
      isLocal: isSet(object.isLocal) ? globalThis.Boolean(object.isLocal) : false,
      revision: isSet(object.revision) ? globalThis.Number(object.revision) : 0,
      orgKind: isSet(object.orgKind) ? orgKindFromJSON(object.orgKind) : 0,
      fabricType: isSet(object.fabricType) ? fabricTypeFromJSON(object.fabricType) : 0,
      updatedTs: isSet(object.updatedTs) ? fromJsonTimestamp(object.updatedTs) : undefined,
    };
  },

  toJSON(message: OrgServiceRoute): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgUuid !== "") {
      obj.orgUuid = message.orgUuid;
    }
    if (message.fabricUuid !== "") {
      obj.fabricUuid = message.fabricUuid;
    }
    if (message.svc !== "") {
      obj.svc = message.svc;
    }
    if (message.proto !== "") {
      obj.proto = message.proto;
    }
    if (message.host !== "") {
      obj.host = message.host;
    }
    if (message.port !== 0) {
      obj.port = Math.round(message.port);
    }
    if (message.isLocal !== false) {
      obj.isLocal = message.isLocal;
    }
    if (message.revision !== 0) {
      obj.revision = Math.round(message.revision);
    }
    if (message.orgKind !== 0) {
      obj.orgKind = orgKindToJSON(message.orgKind);
    }
    if (message.fabricType !== 0) {
      obj.fabricType = fabricTypeToJSON(message.fabricType);
    }
    if (message.updatedTs !== undefined) {
      obj.updatedTs = message.updatedTs.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgServiceRoute>, I>>(base?: I): OrgServiceRoute {
    return OrgServiceRoute.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgServiceRoute>, I>>(object: I): OrgServiceRoute {
    const message = createBaseOrgServiceRoute();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgUuid = object.orgUuid ?? "";
    message.fabricUuid = object.fabricUuid ?? "";
    message.svc = object.svc ?? "";
    message.proto = object.proto ?? "";
    message.host = object.host ?? "";
    message.port = object.port ?? 0;
    message.isLocal = object.isLocal ?? false;
    message.revision = object.revision ?? 0;
    message.orgKind = object.orgKind ?? 0;
    message.fabricType = object.fabricType ?? 0;
    message.updatedTs = object.updatedTs ?? undefined;
    return message;
  },
};

function createBaseOrgToCellPairMapping(): OrgToCellPairMapping {
  return {
    id: undefined,
    orgUuid: "",
    cellPairUuid: "",
    cellASvcHost: "",
    cellBSvcHost: "",
    generation: 0,
    orgKind: 0,
    updatedTs: undefined,
  };
}

export const OrgToCellPairMapping = {
  encode(message: OrgToCellPairMapping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.orgUuid !== "") {
      writer.uint32(26).string(message.orgUuid);
    }
    if (message.cellPairUuid !== "") {
      writer.uint32(34).string(message.cellPairUuid);
    }
    if (message.cellASvcHost !== "") {
      writer.uint32(42).string(message.cellASvcHost);
    }
    if (message.cellBSvcHost !== "") {
      writer.uint32(50).string(message.cellBSvcHost);
    }
    if (message.generation !== 0) {
      writer.uint32(56).int32(message.generation);
    }
    if (message.orgKind !== 0) {
      writer.uint32(64).int32(message.orgKind);
    }
    if (message.updatedTs !== undefined) {
      Timestamp.encode(toTimestamp(message.updatedTs), writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OrgToCellPairMapping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrgToCellPairMapping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgUuid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cellPairUuid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.cellASvcHost = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.cellBSvcHost = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.generation = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.orgKind = reader.int32() as any;
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.updatedTs = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OrgToCellPairMapping {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgUuid: isSet(object.orgUuid) ? globalThis.String(object.orgUuid) : "",
      cellPairUuid: isSet(object.cellPairUuid) ? globalThis.String(object.cellPairUuid) : "",
      cellASvcHost: isSet(object.cellASvcHost) ? globalThis.String(object.cellASvcHost) : "",
      cellBSvcHost: isSet(object.cellBSvcHost) ? globalThis.String(object.cellBSvcHost) : "",
      generation: isSet(object.generation) ? globalThis.Number(object.generation) : 0,
      orgKind: isSet(object.orgKind) ? orgKindFromJSON(object.orgKind) : 0,
      updatedTs: isSet(object.updatedTs) ? fromJsonTimestamp(object.updatedTs) : undefined,
    };
  },

  toJSON(message: OrgToCellPairMapping): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgUuid !== "") {
      obj.orgUuid = message.orgUuid;
    }
    if (message.cellPairUuid !== "") {
      obj.cellPairUuid = message.cellPairUuid;
    }
    if (message.cellASvcHost !== "") {
      obj.cellASvcHost = message.cellASvcHost;
    }
    if (message.cellBSvcHost !== "") {
      obj.cellBSvcHost = message.cellBSvcHost;
    }
    if (message.generation !== 0) {
      obj.generation = Math.round(message.generation);
    }
    if (message.orgKind !== 0) {
      obj.orgKind = orgKindToJSON(message.orgKind);
    }
    if (message.updatedTs !== undefined) {
      obj.updatedTs = message.updatedTs.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<OrgToCellPairMapping>, I>>(base?: I): OrgToCellPairMapping {
    return OrgToCellPairMapping.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<OrgToCellPairMapping>, I>>(object: I): OrgToCellPairMapping {
    const message = createBaseOrgToCellPairMapping();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgUuid = object.orgUuid ?? "";
    message.cellPairUuid = object.cellPairUuid ?? "";
    message.cellASvcHost = object.cellASvcHost ?? "";
    message.cellBSvcHost = object.cellBSvcHost ?? "";
    message.generation = object.generation ?? 0;
    message.orgKind = object.orgKind ?? 0;
    message.updatedTs = object.updatedTs ?? undefined;
    return message;
  },
};

function createBaseQsfpInfo(): QsfpInfo {
  return {
    id: undefined,
    activeApselHostlane1: false,
    activeApselHostlane2: false,
    activeApselHostlane3: false,
    activeApselHostlane4: false,
    activeApselHostlane5: false,
    activeApselHostlane6: false,
    activeApselHostlane7: false,
    activeApselHostlane8: false,
    activeFirmware: "",
    applicationAdvertisement: "",
    cableLength: 0,
    cableType: "",
    cmisRev: 0,
    connector: "",
    domCapability: "",
    encoding: "",
    extIdentifier: "",
    extRateselectCompliance: "",
    hardwareRev: 0,
    hostElectricalInterface: "",
    hostLaneAssignmentOption: 0,
    hostLaneCount: 0,
    inactiveFirmware: "",
    isReplaceable: false,
    manufacturer: "",
    mediaInterfaceCode: "",
    mediaInterfaceTechnology: "",
    mediaLaneAssignmentOption: 0,
    mediaLaneCount: 0,
    model: "",
    nominalBitRate: 0,
    serial: "",
    specificationCompliance: "",
    supportedMaxLaserFreq: 0,
    supportedMaxTxPower: 0,
    supportedMinLaserFreq: 0,
    supportedMinTxPower: 0,
    type: "",
    vendorDate: "",
    vendorOui: "",
    vendorRev: "",
    transceiverModule: 0,
    collectedAt: undefined,
  };
}

export const QsfpInfo = {
  encode(message: QsfpInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.activeApselHostlane1 !== false) {
      writer.uint32(24).bool(message.activeApselHostlane1);
    }
    if (message.activeApselHostlane2 !== false) {
      writer.uint32(32).bool(message.activeApselHostlane2);
    }
    if (message.activeApselHostlane3 !== false) {
      writer.uint32(40).bool(message.activeApselHostlane3);
    }
    if (message.activeApselHostlane4 !== false) {
      writer.uint32(48).bool(message.activeApselHostlane4);
    }
    if (message.activeApselHostlane5 !== false) {
      writer.uint32(56).bool(message.activeApselHostlane5);
    }
    if (message.activeApselHostlane6 !== false) {
      writer.uint32(64).bool(message.activeApselHostlane6);
    }
    if (message.activeApselHostlane7 !== false) {
      writer.uint32(72).bool(message.activeApselHostlane7);
    }
    if (message.activeApselHostlane8 !== false) {
      writer.uint32(80).bool(message.activeApselHostlane8);
    }
    if (message.activeFirmware !== "") {
      writer.uint32(90).string(message.activeFirmware);
    }
    if (message.applicationAdvertisement !== "") {
      writer.uint32(98).string(message.applicationAdvertisement);
    }
    if (message.cableLength !== 0) {
      writer.uint32(109).float(message.cableLength);
    }
    if (message.cableType !== "") {
      writer.uint32(114).string(message.cableType);
    }
    if (message.cmisRev !== 0) {
      writer.uint32(125).float(message.cmisRev);
    }
    if (message.connector !== "") {
      writer.uint32(130).string(message.connector);
    }
    if (message.domCapability !== "") {
      writer.uint32(138).string(message.domCapability);
    }
    if (message.encoding !== "") {
      writer.uint32(146).string(message.encoding);
    }
    if (message.extIdentifier !== "") {
      writer.uint32(154).string(message.extIdentifier);
    }
    if (message.extRateselectCompliance !== "") {
      writer.uint32(162).string(message.extRateselectCompliance);
    }
    if (message.hardwareRev !== 0) {
      writer.uint32(173).float(message.hardwareRev);
    }
    if (message.hostElectricalInterface !== "") {
      writer.uint32(178).string(message.hostElectricalInterface);
    }
    if (message.hostLaneAssignmentOption !== 0) {
      writer.uint32(184).int32(message.hostLaneAssignmentOption);
    }
    if (message.hostLaneCount !== 0) {
      writer.uint32(192).int32(message.hostLaneCount);
    }
    if (message.inactiveFirmware !== "") {
      writer.uint32(202).string(message.inactiveFirmware);
    }
    if (message.isReplaceable !== false) {
      writer.uint32(208).bool(message.isReplaceable);
    }
    if (message.manufacturer !== "") {
      writer.uint32(218).string(message.manufacturer);
    }
    if (message.mediaInterfaceCode !== "") {
      writer.uint32(226).string(message.mediaInterfaceCode);
    }
    if (message.mediaInterfaceTechnology !== "") {
      writer.uint32(234).string(message.mediaInterfaceTechnology);
    }
    if (message.mediaLaneAssignmentOption !== 0) {
      writer.uint32(240).int32(message.mediaLaneAssignmentOption);
    }
    if (message.mediaLaneCount !== 0) {
      writer.uint32(248).int32(message.mediaLaneCount);
    }
    if (message.model !== "") {
      writer.uint32(258).string(message.model);
    }
    if (message.nominalBitRate !== 0) {
      writer.uint32(269).float(message.nominalBitRate);
    }
    if (message.serial !== "") {
      writer.uint32(274).string(message.serial);
    }
    if (message.specificationCompliance !== "") {
      writer.uint32(282).string(message.specificationCompliance);
    }
    if (message.supportedMaxLaserFreq !== 0) {
      writer.uint32(293).float(message.supportedMaxLaserFreq);
    }
    if (message.supportedMaxTxPower !== 0) {
      writer.uint32(301).float(message.supportedMaxTxPower);
    }
    if (message.supportedMinLaserFreq !== 0) {
      writer.uint32(309).float(message.supportedMinLaserFreq);
    }
    if (message.supportedMinTxPower !== 0) {
      writer.uint32(317).float(message.supportedMinTxPower);
    }
    if (message.type !== "") {
      writer.uint32(322).string(message.type);
    }
    if (message.vendorDate !== "") {
      writer.uint32(330).string(message.vendorDate);
    }
    if (message.vendorOui !== "") {
      writer.uint32(338).string(message.vendorOui);
    }
    if (message.vendorRev !== "") {
      writer.uint32(346).string(message.vendorRev);
    }
    if (message.transceiverModule !== 0) {
      writer.uint32(352).int32(message.transceiverModule);
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(482).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): QsfpInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQsfpInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.activeApselHostlane1 = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.activeApselHostlane2 = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.activeApselHostlane3 = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.activeApselHostlane4 = reader.bool();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.activeApselHostlane5 = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.activeApselHostlane6 = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.activeApselHostlane7 = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.activeApselHostlane8 = reader.bool();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.activeFirmware = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.applicationAdvertisement = reader.string();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.cableLength = reader.float();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.cableType = reader.string();
          continue;
        case 15:
          if (tag !== 125) {
            break;
          }

          message.cmisRev = reader.float();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.connector = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.domCapability = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.encoding = reader.string();
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.extIdentifier = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.extRateselectCompliance = reader.string();
          continue;
        case 21:
          if (tag !== 173) {
            break;
          }

          message.hardwareRev = reader.float();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.hostElectricalInterface = reader.string();
          continue;
        case 23:
          if (tag !== 184) {
            break;
          }

          message.hostLaneAssignmentOption = reader.int32();
          continue;
        case 24:
          if (tag !== 192) {
            break;
          }

          message.hostLaneCount = reader.int32();
          continue;
        case 25:
          if (tag !== 202) {
            break;
          }

          message.inactiveFirmware = reader.string();
          continue;
        case 26:
          if (tag !== 208) {
            break;
          }

          message.isReplaceable = reader.bool();
          continue;
        case 27:
          if (tag !== 218) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 28:
          if (tag !== 226) {
            break;
          }

          message.mediaInterfaceCode = reader.string();
          continue;
        case 29:
          if (tag !== 234) {
            break;
          }

          message.mediaInterfaceTechnology = reader.string();
          continue;
        case 30:
          if (tag !== 240) {
            break;
          }

          message.mediaLaneAssignmentOption = reader.int32();
          continue;
        case 31:
          if (tag !== 248) {
            break;
          }

          message.mediaLaneCount = reader.int32();
          continue;
        case 32:
          if (tag !== 258) {
            break;
          }

          message.model = reader.string();
          continue;
        case 33:
          if (tag !== 269) {
            break;
          }

          message.nominalBitRate = reader.float();
          continue;
        case 34:
          if (tag !== 274) {
            break;
          }

          message.serial = reader.string();
          continue;
        case 35:
          if (tag !== 282) {
            break;
          }

          message.specificationCompliance = reader.string();
          continue;
        case 36:
          if (tag !== 293) {
            break;
          }

          message.supportedMaxLaserFreq = reader.float();
          continue;
        case 37:
          if (tag !== 301) {
            break;
          }

          message.supportedMaxTxPower = reader.float();
          continue;
        case 38:
          if (tag !== 309) {
            break;
          }

          message.supportedMinLaserFreq = reader.float();
          continue;
        case 39:
          if (tag !== 317) {
            break;
          }

          message.supportedMinTxPower = reader.float();
          continue;
        case 40:
          if (tag !== 322) {
            break;
          }

          message.type = reader.string();
          continue;
        case 41:
          if (tag !== 330) {
            break;
          }

          message.vendorDate = reader.string();
          continue;
        case 42:
          if (tag !== 338) {
            break;
          }

          message.vendorOui = reader.string();
          continue;
        case 43:
          if (tag !== 346) {
            break;
          }

          message.vendorRev = reader.string();
          continue;
        case 44:
          if (tag !== 352) {
            break;
          }

          message.transceiverModule = reader.int32() as any;
          continue;
        case 60:
          if (tag !== 482) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): QsfpInfo {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      activeApselHostlane1: isSet(object.activeApselHostlane1)
        ? globalThis.Boolean(object.activeApselHostlane1)
        : false,
      activeApselHostlane2: isSet(object.activeApselHostlane2)
        ? globalThis.Boolean(object.activeApselHostlane2)
        : false,
      activeApselHostlane3: isSet(object.activeApselHostlane3)
        ? globalThis.Boolean(object.activeApselHostlane3)
        : false,
      activeApselHostlane4: isSet(object.activeApselHostlane4)
        ? globalThis.Boolean(object.activeApselHostlane4)
        : false,
      activeApselHostlane5: isSet(object.activeApselHostlane5)
        ? globalThis.Boolean(object.activeApselHostlane5)
        : false,
      activeApselHostlane6: isSet(object.activeApselHostlane6)
        ? globalThis.Boolean(object.activeApselHostlane6)
        : false,
      activeApselHostlane7: isSet(object.activeApselHostlane7)
        ? globalThis.Boolean(object.activeApselHostlane7)
        : false,
      activeApselHostlane8: isSet(object.activeApselHostlane8)
        ? globalThis.Boolean(object.activeApselHostlane8)
        : false,
      activeFirmware: isSet(object.activeFirmware) ? globalThis.String(object.activeFirmware) : "",
      applicationAdvertisement: isSet(object.applicationAdvertisement)
        ? globalThis.String(object.applicationAdvertisement)
        : "",
      cableLength: isSet(object.cableLength) ? globalThis.Number(object.cableLength) : 0,
      cableType: isSet(object.cableType) ? globalThis.String(object.cableType) : "",
      cmisRev: isSet(object.cmisRev) ? globalThis.Number(object.cmisRev) : 0,
      connector: isSet(object.connector) ? globalThis.String(object.connector) : "",
      domCapability: isSet(object.domCapability) ? globalThis.String(object.domCapability) : "",
      encoding: isSet(object.encoding) ? globalThis.String(object.encoding) : "",
      extIdentifier: isSet(object.extIdentifier) ? globalThis.String(object.extIdentifier) : "",
      extRateselectCompliance: isSet(object.extRateselectCompliance)
        ? globalThis.String(object.extRateselectCompliance)
        : "",
      hardwareRev: isSet(object.hardwareRev) ? globalThis.Number(object.hardwareRev) : 0,
      hostElectricalInterface: isSet(object.hostElectricalInterface)
        ? globalThis.String(object.hostElectricalInterface)
        : "",
      hostLaneAssignmentOption: isSet(object.hostLaneAssignmentOption)
        ? globalThis.Number(object.hostLaneAssignmentOption)
        : 0,
      hostLaneCount: isSet(object.hostLaneCount) ? globalThis.Number(object.hostLaneCount) : 0,
      inactiveFirmware: isSet(object.inactiveFirmware) ? globalThis.String(object.inactiveFirmware) : "",
      isReplaceable: isSet(object.isReplaceable) ? globalThis.Boolean(object.isReplaceable) : false,
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      mediaInterfaceCode: isSet(object.mediaInterfaceCode) ? globalThis.String(object.mediaInterfaceCode) : "",
      mediaInterfaceTechnology: isSet(object.mediaInterfaceTechnology)
        ? globalThis.String(object.mediaInterfaceTechnology)
        : "",
      mediaLaneAssignmentOption: isSet(object.mediaLaneAssignmentOption)
        ? globalThis.Number(object.mediaLaneAssignmentOption)
        : 0,
      mediaLaneCount: isSet(object.mediaLaneCount) ? globalThis.Number(object.mediaLaneCount) : 0,
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      nominalBitRate: isSet(object.nominalBitRate) ? globalThis.Number(object.nominalBitRate) : 0,
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      specificationCompliance: isSet(object.specificationCompliance)
        ? globalThis.String(object.specificationCompliance)
        : "",
      supportedMaxLaserFreq: isSet(object.supportedMaxLaserFreq) ? globalThis.Number(object.supportedMaxLaserFreq) : 0,
      supportedMaxTxPower: isSet(object.supportedMaxTxPower) ? globalThis.Number(object.supportedMaxTxPower) : 0,
      supportedMinLaserFreq: isSet(object.supportedMinLaserFreq) ? globalThis.Number(object.supportedMinLaserFreq) : 0,
      supportedMinTxPower: isSet(object.supportedMinTxPower) ? globalThis.Number(object.supportedMinTxPower) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      vendorDate: isSet(object.vendorDate) ? globalThis.String(object.vendorDate) : "",
      vendorOui: isSet(object.vendorOui) ? globalThis.String(object.vendorOui) : "",
      vendorRev: isSet(object.vendorRev) ? globalThis.String(object.vendorRev) : "",
      transceiverModule: isSet(object.transceiverModule) ? qsfpTransceiverModuleFromJSON(object.transceiverModule) : 0,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: QsfpInfo): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.activeApselHostlane1 !== false) {
      obj.activeApselHostlane1 = message.activeApselHostlane1;
    }
    if (message.activeApselHostlane2 !== false) {
      obj.activeApselHostlane2 = message.activeApselHostlane2;
    }
    if (message.activeApselHostlane3 !== false) {
      obj.activeApselHostlane3 = message.activeApselHostlane3;
    }
    if (message.activeApselHostlane4 !== false) {
      obj.activeApselHostlane4 = message.activeApselHostlane4;
    }
    if (message.activeApselHostlane5 !== false) {
      obj.activeApselHostlane5 = message.activeApselHostlane5;
    }
    if (message.activeApselHostlane6 !== false) {
      obj.activeApselHostlane6 = message.activeApselHostlane6;
    }
    if (message.activeApselHostlane7 !== false) {
      obj.activeApselHostlane7 = message.activeApselHostlane7;
    }
    if (message.activeApselHostlane8 !== false) {
      obj.activeApselHostlane8 = message.activeApselHostlane8;
    }
    if (message.activeFirmware !== "") {
      obj.activeFirmware = message.activeFirmware;
    }
    if (message.applicationAdvertisement !== "") {
      obj.applicationAdvertisement = message.applicationAdvertisement;
    }
    if (message.cableLength !== 0) {
      obj.cableLength = message.cableLength;
    }
    if (message.cableType !== "") {
      obj.cableType = message.cableType;
    }
    if (message.cmisRev !== 0) {
      obj.cmisRev = message.cmisRev;
    }
    if (message.connector !== "") {
      obj.connector = message.connector;
    }
    if (message.domCapability !== "") {
      obj.domCapability = message.domCapability;
    }
    if (message.encoding !== "") {
      obj.encoding = message.encoding;
    }
    if (message.extIdentifier !== "") {
      obj.extIdentifier = message.extIdentifier;
    }
    if (message.extRateselectCompliance !== "") {
      obj.extRateselectCompliance = message.extRateselectCompliance;
    }
    if (message.hardwareRev !== 0) {
      obj.hardwareRev = message.hardwareRev;
    }
    if (message.hostElectricalInterface !== "") {
      obj.hostElectricalInterface = message.hostElectricalInterface;
    }
    if (message.hostLaneAssignmentOption !== 0) {
      obj.hostLaneAssignmentOption = Math.round(message.hostLaneAssignmentOption);
    }
    if (message.hostLaneCount !== 0) {
      obj.hostLaneCount = Math.round(message.hostLaneCount);
    }
    if (message.inactiveFirmware !== "") {
      obj.inactiveFirmware = message.inactiveFirmware;
    }
    if (message.isReplaceable !== false) {
      obj.isReplaceable = message.isReplaceable;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.mediaInterfaceCode !== "") {
      obj.mediaInterfaceCode = message.mediaInterfaceCode;
    }
    if (message.mediaInterfaceTechnology !== "") {
      obj.mediaInterfaceTechnology = message.mediaInterfaceTechnology;
    }
    if (message.mediaLaneAssignmentOption !== 0) {
      obj.mediaLaneAssignmentOption = Math.round(message.mediaLaneAssignmentOption);
    }
    if (message.mediaLaneCount !== 0) {
      obj.mediaLaneCount = Math.round(message.mediaLaneCount);
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.nominalBitRate !== 0) {
      obj.nominalBitRate = message.nominalBitRate;
    }
    if (message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.specificationCompliance !== "") {
      obj.specificationCompliance = message.specificationCompliance;
    }
    if (message.supportedMaxLaserFreq !== 0) {
      obj.supportedMaxLaserFreq = message.supportedMaxLaserFreq;
    }
    if (message.supportedMaxTxPower !== 0) {
      obj.supportedMaxTxPower = message.supportedMaxTxPower;
    }
    if (message.supportedMinLaserFreq !== 0) {
      obj.supportedMinLaserFreq = message.supportedMinLaserFreq;
    }
    if (message.supportedMinTxPower !== 0) {
      obj.supportedMinTxPower = message.supportedMinTxPower;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.vendorDate !== "") {
      obj.vendorDate = message.vendorDate;
    }
    if (message.vendorOui !== "") {
      obj.vendorOui = message.vendorOui;
    }
    if (message.vendorRev !== "") {
      obj.vendorRev = message.vendorRev;
    }
    if (message.transceiverModule !== 0) {
      obj.transceiverModule = qsfpTransceiverModuleToJSON(message.transceiverModule);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<QsfpInfo>, I>>(base?: I): QsfpInfo {
    return QsfpInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<QsfpInfo>, I>>(object: I): QsfpInfo {
    const message = createBaseQsfpInfo();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.activeApselHostlane1 = object.activeApselHostlane1 ?? false;
    message.activeApselHostlane2 = object.activeApselHostlane2 ?? false;
    message.activeApselHostlane3 = object.activeApselHostlane3 ?? false;
    message.activeApselHostlane4 = object.activeApselHostlane4 ?? false;
    message.activeApselHostlane5 = object.activeApselHostlane5 ?? false;
    message.activeApselHostlane6 = object.activeApselHostlane6 ?? false;
    message.activeApselHostlane7 = object.activeApselHostlane7 ?? false;
    message.activeApselHostlane8 = object.activeApselHostlane8 ?? false;
    message.activeFirmware = object.activeFirmware ?? "";
    message.applicationAdvertisement = object.applicationAdvertisement ?? "";
    message.cableLength = object.cableLength ?? 0;
    message.cableType = object.cableType ?? "";
    message.cmisRev = object.cmisRev ?? 0;
    message.connector = object.connector ?? "";
    message.domCapability = object.domCapability ?? "";
    message.encoding = object.encoding ?? "";
    message.extIdentifier = object.extIdentifier ?? "";
    message.extRateselectCompliance = object.extRateselectCompliance ?? "";
    message.hardwareRev = object.hardwareRev ?? 0;
    message.hostElectricalInterface = object.hostElectricalInterface ?? "";
    message.hostLaneAssignmentOption = object.hostLaneAssignmentOption ?? 0;
    message.hostLaneCount = object.hostLaneCount ?? 0;
    message.inactiveFirmware = object.inactiveFirmware ?? "";
    message.isReplaceable = object.isReplaceable ?? false;
    message.manufacturer = object.manufacturer ?? "";
    message.mediaInterfaceCode = object.mediaInterfaceCode ?? "";
    message.mediaInterfaceTechnology = object.mediaInterfaceTechnology ?? "";
    message.mediaLaneAssignmentOption = object.mediaLaneAssignmentOption ?? 0;
    message.mediaLaneCount = object.mediaLaneCount ?? 0;
    message.model = object.model ?? "";
    message.nominalBitRate = object.nominalBitRate ?? 0;
    message.serial = object.serial ?? "";
    message.specificationCompliance = object.specificationCompliance ?? "";
    message.supportedMaxLaserFreq = object.supportedMaxLaserFreq ?? 0;
    message.supportedMaxTxPower = object.supportedMaxTxPower ?? 0;
    message.supportedMinLaserFreq = object.supportedMinLaserFreq ?? 0;
    message.supportedMinTxPower = object.supportedMinTxPower ?? 0;
    message.type = object.type ?? "";
    message.vendorDate = object.vendorDate ?? "";
    message.vendorOui = object.vendorOui ?? "";
    message.vendorRev = object.vendorRev ?? "";
    message.transceiverModule = object.transceiverModule ?? 0;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseApiPath(): ApiPath {
  return { id: undefined };
}

export const ApiPath = {
  encode(message: ApiPath, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiPath {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiPath();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiPath {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: ApiPath): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiPath>, I>>(base?: I): ApiPath {
    return ApiPath.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiPath>, I>>(object: I): ApiPath {
    const message = createBaseApiPath();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseApiPathMethod(): ApiPathMethod {
  return { id: undefined };
}

export const ApiPathMethod = {
  encode(message: ApiPathMethod, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiPathMethod {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiPathMethod();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiPathMethod {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: ApiPathMethod): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiPathMethod>, I>>(base?: I): ApiPathMethod {
    return ApiPathMethod.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiPathMethod>, I>>(object: I): ApiPathMethod {
    const message = createBaseApiPathMethod();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseApiServiceMapping(): ApiServiceMapping {
  return { id: undefined, api: "", method: "", svc: "" };
}

export const ApiServiceMapping = {
  encode(message: ApiServiceMapping, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.api !== "") {
      writer.uint32(26).string(message.api);
    }
    if (message.method !== "") {
      writer.uint32(34).string(message.method);
    }
    if (message.svc !== "") {
      writer.uint32(42).string(message.svc);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiServiceMapping {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiServiceMapping();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.api = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.method = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.svc = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiServiceMapping {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      api: isSet(object.api) ? globalThis.String(object.api) : "",
      method: isSet(object.method) ? globalThis.String(object.method) : "",
      svc: isSet(object.svc) ? globalThis.String(object.svc) : "",
    };
  },

  toJSON(message: ApiServiceMapping): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.api !== "") {
      obj.api = message.api;
    }
    if (message.method !== "") {
      obj.method = message.method;
    }
    if (message.svc !== "") {
      obj.svc = message.svc;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiServiceMapping>, I>>(base?: I): ApiServiceMapping {
    return ApiServiceMapping.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiServiceMapping>, I>>(object: I): ApiServiceMapping {
    const message = createBaseApiServiceMapping();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.api = object.api ?? "";
    message.method = object.method ?? "";
    message.svc = object.svc ?? "";
    return message;
  },
};

function createBaseTenantUuid(): TenantUuid {
  return { id: undefined };
}

export const TenantUuid = {
  encode(message: TenantUuid, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantUuid {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantUuid();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantUuid {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: TenantUuid): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantUuid>, I>>(base?: I): TenantUuid {
    return TenantUuid.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantUuid>, I>>(object: I): TenantUuid {
    const message = createBaseTenantUuid();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseUserEmail(): UserEmail {
  return { id: undefined, orgUuid: "", tenantUuid: "", userEmail: "" };
}

export const UserEmail = {
  encode(message: UserEmail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.orgUuid !== "") {
      writer.uint32(26).string(message.orgUuid);
    }
    if (message.tenantUuid !== "") {
      writer.uint32(34).string(message.tenantUuid);
    }
    if (message.userEmail !== "") {
      writer.uint32(42).string(message.userEmail);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserEmail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserEmail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.orgUuid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenantUuid = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.userEmail = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UserEmail {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgUuid: isSet(object.orgUuid) ? globalThis.String(object.orgUuid) : "",
      tenantUuid: isSet(object.tenantUuid) ? globalThis.String(object.tenantUuid) : "",
      userEmail: isSet(object.userEmail) ? globalThis.String(object.userEmail) : "",
    };
  },

  toJSON(message: UserEmail): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgUuid !== "") {
      obj.orgUuid = message.orgUuid;
    }
    if (message.tenantUuid !== "") {
      obj.tenantUuid = message.tenantUuid;
    }
    if (message.userEmail !== "") {
      obj.userEmail = message.userEmail;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UserEmail>, I>>(base?: I): UserEmail {
    return UserEmail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserEmail>, I>>(object: I): UserEmail {
    const message = createBaseUserEmail();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgUuid = object.orgUuid ?? "";
    message.tenantUuid = object.tenantUuid ?? "";
    message.userEmail = object.userEmail ?? "";
    return message;
  },
};

function createBaseServiceInstanceHealth(): ServiceInstanceHealth {
  return {
    id: undefined,
    collectedAt: undefined,
    instanceUid: "",
    memoryTotal: 0,
    memoryFree: 0,
    cpuUserTime: 0,
    cpuSystemTime: 0,
  };
}

export const ServiceInstanceHealth = {
  encode(message: ServiceInstanceHealth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.instanceUid !== "") {
      writer.uint32(34).string(message.instanceUid);
    }
    if (message.memoryTotal !== 0) {
      writer.uint32(40).uint64(message.memoryTotal);
    }
    if (message.memoryFree !== 0) {
      writer.uint32(48).uint64(message.memoryFree);
    }
    if (message.cpuUserTime !== 0) {
      writer.uint32(57).double(message.cpuUserTime);
    }
    if (message.cpuSystemTime !== 0) {
      writer.uint32(65).double(message.cpuSystemTime);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServiceInstanceHealth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServiceInstanceHealth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.instanceUid = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.memoryTotal = longToNumber(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.memoryFree = longToNumber(reader.uint64() as Long);
          continue;
        case 7:
          if (tag !== 57) {
            break;
          }

          message.cpuUserTime = reader.double();
          continue;
        case 8:
          if (tag !== 65) {
            break;
          }

          message.cpuSystemTime = reader.double();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServiceInstanceHealth {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
      instanceUid: isSet(object.instanceUid) ? globalThis.String(object.instanceUid) : "",
      memoryTotal: isSet(object.memoryTotal) ? globalThis.Number(object.memoryTotal) : 0,
      memoryFree: isSet(object.memoryFree) ? globalThis.Number(object.memoryFree) : 0,
      cpuUserTime: isSet(object.cpuUserTime) ? globalThis.Number(object.cpuUserTime) : 0,
      cpuSystemTime: isSet(object.cpuSystemTime) ? globalThis.Number(object.cpuSystemTime) : 0,
    };
  },

  toJSON(message: ServiceInstanceHealth): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    if (message.instanceUid !== "") {
      obj.instanceUid = message.instanceUid;
    }
    if (message.memoryTotal !== 0) {
      obj.memoryTotal = Math.round(message.memoryTotal);
    }
    if (message.memoryFree !== 0) {
      obj.memoryFree = Math.round(message.memoryFree);
    }
    if (message.cpuUserTime !== 0) {
      obj.cpuUserTime = message.cpuUserTime;
    }
    if (message.cpuSystemTime !== 0) {
      obj.cpuSystemTime = message.cpuSystemTime;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServiceInstanceHealth>, I>>(base?: I): ServiceInstanceHealth {
    return ServiceInstanceHealth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServiceInstanceHealth>, I>>(object: I): ServiceInstanceHealth {
    const message = createBaseServiceInstanceHealth();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    message.instanceUid = object.instanceUid ?? "";
    message.memoryTotal = object.memoryTotal ?? 0;
    message.memoryFree = object.memoryFree ?? 0;
    message.cpuUserTime = object.cpuUserTime ?? 0;
    message.cpuSystemTime = object.cpuSystemTime ?? 0;
    return message;
  },
};

function createBaseAssertConfigLinkUpData(): AssertConfigLinkUpData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigLinkUpData = {
  encode(message: AssertConfigLinkUpData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigLinkUpData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigLinkUpData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigLinkUpData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigLinkUpData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigLinkUpData>, I>>(base?: I): AssertConfigLinkUpData {
    return AssertConfigLinkUpData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigLinkUpData>, I>>(object: I): AssertConfigLinkUpData {
    const message = createBaseAssertConfigLinkUpData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortLinkUp(): AssertConfigPortLinkUp {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortLinkUp = {
  encode(message: AssertConfigPortLinkUp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigLinkUpData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortLinkUp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortLinkUp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigLinkUpData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortLinkUp {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigLinkUpData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortLinkUp): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigLinkUpData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortLinkUp>, I>>(base?: I): AssertConfigPortLinkUp {
    return AssertConfigPortLinkUp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortLinkUp>, I>>(object: I): AssertConfigPortLinkUp {
    const message = createBaseAssertConfigPortLinkUp();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigLinkUpData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortLinkUp(): AssertPortLinkUp {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortLinkUp = {
  encode(message: AssertPortLinkUp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigLinkUpData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortLinkUp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortLinkUp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigLinkUpData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortLinkUp {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigLinkUpData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortLinkUp): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigLinkUpData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortLinkUp>, I>>(base?: I): AssertPortLinkUp {
    return AssertPortLinkUp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortLinkUp>, I>>(object: I): AssertPortLinkUp {
    const message = createBaseAssertPortLinkUp();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigLinkUpData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigLinkDownData(): AssertConfigLinkDownData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigLinkDownData = {
  encode(message: AssertConfigLinkDownData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigLinkDownData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigLinkDownData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigLinkDownData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigLinkDownData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigLinkDownData>, I>>(base?: I): AssertConfigLinkDownData {
    return AssertConfigLinkDownData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigLinkDownData>, I>>(object: I): AssertConfigLinkDownData {
    const message = createBaseAssertConfigLinkDownData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortLinkDown(): AssertConfigPortLinkDown {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortLinkDown = {
  encode(message: AssertConfigPortLinkDown, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigLinkDownData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortLinkDown {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortLinkDown();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigLinkDownData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortLinkDown {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigLinkDownData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortLinkDown): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigLinkDownData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortLinkDown>, I>>(base?: I): AssertConfigPortLinkDown {
    return AssertConfigPortLinkDown.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortLinkDown>, I>>(object: I): AssertConfigPortLinkDown {
    const message = createBaseAssertConfigPortLinkDown();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigLinkDownData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortLinkDown(): AssertPortLinkDown {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    remoteDeviceId: "",
    remotePortName: "",
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortLinkDown = {
  encode(message: AssertPortLinkDown, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigLinkDownData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(74).string(message.remoteDeviceId);
    }
    if (message.remotePortName !== "") {
      writer.uint32(82).string(message.remotePortName);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(88).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(96).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortLinkDown {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortLinkDown();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigLinkDownData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortLinkDown {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigLinkDownData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      remoteDeviceId: isSet(object.remoteDeviceId) ? globalThis.String(object.remoteDeviceId) : "",
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortLinkDown): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigLinkDownData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.remoteDeviceId !== "") {
      obj.remoteDeviceId = message.remoteDeviceId;
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortLinkDown>, I>>(base?: I): AssertPortLinkDown {
    return AssertPortLinkDown.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortLinkDown>, I>>(object: I): AssertPortLinkDown {
    const message = createBaseAssertPortLinkDown();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigLinkDownData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.remotePortName = object.remotePortName ?? "";
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigConnectedToCloudData(): AssertConfigConnectedToCloudData {
  return { generationId: 0, latchedAt: undefined, latchTime: 0, implicit: false, orgId: "", fabricId: "" };
}

export const AssertConfigConnectedToCloudData = {
  encode(message: AssertConfigConnectedToCloudData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(50).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigConnectedToCloudData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigConnectedToCloudData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigConnectedToCloudData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigConnectedToCloudData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigConnectedToCloudData>, I>>(
    base?: I,
  ): AssertConfigConnectedToCloudData {
    return AssertConfigConnectedToCloudData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigConnectedToCloudData>, I>>(
    object: I,
  ): AssertConfigConnectedToCloudData {
    const message = createBaseAssertConfigConnectedToCloudData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigDeviceConnectedToCloud(): AssertConfigDeviceConnectedToCloud {
  return { id: undefined, config: undefined };
}

export const AssertConfigDeviceConnectedToCloud = {
  encode(message: AssertConfigDeviceConnectedToCloud, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedToCloudData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigDeviceConnectedToCloud {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigDeviceConnectedToCloud();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigConnectedToCloudData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigDeviceConnectedToCloud {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedToCloudData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigDeviceConnectedToCloud): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedToCloudData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigDeviceConnectedToCloud>, I>>(
    base?: I,
  ): AssertConfigDeviceConnectedToCloud {
    return AssertConfigDeviceConnectedToCloud.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigDeviceConnectedToCloud>, I>>(
    object: I,
  ): AssertConfigDeviceConnectedToCloud {
    const message = createBaseAssertConfigDeviceConnectedToCloud();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedToCloudData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertDeviceConnectedToCloud(): AssertDeviceConnectedToCloud {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertDeviceConnectedToCloud = {
  encode(message: AssertDeviceConnectedToCloud, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedToCloudData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceConnectedToCloud {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceConnectedToCloud();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigConnectedToCloudData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceConnectedToCloud {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedToCloudData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertDeviceConnectedToCloud): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedToCloudData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceConnectedToCloud>, I>>(base?: I): AssertDeviceConnectedToCloud {
    return AssertDeviceConnectedToCloud.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceConnectedToCloud>, I>>(object: I): AssertDeviceConnectedToCloud {
    const message = createBaseAssertDeviceConnectedToCloud();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedToCloudData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigExpectedNeighborData(): AssertConfigExpectedNeighborData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigExpectedNeighborData = {
  encode(message: AssertConfigExpectedNeighborData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigExpectedNeighborData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigExpectedNeighborData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigExpectedNeighborData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigExpectedNeighborData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigExpectedNeighborData>, I>>(
    base?: I,
  ): AssertConfigExpectedNeighborData {
    return AssertConfigExpectedNeighborData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigExpectedNeighborData>, I>>(
    object: I,
  ): AssertConfigExpectedNeighborData {
    const message = createBaseAssertConfigExpectedNeighborData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortExpectedNeighbor(): AssertConfigPortExpectedNeighbor {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortExpectedNeighbor = {
  encode(message: AssertConfigPortExpectedNeighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigExpectedNeighborData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortExpectedNeighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortExpectedNeighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigExpectedNeighborData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortExpectedNeighbor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigExpectedNeighborData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortExpectedNeighbor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigExpectedNeighborData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortExpectedNeighbor>, I>>(
    base?: I,
  ): AssertConfigPortExpectedNeighbor {
    return AssertConfigPortExpectedNeighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortExpectedNeighbor>, I>>(
    object: I,
  ): AssertConfigPortExpectedNeighbor {
    const message = createBaseAssertConfigPortExpectedNeighbor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigExpectedNeighborData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortExpectedNeighbor(): AssertPortExpectedNeighbor {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    remoteDeviceId: "",
    remotePortName: "",
    numNeighbors: 0,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortExpectedNeighbor = {
  encode(message: AssertPortExpectedNeighbor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigExpectedNeighborData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(74).string(message.remoteDeviceId);
    }
    if (message.remotePortName !== "") {
      writer.uint32(82).string(message.remotePortName);
    }
    if (message.numNeighbors !== 0) {
      writer.uint32(88).int32(message.numNeighbors);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(96).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(104).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortExpectedNeighbor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortExpectedNeighbor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigExpectedNeighborData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.numNeighbors = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortExpectedNeighbor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigExpectedNeighborData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      remoteDeviceId: isSet(object.remoteDeviceId) ? globalThis.String(object.remoteDeviceId) : "",
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      numNeighbors: isSet(object.numNeighbors) ? globalThis.Number(object.numNeighbors) : 0,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortExpectedNeighbor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigExpectedNeighborData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.remoteDeviceId !== "") {
      obj.remoteDeviceId = message.remoteDeviceId;
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.numNeighbors !== 0) {
      obj.numNeighbors = Math.round(message.numNeighbors);
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortExpectedNeighbor>, I>>(base?: I): AssertPortExpectedNeighbor {
    return AssertPortExpectedNeighbor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortExpectedNeighbor>, I>>(object: I): AssertPortExpectedNeighbor {
    const message = createBaseAssertPortExpectedNeighbor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigExpectedNeighborData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.remotePortName = object.remotePortName ?? "";
    message.numNeighbors = object.numNeighbors ?? 0;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigNotConnectedToFabricData(): AssertConfigNotConnectedToFabricData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigNotConnectedToFabricData = {
  encode(message: AssertConfigNotConnectedToFabricData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigNotConnectedToFabricData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigNotConnectedToFabricData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigNotConnectedToFabricData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigNotConnectedToFabricData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigNotConnectedToFabricData>, I>>(
    base?: I,
  ): AssertConfigNotConnectedToFabricData {
    return AssertConfigNotConnectedToFabricData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigNotConnectedToFabricData>, I>>(
    object: I,
  ): AssertConfigNotConnectedToFabricData {
    const message = createBaseAssertConfigNotConnectedToFabricData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortNotConnectedToFabric(): AssertConfigPortNotConnectedToFabric {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortNotConnectedToFabric = {
  encode(message: AssertConfigPortNotConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigNotConnectedToFabricData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortNotConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortNotConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigNotConnectedToFabricData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortNotConnectedToFabric {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigNotConnectedToFabricData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortNotConnectedToFabric): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigNotConnectedToFabricData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortNotConnectedToFabric>, I>>(
    base?: I,
  ): AssertConfigPortNotConnectedToFabric {
    return AssertConfigPortNotConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortNotConnectedToFabric>, I>>(
    object: I,
  ): AssertConfigPortNotConnectedToFabric {
    const message = createBaseAssertConfigPortNotConnectedToFabric();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigNotConnectedToFabricData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortNotConnectedToFabric(): AssertPortNotConnectedToFabric {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    remoteDeviceId: "",
    remotePortName: "",
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortNotConnectedToFabric = {
  encode(message: AssertPortNotConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigNotConnectedToFabricData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(74).string(message.remoteDeviceId);
    }
    if (message.remotePortName !== "") {
      writer.uint32(82).string(message.remotePortName);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(88).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(96).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortNotConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortNotConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigNotConnectedToFabricData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortNotConnectedToFabric {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigNotConnectedToFabricData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      remoteDeviceId: isSet(object.remoteDeviceId) ? globalThis.String(object.remoteDeviceId) : "",
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortNotConnectedToFabric): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigNotConnectedToFabricData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.remoteDeviceId !== "") {
      obj.remoteDeviceId = message.remoteDeviceId;
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortNotConnectedToFabric>, I>>(base?: I): AssertPortNotConnectedToFabric {
    return AssertPortNotConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortNotConnectedToFabric>, I>>(
    object: I,
  ): AssertPortNotConnectedToFabric {
    const message = createBaseAssertPortNotConnectedToFabric();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigNotConnectedToFabricData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.remotePortName = object.remotePortName ?? "";
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigConnectedToFabricData(): AssertConfigConnectedToFabricData {
  return { generationId: 0, latchedAt: undefined, latchTime: 0, implicit: false, orgId: "", fabricId: "" };
}

export const AssertConfigConnectedToFabricData = {
  encode(message: AssertConfigConnectedToFabricData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(50).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigConnectedToFabricData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigConnectedToFabricData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigConnectedToFabricData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigConnectedToFabricData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigConnectedToFabricData>, I>>(
    base?: I,
  ): AssertConfigConnectedToFabricData {
    return AssertConfigConnectedToFabricData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigConnectedToFabricData>, I>>(
    object: I,
  ): AssertConfigConnectedToFabricData {
    const message = createBaseAssertConfigConnectedToFabricData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigDeviceConnectedToFabric(): AssertConfigDeviceConnectedToFabric {
  return { id: undefined, config: undefined };
}

export const AssertConfigDeviceConnectedToFabric = {
  encode(message: AssertConfigDeviceConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedToFabricData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigDeviceConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigDeviceConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigConnectedToFabricData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigDeviceConnectedToFabric {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedToFabricData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigDeviceConnectedToFabric): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedToFabricData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigDeviceConnectedToFabric>, I>>(
    base?: I,
  ): AssertConfigDeviceConnectedToFabric {
    return AssertConfigDeviceConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigDeviceConnectedToFabric>, I>>(
    object: I,
  ): AssertConfigDeviceConnectedToFabric {
    const message = createBaseAssertConfigDeviceConnectedToFabric();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedToFabricData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertDeviceConnectedToFabric(): AssertDeviceConnectedToFabric {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertDeviceConnectedToFabric = {
  encode(message: AssertDeviceConnectedToFabric, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedToFabricData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceConnectedToFabric {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceConnectedToFabric();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigConnectedToFabricData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceConnectedToFabric {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedToFabricData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertDeviceConnectedToFabric): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedToFabricData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceConnectedToFabric>, I>>(base?: I): AssertDeviceConnectedToFabric {
    return AssertDeviceConnectedToFabric.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceConnectedToFabric>, I>>(
    object: I,
  ): AssertDeviceConnectedToFabric {
    const message = createBaseAssertDeviceConnectedToFabric();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedToFabricData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigConnectedPortSpeedsMatchData(): AssertConfigConnectedPortSpeedsMatchData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigConnectedPortSpeedsMatchData = {
  encode(message: AssertConfigConnectedPortSpeedsMatchData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigConnectedPortSpeedsMatchData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigConnectedPortSpeedsMatchData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigConnectedPortSpeedsMatchData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigConnectedPortSpeedsMatchData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigConnectedPortSpeedsMatchData>, I>>(
    base?: I,
  ): AssertConfigConnectedPortSpeedsMatchData {
    return AssertConfigConnectedPortSpeedsMatchData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigConnectedPortSpeedsMatchData>, I>>(
    object: I,
  ): AssertConfigConnectedPortSpeedsMatchData {
    const message = createBaseAssertConfigConnectedPortSpeedsMatchData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortConnectionSpeedMatch(): AssertConfigPortConnectionSpeedMatch {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortConnectionSpeedMatch = {
  encode(message: AssertConfigPortConnectionSpeedMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedPortSpeedsMatchData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortConnectionSpeedMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortConnectionSpeedMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigConnectedPortSpeedsMatchData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortConnectionSpeedMatch {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedPortSpeedsMatchData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortConnectionSpeedMatch): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedPortSpeedsMatchData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortConnectionSpeedMatch>, I>>(
    base?: I,
  ): AssertConfigPortConnectionSpeedMatch {
    return AssertConfigPortConnectionSpeedMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortConnectionSpeedMatch>, I>>(
    object: I,
  ): AssertConfigPortConnectionSpeedMatch {
    const message = createBaseAssertConfigPortConnectionSpeedMatch();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedPortSpeedsMatchData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortConnectionSpeedMatch(): AssertPortConnectionSpeedMatch {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    localPortSpeed: 0,
    remotePortName: "",
    remoteDeviceId: "",
    remotePortSpeed: 0,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortConnectionSpeedMatch = {
  encode(message: AssertPortConnectionSpeedMatch, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigConnectedPortSpeedsMatchData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.localPortSpeed !== 0) {
      writer.uint32(72).uint64(message.localPortSpeed);
    }
    if (message.remotePortName !== "") {
      writer.uint32(82).string(message.remotePortName);
    }
    if (message.remoteDeviceId !== "") {
      writer.uint32(90).string(message.remoteDeviceId);
    }
    if (message.remotePortSpeed !== 0) {
      writer.uint32(96).uint64(message.remotePortSpeed);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(104).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(112).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortConnectionSpeedMatch {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortConnectionSpeedMatch();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigConnectedPortSpeedsMatchData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.localPortSpeed = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.remotePortName = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.remoteDeviceId = reader.string();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.remotePortSpeed = longToNumber(reader.uint64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortConnectionSpeedMatch {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigConnectedPortSpeedsMatchData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      localPortSpeed: isSet(object.localPortSpeed) ? globalThis.Number(object.localPortSpeed) : 0,
      remotePortName: isSet(object.remotePortName) ? globalThis.String(object.remotePortName) : "",
      remoteDeviceId: isSet(object.remoteDeviceId) ? globalThis.String(object.remoteDeviceId) : "",
      remotePortSpeed: isSet(object.remotePortSpeed) ? globalThis.Number(object.remotePortSpeed) : 0,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortConnectionSpeedMatch): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigConnectedPortSpeedsMatchData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.localPortSpeed !== 0) {
      obj.localPortSpeed = Math.round(message.localPortSpeed);
    }
    if (message.remotePortName !== "") {
      obj.remotePortName = message.remotePortName;
    }
    if (message.remoteDeviceId !== "") {
      obj.remoteDeviceId = message.remoteDeviceId;
    }
    if (message.remotePortSpeed !== 0) {
      obj.remotePortSpeed = Math.round(message.remotePortSpeed);
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortConnectionSpeedMatch>, I>>(base?: I): AssertPortConnectionSpeedMatch {
    return AssertPortConnectionSpeedMatch.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortConnectionSpeedMatch>, I>>(
    object: I,
  ): AssertPortConnectionSpeedMatch {
    const message = createBaseAssertPortConnectionSpeedMatch();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigConnectedPortSpeedsMatchData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.localPortSpeed = object.localPortSpeed ?? 0;
    message.remotePortName = object.remotePortName ?? "";
    message.remoteDeviceId = object.remoteDeviceId ?? "";
    message.remotePortSpeed = object.remotePortSpeed ?? 0;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigPortSpeedConsistentData(): AssertConfigPortSpeedConsistentData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    port: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigPortSpeedConsistentData = {
  encode(message: AssertConfigPortSpeedConsistentData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.port !== undefined) {
      PortConfigDetails.encode(message.port, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortSpeedConsistentData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortSpeedConsistentData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.port = PortConfigDetails.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortSpeedConsistentData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      port: isSet(object.port) ? PortConfigDetails.fromJSON(object.port) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigPortSpeedConsistentData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.port !== undefined) {
      obj.port = PortConfigDetails.toJSON(message.port);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortSpeedConsistentData>, I>>(
    base?: I,
  ): AssertConfigPortSpeedConsistentData {
    return AssertConfigPortSpeedConsistentData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortSpeedConsistentData>, I>>(
    object: I,
  ): AssertConfigPortSpeedConsistentData {
    const message = createBaseAssertConfigPortSpeedConsistentData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.port = (object.port !== undefined && object.port !== null)
      ? PortConfigDetails.fromPartial(object.port)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigPortSpeedConsistent(): AssertConfigPortSpeedConsistent {
  return { id: undefined, config: undefined };
}

export const AssertConfigPortSpeedConsistent = {
  encode(message: AssertConfigPortSpeedConsistent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigPortSpeedConsistentData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigPortSpeedConsistent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigPortSpeedConsistent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigPortSpeedConsistentData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigPortSpeedConsistent {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigPortSpeedConsistentData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigPortSpeedConsistent): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigPortSpeedConsistentData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigPortSpeedConsistent>, I>>(base?: I): AssertConfigPortSpeedConsistent {
    return AssertConfigPortSpeedConsistent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigPortSpeedConsistent>, I>>(
    object: I,
  ): AssertConfigPortSpeedConsistent {
    const message = createBaseAssertConfigPortSpeedConsistent();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigPortSpeedConsistentData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertPortSpeedConsistent(): AssertPortSpeedConsistent {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    localPortSpeed: 0,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertPortSpeedConsistent = {
  encode(message: AssertPortSpeedConsistent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigPortSpeedConsistentData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.localPortSpeed !== 0) {
      writer.uint32(72).uint64(message.localPortSpeed);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(80).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(88).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertPortSpeedConsistent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertPortSpeedConsistent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigPortSpeedConsistentData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.localPortSpeed = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertPortSpeedConsistent {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigPortSpeedConsistentData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      localPortSpeed: isSet(object.localPortSpeed) ? globalThis.Number(object.localPortSpeed) : 0,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertPortSpeedConsistent): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigPortSpeedConsistentData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.localPortSpeed !== 0) {
      obj.localPortSpeed = Math.round(message.localPortSpeed);
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertPortSpeedConsistent>, I>>(base?: I): AssertPortSpeedConsistent {
    return AssertPortSpeedConsistent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertPortSpeedConsistent>, I>>(object: I): AssertPortSpeedConsistent {
    const message = createBaseAssertPortSpeedConsistent();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigPortSpeedConsistentData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.localPortSpeed = object.localPortSpeed ?? 0;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigVlanHasTrafficData(): AssertConfigVlanHasTrafficData {
  return { generationId: 0, latchedAt: undefined, latchTime: 0, implicit: false, orgId: "", fabricId: "" };
}

export const AssertConfigVlanHasTrafficData = {
  encode(message: AssertConfigVlanHasTrafficData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(50).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigVlanHasTrafficData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigVlanHasTrafficData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigVlanHasTrafficData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigVlanHasTrafficData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigVlanHasTrafficData>, I>>(base?: I): AssertConfigVlanHasTrafficData {
    return AssertConfigVlanHasTrafficData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigVlanHasTrafficData>, I>>(
    object: I,
  ): AssertConfigVlanHasTrafficData {
    const message = createBaseAssertConfigVlanHasTrafficData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigVlanHasTraffic(): AssertConfigVlanHasTraffic {
  return { id: undefined, config: undefined };
}

export const AssertConfigVlanHasTraffic = {
  encode(message: AssertConfigVlanHasTraffic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigVlanHasTrafficData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigVlanHasTraffic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigVlanHasTraffic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigVlanHasTrafficData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigVlanHasTraffic {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigVlanHasTrafficData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigVlanHasTraffic): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigVlanHasTrafficData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigVlanHasTraffic>, I>>(base?: I): AssertConfigVlanHasTraffic {
    return AssertConfigVlanHasTraffic.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigVlanHasTraffic>, I>>(object: I): AssertConfigVlanHasTraffic {
    const message = createBaseAssertConfigVlanHasTraffic();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigVlanHasTrafficData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertVlanHasTraffic(): AssertVlanHasTraffic {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    lastTrafficBytes: 0,
    lastTrafficTime: undefined,
    recentTrafficBytes: 0,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertVlanHasTraffic = {
  encode(message: AssertVlanHasTraffic, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigVlanHasTrafficData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.lastTrafficBytes !== 0) {
      writer.uint32(72).uint64(message.lastTrafficBytes);
    }
    if (message.lastTrafficTime !== undefined) {
      Timestamp.encode(toTimestamp(message.lastTrafficTime), writer.uint32(82).fork()).ldelim();
    }
    if (message.recentTrafficBytes !== 0) {
      writer.uint32(88).uint64(message.recentTrafficBytes);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(96).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(104).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertVlanHasTraffic {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertVlanHasTraffic();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigVlanHasTrafficData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.lastTrafficBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lastTrafficTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.recentTrafficBytes = longToNumber(reader.uint64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertVlanHasTraffic {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigVlanHasTrafficData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      lastTrafficBytes: isSet(object.lastTrafficBytes) ? globalThis.Number(object.lastTrafficBytes) : 0,
      lastTrafficTime: isSet(object.lastTrafficTime) ? fromJsonTimestamp(object.lastTrafficTime) : undefined,
      recentTrafficBytes: isSet(object.recentTrafficBytes) ? globalThis.Number(object.recentTrafficBytes) : 0,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertVlanHasTraffic): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigVlanHasTrafficData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.lastTrafficBytes !== 0) {
      obj.lastTrafficBytes = Math.round(message.lastTrafficBytes);
    }
    if (message.lastTrafficTime !== undefined) {
      obj.lastTrafficTime = message.lastTrafficTime.toISOString();
    }
    if (message.recentTrafficBytes !== 0) {
      obj.recentTrafficBytes = Math.round(message.recentTrafficBytes);
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertVlanHasTraffic>, I>>(base?: I): AssertVlanHasTraffic {
    return AssertVlanHasTraffic.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertVlanHasTraffic>, I>>(object: I): AssertVlanHasTraffic {
    const message = createBaseAssertVlanHasTraffic();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigVlanHasTrafficData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.lastTrafficBytes = object.lastTrafficBytes ?? 0;
    message.lastTrafficTime = object.lastTrafficTime ?? undefined;
    message.recentTrafficBytes = object.recentTrafficBytes ?? 0;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigStaticDefaultRouteExistsData(): AssertConfigStaticDefaultRouteExistsData {
  return { generationId: 0, latchedAt: undefined, latchTime: 0, implicit: false, orgId: "", fabricId: "" };
}

export const AssertConfigStaticDefaultRouteExistsData = {
  encode(message: AssertConfigStaticDefaultRouteExistsData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(50).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigStaticDefaultRouteExistsData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigStaticDefaultRouteExistsData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigStaticDefaultRouteExistsData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigStaticDefaultRouteExistsData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigStaticDefaultRouteExistsData>, I>>(
    base?: I,
  ): AssertConfigStaticDefaultRouteExistsData {
    return AssertConfigStaticDefaultRouteExistsData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigStaticDefaultRouteExistsData>, I>>(
    object: I,
  ): AssertConfigStaticDefaultRouteExistsData {
    const message = createBaseAssertConfigStaticDefaultRouteExistsData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigStaticDefaultRouteExists(): AssertConfigStaticDefaultRouteExists {
  return { id: undefined, config: undefined };
}

export const AssertConfigStaticDefaultRouteExists = {
  encode(message: AssertConfigStaticDefaultRouteExists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigStaticDefaultRouteExistsData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigStaticDefaultRouteExists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigStaticDefaultRouteExists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigStaticDefaultRouteExistsData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigStaticDefaultRouteExists {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigStaticDefaultRouteExistsData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigStaticDefaultRouteExists): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigStaticDefaultRouteExistsData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigStaticDefaultRouteExists>, I>>(
    base?: I,
  ): AssertConfigStaticDefaultRouteExists {
    return AssertConfigStaticDefaultRouteExists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigStaticDefaultRouteExists>, I>>(
    object: I,
  ): AssertConfigStaticDefaultRouteExists {
    const message = createBaseAssertConfigStaticDefaultRouteExists();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigStaticDefaultRouteExistsData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertStaticDefaultRouteExists(): AssertStaticDefaultRouteExists {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertStaticDefaultRouteExists = {
  encode(message: AssertStaticDefaultRouteExists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigStaticDefaultRouteExistsData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertStaticDefaultRouteExists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertStaticDefaultRouteExists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigStaticDefaultRouteExistsData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertStaticDefaultRouteExists {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigStaticDefaultRouteExistsData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertStaticDefaultRouteExists): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigStaticDefaultRouteExistsData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertStaticDefaultRouteExists>, I>>(base?: I): AssertStaticDefaultRouteExists {
    return AssertStaticDefaultRouteExists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertStaticDefaultRouteExists>, I>>(
    object: I,
  ): AssertStaticDefaultRouteExists {
    const message = createBaseAssertStaticDefaultRouteExists();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigStaticDefaultRouteExistsData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigManagementPortConfigData(): AssertConfigManagementPortConfigData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    mgmtPortState: undefined,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigManagementPortConfigData = {
  encode(message: AssertConfigManagementPortConfigData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.mgmtPortState !== undefined) {
      ManagementState.encode(message.mgmtPortState, writer.uint32(34).fork()).ldelim();
    }
    if (message.implicit !== false) {
      writer.uint32(40).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigManagementPortConfigData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigManagementPortConfigData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.mgmtPortState = ManagementState.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigManagementPortConfigData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      mgmtPortState: isSet(object.mgmtPortState) ? ManagementState.fromJSON(object.mgmtPortState) : undefined,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigManagementPortConfigData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.mgmtPortState !== undefined) {
      obj.mgmtPortState = ManagementState.toJSON(message.mgmtPortState);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigManagementPortConfigData>, I>>(
    base?: I,
  ): AssertConfigManagementPortConfigData {
    return AssertConfigManagementPortConfigData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigManagementPortConfigData>, I>>(
    object: I,
  ): AssertConfigManagementPortConfigData {
    const message = createBaseAssertConfigManagementPortConfigData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.mgmtPortState = (object.mgmtPortState !== undefined && object.mgmtPortState !== null)
      ? ManagementState.fromPartial(object.mgmtPortState)
      : undefined;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigManagementPortConfigSame(): AssertConfigManagementPortConfigSame {
  return { id: undefined, config: undefined };
}

export const AssertConfigManagementPortConfigSame = {
  encode(message: AssertConfigManagementPortConfigSame, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigManagementPortConfigData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigManagementPortConfigSame {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigManagementPortConfigSame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigManagementPortConfigData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigManagementPortConfigSame {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigManagementPortConfigData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigManagementPortConfigSame): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigManagementPortConfigData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigManagementPortConfigSame>, I>>(
    base?: I,
  ): AssertConfigManagementPortConfigSame {
    return AssertConfigManagementPortConfigSame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigManagementPortConfigSame>, I>>(
    object: I,
  ): AssertConfigManagementPortConfigSame {
    const message = createBaseAssertConfigManagementPortConfigSame();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigManagementPortConfigData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertManagementPortConfigSame(): AssertManagementPortConfigSame {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    operationalMgmtPortState: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertManagementPortConfigSame = {
  encode(message: AssertManagementPortConfigSame, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigManagementPortConfigData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.operationalMgmtPortState !== undefined) {
      ManagementState.encode(message.operationalMgmtPortState, writer.uint32(74).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(80).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(88).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertManagementPortConfigSame {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertManagementPortConfigSame();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigManagementPortConfigData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.operationalMgmtPortState = ManagementState.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertManagementPortConfigSame {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigManagementPortConfigData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      operationalMgmtPortState: isSet(object.operationalMgmtPortState)
        ? ManagementState.fromJSON(object.operationalMgmtPortState)
        : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertManagementPortConfigSame): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigManagementPortConfigData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.operationalMgmtPortState !== undefined) {
      obj.operationalMgmtPortState = ManagementState.toJSON(message.operationalMgmtPortState);
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertManagementPortConfigSame>, I>>(base?: I): AssertManagementPortConfigSame {
    return AssertManagementPortConfigSame.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertManagementPortConfigSame>, I>>(
    object: I,
  ): AssertManagementPortConfigSame {
    const message = createBaseAssertManagementPortConfigSame();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigManagementPortConfigData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.operationalMgmtPortState =
      (object.operationalMgmtPortState !== undefined && object.operationalMgmtPortState !== null)
        ? ManagementState.fromPartial(object.operationalMgmtPortState)
        : undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigExpectedPortBreakoutExistsData(): AssertConfigExpectedPortBreakoutExistsData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    expectedPortBreakoutCfg: undefined,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigExpectedPortBreakoutExistsData = {
  encode(message: AssertConfigExpectedPortBreakoutExistsData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.expectedPortBreakoutCfg !== undefined) {
      PortBreakoutConfig.encode(message.expectedPortBreakoutCfg, writer.uint32(42).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(50).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(58).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigExpectedPortBreakoutExistsData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigExpectedPortBreakoutExistsData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.expectedPortBreakoutCfg = PortBreakoutConfig.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigExpectedPortBreakoutExistsData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      expectedPortBreakoutCfg: isSet(object.expectedPortBreakoutCfg)
        ? PortBreakoutConfig.fromJSON(object.expectedPortBreakoutCfg)
        : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigExpectedPortBreakoutExistsData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.expectedPortBreakoutCfg !== undefined) {
      obj.expectedPortBreakoutCfg = PortBreakoutConfig.toJSON(message.expectedPortBreakoutCfg);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigExpectedPortBreakoutExistsData>, I>>(
    base?: I,
  ): AssertConfigExpectedPortBreakoutExistsData {
    return AssertConfigExpectedPortBreakoutExistsData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigExpectedPortBreakoutExistsData>, I>>(
    object: I,
  ): AssertConfigExpectedPortBreakoutExistsData {
    const message = createBaseAssertConfigExpectedPortBreakoutExistsData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.expectedPortBreakoutCfg =
      (object.expectedPortBreakoutCfg !== undefined && object.expectedPortBreakoutCfg !== null)
        ? PortBreakoutConfig.fromPartial(object.expectedPortBreakoutCfg)
        : undefined;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigExpectedPortBreakoutExists(): AssertConfigExpectedPortBreakoutExists {
  return { id: undefined, config: undefined };
}

export const AssertConfigExpectedPortBreakoutExists = {
  encode(message: AssertConfigExpectedPortBreakoutExists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigExpectedPortBreakoutExistsData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigExpectedPortBreakoutExists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigExpectedPortBreakoutExists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigExpectedPortBreakoutExistsData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigExpectedPortBreakoutExists {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigExpectedPortBreakoutExistsData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigExpectedPortBreakoutExists): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigExpectedPortBreakoutExistsData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigExpectedPortBreakoutExists>, I>>(
    base?: I,
  ): AssertConfigExpectedPortBreakoutExists {
    return AssertConfigExpectedPortBreakoutExists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigExpectedPortBreakoutExists>, I>>(
    object: I,
  ): AssertConfigExpectedPortBreakoutExists {
    const message = createBaseAssertConfigExpectedPortBreakoutExists();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigExpectedPortBreakoutExistsData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertExpectedPortBreakoutExists(): AssertExpectedPortBreakoutExists {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertExpectedPortBreakoutExists = {
  encode(message: AssertExpectedPortBreakoutExists, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigExpectedPortBreakoutExistsData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertExpectedPortBreakoutExists {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertExpectedPortBreakoutExists();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigExpectedPortBreakoutExistsData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertExpectedPortBreakoutExists {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigExpectedPortBreakoutExistsData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertExpectedPortBreakoutExists): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigExpectedPortBreakoutExistsData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertExpectedPortBreakoutExists>, I>>(
    base?: I,
  ): AssertExpectedPortBreakoutExists {
    return AssertExpectedPortBreakoutExists.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertExpectedPortBreakoutExists>, I>>(
    object: I,
  ): AssertExpectedPortBreakoutExists {
    const message = createBaseAssertExpectedPortBreakoutExists();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigExpectedPortBreakoutExistsData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBasePortBreakoutConfig(): PortBreakoutConfig {
  return { speed: 0, media: 0, name: "" };
}

export const PortBreakoutConfig = {
  encode(message: PortBreakoutConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.speed !== 0) {
      writer.uint32(8).uint64(message.speed);
    }
    if (message.media !== 0) {
      writer.uint32(16).int32(message.media);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PortBreakoutConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePortBreakoutConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.speed = longToNumber(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.media = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PortBreakoutConfig {
    return {
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      media: isSet(object.media) ? cableTypeFromJSON(object.media) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
    };
  },

  toJSON(message: PortBreakoutConfig): unknown {
    const obj: any = {};
    if (message.speed !== 0) {
      obj.speed = Math.round(message.speed);
    }
    if (message.media !== 0) {
      obj.media = cableTypeToJSON(message.media);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PortBreakoutConfig>, I>>(base?: I): PortBreakoutConfig {
    return PortBreakoutConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PortBreakoutConfig>, I>>(object: I): PortBreakoutConfig {
    const message = createBasePortBreakoutConfig();
    message.speed = object.speed ?? 0;
    message.media = object.media ?? 0;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseAssertConfigDeviceResourceUsageBelowThresholdData(): AssertConfigDeviceResourceUsageBelowThresholdData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    cpuThreshold: 0,
    memoryThreshold: 0,
    diskThreshold: 0,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigDeviceResourceUsageBelowThresholdData = {
  encode(
    message: AssertConfigDeviceResourceUsageBelowThresholdData,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.cpuThreshold !== 0) {
      writer.uint32(37).float(message.cpuThreshold);
    }
    if (message.memoryThreshold !== 0) {
      writer.uint32(45).float(message.memoryThreshold);
    }
    if (message.diskThreshold !== 0) {
      writer.uint32(53).float(message.diskThreshold);
    }
    if (message.implicit !== false) {
      writer.uint32(56).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(66).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigDeviceResourceUsageBelowThresholdData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigDeviceResourceUsageBelowThresholdData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.cpuThreshold = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.memoryThreshold = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.diskThreshold = reader.float();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigDeviceResourceUsageBelowThresholdData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      cpuThreshold: isSet(object.cpuThreshold) ? globalThis.Number(object.cpuThreshold) : 0,
      memoryThreshold: isSet(object.memoryThreshold) ? globalThis.Number(object.memoryThreshold) : 0,
      diskThreshold: isSet(object.diskThreshold) ? globalThis.Number(object.diskThreshold) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigDeviceResourceUsageBelowThresholdData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.cpuThreshold !== 0) {
      obj.cpuThreshold = message.cpuThreshold;
    }
    if (message.memoryThreshold !== 0) {
      obj.memoryThreshold = message.memoryThreshold;
    }
    if (message.diskThreshold !== 0) {
      obj.diskThreshold = message.diskThreshold;
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigDeviceResourceUsageBelowThresholdData>, I>>(
    base?: I,
  ): AssertConfigDeviceResourceUsageBelowThresholdData {
    return AssertConfigDeviceResourceUsageBelowThresholdData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigDeviceResourceUsageBelowThresholdData>, I>>(
    object: I,
  ): AssertConfigDeviceResourceUsageBelowThresholdData {
    const message = createBaseAssertConfigDeviceResourceUsageBelowThresholdData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.cpuThreshold = object.cpuThreshold ?? 0;
    message.memoryThreshold = object.memoryThreshold ?? 0;
    message.diskThreshold = object.diskThreshold ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigDeviceResourceUsageBelowThreshold(): AssertConfigDeviceResourceUsageBelowThreshold {
  return { id: undefined, config: undefined };
}

export const AssertConfigDeviceResourceUsageBelowThreshold = {
  encode(message: AssertConfigDeviceResourceUsageBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigDeviceResourceUsageBelowThresholdData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigDeviceResourceUsageBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigDeviceResourceUsageBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigDeviceResourceUsageBelowThresholdData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigDeviceResourceUsageBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config)
        ? AssertConfigDeviceResourceUsageBelowThresholdData.fromJSON(object.config)
        : undefined,
    };
  },

  toJSON(message: AssertConfigDeviceResourceUsageBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigDeviceResourceUsageBelowThresholdData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigDeviceResourceUsageBelowThreshold>, I>>(
    base?: I,
  ): AssertConfigDeviceResourceUsageBelowThreshold {
    return AssertConfigDeviceResourceUsageBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigDeviceResourceUsageBelowThreshold>, I>>(
    object: I,
  ): AssertConfigDeviceResourceUsageBelowThreshold {
    const message = createBaseAssertConfigDeviceResourceUsageBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigDeviceResourceUsageBelowThresholdData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertDeviceResourceUsageBelowThreshold(): AssertDeviceResourceUsageBelowThreshold {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    currentSystemStats: undefined,
    highCpuUtil: false,
    highMemUtil: false,
    highDiskUtil: false,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertDeviceResourceUsageBelowThreshold = {
  encode(message: AssertDeviceResourceUsageBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigDeviceResourceUsageBelowThresholdData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.currentSystemStats !== undefined) {
      SystemStats.encode(message.currentSystemStats, writer.uint32(74).fork()).ldelim();
    }
    if (message.highCpuUtil !== false) {
      writer.uint32(80).bool(message.highCpuUtil);
    }
    if (message.highMemUtil !== false) {
      writer.uint32(88).bool(message.highMemUtil);
    }
    if (message.highDiskUtil !== false) {
      writer.uint32(96).bool(message.highDiskUtil);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(104).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(112).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertDeviceResourceUsageBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertDeviceResourceUsageBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigDeviceResourceUsageBelowThresholdData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentSystemStats = SystemStats.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.highCpuUtil = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.highMemUtil = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.highDiskUtil = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertDeviceResourceUsageBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config)
        ? AssertConfigDeviceResourceUsageBelowThresholdData.fromJSON(object.config)
        : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      currentSystemStats: isSet(object.currentSystemStats)
        ? SystemStats.fromJSON(object.currentSystemStats)
        : undefined,
      highCpuUtil: isSet(object.highCpuUtil) ? globalThis.Boolean(object.highCpuUtil) : false,
      highMemUtil: isSet(object.highMemUtil) ? globalThis.Boolean(object.highMemUtil) : false,
      highDiskUtil: isSet(object.highDiskUtil) ? globalThis.Boolean(object.highDiskUtil) : false,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertDeviceResourceUsageBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigDeviceResourceUsageBelowThresholdData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.currentSystemStats !== undefined) {
      obj.currentSystemStats = SystemStats.toJSON(message.currentSystemStats);
    }
    if (message.highCpuUtil !== false) {
      obj.highCpuUtil = message.highCpuUtil;
    }
    if (message.highMemUtil !== false) {
      obj.highMemUtil = message.highMemUtil;
    }
    if (message.highDiskUtil !== false) {
      obj.highDiskUtil = message.highDiskUtil;
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertDeviceResourceUsageBelowThreshold>, I>>(
    base?: I,
  ): AssertDeviceResourceUsageBelowThreshold {
    return AssertDeviceResourceUsageBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertDeviceResourceUsageBelowThreshold>, I>>(
    object: I,
  ): AssertDeviceResourceUsageBelowThreshold {
    const message = createBaseAssertDeviceResourceUsageBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigDeviceResourceUsageBelowThresholdData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.currentSystemStats = (object.currentSystemStats !== undefined && object.currentSystemStats !== null)
      ? SystemStats.fromPartial(object.currentSystemStats)
      : undefined;
    message.highCpuUtil = object.highCpuUtil ?? false;
    message.highMemUtil = object.highMemUtil ?? false;
    message.highDiskUtil = object.highDiskUtil ?? false;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigBgpPeerEstablishedData(): AssertConfigBgpPeerEstablishedData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    implicit: false,
    orgId: "",
    peerRemoteAs: 0,
    vrfName: "",
    fabricId: "",
  };
}

export const AssertConfigBgpPeerEstablishedData = {
  encode(message: AssertConfigBgpPeerEstablishedData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.implicit !== false) {
      writer.uint32(32).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(42).string(message.orgId);
    }
    if (message.peerRemoteAs !== 0) {
      writer.uint32(48).uint32(message.peerRemoteAs);
    }
    if (message.vrfName !== "") {
      writer.uint32(58).string(message.vrfName);
    }
    if (message.fabricId !== "") {
      writer.uint32(66).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigBgpPeerEstablishedData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigBgpPeerEstablishedData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.peerRemoteAs = reader.uint32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.vrfName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigBgpPeerEstablishedData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      peerRemoteAs: isSet(object.peerRemoteAs) ? globalThis.Number(object.peerRemoteAs) : 0,
      vrfName: isSet(object.vrfName) ? globalThis.String(object.vrfName) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigBgpPeerEstablishedData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.peerRemoteAs !== 0) {
      obj.peerRemoteAs = Math.round(message.peerRemoteAs);
    }
    if (message.vrfName !== "") {
      obj.vrfName = message.vrfName;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigBgpPeerEstablishedData>, I>>(
    base?: I,
  ): AssertConfigBgpPeerEstablishedData {
    return AssertConfigBgpPeerEstablishedData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigBgpPeerEstablishedData>, I>>(
    object: I,
  ): AssertConfigBgpPeerEstablishedData {
    const message = createBaseAssertConfigBgpPeerEstablishedData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.peerRemoteAs = object.peerRemoteAs ?? 0;
    message.vrfName = object.vrfName ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigBgpPeerEstablished(): AssertConfigBgpPeerEstablished {
  return { id: undefined, config: undefined };
}

export const AssertConfigBgpPeerEstablished = {
  encode(message: AssertConfigBgpPeerEstablished, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigBgpPeerEstablishedData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigBgpPeerEstablished {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigBgpPeerEstablished();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigBgpPeerEstablishedData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigBgpPeerEstablished {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigBgpPeerEstablishedData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigBgpPeerEstablished): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigBgpPeerEstablishedData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigBgpPeerEstablished>, I>>(base?: I): AssertConfigBgpPeerEstablished {
    return AssertConfigBgpPeerEstablished.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigBgpPeerEstablished>, I>>(
    object: I,
  ): AssertConfigBgpPeerEstablished {
    const message = createBaseAssertConfigBgpPeerEstablished();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigBgpPeerEstablishedData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertBgpPeerEstablished(): AssertBgpPeerEstablished {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertBgpPeerEstablished = {
  encode(message: AssertBgpPeerEstablished, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigBgpPeerEstablishedData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(72).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(80).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertBgpPeerEstablished {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertBgpPeerEstablished();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigBgpPeerEstablishedData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertBgpPeerEstablished {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigBgpPeerEstablishedData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertBgpPeerEstablished): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigBgpPeerEstablishedData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertBgpPeerEstablished>, I>>(base?: I): AssertBgpPeerEstablished {
    return AssertBgpPeerEstablished.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertBgpPeerEstablished>, I>>(object: I): AssertBgpPeerEstablished {
    const message = createBaseAssertBgpPeerEstablished();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigBgpPeerEstablishedData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigIpmLatencyBelowThresholdData(): AssertConfigIpmLatencyBelowThresholdData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    suspiciousThreshold: 0,
    criticalThreshold: 0,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigIpmLatencyBelowThresholdData = {
  encode(message: AssertConfigIpmLatencyBelowThresholdData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.suspiciousThreshold !== 0) {
      writer.uint32(37).float(message.suspiciousThreshold);
    }
    if (message.criticalThreshold !== 0) {
      writer.uint32(45).float(message.criticalThreshold);
    }
    if (message.implicit !== false) {
      writer.uint32(48).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(58).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(66).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigIpmLatencyBelowThresholdData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigIpmLatencyBelowThresholdData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.suspiciousThreshold = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.criticalThreshold = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigIpmLatencyBelowThresholdData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      suspiciousThreshold: isSet(object.suspiciousThreshold) ? globalThis.Number(object.suspiciousThreshold) : 0,
      criticalThreshold: isSet(object.criticalThreshold) ? globalThis.Number(object.criticalThreshold) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigIpmLatencyBelowThresholdData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.suspiciousThreshold !== 0) {
      obj.suspiciousThreshold = message.suspiciousThreshold;
    }
    if (message.criticalThreshold !== 0) {
      obj.criticalThreshold = message.criticalThreshold;
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigIpmLatencyBelowThresholdData>, I>>(
    base?: I,
  ): AssertConfigIpmLatencyBelowThresholdData {
    return AssertConfigIpmLatencyBelowThresholdData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigIpmLatencyBelowThresholdData>, I>>(
    object: I,
  ): AssertConfigIpmLatencyBelowThresholdData {
    const message = createBaseAssertConfigIpmLatencyBelowThresholdData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.suspiciousThreshold = object.suspiciousThreshold ?? 0;
    message.criticalThreshold = object.criticalThreshold ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigIpmLatencyBelowThreshold(): AssertConfigIpmLatencyBelowThreshold {
  return { id: undefined, config: undefined };
}

export const AssertConfigIpmLatencyBelowThreshold = {
  encode(message: AssertConfigIpmLatencyBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigIpmLatencyBelowThresholdData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigIpmLatencyBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigIpmLatencyBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigIpmLatencyBelowThresholdData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigIpmLatencyBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigIpmLatencyBelowThresholdData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigIpmLatencyBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigIpmLatencyBelowThresholdData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigIpmLatencyBelowThreshold>, I>>(
    base?: I,
  ): AssertConfigIpmLatencyBelowThreshold {
    return AssertConfigIpmLatencyBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigIpmLatencyBelowThreshold>, I>>(
    object: I,
  ): AssertConfigIpmLatencyBelowThreshold {
    const message = createBaseAssertConfigIpmLatencyBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigIpmLatencyBelowThresholdData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertIpmLatencyBelowThreshold(): AssertIpmLatencyBelowThreshold {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    currentLatencyStats: undefined,
    suspiciousLatency: false,
    criticalLatency: false,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertIpmLatencyBelowThreshold = {
  encode(message: AssertIpmLatencyBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigIpmLatencyBelowThresholdData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.currentLatencyStats !== undefined) {
      IpmProcLatencyCounters.encode(message.currentLatencyStats, writer.uint32(74).fork()).ldelim();
    }
    if (message.suspiciousLatency !== false) {
      writer.uint32(80).bool(message.suspiciousLatency);
    }
    if (message.criticalLatency !== false) {
      writer.uint32(88).bool(message.criticalLatency);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(96).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(104).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertIpmLatencyBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertIpmLatencyBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigIpmLatencyBelowThresholdData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentLatencyStats = IpmProcLatencyCounters.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.suspiciousLatency = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.criticalLatency = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertIpmLatencyBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigIpmLatencyBelowThresholdData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      currentLatencyStats: isSet(object.currentLatencyStats)
        ? IpmProcLatencyCounters.fromJSON(object.currentLatencyStats)
        : undefined,
      suspiciousLatency: isSet(object.suspiciousLatency) ? globalThis.Boolean(object.suspiciousLatency) : false,
      criticalLatency: isSet(object.criticalLatency) ? globalThis.Boolean(object.criticalLatency) : false,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertIpmLatencyBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigIpmLatencyBelowThresholdData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.currentLatencyStats !== undefined) {
      obj.currentLatencyStats = IpmProcLatencyCounters.toJSON(message.currentLatencyStats);
    }
    if (message.suspiciousLatency !== false) {
      obj.suspiciousLatency = message.suspiciousLatency;
    }
    if (message.criticalLatency !== false) {
      obj.criticalLatency = message.criticalLatency;
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertIpmLatencyBelowThreshold>, I>>(base?: I): AssertIpmLatencyBelowThreshold {
    return AssertIpmLatencyBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertIpmLatencyBelowThreshold>, I>>(
    object: I,
  ): AssertIpmLatencyBelowThreshold {
    const message = createBaseAssertIpmLatencyBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigIpmLatencyBelowThresholdData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.currentLatencyStats = (object.currentLatencyStats !== undefined && object.currentLatencyStats !== null)
      ? IpmProcLatencyCounters.fromPartial(object.currentLatencyStats)
      : undefined;
    message.suspiciousLatency = object.suspiciousLatency ?? false;
    message.criticalLatency = object.criticalLatency ?? false;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseAssertConfigIpmLossBelowThresholdData(): AssertConfigIpmLossBelowThresholdData {
  return {
    generationId: 0,
    latchedAt: undefined,
    latchTime: 0,
    suspiciousThreshold: 0,
    criticalThreshold: 0,
    implicit: false,
    orgId: "",
    fabricId: "",
  };
}

export const AssertConfigIpmLossBelowThresholdData = {
  encode(message: AssertConfigIpmLossBelowThresholdData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.generationId !== 0) {
      writer.uint32(8).int64(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.latchedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.latchTime !== 0) {
      writer.uint32(24).int64(message.latchTime);
    }
    if (message.suspiciousThreshold !== 0) {
      writer.uint32(37).float(message.suspiciousThreshold);
    }
    if (message.criticalThreshold !== 0) {
      writer.uint32(45).float(message.criticalThreshold);
    }
    if (message.implicit !== false) {
      writer.uint32(48).bool(message.implicit);
    }
    if (message.orgId !== "") {
      writer.uint32(58).string(message.orgId);
    }
    if (message.fabricId !== "") {
      writer.uint32(66).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigIpmLossBelowThresholdData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigIpmLossBelowThresholdData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.generationId = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.latchedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.latchTime = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.suspiciousThreshold = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.criticalThreshold = reader.float();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.implicit = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigIpmLossBelowThresholdData {
    return {
      generationId: isSet(object.generationId) ? globalThis.Number(object.generationId) : 0,
      latchedAt: isSet(object.latchedAt) ? fromJsonTimestamp(object.latchedAt) : undefined,
      latchTime: isSet(object.latchTime) ? globalThis.Number(object.latchTime) : 0,
      suspiciousThreshold: isSet(object.suspiciousThreshold) ? globalThis.Number(object.suspiciousThreshold) : 0,
      criticalThreshold: isSet(object.criticalThreshold) ? globalThis.Number(object.criticalThreshold) : 0,
      implicit: isSet(object.implicit) ? globalThis.Boolean(object.implicit) : false,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: AssertConfigIpmLossBelowThresholdData): unknown {
    const obj: any = {};
    if (message.generationId !== 0) {
      obj.generationId = Math.round(message.generationId);
    }
    if (message.latchedAt !== undefined) {
      obj.latchedAt = message.latchedAt.toISOString();
    }
    if (message.latchTime !== 0) {
      obj.latchTime = Math.round(message.latchTime);
    }
    if (message.suspiciousThreshold !== 0) {
      obj.suspiciousThreshold = message.suspiciousThreshold;
    }
    if (message.criticalThreshold !== 0) {
      obj.criticalThreshold = message.criticalThreshold;
    }
    if (message.implicit !== false) {
      obj.implicit = message.implicit;
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigIpmLossBelowThresholdData>, I>>(
    base?: I,
  ): AssertConfigIpmLossBelowThresholdData {
    return AssertConfigIpmLossBelowThresholdData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigIpmLossBelowThresholdData>, I>>(
    object: I,
  ): AssertConfigIpmLossBelowThresholdData {
    const message = createBaseAssertConfigIpmLossBelowThresholdData();
    message.generationId = object.generationId ?? 0;
    message.latchedAt = object.latchedAt ?? undefined;
    message.latchTime = object.latchTime ?? 0;
    message.suspiciousThreshold = object.suspiciousThreshold ?? 0;
    message.criticalThreshold = object.criticalThreshold ?? 0;
    message.implicit = object.implicit ?? false;
    message.orgId = object.orgId ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseAssertConfigIpmLossBelowThreshold(): AssertConfigIpmLossBelowThreshold {
  return { id: undefined, config: undefined };
}

export const AssertConfigIpmLossBelowThreshold = {
  encode(message: AssertConfigIpmLossBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigIpmLossBelowThresholdData.encode(message.config, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertConfigIpmLossBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertConfigIpmLossBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.config = AssertConfigIpmLossBelowThresholdData.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertConfigIpmLossBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      config: isSet(object.config) ? AssertConfigIpmLossBelowThresholdData.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: AssertConfigIpmLossBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigIpmLossBelowThresholdData.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertConfigIpmLossBelowThreshold>, I>>(
    base?: I,
  ): AssertConfigIpmLossBelowThreshold {
    return AssertConfigIpmLossBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertConfigIpmLossBelowThreshold>, I>>(
    object: I,
  ): AssertConfigIpmLossBelowThreshold {
    const message = createBaseAssertConfigIpmLossBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigIpmLossBelowThresholdData.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseAssertIpmLossBelowThreshold(): AssertIpmLossBelowThreshold {
  return {
    id: undefined,
    category: 0,
    assertState: 0,
    modifiedAt: undefined,
    config: undefined,
    masked: false,
    readyToLatchAt: undefined,
    currentLossStats: undefined,
    suspiciousLoss: false,
    criticalLoss: false,
    maskedByPortLinkUp: false,
    maskedByConnectionToCloud: false,
  };
}

export const AssertIpmLossBelowThreshold = {
  encode(message: AssertIpmLossBelowThreshold, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.category !== 0) {
      writer.uint32(24).int32(message.category);
    }
    if (message.assertState !== 0) {
      writer.uint32(32).int32(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.modifiedAt), writer.uint32(42).fork()).ldelim();
    }
    if (message.config !== undefined) {
      AssertConfigIpmLossBelowThresholdData.encode(message.config, writer.uint32(50).fork()).ldelim();
    }
    if (message.masked !== false) {
      writer.uint32(56).bool(message.masked);
    }
    if (message.readyToLatchAt !== undefined) {
      Timestamp.encode(toTimestamp(message.readyToLatchAt), writer.uint32(66).fork()).ldelim();
    }
    if (message.currentLossStats !== undefined) {
      IpmProcLossCounters.encode(message.currentLossStats, writer.uint32(74).fork()).ldelim();
    }
    if (message.suspiciousLoss !== false) {
      writer.uint32(80).bool(message.suspiciousLoss);
    }
    if (message.criticalLoss !== false) {
      writer.uint32(88).bool(message.criticalLoss);
    }
    if (message.maskedByPortLinkUp !== false) {
      writer.uint32(96).bool(message.maskedByPortLinkUp);
    }
    if (message.maskedByConnectionToCloud !== false) {
      writer.uint32(104).bool(message.maskedByConnectionToCloud);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AssertIpmLossBelowThreshold {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssertIpmLossBelowThreshold();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.assertState = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.modifiedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.config = AssertConfigIpmLossBelowThresholdData.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.masked = reader.bool();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.readyToLatchAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.currentLossStats = IpmProcLossCounters.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.suspiciousLoss = reader.bool();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.criticalLoss = reader.bool();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maskedByPortLinkUp = reader.bool();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maskedByConnectionToCloud = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssertIpmLossBelowThreshold {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      category: isSet(object.category) ? assertCategoryTypeFromJSON(object.category) : 0,
      assertState: isSet(object.assertState) ? assertStateTypeFromJSON(object.assertState) : 0,
      modifiedAt: isSet(object.modifiedAt) ? fromJsonTimestamp(object.modifiedAt) : undefined,
      config: isSet(object.config) ? AssertConfigIpmLossBelowThresholdData.fromJSON(object.config) : undefined,
      masked: isSet(object.masked) ? globalThis.Boolean(object.masked) : false,
      readyToLatchAt: isSet(object.readyToLatchAt) ? fromJsonTimestamp(object.readyToLatchAt) : undefined,
      currentLossStats: isSet(object.currentLossStats)
        ? IpmProcLossCounters.fromJSON(object.currentLossStats)
        : undefined,
      suspiciousLoss: isSet(object.suspiciousLoss) ? globalThis.Boolean(object.suspiciousLoss) : false,
      criticalLoss: isSet(object.criticalLoss) ? globalThis.Boolean(object.criticalLoss) : false,
      maskedByPortLinkUp: isSet(object.maskedByPortLinkUp) ? globalThis.Boolean(object.maskedByPortLinkUp) : false,
      maskedByConnectionToCloud: isSet(object.maskedByConnectionToCloud)
        ? globalThis.Boolean(object.maskedByConnectionToCloud)
        : false,
    };
  },

  toJSON(message: AssertIpmLossBelowThreshold): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.category !== 0) {
      obj.category = assertCategoryTypeToJSON(message.category);
    }
    if (message.assertState !== 0) {
      obj.assertState = assertStateTypeToJSON(message.assertState);
    }
    if (message.modifiedAt !== undefined) {
      obj.modifiedAt = message.modifiedAt.toISOString();
    }
    if (message.config !== undefined) {
      obj.config = AssertConfigIpmLossBelowThresholdData.toJSON(message.config);
    }
    if (message.masked !== false) {
      obj.masked = message.masked;
    }
    if (message.readyToLatchAt !== undefined) {
      obj.readyToLatchAt = message.readyToLatchAt.toISOString();
    }
    if (message.currentLossStats !== undefined) {
      obj.currentLossStats = IpmProcLossCounters.toJSON(message.currentLossStats);
    }
    if (message.suspiciousLoss !== false) {
      obj.suspiciousLoss = message.suspiciousLoss;
    }
    if (message.criticalLoss !== false) {
      obj.criticalLoss = message.criticalLoss;
    }
    if (message.maskedByPortLinkUp !== false) {
      obj.maskedByPortLinkUp = message.maskedByPortLinkUp;
    }
    if (message.maskedByConnectionToCloud !== false) {
      obj.maskedByConnectionToCloud = message.maskedByConnectionToCloud;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AssertIpmLossBelowThreshold>, I>>(base?: I): AssertIpmLossBelowThreshold {
    return AssertIpmLossBelowThreshold.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AssertIpmLossBelowThreshold>, I>>(object: I): AssertIpmLossBelowThreshold {
    const message = createBaseAssertIpmLossBelowThreshold();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.category = object.category ?? 0;
    message.assertState = object.assertState ?? 0;
    message.modifiedAt = object.modifiedAt ?? undefined;
    message.config = (object.config !== undefined && object.config !== null)
      ? AssertConfigIpmLossBelowThresholdData.fromPartial(object.config)
      : undefined;
    message.masked = object.masked ?? false;
    message.readyToLatchAt = object.readyToLatchAt ?? undefined;
    message.currentLossStats = (object.currentLossStats !== undefined && object.currentLossStats !== null)
      ? IpmProcLossCounters.fromPartial(object.currentLossStats)
      : undefined;
    message.suspiciousLoss = object.suspiciousLoss ?? false;
    message.criticalLoss = object.criticalLoss ?? false;
    message.maskedByPortLinkUp = object.maskedByPortLinkUp ?? false;
    message.maskedByConnectionToCloud = object.maskedByConnectionToCloud ?? false;
    return message;
  },
};

function createBaseSonicConfig(): SonicConfig {
  return { ipv4Addr: "", ipv6Addr: "", ipv4Gateway: "", ipv6Gateway: "", hostname: "" };
}

export const SonicConfig = {
  encode(message: SonicConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ipv4Addr !== "") {
      writer.uint32(18).string(message.ipv4Addr);
    }
    if (message.ipv6Addr !== "") {
      writer.uint32(26).string(message.ipv6Addr);
    }
    if (message.ipv4Gateway !== "") {
      writer.uint32(34).string(message.ipv4Gateway);
    }
    if (message.ipv6Gateway !== "") {
      writer.uint32(42).string(message.ipv6Gateway);
    }
    if (message.hostname !== "") {
      writer.uint32(50).string(message.hostname);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SonicConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSonicConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.ipv4Addr = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ipv6Addr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ipv4Gateway = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ipv6Gateway = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.hostname = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SonicConfig {
    return {
      ipv4Addr: isSet(object.ipv4Addr) ? globalThis.String(object.ipv4Addr) : "",
      ipv6Addr: isSet(object.ipv6Addr) ? globalThis.String(object.ipv6Addr) : "",
      ipv4Gateway: isSet(object.ipv4Gateway) ? globalThis.String(object.ipv4Gateway) : "",
      ipv6Gateway: isSet(object.ipv6Gateway) ? globalThis.String(object.ipv6Gateway) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
    };
  },

  toJSON(message: SonicConfig): unknown {
    const obj: any = {};
    if (message.ipv4Addr !== "") {
      obj.ipv4Addr = message.ipv4Addr;
    }
    if (message.ipv6Addr !== "") {
      obj.ipv6Addr = message.ipv6Addr;
    }
    if (message.ipv4Gateway !== "") {
      obj.ipv4Gateway = message.ipv4Gateway;
    }
    if (message.ipv6Gateway !== "") {
      obj.ipv6Gateway = message.ipv6Gateway;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SonicConfig>, I>>(base?: I): SonicConfig {
    return SonicConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SonicConfig>, I>>(object: I): SonicConfig {
    const message = createBaseSonicConfig();
    message.ipv4Addr = object.ipv4Addr ?? "";
    message.ipv6Addr = object.ipv6Addr ?? "";
    message.ipv4Gateway = object.ipv4Gateway ?? "";
    message.ipv6Gateway = object.ipv6Gateway ?? "";
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseProxyConfig(): ProxyConfig {
  return { httpProxy: "", httpsProxy: "", noProxy: "", user: "", pass: "" };
}

export const ProxyConfig = {
  encode(message: ProxyConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.httpProxy !== "") {
      writer.uint32(18).string(message.httpProxy);
    }
    if (message.httpsProxy !== "") {
      writer.uint32(26).string(message.httpsProxy);
    }
    if (message.noProxy !== "") {
      writer.uint32(34).string(message.noProxy);
    }
    if (message.user !== "") {
      writer.uint32(42).string(message.user);
    }
    if (message.pass !== "") {
      writer.uint32(50).string(message.pass);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProxyConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProxyConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.httpProxy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.httpsProxy = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.noProxy = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.user = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pass = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProxyConfig {
    return {
      httpProxy: isSet(object.httpProxy) ? globalThis.String(object.httpProxy) : "",
      httpsProxy: isSet(object.httpsProxy) ? globalThis.String(object.httpsProxy) : "",
      noProxy: isSet(object.noProxy) ? globalThis.String(object.noProxy) : "",
      user: isSet(object.user) ? globalThis.String(object.user) : "",
      pass: isSet(object.pass) ? globalThis.String(object.pass) : "",
    };
  },

  toJSON(message: ProxyConfig): unknown {
    const obj: any = {};
    if (message.httpProxy !== "") {
      obj.httpProxy = message.httpProxy;
    }
    if (message.httpsProxy !== "") {
      obj.httpsProxy = message.httpsProxy;
    }
    if (message.noProxy !== "") {
      obj.noProxy = message.noProxy;
    }
    if (message.user !== "") {
      obj.user = message.user;
    }
    if (message.pass !== "") {
      obj.pass = message.pass;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProxyConfig>, I>>(base?: I): ProxyConfig {
    return ProxyConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProxyConfig>, I>>(object: I): ProxyConfig {
    const message = createBaseProxyConfig();
    message.httpProxy = object.httpProxy ?? "";
    message.httpsProxy = object.httpsProxy ?? "";
    message.noProxy = object.noProxy ?? "";
    message.user = object.user ?? "";
    message.pass = object.pass ?? "";
    return message;
  },
};

function createBaseDnsConfig(): DnsConfig {
  return { dnsServers: [] };
}

export const DnsConfig = {
  encode(message: DnsConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dnsServers) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DnsConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDnsConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dnsServers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DnsConfig {
    return {
      dnsServers: globalThis.Array.isArray(object?.dnsServers)
        ? object.dnsServers.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DnsConfig): unknown {
    const obj: any = {};
    if (message.dnsServers?.length) {
      obj.dnsServers = message.dnsServers;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DnsConfig>, I>>(base?: I): DnsConfig {
    return DnsConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DnsConfig>, I>>(object: I): DnsConfig {
    const message = createBaseDnsConfig();
    message.dnsServers = object.dnsServers?.map((e) => e) || [];
    return message;
  },
};

function createBaseDrakeConfig(): DrakeConfig {
  return { cloudBaseUrlList: [] };
}

export const DrakeConfig = {
  encode(message: DrakeConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cloudBaseUrlList) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DrakeConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDrakeConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.cloudBaseUrlList.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DrakeConfig {
    return {
      cloudBaseUrlList: globalThis.Array.isArray(object?.cloudBaseUrlList)
        ? object.cloudBaseUrlList.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DrakeConfig): unknown {
    const obj: any = {};
    if (message.cloudBaseUrlList?.length) {
      obj.cloudBaseUrlList = message.cloudBaseUrlList;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DrakeConfig>, I>>(base?: I): DrakeConfig {
    return DrakeConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DrakeConfig>, I>>(object: I): DrakeConfig {
    const message = createBaseDrakeConfig();
    message.cloudBaseUrlList = object.cloudBaseUrlList?.map((e) => e) || [];
    return message;
  },
};

function createBaseServerBmcPreAuthInfo(): ServerBmcPreAuthInfo {
  return { id: "", vendor: "", product: "" };
}

export const ServerBmcPreAuthInfo = {
  encode(message: ServerBmcPreAuthInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.vendor !== "") {
      writer.uint32(18).string(message.vendor);
    }
    if (message.product !== "") {
      writer.uint32(26).string(message.product);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerBmcPreAuthInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerBmcPreAuthInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.vendor = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.product = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerBmcPreAuthInfo {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      vendor: isSet(object.vendor) ? globalThis.String(object.vendor) : "",
      product: isSet(object.product) ? globalThis.String(object.product) : "",
    };
  },

  toJSON(message: ServerBmcPreAuthInfo): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.vendor !== "") {
      obj.vendor = message.vendor;
    }
    if (message.product !== "") {
      obj.product = message.product;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerBmcPreAuthInfo>, I>>(base?: I): ServerBmcPreAuthInfo {
    return ServerBmcPreAuthInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerBmcPreAuthInfo>, I>>(object: I): ServerBmcPreAuthInfo {
    const message = createBaseServerBmcPreAuthInfo();
    message.id = object.id ?? "";
    message.vendor = object.vendor ?? "";
    message.product = object.product ?? "";
    return message;
  },
};

function createBaseServerFirmwareInventoryItem(): ServerFirmwareInventoryItem {
  return { name: "", description: "", manufacturer: "", version: "" };
}

export const ServerFirmwareInventoryItem = {
  encode(message: ServerFirmwareInventoryItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.manufacturer !== "") {
      writer.uint32(26).string(message.manufacturer);
    }
    if (message.version !== "") {
      writer.uint32(34).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerFirmwareInventoryItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerFirmwareInventoryItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerFirmwareInventoryItem {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: ServerFirmwareInventoryItem): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerFirmwareInventoryItem>, I>>(base?: I): ServerFirmwareInventoryItem {
    return ServerFirmwareInventoryItem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerFirmwareInventoryItem>, I>>(object: I): ServerFirmwareInventoryItem {
    const message = createBaseServerFirmwareInventoryItem();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseServerProcessorId(): ServerProcessorId {
  return { family: "", model: "", step: "", vendorId: "" };
}

export const ServerProcessorId = {
  encode(message: ServerProcessorId, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.family !== "") {
      writer.uint32(10).string(message.family);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.step !== "") {
      writer.uint32(26).string(message.step);
    }
    if (message.vendorId !== "") {
      writer.uint32(34).string(message.vendorId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerProcessorId {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerProcessorId();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.family = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.step = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.vendorId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerProcessorId {
    return {
      family: isSet(object.family) ? globalThis.String(object.family) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      step: isSet(object.step) ? globalThis.String(object.step) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
    };
  },

  toJSON(message: ServerProcessorId): unknown {
    const obj: any = {};
    if (message.family !== "") {
      obj.family = message.family;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.step !== "") {
      obj.step = message.step;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerProcessorId>, I>>(base?: I): ServerProcessorId {
    return ServerProcessorId.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerProcessorId>, I>>(object: I): ServerProcessorId {
    const message = createBaseServerProcessorId();
    message.family = object.family ?? "";
    message.model = object.model ?? "";
    message.step = object.step ?? "";
    message.vendorId = object.vendorId ?? "";
    return message;
  },
};

function createBaseServerProcessor(): ServerProcessor {
  return {
    id: "",
    name: "",
    description: "",
    instructionSet: "",
    manufacturer: "",
    operatingSpeedMhz: 0,
    processorId: undefined,
    socket: "",
    totalCores: 0,
    totalThreads: 0,
  };
}

export const ServerProcessor = {
  encode(message: ServerProcessor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.instructionSet !== "") {
      writer.uint32(34).string(message.instructionSet);
    }
    if (message.manufacturer !== "") {
      writer.uint32(42).string(message.manufacturer);
    }
    if (message.operatingSpeedMhz !== 0) {
      writer.uint32(48).int32(message.operatingSpeedMhz);
    }
    if (message.processorId !== undefined) {
      ServerProcessorId.encode(message.processorId, writer.uint32(58).fork()).ldelim();
    }
    if (message.socket !== "") {
      writer.uint32(66).string(message.socket);
    }
    if (message.totalCores !== 0) {
      writer.uint32(72).int32(message.totalCores);
    }
    if (message.totalThreads !== 0) {
      writer.uint32(80).int32(message.totalThreads);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerProcessor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerProcessor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.instructionSet = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.operatingSpeedMhz = reader.int32();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.processorId = ServerProcessorId.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.socket = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.totalCores = reader.int32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.totalThreads = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerProcessor {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      instructionSet: isSet(object.instructionSet) ? globalThis.String(object.instructionSet) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      operatingSpeedMhz: isSet(object.operatingSpeedMhz) ? globalThis.Number(object.operatingSpeedMhz) : 0,
      processorId: isSet(object.processorId) ? ServerProcessorId.fromJSON(object.processorId) : undefined,
      socket: isSet(object.socket) ? globalThis.String(object.socket) : "",
      totalCores: isSet(object.totalCores) ? globalThis.Number(object.totalCores) : 0,
      totalThreads: isSet(object.totalThreads) ? globalThis.Number(object.totalThreads) : 0,
    };
  },

  toJSON(message: ServerProcessor): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.instructionSet !== "") {
      obj.instructionSet = message.instructionSet;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.operatingSpeedMhz !== 0) {
      obj.operatingSpeedMhz = Math.round(message.operatingSpeedMhz);
    }
    if (message.processorId !== undefined) {
      obj.processorId = ServerProcessorId.toJSON(message.processorId);
    }
    if (message.socket !== "") {
      obj.socket = message.socket;
    }
    if (message.totalCores !== 0) {
      obj.totalCores = Math.round(message.totalCores);
    }
    if (message.totalThreads !== 0) {
      obj.totalThreads = Math.round(message.totalThreads);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerProcessor>, I>>(base?: I): ServerProcessor {
    return ServerProcessor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerProcessor>, I>>(object: I): ServerProcessor {
    const message = createBaseServerProcessor();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.instructionSet = object.instructionSet ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.operatingSpeedMhz = object.operatingSpeedMhz ?? 0;
    message.processorId = (object.processorId !== undefined && object.processorId !== null)
      ? ServerProcessorId.fromPartial(object.processorId)
      : undefined;
    message.socket = object.socket ?? "";
    message.totalCores = object.totalCores ?? 0;
    message.totalThreads = object.totalThreads ?? 0;
    return message;
  },
};

function createBaseServerMemorySummary(): ServerMemorySummary {
  return { TotalSystemMemoryGiB: 0 };
}

export const ServerMemorySummary = {
  encode(message: ServerMemorySummary, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.TotalSystemMemoryGiB !== 0) {
      writer.uint32(13).float(message.TotalSystemMemoryGiB);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerMemorySummary {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerMemorySummary();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.TotalSystemMemoryGiB = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerMemorySummary {
    return {
      TotalSystemMemoryGiB: isSet(object.TotalSystemMemoryGiB) ? globalThis.Number(object.TotalSystemMemoryGiB) : 0,
    };
  },

  toJSON(message: ServerMemorySummary): unknown {
    const obj: any = {};
    if (message.TotalSystemMemoryGiB !== 0) {
      obj.TotalSystemMemoryGiB = message.TotalSystemMemoryGiB;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerMemorySummary>, I>>(base?: I): ServerMemorySummary {
    return ServerMemorySummary.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerMemorySummary>, I>>(object: I): ServerMemorySummary {
    const message = createBaseServerMemorySummary();
    message.TotalSystemMemoryGiB = object.TotalSystemMemoryGiB ?? 0;
    return message;
  },
};

function createBaseServerMemory(): ServerMemory {
  return {
    id: "",
    name: "",
    capacityMiB: 0,
    deviceLocator: "",
    manufacturer: "",
    memoryType: "",
    operatingSpeedMhz: 0,
    partNumber: "",
    serialNumber: "",
  };
}

export const ServerMemory = {
  encode(message: ServerMemory, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.capacityMiB !== 0) {
      writer.uint32(24).int32(message.capacityMiB);
    }
    if (message.deviceLocator !== "") {
      writer.uint32(34).string(message.deviceLocator);
    }
    if (message.manufacturer !== "") {
      writer.uint32(42).string(message.manufacturer);
    }
    if (message.memoryType !== "") {
      writer.uint32(50).string(message.memoryType);
    }
    if (message.operatingSpeedMhz !== 0) {
      writer.uint32(56).int32(message.operatingSpeedMhz);
    }
    if (message.partNumber !== "") {
      writer.uint32(66).string(message.partNumber);
    }
    if (message.serialNumber !== "") {
      writer.uint32(74).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerMemory {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerMemory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.capacityMiB = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceLocator = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.memoryType = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.operatingSpeedMhz = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerMemory {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      capacityMiB: isSet(object.capacityMiB) ? globalThis.Number(object.capacityMiB) : 0,
      deviceLocator: isSet(object.deviceLocator) ? globalThis.String(object.deviceLocator) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      memoryType: isSet(object.memoryType) ? globalThis.String(object.memoryType) : "",
      operatingSpeedMhz: isSet(object.operatingSpeedMhz) ? globalThis.Number(object.operatingSpeedMhz) : 0,
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
    };
  },

  toJSON(message: ServerMemory): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.capacityMiB !== 0) {
      obj.capacityMiB = Math.round(message.capacityMiB);
    }
    if (message.deviceLocator !== "") {
      obj.deviceLocator = message.deviceLocator;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.memoryType !== "") {
      obj.memoryType = message.memoryType;
    }
    if (message.operatingSpeedMhz !== 0) {
      obj.operatingSpeedMhz = Math.round(message.operatingSpeedMhz);
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerMemory>, I>>(base?: I): ServerMemory {
    return ServerMemory.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerMemory>, I>>(object: I): ServerMemory {
    const message = createBaseServerMemory();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.capacityMiB = object.capacityMiB ?? 0;
    message.deviceLocator = object.deviceLocator ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.memoryType = object.memoryType ?? "";
    message.operatingSpeedMhz = object.operatingSpeedMhz ?? 0;
    message.partNumber = object.partNumber ?? "";
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseServerTrustedModule(): ServerTrustedModule {
  return { firmwareVersion: "", interfaceType: "" };
}

export const ServerTrustedModule = {
  encode(message: ServerTrustedModule, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.firmwareVersion !== "") {
      writer.uint32(10).string(message.firmwareVersion);
    }
    if (message.interfaceType !== "") {
      writer.uint32(18).string(message.interfaceType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerTrustedModule {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerTrustedModule();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.firmwareVersion = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.interfaceType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerTrustedModule {
    return {
      firmwareVersion: isSet(object.firmwareVersion) ? globalThis.String(object.firmwareVersion) : "",
      interfaceType: isSet(object.interfaceType) ? globalThis.String(object.interfaceType) : "",
    };
  },

  toJSON(message: ServerTrustedModule): unknown {
    const obj: any = {};
    if (message.firmwareVersion !== "") {
      obj.firmwareVersion = message.firmwareVersion;
    }
    if (message.interfaceType !== "") {
      obj.interfaceType = message.interfaceType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerTrustedModule>, I>>(base?: I): ServerTrustedModule {
    return ServerTrustedModule.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerTrustedModule>, I>>(object: I): ServerTrustedModule {
    const message = createBaseServerTrustedModule();
    message.firmwareVersion = object.firmwareVersion ?? "";
    message.interfaceType = object.interfaceType ?? "";
    return message;
  },
};

function createBaseServerEthernetInterface(): ServerEthernetInterface {
  return { id: "", name: "", description: "", macAddress: "" };
}

export const ServerEthernetInterface = {
  encode(message: ServerEthernetInterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.macAddress !== "") {
      writer.uint32(34).string(message.macAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerEthernetInterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerEthernetInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.macAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerEthernetInterface {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
    };
  },

  toJSON(message: ServerEthernetInterface): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerEthernetInterface>, I>>(base?: I): ServerEthernetInterface {
    return ServerEthernetInterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerEthernetInterface>, I>>(object: I): ServerEthernetInterface {
    const message = createBaseServerEthernetInterface();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.macAddress = object.macAddress ?? "";
    return message;
  },
};

function createBaseServerPcieInterface(): ServerPcieInterface {
  return { maxLanes: 0, lanesInUse: 0, pcieType: "" };
}

export const ServerPcieInterface = {
  encode(message: ServerPcieInterface, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maxLanes !== 0) {
      writer.uint32(8).int32(message.maxLanes);
    }
    if (message.lanesInUse !== 0) {
      writer.uint32(16).int32(message.lanesInUse);
    }
    if (message.pcieType !== "") {
      writer.uint32(26).string(message.pcieType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerPcieInterface {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerPcieInterface();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.maxLanes = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.lanesInUse = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pcieType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerPcieInterface {
    return {
      maxLanes: isSet(object.maxLanes) ? globalThis.Number(object.maxLanes) : 0,
      lanesInUse: isSet(object.lanesInUse) ? globalThis.Number(object.lanesInUse) : 0,
      pcieType: isSet(object.pcieType) ? globalThis.String(object.pcieType) : "",
    };
  },

  toJSON(message: ServerPcieInterface): unknown {
    const obj: any = {};
    if (message.maxLanes !== 0) {
      obj.maxLanes = Math.round(message.maxLanes);
    }
    if (message.lanesInUse !== 0) {
      obj.lanesInUse = Math.round(message.lanesInUse);
    }
    if (message.pcieType !== "") {
      obj.pcieType = message.pcieType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerPcieInterface>, I>>(base?: I): ServerPcieInterface {
    return ServerPcieInterface.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerPcieInterface>, I>>(object: I): ServerPcieInterface {
    const message = createBaseServerPcieInterface();
    message.maxLanes = object.maxLanes ?? 0;
    message.lanesInUse = object.lanesInUse ?? 0;
    message.pcieType = object.pcieType ?? "";
    return message;
  },
};

function createBaseServerPcieDevice(): ServerPcieDevice {
  return {
    id: "",
    slotNumber: 0,
    name: "",
    partNumber: "",
    firmwareVersion: "",
    manufacturer: "",
    sku: "",
    serialNumber: "",
    model: "",
    pcieInterface: undefined,
  };
}

export const ServerPcieDevice = {
  encode(message: ServerPcieDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.slotNumber !== 0) {
      writer.uint32(16).int32(message.slotNumber);
    }
    if (message.name !== "") {
      writer.uint32(26).string(message.name);
    }
    if (message.partNumber !== "") {
      writer.uint32(34).string(message.partNumber);
    }
    if (message.firmwareVersion !== "") {
      writer.uint32(42).string(message.firmwareVersion);
    }
    if (message.manufacturer !== "") {
      writer.uint32(50).string(message.manufacturer);
    }
    if (message.sku !== "") {
      writer.uint32(58).string(message.sku);
    }
    if (message.serialNumber !== "") {
      writer.uint32(66).string(message.serialNumber);
    }
    if (message.model !== "") {
      writer.uint32(74).string(message.model);
    }
    if (message.pcieInterface !== undefined) {
      ServerPcieInterface.encode(message.pcieInterface, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerPcieDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerPcieDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.slotNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firmwareVersion = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sku = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.model = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pcieInterface = ServerPcieInterface.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerPcieDevice {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      slotNumber: isSet(object.slotNumber) ? globalThis.Number(object.slotNumber) : 0,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      firmwareVersion: isSet(object.firmwareVersion) ? globalThis.String(object.firmwareVersion) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      sku: isSet(object.sku) ? globalThis.String(object.sku) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      pcieInterface: isSet(object.pcieInterface) ? ServerPcieInterface.fromJSON(object.pcieInterface) : undefined,
    };
  },

  toJSON(message: ServerPcieDevice): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.slotNumber !== 0) {
      obj.slotNumber = Math.round(message.slotNumber);
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.firmwareVersion !== "") {
      obj.firmwareVersion = message.firmwareVersion;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.sku !== "") {
      obj.sku = message.sku;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.pcieInterface !== undefined) {
      obj.pcieInterface = ServerPcieInterface.toJSON(message.pcieInterface);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerPcieDevice>, I>>(base?: I): ServerPcieDevice {
    return ServerPcieDevice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerPcieDevice>, I>>(object: I): ServerPcieDevice {
    const message = createBaseServerPcieDevice();
    message.id = object.id ?? "";
    message.slotNumber = object.slotNumber ?? 0;
    message.name = object.name ?? "";
    message.partNumber = object.partNumber ?? "";
    message.firmwareVersion = object.firmwareVersion ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.sku = object.sku ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.model = object.model ?? "";
    message.pcieInterface = (object.pcieInterface !== undefined && object.pcieInterface !== null)
      ? ServerPcieInterface.fromPartial(object.pcieInterface)
      : undefined;
    return message;
  },
};

function createBaseServerPCIeFunction(): ServerPCIeFunction {
  return { id: "", name: "", vendorId: "", deviceId: "", deviceName: "", ethernetInterfaceIds: [] };
}

export const ServerPCIeFunction = {
  encode(message: ServerPCIeFunction, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.vendorId !== "") {
      writer.uint32(26).string(message.vendorId);
    }
    if (message.deviceId !== "") {
      writer.uint32(34).string(message.deviceId);
    }
    if (message.deviceName !== "") {
      writer.uint32(42).string(message.deviceName);
    }
    for (const v of message.ethernetInterfaceIds) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerPCIeFunction {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerPCIeFunction();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.vendorId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.deviceName = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ethernetInterfaceIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerPCIeFunction {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      vendorId: isSet(object.vendorId) ? globalThis.String(object.vendorId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
      ethernetInterfaceIds: globalThis.Array.isArray(object?.ethernetInterfaceIds)
        ? object.ethernetInterfaceIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ServerPCIeFunction): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.vendorId !== "") {
      obj.vendorId = message.vendorId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.deviceName !== "") {
      obj.deviceName = message.deviceName;
    }
    if (message.ethernetInterfaceIds?.length) {
      obj.ethernetInterfaceIds = message.ethernetInterfaceIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerPCIeFunction>, I>>(base?: I): ServerPCIeFunction {
    return ServerPCIeFunction.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerPCIeFunction>, I>>(object: I): ServerPCIeFunction {
    const message = createBaseServerPCIeFunction();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.vendorId = object.vendorId ?? "";
    message.deviceId = object.deviceId ?? "";
    message.deviceName = object.deviceName ?? "";
    message.ethernetInterfaceIds = object.ethernetInterfaceIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseServerStorageController(): ServerStorageController {
  return { id: "", manufacturer: "", model: "", serialNumber: "", firmwareVersion: "" };
}

export const ServerStorageController = {
  encode(message: ServerStorageController, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.manufacturer !== "") {
      writer.uint32(18).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.serialNumber !== "") {
      writer.uint32(34).string(message.serialNumber);
    }
    if (message.firmwareVersion !== "") {
      writer.uint32(42).string(message.firmwareVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerStorageController {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerStorageController();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firmwareVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerStorageController {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      firmwareVersion: isSet(object.firmwareVersion) ? globalThis.String(object.firmwareVersion) : "",
    };
  },

  toJSON(message: ServerStorageController): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.firmwareVersion !== "") {
      obj.firmwareVersion = message.firmwareVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerStorageController>, I>>(base?: I): ServerStorageController {
    return ServerStorageController.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerStorageController>, I>>(object: I): ServerStorageController {
    const message = createBaseServerStorageController();
    message.id = object.id ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.firmwareVersion = object.firmwareVersion ?? "";
    return message;
  },
};

function createBaseServerStorage(): ServerStorage {
  return { id: "", name: "", description: "", controllers: [] };
}

export const ServerStorage = {
  encode(message: ServerStorage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.controllers) {
      ServerStorageController.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerStorage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerStorage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.controllers.push(ServerStorageController.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerStorage {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      controllers: globalThis.Array.isArray(object?.controllers)
        ? object.controllers.map((e: any) => ServerStorageController.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerStorage): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.controllers?.length) {
      obj.controllers = message.controllers.map((e) => ServerStorageController.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerStorage>, I>>(base?: I): ServerStorage {
    return ServerStorage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerStorage>, I>>(object: I): ServerStorage {
    const message = createBaseServerStorage();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.controllers = object.controllers?.map((e) => ServerStorageController.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerSystem(): ServerSystem {
  return {
    id: "",
    name: "",
    description: "",
    powerState: "",
    health: "",
    biosVersion: "",
    hostName: "",
    memorySummary: undefined,
    trustedModules: [],
    ethernetInterfaces: [],
    pcieDevices: [],
    pcieFunctions: [],
    processors: [],
    memorys: [],
    storages: [],
  };
}

export const ServerSystem = {
  encode(message: ServerSystem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.powerState !== "") {
      writer.uint32(34).string(message.powerState);
    }
    if (message.health !== "") {
      writer.uint32(42).string(message.health);
    }
    if (message.biosVersion !== "") {
      writer.uint32(50).string(message.biosVersion);
    }
    if (message.hostName !== "") {
      writer.uint32(58).string(message.hostName);
    }
    if (message.memorySummary !== undefined) {
      ServerMemorySummary.encode(message.memorySummary, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.trustedModules) {
      ServerTrustedModule.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.ethernetInterfaces) {
      ServerEthernetInterface.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.pcieDevices) {
      ServerPcieDevice.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.pcieFunctions) {
      ServerPCIeFunction.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.processors) {
      ServerProcessor.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.memorys) {
      ServerMemory.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.storages) {
      ServerStorage.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerSystem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerSystem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.powerState = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.health = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.biosVersion = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.hostName = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.memorySummary = ServerMemorySummary.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.trustedModules.push(ServerTrustedModule.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ethernetInterfaces.push(ServerEthernetInterface.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.pcieDevices.push(ServerPcieDevice.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.pcieFunctions.push(ServerPCIeFunction.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.processors.push(ServerProcessor.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.memorys.push(ServerMemory.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.storages.push(ServerStorage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerSystem {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      powerState: isSet(object.powerState) ? globalThis.String(object.powerState) : "",
      health: isSet(object.health) ? globalThis.String(object.health) : "",
      biosVersion: isSet(object.biosVersion) ? globalThis.String(object.biosVersion) : "",
      hostName: isSet(object.hostName) ? globalThis.String(object.hostName) : "",
      memorySummary: isSet(object.memorySummary) ? ServerMemorySummary.fromJSON(object.memorySummary) : undefined,
      trustedModules: globalThis.Array.isArray(object?.trustedModules)
        ? object.trustedModules.map((e: any) => ServerTrustedModule.fromJSON(e))
        : [],
      ethernetInterfaces: globalThis.Array.isArray(object?.ethernetInterfaces)
        ? object.ethernetInterfaces.map((e: any) => ServerEthernetInterface.fromJSON(e))
        : [],
      pcieDevices: globalThis.Array.isArray(object?.pcieDevices)
        ? object.pcieDevices.map((e: any) => ServerPcieDevice.fromJSON(e))
        : [],
      pcieFunctions: globalThis.Array.isArray(object?.pcieFunctions)
        ? object.pcieFunctions.map((e: any) => ServerPCIeFunction.fromJSON(e))
        : [],
      processors: globalThis.Array.isArray(object?.processors)
        ? object.processors.map((e: any) => ServerProcessor.fromJSON(e))
        : [],
      memorys: globalThis.Array.isArray(object?.memorys)
        ? object.memorys.map((e: any) => ServerMemory.fromJSON(e))
        : [],
      storages: globalThis.Array.isArray(object?.storages)
        ? object.storages.map((e: any) => ServerStorage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerSystem): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.powerState !== "") {
      obj.powerState = message.powerState;
    }
    if (message.health !== "") {
      obj.health = message.health;
    }
    if (message.biosVersion !== "") {
      obj.biosVersion = message.biosVersion;
    }
    if (message.hostName !== "") {
      obj.hostName = message.hostName;
    }
    if (message.memorySummary !== undefined) {
      obj.memorySummary = ServerMemorySummary.toJSON(message.memorySummary);
    }
    if (message.trustedModules?.length) {
      obj.trustedModules = message.trustedModules.map((e) => ServerTrustedModule.toJSON(e));
    }
    if (message.ethernetInterfaces?.length) {
      obj.ethernetInterfaces = message.ethernetInterfaces.map((e) => ServerEthernetInterface.toJSON(e));
    }
    if (message.pcieDevices?.length) {
      obj.pcieDevices = message.pcieDevices.map((e) => ServerPcieDevice.toJSON(e));
    }
    if (message.pcieFunctions?.length) {
      obj.pcieFunctions = message.pcieFunctions.map((e) => ServerPCIeFunction.toJSON(e));
    }
    if (message.processors?.length) {
      obj.processors = message.processors.map((e) => ServerProcessor.toJSON(e));
    }
    if (message.memorys?.length) {
      obj.memorys = message.memorys.map((e) => ServerMemory.toJSON(e));
    }
    if (message.storages?.length) {
      obj.storages = message.storages.map((e) => ServerStorage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerSystem>, I>>(base?: I): ServerSystem {
    return ServerSystem.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerSystem>, I>>(object: I): ServerSystem {
    const message = createBaseServerSystem();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.powerState = object.powerState ?? "";
    message.health = object.health ?? "";
    message.biosVersion = object.biosVersion ?? "";
    message.hostName = object.hostName ?? "";
    message.memorySummary = (object.memorySummary !== undefined && object.memorySummary !== null)
      ? ServerMemorySummary.fromPartial(object.memorySummary)
      : undefined;
    message.trustedModules = object.trustedModules?.map((e) => ServerTrustedModule.fromPartial(e)) || [];
    message.ethernetInterfaces = object.ethernetInterfaces?.map((e) => ServerEthernetInterface.fromPartial(e)) || [];
    message.pcieDevices = object.pcieDevices?.map((e) => ServerPcieDevice.fromPartial(e)) || [];
    message.pcieFunctions = object.pcieFunctions?.map((e) => ServerPCIeFunction.fromPartial(e)) || [];
    message.processors = object.processors?.map((e) => ServerProcessor.fromPartial(e)) || [];
    message.memorys = object.memorys?.map((e) => ServerMemory.fromPartial(e)) || [];
    message.storages = object.storages?.map((e) => ServerStorage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerDrive(): ServerDrive {
  return {
    id: "",
    name: "",
    description: "",
    manufacturer: "",
    model: "",
    mediaType: "",
    blockSizeBytes: 0,
    capacityBytes: 0,
    revision: "",
    serialNumber: "",
  };
}

export const ServerDrive = {
  encode(message: ServerDrive, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.manufacturer !== "") {
      writer.uint32(34).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.mediaType !== "") {
      writer.uint32(50).string(message.mediaType);
    }
    if (message.blockSizeBytes !== 0) {
      writer.uint32(56).int32(message.blockSizeBytes);
    }
    if (message.capacityBytes !== 0) {
      writer.uint32(64).int64(message.capacityBytes);
    }
    if (message.revision !== "") {
      writer.uint32(74).string(message.revision);
    }
    if (message.serialNumber !== "") {
      writer.uint32(82).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerDrive {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerDrive();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.mediaType = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.blockSizeBytes = reader.int32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.capacityBytes = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.revision = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerDrive {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      mediaType: isSet(object.mediaType) ? globalThis.String(object.mediaType) : "",
      blockSizeBytes: isSet(object.blockSizeBytes) ? globalThis.Number(object.blockSizeBytes) : 0,
      capacityBytes: isSet(object.capacityBytes) ? globalThis.Number(object.capacityBytes) : 0,
      revision: isSet(object.revision) ? globalThis.String(object.revision) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
    };
  },

  toJSON(message: ServerDrive): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.mediaType !== "") {
      obj.mediaType = message.mediaType;
    }
    if (message.blockSizeBytes !== 0) {
      obj.blockSizeBytes = Math.round(message.blockSizeBytes);
    }
    if (message.capacityBytes !== 0) {
      obj.capacityBytes = Math.round(message.capacityBytes);
    }
    if (message.revision !== "") {
      obj.revision = message.revision;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerDrive>, I>>(base?: I): ServerDrive {
    return ServerDrive.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerDrive>, I>>(object: I): ServerDrive {
    const message = createBaseServerDrive();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.mediaType = object.mediaType ?? "";
    message.blockSizeBytes = object.blockSizeBytes ?? 0;
    message.capacityBytes = object.capacityBytes ?? 0;
    message.revision = object.revision ?? "";
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseServerTemperature(): ServerTemperature {
  return {
    name: "",
    sensorNumber: 0,
    memberId: "",
    physicalContext: "",
    readingCelsius: 0,
    lowerThresholdCritical: 0,
    lowerThresholdNonCritical: 0,
    upperThresholdCritical: 0,
    upperThresholdNonCritical: 0,
    minReadingRangeTemp: 0,
    maxReadingRangeTemp: 0,
  };
}

export const ServerTemperature = {
  encode(message: ServerTemperature, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.sensorNumber !== 0) {
      writer.uint32(16).int32(message.sensorNumber);
    }
    if (message.memberId !== "") {
      writer.uint32(26).string(message.memberId);
    }
    if (message.physicalContext !== "") {
      writer.uint32(34).string(message.physicalContext);
    }
    if (message.readingCelsius !== 0) {
      writer.uint32(45).float(message.readingCelsius);
    }
    if (message.lowerThresholdCritical !== 0) {
      writer.uint32(53).float(message.lowerThresholdCritical);
    }
    if (message.lowerThresholdNonCritical !== 0) {
      writer.uint32(61).float(message.lowerThresholdNonCritical);
    }
    if (message.upperThresholdCritical !== 0) {
      writer.uint32(69).float(message.upperThresholdCritical);
    }
    if (message.upperThresholdNonCritical !== 0) {
      writer.uint32(77).float(message.upperThresholdNonCritical);
    }
    if (message.minReadingRangeTemp !== 0) {
      writer.uint32(85).float(message.minReadingRangeTemp);
    }
    if (message.maxReadingRangeTemp !== 0) {
      writer.uint32(93).float(message.maxReadingRangeTemp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerTemperature {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerTemperature();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sensorNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.physicalContext = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.readingCelsius = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.lowerThresholdCritical = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.lowerThresholdNonCritical = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.upperThresholdCritical = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.upperThresholdNonCritical = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.minReadingRangeTemp = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.maxReadingRangeTemp = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerTemperature {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      sensorNumber: isSet(object.sensorNumber) ? globalThis.Number(object.sensorNumber) : 0,
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      physicalContext: isSet(object.physicalContext) ? globalThis.String(object.physicalContext) : "",
      readingCelsius: isSet(object.readingCelsius) ? globalThis.Number(object.readingCelsius) : 0,
      lowerThresholdCritical: isSet(object.lowerThresholdCritical)
        ? globalThis.Number(object.lowerThresholdCritical)
        : 0,
      lowerThresholdNonCritical: isSet(object.lowerThresholdNonCritical)
        ? globalThis.Number(object.lowerThresholdNonCritical)
        : 0,
      upperThresholdCritical: isSet(object.upperThresholdCritical)
        ? globalThis.Number(object.upperThresholdCritical)
        : 0,
      upperThresholdNonCritical: isSet(object.upperThresholdNonCritical)
        ? globalThis.Number(object.upperThresholdNonCritical)
        : 0,
      minReadingRangeTemp: isSet(object.minReadingRangeTemp) ? globalThis.Number(object.minReadingRangeTemp) : 0,
      maxReadingRangeTemp: isSet(object.maxReadingRangeTemp) ? globalThis.Number(object.maxReadingRangeTemp) : 0,
    };
  },

  toJSON(message: ServerTemperature): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.sensorNumber !== 0) {
      obj.sensorNumber = Math.round(message.sensorNumber);
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.physicalContext !== "") {
      obj.physicalContext = message.physicalContext;
    }
    if (message.readingCelsius !== 0) {
      obj.readingCelsius = message.readingCelsius;
    }
    if (message.lowerThresholdCritical !== 0) {
      obj.lowerThresholdCritical = message.lowerThresholdCritical;
    }
    if (message.lowerThresholdNonCritical !== 0) {
      obj.lowerThresholdNonCritical = message.lowerThresholdNonCritical;
    }
    if (message.upperThresholdCritical !== 0) {
      obj.upperThresholdCritical = message.upperThresholdCritical;
    }
    if (message.upperThresholdNonCritical !== 0) {
      obj.upperThresholdNonCritical = message.upperThresholdNonCritical;
    }
    if (message.minReadingRangeTemp !== 0) {
      obj.minReadingRangeTemp = message.minReadingRangeTemp;
    }
    if (message.maxReadingRangeTemp !== 0) {
      obj.maxReadingRangeTemp = message.maxReadingRangeTemp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerTemperature>, I>>(base?: I): ServerTemperature {
    return ServerTemperature.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerTemperature>, I>>(object: I): ServerTemperature {
    const message = createBaseServerTemperature();
    message.name = object.name ?? "";
    message.sensorNumber = object.sensorNumber ?? 0;
    message.memberId = object.memberId ?? "";
    message.physicalContext = object.physicalContext ?? "";
    message.readingCelsius = object.readingCelsius ?? 0;
    message.lowerThresholdCritical = object.lowerThresholdCritical ?? 0;
    message.lowerThresholdNonCritical = object.lowerThresholdNonCritical ?? 0;
    message.upperThresholdCritical = object.upperThresholdCritical ?? 0;
    message.upperThresholdNonCritical = object.upperThresholdNonCritical ?? 0;
    message.minReadingRangeTemp = object.minReadingRangeTemp ?? 0;
    message.maxReadingRangeTemp = object.maxReadingRangeTemp ?? 0;
    return message;
  },
};

function createBaseServerThermal(): ServerThermal {
  return { temperatures: [] };
}

export const ServerThermal = {
  encode(message: ServerThermal, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.temperatures) {
      ServerTemperature.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerThermal {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerThermal();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.temperatures.push(ServerTemperature.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerThermal {
    return {
      temperatures: globalThis.Array.isArray(object?.temperatures)
        ? object.temperatures.map((e: any) => ServerTemperature.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerThermal): unknown {
    const obj: any = {};
    if (message.temperatures?.length) {
      obj.temperatures = message.temperatures.map((e) => ServerTemperature.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerThermal>, I>>(base?: I): ServerThermal {
    return ServerThermal.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerThermal>, I>>(object: I): ServerThermal {
    const message = createBaseServerThermal();
    message.temperatures = object.temperatures?.map((e) => ServerTemperature.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerVoltage(): ServerVoltage {
  return {
    name: "",
    sensorNumber: 0,
    memberId: "",
    physicalContext: "",
    readingVolts: 0,
    lowerThresholdCritical: 0,
    lowerThresholdNonCritical: 0,
    upperThresholdCritical: 0,
    upperThresholdNonCritical: 0,
    minReadingRangeVolts: 0,
    maxReadingRangeVolts: 0,
  };
}

export const ServerVoltage = {
  encode(message: ServerVoltage, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.sensorNumber !== 0) {
      writer.uint32(16).int32(message.sensorNumber);
    }
    if (message.memberId !== "") {
      writer.uint32(26).string(message.memberId);
    }
    if (message.physicalContext !== "") {
      writer.uint32(34).string(message.physicalContext);
    }
    if (message.readingVolts !== 0) {
      writer.uint32(45).float(message.readingVolts);
    }
    if (message.lowerThresholdCritical !== 0) {
      writer.uint32(53).float(message.lowerThresholdCritical);
    }
    if (message.lowerThresholdNonCritical !== 0) {
      writer.uint32(61).float(message.lowerThresholdNonCritical);
    }
    if (message.upperThresholdCritical !== 0) {
      writer.uint32(69).float(message.upperThresholdCritical);
    }
    if (message.upperThresholdNonCritical !== 0) {
      writer.uint32(77).float(message.upperThresholdNonCritical);
    }
    if (message.minReadingRangeVolts !== 0) {
      writer.uint32(85).float(message.minReadingRangeVolts);
    }
    if (message.maxReadingRangeVolts !== 0) {
      writer.uint32(93).float(message.maxReadingRangeVolts);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerVoltage {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerVoltage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sensorNumber = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.physicalContext = reader.string();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.readingVolts = reader.float();
          continue;
        case 6:
          if (tag !== 53) {
            break;
          }

          message.lowerThresholdCritical = reader.float();
          continue;
        case 7:
          if (tag !== 61) {
            break;
          }

          message.lowerThresholdNonCritical = reader.float();
          continue;
        case 8:
          if (tag !== 69) {
            break;
          }

          message.upperThresholdCritical = reader.float();
          continue;
        case 9:
          if (tag !== 77) {
            break;
          }

          message.upperThresholdNonCritical = reader.float();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.minReadingRangeVolts = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.maxReadingRangeVolts = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerVoltage {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      sensorNumber: isSet(object.sensorNumber) ? globalThis.Number(object.sensorNumber) : 0,
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      physicalContext: isSet(object.physicalContext) ? globalThis.String(object.physicalContext) : "",
      readingVolts: isSet(object.readingVolts) ? globalThis.Number(object.readingVolts) : 0,
      lowerThresholdCritical: isSet(object.lowerThresholdCritical)
        ? globalThis.Number(object.lowerThresholdCritical)
        : 0,
      lowerThresholdNonCritical: isSet(object.lowerThresholdNonCritical)
        ? globalThis.Number(object.lowerThresholdNonCritical)
        : 0,
      upperThresholdCritical: isSet(object.upperThresholdCritical)
        ? globalThis.Number(object.upperThresholdCritical)
        : 0,
      upperThresholdNonCritical: isSet(object.upperThresholdNonCritical)
        ? globalThis.Number(object.upperThresholdNonCritical)
        : 0,
      minReadingRangeVolts: isSet(object.minReadingRangeVolts) ? globalThis.Number(object.minReadingRangeVolts) : 0,
      maxReadingRangeVolts: isSet(object.maxReadingRangeVolts) ? globalThis.Number(object.maxReadingRangeVolts) : 0,
    };
  },

  toJSON(message: ServerVoltage): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.sensorNumber !== 0) {
      obj.sensorNumber = Math.round(message.sensorNumber);
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.physicalContext !== "") {
      obj.physicalContext = message.physicalContext;
    }
    if (message.readingVolts !== 0) {
      obj.readingVolts = message.readingVolts;
    }
    if (message.lowerThresholdCritical !== 0) {
      obj.lowerThresholdCritical = message.lowerThresholdCritical;
    }
    if (message.lowerThresholdNonCritical !== 0) {
      obj.lowerThresholdNonCritical = message.lowerThresholdNonCritical;
    }
    if (message.upperThresholdCritical !== 0) {
      obj.upperThresholdCritical = message.upperThresholdCritical;
    }
    if (message.upperThresholdNonCritical !== 0) {
      obj.upperThresholdNonCritical = message.upperThresholdNonCritical;
    }
    if (message.minReadingRangeVolts !== 0) {
      obj.minReadingRangeVolts = message.minReadingRangeVolts;
    }
    if (message.maxReadingRangeVolts !== 0) {
      obj.maxReadingRangeVolts = message.maxReadingRangeVolts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerVoltage>, I>>(base?: I): ServerVoltage {
    return ServerVoltage.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerVoltage>, I>>(object: I): ServerVoltage {
    const message = createBaseServerVoltage();
    message.name = object.name ?? "";
    message.sensorNumber = object.sensorNumber ?? 0;
    message.memberId = object.memberId ?? "";
    message.physicalContext = object.physicalContext ?? "";
    message.readingVolts = object.readingVolts ?? 0;
    message.lowerThresholdCritical = object.lowerThresholdCritical ?? 0;
    message.lowerThresholdNonCritical = object.lowerThresholdNonCritical ?? 0;
    message.upperThresholdCritical = object.upperThresholdCritical ?? 0;
    message.upperThresholdNonCritical = object.upperThresholdNonCritical ?? 0;
    message.minReadingRangeVolts = object.minReadingRangeVolts ?? 0;
    message.maxReadingRangeVolts = object.maxReadingRangeVolts ?? 0;
    return message;
  },
};

function createBaseServerInputRange(): ServerInputRange {
  return { minimumVoltage: 0, maximumVoltage: 0, minimumFrequencyHz: 0, maximumFrequencyHz: 0, outputWattage: 0 };
}

export const ServerInputRange = {
  encode(message: ServerInputRange, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.minimumVoltage !== 0) {
      writer.uint32(13).float(message.minimumVoltage);
    }
    if (message.maximumVoltage !== 0) {
      writer.uint32(21).float(message.maximumVoltage);
    }
    if (message.minimumFrequencyHz !== 0) {
      writer.uint32(29).float(message.minimumFrequencyHz);
    }
    if (message.maximumFrequencyHz !== 0) {
      writer.uint32(37).float(message.maximumFrequencyHz);
    }
    if (message.outputWattage !== 0) {
      writer.uint32(45).float(message.outputWattage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerInputRange {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerInputRange();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 13) {
            break;
          }

          message.minimumVoltage = reader.float();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.maximumVoltage = reader.float();
          continue;
        case 3:
          if (tag !== 29) {
            break;
          }

          message.minimumFrequencyHz = reader.float();
          continue;
        case 4:
          if (tag !== 37) {
            break;
          }

          message.maximumFrequencyHz = reader.float();
          continue;
        case 5:
          if (tag !== 45) {
            break;
          }

          message.outputWattage = reader.float();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerInputRange {
    return {
      minimumVoltage: isSet(object.minimumVoltage) ? globalThis.Number(object.minimumVoltage) : 0,
      maximumVoltage: isSet(object.maximumVoltage) ? globalThis.Number(object.maximumVoltage) : 0,
      minimumFrequencyHz: isSet(object.minimumFrequencyHz) ? globalThis.Number(object.minimumFrequencyHz) : 0,
      maximumFrequencyHz: isSet(object.maximumFrequencyHz) ? globalThis.Number(object.maximumFrequencyHz) : 0,
      outputWattage: isSet(object.outputWattage) ? globalThis.Number(object.outputWattage) : 0,
    };
  },

  toJSON(message: ServerInputRange): unknown {
    const obj: any = {};
    if (message.minimumVoltage !== 0) {
      obj.minimumVoltage = message.minimumVoltage;
    }
    if (message.maximumVoltage !== 0) {
      obj.maximumVoltage = message.maximumVoltage;
    }
    if (message.minimumFrequencyHz !== 0) {
      obj.minimumFrequencyHz = message.minimumFrequencyHz;
    }
    if (message.maximumFrequencyHz !== 0) {
      obj.maximumFrequencyHz = message.maximumFrequencyHz;
    }
    if (message.outputWattage !== 0) {
      obj.outputWattage = message.outputWattage;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerInputRange>, I>>(base?: I): ServerInputRange {
    return ServerInputRange.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerInputRange>, I>>(object: I): ServerInputRange {
    const message = createBaseServerInputRange();
    message.minimumVoltage = object.minimumVoltage ?? 0;
    message.maximumVoltage = object.maximumVoltage ?? 0;
    message.minimumFrequencyHz = object.minimumFrequencyHz ?? 0;
    message.maximumFrequencyHz = object.maximumFrequencyHz ?? 0;
    message.outputWattage = object.outputWattage ?? 0;
    return message;
  },
};

function createBaseServerPowerSupply(): ServerPowerSupply {
  return {
    name: "",
    memberId: "",
    manufacturer: "",
    model: "",
    partNumber: "",
    serialNumber: "",
    sparePartNumber: "",
    firmwareVersion: "",
    powerSupplyType: "",
    lineInputVoltage: 0,
    powerInputWatts: 0,
    inputRanges: [],
  };
}

export const ServerPowerSupply = {
  encode(message: ServerPowerSupply, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.memberId !== "") {
      writer.uint32(18).string(message.memberId);
    }
    if (message.manufacturer !== "") {
      writer.uint32(26).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    if (message.partNumber !== "") {
      writer.uint32(42).string(message.partNumber);
    }
    if (message.serialNumber !== "") {
      writer.uint32(50).string(message.serialNumber);
    }
    if (message.sparePartNumber !== "") {
      writer.uint32(58).string(message.sparePartNumber);
    }
    if (message.firmwareVersion !== "") {
      writer.uint32(66).string(message.firmwareVersion);
    }
    if (message.powerSupplyType !== "") {
      writer.uint32(74).string(message.powerSupplyType);
    }
    if (message.lineInputVoltage !== 0) {
      writer.uint32(85).float(message.lineInputVoltage);
    }
    if (message.powerInputWatts !== 0) {
      writer.uint32(93).float(message.powerInputWatts);
    }
    for (const v of message.inputRanges) {
      ServerInputRange.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerPowerSupply {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerPowerSupply();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.sparePartNumber = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.firmwareVersion = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.powerSupplyType = reader.string();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.lineInputVoltage = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.powerInputWatts = reader.float();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.inputRanges.push(ServerInputRange.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerPowerSupply {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      sparePartNumber: isSet(object.sparePartNumber) ? globalThis.String(object.sparePartNumber) : "",
      firmwareVersion: isSet(object.firmwareVersion) ? globalThis.String(object.firmwareVersion) : "",
      powerSupplyType: isSet(object.powerSupplyType) ? globalThis.String(object.powerSupplyType) : "",
      lineInputVoltage: isSet(object.lineInputVoltage) ? globalThis.Number(object.lineInputVoltage) : 0,
      powerInputWatts: isSet(object.powerInputWatts) ? globalThis.Number(object.powerInputWatts) : 0,
      inputRanges: globalThis.Array.isArray(object?.inputRanges)
        ? object.inputRanges.map((e: any) => ServerInputRange.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerPowerSupply): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.sparePartNumber !== "") {
      obj.sparePartNumber = message.sparePartNumber;
    }
    if (message.firmwareVersion !== "") {
      obj.firmwareVersion = message.firmwareVersion;
    }
    if (message.powerSupplyType !== "") {
      obj.powerSupplyType = message.powerSupplyType;
    }
    if (message.lineInputVoltage !== 0) {
      obj.lineInputVoltage = message.lineInputVoltage;
    }
    if (message.powerInputWatts !== 0) {
      obj.powerInputWatts = message.powerInputWatts;
    }
    if (message.inputRanges?.length) {
      obj.inputRanges = message.inputRanges.map((e) => ServerInputRange.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerPowerSupply>, I>>(base?: I): ServerPowerSupply {
    return ServerPowerSupply.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerPowerSupply>, I>>(object: I): ServerPowerSupply {
    const message = createBaseServerPowerSupply();
    message.name = object.name ?? "";
    message.memberId = object.memberId ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.partNumber = object.partNumber ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.sparePartNumber = object.sparePartNumber ?? "";
    message.firmwareVersion = object.firmwareVersion ?? "";
    message.powerSupplyType = object.powerSupplyType ?? "";
    message.lineInputVoltage = object.lineInputVoltage ?? 0;
    message.powerInputWatts = object.powerInputWatts ?? 0;
    message.inputRanges = object.inputRanges?.map((e) => ServerInputRange.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerPower(): ServerPower {
  return { powerSupplies: [], voltages: [] };
}

export const ServerPower = {
  encode(message: ServerPower, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.powerSupplies) {
      ServerPowerSupply.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.voltages) {
      ServerVoltage.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerPower {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerPower();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.powerSupplies.push(ServerPowerSupply.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.voltages.push(ServerVoltage.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerPower {
    return {
      powerSupplies: globalThis.Array.isArray(object?.powerSupplies)
        ? object.powerSupplies.map((e: any) => ServerPowerSupply.fromJSON(e))
        : [],
      voltages: globalThis.Array.isArray(object?.voltages)
        ? object.voltages.map((e: any) => ServerVoltage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerPower): unknown {
    const obj: any = {};
    if (message.powerSupplies?.length) {
      obj.powerSupplies = message.powerSupplies.map((e) => ServerPowerSupply.toJSON(e));
    }
    if (message.voltages?.length) {
      obj.voltages = message.voltages.map((e) => ServerVoltage.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerPower>, I>>(base?: I): ServerPower {
    return ServerPower.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerPower>, I>>(object: I): ServerPower {
    const message = createBaseServerPower();
    message.powerSupplies = object.powerSupplies?.map((e) => ServerPowerSupply.fromPartial(e)) || [];
    message.voltages = object.voltages?.map((e) => ServerVoltage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerAssembly(): ServerAssembly {
  return { memberId: "", producer: "", model: "", serialNumber: "", version: "", physicalContext: "" };
}

export const ServerAssembly = {
  encode(message: ServerAssembly, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.memberId !== "") {
      writer.uint32(10).string(message.memberId);
    }
    if (message.producer !== "") {
      writer.uint32(18).string(message.producer);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.serialNumber !== "") {
      writer.uint32(34).string(message.serialNumber);
    }
    if (message.version !== "") {
      writer.uint32(42).string(message.version);
    }
    if (message.physicalContext !== "") {
      writer.uint32(50).string(message.physicalContext);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerAssembly {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerAssembly();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.memberId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.producer = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.version = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.physicalContext = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerAssembly {
    return {
      memberId: isSet(object.memberId) ? globalThis.String(object.memberId) : "",
      producer: isSet(object.producer) ? globalThis.String(object.producer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      physicalContext: isSet(object.physicalContext) ? globalThis.String(object.physicalContext) : "",
    };
  },

  toJSON(message: ServerAssembly): unknown {
    const obj: any = {};
    if (message.memberId !== "") {
      obj.memberId = message.memberId;
    }
    if (message.producer !== "") {
      obj.producer = message.producer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.physicalContext !== "") {
      obj.physicalContext = message.physicalContext;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerAssembly>, I>>(base?: I): ServerAssembly {
    return ServerAssembly.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerAssembly>, I>>(object: I): ServerAssembly {
    const message = createBaseServerAssembly();
    message.memberId = object.memberId ?? "";
    message.producer = object.producer ?? "";
    message.model = object.model ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.version = object.version ?? "";
    message.physicalContext = object.physicalContext ?? "";
    return message;
  },
};

function createBaseServerNetworkAdaptor(): ServerNetworkAdaptor {
  return { id: "", manufacturer: "", model: "", partNumber: "", serialNumber: "" };
}

export const ServerNetworkAdaptor = {
  encode(message: ServerNetworkAdaptor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.manufacturer !== "") {
      writer.uint32(18).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(26).string(message.model);
    }
    if (message.partNumber !== "") {
      writer.uint32(34).string(message.partNumber);
    }
    if (message.serialNumber !== "") {
      writer.uint32(42).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerNetworkAdaptor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerNetworkAdaptor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.model = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerNetworkAdaptor {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
    };
  },

  toJSON(message: ServerNetworkAdaptor): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerNetworkAdaptor>, I>>(base?: I): ServerNetworkAdaptor {
    return ServerNetworkAdaptor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerNetworkAdaptor>, I>>(object: I): ServerNetworkAdaptor {
    const message = createBaseServerNetworkAdaptor();
    message.id = object.id ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.partNumber = object.partNumber ?? "";
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseServerChassis(): ServerChassis {
  return {
    id: "",
    name: "",
    description: "",
    manufacturer: "",
    model: "",
    serialNumber: "",
    partNumber: "",
    chassisType: "",
    uuid: "",
    heightMm: 0,
    widthMm: 0,
    depthMm: 0,
    weightKg: 0,
    drives: [],
    thermal: undefined,
    power: undefined,
    powerState: "",
    assemblies: [],
    networkAdaptors: [],
    pcieDevices: [],
  };
}

export const ServerChassis = {
  encode(message: ServerChassis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.manufacturer !== "") {
      writer.uint32(34).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.serialNumber !== "") {
      writer.uint32(50).string(message.serialNumber);
    }
    if (message.partNumber !== "") {
      writer.uint32(58).string(message.partNumber);
    }
    if (message.chassisType !== "") {
      writer.uint32(66).string(message.chassisType);
    }
    if (message.uuid !== "") {
      writer.uint32(74).string(message.uuid);
    }
    if (message.heightMm !== 0) {
      writer.uint32(85).float(message.heightMm);
    }
    if (message.widthMm !== 0) {
      writer.uint32(93).float(message.widthMm);
    }
    if (message.depthMm !== 0) {
      writer.uint32(101).float(message.depthMm);
    }
    if (message.weightKg !== 0) {
      writer.uint32(109).float(message.weightKg);
    }
    for (const v of message.drives) {
      ServerDrive.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    if (message.thermal !== undefined) {
      ServerThermal.encode(message.thermal, writer.uint32(122).fork()).ldelim();
    }
    if (message.power !== undefined) {
      ServerPower.encode(message.power, writer.uint32(130).fork()).ldelim();
    }
    if (message.powerState !== "") {
      writer.uint32(138).string(message.powerState);
    }
    for (const v of message.assemblies) {
      ServerAssembly.encode(v!, writer.uint32(146).fork()).ldelim();
    }
    for (const v of message.networkAdaptors) {
      ServerNetworkAdaptor.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    for (const v of message.pcieDevices) {
      ServerPcieDevice.encode(v!, writer.uint32(162).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerChassis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerChassis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.chassisType = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 10:
          if (tag !== 85) {
            break;
          }

          message.heightMm = reader.float();
          continue;
        case 11:
          if (tag !== 93) {
            break;
          }

          message.widthMm = reader.float();
          continue;
        case 12:
          if (tag !== 101) {
            break;
          }

          message.depthMm = reader.float();
          continue;
        case 13:
          if (tag !== 109) {
            break;
          }

          message.weightKg = reader.float();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.drives.push(ServerDrive.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.thermal = ServerThermal.decode(reader, reader.uint32());
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.power = ServerPower.decode(reader, reader.uint32());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.powerState = reader.string();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.assemblies.push(ServerAssembly.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.networkAdaptors.push(ServerNetworkAdaptor.decode(reader, reader.uint32()));
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.pcieDevices.push(ServerPcieDevice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerChassis {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      chassisType: isSet(object.chassisType) ? globalThis.String(object.chassisType) : "",
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      heightMm: isSet(object.heightMm) ? globalThis.Number(object.heightMm) : 0,
      widthMm: isSet(object.widthMm) ? globalThis.Number(object.widthMm) : 0,
      depthMm: isSet(object.depthMm) ? globalThis.Number(object.depthMm) : 0,
      weightKg: isSet(object.weightKg) ? globalThis.Number(object.weightKg) : 0,
      drives: globalThis.Array.isArray(object?.drives) ? object.drives.map((e: any) => ServerDrive.fromJSON(e)) : [],
      thermal: isSet(object.thermal) ? ServerThermal.fromJSON(object.thermal) : undefined,
      power: isSet(object.power) ? ServerPower.fromJSON(object.power) : undefined,
      powerState: isSet(object.powerState) ? globalThis.String(object.powerState) : "",
      assemblies: globalThis.Array.isArray(object?.assemblies)
        ? object.assemblies.map((e: any) => ServerAssembly.fromJSON(e))
        : [],
      networkAdaptors: globalThis.Array.isArray(object?.networkAdaptors)
        ? object.networkAdaptors.map((e: any) => ServerNetworkAdaptor.fromJSON(e))
        : [],
      pcieDevices: globalThis.Array.isArray(object?.pcieDevices)
        ? object.pcieDevices.map((e: any) => ServerPcieDevice.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerChassis): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.chassisType !== "") {
      obj.chassisType = message.chassisType;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.heightMm !== 0) {
      obj.heightMm = message.heightMm;
    }
    if (message.widthMm !== 0) {
      obj.widthMm = message.widthMm;
    }
    if (message.depthMm !== 0) {
      obj.depthMm = message.depthMm;
    }
    if (message.weightKg !== 0) {
      obj.weightKg = message.weightKg;
    }
    if (message.drives?.length) {
      obj.drives = message.drives.map((e) => ServerDrive.toJSON(e));
    }
    if (message.thermal !== undefined) {
      obj.thermal = ServerThermal.toJSON(message.thermal);
    }
    if (message.power !== undefined) {
      obj.power = ServerPower.toJSON(message.power);
    }
    if (message.powerState !== "") {
      obj.powerState = message.powerState;
    }
    if (message.assemblies?.length) {
      obj.assemblies = message.assemblies.map((e) => ServerAssembly.toJSON(e));
    }
    if (message.networkAdaptors?.length) {
      obj.networkAdaptors = message.networkAdaptors.map((e) => ServerNetworkAdaptor.toJSON(e));
    }
    if (message.pcieDevices?.length) {
      obj.pcieDevices = message.pcieDevices.map((e) => ServerPcieDevice.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerChassis>, I>>(base?: I): ServerChassis {
    return ServerChassis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerChassis>, I>>(object: I): ServerChassis {
    const message = createBaseServerChassis();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.partNumber = object.partNumber ?? "";
    message.chassisType = object.chassisType ?? "";
    message.uuid = object.uuid ?? "";
    message.heightMm = object.heightMm ?? 0;
    message.widthMm = object.widthMm ?? 0;
    message.depthMm = object.depthMm ?? 0;
    message.weightKg = object.weightKg ?? 0;
    message.drives = object.drives?.map((e) => ServerDrive.fromPartial(e)) || [];
    message.thermal = (object.thermal !== undefined && object.thermal !== null)
      ? ServerThermal.fromPartial(object.thermal)
      : undefined;
    message.power = (object.power !== undefined && object.power !== null)
      ? ServerPower.fromPartial(object.power)
      : undefined;
    message.powerState = object.powerState ?? "";
    message.assemblies = object.assemblies?.map((e) => ServerAssembly.fromPartial(e)) || [];
    message.networkAdaptors = object.networkAdaptors?.map((e) => ServerNetworkAdaptor.fromPartial(e)) || [];
    message.pcieDevices = object.pcieDevices?.map((e) => ServerPcieDevice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServerManager(): ServerManager {
  return { id: "", name: "", description: "", managerType: "", model: "", uuid: "", firmwareVersion: "" };
}

export const ServerManager = {
  encode(message: ServerManager, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.managerType !== "") {
      writer.uint32(34).string(message.managerType);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.uuid !== "") {
      writer.uint32(50).string(message.uuid);
    }
    if (message.firmwareVersion !== "") {
      writer.uint32(58).string(message.firmwareVersion);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerManager {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerManager();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.managerType = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.firmwareVersion = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerManager {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      managerType: isSet(object.managerType) ? globalThis.String(object.managerType) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      firmwareVersion: isSet(object.firmwareVersion) ? globalThis.String(object.firmwareVersion) : "",
    };
  },

  toJSON(message: ServerManager): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.managerType !== "") {
      obj.managerType = message.managerType;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.firmwareVersion !== "") {
      obj.firmwareVersion = message.firmwareVersion;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerManager>, I>>(base?: I): ServerManager {
    return ServerManager.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerManager>, I>>(object: I): ServerManager {
    const message = createBaseServerManager();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.managerType = object.managerType ?? "";
    message.model = object.model ?? "";
    message.uuid = object.uuid ?? "";
    message.firmwareVersion = object.firmwareVersion ?? "";
    return message;
  },
};

function createBaseServerCertificate(): ServerCertificate {
  return { id: "", name: "", description: "", certString: "", type: "" };
}

export const ServerCertificate = {
  encode(message: ServerCertificate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.certString !== "") {
      writer.uint32(34).string(message.certString);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerCertificate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerCertificate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.certString = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerCertificate {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      certString: isSet(object.certString) ? globalThis.String(object.certString) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: ServerCertificate): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.certString !== "") {
      obj.certString = message.certString;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerCertificate>, I>>(base?: I): ServerCertificate {
    return ServerCertificate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerCertificate>, I>>(object: I): ServerCertificate {
    const message = createBaseServerCertificate();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.certString = object.certString ?? "";
    message.type = object.type ?? "";
    return message;
  },
};

function createBaseSPDMresponderAuth(): SPDMresponderAuth {
  return { certificate: undefined };
}

export const SPDMresponderAuth = {
  encode(message: SPDMresponderAuth, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificate !== undefined) {
      ServerCertificate.encode(message.certificate, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SPDMresponderAuth {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSPDMresponderAuth();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificate = ServerCertificate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SPDMresponderAuth {
    return { certificate: isSet(object.certificate) ? ServerCertificate.fromJSON(object.certificate) : undefined };
  },

  toJSON(message: SPDMresponderAuth): unknown {
    const obj: any = {};
    if (message.certificate !== undefined) {
      obj.certificate = ServerCertificate.toJSON(message.certificate);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SPDMresponderAuth>, I>>(base?: I): SPDMresponderAuth {
    return SPDMresponderAuth.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SPDMresponderAuth>, I>>(object: I): SPDMresponderAuth {
    const message = createBaseSPDMresponderAuth();
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? ServerCertificate.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseSPDMidentity(): SPDMidentity {
  return { respAuth: undefined };
}

export const SPDMidentity = {
  encode(message: SPDMidentity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.respAuth !== undefined) {
      SPDMresponderAuth.encode(message.respAuth, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SPDMidentity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSPDMidentity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.respAuth = SPDMresponderAuth.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SPDMidentity {
    return { respAuth: isSet(object.respAuth) ? SPDMresponderAuth.fromJSON(object.respAuth) : undefined };
  },

  toJSON(message: SPDMidentity): unknown {
    const obj: any = {};
    if (message.respAuth !== undefined) {
      obj.respAuth = SPDMresponderAuth.toJSON(message.respAuth);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SPDMidentity>, I>>(base?: I): SPDMidentity {
    return SPDMidentity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SPDMidentity>, I>>(object: I): SPDMidentity {
    const message = createBaseSPDMidentity();
    message.respAuth = (object.respAuth !== undefined && object.respAuth !== null)
      ? SPDMresponderAuth.fromPartial(object.respAuth)
      : undefined;
    return message;
  },
};

function createBaseSPDMInfo(): SPDMInfo {
  return { identity: undefined };
}

export const SPDMInfo = {
  encode(message: SPDMInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.identity !== undefined) {
      SPDMidentity.encode(message.identity, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SPDMInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSPDMInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.identity = SPDMidentity.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SPDMInfo {
    return { identity: isSet(object.identity) ? SPDMidentity.fromJSON(object.identity) : undefined };
  },

  toJSON(message: SPDMInfo): unknown {
    const obj: any = {};
    if (message.identity !== undefined) {
      obj.identity = SPDMidentity.toJSON(message.identity);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SPDMInfo>, I>>(base?: I): SPDMInfo {
    return SPDMInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SPDMInfo>, I>>(object: I): SPDMInfo {
    const message = createBaseSPDMInfo();
    message.identity = (object.identity !== undefined && object.identity !== null)
      ? SPDMidentity.fromPartial(object.identity)
      : undefined;
    return message;
  },
};

function createBaseServerComponentIntegrity(): ServerComponentIntegrity {
  return { enabled: false, type: "", version: "", description: "", lastUpdated: "", spdm: undefined };
}

export const ServerComponentIntegrity = {
  encode(message: ServerComponentIntegrity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.enabled !== false) {
      writer.uint32(8).bool(message.enabled);
    }
    if (message.type !== "") {
      writer.uint32(18).string(message.type);
    }
    if (message.version !== "") {
      writer.uint32(26).string(message.version);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.lastUpdated !== "") {
      writer.uint32(42).string(message.lastUpdated);
    }
    if (message.spdm !== undefined) {
      SPDMInfo.encode(message.spdm, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerComponentIntegrity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerComponentIntegrity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.type = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.version = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.lastUpdated = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.spdm = SPDMInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerComponentIntegrity {
    return {
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      lastUpdated: isSet(object.lastUpdated) ? globalThis.String(object.lastUpdated) : "",
      spdm: isSet(object.spdm) ? SPDMInfo.fromJSON(object.spdm) : undefined,
    };
  },

  toJSON(message: ServerComponentIntegrity): unknown {
    const obj: any = {};
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.lastUpdated !== "") {
      obj.lastUpdated = message.lastUpdated;
    }
    if (message.spdm !== undefined) {
      obj.spdm = SPDMInfo.toJSON(message.spdm);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerComponentIntegrity>, I>>(base?: I): ServerComponentIntegrity {
    return ServerComponentIntegrity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerComponentIntegrity>, I>>(object: I): ServerComponentIntegrity {
    const message = createBaseServerComponentIntegrity();
    message.enabled = object.enabled ?? false;
    message.type = object.type ?? "";
    message.version = object.version ?? "";
    message.description = object.description ?? "";
    message.lastUpdated = object.lastUpdated ?? "";
    message.spdm = (object.spdm !== undefined && object.spdm !== null) ? SPDMInfo.fromPartial(object.spdm) : undefined;
    return message;
  },
};

function createBaseServerSmartNicCard(): ServerSmartNicCard {
  return { serialNum: "", slotId: "" };
}

export const ServerSmartNicCard = {
  encode(message: ServerSmartNicCard, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNum !== "") {
      writer.uint32(10).string(message.serialNum);
    }
    if (message.slotId !== "") {
      writer.uint32(18).string(message.slotId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerSmartNicCard {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerSmartNicCard();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNum = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.slotId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerSmartNicCard {
    return {
      serialNum: isSet(object.serialNum) ? globalThis.String(object.serialNum) : "",
      slotId: isSet(object.slotId) ? globalThis.String(object.slotId) : "",
    };
  },

  toJSON(message: ServerSmartNicCard): unknown {
    const obj: any = {};
    if (message.serialNum !== "") {
      obj.serialNum = message.serialNum;
    }
    if (message.slotId !== "") {
      obj.slotId = message.slotId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerSmartNicCard>, I>>(base?: I): ServerSmartNicCard {
    return ServerSmartNicCard.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerSmartNicCard>, I>>(object: I): ServerSmartNicCard {
    const message = createBaseServerSmartNicCard();
    message.serialNum = object.serialNum ?? "";
    message.slotId = object.slotId ?? "";
    return message;
  },
};

function createBaseServerBmcInfo(): ServerBmcInfo {
  return { smartNicCards: [] };
}

export const ServerBmcInfo = {
  encode(message: ServerBmcInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.smartNicCards) {
      ServerSmartNicCard.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerBmcInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerBmcInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.smartNicCards.push(ServerSmartNicCard.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerBmcInfo {
    return {
      smartNicCards: globalThis.Array.isArray(object?.smartNicCards)
        ? object.smartNicCards.map((e: any) => ServerSmartNicCard.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServerBmcInfo): unknown {
    const obj: any = {};
    if (message.smartNicCards?.length) {
      obj.smartNicCards = message.smartNicCards.map((e) => ServerSmartNicCard.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerBmcInfo>, I>>(base?: I): ServerBmcInfo {
    return ServerBmcInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerBmcInfo>, I>>(object: I): ServerBmcInfo {
    const message = createBaseServerBmcInfo();
    message.smartNicCards = object.smartNicCards?.map((e) => ServerSmartNicCard.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSmartNicBmcInfo(): SmartNicBmcInfo {
  return {};
}

export const SmartNicBmcInfo = {
  encode(_: SmartNicBmcInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartNicBmcInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartNicBmcInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): SmartNicBmcInfo {
    return {};
  },

  toJSON(_: SmartNicBmcInfo): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartNicBmcInfo>, I>>(base?: I): SmartNicBmcInfo {
    return SmartNicBmcInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartNicBmcInfo>, I>>(_: I): SmartNicBmcInfo {
    const message = createBaseSmartNicBmcInfo();
    return message;
  },
};

function createBaseManagedEndpointServerInfo(): ManagedEndpointServerInfo {
  return {
    manufacturer: "",
    product: "",
    version: "",
    serialNum: "",
    uuid: "",
    sku: "",
    pcieSlot: 0,
    pcieBus: 0,
    pcieFunction: 0,
  };
}

export const ManagedEndpointServerInfo = {
  encode(message: ManagedEndpointServerInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.manufacturer !== "") {
      writer.uint32(10).string(message.manufacturer);
    }
    if (message.product !== "") {
      writer.uint32(18).string(message.product);
    }
    if (message.version !== "") {
      writer.uint32(26).string(message.version);
    }
    if (message.serialNum !== "") {
      writer.uint32(34).string(message.serialNum);
    }
    if (message.uuid !== "") {
      writer.uint32(42).string(message.uuid);
    }
    if (message.sku !== "") {
      writer.uint32(50).string(message.sku);
    }
    if (message.pcieSlot !== 0) {
      writer.uint32(56).uint32(message.pcieSlot);
    }
    if (message.pcieBus !== 0) {
      writer.uint32(64).uint32(message.pcieBus);
    }
    if (message.pcieFunction !== 0) {
      writer.uint32(72).uint32(message.pcieFunction);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedEndpointServerInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedEndpointServerInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.product = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.version = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.serialNum = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.sku = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.pcieSlot = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.pcieBus = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.pcieFunction = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedEndpointServerInfo {
    return {
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      product: isSet(object.product) ? globalThis.String(object.product) : "",
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      serialNum: isSet(object.serialNum) ? globalThis.String(object.serialNum) : "",
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      sku: isSet(object.sku) ? globalThis.String(object.sku) : "",
      pcieSlot: isSet(object.pcieSlot) ? globalThis.Number(object.pcieSlot) : 0,
      pcieBus: isSet(object.pcieBus) ? globalThis.Number(object.pcieBus) : 0,
      pcieFunction: isSet(object.pcieFunction) ? globalThis.Number(object.pcieFunction) : 0,
    };
  },

  toJSON(message: ManagedEndpointServerInfo): unknown {
    const obj: any = {};
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.product !== "") {
      obj.product = message.product;
    }
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.serialNum !== "") {
      obj.serialNum = message.serialNum;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.sku !== "") {
      obj.sku = message.sku;
    }
    if (message.pcieSlot !== 0) {
      obj.pcieSlot = Math.round(message.pcieSlot);
    }
    if (message.pcieBus !== 0) {
      obj.pcieBus = Math.round(message.pcieBus);
    }
    if (message.pcieFunction !== 0) {
      obj.pcieFunction = Math.round(message.pcieFunction);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedEndpointServerInfo>, I>>(base?: I): ManagedEndpointServerInfo {
    return ManagedEndpointServerInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedEndpointServerInfo>, I>>(object: I): ManagedEndpointServerInfo {
    const message = createBaseManagedEndpointServerInfo();
    message.manufacturer = object.manufacturer ?? "";
    message.product = object.product ?? "";
    message.version = object.version ?? "";
    message.serialNum = object.serialNum ?? "";
    message.uuid = object.uuid ?? "";
    message.sku = object.sku ?? "";
    message.pcieSlot = object.pcieSlot ?? 0;
    message.pcieBus = object.pcieBus ?? 0;
    message.pcieFunction = object.pcieFunction ?? 0;
    return message;
  },
};

function createBaseManagedEndpointLldpInfo(): ManagedEndpointLldpInfo {
  return { macAddress: "", sysName: "", sysDesc: "", localInterface: "", mgmtAddrs: [], serverInfo: undefined };
}

export const ManagedEndpointLldpInfo = {
  encode(message: ManagedEndpointLldpInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.macAddress !== "") {
      writer.uint32(10).string(message.macAddress);
    }
    if (message.sysName !== "") {
      writer.uint32(18).string(message.sysName);
    }
    if (message.sysDesc !== "") {
      writer.uint32(26).string(message.sysDesc);
    }
    if (message.localInterface !== "") {
      writer.uint32(34).string(message.localInterface);
    }
    for (const v of message.mgmtAddrs) {
      writer.uint32(42).string(v!);
    }
    if (message.serverInfo !== undefined) {
      ManagedEndpointServerInfo.encode(message.serverInfo, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedEndpointLldpInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedEndpointLldpInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.sysName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.sysDesc = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.localInterface = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.mgmtAddrs.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serverInfo = ManagedEndpointServerInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedEndpointLldpInfo {
    return {
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      sysName: isSet(object.sysName) ? globalThis.String(object.sysName) : "",
      sysDesc: isSet(object.sysDesc) ? globalThis.String(object.sysDesc) : "",
      localInterface: isSet(object.localInterface) ? globalThis.String(object.localInterface) : "",
      mgmtAddrs: globalThis.Array.isArray(object?.mgmtAddrs)
        ? object.mgmtAddrs.map((e: any) => globalThis.String(e))
        : [],
      serverInfo: isSet(object.serverInfo) ? ManagedEndpointServerInfo.fromJSON(object.serverInfo) : undefined,
    };
  },

  toJSON(message: ManagedEndpointLldpInfo): unknown {
    const obj: any = {};
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.sysName !== "") {
      obj.sysName = message.sysName;
    }
    if (message.sysDesc !== "") {
      obj.sysDesc = message.sysDesc;
    }
    if (message.localInterface !== "") {
      obj.localInterface = message.localInterface;
    }
    if (message.mgmtAddrs?.length) {
      obj.mgmtAddrs = message.mgmtAddrs;
    }
    if (message.serverInfo !== undefined) {
      obj.serverInfo = ManagedEndpointServerInfo.toJSON(message.serverInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedEndpointLldpInfo>, I>>(base?: I): ManagedEndpointLldpInfo {
    return ManagedEndpointLldpInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedEndpointLldpInfo>, I>>(object: I): ManagedEndpointLldpInfo {
    const message = createBaseManagedEndpointLldpInfo();
    message.macAddress = object.macAddress ?? "";
    message.sysName = object.sysName ?? "";
    message.sysDesc = object.sysDesc ?? "";
    message.localInterface = object.localInterface ?? "";
    message.mgmtAddrs = object.mgmtAddrs?.map((e) => e) || [];
    message.serverInfo = (object.serverInfo !== undefined && object.serverInfo !== null)
      ? ManagedEndpointServerInfo.fromPartial(object.serverInfo)
      : undefined;
    return message;
  },
};

function createBaseManagedEndpointDeviceInfo(): ManagedEndpointDeviceInfo {
  return {
    manufacturer: "",
    model: "",
    productName: "",
    partNumber: "",
    serialNumber: "",
    uuid: "",
    powerState: 0,
    pcieDevices: [],
  };
}

export const ManagedEndpointDeviceInfo = {
  encode(message: ManagedEndpointDeviceInfo, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.manufacturer !== "") {
      writer.uint32(10).string(message.manufacturer);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.productName !== "") {
      writer.uint32(26).string(message.productName);
    }
    if (message.partNumber !== "") {
      writer.uint32(34).string(message.partNumber);
    }
    if (message.serialNumber !== "") {
      writer.uint32(42).string(message.serialNumber);
    }
    if (message.uuid !== "") {
      writer.uint32(50).string(message.uuid);
    }
    if (message.powerState !== 0) {
      writer.uint32(56).int32(message.powerState);
    }
    for (const v of message.pcieDevices) {
      ServerPcieDevice.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedEndpointDeviceInfo {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedEndpointDeviceInfo();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partNumber = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.uuid = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.powerState = reader.int32() as any;
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pcieDevices.push(ServerPcieDevice.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedEndpointDeviceInfo {
    return {
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      productName: isSet(object.productName) ? globalThis.String(object.productName) : "",
      partNumber: isSet(object.partNumber) ? globalThis.String(object.partNumber) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      uuid: isSet(object.uuid) ? globalThis.String(object.uuid) : "",
      powerState: isSet(object.powerState) ? powerStateEnumFromJSON(object.powerState) : 0,
      pcieDevices: globalThis.Array.isArray(object?.pcieDevices)
        ? object.pcieDevices.map((e: any) => ServerPcieDevice.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ManagedEndpointDeviceInfo): unknown {
    const obj: any = {};
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.productName !== "") {
      obj.productName = message.productName;
    }
    if (message.partNumber !== "") {
      obj.partNumber = message.partNumber;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.uuid !== "") {
      obj.uuid = message.uuid;
    }
    if (message.powerState !== 0) {
      obj.powerState = powerStateEnumToJSON(message.powerState);
    }
    if (message.pcieDevices?.length) {
      obj.pcieDevices = message.pcieDevices.map((e) => ServerPcieDevice.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedEndpointDeviceInfo>, I>>(base?: I): ManagedEndpointDeviceInfo {
    return ManagedEndpointDeviceInfo.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedEndpointDeviceInfo>, I>>(object: I): ManagedEndpointDeviceInfo {
    const message = createBaseManagedEndpointDeviceInfo();
    message.manufacturer = object.manufacturer ?? "";
    message.model = object.model ?? "";
    message.productName = object.productName ?? "";
    message.partNumber = object.partNumber ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.uuid = object.uuid ?? "";
    message.powerState = object.powerState ?? 0;
    message.pcieDevices = object.pcieDevices?.map((e) => ServerPcieDevice.fromPartial(e)) || [];
    return message;
  },
};

function createBaseManagedEndpoint(): ManagedEndpoint {
  return {
    lldpInfo: undefined,
    lastLldpReceivedAt: undefined,
    lastConnectedAt: undefined,
    connectivityStatus: 0,
    connectionReason: "",
    meType: 0,
    supportsIdentityAuth: false,
    identityAuthOk: false,
    deviceInfo: undefined,
  };
}

export const ManagedEndpoint = {
  encode(message: ManagedEndpoint, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lldpInfo !== undefined) {
      ManagedEndpointLldpInfo.encode(message.lldpInfo, writer.uint32(10).fork()).ldelim();
    }
    if (message.lastLldpReceivedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastLldpReceivedAt), writer.uint32(18).fork()).ldelim();
    }
    if (message.lastConnectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.lastConnectedAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.connectivityStatus !== 0) {
      writer.uint32(32).int32(message.connectivityStatus);
    }
    if (message.connectionReason !== "") {
      writer.uint32(42).string(message.connectionReason);
    }
    if (message.meType !== 0) {
      writer.uint32(48).int32(message.meType);
    }
    if (message.supportsIdentityAuth !== false) {
      writer.uint32(56).bool(message.supportsIdentityAuth);
    }
    if (message.identityAuthOk !== false) {
      writer.uint32(64).bool(message.identityAuthOk);
    }
    if (message.deviceInfo !== undefined) {
      ManagedEndpointDeviceInfo.encode(message.deviceInfo, writer.uint32(74).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ManagedEndpoint {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseManagedEndpoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lldpInfo = ManagedEndpointLldpInfo.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lastLldpReceivedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.lastConnectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.connectivityStatus = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.connectionReason = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.meType = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.supportsIdentityAuth = reader.bool();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.identityAuthOk = reader.bool();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.deviceInfo = ManagedEndpointDeviceInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ManagedEndpoint {
    return {
      lldpInfo: isSet(object.lldpInfo) ? ManagedEndpointLldpInfo.fromJSON(object.lldpInfo) : undefined,
      lastLldpReceivedAt: isSet(object.lastLldpReceivedAt) ? fromJsonTimestamp(object.lastLldpReceivedAt) : undefined,
      lastConnectedAt: isSet(object.lastConnectedAt) ? fromJsonTimestamp(object.lastConnectedAt) : undefined,
      connectivityStatus: isSet(object.connectivityStatus) ? connectivityStatusFromJSON(object.connectivityStatus) : 0,
      connectionReason: isSet(object.connectionReason) ? globalThis.String(object.connectionReason) : "",
      meType: isSet(object.meType) ? managedEndpointTypeFromJSON(object.meType) : 0,
      supportsIdentityAuth: isSet(object.supportsIdentityAuth)
        ? globalThis.Boolean(object.supportsIdentityAuth)
        : false,
      identityAuthOk: isSet(object.identityAuthOk) ? globalThis.Boolean(object.identityAuthOk) : false,
      deviceInfo: isSet(object.deviceInfo) ? ManagedEndpointDeviceInfo.fromJSON(object.deviceInfo) : undefined,
    };
  },

  toJSON(message: ManagedEndpoint): unknown {
    const obj: any = {};
    if (message.lldpInfo !== undefined) {
      obj.lldpInfo = ManagedEndpointLldpInfo.toJSON(message.lldpInfo);
    }
    if (message.lastLldpReceivedAt !== undefined) {
      obj.lastLldpReceivedAt = message.lastLldpReceivedAt.toISOString();
    }
    if (message.lastConnectedAt !== undefined) {
      obj.lastConnectedAt = message.lastConnectedAt.toISOString();
    }
    if (message.connectivityStatus !== 0) {
      obj.connectivityStatus = connectivityStatusToJSON(message.connectivityStatus);
    }
    if (message.connectionReason !== "") {
      obj.connectionReason = message.connectionReason;
    }
    if (message.meType !== 0) {
      obj.meType = managedEndpointTypeToJSON(message.meType);
    }
    if (message.supportsIdentityAuth !== false) {
      obj.supportsIdentityAuth = message.supportsIdentityAuth;
    }
    if (message.identityAuthOk !== false) {
      obj.identityAuthOk = message.identityAuthOk;
    }
    if (message.deviceInfo !== undefined) {
      obj.deviceInfo = ManagedEndpointDeviceInfo.toJSON(message.deviceInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ManagedEndpoint>, I>>(base?: I): ManagedEndpoint {
    return ManagedEndpoint.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ManagedEndpoint>, I>>(object: I): ManagedEndpoint {
    const message = createBaseManagedEndpoint();
    message.lldpInfo = (object.lldpInfo !== undefined && object.lldpInfo !== null)
      ? ManagedEndpointLldpInfo.fromPartial(object.lldpInfo)
      : undefined;
    message.lastLldpReceivedAt = object.lastLldpReceivedAt ?? undefined;
    message.lastConnectedAt = object.lastConnectedAt ?? undefined;
    message.connectivityStatus = object.connectivityStatus ?? 0;
    message.connectionReason = object.connectionReason ?? "";
    message.meType = object.meType ?? 0;
    message.supportsIdentityAuth = object.supportsIdentityAuth ?? false;
    message.identityAuthOk = object.identityAuthOk ?? false;
    message.deviceInfo = (object.deviceInfo !== undefined && object.deviceInfo !== null)
      ? ManagedEndpointDeviceInfo.fromPartial(object.deviceInfo)
      : undefined;
    return message;
  },
};

function createBaseBmc(): Bmc {
  return {
    id: undefined,
    managedEndpoint: undefined,
    preAuthInfo: undefined,
    bmcProduct: 0,
    firmwareInventory: [],
    serverBmcInfo: undefined,
    smartNicBmcInfo: undefined,
  };
}

export const Bmc = {
  encode(message: Bmc, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.managedEndpoint !== undefined) {
      ManagedEndpoint.encode(message.managedEndpoint, writer.uint32(18).fork()).ldelim();
    }
    if (message.preAuthInfo !== undefined) {
      ServerBmcPreAuthInfo.encode(message.preAuthInfo, writer.uint32(26).fork()).ldelim();
    }
    if (message.bmcProduct !== 0) {
      writer.uint32(32).int32(message.bmcProduct);
    }
    for (const v of message.firmwareInventory) {
      ServerFirmwareInventoryItem.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.serverBmcInfo !== undefined) {
      ServerBmcInfo.encode(message.serverBmcInfo, writer.uint32(50).fork()).ldelim();
    }
    if (message.smartNicBmcInfo !== undefined) {
      SmartNicBmcInfo.encode(message.smartNicBmcInfo, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Bmc {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBmc();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.managedEndpoint = ManagedEndpoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.preAuthInfo = ServerBmcPreAuthInfo.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.bmcProduct = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.firmwareInventory.push(ServerFirmwareInventoryItem.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.serverBmcInfo = ServerBmcInfo.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.smartNicBmcInfo = SmartNicBmcInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Bmc {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      managedEndpoint: isSet(object.managedEndpoint) ? ManagedEndpoint.fromJSON(object.managedEndpoint) : undefined,
      preAuthInfo: isSet(object.preAuthInfo) ? ServerBmcPreAuthInfo.fromJSON(object.preAuthInfo) : undefined,
      bmcProduct: isSet(object.bmcProduct) ? bmcProductTypeFromJSON(object.bmcProduct) : 0,
      firmwareInventory: globalThis.Array.isArray(object?.firmwareInventory)
        ? object.firmwareInventory.map((e: any) => ServerFirmwareInventoryItem.fromJSON(e))
        : [],
      serverBmcInfo: isSet(object.serverBmcInfo) ? ServerBmcInfo.fromJSON(object.serverBmcInfo) : undefined,
      smartNicBmcInfo: isSet(object.smartNicBmcInfo) ? SmartNicBmcInfo.fromJSON(object.smartNicBmcInfo) : undefined,
    };
  },

  toJSON(message: Bmc): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.managedEndpoint !== undefined) {
      obj.managedEndpoint = ManagedEndpoint.toJSON(message.managedEndpoint);
    }
    if (message.preAuthInfo !== undefined) {
      obj.preAuthInfo = ServerBmcPreAuthInfo.toJSON(message.preAuthInfo);
    }
    if (message.bmcProduct !== 0) {
      obj.bmcProduct = bmcProductTypeToJSON(message.bmcProduct);
    }
    if (message.firmwareInventory?.length) {
      obj.firmwareInventory = message.firmwareInventory.map((e) => ServerFirmwareInventoryItem.toJSON(e));
    }
    if (message.serverBmcInfo !== undefined) {
      obj.serverBmcInfo = ServerBmcInfo.toJSON(message.serverBmcInfo);
    }
    if (message.smartNicBmcInfo !== undefined) {
      obj.smartNicBmcInfo = SmartNicBmcInfo.toJSON(message.smartNicBmcInfo);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Bmc>, I>>(base?: I): Bmc {
    return Bmc.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Bmc>, I>>(object: I): Bmc {
    const message = createBaseBmc();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.managedEndpoint = (object.managedEndpoint !== undefined && object.managedEndpoint !== null)
      ? ManagedEndpoint.fromPartial(object.managedEndpoint)
      : undefined;
    message.preAuthInfo = (object.preAuthInfo !== undefined && object.preAuthInfo !== null)
      ? ServerBmcPreAuthInfo.fromPartial(object.preAuthInfo)
      : undefined;
    message.bmcProduct = object.bmcProduct ?? 0;
    message.firmwareInventory = object.firmwareInventory?.map((e) => ServerFirmwareInventoryItem.fromPartial(e)) || [];
    message.serverBmcInfo = (object.serverBmcInfo !== undefined && object.serverBmcInfo !== null)
      ? ServerBmcInfo.fromPartial(object.serverBmcInfo)
      : undefined;
    message.smartNicBmcInfo = (object.smartNicBmcInfo !== undefined && object.smartNicBmcInfo !== null)
      ? SmartNicBmcInfo.fromPartial(object.smartNicBmcInfo)
      : undefined;
    return message;
  },
};

function createBaseServerVoltageSensor(): ServerVoltageSensor {
  return { id: undefined, voltageInfo: undefined, collectedAt: undefined };
}

export const ServerVoltageSensor = {
  encode(message: ServerVoltageSensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.voltageInfo !== undefined) {
      ServerVoltage.encode(message.voltageInfo, writer.uint32(18).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerVoltageSensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerVoltageSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.voltageInfo = ServerVoltage.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerVoltageSensor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      voltageInfo: isSet(object.voltageInfo) ? ServerVoltage.fromJSON(object.voltageInfo) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ServerVoltageSensor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.voltageInfo !== undefined) {
      obj.voltageInfo = ServerVoltage.toJSON(message.voltageInfo);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerVoltageSensor>, I>>(base?: I): ServerVoltageSensor {
    return ServerVoltageSensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerVoltageSensor>, I>>(object: I): ServerVoltageSensor {
    const message = createBaseServerVoltageSensor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.voltageInfo = (object.voltageInfo !== undefined && object.voltageInfo !== null)
      ? ServerVoltage.fromPartial(object.voltageInfo)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseServerTemperatureSensor(): ServerTemperatureSensor {
  return { id: undefined, temperatureInfo: undefined, collectedAt: undefined };
}

export const ServerTemperatureSensor = {
  encode(message: ServerTemperatureSensor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.temperatureInfo !== undefined) {
      ServerTemperature.encode(message.temperatureInfo, writer.uint32(18).fork()).ldelim();
    }
    if (message.collectedAt !== undefined) {
      Timestamp.encode(toTimestamp(message.collectedAt), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ServerTemperatureSensor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServerTemperatureSensor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.temperatureInfo = ServerTemperature.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectedAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServerTemperatureSensor {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      temperatureInfo: isSet(object.temperatureInfo) ? ServerTemperature.fromJSON(object.temperatureInfo) : undefined,
      collectedAt: isSet(object.collectedAt) ? fromJsonTimestamp(object.collectedAt) : undefined,
    };
  },

  toJSON(message: ServerTemperatureSensor): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.temperatureInfo !== undefined) {
      obj.temperatureInfo = ServerTemperature.toJSON(message.temperatureInfo);
    }
    if (message.collectedAt !== undefined) {
      obj.collectedAt = message.collectedAt.toISOString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ServerTemperatureSensor>, I>>(base?: I): ServerTemperatureSensor {
    return ServerTemperatureSensor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ServerTemperatureSensor>, I>>(object: I): ServerTemperatureSensor {
    const message = createBaseServerTemperatureSensor();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.temperatureInfo = (object.temperatureInfo !== undefined && object.temperatureInfo !== null)
      ? ServerTemperature.fromPartial(object.temperatureInfo)
      : undefined;
    message.collectedAt = object.collectedAt ?? undefined;
    return message;
  },
};

function createBaseSmartNicDpu(): SmartNicDpu {
  return { id: undefined, managedEndpoint: undefined, dpuProduct: 0 };
}

export const SmartNicDpu = {
  encode(message: SmartNicDpu, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.managedEndpoint !== undefined) {
      ManagedEndpoint.encode(message.managedEndpoint, writer.uint32(18).fork()).ldelim();
    }
    if (message.dpuProduct !== 0) {
      writer.uint32(24).int32(message.dpuProduct);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SmartNicDpu {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSmartNicDpu();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.managedEndpoint = ManagedEndpoint.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.dpuProduct = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SmartNicDpu {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      managedEndpoint: isSet(object.managedEndpoint) ? ManagedEndpoint.fromJSON(object.managedEndpoint) : undefined,
      dpuProduct: isSet(object.dpuProduct) ? smartNicDpuProductTypeFromJSON(object.dpuProduct) : 0,
    };
  },

  toJSON(message: SmartNicDpu): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.managedEndpoint !== undefined) {
      obj.managedEndpoint = ManagedEndpoint.toJSON(message.managedEndpoint);
    }
    if (message.dpuProduct !== 0) {
      obj.dpuProduct = smartNicDpuProductTypeToJSON(message.dpuProduct);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SmartNicDpu>, I>>(base?: I): SmartNicDpu {
    return SmartNicDpu.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SmartNicDpu>, I>>(object: I): SmartNicDpu {
    const message = createBaseSmartNicDpu();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.managedEndpoint = (object.managedEndpoint !== undefined && object.managedEndpoint !== null)
      ? ManagedEndpoint.fromPartial(object.managedEndpoint)
      : undefined;
    message.dpuProduct = object.dpuProduct ?? 0;
    return message;
  },
};

function createBaseBmcConfig(): BmcConfig {
  return { id: undefined };
}

export const BmcConfig = {
  encode(message: BmcConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BmcConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBmcConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BmcConfig {
    return { id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined };
  },

  toJSON(message: BmcConfig): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BmcConfig>, I>>(base?: I): BmcConfig {
    return BmcConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BmcConfig>, I>>(object: I): BmcConfig {
    const message = createBaseBmcConfig();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    return message;
  },
};

function createBaseBearerToken(): BearerToken {
  return {
    id: undefined,
    orgId: "",
    tenantId: "",
    email: "",
    provider: 0,
    userRole: 0,
    tokenId: "",
    fingerprint: "",
    notBefore: undefined,
    expires: undefined,
    scope: 0,
    signingPublicKey: "",
    signingPublicKeyFp: "",
  };
}

export const BearerToken = {
  encode(message: BearerToken, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.provider !== 0) {
      writer.uint32(40).int32(message.provider);
    }
    if (message.userRole !== 0) {
      writer.uint32(48).int32(message.userRole);
    }
    if (message.tokenId !== "") {
      writer.uint32(58).string(message.tokenId);
    }
    if (message.fingerprint !== "") {
      writer.uint32(66).string(message.fingerprint);
    }
    if (message.notBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.notBefore), writer.uint32(74).fork()).ldelim();
    }
    if (message.expires !== undefined) {
      Timestamp.encode(toTimestamp(message.expires), writer.uint32(82).fork()).ldelim();
    }
    if (message.scope !== 0) {
      writer.uint32(88).int32(message.scope);
    }
    if (message.signingPublicKey !== "") {
      writer.uint32(98).string(message.signingPublicKey);
    }
    if (message.signingPublicKeyFp !== "") {
      writer.uint32(106).string(message.signingPublicKeyFp);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BearerToken {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBearerToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.provider = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.notBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.expires = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.signingPublicKey = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.signingPublicKeyFp = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BearerToken {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? knownProviderFromJSON(object.provider) : 0,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : 0,
      tokenId: isSet(object.tokenId) ? globalThis.String(object.tokenId) : "",
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      notBefore: isSet(object.notBefore) ? fromJsonTimestamp(object.notBefore) : undefined,
      expires: isSet(object.expires) ? fromJsonTimestamp(object.expires) : undefined,
      scope: isSet(object.scope) ? tokenScopeFromJSON(object.scope) : 0,
      signingPublicKey: isSet(object.signingPublicKey) ? globalThis.String(object.signingPublicKey) : "",
      signingPublicKeyFp: isSet(object.signingPublicKeyFp) ? globalThis.String(object.signingPublicKeyFp) : "",
    };
  },

  toJSON(message: BearerToken): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== 0) {
      obj.provider = knownProviderToJSON(message.provider);
    }
    if (message.userRole !== 0) {
      obj.userRole = userRoleToJSON(message.userRole);
    }
    if (message.tokenId !== "") {
      obj.tokenId = message.tokenId;
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore.toISOString();
    }
    if (message.expires !== undefined) {
      obj.expires = message.expires.toISOString();
    }
    if (message.scope !== 0) {
      obj.scope = tokenScopeToJSON(message.scope);
    }
    if (message.signingPublicKey !== "") {
      obj.signingPublicKey = message.signingPublicKey;
    }
    if (message.signingPublicKeyFp !== "") {
      obj.signingPublicKeyFp = message.signingPublicKeyFp;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BearerToken>, I>>(base?: I): BearerToken {
    return BearerToken.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BearerToken>, I>>(object: I): BearerToken {
    const message = createBaseBearerToken();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgId = object.orgId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.email = object.email ?? "";
    message.provider = object.provider ?? 0;
    message.userRole = object.userRole ?? 0;
    message.tokenId = object.tokenId ?? "";
    message.fingerprint = object.fingerprint ?? "";
    message.notBefore = object.notBefore ?? undefined;
    message.expires = object.expires ?? undefined;
    message.scope = object.scope ?? 0;
    message.signingPublicKey = object.signingPublicKey ?? "";
    message.signingPublicKeyFp = object.signingPublicKeyFp ?? "";
    return message;
  },
};

function createBaseApiKey(): ApiKey {
  return {
    id: undefined,
    orgId: "",
    tenantId: "",
    email: "",
    provider: 0,
    userRole: 0,
    keyId: "",
    fingerprint: "",
    notBefore: undefined,
    notAfter: undefined,
    scope: 0,
    keyType: 0,
    publicKey: "",
  };
}

export const ApiKey = {
  encode(message: ApiKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== undefined) {
      PathId.encode(message.id, writer.uint32(10).fork()).ldelim();
    }
    if (message.orgId !== "") {
      writer.uint32(18).string(message.orgId);
    }
    if (message.tenantId !== "") {
      writer.uint32(26).string(message.tenantId);
    }
    if (message.email !== "") {
      writer.uint32(34).string(message.email);
    }
    if (message.provider !== 0) {
      writer.uint32(40).int32(message.provider);
    }
    if (message.userRole !== 0) {
      writer.uint32(48).int32(message.userRole);
    }
    if (message.keyId !== "") {
      writer.uint32(58).string(message.keyId);
    }
    if (message.fingerprint !== "") {
      writer.uint32(66).string(message.fingerprint);
    }
    if (message.notBefore !== undefined) {
      Timestamp.encode(toTimestamp(message.notBefore), writer.uint32(74).fork()).ldelim();
    }
    if (message.notAfter !== undefined) {
      Timestamp.encode(toTimestamp(message.notAfter), writer.uint32(82).fork()).ldelim();
    }
    if (message.scope !== 0) {
      writer.uint32(88).int32(message.scope);
    }
    if (message.keyType !== 0) {
      writer.uint32(96).int32(message.keyType);
    }
    if (message.publicKey !== "") {
      writer.uint32(106).string(message.publicKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = PathId.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.orgId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.tenantId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.email = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.provider = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.userRole = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.keyId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.fingerprint = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.notBefore = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.notAfter = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.scope = reader.int32() as any;
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.keyType = reader.int32() as any;
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.publicKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ApiKey {
    return {
      id: isSet(object.id) ? PathId.fromJSON(object.id) : undefined,
      orgId: isSet(object.orgId) ? globalThis.String(object.orgId) : "",
      tenantId: isSet(object.tenantId) ? globalThis.String(object.tenantId) : "",
      email: isSet(object.email) ? globalThis.String(object.email) : "",
      provider: isSet(object.provider) ? knownProviderFromJSON(object.provider) : 0,
      userRole: isSet(object.userRole) ? userRoleFromJSON(object.userRole) : 0,
      keyId: isSet(object.keyId) ? globalThis.String(object.keyId) : "",
      fingerprint: isSet(object.fingerprint) ? globalThis.String(object.fingerprint) : "",
      notBefore: isSet(object.notBefore) ? fromJsonTimestamp(object.notBefore) : undefined,
      notAfter: isSet(object.notAfter) ? fromJsonTimestamp(object.notAfter) : undefined,
      scope: isSet(object.scope) ? tokenScopeFromJSON(object.scope) : 0,
      keyType: isSet(object.keyType) ? restKeyTypeFromJSON(object.keyType) : 0,
      publicKey: isSet(object.publicKey) ? globalThis.String(object.publicKey) : "",
    };
  },

  toJSON(message: ApiKey): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = PathId.toJSON(message.id);
    }
    if (message.orgId !== "") {
      obj.orgId = message.orgId;
    }
    if (message.tenantId !== "") {
      obj.tenantId = message.tenantId;
    }
    if (message.email !== "") {
      obj.email = message.email;
    }
    if (message.provider !== 0) {
      obj.provider = knownProviderToJSON(message.provider);
    }
    if (message.userRole !== 0) {
      obj.userRole = userRoleToJSON(message.userRole);
    }
    if (message.keyId !== "") {
      obj.keyId = message.keyId;
    }
    if (message.fingerprint !== "") {
      obj.fingerprint = message.fingerprint;
    }
    if (message.notBefore !== undefined) {
      obj.notBefore = message.notBefore.toISOString();
    }
    if (message.notAfter !== undefined) {
      obj.notAfter = message.notAfter.toISOString();
    }
    if (message.scope !== 0) {
      obj.scope = tokenScopeToJSON(message.scope);
    }
    if (message.keyType !== 0) {
      obj.keyType = restKeyTypeToJSON(message.keyType);
    }
    if (message.publicKey !== "") {
      obj.publicKey = message.publicKey;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ApiKey>, I>>(base?: I): ApiKey {
    return ApiKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ApiKey>, I>>(object: I): ApiKey {
    const message = createBaseApiKey();
    message.id = (object.id !== undefined && object.id !== null) ? PathId.fromPartial(object.id) : undefined;
    message.orgId = object.orgId ?? "";
    message.tenantId = object.tenantId ?? "";
    message.email = object.email ?? "";
    message.provider = object.provider ?? 0;
    message.userRole = object.userRole ?? 0;
    message.keyId = object.keyId ?? "";
    message.fingerprint = object.fingerprint ?? "";
    message.notBefore = object.notBefore ?? undefined;
    message.notAfter = object.notAfter ?? undefined;
    message.scope = object.scope ?? 0;
    message.keyType = object.keyType ?? 0;
    message.publicKey = object.publicKey ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
