// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: configd/api.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { PaginationRequest, PaginationResponse } from "../common/grpc";
import { Timestamp } from "../google/protobuf/timestamp";
import { CableModel, DeviceModel } from "../models/bom";
import {
  CableType,
  cableTypeFromJSON,
  cableTypeToJSON,
  FabricTopology,
  fabricTopologyFromJSON,
  fabricTopologyToJSON,
  NodeRole,
  nodeRoleFromJSON,
  nodeRoleToJSON,
} from "../models/fabric";
import {
  Annotation as Annotation1,
  ObjectType,
  objectTypeFromJSON,
  objectTypeToJSON,
  Operation,
  operationFromJSON,
  operationToJSON,
} from "../models/global";
import {
  DhcpRelay,
  Fabric,
  Loopback,
  NetworkPort,
  Node,
  PortBreakout,
  PortChannel,
  StaticRoutes,
  SubInterface,
  Vlan,
  Vni,
  Vrf,
} from "../models/models";
import { NetworkPortPolicy, OrgProperty } from "../models/policy";
import { BgpPeer, PerVlanStp } from "../models/routing";
import { Metadata, PortConnection } from "../models/types";
import { WorkflowFeedback } from "../onprem/onprem";
import { WorkflowType, workflowTypeFromJSON, workflowTypeToJSON } from "../onprem/workflow";

export const protobufPackage = "configd";

/** Tag encapsulates a tag and identifiers of objects associated with the tag. */
export interface Label {
  /**
   * The label name. Tag name must comply with following rules.
   *  a) Name must be a string of fewer than 64 characters.
   *  b) Name must not begin with ##.
   *  c) Name must not have comma "," in it.
   */
  name: string;
  /**
   * Indicates if tag is enabled or not. Enabled flag is useful in SetTags API.
   * SetTags adds new tag when Enabled is true, and deletes existing tag when
   * Enabled is false.
   */
  enabled: boolean;
  /** A set of fabrics associated with the label. */
  fabricIds: string[];
  /** A set of nodes associated with the label. */
  nodeIds: string[];
  /** A set of network ports associated with the label. */
  networkPortIds: string[];
  /** A set of port channels associated with the label. */
  portChannelIds: string[];
  /** A set of vlans associated with the label. */
  vlanIds: string[];
  /** A set of Loopback devices associated with the label. */
  loopbackIds: string[];
  /** A set of Vrfs associated with the label. */
  vrfIds: string[];
  /** A set of Vnis associated with the label. */
  vniIds: string[];
  /** A set of StaticRoutes associated with the label. */
  staticRouteIds: string[];
  /** A set of DhcpRelays associated with the label. */
  dhcpRelayIds: string[];
  /** A set of SubInterfaces associated with the label. */
  subInterfaceIds: string[];
  /** A set of BgpPeer objects associated with the label. */
  bgpPeerIds: string[];
  /** A set of PortBreakout objects associated with the label. */
  portBreakoutIds: string[];
}

/** Annotation encapsulates an object annotation and identifiers of associated objects. */
export interface Annotation {
  /**
   * The object annotation object.
   *  a) Name must be a string of fewer than 64 characters.
   *  b) Value must be a string of fewer than 512 characters.
   */
  annotation:
    | Annotation1
    | undefined;
  /**
   * Indicates if annotation is enabled or not. Enabled flag is useful in SetAnnotations API.
   * SetAnnotations adds new annotation when Enabled is true, and deletes existing annotations
   * when Enabled is false.
   */
  enabled: boolean;
  /** A set of fabrics associated with the annotation. */
  fabricIds: string[];
  /** A set of nodes associated with the annotation. */
  nodeIds: string[];
  /** A set of network ports associated with the annotation. */
  networkPortIds: string[];
  /** A set of port channels associated with the annotation. */
  portChannelIds: string[];
  /** A set of vlans associated with the annotation. */
  vlanIds: string[];
  /** A set of Loopback devices associated with the annotation. */
  loopbackIds: string[];
  /** A set of Vrfs associated with the annotation. */
  vrfIds: string[];
  /** A set of Vnis associated with the annotation. */
  vniIds: string[];
  /** A set of StaticRoutes associated with the annotation. */
  staticRouteIds: string[];
  /** A set of DhcpRelays associated with the annotation. */
  dhcpRelayIds: string[];
  /** A set of SubInterface associated with the annotation. */
  subInterfaceIds: string[];
  /** A set of BgpPeer objects associated with the annotation. */
  bgpPeerIds: string[];
  /** A set of PortBreakout objects associated with the annotation. */
  portBreakoutIds: string[];
}

/** NodeShowTech encapsulates tech-support config. */
export interface NodeShowTech {
  /** The fabric identifier. */
  fabricId: string;
  /** The node identifier. */
  nodeId: string;
  /** Show-tech start timestamp. */
  startTime:
    | Date
    | undefined;
  /** Show-tech expiration timestamp. */
  endTime:
    | Date
    | undefined;
  /** Change revision information. */
  metadata: Metadata | undefined;
}

/** ProvisionSettings encapsulates organization settings. */
export interface ProvisionSettings {
  /** A set of network port policies. */
  setPortPolicies: NetworkPortPolicy[];
  /** A set of network port policy identifiers. */
  delPortPolicies: string[];
  /** A set of organization properties. */
  setOrgProperties: OrgProperty[];
  /** A set of labels to be set or unset for a number of objects. */
  setLabels: Label[];
  /** A set of annotations to be set or unset for a number of objects. */
  setAnnotations: Annotation[];
}

/**
 * ProvisionTenants encapsulates request messages to create/modify/delete tenants
 * and tenant objects.
 */
export interface ProvisionTenants {
  /** A set of tenant identifiers to delete. */
  delTenants: string[];
  /** A set of Vlan objects. */
  setVlans: Vlan[];
  /** A set of Vlan object identifiers. */
  delVlans: string[];
  /** A set of Loopback objects. */
  setLoopbacks: Loopback[];
  /** A ser of Loopback identifiers. */
  delLoopbacks: string[];
  /** A set of Vrf objects, */
  setVrfs: Vrf[];
  /** A set of Vrf names to delete. */
  delVrfs: string[];
  /** A set of port channel (LAG) objects. */
  setPortChannels: PortChannel[];
  /** A set of port channel identifiers. */
  delPortChannels: string[];
  /** A set of Vni objects, */
  setVnis: Vni[];
  /** A set of Vni object identifiers to delete. */
  delVnis: string[];
  /** A set of StaticRoutes objects, */
  setStaticRoutes: StaticRoutes[];
  /** A set of StaticRoutes object identifiers to delete. */
  delStaticRoutes: string[];
  /** A set of DhcpRelay objects, */
  setDhcpRelays: DhcpRelay[];
  /** A set of DhcpRelay object identifiers to delete. */
  delDhcpRelays: string[];
  /** A set of SubInterface objects, */
  setSubInterfaces: SubInterface[];
  /** A set of SubInterface object identifiers to delete. */
  delSubInterfaces: string[];
  /** A set of BgpPeer objects, */
  setBgpPeers: BgpPeer[];
  /** A set of BgpPeer object identifiers to delete. */
  delBgpPeers: string[];
}

/**
 * BindDevice represents a message to bind a device to a blueprint node.
 * NOTES:
 *  - An empty device_id results in device getting unbound from node.
 */
export interface BindDevice {
  /** The fabric identifier. */
  fabricId: string;
  /** The identifier of blueprint node. */
  nodeId: string;
  /** The identifier of inventory device. */
  deviceId: string;
}

/**
 * ProvisionFabrics encapsulates request messages to create/modify/delete Fabric
 * and Fabric child objects such as Node, NetworkPort etc.
 */
export interface ProvisionFabrics {
  /** A set of fabrics to create/update. */
  setFabrics: Fabric[];
  /** A set of fabrics identifiers to delete. */
  delFabrics: string[];
  /** A set of port connections. */
  setConnections: PortConnection[];
  /** A set of connection identifiers. */
  delConnections: string[];
  /** A set of PortBreakout objects, */
  setPortBreakouts: PortBreakout[];
  /** A set of PortBreakout object identifiers to delete. */
  delPortBreakouts: string[];
  /** A set of nodes to create/update. */
  setNodes: Node[];
  /** A set of node identifiers to delete. */
  delNodes: string[];
  /** A set of messages to bind a device to a node. */
  bindDevices: BindDevice[];
  /** A set of node identifiers to unbind devices from nodes. */
  unbindDevices: string[];
  /** PerVlanStp config of the fabric, */
  setPerVlanStps: PerVlanStp[];
  /** A set of network ports to be modified. */
  setNetworkPorts: NetworkPort[];
  /** Reset configs of a set of network ports. */
  resetNetworkPorts: string[];
  /** A set of ShowTechConfig objects. */
  setShowTechs: NodeShowTech[];
  /** A set of inventory switch identifiers. */
  delInventories: string[];
}

/**
 * ProvisionRequest encapsulates provisioning requests. ProvisionRequest
 * guarantees atomic (transactional) update of all objects contained in the
 * request.
 */
export interface ProvisionRequest {
  /** Is dry run or test-flight? (Return results, do not commit). */
  dryRun: boolean;
  /** The global fabric identifier. FabricId is required for deletes. */
  fabricId: string;
  /** The candidate name. */
  candidate: string;
  /** Candidate creation comments. */
  comments: string;
  /** Organization configs. */
  settings:
    | ProvisionSettings
    | undefined;
  /** Fabrics provisioning request. */
  fabrics:
    | ProvisionFabrics
    | undefined;
  /** Tenants provisioning request. */
  tenants: ProvisionTenants | undefined;
}

export interface ProvisionResponse {
  /** Is dry run or test-flight? */
  dryRun: boolean;
  /** Organization configs. */
  settings:
    | ProvisionSettings
    | undefined;
  /** Fabrics provisioning request. */
  fabrics:
    | ProvisionFabrics
    | undefined;
  /** Tenants provisioning request. */
  tenants: ProvisionTenants | undefined;
}

/**
 * GetFabricsFilters defines supported filters for GetFabrics gRPC API.
 * NOTES:
 *  LoadChildren means load full fabric tree.
 */
export interface GetFabricsFilters {
  /** Use partial match on names. */
  partialMatch: boolean;
  /** Load child objects such as nodes and ports. */
  loadChildren: boolean;
  /** The candidate name. */
  candidate: string;
  /** A set of fabric identifiers. */
  fabricIds: string[];
  /** A set of fabric names. */
  names: string[];
  /** A set of tags. */
  labels: string[];
  /** A set of node identifiers. */
  nodeIds: string[];
}

/** GetFabricsRequest defines protobuf message used for querying fabrics. */
export interface GetFabricsRequest {
  /** Query filters. */
  filters: GetFabricsFilters | undefined;
  pagination: PaginationRequest | undefined;
}

export interface GetFabricsResponse {
  fabrics: Fabric[];
  /** The candidate name. */
  candidate: string;
  pagination: PaginationResponse | undefined;
}

/** GetNodesFilters defines supported filters for GetNodes gRPC API. */
export interface GetNodesFilters {
  /** Use partial match on names. */
  partialMatch: boolean;
  /** Load child objects. */
  loadChildren: boolean;
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier or name. */
  fabricId: string;
  /** Filter by one or more node ids. */
  nodeIds: string[];
  /** Filter by node names. */
  names: string[];
  /** Filter by tags. */
  labels: string[];
  /** Filter by roles. */
  roles: NodeRole[];
  /** Filter by serial numbers. */
  serialNumbers: string[];
  /** Filter by model name. */
  modelNames: string[];
  /** Filter by device identifiers. */
  deviceIds: string[];
}

export interface GetNodesRequest {
  /** Query filters. */
  filters: GetNodesFilters | undefined;
  pagination: PaginationRequest | undefined;
}

export interface GetNodesResponse {
  nodes: Node[];
  /** The candidate name. */
  candidate: string;
  pagination: PaginationResponse | undefined;
}

export interface GetNodesPortsRequest {
  /** The transaction name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** The node identifiers; must have exactly one identifier. */
  nodeIds: string[];
  /** A set of port names. */
  ports: string[];
}

export interface GetNodesPortsResponse {
  nodes: Node[];
}

export interface GetPortBreakoutsRequest {
  /** The transaction name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of node identifiers. */
  nodeIds: string[];
  /** A set of object identifiers. */
  ids: string[];
  /** A set of object names. */
  names: string[];
}

export interface GetPortBreakoutsResponse {
  breakouts: PortBreakout[];
}

/** TenantObjects defines an envelope to hold tenant objects. */
export interface TenantObjects {
  id: string;
  name: string;
  enabled: boolean;
  /** The transaction name. */
  candidate: string;
  vlans: Vlan[];
  vrfs: Vrf[];
  portChannels: PortChannel[];
  vnis: Vni[];
  staticRoutes: StaticRoutes[];
  dhcpRelays: DhcpRelay[];
  loopbacks: Loopback[];
  subInterfaces: SubInterface[];
  bgpPeers: BgpPeer[];
}

/** TenantsObjectsResponse encapsulates fabric's tenant objects. */
export interface TenantsObjectsResponse {
  tenants: TenantObjects[];
}

/** GetTenantsObjectsRequest encapsulates properties to query fabric's tenant objects. */
export interface GetTenantsObjectsRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
}

/** GetVlansRequest encapsulates properties to query Vlan objects. */
export interface GetVlansRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of Vni object identifiers. */
  ids: string[];
  /** A set of VLAN identifiers. */
  vlanIds: number[];
  /** A set of VNIs. */
  vnis: number[];
}

/** GetVnisRequest encapsulates parameters to query L2/L3 Vni objects. */
export interface GetVnisRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of Vni object identifiers. */
  ids: string[];
  /** A set of Vni names. */
  names: string[];
  /** A set of VNIs. */
  vnis: number[];
  /** A set of VLAN identifiers. */
  vlanIds: number[];
  /** Query only L3 VNIs. */
  l3Only: boolean;
}

/** GetMaterializedVniRequest materializes a set of VNIs for a given node. */
export interface GetMaterializedVniRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** VNI identifier. */
  vni: number;
  /** The node identifier. */
  nodeId: string;
}

/** GetVrfsRequest encapsulates properties to query Vrf objects. */
export interface GetVrfsRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of Vrf identifiers. */
  ids: string[];
  /** A set of Vrf names. */
  names: string[];
  /** Include interface IP addresses in the response message. Must set exactly one identifier in the ID list. */
  needIps: boolean;
  /**
   * A set of IPv4 addresses to filter interfaces. Must set exactly one identifier in the ID list.
   * IPv4 addresses maybe either host addresses or network addresses.
   */
  ipv4Addresses: string[];
  /**
   * A set of IPv6 addresses to filter interfaces. ust set exactly one identifier in the ID list.
   * IPv6 addresses maybe either host addresses or network addresses.
   */
  ipv6Addresses: string[];
}

/** GetPortChannelsRequest encapsulates parameters to query PortChannel objects. */
export interface GetPortChannelsRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of PortChannel identifiers (required). */
  ids: string[];
  /** A set of names. */
  names: string[];
}

/** GetSubInterfacesRequest encapsulates parameters to query SubInterface objects. */
export interface GetSubInterfacesRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of node identifiers (required). */
  nodeIds: string[];
  /** A set of SubInterface identifiers. */
  ids: string[];
  /** A set of names. */
  names: string[];
  /** A set of parent interface names. */
  parents: string[];
}

/** GetStaticRoutesRequest encapsulates properties to query StaticRoutes objects. */
export interface GetStaticRoutesRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of StaticRoutes identifiers. */
  ids: string[];
  /** A set of StaticRoutes names. */
  names: string[];
  /** A set of VRF identifiers. */
  vrfIds: string[];
}

/** GetDhcpRelaysRequest encapsulates properties to query DhcpRelay objects. */
export interface GetDhcpRelaysRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of DhcpRelay identifiers. */
  ids: string[];
  /** A set of DhcpRelay names. */
  names: string[];
  /** A set of Vrf identifiers. */
  vrfIds: string[];
}

/** GetLoopbacksRequest encapsulates properties to query Loopback objects. */
export interface GetLoopbacksRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of node identifiers. */
  nodeIds: string[];
  /** A set of Loopback identifiers. */
  ids: string[];
  /** A set of Loopback names. */
  names: string[];
}

/** GetPerVlanStpRequest encapsulates properties to query PerVlanStp config of a fabric */
export interface GetPerVlanStpRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
}

/** GetPerVlanStpsResponse encapsulates current PerVlanStp config of a fabric. */
export interface GetPerVlanStpResponse {
  perVlanStp: PerVlanStp | undefined;
}

/** GetBgpPeersRequest encapsulates properties to query BgpPeer objects. */
export interface GetBgpPeersRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of BgpPeer identifiers. */
  ids: string[];
  /** A set of BgpPeer names. */
  names: string[];
  /** A set of VRF identifiers. */
  vrfIds: string[];
}

/**
 * DeviceClaim encapsulates information needed to claim a node. Organization
 * must provide either a serial number or device identifier to claim a device.
 * In addition to that, organization must provide a claim code as well.
 */
export interface DeviceClaim {
  /** The serial number of device. */
  serialNumber: string;
  /** The model name of device. */
  modelName: string;
  /** The device identifier (or MAC address). */
  deviceId: string;
  /** The claim code ???. */
  claimCode: string;
}

export interface DeviceClaimRequest {
  /** Devices to be claimed. */
  claims: DeviceClaim[];
}

export interface DeviceClaimResponse {
  /** Devices that are successfully claimed. */
  claims: DeviceClaim[];
}

/**
 * GetWorkflowFeedbacksRequest encapsulates properties to query workflow
 * execution feedback messages.
 */
export interface GetWorkflowFeedbacksRequest {
  /** A set of node identifier. Caller must provide at least one node identifier. */
  nodeIds: string[];
  /** Include task feedback messages. */
  details: boolean;
  /** Start timestamp for range query. Both StartTime and EndTime are required properties. */
  startTime:
    | Date
    | undefined;
  /** End timestamp for range query. Both StartTime and EndTime are required properties. */
  endTime:
    | Date
    | undefined;
  /** Fingerprint of the Fabric config. ConfigFp is optional. */
  configFp: string;
  /** An optional set of task names. */
  taskNames: string[];
  /** An optional set of workflow types. */
  workflowTypes: WorkflowType[];
}

/** GetConfigFeedbacksResponse encapsulates response to feedback query. */
export interface GetWorkflowFeedbacksResponse {
  nodeIds: string[];
  feedbacks: WorkflowFeedback[];
  names: string[];
}

/**
 * CandidateReview represents a candidate review record.
 * CandidateReview records are created when users add review comments.
 */
export interface CandidateReview {
  /** Transaction comments. */
  comments: string;
  /** UserId of the user who added the comments. */
  createdBy: string;
  createdAt:
    | Date
    | undefined;
  /** The type of user operation. */
  operation: Operation;
}

/** FabricCandidate represents a single fabric candidate record. */
export interface FabricCandidate {
  /** The fabric identifier. */
  fabricId: string;
  /** The candidate name. */
  name: string;
  /** Indicates that there are pending changes. */
  active: boolean;
  /** The transaction sequence number. */
  txnId: number;
  /** UserId of the user who created transaction. */
  createdBy: string;
  createdAt:
    | Date
    | undefined;
  /** A set of review logs. */
  reviews: CandidateReview[];
  /** Activity events. */
  events: ActivityEvent[];
}

/**
 * GetFabricCandidatesRequest encapsulates properties to query
 * pending candidates for a fabric.
 */
export interface GetFabricCandidatesRequest {
  /** The fabric identifier. */
  fabricId: string;
  /** The transaction name. */
  name: string;
  /** The transaction sequence number. */
  txnId: number;
  /** Include committed/reverted transactions. */
  needInactive: boolean;
  /** Include review logs. */
  needReviews: boolean;
  /** Include activity events. */
  needEvents: boolean;
  /** Start value of the time range. */
  startTime:
    | Date
    | undefined;
  /** End value of the time range. */
  endTime: Date | undefined;
  pagination: PaginationRequest | undefined;
}

/** GetFabricCandidateRequest returns a single named candidate configuration object. */
export interface GetFabricCandidateRequest {
  /** The fabric identifier. */
  fabricId: string;
  /** The candidate name. */
  name: string;
  /** Include committed/reverted transactions. */
  needInactive: boolean;
  /** Include review logs. */
  needReviews: boolean;
  /** Include activity events. */
  needEvents: boolean;
}

/**
 * GetFabricCandidatesResponse encapsulates query response for
 * fabric candidates.
 */
export interface GetFabricCandidatesResponse {
  candidates: FabricCandidate[];
  pagination: PaginationResponse | undefined;
}

/**
 * CommitFabricCandidateRequest encapsulates properties to commit a
 * pending fabric candidate. Candidates commit may require special
 * user privileges.
 */
export interface CommitFabricCandidateRequest {
  /** The fabric identifier. Fabric identifier is mandatory. */
  fabricId: string;
  /** The candidate name. */
  name: string;
  /** The reason for commit. */
  comments: string;
}

export interface CommitFabricCandidateResponse {
  /** The fabric identifier. */
  fabricId: string;
  /** Indicates that pending transaction have been committed. */
  committed: boolean;
}

/**
 * RevertFabricCandidateRequest encapsulates properties to revert or rollback
 * a pending transaction of a Fabric.
 */
export interface RevertFabricCandidateRequest {
  /** The fabric identifier. Fabric identifier is mandatory. */
  fabricId: string;
  /** The candidate name. */
  name: string;
  /** The reason for commit. */
  comments: string;
}

export interface RevertFabricCandidateResponse {
  /** The fabric identifier. */
  fabricId: string;
  /** Indicates that pending transaction have been reverted. */
  reverted: boolean;
}

/** FabricConfig encapsulates entire config of a fabric. */
export interface FabricConfig {
  /** Fabric object. */
  fabric:
    | Fabric
    | undefined;
  /** Tenant objects. */
  tenants: TenantObjects[];
  /** PerVlanStp of the fabric. */
  perVlanStp: PerVlanStp | undefined;
}

/**
 * GetFabricsRunningConfigRequest defines a request message to query
 * running configs of a fabric.
 */
export interface GetFabricConfigRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
}

export interface GetFabricConfigResponse {
  /** The candidate name. */
  candidate: string;
  config: FabricConfig | undefined;
}

/**
 * ReviewFabricCandidateRequest encapsulates properties to add a comment
 * to an existing candidate.
 */
export interface ReviewFabricCandidateRequest {
  /** The fabric identifier. Fabric identifier is mandatory. */
  fabricId: string;
  /** The candidate name. */
  name: string;
  /** Review comments. */
  comments: string;
}

export interface ReviewFabricCandidateResponse {
  /** The fabric identifier. */
  fabricId: string;
}

/**
 * GetFabricInventoriesRequest encapsulates properties required to query fabric inventories.
 * See StreamFabricInventories and GetFabricInventories gRPC APIs for details.
 * NOTES:
 * - The service does not support streaming all inventories. Caller must provide a identifier
 *   of a blueprint Fabric to stream inventories.
 * - Only one stream per user is supported.
 */
export interface GetFabricInventoriesRequest {
  /**
   * Query inventories of a given Fabric. This is valid only when at least one of the
   * discovered device is bound to a blueprint Fabric. Fabric identifier must be of
   * the blueprint Fabric.
   */
  fabricId: string;
  /** Query inventories that matches a specific topology. */
  topology: FabricTopology;
  /** Query inventories that include a set of node identifiers. */
  nodeIds: string[];
}

export interface GetFabricInventoriesResponse {
  fabrics: Fabric[];
}

/** ActivityEvent encapsulates an activity log event. */
export interface ActivityEvent {
  /** The monotonically increasing event identifier. */
  eventId: number;
  /** The activity event timestamp. */
  timestamp:
    | Date
    | undefined;
  /** The user who triggered the activity event. */
  username: string;
  /** The type of the object that triggered the event. */
  objectType: ObjectType;
  /** The object identifier. */
  objectId: string;
  /** The object name (maybe empty). */
  objectName: string;
  /** The type of parent object. */
  parentType: ObjectType;
  /** The object identifier of parent object. */
  parentId: string;
  /** The type of user operation. */
  operation: Operation;
}

/** GetActivityEventsRequest is the input message for querying activity events. */
export interface GetActivityEventsRequest {
  /** Optional start time of the query time range. */
  startTime:
    | Date
    | undefined;
  /** Optional end time of the query time range. */
  endTime:
    | Date
    | undefined;
  /** Filter by object types. */
  objectTypes: ObjectType[];
  /** Filter by object identifier. */
  objectIds: string[];
  /** Filter by username. */
  usernames: string[];
  /** Filter by timestamp. */
  objectNames: string[];
  /** The type of user operation. */
  operations: Operation[];
  pagination: PaginationRequest | undefined;
}

export interface GetActivityEventsResponse {
  events: ActivityEvent[];
  pagination: PaginationResponse | undefined;
}

/** GetTagsRequest is the input message for querying object labels. */
export interface GetLabelsRequest {
  /** Optional start time of the query time range. */
  startTime:
    | Date
    | undefined;
  /** Optional end time of the query time range. */
  endTime:
    | Date
    | undefined;
  /** Filter by object labels. */
  labels: string[];
  /** Filter by object types. */
  objectTypes: ObjectType[];
  /** Filter by object identifiers. */
  objectIds: string[];
  /** Do not include object identifiers in the response. */
  tagsOnly: boolean;
  pagination: PaginationRequest | undefined;
}

/** GetLabelsResponse is the output of GetLabels API. */
export interface GetLabelsResponse {
  /** list of tag objects. */
  labels: Label[];
  pagination: PaginationResponse | undefined;
}

/** GetShowTechConfigsRequest is the input message for querying pending show-tech requests. */
export interface GetShowTechConfigsRequest {
  /** The candidate name. */
  candidate: string;
  /** The fabric identifier. */
  fabricId: string;
  /** A set of node identifiers. */
  nodeIds: string[];
}

/** GetShowTechConfigsResponse is the output of GetShowTechConfigs API. */
export interface GetShowTechConfigsResponse {
  showTechs: NodeShowTech[];
}

/** GetDeviceModelsRequest encapsulates properties to query device models. */
export interface GetDeviceModelsRequest {
  /** A set of model names or PIDs. */
  modelNames: string[];
  /** A set of descriptive names (E.g. Siren). */
  names: string[];
  /** Device roles (E.g LEAF/SPINE). */
  roles: NodeRole[];
}

/** GetDeviceModelsResponse encapsulates query response for device models. */
export interface GetDeviceModelsResponse {
  devices: DeviceModel[];
}

/** GetCableModelsRequest encapsulates properties to query cable models. */
export interface GetCableModelsRequest {
  /** A set of model names or PIDs. */
  modelNames: string[];
  /** A set of speeds  (E.g. 100G, 40G etc.). */
  speeds: string[];
  /** A set of connector type names (E.g. SFP+). */
  connectors: string[];
  /** A set of cable types (E.g. DAC, SMF etc.) */
  cableTypes: CableType[];
}

/** GetCableModelsResponse encapsulates query response of a cable models. */
export interface GetCableModelsResponse {
  cables: CableModel[];
}

/** Add one or more nodes to a specific fabric. */
export interface AddNodesToFabricRequest {
  /** The fabric UUID. */
  fabricId: string;
  /** A list of one or more nodes to add to the fabric. */
  nodes: Node[];
}

export interface AddNodesToFabricResponse {
  nodes: Node[];
}

/** Delete a specific node in a fabric. */
export interface DeleteNodeFromFabricRequest {
  /** The fabric id. */
  fabricId: string;
  /** The node id. */
  nodeId: string;
}

export interface DeleteNodeFromFabricResponse {
  fabricId: string;
  nodeId: string;
}

/** Bind a device to a node. */
export interface BindDeviceRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The id of the node to bind. */
  nodeId: string;
  /** The id of the device to bind. */
  deviceId: string;
}

export interface BindDeviceResponse {
  nodeId: string;
  deviceId: string;
}

/** Unbind a device from a node. */
export interface UnbindDeviceRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The id of the node from which a device is bound to. */
  nodeId: string;
}

export interface UnbindDeviceResponse {
  /** The node id or name. */
  nodeId: string;
}

/** Get the list of connections configuration information for a specific node in a fabric. */
export interface GetNodeConnectionsRequest {
  /** The fabric id or name. */
  fabricId: string;
  /** The node id or name. */
  nodeId: string;
  /** The candidate configuration name. If not set the running configuration values are returned. */
  candidate: string;
}

export interface GetNodeConnectionsResponse {
  /** The node id. */
  nodeId: string;
  connections: PortConnection[];
}

/** GetLimitsRequest defines message to query limits and restrictions. */
export interface GetLimitsRequest {
}

/** GetLimitsResponse encapsulates limits and restriction values. */
export interface GetLimitsResponse {
  /** Maximum number of spines per fabric. */
  maxSpines: number;
  /** Maximum number of leaves per fabric. */
  maxLeaves: number;
  /** Maximum value of port index. */
  maxPortIndex: number;
  /** Maximum number of breakouts per port. */
  maxBreakouts: number;
  /** Maximum number of L3/L2 VNI per fabric. */
  maxVnis: number;
  /** Maximum number of VRF objects per fabric. */
  maxVrfs: number;
  /** Maximum number of members per Vni/Vlan. */
  maxVlanMembers: number;
  /** Minimum value of VlanId. */
  minUserVlanId: number;
  /** Maximum value of user-defined VlanId. */
  maxUserVlanId: number;
  /** Minimum value of user supplied VNI. */
  minUserVni: number;
  /** Maximum value of user-defined VNI. */
  maxUserVni: number;
  /** Maximum number of interfaces per VRF. */
  maxVrfInterfaces: number;
  /** Maximum number of SubInterfaces per port. */
  maxSubInterfaces: number;
  /** Maximum number of static routes per fabric. */
  maxStaticRoutes: number;
  /** Maximum number of DHCP relay objects per fabric. */
  maxDhcpRelays: number;
  /** Maximum number of PortChannel objects per fabric. */
  maxPortChannels: number;
  /** Maximum number of Loopback objects per node. */
  maxLoopbacks: number;
  /** Maximum number of PerVlanStp objects per fabric. */
  maxPerVlanStps: number;
  /** Maximum number of BgpPeer objects per fabric. */
  maxBgpPeers: number;
  /** Maximum number of annotations per object. */
  maxAnnotations: number;
  /** Minimum value of IPv4 CIDR mask. */
  minIpv4Mask: number;
  /** Minimum value of IPv6 CIDR mask. */
  minIpv6Mask: number;
}

function createBaseLabel(): Label {
  return {
    name: "",
    enabled: false,
    fabricIds: [],
    nodeIds: [],
    networkPortIds: [],
    portChannelIds: [],
    vlanIds: [],
    loopbackIds: [],
    vrfIds: [],
    vniIds: [],
    staticRouteIds: [],
    dhcpRelayIds: [],
    subInterfaceIds: [],
    bgpPeerIds: [],
    portBreakoutIds: [],
  };
}

export const Label = {
  encode(message: Label, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    for (const v of message.fabricIds) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.nodeIds) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.networkPortIds) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.portChannelIds) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.vlanIds) {
      writer.uint32(74).string(v!);
    }
    for (const v of message.loopbackIds) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.vrfIds) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.vniIds) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.staticRouteIds) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.dhcpRelayIds) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.subInterfaceIds) {
      writer.uint32(122).string(v!);
    }
    for (const v of message.bgpPeerIds) {
      writer.uint32(138).string(v!);
    }
    for (const v of message.portBreakoutIds) {
      writer.uint32(146).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Label {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.networkPortIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portChannelIds.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.vlanIds.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.loopbackIds.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.vrfIds.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.vniIds.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.staticRouteIds.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dhcpRelayIds.push(reader.string());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.subInterfaceIds.push(reader.string());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.bgpPeerIds.push(reader.string());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.portBreakoutIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Label {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      fabricIds: globalThis.Array.isArray(object?.fabricIds)
        ? object.fabricIds.map((e: any) => globalThis.String(e))
        : [],
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      networkPortIds: globalThis.Array.isArray(object?.networkPortIds)
        ? object.networkPortIds.map((e: any) => globalThis.String(e))
        : [],
      portChannelIds: globalThis.Array.isArray(object?.portChannelIds)
        ? object.portChannelIds.map((e: any) => globalThis.String(e))
        : [],
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.String(e)) : [],
      loopbackIds: globalThis.Array.isArray(object?.loopbackIds)
        ? object.loopbackIds.map((e: any) => globalThis.String(e))
        : [],
      vrfIds: globalThis.Array.isArray(object?.vrfIds) ? object.vrfIds.map((e: any) => globalThis.String(e)) : [],
      vniIds: globalThis.Array.isArray(object?.vniIds) ? object.vniIds.map((e: any) => globalThis.String(e)) : [],
      staticRouteIds: globalThis.Array.isArray(object?.staticRouteIds)
        ? object.staticRouteIds.map((e: any) => globalThis.String(e))
        : [],
      dhcpRelayIds: globalThis.Array.isArray(object?.dhcpRelayIds)
        ? object.dhcpRelayIds.map((e: any) => globalThis.String(e))
        : [],
      subInterfaceIds: globalThis.Array.isArray(object?.subInterfaceIds)
        ? object.subInterfaceIds.map((e: any) => globalThis.String(e))
        : [],
      bgpPeerIds: globalThis.Array.isArray(object?.bgpPeerIds)
        ? object.bgpPeerIds.map((e: any) => globalThis.String(e))
        : [],
      portBreakoutIds: globalThis.Array.isArray(object?.portBreakoutIds)
        ? object.portBreakoutIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Label): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.fabricIds?.length) {
      obj.fabricIds = message.fabricIds;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.networkPortIds?.length) {
      obj.networkPortIds = message.networkPortIds;
    }
    if (message.portChannelIds?.length) {
      obj.portChannelIds = message.portChannelIds;
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds;
    }
    if (message.loopbackIds?.length) {
      obj.loopbackIds = message.loopbackIds;
    }
    if (message.vrfIds?.length) {
      obj.vrfIds = message.vrfIds;
    }
    if (message.vniIds?.length) {
      obj.vniIds = message.vniIds;
    }
    if (message.staticRouteIds?.length) {
      obj.staticRouteIds = message.staticRouteIds;
    }
    if (message.dhcpRelayIds?.length) {
      obj.dhcpRelayIds = message.dhcpRelayIds;
    }
    if (message.subInterfaceIds?.length) {
      obj.subInterfaceIds = message.subInterfaceIds;
    }
    if (message.bgpPeerIds?.length) {
      obj.bgpPeerIds = message.bgpPeerIds;
    }
    if (message.portBreakoutIds?.length) {
      obj.portBreakoutIds = message.portBreakoutIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Label>, I>>(base?: I): Label {
    return Label.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Label>, I>>(object: I): Label {
    const message = createBaseLabel();
    message.name = object.name ?? "";
    message.enabled = object.enabled ?? false;
    message.fabricIds = object.fabricIds?.map((e) => e) || [];
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.networkPortIds = object.networkPortIds?.map((e) => e) || [];
    message.portChannelIds = object.portChannelIds?.map((e) => e) || [];
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.loopbackIds = object.loopbackIds?.map((e) => e) || [];
    message.vrfIds = object.vrfIds?.map((e) => e) || [];
    message.vniIds = object.vniIds?.map((e) => e) || [];
    message.staticRouteIds = object.staticRouteIds?.map((e) => e) || [];
    message.dhcpRelayIds = object.dhcpRelayIds?.map((e) => e) || [];
    message.subInterfaceIds = object.subInterfaceIds?.map((e) => e) || [];
    message.bgpPeerIds = object.bgpPeerIds?.map((e) => e) || [];
    message.portBreakoutIds = object.portBreakoutIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseAnnotation(): Annotation {
  return {
    annotation: undefined,
    enabled: false,
    fabricIds: [],
    nodeIds: [],
    networkPortIds: [],
    portChannelIds: [],
    vlanIds: [],
    loopbackIds: [],
    vrfIds: [],
    vniIds: [],
    staticRouteIds: [],
    dhcpRelayIds: [],
    subInterfaceIds: [],
    bgpPeerIds: [],
    portBreakoutIds: [],
  };
}

export const Annotation = {
  encode(message: Annotation, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.annotation !== undefined) {
      Annotation1.encode(message.annotation, writer.uint32(10).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(16).bool(message.enabled);
    }
    for (const v of message.fabricIds) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.nodeIds) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.networkPortIds) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.portChannelIds) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.vlanIds) {
      writer.uint32(74).string(v!);
    }
    for (const v of message.loopbackIds) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.vrfIds) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.vniIds) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.staticRouteIds) {
      writer.uint32(106).string(v!);
    }
    for (const v of message.dhcpRelayIds) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.subInterfaceIds) {
      writer.uint32(122).string(v!);
    }
    for (const v of message.bgpPeerIds) {
      writer.uint32(138).string(v!);
    }
    for (const v of message.portBreakoutIds) {
      writer.uint32(146).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Annotation {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnotation();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.annotation = Annotation1.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.networkPortIds.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.portChannelIds.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.vlanIds.push(reader.string());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.loopbackIds.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.vrfIds.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.vniIds.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.staticRouteIds.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.dhcpRelayIds.push(reader.string());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.subInterfaceIds.push(reader.string());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.bgpPeerIds.push(reader.string());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.portBreakoutIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Annotation {
    return {
      annotation: isSet(object.annotation) ? Annotation1.fromJSON(object.annotation) : undefined,
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      fabricIds: globalThis.Array.isArray(object?.fabricIds)
        ? object.fabricIds.map((e: any) => globalThis.String(e))
        : [],
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      networkPortIds: globalThis.Array.isArray(object?.networkPortIds)
        ? object.networkPortIds.map((e: any) => globalThis.String(e))
        : [],
      portChannelIds: globalThis.Array.isArray(object?.portChannelIds)
        ? object.portChannelIds.map((e: any) => globalThis.String(e))
        : [],
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.String(e)) : [],
      loopbackIds: globalThis.Array.isArray(object?.loopbackIds)
        ? object.loopbackIds.map((e: any) => globalThis.String(e))
        : [],
      vrfIds: globalThis.Array.isArray(object?.vrfIds) ? object.vrfIds.map((e: any) => globalThis.String(e)) : [],
      vniIds: globalThis.Array.isArray(object?.vniIds) ? object.vniIds.map((e: any) => globalThis.String(e)) : [],
      staticRouteIds: globalThis.Array.isArray(object?.staticRouteIds)
        ? object.staticRouteIds.map((e: any) => globalThis.String(e))
        : [],
      dhcpRelayIds: globalThis.Array.isArray(object?.dhcpRelayIds)
        ? object.dhcpRelayIds.map((e: any) => globalThis.String(e))
        : [],
      subInterfaceIds: globalThis.Array.isArray(object?.subInterfaceIds)
        ? object.subInterfaceIds.map((e: any) => globalThis.String(e))
        : [],
      bgpPeerIds: globalThis.Array.isArray(object?.bgpPeerIds)
        ? object.bgpPeerIds.map((e: any) => globalThis.String(e))
        : [],
      portBreakoutIds: globalThis.Array.isArray(object?.portBreakoutIds)
        ? object.portBreakoutIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Annotation): unknown {
    const obj: any = {};
    if (message.annotation !== undefined) {
      obj.annotation = Annotation1.toJSON(message.annotation);
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.fabricIds?.length) {
      obj.fabricIds = message.fabricIds;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.networkPortIds?.length) {
      obj.networkPortIds = message.networkPortIds;
    }
    if (message.portChannelIds?.length) {
      obj.portChannelIds = message.portChannelIds;
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds;
    }
    if (message.loopbackIds?.length) {
      obj.loopbackIds = message.loopbackIds;
    }
    if (message.vrfIds?.length) {
      obj.vrfIds = message.vrfIds;
    }
    if (message.vniIds?.length) {
      obj.vniIds = message.vniIds;
    }
    if (message.staticRouteIds?.length) {
      obj.staticRouteIds = message.staticRouteIds;
    }
    if (message.dhcpRelayIds?.length) {
      obj.dhcpRelayIds = message.dhcpRelayIds;
    }
    if (message.subInterfaceIds?.length) {
      obj.subInterfaceIds = message.subInterfaceIds;
    }
    if (message.bgpPeerIds?.length) {
      obj.bgpPeerIds = message.bgpPeerIds;
    }
    if (message.portBreakoutIds?.length) {
      obj.portBreakoutIds = message.portBreakoutIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Annotation>, I>>(base?: I): Annotation {
    return Annotation.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Annotation>, I>>(object: I): Annotation {
    const message = createBaseAnnotation();
    message.annotation = (object.annotation !== undefined && object.annotation !== null)
      ? Annotation1.fromPartial(object.annotation)
      : undefined;
    message.enabled = object.enabled ?? false;
    message.fabricIds = object.fabricIds?.map((e) => e) || [];
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.networkPortIds = object.networkPortIds?.map((e) => e) || [];
    message.portChannelIds = object.portChannelIds?.map((e) => e) || [];
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.loopbackIds = object.loopbackIds?.map((e) => e) || [];
    message.vrfIds = object.vrfIds?.map((e) => e) || [];
    message.vniIds = object.vniIds?.map((e) => e) || [];
    message.staticRouteIds = object.staticRouteIds?.map((e) => e) || [];
    message.dhcpRelayIds = object.dhcpRelayIds?.map((e) => e) || [];
    message.subInterfaceIds = object.subInterfaceIds?.map((e) => e) || [];
    message.bgpPeerIds = object.bgpPeerIds?.map((e) => e) || [];
    message.portBreakoutIds = object.portBreakoutIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseNodeShowTech(): NodeShowTech {
  return { fabricId: "", nodeId: "", startTime: undefined, endTime: undefined, metadata: undefined };
}

export const NodeShowTech = {
  encode(message: NodeShowTech, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): NodeShowTech {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNodeShowTech();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): NodeShowTech {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
    };
  },

  toJSON(message: NodeShowTech): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<NodeShowTech>, I>>(base?: I): NodeShowTech {
    return NodeShowTech.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<NodeShowTech>, I>>(object: I): NodeShowTech {
    const message = createBaseNodeShowTech();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    return message;
  },
};

function createBaseProvisionSettings(): ProvisionSettings {
  return { setPortPolicies: [], delPortPolicies: [], setOrgProperties: [], setLabels: [], setAnnotations: [] };
}

export const ProvisionSettings = {
  encode(message: ProvisionSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.setPortPolicies) {
      NetworkPortPolicy.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    for (const v of message.delPortPolicies) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.setOrgProperties) {
      OrgProperty.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.setLabels) {
      Label.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.setAnnotations) {
      Annotation.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 34) {
            break;
          }

          message.setPortPolicies.push(NetworkPortPolicy.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.delPortPolicies.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.setOrgProperties.push(OrgProperty.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.setLabels.push(Label.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.setAnnotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProvisionSettings {
    return {
      setPortPolicies: globalThis.Array.isArray(object?.setPortPolicies)
        ? object.setPortPolicies.map((e: any) => NetworkPortPolicy.fromJSON(e))
        : [],
      delPortPolicies: globalThis.Array.isArray(object?.delPortPolicies)
        ? object.delPortPolicies.map((e: any) => globalThis.String(e))
        : [],
      setOrgProperties: globalThis.Array.isArray(object?.setOrgProperties)
        ? object.setOrgProperties.map((e: any) => OrgProperty.fromJSON(e))
        : [],
      setLabels: globalThis.Array.isArray(object?.setLabels) ? object.setLabels.map((e: any) => Label.fromJSON(e)) : [],
      setAnnotations: globalThis.Array.isArray(object?.setAnnotations)
        ? object.setAnnotations.map((e: any) => Annotation.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ProvisionSettings): unknown {
    const obj: any = {};
    if (message.setPortPolicies?.length) {
      obj.setPortPolicies = message.setPortPolicies.map((e) => NetworkPortPolicy.toJSON(e));
    }
    if (message.delPortPolicies?.length) {
      obj.delPortPolicies = message.delPortPolicies;
    }
    if (message.setOrgProperties?.length) {
      obj.setOrgProperties = message.setOrgProperties.map((e) => OrgProperty.toJSON(e));
    }
    if (message.setLabels?.length) {
      obj.setLabels = message.setLabels.map((e) => Label.toJSON(e));
    }
    if (message.setAnnotations?.length) {
      obj.setAnnotations = message.setAnnotations.map((e) => Annotation.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProvisionSettings>, I>>(base?: I): ProvisionSettings {
    return ProvisionSettings.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionSettings>, I>>(object: I): ProvisionSettings {
    const message = createBaseProvisionSettings();
    message.setPortPolicies = object.setPortPolicies?.map((e) => NetworkPortPolicy.fromPartial(e)) || [];
    message.delPortPolicies = object.delPortPolicies?.map((e) => e) || [];
    message.setOrgProperties = object.setOrgProperties?.map((e) => OrgProperty.fromPartial(e)) || [];
    message.setLabels = object.setLabels?.map((e) => Label.fromPartial(e)) || [];
    message.setAnnotations = object.setAnnotations?.map((e) => Annotation.fromPartial(e)) || [];
    return message;
  },
};

function createBaseProvisionTenants(): ProvisionTenants {
  return {
    delTenants: [],
    setVlans: [],
    delVlans: [],
    setLoopbacks: [],
    delLoopbacks: [],
    setVrfs: [],
    delVrfs: [],
    setPortChannels: [],
    delPortChannels: [],
    setVnis: [],
    delVnis: [],
    setStaticRoutes: [],
    delStaticRoutes: [],
    setDhcpRelays: [],
    delDhcpRelays: [],
    setSubInterfaces: [],
    delSubInterfaces: [],
    setBgpPeers: [],
    delBgpPeers: [],
  };
}

export const ProvisionTenants = {
  encode(message: ProvisionTenants, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.delTenants) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.setVlans) {
      Vlan.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.delVlans) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.setLoopbacks) {
      Loopback.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.delLoopbacks) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.setVrfs) {
      Vrf.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.delVrfs) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.setPortChannels) {
      PortChannel.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.delPortChannels) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.setVnis) {
      Vni.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.delVnis) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.setStaticRoutes) {
      StaticRoutes.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.delStaticRoutes) {
      writer.uint32(114).string(v!);
    }
    for (const v of message.setDhcpRelays) {
      DhcpRelay.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.delDhcpRelays) {
      writer.uint32(130).string(v!);
    }
    for (const v of message.setSubInterfaces) {
      SubInterface.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.delSubInterfaces) {
      writer.uint32(146).string(v!);
    }
    for (const v of message.setBgpPeers) {
      BgpPeer.encode(v!, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.delBgpPeers) {
      writer.uint32(178).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionTenants {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionTenants();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.delTenants.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.setVlans.push(Vlan.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.delVlans.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.setLoopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.delLoopbacks.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.setVrfs.push(Vrf.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.delVrfs.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.setPortChannels.push(PortChannel.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.delPortChannels.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.setVnis.push(Vni.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.delVnis.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.setStaticRoutes.push(StaticRoutes.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.delStaticRoutes.push(reader.string());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.setDhcpRelays.push(DhcpRelay.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.delDhcpRelays.push(reader.string());
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.setSubInterfaces.push(SubInterface.decode(reader, reader.uint32()));
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.delSubInterfaces.push(reader.string());
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.setBgpPeers.push(BgpPeer.decode(reader, reader.uint32()));
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.delBgpPeers.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProvisionTenants {
    return {
      delTenants: globalThis.Array.isArray(object?.delTenants)
        ? object.delTenants.map((e: any) => globalThis.String(e))
        : [],
      setVlans: globalThis.Array.isArray(object?.setVlans) ? object.setVlans.map((e: any) => Vlan.fromJSON(e)) : [],
      delVlans: globalThis.Array.isArray(object?.delVlans) ? object.delVlans.map((e: any) => globalThis.String(e)) : [],
      setLoopbacks: globalThis.Array.isArray(object?.setLoopbacks)
        ? object.setLoopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
      delLoopbacks: globalThis.Array.isArray(object?.delLoopbacks)
        ? object.delLoopbacks.map((e: any) => globalThis.String(e))
        : [],
      setVrfs: globalThis.Array.isArray(object?.setVrfs) ? object.setVrfs.map((e: any) => Vrf.fromJSON(e)) : [],
      delVrfs: globalThis.Array.isArray(object?.delVrfs) ? object.delVrfs.map((e: any) => globalThis.String(e)) : [],
      setPortChannels: globalThis.Array.isArray(object?.setPortChannels)
        ? object.setPortChannels.map((e: any) => PortChannel.fromJSON(e))
        : [],
      delPortChannels: globalThis.Array.isArray(object?.delPortChannels)
        ? object.delPortChannels.map((e: any) => globalThis.String(e))
        : [],
      setVnis: globalThis.Array.isArray(object?.setVnis)
        ? object.setVnis.map((e: any) => Vni.fromJSON(e))
        : [],
      delVnis: globalThis.Array.isArray(object?.delVnis) ? object.delVnis.map((e: any) => globalThis.String(e)) : [],
      setStaticRoutes: globalThis.Array.isArray(object?.setStaticRoutes)
        ? object.setStaticRoutes.map((e: any) => StaticRoutes.fromJSON(e))
        : [],
      delStaticRoutes: globalThis.Array.isArray(object?.delStaticRoutes)
        ? object.delStaticRoutes.map((e: any) => globalThis.String(e))
        : [],
      setDhcpRelays: globalThis.Array.isArray(object?.setDhcpRelays)
        ? object.setDhcpRelays.map((e: any) => DhcpRelay.fromJSON(e))
        : [],
      delDhcpRelays: globalThis.Array.isArray(object?.delDhcpRelays)
        ? object.delDhcpRelays.map((e: any) => globalThis.String(e))
        : [],
      setSubInterfaces: globalThis.Array.isArray(object?.setSubInterfaces)
        ? object.setSubInterfaces.map((e: any) => SubInterface.fromJSON(e))
        : [],
      delSubInterfaces: globalThis.Array.isArray(object?.delSubInterfaces)
        ? object.delSubInterfaces.map((e: any) => globalThis.String(e))
        : [],
      setBgpPeers: globalThis.Array.isArray(object?.setBgpPeers)
        ? object.setBgpPeers.map((e: any) => BgpPeer.fromJSON(e))
        : [],
      delBgpPeers: globalThis.Array.isArray(object?.delBgpPeers)
        ? object.delBgpPeers.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProvisionTenants): unknown {
    const obj: any = {};
    if (message.delTenants?.length) {
      obj.delTenants = message.delTenants;
    }
    if (message.setVlans?.length) {
      obj.setVlans = message.setVlans.map((e) => Vlan.toJSON(e));
    }
    if (message.delVlans?.length) {
      obj.delVlans = message.delVlans;
    }
    if (message.setLoopbacks?.length) {
      obj.setLoopbacks = message.setLoopbacks.map((e) => Loopback.toJSON(e));
    }
    if (message.delLoopbacks?.length) {
      obj.delLoopbacks = message.delLoopbacks;
    }
    if (message.setVrfs?.length) {
      obj.setVrfs = message.setVrfs.map((e) => Vrf.toJSON(e));
    }
    if (message.delVrfs?.length) {
      obj.delVrfs = message.delVrfs;
    }
    if (message.setPortChannels?.length) {
      obj.setPortChannels = message.setPortChannels.map((e) => PortChannel.toJSON(e));
    }
    if (message.delPortChannels?.length) {
      obj.delPortChannels = message.delPortChannels;
    }
    if (message.setVnis?.length) {
      obj.setVnis = message.setVnis.map((e) => Vni.toJSON(e));
    }
    if (message.delVnis?.length) {
      obj.delVnis = message.delVnis;
    }
    if (message.setStaticRoutes?.length) {
      obj.setStaticRoutes = message.setStaticRoutes.map((e) => StaticRoutes.toJSON(e));
    }
    if (message.delStaticRoutes?.length) {
      obj.delStaticRoutes = message.delStaticRoutes;
    }
    if (message.setDhcpRelays?.length) {
      obj.setDhcpRelays = message.setDhcpRelays.map((e) => DhcpRelay.toJSON(e));
    }
    if (message.delDhcpRelays?.length) {
      obj.delDhcpRelays = message.delDhcpRelays;
    }
    if (message.setSubInterfaces?.length) {
      obj.setSubInterfaces = message.setSubInterfaces.map((e) => SubInterface.toJSON(e));
    }
    if (message.delSubInterfaces?.length) {
      obj.delSubInterfaces = message.delSubInterfaces;
    }
    if (message.setBgpPeers?.length) {
      obj.setBgpPeers = message.setBgpPeers.map((e) => BgpPeer.toJSON(e));
    }
    if (message.delBgpPeers?.length) {
      obj.delBgpPeers = message.delBgpPeers;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProvisionTenants>, I>>(base?: I): ProvisionTenants {
    return ProvisionTenants.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionTenants>, I>>(object: I): ProvisionTenants {
    const message = createBaseProvisionTenants();
    message.delTenants = object.delTenants?.map((e) => e) || [];
    message.setVlans = object.setVlans?.map((e) => Vlan.fromPartial(e)) || [];
    message.delVlans = object.delVlans?.map((e) => e) || [];
    message.setLoopbacks = object.setLoopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    message.delLoopbacks = object.delLoopbacks?.map((e) => e) || [];
    message.setVrfs = object.setVrfs?.map((e) => Vrf.fromPartial(e)) || [];
    message.delVrfs = object.delVrfs?.map((e) => e) || [];
    message.setPortChannels = object.setPortChannels?.map((e) => PortChannel.fromPartial(e)) || [];
    message.delPortChannels = object.delPortChannels?.map((e) => e) || [];
    message.setVnis = object.setVnis?.map((e) => Vni.fromPartial(e)) || [];
    message.delVnis = object.delVnis?.map((e) => e) || [];
    message.setStaticRoutes = object.setStaticRoutes?.map((e) => StaticRoutes.fromPartial(e)) || [];
    message.delStaticRoutes = object.delStaticRoutes?.map((e) => e) || [];
    message.setDhcpRelays = object.setDhcpRelays?.map((e) => DhcpRelay.fromPartial(e)) || [];
    message.delDhcpRelays = object.delDhcpRelays?.map((e) => e) || [];
    message.setSubInterfaces = object.setSubInterfaces?.map((e) => SubInterface.fromPartial(e)) || [];
    message.delSubInterfaces = object.delSubInterfaces?.map((e) => e) || [];
    message.setBgpPeers = object.setBgpPeers?.map((e) => BgpPeer.fromPartial(e)) || [];
    message.delBgpPeers = object.delBgpPeers?.map((e) => e) || [];
    return message;
  },
};

function createBaseBindDevice(): BindDevice {
  return { fabricId: "", nodeId: "", deviceId: "" };
}

export const BindDevice = {
  encode(message: BindDevice, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BindDevice {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBindDevice();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BindDevice {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: BindDevice): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BindDevice>, I>>(base?: I): BindDevice {
    return BindDevice.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BindDevice>, I>>(object: I): BindDevice {
    const message = createBaseBindDevice();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseProvisionFabrics(): ProvisionFabrics {
  return {
    setFabrics: [],
    delFabrics: [],
    setConnections: [],
    delConnections: [],
    setPortBreakouts: [],
    delPortBreakouts: [],
    setNodes: [],
    delNodes: [],
    bindDevices: [],
    unbindDevices: [],
    setPerVlanStps: [],
    setNetworkPorts: [],
    resetNetworkPorts: [],
    setShowTechs: [],
    delInventories: [],
  };
}

export const ProvisionFabrics = {
  encode(message: ProvisionFabrics, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.setFabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.delFabrics) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.setConnections) {
      PortConnection.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.delConnections) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.setPortBreakouts) {
      PortBreakout.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    for (const v of message.delPortBreakouts) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.setNodes) {
      Node.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.delNodes) {
      writer.uint32(66).string(v!);
    }
    for (const v of message.bindDevices) {
      BindDevice.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.unbindDevices) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.setPerVlanStps) {
      PerVlanStp.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.setNetworkPorts) {
      NetworkPort.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.resetNetworkPorts) {
      writer.uint32(130).string(v!);
    }
    for (const v of message.setShowTechs) {
      NodeShowTech.encode(v!, writer.uint32(394).fork()).ldelim();
    }
    for (const v of message.delInventories) {
      writer.uint32(402).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionFabrics {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionFabrics();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.setFabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.delFabrics.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.setConnections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.delConnections.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.setPortBreakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.delPortBreakouts.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.setNodes.push(Node.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.delNodes.push(reader.string());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.bindDevices.push(BindDevice.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.unbindDevices.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.setPerVlanStps.push(PerVlanStp.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.setNetworkPorts.push(NetworkPort.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.resetNetworkPorts.push(reader.string());
          continue;
        case 49:
          if (tag !== 394) {
            break;
          }

          message.setShowTechs.push(NodeShowTech.decode(reader, reader.uint32()));
          continue;
        case 50:
          if (tag !== 402) {
            break;
          }

          message.delInventories.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProvisionFabrics {
    return {
      setFabrics: globalThis.Array.isArray(object?.setFabrics)
        ? object.setFabrics.map((e: any) => Fabric.fromJSON(e))
        : [],
      delFabrics: globalThis.Array.isArray(object?.delFabrics)
        ? object.delFabrics.map((e: any) => globalThis.String(e))
        : [],
      setConnections: globalThis.Array.isArray(object?.setConnections)
        ? object.setConnections.map((e: any) => PortConnection.fromJSON(e))
        : [],
      delConnections: globalThis.Array.isArray(object?.delConnections)
        ? object.delConnections.map((e: any) => globalThis.String(e))
        : [],
      setPortBreakouts: globalThis.Array.isArray(object?.setPortBreakouts)
        ? object.setPortBreakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
      delPortBreakouts: globalThis.Array.isArray(object?.delPortBreakouts)
        ? object.delPortBreakouts.map((e: any) => globalThis.String(e))
        : [],
      setNodes: globalThis.Array.isArray(object?.setNodes)
        ? object.setNodes.map((e: any) => Node.fromJSON(e))
        : [],
      delNodes: globalThis.Array.isArray(object?.delNodes) ? object.delNodes.map((e: any) => globalThis.String(e)) : [],
      bindDevices: globalThis.Array.isArray(object?.bindDevices)
        ? object.bindDevices.map((e: any) => BindDevice.fromJSON(e))
        : [],
      unbindDevices: globalThis.Array.isArray(object?.unbindDevices)
        ? object.unbindDevices.map((e: any) => globalThis.String(e))
        : [],
      setPerVlanStps: globalThis.Array.isArray(object?.setPerVlanStps)
        ? object.setPerVlanStps.map((e: any) => PerVlanStp.fromJSON(e))
        : [],
      setNetworkPorts: globalThis.Array.isArray(object?.setNetworkPorts)
        ? object.setNetworkPorts.map((e: any) => NetworkPort.fromJSON(e))
        : [],
      resetNetworkPorts: globalThis.Array.isArray(object?.resetNetworkPorts)
        ? object.resetNetworkPorts.map((e: any) => globalThis.String(e))
        : [],
      setShowTechs: globalThis.Array.isArray(object?.setShowTechs)
        ? object.setShowTechs.map((e: any) => NodeShowTech.fromJSON(e))
        : [],
      delInventories: globalThis.Array.isArray(object?.delInventories)
        ? object.delInventories.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: ProvisionFabrics): unknown {
    const obj: any = {};
    if (message.setFabrics?.length) {
      obj.setFabrics = message.setFabrics.map((e) => Fabric.toJSON(e));
    }
    if (message.delFabrics?.length) {
      obj.delFabrics = message.delFabrics;
    }
    if (message.setConnections?.length) {
      obj.setConnections = message.setConnections.map((e) => PortConnection.toJSON(e));
    }
    if (message.delConnections?.length) {
      obj.delConnections = message.delConnections;
    }
    if (message.setPortBreakouts?.length) {
      obj.setPortBreakouts = message.setPortBreakouts.map((e) => PortBreakout.toJSON(e));
    }
    if (message.delPortBreakouts?.length) {
      obj.delPortBreakouts = message.delPortBreakouts;
    }
    if (message.setNodes?.length) {
      obj.setNodes = message.setNodes.map((e) => Node.toJSON(e));
    }
    if (message.delNodes?.length) {
      obj.delNodes = message.delNodes;
    }
    if (message.bindDevices?.length) {
      obj.bindDevices = message.bindDevices.map((e) => BindDevice.toJSON(e));
    }
    if (message.unbindDevices?.length) {
      obj.unbindDevices = message.unbindDevices;
    }
    if (message.setPerVlanStps?.length) {
      obj.setPerVlanStps = message.setPerVlanStps.map((e) => PerVlanStp.toJSON(e));
    }
    if (message.setNetworkPorts?.length) {
      obj.setNetworkPorts = message.setNetworkPorts.map((e) => NetworkPort.toJSON(e));
    }
    if (message.resetNetworkPorts?.length) {
      obj.resetNetworkPorts = message.resetNetworkPorts;
    }
    if (message.setShowTechs?.length) {
      obj.setShowTechs = message.setShowTechs.map((e) => NodeShowTech.toJSON(e));
    }
    if (message.delInventories?.length) {
      obj.delInventories = message.delInventories;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProvisionFabrics>, I>>(base?: I): ProvisionFabrics {
    return ProvisionFabrics.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionFabrics>, I>>(object: I): ProvisionFabrics {
    const message = createBaseProvisionFabrics();
    message.setFabrics = object.setFabrics?.map((e) => Fabric.fromPartial(e)) || [];
    message.delFabrics = object.delFabrics?.map((e) => e) || [];
    message.setConnections = object.setConnections?.map((e) => PortConnection.fromPartial(e)) || [];
    message.delConnections = object.delConnections?.map((e) => e) || [];
    message.setPortBreakouts = object.setPortBreakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    message.delPortBreakouts = object.delPortBreakouts?.map((e) => e) || [];
    message.setNodes = object.setNodes?.map((e) => Node.fromPartial(e)) || [];
    message.delNodes = object.delNodes?.map((e) => e) || [];
    message.bindDevices = object.bindDevices?.map((e) => BindDevice.fromPartial(e)) || [];
    message.unbindDevices = object.unbindDevices?.map((e) => e) || [];
    message.setPerVlanStps = object.setPerVlanStps?.map((e) => PerVlanStp.fromPartial(e)) || [];
    message.setNetworkPorts = object.setNetworkPorts?.map((e) => NetworkPort.fromPartial(e)) || [];
    message.resetNetworkPorts = object.resetNetworkPorts?.map((e) => e) || [];
    message.setShowTechs = object.setShowTechs?.map((e) => NodeShowTech.fromPartial(e)) || [];
    message.delInventories = object.delInventories?.map((e) => e) || [];
    return message;
  },
};

function createBaseProvisionRequest(): ProvisionRequest {
  return {
    dryRun: false,
    fabricId: "",
    candidate: "",
    comments: "",
    settings: undefined,
    fabrics: undefined,
    tenants: undefined,
  };
}

export const ProvisionRequest = {
  encode(message: ProvisionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dryRun !== false) {
      writer.uint32(8).bool(message.dryRun);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.comments !== "") {
      writer.uint32(34).string(message.comments);
    }
    if (message.settings !== undefined) {
      ProvisionSettings.encode(message.settings, writer.uint32(42).fork()).ldelim();
    }
    if (message.fabrics !== undefined) {
      ProvisionFabrics.encode(message.fabrics, writer.uint32(50).fork()).ldelim();
    }
    if (message.tenants !== undefined) {
      ProvisionTenants.encode(message.tenants, writer.uint32(58).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.dryRun = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.settings = ProvisionSettings.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.fabrics = ProvisionFabrics.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tenants = ProvisionTenants.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProvisionRequest {
    return {
      dryRun: isSet(object.dryRun) ? globalThis.Boolean(object.dryRun) : false,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
      settings: isSet(object.settings) ? ProvisionSettings.fromJSON(object.settings) : undefined,
      fabrics: isSet(object.fabrics) ? ProvisionFabrics.fromJSON(object.fabrics) : undefined,
      tenants: isSet(object.tenants) ? ProvisionTenants.fromJSON(object.tenants) : undefined,
    };
  },

  toJSON(message: ProvisionRequest): unknown {
    const obj: any = {};
    if (message.dryRun !== false) {
      obj.dryRun = message.dryRun;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    if (message.settings !== undefined) {
      obj.settings = ProvisionSettings.toJSON(message.settings);
    }
    if (message.fabrics !== undefined) {
      obj.fabrics = ProvisionFabrics.toJSON(message.fabrics);
    }
    if (message.tenants !== undefined) {
      obj.tenants = ProvisionTenants.toJSON(message.tenants);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProvisionRequest>, I>>(base?: I): ProvisionRequest {
    return ProvisionRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionRequest>, I>>(object: I): ProvisionRequest {
    const message = createBaseProvisionRequest();
    message.dryRun = object.dryRun ?? false;
    message.fabricId = object.fabricId ?? "";
    message.candidate = object.candidate ?? "";
    message.comments = object.comments ?? "";
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? ProvisionSettings.fromPartial(object.settings)
      : undefined;
    message.fabrics = (object.fabrics !== undefined && object.fabrics !== null)
      ? ProvisionFabrics.fromPartial(object.fabrics)
      : undefined;
    message.tenants = (object.tenants !== undefined && object.tenants !== null)
      ? ProvisionTenants.fromPartial(object.tenants)
      : undefined;
    return message;
  },
};

function createBaseProvisionResponse(): ProvisionResponse {
  return { dryRun: false, settings: undefined, fabrics: undefined, tenants: undefined };
}

export const ProvisionResponse = {
  encode(message: ProvisionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dryRun !== false) {
      writer.uint32(8).bool(message.dryRun);
    }
    if (message.settings !== undefined) {
      ProvisionSettings.encode(message.settings, writer.uint32(18).fork()).ldelim();
    }
    if (message.fabrics !== undefined) {
      ProvisionFabrics.encode(message.fabrics, writer.uint32(26).fork()).ldelim();
    }
    if (message.tenants !== undefined) {
      ProvisionTenants.encode(message.tenants, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProvisionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProvisionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.dryRun = reader.bool();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.settings = ProvisionSettings.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.fabrics = ProvisionFabrics.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.tenants = ProvisionTenants.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ProvisionResponse {
    return {
      dryRun: isSet(object.dryRun) ? globalThis.Boolean(object.dryRun) : false,
      settings: isSet(object.settings) ? ProvisionSettings.fromJSON(object.settings) : undefined,
      fabrics: isSet(object.fabrics) ? ProvisionFabrics.fromJSON(object.fabrics) : undefined,
      tenants: isSet(object.tenants) ? ProvisionTenants.fromJSON(object.tenants) : undefined,
    };
  },

  toJSON(message: ProvisionResponse): unknown {
    const obj: any = {};
    if (message.dryRun !== false) {
      obj.dryRun = message.dryRun;
    }
    if (message.settings !== undefined) {
      obj.settings = ProvisionSettings.toJSON(message.settings);
    }
    if (message.fabrics !== undefined) {
      obj.fabrics = ProvisionFabrics.toJSON(message.fabrics);
    }
    if (message.tenants !== undefined) {
      obj.tenants = ProvisionTenants.toJSON(message.tenants);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ProvisionResponse>, I>>(base?: I): ProvisionResponse {
    return ProvisionResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProvisionResponse>, I>>(object: I): ProvisionResponse {
    const message = createBaseProvisionResponse();
    message.dryRun = object.dryRun ?? false;
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? ProvisionSettings.fromPartial(object.settings)
      : undefined;
    message.fabrics = (object.fabrics !== undefined && object.fabrics !== null)
      ? ProvisionFabrics.fromPartial(object.fabrics)
      : undefined;
    message.tenants = (object.tenants !== undefined && object.tenants !== null)
      ? ProvisionTenants.fromPartial(object.tenants)
      : undefined;
    return message;
  },
};

function createBaseGetFabricsFilters(): GetFabricsFilters {
  return { partialMatch: false, loadChildren: false, candidate: "", fabricIds: [], names: [], labels: [], nodeIds: [] };
}

export const GetFabricsFilters = {
  encode(message: GetFabricsFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.partialMatch !== false) {
      writer.uint32(8).bool(message.partialMatch);
    }
    if (message.loadChildren !== false) {
      writer.uint32(16).bool(message.loadChildren);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    for (const v of message.fabricIds) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.labels) {
      writer.uint32(58).string(v!);
    }
    for (const v of message.nodeIds) {
      writer.uint32(66).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricsFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricsFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.partialMatch = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.loadChildren = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.fabricIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricsFilters {
    return {
      partialMatch: isSet(object.partialMatch) ? globalThis.Boolean(object.partialMatch) : false,
      loadChildren: isSet(object.loadChildren) ? globalThis.Boolean(object.loadChildren) : false,
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricIds: globalThis.Array.isArray(object?.fabricIds)
        ? object.fabricIds.map((e: any) => globalThis.String(e))
        : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetFabricsFilters): unknown {
    const obj: any = {};
    if (message.partialMatch !== false) {
      obj.partialMatch = message.partialMatch;
    }
    if (message.loadChildren !== false) {
      obj.loadChildren = message.loadChildren;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricIds?.length) {
      obj.fabricIds = message.fabricIds;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricsFilters>, I>>(base?: I): GetFabricsFilters {
    return GetFabricsFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricsFilters>, I>>(object: I): GetFabricsFilters {
    const message = createBaseGetFabricsFilters();
    message.partialMatch = object.partialMatch ?? false;
    message.loadChildren = object.loadChildren ?? false;
    message.candidate = object.candidate ?? "";
    message.fabricIds = object.fabricIds?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.labels = object.labels?.map((e) => e) || [];
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetFabricsRequest(): GetFabricsRequest {
  return { filters: undefined, pagination: undefined };
}

export const GetFabricsRequest = {
  encode(message: GetFabricsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filters !== undefined) {
      GetFabricsFilters.encode(message.filters, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters = GetFabricsFilters.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricsRequest {
    return {
      filters: isSet(object.filters) ? GetFabricsFilters.fromJSON(object.filters) : undefined,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetFabricsRequest): unknown {
    const obj: any = {};
    if (message.filters !== undefined) {
      obj.filters = GetFabricsFilters.toJSON(message.filters);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricsRequest>, I>>(base?: I): GetFabricsRequest {
    return GetFabricsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricsRequest>, I>>(object: I): GetFabricsRequest {
    const message = createBaseGetFabricsRequest();
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? GetFabricsFilters.fromPartial(object.filters)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetFabricsResponse(): GetFabricsResponse {
  return { fabrics: [], candidate: "", pagination: undefined };
}

export const GetFabricsResponse = {
  encode(message: GetFabricsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricsResponse {
    return {
      fabrics: globalThis.Array.isArray(object?.fabrics) ? object.fabrics.map((e: any) => Fabric.fromJSON(e)) : [],
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetFabricsResponse): unknown {
    const obj: any = {};
    if (message.fabrics?.length) {
      obj.fabrics = message.fabrics.map((e) => Fabric.toJSON(e));
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricsResponse>, I>>(base?: I): GetFabricsResponse {
    return GetFabricsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricsResponse>, I>>(object: I): GetFabricsResponse {
    const message = createBaseGetFabricsResponse();
    message.fabrics = object.fabrics?.map((e) => Fabric.fromPartial(e)) || [];
    message.candidate = object.candidate ?? "";
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetNodesFilters(): GetNodesFilters {
  return {
    partialMatch: false,
    loadChildren: false,
    candidate: "",
    fabricId: "",
    nodeIds: [],
    names: [],
    labels: [],
    roles: [],
    serialNumbers: [],
    modelNames: [],
    deviceIds: [],
  };
}

export const GetNodesFilters = {
  encode(message: GetNodesFilters, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.partialMatch !== false) {
      writer.uint32(8).bool(message.partialMatch);
    }
    if (message.loadChildren !== false) {
      writer.uint32(16).bool(message.loadChildren);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(34).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.labels) {
      writer.uint32(58).string(v!);
    }
    writer.uint32(66).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.serialNumbers) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.modelNames) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.deviceIds) {
      writer.uint32(98).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodesFilters {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodesFilters();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.partialMatch = reader.bool();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.loadChildren = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 8:
          if (tag === 64) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.serialNumbers.push(reader.string());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.modelNames.push(reader.string());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.deviceIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodesFilters {
    return {
      partialMatch: isSet(object.partialMatch) ? globalThis.Boolean(object.partialMatch) : false,
      loadChildren: isSet(object.loadChildren) ? globalThis.Boolean(object.loadChildren) : false,
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
      serialNumbers: globalThis.Array.isArray(object?.serialNumbers)
        ? object.serialNumbers.map((e: any) => globalThis.String(e))
        : [],
      modelNames: globalThis.Array.isArray(object?.modelNames)
        ? object.modelNames.map((e: any) => globalThis.String(e))
        : [],
      deviceIds: globalThis.Array.isArray(object?.deviceIds)
        ? object.deviceIds.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetNodesFilters): unknown {
    const obj: any = {};
    if (message.partialMatch !== false) {
      obj.partialMatch = message.partialMatch;
    }
    if (message.loadChildren !== false) {
      obj.loadChildren = message.loadChildren;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    if (message.serialNumbers?.length) {
      obj.serialNumbers = message.serialNumbers;
    }
    if (message.modelNames?.length) {
      obj.modelNames = message.modelNames;
    }
    if (message.deviceIds?.length) {
      obj.deviceIds = message.deviceIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodesFilters>, I>>(base?: I): GetNodesFilters {
    return GetNodesFilters.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodesFilters>, I>>(object: I): GetNodesFilters {
    const message = createBaseGetNodesFilters();
    message.partialMatch = object.partialMatch ?? false;
    message.loadChildren = object.loadChildren ?? false;
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.labels = object.labels?.map((e) => e) || [];
    message.roles = object.roles?.map((e) => e) || [];
    message.serialNumbers = object.serialNumbers?.map((e) => e) || [];
    message.modelNames = object.modelNames?.map((e) => e) || [];
    message.deviceIds = object.deviceIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetNodesRequest(): GetNodesRequest {
  return { filters: undefined, pagination: undefined };
}

export const GetNodesRequest = {
  encode(message: GetNodesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filters !== undefined) {
      GetNodesFilters.encode(message.filters, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filters = GetNodesFilters.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodesRequest {
    return {
      filters: isSet(object.filters) ? GetNodesFilters.fromJSON(object.filters) : undefined,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetNodesRequest): unknown {
    const obj: any = {};
    if (message.filters !== undefined) {
      obj.filters = GetNodesFilters.toJSON(message.filters);
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodesRequest>, I>>(base?: I): GetNodesRequest {
    return GetNodesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodesRequest>, I>>(object: I): GetNodesRequest {
    const message = createBaseGetNodesRequest();
    message.filters = (object.filters !== undefined && object.filters !== null)
      ? GetNodesFilters.fromPartial(object.filters)
      : undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetNodesResponse(): GetNodesResponse {
  return { nodes: [], candidate: "", pagination: undefined };
}

export const GetNodesResponse = {
  encode(message: GetNodesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.candidate !== "") {
      writer.uint32(18).string(message.candidate);
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodesResponse {
    return {
      nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [],
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetNodesResponse): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodesResponse>, I>>(base?: I): GetNodesResponse {
    return GetNodesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodesResponse>, I>>(object: I): GetNodesResponse {
    const message = createBaseGetNodesResponse();
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    message.candidate = object.candidate ?? "";
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetNodesPortsRequest(): GetNodesPortsRequest {
  return { candidate: "", fabricId: "", nodeIds: [], ports: [] };
}

export const GetNodesPortsRequest = {
  encode(message: GetNodesPortsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.ports) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodesPortsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodesPortsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ports.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodesPortsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetNodesPortsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodesPortsRequest>, I>>(base?: I): GetNodesPortsRequest {
    return GetNodesPortsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodesPortsRequest>, I>>(object: I): GetNodesPortsRequest {
    const message = createBaseGetNodesPortsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.ports = object.ports?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetNodesPortsResponse(): GetNodesPortsResponse {
  return { nodes: [] };
}

export const GetNodesPortsResponse = {
  encode(message: GetNodesPortsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodesPortsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodesPortsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodesPortsResponse {
    return { nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [] };
  },

  toJSON(message: GetNodesPortsResponse): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodesPortsResponse>, I>>(base?: I): GetNodesPortsResponse {
    return GetNodesPortsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodesPortsResponse>, I>>(object: I): GetNodesPortsResponse {
    const message = createBaseGetNodesPortsResponse();
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetPortBreakoutsRequest(): GetPortBreakoutsRequest {
  return { candidate: "", fabricId: "", nodeIds: [], ids: [], names: [] };
}

export const GetPortBreakoutsRequest = {
  encode(message: GetPortBreakoutsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.ids) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPortBreakoutsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPortBreakoutsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPortBreakoutsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetPortBreakoutsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPortBreakoutsRequest>, I>>(base?: I): GetPortBreakoutsRequest {
    return GetPortBreakoutsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPortBreakoutsRequest>, I>>(object: I): GetPortBreakoutsRequest {
    const message = createBaseGetPortBreakoutsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetPortBreakoutsResponse(): GetPortBreakoutsResponse {
  return { breakouts: [] };
}

export const GetPortBreakoutsResponse = {
  encode(message: GetPortBreakoutsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.breakouts) {
      PortBreakout.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPortBreakoutsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPortBreakoutsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.breakouts.push(PortBreakout.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPortBreakoutsResponse {
    return {
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => PortBreakout.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetPortBreakoutsResponse): unknown {
    const obj: any = {};
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts.map((e) => PortBreakout.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPortBreakoutsResponse>, I>>(base?: I): GetPortBreakoutsResponse {
    return GetPortBreakoutsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPortBreakoutsResponse>, I>>(object: I): GetPortBreakoutsResponse {
    const message = createBaseGetPortBreakoutsResponse();
    message.breakouts = object.breakouts?.map((e) => PortBreakout.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTenantObjects(): TenantObjects {
  return {
    id: "",
    name: "",
    enabled: false,
    candidate: "",
    vlans: [],
    vrfs: [],
    portChannels: [],
    vnis: [],
    staticRoutes: [],
    dhcpRelays: [],
    loopbacks: [],
    subInterfaces: [],
    bgpPeers: [],
  };
}

export const TenantObjects = {
  encode(message: TenantObjects, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.enabled !== false) {
      writer.uint32(24).bool(message.enabled);
    }
    if (message.candidate !== "") {
      writer.uint32(34).string(message.candidate);
    }
    for (const v of message.vlans) {
      Vlan.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.vrfs) {
      Vrf.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.portChannels) {
      PortChannel.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.vnis) {
      Vni.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    for (const v of message.staticRoutes) {
      StaticRoutes.encode(v!, writer.uint32(114).fork()).ldelim();
    }
    for (const v of message.dhcpRelays) {
      DhcpRelay.encode(v!, writer.uint32(122).fork()).ldelim();
    }
    for (const v of message.loopbacks) {
      Loopback.encode(v!, writer.uint32(130).fork()).ldelim();
    }
    for (const v of message.subInterfaces) {
      SubInterface.encode(v!, writer.uint32(138).fork()).ldelim();
    }
    for (const v of message.bgpPeers) {
      BgpPeer.encode(v!, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantObjects {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantObjects();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.vlans.push(Vlan.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.vrfs.push(Vrf.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.portChannels.push(PortChannel.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.vnis.push(Vni.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.staticRoutes.push(StaticRoutes.decode(reader, reader.uint32()));
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.dhcpRelays.push(DhcpRelay.decode(reader, reader.uint32()));
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.loopbacks.push(Loopback.decode(reader, reader.uint32()));
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.subInterfaces.push(SubInterface.decode(reader, reader.uint32()));
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.bgpPeers.push(BgpPeer.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantObjects {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      vlans: globalThis.Array.isArray(object?.vlans) ? object.vlans.map((e: any) => Vlan.fromJSON(e)) : [],
      vrfs: globalThis.Array.isArray(object?.vrfs) ? object.vrfs.map((e: any) => Vrf.fromJSON(e)) : [],
      portChannels: globalThis.Array.isArray(object?.portChannels)
        ? object.portChannels.map((e: any) => PortChannel.fromJSON(e))
        : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => Vni.fromJSON(e)) : [],
      staticRoutes: globalThis.Array.isArray(object?.staticRoutes)
        ? object.staticRoutes.map((e: any) => StaticRoutes.fromJSON(e))
        : [],
      dhcpRelays: globalThis.Array.isArray(object?.dhcpRelays)
        ? object.dhcpRelays.map((e: any) => DhcpRelay.fromJSON(e))
        : [],
      loopbacks: globalThis.Array.isArray(object?.loopbacks)
        ? object.loopbacks.map((e: any) => Loopback.fromJSON(e))
        : [],
      subInterfaces: globalThis.Array.isArray(object?.subInterfaces)
        ? object.subInterfaces.map((e: any) => SubInterface.fromJSON(e))
        : [],
      bgpPeers: globalThis.Array.isArray(object?.bgpPeers)
        ? object.bgpPeers.map((e: any) => BgpPeer.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TenantObjects): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.vlans?.length) {
      obj.vlans = message.vlans.map((e) => Vlan.toJSON(e));
    }
    if (message.vrfs?.length) {
      obj.vrfs = message.vrfs.map((e) => Vrf.toJSON(e));
    }
    if (message.portChannels?.length) {
      obj.portChannels = message.portChannels.map((e) => PortChannel.toJSON(e));
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Vni.toJSON(e));
    }
    if (message.staticRoutes?.length) {
      obj.staticRoutes = message.staticRoutes.map((e) => StaticRoutes.toJSON(e));
    }
    if (message.dhcpRelays?.length) {
      obj.dhcpRelays = message.dhcpRelays.map((e) => DhcpRelay.toJSON(e));
    }
    if (message.loopbacks?.length) {
      obj.loopbacks = message.loopbacks.map((e) => Loopback.toJSON(e));
    }
    if (message.subInterfaces?.length) {
      obj.subInterfaces = message.subInterfaces.map((e) => SubInterface.toJSON(e));
    }
    if (message.bgpPeers?.length) {
      obj.bgpPeers = message.bgpPeers.map((e) => BgpPeer.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantObjects>, I>>(base?: I): TenantObjects {
    return TenantObjects.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantObjects>, I>>(object: I): TenantObjects {
    const message = createBaseTenantObjects();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.enabled = object.enabled ?? false;
    message.candidate = object.candidate ?? "";
    message.vlans = object.vlans?.map((e) => Vlan.fromPartial(e)) || [];
    message.vrfs = object.vrfs?.map((e) => Vrf.fromPartial(e)) || [];
    message.portChannels = object.portChannels?.map((e) => PortChannel.fromPartial(e)) || [];
    message.vnis = object.vnis?.map((e) => Vni.fromPartial(e)) || [];
    message.staticRoutes = object.staticRoutes?.map((e) => StaticRoutes.fromPartial(e)) || [];
    message.dhcpRelays = object.dhcpRelays?.map((e) => DhcpRelay.fromPartial(e)) || [];
    message.loopbacks = object.loopbacks?.map((e) => Loopback.fromPartial(e)) || [];
    message.subInterfaces = object.subInterfaces?.map((e) => SubInterface.fromPartial(e)) || [];
    message.bgpPeers = object.bgpPeers?.map((e) => BgpPeer.fromPartial(e)) || [];
    return message;
  },
};

function createBaseTenantsObjectsResponse(): TenantsObjectsResponse {
  return { tenants: [] };
}

export const TenantsObjectsResponse = {
  encode(message: TenantsObjectsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tenants) {
      TenantObjects.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TenantsObjectsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTenantsObjectsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tenants.push(TenantObjects.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TenantsObjectsResponse {
    return {
      tenants: globalThis.Array.isArray(object?.tenants)
        ? object.tenants.map((e: any) => TenantObjects.fromJSON(e))
        : [],
    };
  },

  toJSON(message: TenantsObjectsResponse): unknown {
    const obj: any = {};
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => TenantObjects.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<TenantsObjectsResponse>, I>>(base?: I): TenantsObjectsResponse {
    return TenantsObjectsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<TenantsObjectsResponse>, I>>(object: I): TenantsObjectsResponse {
    const message = createBaseTenantsObjectsResponse();
    message.tenants = object.tenants?.map((e) => TenantObjects.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTenantsObjectsRequest(): GetTenantsObjectsRequest {
  return { candidate: "", fabricId: "" };
}

export const GetTenantsObjectsRequest = {
  encode(message: GetTenantsObjectsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTenantsObjectsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTenantsObjectsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetTenantsObjectsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetTenantsObjectsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetTenantsObjectsRequest>, I>>(base?: I): GetTenantsObjectsRequest {
    return GetTenantsObjectsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTenantsObjectsRequest>, I>>(object: I): GetTenantsObjectsRequest {
    const message = createBaseGetTenantsObjectsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetVlansRequest(): GetVlansRequest {
  return { candidate: "", fabricId: "", ids: [], vlanIds: [], vnis: [] };
}

export const GetVlansRequest = {
  encode(message: GetVlansRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.vlanIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(42).fork();
    for (const v of message.vnis) {
      writer.uint32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVlansRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVlansRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.vlanIds.push(reader.uint32());

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vlanIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 5:
          if (tag === 40) {
            message.vnis.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vnis.push(reader.uint32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVlansRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.Number(e)) : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: GetVlansRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds.map((e) => Math.round(e));
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVlansRequest>, I>>(base?: I): GetVlansRequest {
    return GetVlansRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVlansRequest>, I>>(object: I): GetVlansRequest {
    const message = createBaseGetVlansRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.vnis = object.vnis?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetVnisRequest(): GetVnisRequest {
  return { candidate: "", fabricId: "", ids: [], names: [], vnis: [], vlanIds: [], l3Only: false };
}

export const GetVnisRequest = {
  encode(message: GetVnisRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(34).string(v!);
    }
    writer.uint32(42).fork();
    for (const v of message.vnis) {
      writer.uint32(v);
    }
    writer.ldelim();
    writer.uint32(50).fork();
    for (const v of message.vlanIds) {
      writer.uint32(v);
    }
    writer.ldelim();
    if (message.l3Only !== false) {
      writer.uint32(64).bool(message.l3Only);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVnisRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVnisRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 5:
          if (tag === 40) {
            message.vnis.push(reader.uint32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vnis.push(reader.uint32());
            }

            continue;
          }

          break;
        case 6:
          if (tag === 48) {
            message.vlanIds.push(reader.uint32());

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.vlanIds.push(reader.uint32());
            }

            continue;
          }

          break;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.l3Only = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVnisRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      vnis: globalThis.Array.isArray(object?.vnis) ? object.vnis.map((e: any) => globalThis.Number(e)) : [],
      vlanIds: globalThis.Array.isArray(object?.vlanIds) ? object.vlanIds.map((e: any) => globalThis.Number(e)) : [],
      l3Only: isSet(object.l3Only) ? globalThis.Boolean(object.l3Only) : false,
    };
  },

  toJSON(message: GetVnisRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.vnis?.length) {
      obj.vnis = message.vnis.map((e) => Math.round(e));
    }
    if (message.vlanIds?.length) {
      obj.vlanIds = message.vlanIds.map((e) => Math.round(e));
    }
    if (message.l3Only !== false) {
      obj.l3Only = message.l3Only;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVnisRequest>, I>>(base?: I): GetVnisRequest {
    return GetVnisRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVnisRequest>, I>>(object: I): GetVnisRequest {
    const message = createBaseGetVnisRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.vnis = object.vnis?.map((e) => e) || [];
    message.vlanIds = object.vlanIds?.map((e) => e) || [];
    message.l3Only = object.l3Only ?? false;
    return message;
  },
};

function createBaseGetMaterializedVniRequest(): GetMaterializedVniRequest {
  return { candidate: "", fabricId: "", vni: 0, nodeId: "" };
}

export const GetMaterializedVniRequest = {
  encode(message: GetMaterializedVniRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    if (message.vni !== 0) {
      writer.uint32(24).uint32(message.vni);
    }
    if (message.nodeId !== "") {
      writer.uint32(34).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMaterializedVniRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMaterializedVniRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.vni = reader.uint32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetMaterializedVniRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      vni: isSet(object.vni) ? globalThis.Number(object.vni) : 0,
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: GetMaterializedVniRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.vni !== 0) {
      obj.vni = Math.round(message.vni);
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMaterializedVniRequest>, I>>(base?: I): GetMaterializedVniRequest {
    return GetMaterializedVniRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetMaterializedVniRequest>, I>>(object: I): GetMaterializedVniRequest {
    const message = createBaseGetMaterializedVniRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.vni = object.vni ?? 0;
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseGetVrfsRequest(): GetVrfsRequest {
  return { candidate: "", fabricId: "", ids: [], names: [], needIps: false, ipv4Addresses: [], ipv6Addresses: [] };
}

export const GetVrfsRequest = {
  encode(message: GetVrfsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(34).string(v!);
    }
    if (message.needIps !== false) {
      writer.uint32(40).bool(message.needIps);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVrfsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetVrfsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needIps = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetVrfsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      needIps: isSet(object.needIps) ? globalThis.Boolean(object.needIps) : false,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: GetVrfsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.needIps !== false) {
      obj.needIps = message.needIps;
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetVrfsRequest>, I>>(base?: I): GetVrfsRequest {
    return GetVrfsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetVrfsRequest>, I>>(object: I): GetVrfsRequest {
    const message = createBaseGetVrfsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.needIps = object.needIps ?? false;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetPortChannelsRequest(): GetPortChannelsRequest {
  return { candidate: "", fabricId: "", ids: [], names: [] };
}

export const GetPortChannelsRequest = {
  encode(message: GetPortChannelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPortChannelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPortChannelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPortChannelsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetPortChannelsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPortChannelsRequest>, I>>(base?: I): GetPortChannelsRequest {
    return GetPortChannelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPortChannelsRequest>, I>>(object: I): GetPortChannelsRequest {
    const message = createBaseGetPortChannelsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetSubInterfacesRequest(): GetSubInterfacesRequest {
  return { candidate: "", fabricId: "", nodeIds: [], ids: [], names: [], parents: [] };
}

export const GetSubInterfacesRequest = {
  encode(message: GetSubInterfacesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.ids) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.parents) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSubInterfacesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSubInterfacesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.parents.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetSubInterfacesRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      parents: globalThis.Array.isArray(object?.parents) ? object.parents.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetSubInterfacesRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.parents?.length) {
      obj.parents = message.parents;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSubInterfacesRequest>, I>>(base?: I): GetSubInterfacesRequest {
    return GetSubInterfacesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSubInterfacesRequest>, I>>(object: I): GetSubInterfacesRequest {
    const message = createBaseGetSubInterfacesRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.parents = object.parents?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetStaticRoutesRequest(): GetStaticRoutesRequest {
  return { candidate: "", fabricId: "", ids: [], names: [], vrfIds: [] };
}

export const GetStaticRoutesRequest = {
  encode(message: GetStaticRoutesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.vrfIds) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetStaticRoutesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetStaticRoutesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vrfIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetStaticRoutesRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      vrfIds: globalThis.Array.isArray(object?.vrfIds) ? object.vrfIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetStaticRoutesRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.vrfIds?.length) {
      obj.vrfIds = message.vrfIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetStaticRoutesRequest>, I>>(base?: I): GetStaticRoutesRequest {
    return GetStaticRoutesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetStaticRoutesRequest>, I>>(object: I): GetStaticRoutesRequest {
    const message = createBaseGetStaticRoutesRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.vrfIds = object.vrfIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetDhcpRelaysRequest(): GetDhcpRelaysRequest {
  return { candidate: "", fabricId: "", ids: [], names: [], vrfIds: [] };
}

export const GetDhcpRelaysRequest = {
  encode(message: GetDhcpRelaysRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.vrfIds) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDhcpRelaysRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDhcpRelaysRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.vrfIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDhcpRelaysRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      vrfIds: globalThis.Array.isArray(object?.vrfIds) ? object.vrfIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetDhcpRelaysRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.vrfIds?.length) {
      obj.vrfIds = message.vrfIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDhcpRelaysRequest>, I>>(base?: I): GetDhcpRelaysRequest {
    return GetDhcpRelaysRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDhcpRelaysRequest>, I>>(object: I): GetDhcpRelaysRequest {
    const message = createBaseGetDhcpRelaysRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.vrfIds = object.vrfIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetLoopbacksRequest(): GetLoopbacksRequest {
  return { candidate: "", fabricId: "", nodeIds: [], ids: [], names: [] };
}

export const GetLoopbacksRequest = {
  encode(message: GetLoopbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.ids) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLoopbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLoopbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLoopbacksRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetLoopbacksRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLoopbacksRequest>, I>>(base?: I): GetLoopbacksRequest {
    return GetLoopbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLoopbacksRequest>, I>>(object: I): GetLoopbacksRequest {
    const message = createBaseGetLoopbacksRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetPerVlanStpRequest(): GetPerVlanStpRequest {
  return { candidate: "", fabricId: "" };
}

export const GetPerVlanStpRequest = {
  encode(message: GetPerVlanStpRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPerVlanStpRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPerVlanStpRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPerVlanStpRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetPerVlanStpRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPerVlanStpRequest>, I>>(base?: I): GetPerVlanStpRequest {
    return GetPerVlanStpRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPerVlanStpRequest>, I>>(object: I): GetPerVlanStpRequest {
    const message = createBaseGetPerVlanStpRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetPerVlanStpResponse(): GetPerVlanStpResponse {
  return { perVlanStp: undefined };
}

export const GetPerVlanStpResponse = {
  encode(message: GetPerVlanStpResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.perVlanStp !== undefined) {
      PerVlanStp.encode(message.perVlanStp, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPerVlanStpResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPerVlanStpResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.perVlanStp = PerVlanStp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPerVlanStpResponse {
    return { perVlanStp: isSet(object.perVlanStp) ? PerVlanStp.fromJSON(object.perVlanStp) : undefined };
  },

  toJSON(message: GetPerVlanStpResponse): unknown {
    const obj: any = {};
    if (message.perVlanStp !== undefined) {
      obj.perVlanStp = PerVlanStp.toJSON(message.perVlanStp);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPerVlanStpResponse>, I>>(base?: I): GetPerVlanStpResponse {
    return GetPerVlanStpResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPerVlanStpResponse>, I>>(object: I): GetPerVlanStpResponse {
    const message = createBaseGetPerVlanStpResponse();
    message.perVlanStp = (object.perVlanStp !== undefined && object.perVlanStp !== null)
      ? PerVlanStp.fromPartial(object.perVlanStp)
      : undefined;
    return message;
  },
};

function createBaseGetBgpPeersRequest(): GetBgpPeersRequest {
  return { candidate: "", fabricId: "", ids: [], names: [], vrfIds: [] };
}

export const GetBgpPeersRequest = {
  encode(message: GetBgpPeersRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.ids) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.vrfIds) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetBgpPeersRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetBgpPeersRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ids.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.vrfIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetBgpPeersRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => globalThis.String(e)) : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      vrfIds: globalThis.Array.isArray(object?.vrfIds) ? object.vrfIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetBgpPeersRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.ids?.length) {
      obj.ids = message.ids;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.vrfIds?.length) {
      obj.vrfIds = message.vrfIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetBgpPeersRequest>, I>>(base?: I): GetBgpPeersRequest {
    return GetBgpPeersRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetBgpPeersRequest>, I>>(object: I): GetBgpPeersRequest {
    const message = createBaseGetBgpPeersRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.ids = object.ids?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.vrfIds = object.vrfIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseDeviceClaim(): DeviceClaim {
  return { serialNumber: "", modelName: "", deviceId: "", claimCode: "" };
}

export const DeviceClaim = {
  encode(message: DeviceClaim, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.modelName !== "") {
      writer.uint32(18).string(message.modelName);
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    if (message.claimCode !== "") {
      writer.uint32(34).string(message.claimCode);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceClaim {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceClaim();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.modelName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.claimCode = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceClaim {
    return {
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      modelName: isSet(object.modelName) ? globalThis.String(object.modelName) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
      claimCode: isSet(object.claimCode) ? globalThis.String(object.claimCode) : "",
    };
  },

  toJSON(message: DeviceClaim): unknown {
    const obj: any = {};
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.modelName !== "") {
      obj.modelName = message.modelName;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    if (message.claimCode !== "") {
      obj.claimCode = message.claimCode;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceClaim>, I>>(base?: I): DeviceClaim {
    return DeviceClaim.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceClaim>, I>>(object: I): DeviceClaim {
    const message = createBaseDeviceClaim();
    message.serialNumber = object.serialNumber ?? "";
    message.modelName = object.modelName ?? "";
    message.deviceId = object.deviceId ?? "";
    message.claimCode = object.claimCode ?? "";
    return message;
  },
};

function createBaseDeviceClaimRequest(): DeviceClaimRequest {
  return { claims: [] };
}

export const DeviceClaimRequest = {
  encode(message: DeviceClaimRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.claims) {
      DeviceClaim.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceClaimRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceClaimRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.claims.push(DeviceClaim.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceClaimRequest {
    return {
      claims: globalThis.Array.isArray(object?.claims) ? object.claims.map((e: any) => DeviceClaim.fromJSON(e)) : [],
    };
  },

  toJSON(message: DeviceClaimRequest): unknown {
    const obj: any = {};
    if (message.claims?.length) {
      obj.claims = message.claims.map((e) => DeviceClaim.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceClaimRequest>, I>>(base?: I): DeviceClaimRequest {
    return DeviceClaimRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceClaimRequest>, I>>(object: I): DeviceClaimRequest {
    const message = createBaseDeviceClaimRequest();
    message.claims = object.claims?.map((e) => DeviceClaim.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeviceClaimResponse(): DeviceClaimResponse {
  return { claims: [] };
}

export const DeviceClaimResponse = {
  encode(message: DeviceClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.claims) {
      DeviceClaim.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeviceClaimResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceClaimResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.claims.push(DeviceClaim.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceClaimResponse {
    return {
      claims: globalThis.Array.isArray(object?.claims) ? object.claims.map((e: any) => DeviceClaim.fromJSON(e)) : [],
    };
  },

  toJSON(message: DeviceClaimResponse): unknown {
    const obj: any = {};
    if (message.claims?.length) {
      obj.claims = message.claims.map((e) => DeviceClaim.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeviceClaimResponse>, I>>(base?: I): DeviceClaimResponse {
    return DeviceClaimResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeviceClaimResponse>, I>>(object: I): DeviceClaimResponse {
    const message = createBaseDeviceClaimResponse();
    message.claims = object.claims?.map((e) => DeviceClaim.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetWorkflowFeedbacksRequest(): GetWorkflowFeedbacksRequest {
  return {
    nodeIds: [],
    details: false,
    startTime: undefined,
    endTime: undefined,
    configFp: "",
    taskNames: [],
    workflowTypes: [],
  };
}

export const GetWorkflowFeedbacksRequest = {
  encode(message: GetWorkflowFeedbacksRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodeIds) {
      writer.uint32(10).string(v!);
    }
    if (message.details !== false) {
      writer.uint32(16).bool(message.details);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(26).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(34).fork()).ldelim();
    }
    if (message.configFp !== "") {
      writer.uint32(42).string(message.configFp);
    }
    for (const v of message.taskNames) {
      writer.uint32(50).string(v!);
    }
    writer.uint32(66).fork();
    for (const v of message.workflowTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWorkflowFeedbacksRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWorkflowFeedbacksRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.details = reader.bool();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.configFp = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.taskNames.push(reader.string());
          continue;
        case 8:
          if (tag === 64) {
            message.workflowTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.workflowTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWorkflowFeedbacksRequest {
    return {
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      details: isSet(object.details) ? globalThis.Boolean(object.details) : false,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      configFp: isSet(object.configFp) ? globalThis.String(object.configFp) : "",
      taskNames: globalThis.Array.isArray(object?.taskNames)
        ? object.taskNames.map((e: any) => globalThis.String(e))
        : [],
      workflowTypes: globalThis.Array.isArray(object?.workflowTypes)
        ? object.workflowTypes.map((e: any) => workflowTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: GetWorkflowFeedbacksRequest): unknown {
    const obj: any = {};
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.details !== false) {
      obj.details = message.details;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.configFp !== "") {
      obj.configFp = message.configFp;
    }
    if (message.taskNames?.length) {
      obj.taskNames = message.taskNames;
    }
    if (message.workflowTypes?.length) {
      obj.workflowTypes = message.workflowTypes.map((e) => workflowTypeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWorkflowFeedbacksRequest>, I>>(base?: I): GetWorkflowFeedbacksRequest {
    return GetWorkflowFeedbacksRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWorkflowFeedbacksRequest>, I>>(object: I): GetWorkflowFeedbacksRequest {
    const message = createBaseGetWorkflowFeedbacksRequest();
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.details = object.details ?? false;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.configFp = object.configFp ?? "";
    message.taskNames = object.taskNames?.map((e) => e) || [];
    message.workflowTypes = object.workflowTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetWorkflowFeedbacksResponse(): GetWorkflowFeedbacksResponse {
  return { nodeIds: [], feedbacks: [], names: [] };
}

export const GetWorkflowFeedbacksResponse = {
  encode(message: GetWorkflowFeedbacksResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodeIds) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.feedbacks) {
      WorkflowFeedback.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.names) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWorkflowFeedbacksResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWorkflowFeedbacksResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.feedbacks.push(WorkflowFeedback.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.names.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetWorkflowFeedbacksResponse {
    return {
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
      feedbacks: globalThis.Array.isArray(object?.feedbacks)
        ? object.feedbacks.map((e: any) => WorkflowFeedback.fromJSON(e))
        : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetWorkflowFeedbacksResponse): unknown {
    const obj: any = {};
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    if (message.feedbacks?.length) {
      obj.feedbacks = message.feedbacks.map((e) => WorkflowFeedback.toJSON(e));
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetWorkflowFeedbacksResponse>, I>>(base?: I): GetWorkflowFeedbacksResponse {
    return GetWorkflowFeedbacksResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWorkflowFeedbacksResponse>, I>>(object: I): GetWorkflowFeedbacksResponse {
    const message = createBaseGetWorkflowFeedbacksResponse();
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    message.feedbacks = object.feedbacks?.map((e) => WorkflowFeedback.fromPartial(e)) || [];
    message.names = object.names?.map((e) => e) || [];
    return message;
  },
};

function createBaseCandidateReview(): CandidateReview {
  return { comments: "", createdBy: "", createdAt: undefined, operation: 0 };
}

export const CandidateReview = {
  encode(message: CandidateReview, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.comments !== "") {
      writer.uint32(10).string(message.comments);
    }
    if (message.createdBy !== "") {
      writer.uint32(18).string(message.createdBy);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(26).fork()).ldelim();
    }
    if (message.operation !== 0) {
      writer.uint32(32).int32(message.operation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CandidateReview {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCandidateReview();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.comments = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.operation = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CandidateReview {
    return {
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      operation: isSet(object.operation) ? operationFromJSON(object.operation) : 0,
    };
  },

  toJSON(message: CandidateReview): unknown {
    const obj: any = {};
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.operation !== 0) {
      obj.operation = operationToJSON(message.operation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CandidateReview>, I>>(base?: I): CandidateReview {
    return CandidateReview.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CandidateReview>, I>>(object: I): CandidateReview {
    const message = createBaseCandidateReview();
    message.comments = object.comments ?? "";
    message.createdBy = object.createdBy ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.operation = object.operation ?? 0;
    return message;
  },
};

function createBaseFabricCandidate(): FabricCandidate {
  return {
    fabricId: "",
    name: "",
    active: false,
    txnId: 0,
    createdBy: "",
    createdAt: undefined,
    reviews: [],
    events: [],
  };
}

export const FabricCandidate = {
  encode(message: FabricCandidate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.active !== false) {
      writer.uint32(24).bool(message.active);
    }
    if (message.txnId !== 0) {
      writer.uint32(32).int64(message.txnId);
    }
    if (message.createdBy !== "") {
      writer.uint32(42).string(message.createdBy);
    }
    if (message.createdAt !== undefined) {
      Timestamp.encode(toTimestamp(message.createdAt), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.reviews) {
      CandidateReview.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.events) {
      ActivityEvent.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricCandidate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricCandidate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.active = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.txnId = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.createdBy = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.createdAt = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.reviews.push(CandidateReview.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.events.push(ActivityEvent.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricCandidate {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      active: isSet(object.active) ? globalThis.Boolean(object.active) : false,
      txnId: isSet(object.txnId) ? globalThis.Number(object.txnId) : 0,
      createdBy: isSet(object.createdBy) ? globalThis.String(object.createdBy) : "",
      createdAt: isSet(object.createdAt) ? fromJsonTimestamp(object.createdAt) : undefined,
      reviews: globalThis.Array.isArray(object?.reviews)
        ? object.reviews.map((e: any) => CandidateReview.fromJSON(e))
        : [],
      events: globalThis.Array.isArray(object?.events) ? object.events.map((e: any) => ActivityEvent.fromJSON(e)) : [],
    };
  },

  toJSON(message: FabricCandidate): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.active !== false) {
      obj.active = message.active;
    }
    if (message.txnId !== 0) {
      obj.txnId = Math.round(message.txnId);
    }
    if (message.createdBy !== "") {
      obj.createdBy = message.createdBy;
    }
    if (message.createdAt !== undefined) {
      obj.createdAt = message.createdAt.toISOString();
    }
    if (message.reviews?.length) {
      obj.reviews = message.reviews.map((e) => CandidateReview.toJSON(e));
    }
    if (message.events?.length) {
      obj.events = message.events.map((e) => ActivityEvent.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricCandidate>, I>>(base?: I): FabricCandidate {
    return FabricCandidate.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricCandidate>, I>>(object: I): FabricCandidate {
    const message = createBaseFabricCandidate();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.active = object.active ?? false;
    message.txnId = object.txnId ?? 0;
    message.createdBy = object.createdBy ?? "";
    message.createdAt = object.createdAt ?? undefined;
    message.reviews = object.reviews?.map((e) => CandidateReview.fromPartial(e)) || [];
    message.events = object.events?.map((e) => ActivityEvent.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetFabricCandidatesRequest(): GetFabricCandidatesRequest {
  return {
    fabricId: "",
    name: "",
    txnId: 0,
    needInactive: false,
    needReviews: false,
    needEvents: false,
    startTime: undefined,
    endTime: undefined,
    pagination: undefined,
  };
}

export const GetFabricCandidatesRequest = {
  encode(message: GetFabricCandidatesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.txnId !== 0) {
      writer.uint32(24).int64(message.txnId);
    }
    if (message.needInactive !== false) {
      writer.uint32(32).bool(message.needInactive);
    }
    if (message.needReviews !== false) {
      writer.uint32(40).bool(message.needReviews);
    }
    if (message.needEvents !== false) {
      writer.uint32(48).bool(message.needEvents);
    }
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(58).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(66).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricCandidatesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricCandidatesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.txnId = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.needInactive = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needReviews = reader.bool();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.needEvents = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricCandidatesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      txnId: isSet(object.txnId) ? globalThis.Number(object.txnId) : 0,
      needInactive: isSet(object.needInactive) ? globalThis.Boolean(object.needInactive) : false,
      needReviews: isSet(object.needReviews) ? globalThis.Boolean(object.needReviews) : false,
      needEvents: isSet(object.needEvents) ? globalThis.Boolean(object.needEvents) : false,
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetFabricCandidatesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.txnId !== 0) {
      obj.txnId = Math.round(message.txnId);
    }
    if (message.needInactive !== false) {
      obj.needInactive = message.needInactive;
    }
    if (message.needReviews !== false) {
      obj.needReviews = message.needReviews;
    }
    if (message.needEvents !== false) {
      obj.needEvents = message.needEvents;
    }
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricCandidatesRequest>, I>>(base?: I): GetFabricCandidatesRequest {
    return GetFabricCandidatesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricCandidatesRequest>, I>>(object: I): GetFabricCandidatesRequest {
    const message = createBaseGetFabricCandidatesRequest();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.txnId = object.txnId ?? 0;
    message.needInactive = object.needInactive ?? false;
    message.needReviews = object.needReviews ?? false;
    message.needEvents = object.needEvents ?? false;
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetFabricCandidateRequest(): GetFabricCandidateRequest {
  return { fabricId: "", name: "", needInactive: false, needReviews: false, needEvents: false };
}

export const GetFabricCandidateRequest = {
  encode(message: GetFabricCandidateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.needInactive !== false) {
      writer.uint32(24).bool(message.needInactive);
    }
    if (message.needReviews !== false) {
      writer.uint32(32).bool(message.needReviews);
    }
    if (message.needEvents !== false) {
      writer.uint32(40).bool(message.needEvents);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricCandidateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricCandidateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.needInactive = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.needReviews = reader.bool();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.needEvents = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricCandidateRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      needInactive: isSet(object.needInactive) ? globalThis.Boolean(object.needInactive) : false,
      needReviews: isSet(object.needReviews) ? globalThis.Boolean(object.needReviews) : false,
      needEvents: isSet(object.needEvents) ? globalThis.Boolean(object.needEvents) : false,
    };
  },

  toJSON(message: GetFabricCandidateRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.needInactive !== false) {
      obj.needInactive = message.needInactive;
    }
    if (message.needReviews !== false) {
      obj.needReviews = message.needReviews;
    }
    if (message.needEvents !== false) {
      obj.needEvents = message.needEvents;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricCandidateRequest>, I>>(base?: I): GetFabricCandidateRequest {
    return GetFabricCandidateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricCandidateRequest>, I>>(object: I): GetFabricCandidateRequest {
    const message = createBaseGetFabricCandidateRequest();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.needInactive = object.needInactive ?? false;
    message.needReviews = object.needReviews ?? false;
    message.needEvents = object.needEvents ?? false;
    return message;
  },
};

function createBaseGetFabricCandidatesResponse(): GetFabricCandidatesResponse {
  return { candidates: [], pagination: undefined };
}

export const GetFabricCandidatesResponse = {
  encode(message: GetFabricCandidatesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.candidates) {
      FabricCandidate.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricCandidatesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricCandidatesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidates.push(FabricCandidate.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricCandidatesResponse {
    return {
      candidates: globalThis.Array.isArray(object?.candidates)
        ? object.candidates.map((e: any) => FabricCandidate.fromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetFabricCandidatesResponse): unknown {
    const obj: any = {};
    if (message.candidates?.length) {
      obj.candidates = message.candidates.map((e) => FabricCandidate.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricCandidatesResponse>, I>>(base?: I): GetFabricCandidatesResponse {
    return GetFabricCandidatesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricCandidatesResponse>, I>>(object: I): GetFabricCandidatesResponse {
    const message = createBaseGetFabricCandidatesResponse();
    message.candidates = object.candidates?.map((e) => FabricCandidate.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseCommitFabricCandidateRequest(): CommitFabricCandidateRequest {
  return { fabricId: "", name: "", comments: "" };
}

export const CommitFabricCandidateRequest = {
  encode(message: CommitFabricCandidateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommitFabricCandidateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommitFabricCandidateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommitFabricCandidateRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
    };
  },

  toJSON(message: CommitFabricCandidateRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommitFabricCandidateRequest>, I>>(base?: I): CommitFabricCandidateRequest {
    return CommitFabricCandidateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommitFabricCandidateRequest>, I>>(object: I): CommitFabricCandidateRequest {
    const message = createBaseCommitFabricCandidateRequest();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    return message;
  },
};

function createBaseCommitFabricCandidateResponse(): CommitFabricCandidateResponse {
  return { fabricId: "", committed: false };
}

export const CommitFabricCandidateResponse = {
  encode(message: CommitFabricCandidateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.committed !== false) {
      writer.uint32(16).bool(message.committed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CommitFabricCandidateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommitFabricCandidateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.committed = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommitFabricCandidateResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      committed: isSet(object.committed) ? globalThis.Boolean(object.committed) : false,
    };
  },

  toJSON(message: CommitFabricCandidateResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.committed !== false) {
      obj.committed = message.committed;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CommitFabricCandidateResponse>, I>>(base?: I): CommitFabricCandidateResponse {
    return CommitFabricCandidateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CommitFabricCandidateResponse>, I>>(
    object: I,
  ): CommitFabricCandidateResponse {
    const message = createBaseCommitFabricCandidateResponse();
    message.fabricId = object.fabricId ?? "";
    message.committed = object.committed ?? false;
    return message;
  },
};

function createBaseRevertFabricCandidateRequest(): RevertFabricCandidateRequest {
  return { fabricId: "", name: "", comments: "" };
}

export const RevertFabricCandidateRequest = {
  encode(message: RevertFabricCandidateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevertFabricCandidateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevertFabricCandidateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RevertFabricCandidateRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
    };
  },

  toJSON(message: RevertFabricCandidateRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RevertFabricCandidateRequest>, I>>(base?: I): RevertFabricCandidateRequest {
    return RevertFabricCandidateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevertFabricCandidateRequest>, I>>(object: I): RevertFabricCandidateRequest {
    const message = createBaseRevertFabricCandidateRequest();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    return message;
  },
};

function createBaseRevertFabricCandidateResponse(): RevertFabricCandidateResponse {
  return { fabricId: "", reverted: false };
}

export const RevertFabricCandidateResponse = {
  encode(message: RevertFabricCandidateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.reverted !== false) {
      writer.uint32(16).bool(message.reverted);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RevertFabricCandidateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRevertFabricCandidateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.reverted = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RevertFabricCandidateResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      reverted: isSet(object.reverted) ? globalThis.Boolean(object.reverted) : false,
    };
  },

  toJSON(message: RevertFabricCandidateResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.reverted !== false) {
      obj.reverted = message.reverted;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<RevertFabricCandidateResponse>, I>>(base?: I): RevertFabricCandidateResponse {
    return RevertFabricCandidateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<RevertFabricCandidateResponse>, I>>(
    object: I,
  ): RevertFabricCandidateResponse {
    const message = createBaseRevertFabricCandidateResponse();
    message.fabricId = object.fabricId ?? "";
    message.reverted = object.reverted ?? false;
    return message;
  },
};

function createBaseFabricConfig(): FabricConfig {
  return { fabric: undefined, tenants: [], perVlanStp: undefined };
}

export const FabricConfig = {
  encode(message: FabricConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabric !== undefined) {
      Fabric.encode(message.fabric, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.tenants) {
      TenantObjects.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    if (message.perVlanStp !== undefined) {
      PerVlanStp.encode(message.perVlanStp, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FabricConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFabricConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabric = Fabric.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tenants.push(TenantObjects.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.perVlanStp = PerVlanStp.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FabricConfig {
    return {
      fabric: isSet(object.fabric) ? Fabric.fromJSON(object.fabric) : undefined,
      tenants: globalThis.Array.isArray(object?.tenants)
        ? object.tenants.map((e: any) => TenantObjects.fromJSON(e))
        : [],
      perVlanStp: isSet(object.perVlanStp) ? PerVlanStp.fromJSON(object.perVlanStp) : undefined,
    };
  },

  toJSON(message: FabricConfig): unknown {
    const obj: any = {};
    if (message.fabric !== undefined) {
      obj.fabric = Fabric.toJSON(message.fabric);
    }
    if (message.tenants?.length) {
      obj.tenants = message.tenants.map((e) => TenantObjects.toJSON(e));
    }
    if (message.perVlanStp !== undefined) {
      obj.perVlanStp = PerVlanStp.toJSON(message.perVlanStp);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FabricConfig>, I>>(base?: I): FabricConfig {
    return FabricConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FabricConfig>, I>>(object: I): FabricConfig {
    const message = createBaseFabricConfig();
    message.fabric = (object.fabric !== undefined && object.fabric !== null)
      ? Fabric.fromPartial(object.fabric)
      : undefined;
    message.tenants = object.tenants?.map((e) => TenantObjects.fromPartial(e)) || [];
    message.perVlanStp = (object.perVlanStp !== undefined && object.perVlanStp !== null)
      ? PerVlanStp.fromPartial(object.perVlanStp)
      : undefined;
    return message;
  },
};

function createBaseGetFabricConfigRequest(): GetFabricConfigRequest {
  return { candidate: "", fabricId: "" };
}

export const GetFabricConfigRequest = {
  encode(message: GetFabricConfigRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricConfigRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricConfigRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricConfigRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
    };
  },

  toJSON(message: GetFabricConfigRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricConfigRequest>, I>>(base?: I): GetFabricConfigRequest {
    return GetFabricConfigRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricConfigRequest>, I>>(object: I): GetFabricConfigRequest {
    const message = createBaseGetFabricConfigRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetFabricConfigResponse(): GetFabricConfigResponse {
  return { candidate: "", config: undefined };
}

export const GetFabricConfigResponse = {
  encode(message: GetFabricConfigResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.config !== undefined) {
      FabricConfig.encode(message.config, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricConfigResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricConfigResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.config = FabricConfig.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricConfigResponse {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      config: isSet(object.config) ? FabricConfig.fromJSON(object.config) : undefined,
    };
  },

  toJSON(message: GetFabricConfigResponse): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.config !== undefined) {
      obj.config = FabricConfig.toJSON(message.config);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricConfigResponse>, I>>(base?: I): GetFabricConfigResponse {
    return GetFabricConfigResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricConfigResponse>, I>>(object: I): GetFabricConfigResponse {
    const message = createBaseGetFabricConfigResponse();
    message.candidate = object.candidate ?? "";
    message.config = (object.config !== undefined && object.config !== null)
      ? FabricConfig.fromPartial(object.config)
      : undefined;
    return message;
  },
};

function createBaseReviewFabricCandidateRequest(): ReviewFabricCandidateRequest {
  return { fabricId: "", name: "", comments: "" };
}

export const ReviewFabricCandidateRequest = {
  encode(message: ReviewFabricCandidateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.comments !== "") {
      writer.uint32(26).string(message.comments);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewFabricCandidateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewFabricCandidateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.comments = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewFabricCandidateRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      comments: isSet(object.comments) ? globalThis.String(object.comments) : "",
    };
  },

  toJSON(message: ReviewFabricCandidateRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.comments !== "") {
      obj.comments = message.comments;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewFabricCandidateRequest>, I>>(base?: I): ReviewFabricCandidateRequest {
    return ReviewFabricCandidateRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReviewFabricCandidateRequest>, I>>(object: I): ReviewFabricCandidateRequest {
    const message = createBaseReviewFabricCandidateRequest();
    message.fabricId = object.fabricId ?? "";
    message.name = object.name ?? "";
    message.comments = object.comments ?? "";
    return message;
  },
};

function createBaseReviewFabricCandidateResponse(): ReviewFabricCandidateResponse {
  return { fabricId: "" };
}

export const ReviewFabricCandidateResponse = {
  encode(message: ReviewFabricCandidateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReviewFabricCandidateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReviewFabricCandidateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReviewFabricCandidateResponse {
    return { fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "" };
  },

  toJSON(message: ReviewFabricCandidateResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReviewFabricCandidateResponse>, I>>(base?: I): ReviewFabricCandidateResponse {
    return ReviewFabricCandidateResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReviewFabricCandidateResponse>, I>>(
    object: I,
  ): ReviewFabricCandidateResponse {
    const message = createBaseReviewFabricCandidateResponse();
    message.fabricId = object.fabricId ?? "";
    return message;
  },
};

function createBaseGetFabricInventoriesRequest(): GetFabricInventoriesRequest {
  return { fabricId: "", topology: 0, nodeIds: [] };
}

export const GetFabricInventoriesRequest = {
  encode(message: GetFabricInventoriesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.topology !== 0) {
      writer.uint32(16).int32(message.topology);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricInventoriesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricInventoriesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.topology = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricInventoriesRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      topology: isSet(object.topology) ? fabricTopologyFromJSON(object.topology) : 0,
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetFabricInventoriesRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.topology !== 0) {
      obj.topology = fabricTopologyToJSON(message.topology);
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricInventoriesRequest>, I>>(base?: I): GetFabricInventoriesRequest {
    return GetFabricInventoriesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricInventoriesRequest>, I>>(object: I): GetFabricInventoriesRequest {
    const message = createBaseGetFabricInventoriesRequest();
    message.fabricId = object.fabricId ?? "";
    message.topology = object.topology ?? 0;
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetFabricInventoriesResponse(): GetFabricInventoriesResponse {
  return { fabrics: [] };
}

export const GetFabricInventoriesResponse = {
  encode(message: GetFabricInventoriesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.fabrics) {
      Fabric.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFabricInventoriesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetFabricInventoriesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabrics.push(Fabric.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetFabricInventoriesResponse {
    return {
      fabrics: globalThis.Array.isArray(object?.fabrics) ? object.fabrics.map((e: any) => Fabric.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetFabricInventoriesResponse): unknown {
    const obj: any = {};
    if (message.fabrics?.length) {
      obj.fabrics = message.fabrics.map((e) => Fabric.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetFabricInventoriesResponse>, I>>(base?: I): GetFabricInventoriesResponse {
    return GetFabricInventoriesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetFabricInventoriesResponse>, I>>(object: I): GetFabricInventoriesResponse {
    const message = createBaseGetFabricInventoriesResponse();
    message.fabrics = object.fabrics?.map((e) => Fabric.fromPartial(e)) || [];
    return message;
  },
};

function createBaseActivityEvent(): ActivityEvent {
  return {
    eventId: 0,
    timestamp: undefined,
    username: "",
    objectType: 0,
    objectId: "",
    objectName: "",
    parentType: 0,
    parentId: "",
    operation: 0,
  };
}

export const ActivityEvent = {
  encode(message: ActivityEvent, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventId !== 0) {
      writer.uint32(8).int64(message.eventId);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(26).fork()).ldelim();
    }
    if (message.username !== "") {
      writer.uint32(34).string(message.username);
    }
    if (message.objectType !== 0) {
      writer.uint32(40).int32(message.objectType);
    }
    if (message.objectId !== "") {
      writer.uint32(50).string(message.objectId);
    }
    if (message.objectName !== "") {
      writer.uint32(58).string(message.objectName);
    }
    if (message.parentType !== 0) {
      writer.uint32(64).int32(message.parentType);
    }
    if (message.parentId !== "") {
      writer.uint32(74).string(message.parentId);
    }
    if (message.operation !== 0) {
      writer.uint32(80).int32(message.operation);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ActivityEvent {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivityEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.eventId = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.username = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.objectType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.objectId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.objectName = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.parentType = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.parentId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.operation = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ActivityEvent {
    return {
      eventId: isSet(object.eventId) ? globalThis.Number(object.eventId) : 0,
      timestamp: isSet(object.timestamp) ? fromJsonTimestamp(object.timestamp) : undefined,
      username: isSet(object.username) ? globalThis.String(object.username) : "",
      objectType: isSet(object.objectType) ? objectTypeFromJSON(object.objectType) : 0,
      objectId: isSet(object.objectId) ? globalThis.String(object.objectId) : "",
      objectName: isSet(object.objectName) ? globalThis.String(object.objectName) : "",
      parentType: isSet(object.parentType) ? objectTypeFromJSON(object.parentType) : 0,
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      operation: isSet(object.operation) ? operationFromJSON(object.operation) : 0,
    };
  },

  toJSON(message: ActivityEvent): unknown {
    const obj: any = {};
    if (message.eventId !== 0) {
      obj.eventId = Math.round(message.eventId);
    }
    if (message.timestamp !== undefined) {
      obj.timestamp = message.timestamp.toISOString();
    }
    if (message.username !== "") {
      obj.username = message.username;
    }
    if (message.objectType !== 0) {
      obj.objectType = objectTypeToJSON(message.objectType);
    }
    if (message.objectId !== "") {
      obj.objectId = message.objectId;
    }
    if (message.objectName !== "") {
      obj.objectName = message.objectName;
    }
    if (message.parentType !== 0) {
      obj.parentType = objectTypeToJSON(message.parentType);
    }
    if (message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.operation !== 0) {
      obj.operation = operationToJSON(message.operation);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ActivityEvent>, I>>(base?: I): ActivityEvent {
    return ActivityEvent.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ActivityEvent>, I>>(object: I): ActivityEvent {
    const message = createBaseActivityEvent();
    message.eventId = object.eventId ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    message.username = object.username ?? "";
    message.objectType = object.objectType ?? 0;
    message.objectId = object.objectId ?? "";
    message.objectName = object.objectName ?? "";
    message.parentType = object.parentType ?? 0;
    message.parentId = object.parentId ?? "";
    message.operation = object.operation ?? 0;
    return message;
  },
};

function createBaseGetActivityEventsRequest(): GetActivityEventsRequest {
  return {
    startTime: undefined,
    endTime: undefined,
    objectTypes: [],
    objectIds: [],
    usernames: [],
    objectNames: [],
    operations: [],
    pagination: undefined,
  };
}

export const GetActivityEventsRequest = {
  encode(message: GetActivityEventsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(18).fork()).ldelim();
    }
    writer.uint32(26).fork();
    for (const v of message.objectTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.objectIds) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.usernames) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.objectNames) {
      writer.uint32(50).string(v!);
    }
    writer.uint32(58).fork();
    for (const v of message.operations) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetActivityEventsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActivityEventsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag === 24) {
            message.objectTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.objectTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.objectIds.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.usernames.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.objectNames.push(reader.string());
          continue;
        case 7:
          if (tag === 56) {
            message.operations.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.operations.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActivityEventsRequest {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      objectTypes: globalThis.Array.isArray(object?.objectTypes)
        ? object.objectTypes.map((e: any) => objectTypeFromJSON(e))
        : [],
      objectIds: globalThis.Array.isArray(object?.objectIds)
        ? object.objectIds.map((e: any) => globalThis.String(e))
        : [],
      usernames: globalThis.Array.isArray(object?.usernames)
        ? object.usernames.map((e: any) => globalThis.String(e))
        : [],
      objectNames: globalThis.Array.isArray(object?.objectNames)
        ? object.objectNames.map((e: any) => globalThis.String(e))
        : [],
      operations: globalThis.Array.isArray(object?.operations)
        ? object.operations.map((e: any) => operationFromJSON(e))
        : [],
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetActivityEventsRequest): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.objectTypes?.length) {
      obj.objectTypes = message.objectTypes.map((e) => objectTypeToJSON(e));
    }
    if (message.objectIds?.length) {
      obj.objectIds = message.objectIds;
    }
    if (message.usernames?.length) {
      obj.usernames = message.usernames;
    }
    if (message.objectNames?.length) {
      obj.objectNames = message.objectNames;
    }
    if (message.operations?.length) {
      obj.operations = message.operations.map((e) => operationToJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetActivityEventsRequest>, I>>(base?: I): GetActivityEventsRequest {
    return GetActivityEventsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetActivityEventsRequest>, I>>(object: I): GetActivityEventsRequest {
    const message = createBaseGetActivityEventsRequest();
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.objectTypes = object.objectTypes?.map((e) => e) || [];
    message.objectIds = object.objectIds?.map((e) => e) || [];
    message.usernames = object.usernames?.map((e) => e) || [];
    message.objectNames = object.objectNames?.map((e) => e) || [];
    message.operations = object.operations?.map((e) => e) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetActivityEventsResponse(): GetActivityEventsResponse {
  return { events: [], pagination: undefined };
}

export const GetActivityEventsResponse = {
  encode(message: GetActivityEventsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.events) {
      ActivityEvent.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetActivityEventsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetActivityEventsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.events.push(ActivityEvent.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetActivityEventsResponse {
    return {
      events: globalThis.Array.isArray(object?.events) ? object.events.map((e: any) => ActivityEvent.fromJSON(e)) : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetActivityEventsResponse): unknown {
    const obj: any = {};
    if (message.events?.length) {
      obj.events = message.events.map((e) => ActivityEvent.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetActivityEventsResponse>, I>>(base?: I): GetActivityEventsResponse {
    return GetActivityEventsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetActivityEventsResponse>, I>>(object: I): GetActivityEventsResponse {
    const message = createBaseGetActivityEventsResponse();
    message.events = object.events?.map((e) => ActivityEvent.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetLabelsRequest(): GetLabelsRequest {
  return {
    startTime: undefined,
    endTime: undefined,
    labels: [],
    objectTypes: [],
    objectIds: [],
    tagsOnly: false,
    pagination: undefined,
  };
}

export const GetLabelsRequest = {
  encode(message: GetLabelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startTime !== undefined) {
      Timestamp.encode(toTimestamp(message.startTime), writer.uint32(10).fork()).ldelim();
    }
    if (message.endTime !== undefined) {
      Timestamp.encode(toTimestamp(message.endTime), writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.labels) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.objectTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.objectIds) {
      writer.uint32(42).string(v!);
    }
    if (message.tagsOnly !== false) {
      writer.uint32(48).bool(message.tagsOnly);
    }
    if (message.pagination !== undefined) {
      PaginationRequest.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLabelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLabelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.objectTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.objectTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.objectIds.push(reader.string());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.tagsOnly = reader.bool();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationRequest.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLabelsRequest {
    return {
      startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
      endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      objectTypes: globalThis.Array.isArray(object?.objectTypes)
        ? object.objectTypes.map((e: any) => objectTypeFromJSON(e))
        : [],
      objectIds: globalThis.Array.isArray(object?.objectIds)
        ? object.objectIds.map((e: any) => globalThis.String(e))
        : [],
      tagsOnly: isSet(object.tagsOnly) ? globalThis.Boolean(object.tagsOnly) : false,
      pagination: isSet(object.pagination) ? PaginationRequest.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetLabelsRequest): unknown {
    const obj: any = {};
    if (message.startTime !== undefined) {
      obj.startTime = message.startTime.toISOString();
    }
    if (message.endTime !== undefined) {
      obj.endTime = message.endTime.toISOString();
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.objectTypes?.length) {
      obj.objectTypes = message.objectTypes.map((e) => objectTypeToJSON(e));
    }
    if (message.objectIds?.length) {
      obj.objectIds = message.objectIds;
    }
    if (message.tagsOnly !== false) {
      obj.tagsOnly = message.tagsOnly;
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationRequest.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLabelsRequest>, I>>(base?: I): GetLabelsRequest {
    return GetLabelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLabelsRequest>, I>>(object: I): GetLabelsRequest {
    const message = createBaseGetLabelsRequest();
    message.startTime = object.startTime ?? undefined;
    message.endTime = object.endTime ?? undefined;
    message.labels = object.labels?.map((e) => e) || [];
    message.objectTypes = object.objectTypes?.map((e) => e) || [];
    message.objectIds = object.objectIds?.map((e) => e) || [];
    message.tagsOnly = object.tagsOnly ?? false;
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationRequest.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetLabelsResponse(): GetLabelsResponse {
  return { labels: [], pagination: undefined };
}

export const GetLabelsResponse = {
  encode(message: GetLabelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.labels) {
      Label.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pagination !== undefined) {
      PaginationResponse.encode(message.pagination, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLabelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLabelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.labels.push(Label.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.pagination = PaginationResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLabelsResponse {
    return {
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => Label.fromJSON(e)) : [],
      pagination: isSet(object.pagination) ? PaginationResponse.fromJSON(object.pagination) : undefined,
    };
  },

  toJSON(message: GetLabelsResponse): unknown {
    const obj: any = {};
    if (message.labels?.length) {
      obj.labels = message.labels.map((e) => Label.toJSON(e));
    }
    if (message.pagination !== undefined) {
      obj.pagination = PaginationResponse.toJSON(message.pagination);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLabelsResponse>, I>>(base?: I): GetLabelsResponse {
    return GetLabelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLabelsResponse>, I>>(object: I): GetLabelsResponse {
    const message = createBaseGetLabelsResponse();
    message.labels = object.labels?.map((e) => Label.fromPartial(e)) || [];
    message.pagination = (object.pagination !== undefined && object.pagination !== null)
      ? PaginationResponse.fromPartial(object.pagination)
      : undefined;
    return message;
  },
};

function createBaseGetShowTechConfigsRequest(): GetShowTechConfigsRequest {
  return { candidate: "", fabricId: "", nodeIds: [] };
}

export const GetShowTechConfigsRequest = {
  encode(message: GetShowTechConfigsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.candidate !== "") {
      writer.uint32(10).string(message.candidate);
    }
    if (message.fabricId !== "") {
      writer.uint32(18).string(message.fabricId);
    }
    for (const v of message.nodeIds) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetShowTechConfigsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetShowTechConfigsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.candidate = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.nodeIds.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetShowTechConfigsRequest {
    return {
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeIds: globalThis.Array.isArray(object?.nodeIds) ? object.nodeIds.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: GetShowTechConfigsRequest): unknown {
    const obj: any = {};
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeIds?.length) {
      obj.nodeIds = message.nodeIds;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetShowTechConfigsRequest>, I>>(base?: I): GetShowTechConfigsRequest {
    return GetShowTechConfigsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetShowTechConfigsRequest>, I>>(object: I): GetShowTechConfigsRequest {
    const message = createBaseGetShowTechConfigsRequest();
    message.candidate = object.candidate ?? "";
    message.fabricId = object.fabricId ?? "";
    message.nodeIds = object.nodeIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetShowTechConfigsResponse(): GetShowTechConfigsResponse {
  return { showTechs: [] };
}

export const GetShowTechConfigsResponse = {
  encode(message: GetShowTechConfigsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.showTechs) {
      NodeShowTech.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetShowTechConfigsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetShowTechConfigsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.showTechs.push(NodeShowTech.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetShowTechConfigsResponse {
    return {
      showTechs: globalThis.Array.isArray(object?.showTechs)
        ? object.showTechs.map((e: any) => NodeShowTech.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetShowTechConfigsResponse): unknown {
    const obj: any = {};
    if (message.showTechs?.length) {
      obj.showTechs = message.showTechs.map((e) => NodeShowTech.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetShowTechConfigsResponse>, I>>(base?: I): GetShowTechConfigsResponse {
    return GetShowTechConfigsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetShowTechConfigsResponse>, I>>(object: I): GetShowTechConfigsResponse {
    const message = createBaseGetShowTechConfigsResponse();
    message.showTechs = object.showTechs?.map((e) => NodeShowTech.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetDeviceModelsRequest(): GetDeviceModelsRequest {
  return { modelNames: [], names: [], roles: [] };
}

export const GetDeviceModelsRequest = {
  encode(message: GetDeviceModelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modelNames) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.names) {
      writer.uint32(18).string(v!);
    }
    writer.uint32(26).fork();
    for (const v of message.roles) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceModelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceModelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelNames.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.names.push(reader.string());
          continue;
        case 3:
          if (tag === 24) {
            message.roles.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.roles.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceModelsRequest {
    return {
      modelNames: globalThis.Array.isArray(object?.modelNames)
        ? object.modelNames.map((e: any) => globalThis.String(e))
        : [],
      names: globalThis.Array.isArray(object?.names) ? object.names.map((e: any) => globalThis.String(e)) : [],
      roles: globalThis.Array.isArray(object?.roles) ? object.roles.map((e: any) => nodeRoleFromJSON(e)) : [],
    };
  },

  toJSON(message: GetDeviceModelsRequest): unknown {
    const obj: any = {};
    if (message.modelNames?.length) {
      obj.modelNames = message.modelNames;
    }
    if (message.names?.length) {
      obj.names = message.names;
    }
    if (message.roles?.length) {
      obj.roles = message.roles.map((e) => nodeRoleToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceModelsRequest>, I>>(base?: I): GetDeviceModelsRequest {
    return GetDeviceModelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceModelsRequest>, I>>(object: I): GetDeviceModelsRequest {
    const message = createBaseGetDeviceModelsRequest();
    message.modelNames = object.modelNames?.map((e) => e) || [];
    message.names = object.names?.map((e) => e) || [];
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetDeviceModelsResponse(): GetDeviceModelsResponse {
  return { devices: [] };
}

export const GetDeviceModelsResponse = {
  encode(message: GetDeviceModelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.devices) {
      DeviceModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDeviceModelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDeviceModelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.devices.push(DeviceModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetDeviceModelsResponse {
    return {
      devices: globalThis.Array.isArray(object?.devices) ? object.devices.map((e: any) => DeviceModel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetDeviceModelsResponse): unknown {
    const obj: any = {};
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => DeviceModel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetDeviceModelsResponse>, I>>(base?: I): GetDeviceModelsResponse {
    return GetDeviceModelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetDeviceModelsResponse>, I>>(object: I): GetDeviceModelsResponse {
    const message = createBaseGetDeviceModelsResponse();
    message.devices = object.devices?.map((e) => DeviceModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCableModelsRequest(): GetCableModelsRequest {
  return { modelNames: [], speeds: [], connectors: [], cableTypes: [] };
}

export const GetCableModelsRequest = {
  encode(message: GetCableModelsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.modelNames) {
      writer.uint32(10).string(v!);
    }
    for (const v of message.speeds) {
      writer.uint32(18).string(v!);
    }
    for (const v of message.connectors) {
      writer.uint32(26).string(v!);
    }
    writer.uint32(34).fork();
    for (const v of message.cableTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCableModelsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCableModelsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.modelNames.push(reader.string());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.speeds.push(reader.string());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.connectors.push(reader.string());
          continue;
        case 4:
          if (tag === 32) {
            message.cableTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.cableTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCableModelsRequest {
    return {
      modelNames: globalThis.Array.isArray(object?.modelNames)
        ? object.modelNames.map((e: any) => globalThis.String(e))
        : [],
      speeds: globalThis.Array.isArray(object?.speeds) ? object.speeds.map((e: any) => globalThis.String(e)) : [],
      connectors: globalThis.Array.isArray(object?.connectors)
        ? object.connectors.map((e: any) => globalThis.String(e))
        : [],
      cableTypes: globalThis.Array.isArray(object?.cableTypes)
        ? object.cableTypes.map((e: any) => cableTypeFromJSON(e))
        : [],
    };
  },

  toJSON(message: GetCableModelsRequest): unknown {
    const obj: any = {};
    if (message.modelNames?.length) {
      obj.modelNames = message.modelNames;
    }
    if (message.speeds?.length) {
      obj.speeds = message.speeds;
    }
    if (message.connectors?.length) {
      obj.connectors = message.connectors;
    }
    if (message.cableTypes?.length) {
      obj.cableTypes = message.cableTypes.map((e) => cableTypeToJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCableModelsRequest>, I>>(base?: I): GetCableModelsRequest {
    return GetCableModelsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCableModelsRequest>, I>>(object: I): GetCableModelsRequest {
    const message = createBaseGetCableModelsRequest();
    message.modelNames = object.modelNames?.map((e) => e) || [];
    message.speeds = object.speeds?.map((e) => e) || [];
    message.connectors = object.connectors?.map((e) => e) || [];
    message.cableTypes = object.cableTypes?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetCableModelsResponse(): GetCableModelsResponse {
  return { cables: [] };
}

export const GetCableModelsResponse = {
  encode(message: GetCableModelsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.cables) {
      CableModel.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCableModelsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCableModelsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.cables.push(CableModel.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetCableModelsResponse {
    return {
      cables: globalThis.Array.isArray(object?.cables) ? object.cables.map((e: any) => CableModel.fromJSON(e)) : [],
    };
  },

  toJSON(message: GetCableModelsResponse): unknown {
    const obj: any = {};
    if (message.cables?.length) {
      obj.cables = message.cables.map((e) => CableModel.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetCableModelsResponse>, I>>(base?: I): GetCableModelsResponse {
    return GetCableModelsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCableModelsResponse>, I>>(object: I): GetCableModelsResponse {
    const message = createBaseGetCableModelsResponse();
    message.cables = object.cables?.map((e) => CableModel.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddNodesToFabricRequest(): AddNodesToFabricRequest {
  return { fabricId: "", nodes: [] };
}

export const AddNodesToFabricRequest = {
  encode(message: AddNodesToFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodesToFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodesToFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodesToFabricRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [],
    };
  },

  toJSON(message: AddNodesToFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodesToFabricRequest>, I>>(base?: I): AddNodesToFabricRequest {
    return AddNodesToFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodesToFabricRequest>, I>>(object: I): AddNodesToFabricRequest {
    const message = createBaseAddNodesToFabricRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAddNodesToFabricResponse(): AddNodesToFabricResponse {
  return { nodes: [] };
}

export const AddNodesToFabricResponse = {
  encode(message: AddNodesToFabricResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.nodes) {
      Node.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AddNodesToFabricResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAddNodesToFabricResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodes.push(Node.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AddNodesToFabricResponse {
    return { nodes: globalThis.Array.isArray(object?.nodes) ? object.nodes.map((e: any) => Node.fromJSON(e)) : [] };
  },

  toJSON(message: AddNodesToFabricResponse): unknown {
    const obj: any = {};
    if (message.nodes?.length) {
      obj.nodes = message.nodes.map((e) => Node.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AddNodesToFabricResponse>, I>>(base?: I): AddNodesToFabricResponse {
    return AddNodesToFabricResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AddNodesToFabricResponse>, I>>(object: I): AddNodesToFabricResponse {
    const message = createBaseAddNodesToFabricResponse();
    message.nodes = object.nodes?.map((e) => Node.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDeleteNodeFromFabricRequest(): DeleteNodeFromFabricRequest {
  return { fabricId: "", nodeId: "" };
}

export const DeleteNodeFromFabricRequest = {
  encode(message: DeleteNodeFromFabricRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodeFromFabricRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodeFromFabricRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodeFromFabricRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: DeleteNodeFromFabricRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodeFromFabricRequest>, I>>(base?: I): DeleteNodeFromFabricRequest {
    return DeleteNodeFromFabricRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodeFromFabricRequest>, I>>(object: I): DeleteNodeFromFabricRequest {
    const message = createBaseDeleteNodeFromFabricRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseDeleteNodeFromFabricResponse(): DeleteNodeFromFabricResponse {
  return { fabricId: "", nodeId: "" };
}

export const DeleteNodeFromFabricResponse = {
  encode(message: DeleteNodeFromFabricResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteNodeFromFabricResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteNodeFromFabricResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeleteNodeFromFabricResponse {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: DeleteNodeFromFabricResponse): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<DeleteNodeFromFabricResponse>, I>>(base?: I): DeleteNodeFromFabricResponse {
    return DeleteNodeFromFabricResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteNodeFromFabricResponse>, I>>(object: I): DeleteNodeFromFabricResponse {
    const message = createBaseDeleteNodeFromFabricResponse();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseBindDeviceRequest(): BindDeviceRequest {
  return { fabricId: "", nodeId: "", deviceId: "" };
}

export const BindDeviceRequest = {
  encode(message: BindDeviceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.deviceId !== "") {
      writer.uint32(26).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BindDeviceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBindDeviceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BindDeviceRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: BindDeviceRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BindDeviceRequest>, I>>(base?: I): BindDeviceRequest {
    return BindDeviceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BindDeviceRequest>, I>>(object: I): BindDeviceRequest {
    const message = createBaseBindDeviceRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseBindDeviceResponse(): BindDeviceResponse {
  return { nodeId: "", deviceId: "" };
}

export const BindDeviceResponse = {
  encode(message: BindDeviceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    if (message.deviceId !== "") {
      writer.uint32(18).string(message.deviceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BindDeviceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBindDeviceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.deviceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BindDeviceResponse {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      deviceId: isSet(object.deviceId) ? globalThis.String(object.deviceId) : "",
    };
  },

  toJSON(message: BindDeviceResponse): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.deviceId !== "") {
      obj.deviceId = message.deviceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BindDeviceResponse>, I>>(base?: I): BindDeviceResponse {
    return BindDeviceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BindDeviceResponse>, I>>(object: I): BindDeviceResponse {
    const message = createBaseBindDeviceResponse();
    message.nodeId = object.nodeId ?? "";
    message.deviceId = object.deviceId ?? "";
    return message;
  },
};

function createBaseUnbindDeviceRequest(): UnbindDeviceRequest {
  return { fabricId: "", nodeId: "" };
}

export const UnbindDeviceRequest = {
  encode(message: UnbindDeviceRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnbindDeviceRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnbindDeviceRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnbindDeviceRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
    };
  },

  toJSON(message: UnbindDeviceRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnbindDeviceRequest>, I>>(base?: I): UnbindDeviceRequest {
    return UnbindDeviceRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnbindDeviceRequest>, I>>(object: I): UnbindDeviceRequest {
    const message = createBaseUnbindDeviceRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseUnbindDeviceResponse(): UnbindDeviceResponse {
  return { nodeId: "" };
}

export const UnbindDeviceResponse = {
  encode(message: UnbindDeviceResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UnbindDeviceResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUnbindDeviceResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): UnbindDeviceResponse {
    return { nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "" };
  },

  toJSON(message: UnbindDeviceResponse): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<UnbindDeviceResponse>, I>>(base?: I): UnbindDeviceResponse {
    return UnbindDeviceResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UnbindDeviceResponse>, I>>(object: I): UnbindDeviceResponse {
    const message = createBaseUnbindDeviceResponse();
    message.nodeId = object.nodeId ?? "";
    return message;
  },
};

function createBaseGetNodeConnectionsRequest(): GetNodeConnectionsRequest {
  return { fabricId: "", nodeId: "", candidate: "" };
}

export const GetNodeConnectionsRequest = {
  encode(message: GetNodeConnectionsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.nodeId !== "") {
      writer.uint32(18).string(message.nodeId);
    }
    if (message.candidate !== "") {
      writer.uint32(26).string(message.candidate);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeConnectionsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeConnectionsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.candidate = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeConnectionsRequest {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      candidate: isSet(object.candidate) ? globalThis.String(object.candidate) : "",
    };
  },

  toJSON(message: GetNodeConnectionsRequest): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.candidate !== "") {
      obj.candidate = message.candidate;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeConnectionsRequest>, I>>(base?: I): GetNodeConnectionsRequest {
    return GetNodeConnectionsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeConnectionsRequest>, I>>(object: I): GetNodeConnectionsRequest {
    const message = createBaseGetNodeConnectionsRequest();
    message.fabricId = object.fabricId ?? "";
    message.nodeId = object.nodeId ?? "";
    message.candidate = object.candidate ?? "";
    return message;
  },
};

function createBaseGetNodeConnectionsResponse(): GetNodeConnectionsResponse {
  return { nodeId: "", connections: [] };
}

export const GetNodeConnectionsResponse = {
  encode(message: GetNodeConnectionsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.nodeId !== "") {
      writer.uint32(10).string(message.nodeId);
    }
    for (const v of message.connections) {
      PortConnection.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNodeConnectionsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNodeConnectionsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.nodeId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.connections.push(PortConnection.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetNodeConnectionsResponse {
    return {
      nodeId: isSet(object.nodeId) ? globalThis.String(object.nodeId) : "",
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PortConnection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetNodeConnectionsResponse): unknown {
    const obj: any = {};
    if (message.nodeId !== "") {
      obj.nodeId = message.nodeId;
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PortConnection.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetNodeConnectionsResponse>, I>>(base?: I): GetNodeConnectionsResponse {
    return GetNodeConnectionsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNodeConnectionsResponse>, I>>(object: I): GetNodeConnectionsResponse {
    const message = createBaseGetNodeConnectionsResponse();
    message.nodeId = object.nodeId ?? "";
    message.connections = object.connections?.map((e) => PortConnection.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetLimitsRequest(): GetLimitsRequest {
  return {};
}

export const GetLimitsRequest = {
  encode(_: GetLimitsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLimitsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLimitsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): GetLimitsRequest {
    return {};
  },

  toJSON(_: GetLimitsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLimitsRequest>, I>>(base?: I): GetLimitsRequest {
    return GetLimitsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLimitsRequest>, I>>(_: I): GetLimitsRequest {
    const message = createBaseGetLimitsRequest();
    return message;
  },
};

function createBaseGetLimitsResponse(): GetLimitsResponse {
  return {
    maxSpines: 0,
    maxLeaves: 0,
    maxPortIndex: 0,
    maxBreakouts: 0,
    maxVnis: 0,
    maxVrfs: 0,
    maxVlanMembers: 0,
    minUserVlanId: 0,
    maxUserVlanId: 0,
    minUserVni: 0,
    maxUserVni: 0,
    maxVrfInterfaces: 0,
    maxSubInterfaces: 0,
    maxStaticRoutes: 0,
    maxDhcpRelays: 0,
    maxPortChannels: 0,
    maxLoopbacks: 0,
    maxPerVlanStps: 0,
    maxBgpPeers: 0,
    maxAnnotations: 0,
    minIpv4Mask: 0,
    minIpv6Mask: 0,
  };
}

export const GetLimitsResponse = {
  encode(message: GetLimitsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.maxSpines !== 0) {
      writer.uint32(8).uint32(message.maxSpines);
    }
    if (message.maxLeaves !== 0) {
      writer.uint32(16).uint32(message.maxLeaves);
    }
    if (message.maxPortIndex !== 0) {
      writer.uint32(24).uint32(message.maxPortIndex);
    }
    if (message.maxBreakouts !== 0) {
      writer.uint32(32).uint32(message.maxBreakouts);
    }
    if (message.maxVnis !== 0) {
      writer.uint32(40).uint32(message.maxVnis);
    }
    if (message.maxVrfs !== 0) {
      writer.uint32(48).uint32(message.maxVrfs);
    }
    if (message.maxVlanMembers !== 0) {
      writer.uint32(56).uint32(message.maxVlanMembers);
    }
    if (message.minUserVlanId !== 0) {
      writer.uint32(72).uint32(message.minUserVlanId);
    }
    if (message.maxUserVlanId !== 0) {
      writer.uint32(64).uint32(message.maxUserVlanId);
    }
    if (message.minUserVni !== 0) {
      writer.uint32(80).uint32(message.minUserVni);
    }
    if (message.maxUserVni !== 0) {
      writer.uint32(88).uint32(message.maxUserVni);
    }
    if (message.maxVrfInterfaces !== 0) {
      writer.uint32(96).uint32(message.maxVrfInterfaces);
    }
    if (message.maxSubInterfaces !== 0) {
      writer.uint32(104).uint32(message.maxSubInterfaces);
    }
    if (message.maxStaticRoutes !== 0) {
      writer.uint32(112).uint32(message.maxStaticRoutes);
    }
    if (message.maxDhcpRelays !== 0) {
      writer.uint32(120).uint32(message.maxDhcpRelays);
    }
    if (message.maxPortChannels !== 0) {
      writer.uint32(128).uint32(message.maxPortChannels);
    }
    if (message.maxLoopbacks !== 0) {
      writer.uint32(136).uint32(message.maxLoopbacks);
    }
    if (message.maxPerVlanStps !== 0) {
      writer.uint32(144).uint32(message.maxPerVlanStps);
    }
    if (message.maxBgpPeers !== 0) {
      writer.uint32(152).uint32(message.maxBgpPeers);
    }
    if (message.maxAnnotations !== 0) {
      writer.uint32(160).uint32(message.maxAnnotations);
    }
    if (message.minIpv4Mask !== 0) {
      writer.uint32(168).uint32(message.minIpv4Mask);
    }
    if (message.minIpv6Mask !== 0) {
      writer.uint32(176).uint32(message.minIpv6Mask);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLimitsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLimitsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.maxSpines = reader.uint32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.maxLeaves = reader.uint32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.maxPortIndex = reader.uint32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxBreakouts = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.maxVnis = reader.uint32();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.maxVrfs = reader.uint32();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.maxVlanMembers = reader.uint32();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.minUserVlanId = reader.uint32();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.maxUserVlanId = reader.uint32();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.minUserVni = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.maxUserVni = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.maxVrfInterfaces = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.maxSubInterfaces = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.maxStaticRoutes = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.maxDhcpRelays = reader.uint32();
          continue;
        case 16:
          if (tag !== 128) {
            break;
          }

          message.maxPortChannels = reader.uint32();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.maxLoopbacks = reader.uint32();
          continue;
        case 18:
          if (tag !== 144) {
            break;
          }

          message.maxPerVlanStps = reader.uint32();
          continue;
        case 19:
          if (tag !== 152) {
            break;
          }

          message.maxBgpPeers = reader.uint32();
          continue;
        case 20:
          if (tag !== 160) {
            break;
          }

          message.maxAnnotations = reader.uint32();
          continue;
        case 21:
          if (tag !== 168) {
            break;
          }

          message.minIpv4Mask = reader.uint32();
          continue;
        case 22:
          if (tag !== 176) {
            break;
          }

          message.minIpv6Mask = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetLimitsResponse {
    return {
      maxSpines: isSet(object.maxSpines) ? globalThis.Number(object.maxSpines) : 0,
      maxLeaves: isSet(object.maxLeaves) ? globalThis.Number(object.maxLeaves) : 0,
      maxPortIndex: isSet(object.maxPortIndex) ? globalThis.Number(object.maxPortIndex) : 0,
      maxBreakouts: isSet(object.maxBreakouts) ? globalThis.Number(object.maxBreakouts) : 0,
      maxVnis: isSet(object.maxVnis) ? globalThis.Number(object.maxVnis) : 0,
      maxVrfs: isSet(object.maxVrfs) ? globalThis.Number(object.maxVrfs) : 0,
      maxVlanMembers: isSet(object.maxVlanMembers) ? globalThis.Number(object.maxVlanMembers) : 0,
      minUserVlanId: isSet(object.minUserVlanId) ? globalThis.Number(object.minUserVlanId) : 0,
      maxUserVlanId: isSet(object.maxUserVlanId) ? globalThis.Number(object.maxUserVlanId) : 0,
      minUserVni: isSet(object.minUserVni) ? globalThis.Number(object.minUserVni) : 0,
      maxUserVni: isSet(object.maxUserVni) ? globalThis.Number(object.maxUserVni) : 0,
      maxVrfInterfaces: isSet(object.maxVrfInterfaces) ? globalThis.Number(object.maxVrfInterfaces) : 0,
      maxSubInterfaces: isSet(object.maxSubInterfaces) ? globalThis.Number(object.maxSubInterfaces) : 0,
      maxStaticRoutes: isSet(object.maxStaticRoutes) ? globalThis.Number(object.maxStaticRoutes) : 0,
      maxDhcpRelays: isSet(object.maxDhcpRelays) ? globalThis.Number(object.maxDhcpRelays) : 0,
      maxPortChannels: isSet(object.maxPortChannels) ? globalThis.Number(object.maxPortChannels) : 0,
      maxLoopbacks: isSet(object.maxLoopbacks) ? globalThis.Number(object.maxLoopbacks) : 0,
      maxPerVlanStps: isSet(object.maxPerVlanStps) ? globalThis.Number(object.maxPerVlanStps) : 0,
      maxBgpPeers: isSet(object.maxBgpPeers) ? globalThis.Number(object.maxBgpPeers) : 0,
      maxAnnotations: isSet(object.maxAnnotations) ? globalThis.Number(object.maxAnnotations) : 0,
      minIpv4Mask: isSet(object.minIpv4Mask) ? globalThis.Number(object.minIpv4Mask) : 0,
      minIpv6Mask: isSet(object.minIpv6Mask) ? globalThis.Number(object.minIpv6Mask) : 0,
    };
  },

  toJSON(message: GetLimitsResponse): unknown {
    const obj: any = {};
    if (message.maxSpines !== 0) {
      obj.maxSpines = Math.round(message.maxSpines);
    }
    if (message.maxLeaves !== 0) {
      obj.maxLeaves = Math.round(message.maxLeaves);
    }
    if (message.maxPortIndex !== 0) {
      obj.maxPortIndex = Math.round(message.maxPortIndex);
    }
    if (message.maxBreakouts !== 0) {
      obj.maxBreakouts = Math.round(message.maxBreakouts);
    }
    if (message.maxVnis !== 0) {
      obj.maxVnis = Math.round(message.maxVnis);
    }
    if (message.maxVrfs !== 0) {
      obj.maxVrfs = Math.round(message.maxVrfs);
    }
    if (message.maxVlanMembers !== 0) {
      obj.maxVlanMembers = Math.round(message.maxVlanMembers);
    }
    if (message.minUserVlanId !== 0) {
      obj.minUserVlanId = Math.round(message.minUserVlanId);
    }
    if (message.maxUserVlanId !== 0) {
      obj.maxUserVlanId = Math.round(message.maxUserVlanId);
    }
    if (message.minUserVni !== 0) {
      obj.minUserVni = Math.round(message.minUserVni);
    }
    if (message.maxUserVni !== 0) {
      obj.maxUserVni = Math.round(message.maxUserVni);
    }
    if (message.maxVrfInterfaces !== 0) {
      obj.maxVrfInterfaces = Math.round(message.maxVrfInterfaces);
    }
    if (message.maxSubInterfaces !== 0) {
      obj.maxSubInterfaces = Math.round(message.maxSubInterfaces);
    }
    if (message.maxStaticRoutes !== 0) {
      obj.maxStaticRoutes = Math.round(message.maxStaticRoutes);
    }
    if (message.maxDhcpRelays !== 0) {
      obj.maxDhcpRelays = Math.round(message.maxDhcpRelays);
    }
    if (message.maxPortChannels !== 0) {
      obj.maxPortChannels = Math.round(message.maxPortChannels);
    }
    if (message.maxLoopbacks !== 0) {
      obj.maxLoopbacks = Math.round(message.maxLoopbacks);
    }
    if (message.maxPerVlanStps !== 0) {
      obj.maxPerVlanStps = Math.round(message.maxPerVlanStps);
    }
    if (message.maxBgpPeers !== 0) {
      obj.maxBgpPeers = Math.round(message.maxBgpPeers);
    }
    if (message.maxAnnotations !== 0) {
      obj.maxAnnotations = Math.round(message.maxAnnotations);
    }
    if (message.minIpv4Mask !== 0) {
      obj.minIpv4Mask = Math.round(message.minIpv4Mask);
    }
    if (message.minIpv6Mask !== 0) {
      obj.minIpv6Mask = Math.round(message.minIpv6Mask);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetLimitsResponse>, I>>(base?: I): GetLimitsResponse {
    return GetLimitsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLimitsResponse>, I>>(object: I): GetLimitsResponse {
    const message = createBaseGetLimitsResponse();
    message.maxSpines = object.maxSpines ?? 0;
    message.maxLeaves = object.maxLeaves ?? 0;
    message.maxPortIndex = object.maxPortIndex ?? 0;
    message.maxBreakouts = object.maxBreakouts ?? 0;
    message.maxVnis = object.maxVnis ?? 0;
    message.maxVrfs = object.maxVrfs ?? 0;
    message.maxVlanMembers = object.maxVlanMembers ?? 0;
    message.minUserVlanId = object.minUserVlanId ?? 0;
    message.maxUserVlanId = object.maxUserVlanId ?? 0;
    message.minUserVni = object.minUserVni ?? 0;
    message.maxUserVni = object.maxUserVni ?? 0;
    message.maxVrfInterfaces = object.maxVrfInterfaces ?? 0;
    message.maxSubInterfaces = object.maxSubInterfaces ?? 0;
    message.maxStaticRoutes = object.maxStaticRoutes ?? 0;
    message.maxDhcpRelays = object.maxDhcpRelays ?? 0;
    message.maxPortChannels = object.maxPortChannels ?? 0;
    message.maxLoopbacks = object.maxLoopbacks ?? 0;
    message.maxPerVlanStps = object.maxPerVlanStps ?? 0;
    message.maxBgpPeers = object.maxBgpPeers ?? 0;
    message.maxAnnotations = object.maxAnnotations ?? 0;
    message.minIpv4Mask = object.minIpv4Mask ?? 0;
    message.minIpv6Mask = object.minIpv6Mask ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000);
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof globalThis.Date) {
    return o;
  } else if (typeof o === "string") {
    return new globalThis.Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
