import { GrpcWebImpl } from "src/gen/schema/brig/bservice";
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { logger } from "src/core/globals";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;
export class GrpcDebugableWebImpl extends GrpcWebImpl {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: BrowserHeaders | undefined
  ): Promise<any> {
    if (localStorage.getItem("apiLoggingInConsole") === "True") {
      return new Promise<any>((resolve, reject) => {
        super.unary(methodDesc, _request, metadata).then(
          (resp: any) => {
            logger.log({
              service: methodDesc?.service.serviceName,
              method: methodDesc?.methodName,
              request: _request,
              response: resp
            });
            resolve(resp);
          },
          (err) => {
            logger.log({
              service: methodDesc?.service?.serviceName,
              method: methodDesc?.methodName,
              request: _request,
              response: err
            });
            reject(err);
          }
        );
      });
    }
    return super.unary(methodDesc, _request, metadata);
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
