import {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState
} from "react";
import { Fabric } from "src/gen/schema/models/models";
import { Apis, KEY_NAME, CandidateMode } from "src/utils/api";
import { FabricEventMonitor } from "src/pages/candidates/fabricEventMonitor";
import {
  ActivityEvent,
  FabricCandidate,
  FabricConfig
} from "src/gen/schema/configd/api";

const DATE_FORMAT = "LL/dd/yyyy hh:mm:ss";

export const fixContext = () => {
  /**
   * TODO: figure out if there is a better solution
   * This is to bypass a bug
   * with the hot reload. When
   * a file is changed in dev mode
   * the hot reload will reset
   * all context to their default
   * value. this will mostly break
   * any context. To fix the issue
   * forcing a page reload will
   * reset the context back to
   * a working state.
   *
   * This is kind of a sledgehammer
   * for cracking eggs ;)
   */
  window.location.reload();
};

export { CandidateMode, DATE_FORMAT };

interface DataProviderContextSchema {
  loading: boolean;
  fabric: Fabric | undefined;
  events: ActivityEvent[];
  mode: CandidateMode;
  refresh: () => void;
  setMode: (m: CandidateMode) => void;
  setFabricId: (id?: string) => void;
}

export const DataProviderContext = createContext<DataProviderContextSchema>({
  fabric: undefined,
  loading: false,
  events: [],
  mode: CandidateMode.Edit,
  /**
   * This is sort of a sledgehammer
   * because the streaming configs
   * don't stream everything we need.
   */
  refresh: fixContext,
  setMode: fixContext,
  setFabricId: fixContext
});

interface DataProviderProps extends PropsWithChildren {}
export const DataProvider = (props: DataProviderProps) => {
  const { children } = props;

  /* The list of pending Fabric Transaction */
  const [events, setEvents] = useState<ActivityEvent[]>([]);
  /* The full Fabric config */
  const [fabric, setFabric] = useState<Fabric | undefined>();

  const [fabricId, setFabricId] = useState<string | undefined>();
  const [mode, _setMode] = useState<CandidateMode>(() => {
    /* Get the value in local storage if there is one. */
    const v = localStorage.getItem(KEY_NAME);
    /**
     * Currently just defaulting to edit, but once
     * we have the changes api the plan is to default
     * to edit if there are pending changes.
     */
    return v ? (v as CandidateMode) : CandidateMode.Edit;
  });
  const [loading, setLoading] = useState<boolean>(true);

  const refresh = useCallback(() => {
    if (fabricId) {
      Apis.ConfigD.getFabricConfig(mode, fabricId).then(
        (fc: FabricConfig | undefined) => {
          setFabric(fc?.fabric);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
      /**
       * Loads all the pending Fabric Transactions.
       * There should just be one Candidate with a
       * bunch of activity events
       */
      Apis.ConfigD.getFabricTransactions(fabricId).then(
        (t: FabricCandidate[] = []) => {
          if (t[0]) {
            const [candidate] = t;
            setEvents(candidate.events);
          } else {
            setEvents([]);
          }
        }
      );
    }
  }, [fabricId, mode]);

  useEffect(() => {
    refresh();
    window.cnc.setSync(refresh);
  }, [refresh]);

  useEffect(() => {
    // Connect the fabric specific websocket
    window.cnc.sextantPool.useStream(fabricId);
    return () => {
      setFabric(undefined);
      /**
       * websocket will disconnect itself once
       * all the subscribers are cleaned up.
       * should be no need for
       * window.cnc.sextantPool.close(fabricId);
       */
    };
  }, [fabricId]);

  const setMode = useCallback((m: CandidateMode) => {
    /* store the value in local storage so the user will have a consistent experience */
    localStorage.setItem(KEY_NAME, m);
    _setMode(m);
  }, []);

  return (
    <DataProviderContext.Provider
      value={{
        loading,
        fabric,
        events,
        mode,
        /**
         * This should not be needed but
         * the reality is the streaming api
         * doesn't stream everything we need.
         * this will let a component force a
         * reload of the fabric config.
         */
        refresh,
        setMode,
        setFabricId
      }}
    >
      {children}
      <FabricEventMonitor
        key={fabricId}
        show={fabricId !== undefined}
        onUpdate={refresh}
        fabricId={fabricId}
      />
    </DataProviderContext.Provider>
  );
};
