// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/routing.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Annotation } from "./global";
import { Metadata, NetworkInterface, PortEndpoint } from "./types";

export const protobufPackage = "models";

/**
 * PerVlanStp encapsulates properties of a Per-Vlan spanning tree protocol config object.
 * There can be only one PerVlanStp config for a fabric.
 */
export interface PerVlanStp {
  /**
   * The identifier of Fabric to which this PerVlanStp belong. Fabric identifier is mandatory,
   * and immutable once set.
   */
  fabricId: string;
  /** Indicates if PerVlanStp is enabled or disabled. */
  enabled: boolean;
  /** Readonly revision metadata of the object. */
  metadata:
    | Metadata
    | undefined;
  /**
   * Switch waits for timeout duration before moving the port to forwarding state when
   * superior BPDUs (Bridge Protocol Data Unit) stop coming to the port. Default value
   * is 30s, and range is 5-600s.
   */
  rootGuardTimeout: number;
  /** Packet forward delay time in seconds. Default value is 15s, and range is 4-30s. */
  forwardDelay: number;
  /** Hello interval in seconds for transmission of BPDUs. Default values is 2s, and range is 1-10s. */
  helloInterval: number;
  /** Maximum time to listen for root bridge in seconds. Default value is 20s, and range is 6-40s. */
  maxAge: number;
  /** Bridge priority value as a multiple of 4096. Default is 32768, and range is 4096-61440. */
  priority: number;
}

/**
 * BgpPeer encapsulates properties of BGP peer. Vrf on a border Node must have
 * a BGP or OSPF object to talk to external routers. BgpPeer provides the config
 * to VRF to peer with external router.
 */
export interface BgpPeer {
  /**
   * The unique identifier of BgpPeer. Identifier is required to update an existing
   * BgpPeer. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of BgpPeer. BgpPeer name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of BgpPeer. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if BgpPeer is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /**
   * The identifier of Fabric to which this BgpPeer belongs. Fabric identifier
   * is mandatory, and immutable once set.
   */
  fabricId: string;
  /** Local switch/fabric ASN. */
  localAsn: number;
  /** Asn of the external router. */
  remoteAsn: number;
  /**
   * IPv4 addresses of external router. Must not set IPv6 addresses when
   * IPv4 addresses are set. User may not provide more than one IPv4 address.
   */
  ipv4Addresses: string[];
  /**
   * IPv6 addresses of external router. Must not set IPv4 addresses when
   * IPv6 addresses are set. User may not provide more than one IPv6 address.
   */
  ipv6Addresses: string[];
  /**
   * VRF to which this BgpPeer is attached to. VrfId is mandatory.
   * External router's IP addresses must be reachable in the VRF.
   */
  vrfId: string;
  /** Expected number of hops to the external router. */
  ttl: number;
  /** MD5 password or TCP-AO keychain encryption password in clear text. */
  password: string;
  /** Indicates that authentication is TCP-AO based (not Supported for Beta). */
  tcpAo: boolean;
  /** User selected source interface. */
  sourceInf: NetworkInterface | undefined;
}

/**
 * Ospf encapsulates properties of OSPF routing object.
 *
 * NOTES:
 * 1) OSPF is meaningful only in a Vrf configured for Border leaf.
 * 2) All ports must be classified as BORDER port.
 */
export interface Ospf {
  /**
   * The unique identifier of Ospf. Identifier is required to update an existing
   * Ospf. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of Ospf. Ospf name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Ospf. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Ospf is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** OSPF member ports or interfaces. */
  ports: PortEndpoint[];
}

export interface Isis {
  /**
   * The unique identifier of Isis. Identifier is required to update an existing
   * Isis. If identifier is missing, then set operation defaults to CREATE mode.
   */
  id: string;
  /** The user-defined name of Isis. Isis name is unique, and is case-insensitive. */
  name: string;
  /** A user-defined description of Isis. Description can be up to 2000 characters. */
  description: string;
  /** A set of user-defined labels for searching and locating objects. */
  labels: string[];
  /** A set of annotations to store user-defined data. */
  annotations: Annotation[];
  /** Indicates if Isis is enabled or disabled. */
  enabled: boolean;
  /**
   * Readonly revision metadata of the object. Metadata is not required
   * for object creation or modification.
   */
  metadata:
    | Metadata
    | undefined;
  /** IS-IS member ports or interfaces. */
  ports: PortEndpoint[];
}

function createBasePerVlanStp(): PerVlanStp {
  return {
    fabricId: "",
    enabled: false,
    metadata: undefined,
    rootGuardTimeout: 0,
    forwardDelay: 0,
    helloInterval: 0,
    maxAge: 0,
    priority: 0,
  };
}

export const PerVlanStp = {
  encode(message: PerVlanStp, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fabricId !== "") {
      writer.uint32(10).string(message.fabricId);
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.rootGuardTimeout !== 0) {
      writer.uint32(88).uint32(message.rootGuardTimeout);
    }
    if (message.forwardDelay !== 0) {
      writer.uint32(96).uint32(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      writer.uint32(104).uint32(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      writer.uint32(112).uint32(message.maxAge);
    }
    if (message.priority !== 0) {
      writer.uint32(120).uint32(message.priority);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PerVlanStp {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePerVlanStp();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.rootGuardTimeout = reader.uint32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.forwardDelay = reader.uint32();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.helloInterval = reader.uint32();
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.maxAge = reader.uint32();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.priority = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PerVlanStp {
    return {
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      rootGuardTimeout: isSet(object.rootGuardTimeout) ? globalThis.Number(object.rootGuardTimeout) : 0,
      forwardDelay: isSet(object.forwardDelay) ? globalThis.Number(object.forwardDelay) : 0,
      helloInterval: isSet(object.helloInterval) ? globalThis.Number(object.helloInterval) : 0,
      maxAge: isSet(object.maxAge) ? globalThis.Number(object.maxAge) : 0,
      priority: isSet(object.priority) ? globalThis.Number(object.priority) : 0,
    };
  },

  toJSON(message: PerVlanStp): unknown {
    const obj: any = {};
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.rootGuardTimeout !== 0) {
      obj.rootGuardTimeout = Math.round(message.rootGuardTimeout);
    }
    if (message.forwardDelay !== 0) {
      obj.forwardDelay = Math.round(message.forwardDelay);
    }
    if (message.helloInterval !== 0) {
      obj.helloInterval = Math.round(message.helloInterval);
    }
    if (message.maxAge !== 0) {
      obj.maxAge = Math.round(message.maxAge);
    }
    if (message.priority !== 0) {
      obj.priority = Math.round(message.priority);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PerVlanStp>, I>>(base?: I): PerVlanStp {
    return PerVlanStp.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PerVlanStp>, I>>(object: I): PerVlanStp {
    const message = createBasePerVlanStp();
    message.fabricId = object.fabricId ?? "";
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.rootGuardTimeout = object.rootGuardTimeout ?? 0;
    message.forwardDelay = object.forwardDelay ?? 0;
    message.helloInterval = object.helloInterval ?? 0;
    message.maxAge = object.maxAge ?? 0;
    message.priority = object.priority ?? 0;
    return message;
  },
};

function createBaseBgpPeer(): BgpPeer {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    fabricId: "",
    localAsn: 0,
    remoteAsn: 0,
    ipv4Addresses: [],
    ipv6Addresses: [],
    vrfId: "",
    ttl: 0,
    password: "",
    tcpAo: false,
    sourceInf: undefined,
  };
}

export const BgpPeer = {
  encode(message: BgpPeer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.fabricId !== "") {
      writer.uint32(74).string(message.fabricId);
    }
    if (message.localAsn !== 0) {
      writer.uint32(80).uint32(message.localAsn);
    }
    if (message.remoteAsn !== 0) {
      writer.uint32(88).uint32(message.remoteAsn);
    }
    for (const v of message.ipv4Addresses) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.ipv6Addresses) {
      writer.uint32(106).string(v!);
    }
    if (message.vrfId !== "") {
      writer.uint32(114).string(message.vrfId);
    }
    if (message.ttl !== 0) {
      writer.uint32(120).uint32(message.ttl);
    }
    if (message.password !== "") {
      writer.uint32(130).string(message.password);
    }
    if (message.tcpAo !== false) {
      writer.uint32(136).bool(message.tcpAo);
    }
    if (message.sourceInf !== undefined) {
      NetworkInterface.encode(message.sourceInf, writer.uint32(146).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BgpPeer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBgpPeer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.fabricId = reader.string();
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.localAsn = reader.uint32();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.remoteAsn = reader.uint32();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.ipv4Addresses.push(reader.string());
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.ipv6Addresses.push(reader.string());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.vrfId = reader.string();
          continue;
        case 15:
          if (tag !== 120) {
            break;
          }

          message.ttl = reader.uint32();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.password = reader.string();
          continue;
        case 17:
          if (tag !== 136) {
            break;
          }

          message.tcpAo = reader.bool();
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.sourceInf = NetworkInterface.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BgpPeer {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      fabricId: isSet(object.fabricId) ? globalThis.String(object.fabricId) : "",
      localAsn: isSet(object.localAsn) ? globalThis.Number(object.localAsn) : 0,
      remoteAsn: isSet(object.remoteAsn) ? globalThis.Number(object.remoteAsn) : 0,
      ipv4Addresses: globalThis.Array.isArray(object?.ipv4Addresses)
        ? object.ipv4Addresses.map((e: any) => globalThis.String(e))
        : [],
      ipv6Addresses: globalThis.Array.isArray(object?.ipv6Addresses)
        ? object.ipv6Addresses.map((e: any) => globalThis.String(e))
        : [],
      vrfId: isSet(object.vrfId) ? globalThis.String(object.vrfId) : "",
      ttl: isSet(object.ttl) ? globalThis.Number(object.ttl) : 0,
      password: isSet(object.password) ? globalThis.String(object.password) : "",
      tcpAo: isSet(object.tcpAo) ? globalThis.Boolean(object.tcpAo) : false,
      sourceInf: isSet(object.sourceInf) ? NetworkInterface.fromJSON(object.sourceInf) : undefined,
    };
  },

  toJSON(message: BgpPeer): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.fabricId !== "") {
      obj.fabricId = message.fabricId;
    }
    if (message.localAsn !== 0) {
      obj.localAsn = Math.round(message.localAsn);
    }
    if (message.remoteAsn !== 0) {
      obj.remoteAsn = Math.round(message.remoteAsn);
    }
    if (message.ipv4Addresses?.length) {
      obj.ipv4Addresses = message.ipv4Addresses;
    }
    if (message.ipv6Addresses?.length) {
      obj.ipv6Addresses = message.ipv6Addresses;
    }
    if (message.vrfId !== "") {
      obj.vrfId = message.vrfId;
    }
    if (message.ttl !== 0) {
      obj.ttl = Math.round(message.ttl);
    }
    if (message.password !== "") {
      obj.password = message.password;
    }
    if (message.tcpAo !== false) {
      obj.tcpAo = message.tcpAo;
    }
    if (message.sourceInf !== undefined) {
      obj.sourceInf = NetworkInterface.toJSON(message.sourceInf);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BgpPeer>, I>>(base?: I): BgpPeer {
    return BgpPeer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BgpPeer>, I>>(object: I): BgpPeer {
    const message = createBaseBgpPeer();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.fabricId = object.fabricId ?? "";
    message.localAsn = object.localAsn ?? 0;
    message.remoteAsn = object.remoteAsn ?? 0;
    message.ipv4Addresses = object.ipv4Addresses?.map((e) => e) || [];
    message.ipv6Addresses = object.ipv6Addresses?.map((e) => e) || [];
    message.vrfId = object.vrfId ?? "";
    message.ttl = object.ttl ?? 0;
    message.password = object.password ?? "";
    message.tcpAo = object.tcpAo ?? false;
    message.sourceInf = (object.sourceInf !== undefined && object.sourceInf !== null)
      ? NetworkInterface.fromPartial(object.sourceInf)
      : undefined;
    return message;
  },
};

function createBaseOspf(): Ospf {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    ports: [],
  };
}

export const Ospf = {
  encode(message: Ospf, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.ports) {
      PortEndpoint.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Ospf {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOspf();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ports.push(PortEndpoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Ospf {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => PortEndpoint.fromJSON(e)) : [],
    };
  },

  toJSON(message: Ospf): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => PortEndpoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Ospf>, I>>(base?: I): Ospf {
    return Ospf.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Ospf>, I>>(object: I): Ospf {
    const message = createBaseOspf();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.ports = object.ports?.map((e) => PortEndpoint.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIsis(): Isis {
  return {
    id: "",
    name: "",
    description: "",
    labels: [],
    annotations: [],
    enabled: false,
    metadata: undefined,
    ports: [],
  };
}

export const Isis = {
  encode(message: Isis, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    for (const v of message.labels) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.annotations) {
      Annotation.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.enabled !== false) {
      writer.uint32(48).bool(message.enabled);
    }
    if (message.metadata !== undefined) {
      Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.ports) {
      PortEndpoint.encode(v!, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Isis {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIsis();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.labels.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.annotations.push(Annotation.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = Metadata.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.ports.push(PortEndpoint.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Isis {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      labels: globalThis.Array.isArray(object?.labels) ? object.labels.map((e: any) => globalThis.String(e)) : [],
      annotations: globalThis.Array.isArray(object?.annotations)
        ? object.annotations.map((e: any) => Annotation.fromJSON(e))
        : [],
      enabled: isSet(object.enabled) ? globalThis.Boolean(object.enabled) : false,
      metadata: isSet(object.metadata) ? Metadata.fromJSON(object.metadata) : undefined,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => PortEndpoint.fromJSON(e)) : [],
    };
  },

  toJSON(message: Isis): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.labels?.length) {
      obj.labels = message.labels;
    }
    if (message.annotations?.length) {
      obj.annotations = message.annotations.map((e) => Annotation.toJSON(e));
    }
    if (message.enabled !== false) {
      obj.enabled = message.enabled;
    }
    if (message.metadata !== undefined) {
      obj.metadata = Metadata.toJSON(message.metadata);
    }
    if (message.ports?.length) {
      obj.ports = message.ports.map((e) => PortEndpoint.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Isis>, I>>(base?: I): Isis {
    return Isis.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Isis>, I>>(object: I): Isis {
    const message = createBaseIsis();
    message.id = object.id ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.labels = object.labels?.map((e) => e) || [];
    message.annotations = object.annotations?.map((e) => Annotation.fromPartial(e)) || [];
    message.enabled = object.enabled ?? false;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? Metadata.fromPartial(object.metadata)
      : undefined;
    message.ports = object.ports?.map((e) => PortEndpoint.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
