// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.176.0
//   protoc               unknown
// source: models/fabric.proto

/* eslint-disable */
import _m0 from "protobufjs/minimal";

export const protobufPackage = "models";

/** AdjacencyProtocol enumerates various port adjacency protocols. */
export enum AdjacencyProtocol {
  /** ADJACENCY_UNSPECIFIED - Unspecified adjacency protocol type. */
  ADJACENCY_UNSPECIFIED = 0,
  /** LLDP - Link layer discovery protocol. */
  LLDP = 1,
  /** CDP - Cisco discovery protocol. */
  CDP = 2,
  /** LACP - Link aggregation control protocol (aka PortChannel). */
  LACP = 3,
  /** STP - Spanning tree protocol. */
  STP = 4,
  UNRECOGNIZED = -1,
}

export function adjacencyProtocolFromJSON(object: any): AdjacencyProtocol {
  switch (object) {
    case 0:
    case "ADJACENCY_UNSPECIFIED":
      return AdjacencyProtocol.ADJACENCY_UNSPECIFIED;
    case 1:
    case "LLDP":
      return AdjacencyProtocol.LLDP;
    case 2:
    case "CDP":
      return AdjacencyProtocol.CDP;
    case 3:
    case "LACP":
      return AdjacencyProtocol.LACP;
    case 4:
    case "STP":
      return AdjacencyProtocol.STP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return AdjacencyProtocol.UNRECOGNIZED;
  }
}

export function adjacencyProtocolToJSON(object: AdjacencyProtocol): string {
  switch (object) {
    case AdjacencyProtocol.ADJACENCY_UNSPECIFIED:
      return "ADJACENCY_UNSPECIFIED";
    case AdjacencyProtocol.LLDP:
      return "LLDP";
    case AdjacencyProtocol.CDP:
      return "CDP";
    case AdjacencyProtocol.LACP:
      return "LACP";
    case AdjacencyProtocol.STP:
      return "STP";
    case AdjacencyProtocol.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** CableType enumerates various cable types. */
export enum CableType {
  /** CABLE_TYPE_UNSPECIFIED - Unspecified cable type. */
  CABLE_TYPE_UNSPECIFIED = 0,
  /** DAC - Directly attached copper cable. */
  DAC = 1,
  /** FIBER - Fiber or optics cable type. */
  FIBER = 2,
  /** AOC - Active Optical Cable. */
  AOC = 3,
  /** SMF - Single-Mode Fiber. */
  SMF = 4,
  /** MMF - Multi-Mode Fiber. */
  MMF = 5,
  /** CAT6AOR7 - Copper cable of type Cat6a or Cat7. */
  CAT6AOR7 = 6,
  UNRECOGNIZED = -1,
}

export function cableTypeFromJSON(object: any): CableType {
  switch (object) {
    case 0:
    case "CABLE_TYPE_UNSPECIFIED":
      return CableType.CABLE_TYPE_UNSPECIFIED;
    case 1:
    case "DAC":
      return CableType.DAC;
    case 2:
    case "FIBER":
      return CableType.FIBER;
    case 3:
    case "AOC":
      return CableType.AOC;
    case 4:
    case "SMF":
      return CableType.SMF;
    case 5:
    case "MMF":
      return CableType.MMF;
    case 6:
    case "CAT6AOR7":
      return CableType.CAT6AOR7;
    case -1:
    case "UNRECOGNIZED":
    default:
      return CableType.UNRECOGNIZED;
  }
}

export function cableTypeToJSON(object: CableType): string {
  switch (object) {
    case CableType.CABLE_TYPE_UNSPECIFIED:
      return "CABLE_TYPE_UNSPECIFIED";
    case CableType.DAC:
      return "DAC";
    case CableType.FIBER:
      return "FIBER";
    case CableType.AOC:
      return "AOC";
    case CableType.SMF:
      return "SMF";
    case CableType.MMF:
      return "MMF";
    case CableType.CAT6AOR7:
      return "CAT6AOR7";
    case CableType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ConfigOrigin is used by a ManagementPort to indicate if the configuration was provided by the cloud or the device. */
export enum ConfigOrigin {
  /** CONFIG_ORIGIN_UNSPECIFIED - Unspecified config origin. */
  CONFIG_ORIGIN_UNSPECIFIED = 0,
  /** CONFIG_ORIGIN_CLOUD - The management port configuration has been provided by the user. */
  CONFIG_ORIGIN_CLOUD = 1,
  /** CONFIG_ORIGIN_DEVICE - The management port configuration was determined by the device. */
  CONFIG_ORIGIN_DEVICE = 2,
  UNRECOGNIZED = -1,
}

export function configOriginFromJSON(object: any): ConfigOrigin {
  switch (object) {
    case 0:
    case "CONFIG_ORIGIN_UNSPECIFIED":
      return ConfigOrigin.CONFIG_ORIGIN_UNSPECIFIED;
    case 1:
    case "CONFIG_ORIGIN_CLOUD":
      return ConfigOrigin.CONFIG_ORIGIN_CLOUD;
    case 2:
    case "CONFIG_ORIGIN_DEVICE":
      return ConfigOrigin.CONFIG_ORIGIN_DEVICE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigOrigin.UNRECOGNIZED;
  }
}

export function configOriginToJSON(object: ConfigOrigin): string {
  switch (object) {
    case ConfigOrigin.CONFIG_ORIGIN_UNSPECIFIED:
      return "CONFIG_ORIGIN_UNSPECIFIED";
    case ConfigOrigin.CONFIG_ORIGIN_CLOUD:
      return "CONFIG_ORIGIN_CLOUD";
    case ConfigOrigin.CONFIG_ORIGIN_DEVICE:
      return "CONFIG_ORIGIN_DEVICE";
    case ConfigOrigin.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** ConfigType is used by a ManagementPort to indicate if the configuration is static or DHCP. */
export enum ConfigType {
  /** CONFIG_TYPE_UNSPECIFIED - Unspecified config origin. */
  CONFIG_TYPE_UNSPECIFIED = 0,
  /** CONFIG_TYPE_STATIC - The management port configuration has been provided by the user. */
  CONFIG_TYPE_STATIC = 1,
  /** CONFIG_TYPE_DHCP - The management port configuration has been set through DHCP. */
  CONFIG_TYPE_DHCP = 2,
  UNRECOGNIZED = -1,
}

export function configTypeFromJSON(object: any): ConfigType {
  switch (object) {
    case 0:
    case "CONFIG_TYPE_UNSPECIFIED":
      return ConfigType.CONFIG_TYPE_UNSPECIFIED;
    case 1:
    case "CONFIG_TYPE_STATIC":
      return ConfigType.CONFIG_TYPE_STATIC;
    case 2:
    case "CONFIG_TYPE_DHCP":
      return ConfigType.CONFIG_TYPE_DHCP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConfigType.UNRECOGNIZED;
  }
}

export function configTypeToJSON(object: ConfigType): string {
  switch (object) {
    case ConfigType.CONFIG_TYPE_UNSPECIFIED:
      return "CONFIG_TYPE_UNSPECIFIED";
    case ConfigType.CONFIG_TYPE_STATIC:
      return "CONFIG_TYPE_STATIC";
    case ConfigType.CONFIG_TYPE_DHCP:
      return "CONFIG_TYPE_DHCP";
    case ConfigType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/**
 * ConnectedState is used by a ManagementPort to indicate if the port
 * has ever successfully connected to the service. It is distinct from
 * the AdminState.
 */
export enum ConnectedState {
  /** CONNECTED_STATE_UNSPECIFIED - Unspecified connected state. */
  CONNECTED_STATE_UNSPECIFIED = 0,
  /** CONNECTED_STATE_NOT_CONNECTED - The management port has never connected. */
  CONNECTED_STATE_NOT_CONNECTED = 1,
  /** CONNECTED_STATE_CONNECTED - The management port is connected or has connected at some point. */
  CONNECTED_STATE_CONNECTED = 2,
  UNRECOGNIZED = -1,
}

export function connectedStateFromJSON(object: any): ConnectedState {
  switch (object) {
    case 0:
    case "CONNECTED_STATE_UNSPECIFIED":
      return ConnectedState.CONNECTED_STATE_UNSPECIFIED;
    case 1:
    case "CONNECTED_STATE_NOT_CONNECTED":
      return ConnectedState.CONNECTED_STATE_NOT_CONNECTED;
    case 2:
    case "CONNECTED_STATE_CONNECTED":
      return ConnectedState.CONNECTED_STATE_CONNECTED;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ConnectedState.UNRECOGNIZED;
  }
}

export function connectedStateToJSON(object: ConnectedState): string {
  switch (object) {
    case ConnectedState.CONNECTED_STATE_UNSPECIFIED:
      return "CONNECTED_STATE_UNSPECIFIED";
    case ConnectedState.CONNECTED_STATE_NOT_CONNECTED:
      return "CONNECTED_STATE_NOT_CONNECTED";
    case ConnectedState.CONNECTED_STATE_CONNECTED:
      return "CONNECTED_STATE_CONNECTED";
    case ConnectedState.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** FabricTopology defines an enumeration of fabric topology. */
export enum FabricTopology {
  /** TOPOLOGY_UNSPECIFIED - Unspecified fabric topology. */
  TOPOLOGY_UNSPECIFIED = 0,
  /** MESH - A mesh of two or more nodes. */
  MESH = 1,
  /** SPINE_LEAF - Spine-leaf fabric. */
  SPINE_LEAF = 2,
  UNRECOGNIZED = -1,
}

export function fabricTopologyFromJSON(object: any): FabricTopology {
  switch (object) {
    case 0:
    case "TOPOLOGY_UNSPECIFIED":
      return FabricTopology.TOPOLOGY_UNSPECIFIED;
    case 1:
    case "MESH":
      return FabricTopology.MESH;
    case 2:
    case "SPINE_LEAF":
      return FabricTopology.SPINE_LEAF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return FabricTopology.UNRECOGNIZED;
  }
}

export function fabricTopologyToJSON(object: FabricTopology): string {
  switch (object) {
    case FabricTopology.TOPOLOGY_UNSPECIFIED:
      return "TOPOLOGY_UNSPECIFIED";
    case FabricTopology.MESH:
      return "MESH";
    case FabricTopology.SPINE_LEAF:
      return "SPINE_LEAF";
    case FabricTopology.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** InterfaceType enumerates various network interface types. */
export enum InterfaceType {
  /** INTERFACE_UNSPECIFIED - Unspecified interface type. */
  INTERFACE_UNSPECIFIED = 0,
  /** NETWORK_PORT_INTERFACE - Physical network port. */
  NETWORK_PORT_INTERFACE = 1,
  /** LOOPBACK_INTERFACE - Loopback interface. */
  LOOPBACK_INTERFACE = 2,
  /** VLAN_INTERFACE - Vlan interface or SVI. */
  VLAN_INTERFACE = 3,
  /** PORT_CHANNEL_INTERFACE - PortChannel interface. */
  PORT_CHANNEL_INTERFACE = 4,
  /** SUB_PORT_INTERFACE - Sub-port of SubInterface interface. */
  SUB_PORT_INTERFACE = 5,
  UNRECOGNIZED = -1,
}

export function interfaceTypeFromJSON(object: any): InterfaceType {
  switch (object) {
    case 0:
    case "INTERFACE_UNSPECIFIED":
      return InterfaceType.INTERFACE_UNSPECIFIED;
    case 1:
    case "NETWORK_PORT_INTERFACE":
      return InterfaceType.NETWORK_PORT_INTERFACE;
    case 2:
    case "LOOPBACK_INTERFACE":
      return InterfaceType.LOOPBACK_INTERFACE;
    case 3:
    case "VLAN_INTERFACE":
      return InterfaceType.VLAN_INTERFACE;
    case 4:
    case "PORT_CHANNEL_INTERFACE":
      return InterfaceType.PORT_CHANNEL_INTERFACE;
    case 5:
    case "SUB_PORT_INTERFACE":
      return InterfaceType.SUB_PORT_INTERFACE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return InterfaceType.UNRECOGNIZED;
  }
}

export function interfaceTypeToJSON(object: InterfaceType): string {
  switch (object) {
    case InterfaceType.INTERFACE_UNSPECIFIED:
      return "INTERFACE_UNSPECIFIED";
    case InterfaceType.NETWORK_PORT_INTERFACE:
      return "NETWORK_PORT_INTERFACE";
    case InterfaceType.LOOPBACK_INTERFACE:
      return "LOOPBACK_INTERFACE";
    case InterfaceType.VLAN_INTERFACE:
      return "VLAN_INTERFACE";
    case InterfaceType.PORT_CHANNEL_INTERFACE:
      return "PORT_CHANNEL_INTERFACE";
    case InterfaceType.SUB_PORT_INTERFACE:
      return "SUB_PORT_INTERFACE";
    case InterfaceType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** IpAddrType defines internet address types. */
export enum IPAddrType {
  /** IP_ADDR_UNSPECIFIED - Unspecified IP address type. */
  IP_ADDR_UNSPECIFIED = 0,
  /** IPV4 - IPv4 address type. */
  IPV4 = 1,
  /** IPV6 - IPv6 address type. */
  IPV6 = 2,
  UNRECOGNIZED = -1,
}

export function iPAddrTypeFromJSON(object: any): IPAddrType {
  switch (object) {
    case 0:
    case "IP_ADDR_UNSPECIFIED":
      return IPAddrType.IP_ADDR_UNSPECIFIED;
    case 1:
    case "IPV4":
      return IPAddrType.IPV4;
    case 2:
    case "IPV6":
      return IPAddrType.IPV6;
    case -1:
    case "UNRECOGNIZED":
    default:
      return IPAddrType.UNRECOGNIZED;
  }
}

export function iPAddrTypeToJSON(object: IPAddrType): string {
  switch (object) {
    case IPAddrType.IP_ADDR_UNSPECIFIED:
      return "IP_ADDR_UNSPECIFIED";
    case IPAddrType.IPV4:
      return "IPV4";
    case IPAddrType.IPV6:
      return "IPV6";
    case IPAddrType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** LacpMode enumerates different LACP modes. */
export enum LacpMode {
  /** LACP_UNSPECIFIED - Unspecified LACP mode. */
  LACP_UNSPECIFIED = 0,
  /** LACP_ACTIVE - LACP active mode. */
  LACP_ACTIVE = 1,
  /** LACP_PASSIVE - LACP passive mode. */
  LACP_PASSIVE = 2,
  /** LACP_OFF - LACP is disabled. */
  LACP_OFF = 3,
  UNRECOGNIZED = -1,
}

export function lacpModeFromJSON(object: any): LacpMode {
  switch (object) {
    case 0:
    case "LACP_UNSPECIFIED":
      return LacpMode.LACP_UNSPECIFIED;
    case 1:
    case "LACP_ACTIVE":
      return LacpMode.LACP_ACTIVE;
    case 2:
    case "LACP_PASSIVE":
      return LacpMode.LACP_PASSIVE;
    case 3:
    case "LACP_OFF":
      return LacpMode.LACP_OFF;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LacpMode.UNRECOGNIZED;
  }
}

export function lacpModeToJSON(object: LacpMode): string {
  switch (object) {
    case LacpMode.LACP_UNSPECIFIED:
      return "LACP_UNSPECIFIED";
    case LacpMode.LACP_ACTIVE:
      return "LACP_ACTIVE";
    case LacpMode.LACP_PASSIVE:
      return "LACP_PASSIVE";
    case LacpMode.LACP_OFF:
      return "LACP_OFF";
    case LacpMode.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NodeRole defines an enumeration of node roles. */
export enum NodeRole {
  /** NODE_ROLE_UNSPECIFIED - Unspecified node role. */
  NODE_ROLE_UNSPECIFIED = 0,
  /** LEAF - Leaf node or mesh node. */
  LEAF = 1,
  /** SPINE - Node is a spine node. */
  SPINE = 2,
  UNRECOGNIZED = -1,
}

export function nodeRoleFromJSON(object: any): NodeRole {
  switch (object) {
    case 0:
    case "NODE_ROLE_UNSPECIFIED":
      return NodeRole.NODE_ROLE_UNSPECIFIED;
    case 1:
    case "LEAF":
      return NodeRole.LEAF;
    case 2:
    case "SPINE":
      return NodeRole.SPINE;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NodeRole.UNRECOGNIZED;
  }
}

export function nodeRoleToJSON(object: NodeRole): string {
  switch (object) {
    case NodeRole.NODE_ROLE_UNSPECIFIED:
      return "NODE_ROLE_UNSPECIFIED";
    case NodeRole.LEAF:
      return "LEAF";
    case NodeRole.SPINE:
      return "SPINE";
    case NodeRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** NodeType defines an enumeration of node types. */
export enum NodeType {
  /** NODE_TYPE_UNSPECIFIED - Unspecified node type. */
  NODE_TYPE_UNSPECIFIED = 0,
  /** NODE_BLUEPRINT - Blueprint or design mode node. */
  NODE_BLUEPRINT = 1,
  /** NODE_SWITCH - Node is a network switch. */
  NODE_SWITCH = 2,
  UNRECOGNIZED = -1,
}

export function nodeTypeFromJSON(object: any): NodeType {
  switch (object) {
    case 0:
    case "NODE_TYPE_UNSPECIFIED":
      return NodeType.NODE_TYPE_UNSPECIFIED;
    case 1:
    case "NODE_BLUEPRINT":
      return NodeType.NODE_BLUEPRINT;
    case 2:
    case "NODE_SWITCH":
      return NodeType.NODE_SWITCH;
    case -1:
    case "UNRECOGNIZED":
    default:
      return NodeType.UNRECOGNIZED;
  }
}

export function nodeTypeToJSON(object: NodeType): string {
  switch (object) {
    case NodeType.NODE_TYPE_UNSPECIFIED:
      return "NODE_TYPE_UNSPECIFIED";
    case NodeType.NODE_BLUEPRINT:
      return "NODE_BLUEPRINT";
    case NodeType.NODE_SWITCH:
      return "NODE_SWITCH";
    case NodeType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** OsType lists various device operating system types. */
export enum OsType {
  /** OS_UNSPECIFIED - Unspecified OS type. */
  OS_UNSPECIFIED = 0,
  /** HYPER_FABRIC - Cisco Nexus HyperFabric OS. */
  HYPER_FABRIC = 1,
  /** NEXUS - Cisco Nexus OS. */
  NEXUS = 2,
  /** IOS_XE - Cisco IOS-XE OS. */
  IOS_XE = 3,
  /** IOS_XR - Cisco IOS-XR OS. */
  IOS_XR = 4,
  /** LINUX - Linux OS (host OS). */
  LINUX = 5,
  /** WINDOWS - Windows OS (host OS). */
  WINDOWS = 6,
  UNRECOGNIZED = -1,
}

export function osTypeFromJSON(object: any): OsType {
  switch (object) {
    case 0:
    case "OS_UNSPECIFIED":
      return OsType.OS_UNSPECIFIED;
    case 1:
    case "HYPER_FABRIC":
      return OsType.HYPER_FABRIC;
    case 2:
    case "NEXUS":
      return OsType.NEXUS;
    case 3:
    case "IOS_XE":
      return OsType.IOS_XE;
    case 4:
    case "IOS_XR":
      return OsType.IOS_XR;
    case 5:
    case "LINUX":
      return OsType.LINUX;
    case 6:
    case "WINDOWS":
      return OsType.WINDOWS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return OsType.UNRECOGNIZED;
  }
}

export function osTypeToJSON(object: OsType): string {
  switch (object) {
    case OsType.OS_UNSPECIFIED:
      return "OS_UNSPECIFIED";
    case OsType.HYPER_FABRIC:
      return "HYPER_FABRIC";
    case OsType.NEXUS:
      return "NEXUS";
    case OsType.IOS_XE:
      return "IOS_XE";
    case OsType.IOS_XR:
      return "IOS_XR";
    case OsType.LINUX:
      return "LINUX";
    case OsType.WINDOWS:
      return "WINDOWS";
    case OsType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PluggableType enumerates pluggable options. */
export enum PluggableType {
  /** PLUGGABLE_TYPE_UNSPECIFIED - Unspecified pluggable type. */
  PLUGGABLE_TYPE_UNSPECIFIED = 0,
  /** QSFP - QSFP pluggable type. */
  QSFP = 1,
  UNRECOGNIZED = -1,
}

export function pluggableTypeFromJSON(object: any): PluggableType {
  switch (object) {
    case 0:
    case "PLUGGABLE_TYPE_UNSPECIFIED":
      return PluggableType.PLUGGABLE_TYPE_UNSPECIFIED;
    case 1:
    case "QSFP":
      return PluggableType.QSFP;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PluggableType.UNRECOGNIZED;
  }
}

export function pluggableTypeToJSON(object: PluggableType): string {
  switch (object) {
    case PluggableType.PLUGGABLE_TYPE_UNSPECIFIED:
      return "PLUGGABLE_TYPE_UNSPECIFIED";
    case PluggableType.QSFP:
      return "QSFP";
    case PluggableType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** PortType enumerates different physical port types. */
export enum PortRole {
  /** PORT_ROLE_UNSPECIFIED - Unspecified port role. */
  PORT_ROLE_UNSPECIFIED = 0,
  /** UNUSED_PORT - Port is not used. */
  UNUSED_PORT = 1,
  /** FABRIC_PORT - Port is a fabric port. */
  FABRIC_PORT = 2,
  /** HOST_PORT - Port is a host port. */
  HOST_PORT = 3,
  /** ROUTED_PORT - Port is a routed port or a port with IP addresses. */
  ROUTED_PORT = 4,
  /** LAG_PORT - Port is attached to a PortChannel (LAG/MLAG). */
  LAG_PORT = 5,
  UNRECOGNIZED = -1,
}

export function portRoleFromJSON(object: any): PortRole {
  switch (object) {
    case 0:
    case "PORT_ROLE_UNSPECIFIED":
      return PortRole.PORT_ROLE_UNSPECIFIED;
    case 1:
    case "UNUSED_PORT":
      return PortRole.UNUSED_PORT;
    case 2:
    case "FABRIC_PORT":
      return PortRole.FABRIC_PORT;
    case 3:
    case "HOST_PORT":
      return PortRole.HOST_PORT;
    case 4:
    case "ROUTED_PORT":
      return PortRole.ROUTED_PORT;
    case 5:
    case "LAG_PORT":
      return PortRole.LAG_PORT;
    case -1:
    case "UNRECOGNIZED":
    default:
      return PortRole.UNRECOGNIZED;
  }
}

export function portRoleToJSON(object: PortRole): string {
  switch (object) {
    case PortRole.PORT_ROLE_UNSPECIFIED:
      return "PORT_ROLE_UNSPECIFIED";
    case PortRole.UNUSED_PORT:
      return "UNUSED_PORT";
    case PortRole.FABRIC_PORT:
      return "FABRIC_PORT";
    case PortRole.HOST_PORT:
      return "HOST_PORT";
    case PortRole.ROUTED_PORT:
      return "ROUTED_PORT";
    case PortRole.LAG_PORT:
      return "LAG_PORT";
    case PortRole.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Breakout defines port breakout mode. */
export interface Breakout {
  /** NumPorts defines the number of breakout ports (E.g. 1, 2 or 4). */
  numPorts: number;
  /** Speed defines port speed (E.g. 10G, 200G etc.). */
  speed: string;
  /** Media defines the expected cable or media type. */
  media: CableType;
  /** Ports defines the names of base network ports. */
  ports: string[];
  /** Breakouts defines the output of the port breakout operation (readonly). */
  breakouts: string[];
}

/** SpeedMode defines port speed mode. */
export interface SpeedMode {
  /** Speed defines port speed (E.g. 10G, 200G etc.). */
  speed: string;
  /** IsDefault indicates that breakout is a default config. */
  isDefault: boolean;
  /** Ports defines the network port names. */
  ports: string[];
}

function createBaseBreakout(): Breakout {
  return { numPorts: 0, speed: "", media: 0, ports: [], breakouts: [] };
}

export const Breakout = {
  encode(message: Breakout, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numPorts !== 0) {
      writer.uint32(8).uint32(message.numPorts);
    }
    if (message.speed !== "") {
      writer.uint32(18).string(message.speed);
    }
    if (message.media !== 0) {
      writer.uint32(24).int32(message.media);
    }
    for (const v of message.ports) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.breakouts) {
      writer.uint32(42).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Breakout {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBreakout();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numPorts = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.media = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ports.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.breakouts.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Breakout {
    return {
      numPorts: isSet(object.numPorts) ? globalThis.Number(object.numPorts) : 0,
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      media: isSet(object.media) ? cableTypeFromJSON(object.media) : 0,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
      breakouts: globalThis.Array.isArray(object?.breakouts)
        ? object.breakouts.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: Breakout): unknown {
    const obj: any = {};
    if (message.numPorts !== 0) {
      obj.numPorts = Math.round(message.numPorts);
    }
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.media !== 0) {
      obj.media = cableTypeToJSON(message.media);
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    if (message.breakouts?.length) {
      obj.breakouts = message.breakouts;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Breakout>, I>>(base?: I): Breakout {
    return Breakout.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Breakout>, I>>(object: I): Breakout {
    const message = createBaseBreakout();
    message.numPorts = object.numPorts ?? 0;
    message.speed = object.speed ?? "";
    message.media = object.media ?? 0;
    message.ports = object.ports?.map((e) => e) || [];
    message.breakouts = object.breakouts?.map((e) => e) || [];
    return message;
  },
};

function createBaseSpeedMode(): SpeedMode {
  return { speed: "", isDefault: false, ports: [] };
}

export const SpeedMode = {
  encode(message: SpeedMode, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.speed !== "") {
      writer.uint32(10).string(message.speed);
    }
    if (message.isDefault !== false) {
      writer.uint32(16).bool(message.isDefault);
    }
    for (const v of message.ports) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SpeedMode {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpeedMode();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.speed = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.isDefault = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.ports.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SpeedMode {
    return {
      speed: isSet(object.speed) ? globalThis.String(object.speed) : "",
      isDefault: isSet(object.isDefault) ? globalThis.Boolean(object.isDefault) : false,
      ports: globalThis.Array.isArray(object?.ports) ? object.ports.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SpeedMode): unknown {
    const obj: any = {};
    if (message.speed !== "") {
      obj.speed = message.speed;
    }
    if (message.isDefault !== false) {
      obj.isDefault = message.isDefault;
    }
    if (message.ports?.length) {
      obj.ports = message.ports;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SpeedMode>, I>>(base?: I): SpeedMode {
    return SpeedMode.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SpeedMode>, I>>(object: I): SpeedMode {
    const message = createBaseSpeedMode();
    message.speed = object.speed ?? "";
    message.isDefault = object.isDefault ?? false;
    message.ports = object.ports?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
