import { Button } from "@magnetic/button";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { withShowToggle } from "components/hoc/withShowToggle";
import { DataProviderContext } from "src/core/provider/dataProvider";
import { FabricCandidate } from "src/gen/schema/configd/api";
import "./previewChanges.scss";
import { Apis } from "src/utils/api";
import { useNavigate } from "react-router-dom";
import { ApiError } from "src/core/apiError";
import { getFabricRoute } from "src/utils/routesUtils";
import { CandidateChangesDrawer } from "./candidateChangesDrawer";

interface PreviewChangesDrawerProps {
  onClose: () => void;
}
export const PreviewChangesDrawer = withShowToggle(
  ({ onClose }: PreviewChangesDrawerProps) => {
    const { fabric } = useContext(DataProviderContext);
    const redirect = useNavigate();
    const [candidate, setCandidate] = useState<FabricCandidate | undefined>();
    const [error, setError] = useState<ApiError | undefined>();

    useEffect(() => {
      /**
       * Loads all the pending Fabric Transactions.
       * there should just be one with a bunch of
       * events
       */
      if (fabric) {
        Apis.ConfigD.getFabricTransactions(fabric.id).then(
          (fc: FabricCandidate[] = []) => {
            if (fc[0]) {
              setCandidate(fc[0]);
            }
          },
          (e: ApiError) => setError(e)
        );
      }
    }, [fabric]);

    const reviewConfiguration = useCallback(() => {
      if (fabric) {
        redirect(getFabricRoute(fabric.id, "candidate"));
      }
    }, [redirect, fabric]);

    return (
      <CandidateChangesDrawer
        title="Preview Changes"
        onClose={onClose}
        candidate={candidate}
        error={error}
      >
        <Button
          name="review-config"
          kind="primary"
          onClick={reviewConfiguration}
        >
          Review configuration
        </Button>
      </CandidateChangesDrawer>
    );
  }
);
