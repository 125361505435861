import { Spinner } from "@magnetic/spinner";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { navUrls } from "./navbar";
import { DetailPageFallback } from "../components/detailPageFallback";

const DeveloperPage = lazy(() => import("../pages/developer/developerPage"));
const SpyglassPage = lazy(() => import("../pages/spyglass"));
const ApiBearerTokensPage = lazy(
  () => import("../pages/api-bearer-token/apiBearerTokens")
);
const SwitchDetails = lazy(
  () => import("../pages/switches/details/switchDetails")
);
const FabricDetails = lazy(
  () => import("../pages/fabrics/details/fabricDetails")
);
const Home = lazy(() => import("../home"));
const FabricLibrary = lazy(() => import("../pages/fabrics/fabricLibrary"));
const PortDetails = lazy(() => import("../pages/ports/details/portDetails"));
const VniDetails = lazy(() => import("../pages/vni/details/vniDetails"));
const VrfDetails = lazy(() => import("../pages/vrf/details/vrfDetails"));
const ReviewCandidate = lazy(
  () => import("../pages/candidates/reviewCandidate")
);

const UserManagement = lazy(
  () => import("../pages/administration/users/userManagement")
);
const Dummy = ({ text }: { text: string }) => text;

export const PATHS = {
  FABRICS: "fabrics",
  FABRIC: "fabric",
  CHASSIS: "node",
  CANDIDATE: "candidate",
  DIFF: "diff",
  REVIEW: "review",
  SUMMARY: "summary",
  PORT: "port",
  VRF: "vrf",
  VNI: "vni",
  ISSUES: "issues",
  USER_MGMT: "user-management",
  BEARER_TOKEN: "api-bearer-tokens"
};

export const router = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={`/${PATHS.FABRICS}`}>
          <Route index={true} element={<FabricLibrary />} />
        </Route>
        <Route path={`/${PATHS.FABRIC}`}>
          <Route path=":fabricId">
            <Route
              index={true}
              element={<Navigate to={PATHS.SUMMARY} replace={true} />}
            />
            <Route path={PATHS.SUMMARY}>
              <Route
                index={true}
                element={<Navigate to="devices" replace={true} />}
              />
              <Route path={`:menu?`} element={<FabricDetails />} />
            </Route>
            <Route path={`${PATHS.ISSUES}`} element={<FabricDetails />} />
            <Route path={`${PATHS.CANDIDATE}`}>
              <Route
                index={true}
                element={<Navigate to={PATHS.REVIEW} replace={true} />}
              />
              <Route path={`${PATHS.SUMMARY}`} element={<ReviewCandidate />} />
              <Route path={`${PATHS.REVIEW}`} element={<ReviewCandidate />} />
              <Route path={`${PATHS.DIFF}`} element={<ReviewCandidate />} />
            </Route>
            <Route path={`${PATHS.VRF}/:vrfId`} element={<VrfDetails />} />
            <Route path={`${PATHS.VNI}/:vni`} element={<VniDetails />} />
            <Route path={PATHS.CHASSIS}>
              <Route path=":nodeId">
                <Route
                  index={true}
                  element={<Navigate to={`${PATHS.SUMMARY}`} replace={true} />}
                />
                <Route path={`${PATHS.SUMMARY}`}>
                  <Route
                    index={true}
                    element={<Navigate to="environmentals" replace={true} />}
                  />
                  <Route
                    path={`:switchDetailsSubTabs?`}
                    element={<SwitchDetails />}
                  />
                </Route>
                <Route path="workflow" element={<SwitchDetails />} />
                <Route path={`${PATHS.PORT}/:portId`}>
                  <Route
                    index={true}
                    element={
                      <Navigate to={`${PATHS.SUMMARY}`} replace={true} />
                    }
                  />
                  <Route path={`${PATHS.SUMMARY}`} element={<PortDetails />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path={`/${PATHS.VNI}/:vni`} element={<VniDetails />} />
        <Route path="/dev">
          <Route index={true} element={<DeveloperPage />} />
          <Route key="echoPage" path="echo" element={<DeveloperPage />} />
          <Route
            key="syncfollow"
            path="syncfollow"
            element={<DeveloperPage />}
          />
          <Route key="websocket" path="websocket" element={<DeveloperPage />} />
          <Route
            key="developerPage"
            path="settings"
            element={<DeveloperPage />}
          />
          <Route
            key="examplesPage"
            path="examples/:name?"
            element={<DeveloperPage />}
          />
        </Route>
        <Route
          path={`/${PATHS.USER_MGMT}`}
          key="userManagement"
          element={<UserManagement />}
        />
        <Route path="/spyglass" key="spyglass" element={<SpyglassPage />} />
        <Route
          path={`/${PATHS.BEARER_TOKEN}`}
          key="apiBearerTokens"
          element={<ApiBearerTokensPage />}
        />
        {navUrls.map((to: string) => (
          <Route path={to} key={to} element={<Dummy text={to} />} />
        ))}
        <Route
          path="/login"
          element={<Navigate to={`/${PATHS.FABRICS}`} replace={true} />}
        />
        <Route
          path="*"
          element={
            <DetailPageFallback
              breadcrumbs={[["Fabrics", "/fabrics"]]}
              text="Page not found"
            />
          }
        />
      </Routes>
    </Suspense>
  );
};
