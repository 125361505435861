import { logger } from "./globals";

export type settingType =
  | "authBypass"
  | "inlineApiDebugger"
  | "websockets"
  | "dev"
  | "grpcTransportDebug"
  | "apiLoggingInConsole"
  | "backendUrl";

type NiceFormat = {
  setting: settingType;
  active: string | boolean;
  defaultValue: string | boolean | undefined;
  usesDefaultValue: string | boolean | undefined;
};

export default class LocalSettings {
  kvStore: Map<settingType, boolean | string>;
  defaultSettings: Map<settingType, boolean | string>;
  isDefaultMap: Map<settingType, boolean | string>;

  constructor() {
    // build settings map with these default values
    this.kvStore = new Map<settingType, string | boolean>([
      ["authBypass", false],
      ["inlineApiDebugger", false],
      ["apiLoggingInConsole", false],
      ["dev", false],
      ["websockets", false],
      ["grpcTransportDebug", false],
      ["backendUrl", ""]
    ]);

    // make a copy to track default settings before anything gets modified
    this.defaultSettings = new Map(this.kvStore);

    // keep track of which values are overwrites. The values need to be set correctly by loadValues()
    this.isDefaultMap = new Map(this.kvStore);

    // load values into map
    this.loadValues();
  }

  loadValues = () => {
    this.kvStore.forEach((v, k) => {
      const setting = localStorage.getItem(k);
      if (setting === "True") {
        this.kvStore.set(k, true);
        this.isDefaultMap.set(k, false);
      } else if (setting === "False") {
        this.kvStore.set(k, false);
        this.isDefaultMap.set(k, false);
      } else {
        this.isDefaultMap.set(k, true);
      }
    });
  };

  validKey = (k: settingType) => {
    if (!this.kvStore.has(k)) {
      logger.error(
        "Invalid setting '" + k + "'. Available settings:",
        this.kvStore.entries()
      );
      return false;
    }
    return true;
  };

  set = (k: settingType, v: boolean | string) => {
    if (this.validKey(k)) {
      this.kvStore.set(k, v);
      localStorage.setItem(k, v === true ? "True" : v === false ? "False" : v);
      this.isDefaultMap.set(k, false);
    }
  };

  reset = (k: settingType) => {
    if (this.validKey(k)) {
      localStorage.removeItem(k);
      const defaultValue = this.defaultSettings.get(k) ?? false;
      this.kvStore.set(k, defaultValue);
      this.isDefaultMap.set(k, true);
    }
  };

  resetAll = () => {
    this.kvStore.forEach((v, k) => {
      this.reset(k);
    });
  };

  asObject = () => {
    return Object.fromEntries(this.kvStore);
  };

  get = (k: settingType) => {
    if (this.validKey(k)) {
      return !!this.kvStore.get(k);
    }
    return false;
  };

  getString = (k: settingType): string => {
    if (this.validKey(k)) {
      return (this.kvStore.get(k) as string) || "";
    }
    return "";
  };

  getDefault = (k: settingType) => {
    if (this.validKey(k)) {
      return this.defaultSettings.get(k);
    }
    return false;
  };

  isDefault = (k: settingType) => {
    if (this.validKey(k)) {
      return !!this.isDefaultMap.get(k);
    }
    return false;
  };

  toggle = (k: settingType) => {
    // only error once
    if (this.validKey(k)) {
      this.set(k, !this.get(k));
    }
  };

  print = () => {
    const niceFormat: NiceFormat[] = [];
    this.kvStore.forEach((v, k) => {
      niceFormat.push({
        setting: k,
        active: v,
        defaultValue: this.defaultSettings.get(k),
        usesDefaultValue: this.isDefaultMap.get(k)
      });
    });
    logger.info(niceFormat);
  };
}
